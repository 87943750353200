// @type-check
import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { fetchApi } from '../../Services/NetworkServcice';
import { formats } from '../../Constants';
import { Link } from 'react-router-dom';

const SequenceCard = (props) => {
    const [state, setState] = useState({
        tooltipVisible: false
    });

    const signs = [];
    for (const s of props.sequence.signId) {
        const id = props.signs.findIndex(({ id }) => id === s);
        if (id === -1) signs.push('Unk sign');
        else signs.push(props.signs[id].name);
    }

    return (
        <div className="card seq">
            <div className="content">
                <h3>Séquence : {props.sequence.name}</h3>
                <p>
                    <i className="fa fa-images"></i> Format : {formats[props.sequence.orientation]?.name} -{' '}
                    {props.sequence.creatives.length} affiche{props.sequence.creatives.length <= 1 ? '' : 's'}
                </p>
                {props.sequence.signId.length === 0 || (
                    <p>
                        <i className="fa fa-store"></i> Enseignes : {signs.reduce((acc, i) => acc + ', ' + i)}
                    </p>
                )}
                <p>
                    <i className="fa fa-tv"></i> Écrans : {props.sequence.devices.length}
                </p>
            </div>
            <div className="btn-container">
                <Link to={`/sequences/${props.sequence._id}`} className="btn-large">
                    <i className="fa fa-pen"></i> Éditer
                </Link>
                <button
                    className="btn-large-arrow"
                    onClick={() =>
                        setState({
                            ...state,
                            tooltipVisible: !state.tooltipVisible
                        })
                    }
                >
                    <i className="fa fa-chevron-down"></i>
                </button>
                <div className={`tooltip-menu${state.tooltipVisible ? ' active' : ''}`}>
                    <span onClick={() => props.delete(props.sequence._id)}>Supprimer</span>
                </div>
            </div>
        </div>
    );
};

const Homepage = (props) => {
    const [state, setState] = useState({
        sequences: [],
        signs: [],
        selectedOrientation: 'portrait',
        newSequenceName: ''
    });

    useEffect(() => {
        fetchApi('/admin/managed-sequences/home')
            .then((res) => res.json())
            .then(({ sequences, signs }) =>
                setState((prevState) => ({
                    ...prevState,
                    sequences,
                    signs
                }))
            );
    }, []);

    return (
        <div className="dyl-home home">
            <header>
                <p className="hidden-xs">Séquences gérées</p>
                <h3>{dateFormat(new Date(), 'dddd dd mmmm yyyy')}</h3>
            </header>
            <div className="content">
                <div className="flex-row">
                    <div className="summary">
                        <div className="new-sequence">
                            <div className="card">
                                <h3>Nouvelle séquence</h3>
                                <div className="form-group">
                                    <label>Titre:</label>
                                    <input
                                        value={state.newSequenceName}
                                        onChange={(e) => {
                                            const newSequenceName = e.target.value;
                                            setState((prevState) => ({ ...prevState, newSequenceName }));
                                        }}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Orientation:</label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => {
                                            const selectedOrientation = e.target.value;
                                            setState((prevState) => ({ ...prevState, selectedOrientation }));
                                        }}
                                        value={state.selectedOrientation}
                                    >
                                        <option value="portrait">Portrait</option>
                                        <option value="landscape">Paysage</option>
                                    </select>
                                </div>
                                <button
                                    className={`btn-dyl-seq ${
                                        state.newSequenceName.trim().length > 2 ? '' : 'disabled'
                                    }`}
                                    onClick={() =>
                                        props.history.push(
                                            `/sequences/new/${state.newSequenceName}/${state.selectedOrientation}`
                                        )
                                    }
                                >
                                    <i className="fa fa-plus"></i> Créer la séquence
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="sequences">
                        {state.sequences.map((s) => (
                            <SequenceCard key={s._id} sequence={s} signs={state.signs} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
