import React, { useState } from 'react';
import Slide from './Slide';

import '../../Assets/editor-slides.css';

const EditorSlides = ({
    creatives = [],
    maxUserSlides = 8,
    handleSlideClick = (_ = 0) => null,
    handleAddExpirationClick = (_ = '', __ = 0) => null,
    removeSlide = (_ = 0) => null,
    setCreatives = (_ = []) => null,
    onClickNew = () => null,
    onDuplicate = () => null,
    orientation = 'portrait',
    editable = true,
    allowSlidesDurationChange = false,
    showDuplicateButton = true,
    draggable = true,
    selectedSigns = null
}) => {
    const [draggedItem, setDraggedItem] = useState(null);

    const onDragStart = (e, index) => {
        if (!editable || !draggable || !e.target.hasAttribute('draggable')) {
            return;
        }

        setDraggedItem(creatives[index]);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.firstChild);
        const rect = e.target.getBoundingClientRect();
        e.dataTransfer.setDragImage(e.target.children[2], e.pageX - rect.left + (rect.right - rect.left) / 2, e.pageY - rect.top + (rect.bottom - rect.top) / 2);
    };

    const onDragEnd = () => {
        if (!editable || !draggable) {
            return;
        }

        setDraggedItem(null);
    };

    const onDragOver = (index) => {
        if (!editable || !draggable) {
            return;
        }

        const draggedOverItem = creatives[index];

        if (draggedItem === null) {
            return;
        }
        if (draggedItem === draggedOverItem) {
            return;
        }

        const newCreatives = creatives.filter((item) => item !== draggedItem);
        newCreatives.splice(index, 0, draggedItem);

        setCreatives(newCreatives);
    };

    return (
        <div className="editor-slides">
            {creatives.map((c, i) => (
                <div key={i} className="slide">
                    <Slide
                        {...c}
                        orientation={orientation}
                        onClick={() => handleSlideClick(i)}
                        onExpirationClick={() => handleAddExpirationClick(c.creaType, i)}
                        onDelete={() => removeSlide(i)}
                        onDragStart={(e) => onDragStart(e, i)}
                        onDragEnd={onDragEnd}
                        onDragOver={() => onDragOver(i)}
                        onDuplicate={() => onDuplicate(c)}
                        editable={editable}
                        allowSlidesDurationChange={allowSlidesDurationChange}
                        showDuplicateButton={showDuplicateButton}
                        draggable={draggable}
                        selectedSigns={selectedSigns}
                    />
                </div>
            ))}
            {!editable || (orientation !== 'landscape' && creatives.length >= maxUserSlides) || (
                <div className="slide">
                    <div className={'addSlide ' + orientation} onClick={onClickNew}>
                        <i className="fa fa-plus" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditorSlides;
