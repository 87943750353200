import React from 'react';
import dateFormat from 'dateformat';

import '../../Assets/Templates/MyAuchan.css';
import {
    getPathFromCategory,
    getTitleFromCategory,
    getDataFromCategory,
    getFlag,
    getLabels,
    padNum,
    getQuantityText,
    getContainer,
    getKiloPrice,
    getPiecePrice,
} from './MainTemplate';

export const getDates = (startDate, endDate) => {
    let str = '';

    if (startDate && endDate) {
        return `Du ${dateFormat(startDate, 'd mmmm')} au ${dateFormat(endDate, 'd mmmm')}`;
    }

    if (startDate) {
        str += `A partir du ${dateFormat(startDate, 'd mmmm')}`;
    }

    if (endDate) {
        str += `Jusqu'au ${dateFormat(endDate, 'd mmmm')}`;
    }

    return str;
};

const getKiloPiecePriceLabel = (props) => {
    if (props.priceUnit === 'number') {
        return 'Soit le Kg';
    } else if (props.priceUnit === 'piece') {
        return 'Soit pièce';
    } else if (props.origPrice) {
        return 'Au lieu de';
    }

    return '';
};

const getKiloPiecePrice = (props) => {
    if (props.priceUnit === 'number') {
        return getKiloPrice(props.weight, props.price).toString().split(/[.,]/);
    } else if (props.priceUnit === 'piece') {
        return getPiecePrice(props.pieceNb, props.price).toString().split(/[.,]/);
    } else if (props.origPrice) {
        return props.origPrice.toString().split(/[.,]/);
    }

    return '';
};

const hasSecondPrice = (props) => {
    if (props.priceUnit !== 'number' && props.priceUnit !== 'piece' && !props.origPrice) {
        return false;
    }

    return true;
};

export const MyAuchanTemplate = (props) => {
    return (
        <div className={`MyAuchanTemplate MyAuchan ${props.orientation} ${props.rayon}`}>
            <div className="promo">
                <p>{getDates(props.startDate, props.expirationDate)}</p>
                {!props.promotion || <img src="/templates/myauchan-promo.png" alt="promo" />}
            </div>
            <div className="origin">
                <div className="origin-ctr">
                    {!props.originCountry || (
                        <span style={{ textTransform: 'uppercase' }}>
                            {!!props.originType ? props.originType : 'ORIGINE'}
                        </span>
                    )}
                    {getFlag(props.originCountry, props.displayFlag)}
                </div>
                <h5>{props.originCountry}</h5>
            </div>
            <div className="image">
                <img src={getPathFromCategory(props.rayon, props.filename)} alt="product" />
            </div>
            <div className="data-price">
                <div className="price-ctr">
                    {getContainer(props)} {getQuantityText(props)}
                </div>
                <div className="price">
                    <div className="num">{props.price.toString().split(/[.,]/)[0]}</div>
                    <div className="sec-part">
                        <div className="eur">&euro;</div>
                        <span className="cts">{padNum(props.price.toString().split(/[.,]/)[1])}</span>
                    </div>
                </div>
                {!hasSecondPrice(props) || (
                    <React.Fragment>
                        <div className="sep"></div>
                        <span className="kilo-price">{getKiloPiecePriceLabel(props)}</span>
                        <div className="price-min">
                            <div className="num">{getKiloPiecePrice(props)[0]}</div>
                            <div className="sec-part">
                                <div className="eur">&euro;</div>
                                <span className="cts">{padNum(getKiloPiecePrice(props)[1])}</span>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <div className="data">
                <div className="labels">
                    {getLabels(props).map((l) => (
                        <img key={l} src={`/labels/${l}`} alt="label" />
                    ))}
                </div>
                <h1>{getTitleFromCategory(props.rayon, props)}</h1>
                {getDataFromCategory(props.rayon, props)}
            </div>
            <img src="/templates/myauchan-bg.png" alt="bg" className="bg" />
        </div>
    );
};
