import React, { useState } from 'react';

let theme = [13].includes(parseInt(localStorage.getItem('dyl_a_id'))) ? 'hotels' : 'default';

function getImage(lang, type, theme, ext = 'jpg') {
    return `/widgets-images/${type}-preview-${theme}-${lang}.${ext}`;
}

const WIDGETS = [
    {
        type: 'weather',
        name: 'Météo',
        image: getImage('fr', 'weather', theme, theme === 'hotels' ? 'png' : 'jpg'),
        format: ['portrait'],
        widgetData: {
            lang: 'fr',
            theme: theme === 'hotels' ? 'hotels-white' : theme,
        },
    },
    {
        type: 'weather',
        name: 'Weather (🇬🇧)',
        image: getImage('en', 'weather', theme, theme === 'hotels' ? 'png' : 'jpg'),
        format: ['portrait'],
        widgetData: {
            lang: 'en',
            theme: theme === 'hotels' ? 'hotels-white' : theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - Général',
        feed: 'general',
        image: getImage('fr', 'news-general', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'fr',
            theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - Sport',
        feed: 'sport',
        image: getImage('fr', 'news-sport', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'fr',
            theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - Economie',
        feed: 'economy',
        image: getImage('fr', 'news-economy', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'fr',
            theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - Culture',
        feed: 'culture',
        image: getImage('fr', 'news-culture', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'fr',
            theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - General (🇬🇧)',
        feed: 'general',
        image: getImage('en', 'news-general', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'en',
            theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - Sport (🇬🇧)',
        feed: 'sport',
        image: getImage('en', 'news-sport', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'en',
            theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - Economy (🇬🇧)',
        feed: 'economy',
        image: getImage('en', 'news-economy', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'en',
            theme,
        },
    },
    {
        type: 'RSS',
        name: 'News - Culture (🇬🇧)',
        feed: 'culture',
        image: getImage('en', 'news-culture', theme),
        format: ['portrait'],
        widgetData: {
            lang: 'en',
            theme,
        },
    },
];

const WidgetItem = ({ image, name, onClick, isSelected, orientation }) => (
    <div className={isSelected ? `templateItemFree selected ${orientation}` : `templateItemFree`}>
        <img src={image} alt={image} onClick={onClick} />
        <p className="nomargin">{name}</p>
    </div>
);

const HotelsPriceStep = (props) => {
    const [state, setState] = useState({
        priceClassic: props.hotelPricesSlide?.widgetData?.priceClassic ?? '100',
        priceDeluxe: props.hotelPricesSlide?.widgetData?.priceDeluxe ?? '100',
        priceJunior: props.hotelPricesSlide?.widgetData?.priceJunior ?? '100',
    });

    return (
        <div>
            <header>
                <div className="buttons">
                    <button
                        className="button-assistant"
                        disabled={state.selectedWidget === null}
                        onClick={() =>
                            props.onSave({
                                type: 'HotelPrices',
                                name: 'Tarifs hotels',
                                image: '/widgets-images/prices-preview-hotels.png',
                                widgetData: {
                                    lang: props.lang,
                                    priceClassic: state.priceClassic,
                                    priceDeluxe: state.priceDeluxe,
                                    priceJunior: state.priceJunior,
                                },
                                _id: props.hotelPricesSlide?._id,
                            })
                        }
                    >
                        Enregistrer
                    </button>
                </div>
            </header>
            <div>
                <div className="form-group">
                    <label style={{ marginRight: '5px' }}>Prix chambre classique</label>
                    <input
                        type="text"
                        value={state.priceClassic}
                        onChange={(e) => setState({ ...state, priceClassic: e.target.value })}
                    />
                    <p>Valeur: {state.priceClassic}</p>
                </div>
                <div className="form-group">
                    <label style={{ marginRight: '5px' }}>Prix chambre deluxe</label>
                    <input
                        type="text"
                        value={state.priceDeluxe}
                        onChange={(e) => setState({ ...state, priceDeluxe: e.target.value })}
                    />
                    <p>Valeur: {state.priceDeluxe}</p>
                </div>
                <div className="form-group">
                    <label style={{ marginRight: '5px' }}>Prix suite junior</label>
                    <input
                        type="text"
                        value={state.priceJunior}
                        onChange={(e) => setState({ ...state, priceJunior: e.target.value })}
                    />
                    <p>Valeur: {state.priceJunior}</p>
                </div>
            </div>
        </div>
    );
};

export const WidgetsListModal = (props) => {
    const [state, setState] = useState({
        selectedWidget: null,
        hotelsPrice: false,
        hotelsPriceStep: props.hotelPricesSlide !== null,
        lang: props.hotelPricesSlide?.widgetData?.lang ?? 'fr',
    });

    const hotelPrices = {
        type: 'HotelPrices',
        name: 'Tarifs hotels',
        image: '/widgets-images/prices-preview-hotels.png',
        format: ['portrait', 'landscape'],
        widgetData: {
            lang: 'fr',
        },
    };

    const hotelPricesEn = {
        type: 'HotelPrices',
        name: 'Tarifs hotels (🇬🇧)',
        image: '/widgets-images/prices-preview-hotels-en.png',
        format: ['portrait', 'landscape'],
        widgetData: {
            lang: 'en',
        },
    };

    if (state.hotelsPriceStep) {
        return <HotelsPriceStep onSave={props.onSave} lang={state.lang} hotelPricesSlide={props.hotelPricesSlide} />;
    }

    return (
        <div>
            <header>
                <div className="buttons">
                    {!!state.hotelsPrice || (
                        <button
                            className="button-assistant"
                            disabled={state.selectedWidget === null}
                            onClick={() => props.onSave(state.selectedWidget)}
                        >
                            Enregistrer
                        </button>
                    )}
                    {!state.hotelsPrice || (
                        <button
                            className="button-assistant"
                            onClick={() => setState({ ...state, hotelsPriceStep: true })}
                        >
                            Suivant
                        </button>
                    )}
                </div>
            </header>
            <div className="templateList">
                {props.orientation !== 'portrait' || WIDGETS.map((w, i) => (
                    <WidgetItem
                        {...w}
                        key={i}
                        onClick={() => setState({ ...state, selectedWidget: w, hotelsPrice: false })}
                        isSelected={
                            !!state.selectedWidget &&
                            w.type === state.selectedWidget.type &&
                            w.name === state.selectedWidget.name
                        }
                    />
                ))}
                {!props.allowHotelPrices || (
                    <WidgetItem
                        {...hotelPrices}
                        onClick={() => setState({ ...state, hotelsPrice: true, selectedWidget: null, lang: 'fr' })}
                        isSelected={state.hotelsPrice && state.lang === 'fr'}
                    />
                )}
                {!props.allowHotelPrices || (
                    <WidgetItem
                        {...hotelPricesEn}
                        onClick={() => setState({ ...state, hotelsPrice: true, selectedWidget: null, lang: 'en' })}
                        isSelected={state.hotelsPrice && state.lang === 'en'}
                    />
                )}
            </div>
        </div>
    );
};

export const PreviewWidget = (props) => {
    if (props.type === 'weather') {
        let lang = 'fr';
        if (props.widgetData && props.widgetData.lang !== 'fr') {
            lang = props.widgetData.lang;
        }

        return (
            <div>
                <img
                    style={{ maxWidth: '100%' }}
                    src={getImage(lang, 'weather', theme, theme.includes('hotels') ? 'png' : 'jpg')}
                    alt="weather"
                />
            </div>
        );
    } else if (['sport', 'general', 'economy', 'culture'].includes(props.type)) {
        let lang = 'fr';
        let type = `news-${props.type}`;

        if (props.widgetData && props.widgetData.lang !== 'fr') {
            lang = props.widgetData.lang;
        }

        return (
            <div>
                <img style={{ maxWidth: '100%' }} src={getImage(lang, type, theme)} alt="news" />
            </div>
        );
    } else if (props.type === 'HotelPrices') {
        if (props.orientation === 'landscape') {
            if (props.widgetData?.lang === 'en') {
                return (
                    <div>
                        <img style={{ maxWidth: '100%' }} src="/widgets-images/prices-preview-hotels-ls-en.png" alt="thumb" />
                    </div>
                );
            }

            return (
                <div>
                    <img style={{ maxWidth: '100%' }} src="/widgets-images/prices-preview-hotels-ls.png" alt="thumb" />
                </div>
            );
        } else {
            if (props.widgetData?.lang === 'en') {
                return (
                    <div>
                        <img style={{ maxWidth: '100%' }} src="/widgets-images/prices-preview-hotels-en.png" alt="thumb" />
                    </div>
                );
            }

            return (
                <div>
                    <img style={{ maxWidth: '100%' }} src="/widgets-images/prices-preview-hotels.png" alt="thumb" />
                </div>
            );
        }
    }

    return <div>Unknown widget</div>;
};
