import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Assets/space.min.css';
import './Assets/admin2.css';
import './Assets/iconmoon.css';

import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
