import React from 'react';
import { Grid, Row, Col, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthService from '../Components/Auth/AuthService';
import constants from '../Constants';
import { fetchApi } from '../Services/NetworkServcice';

class Login extends React.Component {
    constructor() {
        super();
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handlePasswordRecovery = this.handlePasswordRecovery.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.Auth = new AuthService();
        this.state = {
            login: '',
            password: '',
            info: '',
            phone: '',
            modalPassword: false,
            modalSuccess: false,
            loading: false
        };

        this.loginClass = '';
        if (window.location.host === 'campus.doohyoulike.com') {
            this.loginClass = 'campus';
        } else if (window.location.host === 'sport.doohyoulike.com') {
            this.loginClass = 'sport';
        } else if (window.location.host === 'proxi.doohyoulike.com') {
            this.loginClass = 'proxi';
        } else if (window.location.host === 'hotels.doohyoulike.com') {
            this.loginClass = 'hotels';
        }
    }

    handleFormSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        return fetch(`${constants.endpoint}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                login: this.state.login,
                password: this.state.password
            })
        })
            .then(async (res) => {
                if (res.status === 200) {
                    const { token } = await res.json();
                    this.Auth.setToken(token);
                    this.props.history.replace('/');
                }

                if (res.status === 404) {
                    await swal({
                        title: 'Informations incorrectes',
                        text: 'Utilisateur ou mot de passe incorrects',
                        icon: 'warning'
                    });

                    this.setState({ loading: false });
                }
            })
            .catch((err) => {
                this.setState({ loading: false });

                console.log(err);

                swal({
                    title: 'Erreur de communication',
                    text: 'Une erreur est survenue lors de la communication avec le serveur',
                    icon: 'warning'
                });
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount() {
        if (this.Auth.loggedIn()) {
            this.props.history.replace('/');
        }
    }

    handlePasswordRecovery() {
        if (
            this.state.login === null ||
            this.state.login === '' ||
            this.state.info === null ||
            this.state.info === ''
        ) {
            alert('Veuillez remplir tous les champs');
        } else {
            this.setState({
                modalPassword: false,
                modalSuccess: true
            });
        }
    }

    handleCloseModal() {
        this.setState({
            modalPassword: false,
            modalSuccess: false
        });
    }

    handleEmail(login, info, phone) {
        fetchApi('/admin/wizard/recoveremail', {
            method: 'POST',
            body: JSON.stringify({
                login,
                info,
                phone
            })
        }).catch(function (error) {
            console.log("Il y a eu un problème avec l'opération : " + error.message);
        });
    }

    render() {
        return (
            <div className="page-container">
                <Modal show={this.state.modalPassword} onClose={this.handleCloseModal}>
                    <Modal.Header>
                        <h4>Mot de passe oubli&eacute;</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Entrez votre identifiant</h4>
                        <input
                            type="text"
                            name="login"
                            value={this.state.login}
                            onChange={this.handleChange}
                            className="form-control"
                        />
                        <h5>Téléphone</h5>
                        <input
                            type="text"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handleChange}
                            className="form-control"
                        />
                        <h5>
                            Veuillez nous communiquer le maximum d’informations concernant votre magasin (nom, enseigne,
                            adresse, directeur, email, téléphone, etc…)
                        </h5>
                        <textarea
                            cols="30"
                            rows="10"
                            className="form-control"
                            name="info"
                            value={this.state.info}
                            onChange={this.handleChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default" onClick={this.handleCloseModal}>
                            Annuler
                        </button>
                        <button className="btn btn-info" onClick={this.handlePasswordRecovery}>
                            Envoyer
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalSuccess} onClose={this.handleCloseModal}>
                    <Modal.Header>
                        <h4>Recupération de mot de passe</h4>
                    </Modal.Header>
                    <Modal.Body>
                        Demande envoyée.
                        <br />
                        Nous reviendrons vers vous dans les plus brefs délais afin de vous communiquer vos identifiants.
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-info"
                            onClick={() => {
                                this.handleCloseModal();
                                this.handleEmail(this.state.login, this.state.info, this.state.phone);
                            }}
                        >
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>
                <div className={`page-inner login-page ${this.loginClass}`}>
                    <Grid fluid={true}>
                        <Row>
                            <Col sm={6} md={3} className="login-box">
                                <h4 className="login-title">Se connecter</h4>
                                <form onSubmit={this.handleFormSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="login">Identifiant:</label>
                                        <input
                                            type="text"
                                            name="login"
                                            value={this.state.login}
                                            className="form-control"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Mot de passe:</label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            className="form-control"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <p
                                            className="passForgot"
                                            onClick={() =>
                                                this.setState({
                                                    modalPassword: true
                                                })
                                            }
                                        >
                                            Mot de passe oubli&eacute;
                                        </p>
                                    </div>
                                    <button disabled={this.state.loading} className="btn btn-primary">
                                        Login
                                    </button>
                                </form>
                            </Col>
                            <img className="bordereau-icon mobile-screen" src="/bordereau-mobile.jpg" alt="" />
                            <div className="gradient"></div>
                        </Row>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default Login;
