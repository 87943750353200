import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const getTimeRange = (timeArr) => {
    if (!timeArr[0]) {
        return 'Off';
    }

    return timeArr[0] + ' - ' + timeArr[1];
};

const validateDateTime = (b, e) => {
    if (b.length !== 5 || e.length !== 5 || b[2] !== ':' || e[2] !== ':') {
        return false;
    }

    const bHrs = parseInt(b.split(':')[0]);
    const bMns = parseInt(b.split(':')[1]);

    const eHrs = parseInt(e.split(':')[0]);
    const eMns = parseInt(e.split(':')[1]);

    if (bHrs < 0 || bHrs > 23 || eHrs < 0 || eHrs > 23) {
        return false;
    }

    if (bMns < 0 || bMns > 59 || eMns < 0 || eMns > 59) {
        return false;
    }

    return true;
};

const ScreenManagementTimes = ({ onOffTimes, saveDateTimes, show, hide }) => {
    const [state, setState] = useState({
        powerOnAll: '07:00',
        powerOffAll: '23:00',
        cursorOnTime: '',
        cursorOffTime: '',
        timeSelected: -1,
        modalChangeTime: false,
        onOffTimes: onOffTimes.map((o) => [...o])
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            onOffTimes: onOffTimes.map((o) => [...o])
        }));
    }, [onOffTimes]);

    return (
        <React.Fragment>
            <Modal show={state.modalChangeTime}>
                <Modal.Header>{['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'][state.timeSelected]}</Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Allumage</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={state.cursorOnTime}
                                    placeholder="HH:MM"
                                    onChange={(e) => setState({ ...state, cursorOnTime: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Extinction</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={state.cursorOffTime}
                                    placeholder="HH:MM"
                                    onChange={(e) => setState({ ...state, cursorOffTime: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-success"
                        onClick={() =>
                            setState((prevState) => ({
                                ...prevState,
                                onOffTimes: prevState.onOffTimes.map((o, i) =>
                                    i === prevState.timeSelected ? [prevState.cursorOnTime, prevState.cursorOffTime] : o
                                ),
                                timeSelected: -1,
                                modalChangeTime: false,
                                cursorOffTime: '',
                                cursorOnTime: ''
                            }))
                        }
                        disabled={!validateDateTime(state.cursorOnTime, state.cursorOffTime)}
                    >
                        Valider
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>Horaires</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <div>
                                <div className="form-group">
                                    <label>Allumage</label>
                                    <input
                                        type="text"
                                        value={state.powerOnAll}
                                        className="form-control"
                                        onChange={(e) => setState({ ...state, powerOnAll: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <div className="form-group">
                                    <label>Extinction</label>
                                    <input
                                        type="text"
                                        value={state.powerOffAll}
                                        className="form-control"
                                        onChange={(e) => setState({ ...state, powerOffAll: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <button
                                className="btn btn-info mt-24"
                                onClick={() =>
                                    setState({
                                        ...state,
                                        onOffTimes: state.onOffTimes.map(() => [state.powerOnAll, state.powerOffAll])
                                    })
                                }
                            >
                                Appliquer à tous
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        {!state.onOffTimes || (
                            <table className="table horaires-screen-management">
                                <tbody>
                                    {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((d, i) => (
                                        <tr key={d}>
                                            <td className="day-hours">
                                                {d}:{' '}
                                                {state.timeSelected === i ? (
                                                    <div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Allumage</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={state.cursorOnTime}
                                                                    placeholder="HH:MM"
                                                                    onChange={(e) =>
                                                                        setState({
                                                                            ...state,
                                                                            cursorOnTime: e.target.value
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Extinction</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={state.cursorOffTime}
                                                                    placeholder="HH:MM"
                                                                    onChange={(e) =>
                                                                        setState({
                                                                            ...state,
                                                                            cursorOffTime: e.target.value
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    getTimeRange(state.onOffTimes[i])
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-danger"
                                                    style={{
                                                        marginRight: 10,
                                                        display: state.timeSelected !== i ? 'inherit' : 'none'
                                                    }}
                                                    onClick={() =>
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            onOffTimes: prevState.onOffTimes.map((o, idx) =>
                                                                i === idx ? ['', ''] : o
                                                            )
                                                        }))
                                                    }
                                                    disabled={state.onOffTimes[i][0] === ''}
                                                >
                                                    Off
                                                </button>
                                                {state.timeSelected !== i ? (
                                                    <button
                                                        className="btn btn-info"
                                                        onClick={() =>
                                                            setState({
                                                                ...state,
                                                                cursorOnTime: state.onOffTimes[i][0],
                                                                cursorOffTime: state.onOffTimes[i][1],
                                                                timeSelected: i
                                                            })
                                                        }
                                                    >
                                                        Edit
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={() =>
                                                            setState((prevState) => ({
                                                                ...state,
                                                                onOffTimes: prevState.onOffTimes.map((o, i) =>
                                                                    i === prevState.timeSelected
                                                                        ? [
                                                                              prevState.cursorOnTime,
                                                                              prevState.cursorOffTime
                                                                          ]
                                                                        : o
                                                                ),
                                                                timeSelected: -1,
                                                                cursorOffTime: '',
                                                                cursorOnTime: ''
                                                            }))
                                                        }
                                                        disabled={
                                                            !validateDateTime(state.cursorOnTime, state.cursorOffTime)
                                                        }
                                                    >
                                                        OK
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success" onClick={() => saveDateTimes(state.onOffTimes)}>
                        Enregistrer
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ScreenManagementTimes;
