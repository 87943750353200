import '../../Assets/templates.css';
import '../../Assets/Templates/FreeTemplates.css';

import React, { useEffect, useRef, useState } from 'react';
import TemplateList from '../../Components/TemplateList';
import { fetchApi, sendFile } from '../../Services/NetworkServcice';
import { Modal } from 'react-bootstrap';
import constants from '../../Constants';
import ReactTags from 'react-tag-autocomplete';
import FixedTemplateList from '../../Components/FixedTemplateList';
import ElementEditor from '../../Components/FreeTemplates/ElementsEditor';
import { ASSISTANTS_DATA } from '../../Components/FixedTemplates/FixedTemplatesAssistant';

const FONTS = {
    opensans: 'OpenSans',
    'museo-slab-300': 'Muséo Slab 300',
    'intermarche-bold': 'Intermarche Bold',
    'intermarche-regular': 'Intermarche Regular',
    'blog-script-u': 'Blog Script U',
    bevan: 'Bevan',
    cabinsketch: 'CabinSketch',
    helvetica: 'Helvetica',
    'museo-sans': 'Museo-Sans',
    'sans-serif': 'Sans-Serif',
    'mtf-toast': 'MTF Toast',
    RobotoCondensed: 'RobotoCondensed',
    AvenirNext: 'AvenirNext',
    AvenirNextCondensed: 'AvenirNextCondensed',
    AvenirNextItProCondensed: 'AvenirNextItProCondensed',
    AvenirNextItProHeavy: 'AvenirNextItProHeavy',
    Ashery: 'Ashery',
    AvenirBlack: 'AvenirBlack',
    Klein: 'Klein',
    ProximaNovaSB: 'ProximaNovaSB',
    ProximaNovaBold: 'ProximaNovaBold',
    ProximaNovaLight: 'ProximaNovaLight',
    SanaSansBlack: 'SanaSansBlack',
    SanaSansBook: 'SanaSansBook',
    UbuntuBold: 'UbuntuBold',
    TypoAuchan: 'TypoAuchan',
    'Gill Sans Bold Italic': 'Gill Sans Bold Italic',
    'Gill Sans Bold': 'Gill Sans Bold',
    'GillSans Condensed Bold': 'GillSans Condensed Bold',
    'GillSans Condensed': 'GillSans Condensed',
    'Gill Sans Heavy Italic': 'Gill Sans Heavy Italic',
    'Gill Sans Heavy': 'Gill Sans Heavy',
    'Gill Sans Italic': 'Gill Sans Italic',
    'Gill Sans Light Italic': 'Gill Sans Light Italic',
    'Gill Sans Light': 'Gill Sans Light',
    'Gill Sans Medium Italic': 'Gill Sans Medium Italic',
    'Gill Sans Medium': 'Gill Sans Medium'
};

const IMAGE_SELECTION_RATIOS = {
    portrait: [4, 6, 12],
    landscape: [4, 6, 12],
    led_792_192: [4, 6, 12],
    led_288_420: [4, 6, 12],
    led_288_384: [4, 6, 12],
    led_462_320: [4, 6, 12],
    led_448_340: [1, 2, 3]
};

// Util functions
const isValidImage = (width, height) => {
    if (height === 1920 && width === 1080) {
        return 'portrait';
    }

    if (width === 1920 && height === 1080) {
        return 'landscape';
    }

    if (width === 792 && height === 192) {
        return 'led_792_192';
    }

    if (width === 288 && height === 420) {
        return 'led_288_420';
    }

    if (width === 288 && height === 384) {
        return 'led_288_384';
    }

    if (width === 462 && height === 320) {
        return 'led_462_320';
    }

    if (width === 448 && height === 340) {
        return 'led_448_340';
    }

    console.log(width, height);
    return null;
};

export const getTemplateRatio = (orientation, isEditor = false, isMobile = false, isAssistant = false) => {
    let idx = 0;

    if (isEditor) {
        idx = 1;
    }

    if (isMobile) {
        idx = 2;
    }

    if (orientation in IMAGE_SELECTION_RATIOS) {
        return IMAGE_SELECTION_RATIOS[orientation][idx];
    }

    return 1;
};

// File upload menu (in modal)
const AddFileForTemplate = ({ nextStep, onClose }) => {
    const [loading, setLoading] = useState(0);

    const fileElement = useRef(null);

    const fileChange = (event) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            return alert('File type invalid');
        }

        setLoading(1);

        const fr = new FileReader();

        fr.addEventListener('load', () => {
            const image = new Image();

            image.addEventListener('load', () => {
                const orientation = isValidImage(image.width, image.height);
                if (!orientation) {
                    setLoading(0);
                    return alert('Invalid image dimensions');
                }

                const data = new FormData();

                data.append('asset', file);

                sendFile('/admin/templates/upload', {
                    method: 'POST',
                    body: data
                })
                    .then((res) => res.text())
                    .then((src) => nextStep(src, orientation));
            });

            image.src = fr.result;
        });

        fr.readAsDataURL(file);
    };

    return (
        <React.Fragment>
            <Modal.Body>
                <input
                    style={{ display: 'none' }}
                    type="file"
                    ref={fileElement}
                    accept="image/png,image/jpeg"
                    onChange={fileChange}
                />
                <div
                    className={`${loading === 1 ? 'animated faster fadeOut' : 'animated faster fadeIn'} upload`}
                    onAnimationEnd={() => {
                        if (loading === 1) {
                            setLoading(2);
                        }
                    }}
                    onClick={() => {
                        if (loading !== 0) {
                            return;
                        }

                        fileElement.current.click();
                    }}
                >
                    {loading === 2 ? <i className="menu-icon icon-spinner10 rotate"></i> : '+'}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={onClose}>
                    Quitter
                </button>
            </Modal.Footer>
        </React.Fragment>
    );
};

// Zone selection for template menu (modal)
const ZoneSelectionTemplate = ({
    filename = '',
    orientation = '',
    nextStep = (_ = {}) => null,
    onClose,
    propsCoords = {
        xStart: 0,
        yStart: 0,
        width: 100,
        height: 100
    },
    prevStep = () => null
}) => {
    const [coords, setCoords] = useState({ ...propsCoords });
    const [mouseData, setMouseData] = useState({
        triggered: false,
        resizeTrigerred: false,
        startPosX: 0,
        startPosY: 0,
        zonePosX: 0,
        zonePosY: 0,
        zoneHeight: 0,
        zoneWidth: 0
    });

    return (
        <React.Fragment>
            <Modal.Body>
                <div className={'imageSelection s' + orientation}>
                    <img
                        style={{ transform: `scale(${1 / getTemplateRatio(orientation)})` }}
                        src={`${constants.cloud_storage}/Assets/Library/Templates/${filename}`}
                        alt={`${filename}`}
                    />
                    <div
                        className="zone"
                        style={{
                            left: coords.xStart / getTemplateRatio(orientation),
                            top: coords.yStart / getTemplateRatio(orientation),
                            width: coords.width / getTemplateRatio(orientation),
                            height: coords.height / getTemplateRatio(orientation)
                        }}
                        onMouseDown={(e) => {
                            if (e.target !== e.currentTarget) {
                                return;
                            }

                            setMouseData({
                                ...mouseData,
                                triggered: true,
                                startPosX: e.clientX,
                                startPosY: e.clientY,
                                zonePosX: coords.xStart,
                                zonePosY: coords.yStart
                            });
                        }}
                        onMouseMove={(e) => {
                            if (mouseData.triggered) {
                                setCoords({
                                    ...coords,
                                    xStart: mouseData.zonePosX + 4 * (e.clientX - mouseData.startPosX),
                                    yStart: mouseData.zonePosY + 4 * (e.clientY - mouseData.startPosY)
                                });
                            }
                        }}
                        onMouseOut={() => {
                            if (!mouseData.triggered) {
                                return;
                            }

                            setMouseData({
                                ...mouseData,
                                triggered: false
                            });
                        }}
                        onMouseUp={() => {
                            if (!mouseData.triggered) {
                                return;
                            }

                            setMouseData({
                                ...mouseData,
                                triggered: false
                            });
                        }}
                    >
                        <div
                            className="resize"
                            onMouseDown={(e) => {
                                if (e.target !== e.currentTarget) {
                                    return;
                                }

                                setMouseData({
                                    ...mouseData,
                                    resizeTrigerred: true,
                                    startPosX: e.clientX,
                                    startPosY: e.clientY,
                                    zoneWidth: coords.width,
                                    zoneHeight: coords.height
                                });
                            }}
                            onMouseMove={(e) => {
                                if (mouseData.resizeTrigerred) {
                                    setCoords({
                                        ...coords,
                                        width: mouseData.zoneWidth + 4 * (e.clientX - mouseData.startPosX),
                                        height: mouseData.zoneHeight + 4 * (e.clientY - mouseData.startPosY)
                                    });
                                }
                            }}
                            onMouseOut={() => {
                                if (!mouseData.resizeTrigerred) {
                                    return;
                                }

                                setMouseData({
                                    ...mouseData,
                                    resizeTrigerred: false
                                });
                            }}
                            onMouseUp={() => {
                                if (!mouseData.resizeTrigerred) {
                                    return;
                                }

                                setMouseData({
                                    ...mouseData,
                                    resizeTrigerred: false
                                });
                            }}
                        ></div>
                        Zone de Texte
                    </div>
                </div>
                <hr />
                <div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>X</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    value={coords.xStart}
                                    onChange={(e) =>
                                        setCoords({
                                            ...coords,
                                            xStart: parseInt(e.target.value)
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Y</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    value={coords.yStart}
                                    onChange={(e) =>
                                        setCoords({
                                            ...coords,
                                            yStart: parseInt(e.target.value)
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Largeur</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    value={coords.width}
                                    onChange={(e) =>
                                        setCoords({
                                            ...coords,
                                            width: parseInt(e.target.value)
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Hauteur</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    value={coords.height}
                                    onChange={(e) =>
                                        setCoords({
                                            ...coords,
                                            height: parseInt(e.target.value)
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-info" onClick={() => prevStep()}>
                    Précédent
                </button>
                <button className="btn btn-warning" onClick={onClose}>
                    Quitter
                </button>
                <button className="btn btn-info" onClick={() => nextStep(coords)}>
                    Suivant
                </button>
            </Modal.Footer>
        </React.Fragment>
    );
};

const FreeTemplateData = ({
    onClose,
    onSave,
    onDelete,
    propsTemplate = {
        name: 'New Template',
        categoryName: '',
        allowedFonts: [...Object.keys(FONTS)],
        allowedColors: ['#ffffff', '#000000'],
        signId: [],
        defaultElements: [],
        orientation: 'portrait',
        src: '',
        xStart: 0,
        yStart: 0,
        width: 0,
        height: 0,
        allowModificationOn: [],
        assistantName: '',
        dev: false
    }
}) => {
    const [template, setTemplate] = useState({
        ...propsTemplate
    });

    const [color, setColor] = useState('#000000');

    const [signs, setSigns] = useState([]);

    const [isFixed, setFixed] = useState(false);

    useEffect(() => {
        fetchApi('/admin/signs/all')
            .then((res) => res.json())
            .then(setSigns);

        setFixed(propsTemplate.defaultElements.length > 0);
    }, [propsTemplate.defaultElements.length]);

    const onClone = (id) => {
        fetchApi(`/admin/templates/clone/${id}`, { method: 'POST' }).then(() => onClose(true));
    };

    return (
        <React.Fragment>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Enseignes</label>
                            {signs.map((s) => (
                                <div key={s.id}>
                                    <input
                                        checked={template.signId.indexOf(s.id) !== -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setTemplate({
                                                    ...template,
                                                    signId: [...template.signId, s.id],
                                                    dev: false
                                                });
                                            } else {
                                                setTemplate({
                                                    ...template,
                                                    signId: template.signId.filter((id) => id !== s.id)
                                                });
                                            }
                                        }}
                                        type="checkbox"
                                    />
                                    <span> {s.name}</span>
                                </div>
                            ))}
                            <div>
                                <input
                                    checked={template.dev}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setTemplate({
                                                ...template,
                                                signId: [],
                                                dev: true
                                            });
                                        } else {
                                            setTemplate({
                                                ...template,
                                                dev: false
                                            });
                                        }
                                    }}
                                    type="checkbox"
                                />
                                <span> Developpement</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group">
                            <label>Nom du template</label>
                            <input
                                type="text"
                                className="form-control"
                                value={template.name}
                                onChange={(e) =>
                                    setTemplate({
                                        ...template,
                                        name: e.target.value
                                    })
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label>Polices autorisées</label>
                            <ReactTags
                                tags={template.allowedFonts.map((f) => ({ name: FONTS[f], value: f }))}
                                suggestions={Object.keys(FONTS).map((f) => ({ name: FONTS[f], value: f }))}
                                minQueryLength={0}
                                maxSuggestionsLength={999}
                                handleAddition={(e) =>
                                    setTemplate({
                                        ...template,
                                        allowedFonts: [...new Set([...template.allowedFonts, e.value])]
                                    })
                                }
                                handleDelete={(id) =>
                                    setTemplate({
                                        ...template,
                                        allowedFonts: template.allowedFonts.filter((_, i) => i !== id)
                                    })
                                }
                                placeholder="Ajouter police"
                            />
                        </div>
                        <div className="form-group">
                            <label>Couleurs autorisées</label>
                            <div className="colorPalette">
                                {template.allowedColors.map((c) => (
                                    <div key={c} className="color" style={{ backgroundColor: c }}>
                                        <div
                                            className="remove"
                                            onClick={() =>
                                                setTemplate({
                                                    ...template,
                                                    allowedColors: template.allowedColors.filter((color) => color !== c)
                                                })
                                            }
                                        >
                                            <i className="fa fa-times"></i>
                                        </div>
                                    </div>
                                ))}
                                <input
                                    type="color"
                                    onChange={(e) => setColor(e.target.value)}
                                    value={color}
                                    className="color add"
                                ></input>
                                <div
                                    className="color add"
                                    onClick={() =>
                                        setTemplate({
                                            ...template,
                                            allowedColors: [...new Set([...template.allowedColors, color])]
                                        })
                                    }
                                >
                                    +
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" checked={isFixed} onChange={() => setFixed(!isFixed)} />
                            <label> Texte figé</label>
                            {!isFixed || (
                                <div>
                                    <ElementEditor
                                        baseTemplateInstance={{
                                            elements: template.defaultElements,
                                            templateId: template
                                        }}
                                        isAdmin={true}
                                        allowModificationOn={template.allowModificationOn}
                                        onChange={(elements) => {
                                            const allowModificationOn = [];

                                            elements.forEach((e, i) => {
                                                if (e.isModificationAllowed) {
                                                    allowModificationOn.push(i);
                                                }
                                            });

                                            setTemplate({
                                                ...template,
                                                defaultElements: elements,
                                                allowModificationOn
                                            });
                                        }}
                                        template={template}
                                    />
                                    <hr />
                                    <select
                                        value={template.assistantName}
                                        onChange={(e) =>
                                            setTemplate({
                                                ...template,
                                                assistantName: e.target.value
                                            })
                                        }
                                        className="form-control"
                                    >
                                        {Object.keys(ASSISTANTS_DATA).map((a) => (
                                            <option key={a} value={a}>
                                                {ASSISTANTS_DATA[a].name}
                                            </option>
                                        ))}
                                    </select>
                                    <hr />
                                    <FixedTemplateList templates={[template]} />
                                    <hr />
                                    <div>Animations:</div>
                                    {template.defaultElements.map((e, i) => {
                                        if (e.lineBreak || !e.text || e.text.trim().length === 0) {
                                            return null;
                                        }

                                        return (
                                            <div key={i} className="form-group">
                                                <label>{e.text}:</label>
                                                <div className="flex">
                                                    <select
                                                        value={e.animation}
                                                        onChange={(e) => {
                                                            const defaultElements = template.defaultElements;

                                                            if (e.target.value === '') {
                                                                delete defaultElements[i].animation;
                                                                delete defaultElements[i].animationDelay;
                                                                delete defaultElements[i].animationDuration;
                                                            } else {
                                                                defaultElements[i].animation = e.target.value;
                                                                defaultElements[i].animationDelay = 0;
                                                                defaultElements[i].animationDuration = 1000;
                                                            }

                                                            setTemplate({
                                                                ...template,
                                                                defaultElements
                                                            });
                                                        }}
                                                        className="form-control"
                                                    >
                                                        <option value="">Aucune</option>
                                                        <option value="tpFadeIn">FadeIn</option>
                                                        <option value="tpZoomFadeIn">ZoomIn</option>
                                                        <option value="tpBlinkWhite">BlinkWhite</option>
                                                        <option value="tpBlinkBlack">BlinkBlack</option>
                                                    </select>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={e.animationDelay}
                                                        onChange={(e) => {
                                                            const defaultElements = template.defaultElements;
                                                            if (!defaultElements[i].animation) {
                                                                return;
                                                            }

                                                            defaultElements[i].animationDelay = parseInt(
                                                                e.target.value
                                                            );

                                                            setTemplate({
                                                                ...template,
                                                                defaultElements
                                                            });
                                                        }}
                                                    />
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={e.animationDuration}
                                                        onChange={(e) => {
                                                            const defaultElements = template.defaultElements;
                                                            if (!defaultElements[i].animation) {
                                                                return;
                                                            }

                                                            defaultElements[i].animationDuration = parseInt(
                                                                e.target.value
                                                            );

                                                            setTemplate({
                                                                ...template,
                                                                defaultElements
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!propsTemplate._id || (
                    <button className="btn btn-danger" onClick={onDelete}>
                        Supprimer
                    </button>
                )}
                {!propsTemplate._id || (
                    <button className="btn btn-info" onClick={() => onClone(propsTemplate._id)}>
                        Dupliquer
                    </button>
                )}
                <button className="btn btn-warning" onClick={onClose}>
                    Quitter
                </button>
                <button
                    className="btn btn-success"
                    disabled={
                        (template.signId.length === 0 && !template.dev) ||
                        template.allowedColors.length === 0 ||
                        template.allowedFonts.length === 0
                    }
                    onClick={() => onSave(template)}
                >
                    Enregistrer
                </button>
            </Modal.Footer>
        </React.Fragment>
    );
};

// Modal (file uploading & template creation)
const ModalAdd = ({
    show,
    onClose,
    onAdded,
    onUpdated,
    onRemove,
    selectedTemplate = {
        name: 'New Template',
        categoryName: '',
        src: '',
        orientation: '',
        xStart: 0,
        yStart: 0,
        width: 100,
        height: 100,
        allowedFonts: [...Object.keys(FONTS)],
        allowedColors: ['#ffffff', '#000000'],
        defaultElements: [],
        allowModificationOn: [],
        signId: [],
        dev: false
    },
    used
}) => {
    const [step, setStep] = useState(0);

    const [template, setTemplate] = useState({
        ...selectedTemplate
    });

    let component = null;

    useEffect(() => {
        if (selectedTemplate !== null) {
            setTemplate({
                ...selectedTemplate
            });

            setStep(1);
        } else {
            setTemplate({
                name: 'New Template',
                categoryName: '',
                src: '',
                orientation: '',
                xStart: 0,
                yStart: 0,
                width: 100,
                height: 100,
                allowedFonts: [...Object.keys(FONTS)],
                allowedColors: ['#ffffff', '#000000'],
                defaultElements: [],
                allowModificationOn: [],
                signId: [],
                dev: false
            });

            setStep(0);
        }
    }, [selectedTemplate]);

    switch (step) {
        case 0:
            component = (
                <AddFileForTemplate
                    nextStep={(src, orientation) => {
                        setStep(step + 1);
                        setTemplate({
                            ...template,
                            src,
                            orientation
                        });
                    }}
                    onClose={onClose}
                />
            );
            break;
        case 1:
            component = (
                <ZoneSelectionTemplate
                    nextStep={(coords) => {
                        setStep(step + 1);
                        setTemplate({
                            ...template,
                            ...coords
                        });
                    }}
                    prevStep={() => setStep(0)}
                    propsCoords={{
                        xStart: template.xStart,
                        yStart: template.yStart,
                        width: template.width,
                        height: template.height
                    }}
                    onClose={onClose}
                    filename={template.src}
                    orientation={template.orientation}
                />
            );
            break;
        case 2:
            component = (
                <FreeTemplateData
                    onClose={onClose}
                    onSave={(data) => {
                        const templateObject = { ...template, ...data };

                        if (!selectedTemplate) {
                            // New object

                            fetchApi('/admin/templates', {
                                method: 'POST',
                                body: JSON.stringify(templateObject)
                            })
                                .then((res) => res.json())
                                .then(onAdded);
                        } else {
                            // Update existing object
                            fetchApi(`/admin/templates/${selectedTemplate._id}`, {
                                method: 'PUT',
                                body: JSON.stringify(templateObject)
                            }).then(() => onUpdated(templateObject));
                        }
                    }}
                    onDelete={() => {
                        if (!window.confirm('Ce template va être supprimé. Continuer?')) {
                            return;
                        }

                        if (
                            used &&
                            !window.confirm(
                                'Cette affiche sera retirée des séquences utilisant ce template. Continuer?'
                            )
                        ) {
                            return;
                        }

                        fetchApi(`/admin/templates/${selectedTemplate._id}`, {
                            method: 'DELETE'
                        }).then(() => onRemove(selectedTemplate._id));
                    }}
                    propsTemplate={{
                        _id: template._id,
                        name: template.name,
                        categoryName: template.categoryName,
                        allowedFonts: template.allowedFonts,
                        allowedColors: template.allowedColors,
                        signId: template.signId,
                        defaultElements: template.defaultElements,
                        orientation: template.orientation,
                        src: template.src,
                        xStart: template.xStart,
                        yStart: template.yStart,
                        width: template.width,
                        height: template.height,
                        allowModificationOn: template.allowModificationOn,
                        assistantName: template.assistantName,
                        dev: template.dev
                    }}
                />
            );
            break;
        default:
            break;
    }

    return (
        <Modal show={show} className="AdminFreeTemplates Modal">
            <Modal.Header>Ajouter ({step + 1} / 3)</Modal.Header>
            {component}
        </Modal>
    );
};

const Templates = () => {
    const [templates, setTemplates] = useState([]);
    const [usage, setUsage] = useState([]);
    const [modalOpened, setModalOpened] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        fetchApi('/admin/templates')
            .then((res) => res.json())
            .then(setTemplates);

        fetchApi('/admin/templates/usage')
            .then((res) => res.json())
            .then(setUsage);
    }, []);

    const classicTemplates = templates.filter(({ defaultElements }) => defaultElements.length === 0);
    const fixedTemplates = templates.filter(({ defaultElements }) => defaultElements.length > 0);

    return (
        <div className="AdminFreeTemplates panel">
            <ModalAdd
                show={modalOpened}
                onClose={(refresh = false) => {
                    setSelectedTemplate(null);
                    setModalOpened(false);

                    if (refresh) {
                        fetchApi('/admin/templates')
                            .then((res) => res.json())
                            .then(setTemplates);
                    }
                }}
                onAdded={(template) => {
                    setTemplates([...templates, template]);
                    setModalOpened(false);
                }}
                onUpdated={(template) => {
                    const tmp = [...templates];

                    const idx = tmp.findIndex((t) => t._id === template._id);

                    if (idx === -1) {
                        console.error('Could not find template #', template._id);
                        return;
                    }

                    tmp.splice(idx, 1, template);

                    setSelectedTemplate(null);
                    setModalOpened(false);
                    setTemplates(tmp);
                }}
                onRemove={(templateId) => {
                    setSelectedTemplate(null);
                    setModalOpened(false);
                    setTemplates(templates.filter(({ _id }) => _id !== templateId));
                }}
                selectedTemplate={selectedTemplate}
                used={selectedTemplate && !!usage.find(({ _id }) => _id === selectedTemplate._id)}
            />
            <div className="panel-heading template-heading">
                <h3>Templates ({classicTemplates.length})</h3>
                <button className="btn btn-info" onClick={() => setModalOpened(true)}>
                    <i className="fa fa-plus"></i>
                </button>
            </div>
            <div className="mT-20">
                <TemplateList
                    onClick={(i) => {
                        setSelectedTemplate(classicTemplates[i]);
                        setModalOpened(true);
                    }}
                    templates={classicTemplates}
                    usage={usage}
                />
            </div>
            <hr />
            <h3>Templates Fixes ({fixedTemplates.length})</h3>
            <div className="mT-20">
                <FixedTemplateList
                    onClick={(i) => {
                        setSelectedTemplate(fixedTemplates[i]);
                        setModalOpened(true);
                    }}
                    templates={fixedTemplates}
                    usage={usage}
                />
            </div>
        </div>
    );
};

export default Templates;
