import React, { useRef } from 'react';
import { Panel } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import { fetchApi } from '../../../../Services/NetworkServcice';

const FACTEUR_MULTI = 1.4;
const TICKETS_CAISSE = 1500;
const HEURES_OUVERTURE = 15;
const PRIX_BRUT_SEMAINE = 100;
const PRIX_HEURE = PRIX_BRUT_SEMAINE / 7 / 15;
const FRAIS_TECH = 0.05;
const MAJORATION_HEURE_HORS_TRANCHE = 1.4;

const HOURS_TAKEN = {
    TR_1: { coef: 1.3, nbHours: 4 },
    TR_2: { coef: 1.4, nbHours: 6 },
    TR_3: { coef: 1.5, nbHours: 5 }
};

const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const hoursSum = (planning) => {
    let visibility = 0;

    for (const day of planning) {
        visibility += day.length;
        if (day.indexOf(22) !== -1) {
            visibility--;
        }
    }

    return visibility;
};

const getScreenPrice = (day) => {
    if (day.length === 16) {
        return 100;
    }

    let price = 0;
    let nbTr = 0;
    let pickedHours = false;

    if ([7, 8, 9, 10].every((h) => day.indexOf(h) !== -1)) {
        // Tranche 1
        price += PRIX_HEURE * HOURS_TAKEN.TR_1.coef * HOURS_TAKEN.TR_1.nbHours;
        nbTr++;
    } else {
        for (let i = 7; i <= 10; i++) {
            if (day.indexOf(i) !== -1) {
                pickedHours = true;
                price += PRIX_HEURE * HOURS_TAKEN.TR_1.coef * MAJORATION_HEURE_HORS_TRANCHE;
            }
        }
    }

    if ([11, 12, 13, 14, 15, 16].every((h) => day.indexOf(h) !== -1)) {
        // Tranche 2
        price += PRIX_HEURE * HOURS_TAKEN.TR_2.coef * HOURS_TAKEN.TR_2.nbHours;
        nbTr++;
    } else {
        for (let i = 11; i <= 16; i++) {
            if (day.indexOf(i) !== -1) {
                pickedHours = true;
                price += PRIX_HEURE * HOURS_TAKEN.TR_2.coef * MAJORATION_HEURE_HORS_TRANCHE;
            }
        }
    }

    if ([17, 18, 19, 20, 21, 22].every((h) => day.indexOf(h) !== -1)) {
        // Tranche 3
        price += PRIX_HEURE * HOURS_TAKEN.TR_3.coef * HOURS_TAKEN.TR_3.nbHours;
        nbTr++;
    } else {
        for (let i = 17; i < 22; i++) {
            if (day.indexOf(i) !== -1) {
                pickedHours = true;
                price += PRIX_HEURE * HOURS_TAKEN.TR_3.coef * MAJORATION_HEURE_HORS_TRANCHE;
            }
        }
    }

    // Special case: 2 tr <= 85
    if (!pickedHours && nbTr === 2) {
        return 85;
    }

    return price * 7;
};

const Pricing = (props) => {
    const prix_brut = Math.ceil(getScreenPrice(props.planning[0]) * props.selected.length) * props.slots;
    const frais_tech = Math.max(250, Math.ceil(prix_brut * FRAIS_TECH));
    const prix_brut_unit = Math.ceil(getScreenPrice(props.planning[0])) * props.slots;

    const revenueInputRef = useRef(null);

    return (
        <Panel>
            <Panel.Heading>
                <h4>Pricing Hebdomadaire</h4>
            </Panel.Heading>
            <Panel.Body>
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <th>Titre</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Audience potentielle</td>
                            <td>
                                {numberWithSpaces(
                                    hoursSum(props.planning) *
                                        ((TICKETS_CAISSE * FACTEUR_MULTI) / HEURES_OUVERTURE) *
                                        props.selected.length
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Nombre de diffusion du spot</td>
                            <td>
                                {numberWithSpaces(hoursSum(props.planning) * 60 * props.slots * props.selected.length)}
                            </td>
                        </tr>
                        <tr>
                            <td>Prix brut / ecran</td>
                            <td>{prix_brut_unit} &euro;</td>
                        </tr>
                        <tr>
                            <td>Prix brut</td>
                            <td>{numberWithSpaces(prix_brut)} &euro;</td>
                        </tr>
                        <tr>
                            <td>Frais techniques</td>
                            <td>{numberWithSpaces(frais_tech)} &euro;</td>
                        </tr>
                        <tr>
                            <td>Total brut</td>
                            <td>{numberWithSpaces(prix_brut + frais_tech)} &euro;</td>
                        </tr>
                    </tbody>
                </table>
                {!props._id || (
                    <React.Fragment>
                        <hr />
                        <div className="form-group">
                            <label>Prix final (revenue)</label>
                            <div className="flex">
                                <input
                                    ref={revenueInputRef}
                                    defaultValue={props.revenue}
                                    type="number"
                                    className="form-control"
                                />
                                <button
                                    className="ml-10 btn btn-success"
                                    onClick={() => {
                                        const value = parseFloat(revenueInputRef.current.value);
                                        if (isNaN(value))
                                            return notify.show('Cette valeur doit être numérique', 'error');

                                        fetchApi(`/campaigns/${props._id}/revenue`, {
                                            method: 'PATCH',
                                            body: JSON.stringify({ revenue: value })
                                        }).then((res) => {
                                            if (res.status === 200) notify.show('OK', 'success');
                                            else notify.show('An error occured', 'error');
                                        });
                                    }}
                                >
                                    Appliquer
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </Panel.Body>
        </Panel>
    );
};

export default Pricing;
