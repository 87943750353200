import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

const getLastConnection = (date) => {
    if (date.getTime() === 0) {
        return 'Date inconnue';
    }

    return dateFormat(date, 'dd/mm à HH:MM');
};

const HeaderNotifications = (props) => {
    const [state, setState] = useState({ open: false, modalScreensOpen: false });

    const data = {
        ...{ newAssets: 0, messages: [], screens: [] },
        ...props
    };

    return (
        <div className="notification-container">
            <div
                className="offline-screens-modal"
                style={{ display: state.modalScreensOpen ? 'block' : 'none' }}
                onClick={(e) => e.target !== e.currentTarget || setState({ ...state, modalScreensOpen: false })}
            >
                <div className="modal-screens">
                    <h4 className="title">
                        Écrans déconnectés
                        <div className="close-header" onClick={() => setState({ ...state, modalScreensOpen: false })}>
                            <i className="fa fa-times"></i>
                        </div>
                    </h4>
                    <p className="description">Certains de vos écrans semblent déconnectés du réseau :</p>
                    <div className="screens-offline">
                        {data.screens
                            .filter(({ online }) => !online)
                            .map((s) => (
                                <p key={s.id}>
                                    - <strong>{s.name}</strong>: dernière connexion aux serveurs DoohYouLike le:{' '}
                                    <strong>{getLastConnection(new Date(s.lastUpdate))}</strong>
                                </p>
                            ))}
                    </div>
                    <p className="mt-16">
                        N’hésitez pas à contacter Camille au{' '}
                        <a href="tel:+33609757909" style={{ color: 'inherit', fontWeight: 'bold' }}>
                            06.31.59.61.53
                        </a>
                    </p>
                </div>
            </div>
            <div className="bar">
                <div className={data.messages.length !== 0 ? 'active' : ''}>
                    <Link to="/contact">
                        <i className="fa fa-envelope"></i>
                        {data.messages.length}
                        <span className="hidden-xs">
                            {' '}
                            nouveau{data.messages.length > 1 ? 'x' : ''} message{data.messages.length > 1 ? 's' : ''}
                        </span>
                    </Link>
                </div>
                <div className={data.newAssets !== 0 ? 'active' : ''}>
                    <Link to="/assets">
                        <i className="fa fa-images"></i>
                        {data.newAssets}
                        <span className="hidden-xs"> nouvelles affiches</span>
                    </Link>
                </div>
                <div
                    className={
                        data.screens.filter(({ online }) => !online).length !== 0 ? 'offline-screens active danger' : ''
                    }
                    onClick={() =>
                        data.screens.filter(({ online }) => !online).length !== 0 &&
                        setState({ ...state, modalScreensOpen: true })
                    }
                >
                    <i className="fa fa-exclamation-triangle"></i>
                    {data.screens.filter(({ online }) => !online).length}
                    <span className="hidden-xs">
                        {' '}
                        écran{data.screens.filter(({ online }) => !online).length > 1 ? 's' : ''} déco.
                    </span>
                </div>
            </div>
            <div style={{ display: 'none' }} className="notif-button" onClick={() => setState({ open: !state.open })}>
                <i className="fa fa-bell"></i>
            </div>
            {!state.open || (
                <div className="notification-card">
                    <span className="none">Pas de notifications</span>
                </div>
            )}
        </div>
    );
};

export default HeaderNotifications;
