import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import constants from '../../../../Constants';

const Creative = (props) => (
    <div className="Creative" onDragOver={props.onDragOver}>
        <div draggable onDragStart={props.onDragStart} onDragEnd={props.onDragEnd}>
            {props.type === 'video' ? (
                <video autoPlay playsInline muted loop data-id={props._id}>
                    <source src={`${constants.cloud_storage}/Campaigns/${props.filename}`} />
                </video>
            ) : (
                <img src="" alt="preview" />
            )}
            <div className="controls">
                <button className="btn btn-danger btn-rounded" onClick={() => props.handleRemoveCreative(props._id)}>
                    <i className="fa fa-unlink" />
                </button>
            </div>
        </div>
    </div>
);

export default (props) => {
    const [draggedItem, setDraggedItem] = useState(null);

    const onDragStart = (e, index) => {
        setDraggedItem(props.creatives[index]);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.firstChild);
        e.dataTransfer.setDragImage(e.target.firstChild, 20, 20);
    };

    const onDragEnd = () => {
        setDraggedItem(null);
    };

    const onDragOver = (index) => {
        const draggedOverItem = props.creatives[index];

        if (draggedItem === null) {
            return;
        }

        if (draggedItem === draggedOverItem) {
            return;
        }

        const creatives = props.creatives.filter((item) => item !== draggedItem);
        creatives.splice(index, 0, draggedItem);

        props.updateCreatives(creatives);
    };

    return (
        <Row className="Creatives">
            <Col md={12}>
                {props.creatives.map((c, i) => (
                    <Creative
                        key={c._id}
                        {...c}
                        handleRemoveCreative={props.handleRemoveCreative}
                        onDragStart={(e) => onDragStart(e, i)}
                        onDragEnd={onDragEnd}
                        onDragOver={() => onDragOver(i)}
                    />
                ))}
                {Array(props.loading).fill().map((_, i) => (
                    <div key={i} className="AddCreative">
                        <i className="fa fa-spinner rotate" />
                    </div>
                ))}
                {!!props.loading || <div
                    className="AddCreative"
                    onClick={() => {
                        if (props.loading) {
                            return;
                        }

                        props.handleFileClick();
                    }}
                >
                    <i className="fa fa-plus" />
                </div>}
            </Col>
        </Row>
    );
};
