import React from 'react';
import { Row, Col, Panel } from 'react-bootstrap';
import { fetchApi } from '../../Services/NetworkServcice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Agencies extends React.Component {
    constructor() {
        super();

        this.state = {
            // Data
            agencies: [],
            selected: 'new',
            selectedContact: 'new',

            // Agency
            name: '',
            type: 'agency',

            // Name
            cName: '',
            cPhone: '',
            cEmail: ''
        };

        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleAddAgency = this.handleAddAgency.bind(this);
        this.handleUpdateAgency = this.handleUpdateAgency.bind(this);

        this.handleAddContact = this.handleAddContact.bind(this);
        this.handleUpdateContact = this.handleUpdateContact.bind(this);
        this.handleDeleteContact = this.handleDeleteContact.bind(this);
    }

    async getData() {
        const res = await fetchApi('/campaigns/agencies');
        const agencies = await res.json();

        const ags = agencies.sort((a, b) => a.name.localeCompare(b.name));

        return new Promise((resolve) => {
            this.setState({ agencies: ags }, () => {
                resolve(ags);
            });
        });
    }

    componentDidMount() {
        this.getData();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });

        if (e.target.name === 'selected') {
            if (e.target.value === 'new') {
                this.setState({ name: '', type: 'agency' });
            } else {
                const { name, type } = this.state.agencies.find((x) => x._id === e.target.value);
                this.setState({ name, type });
            }
        }

        if (e.target.name === 'selectedContact') {
            if (e.target.value === 'new') {
                this.setState({ cName: '', cEmail: '', cPhone: '' });
            } else {
                const {
                    name: cName,
                    email: cEmail,
                    phone: cPhone
                } = this.state.agencies
                    .find((x) => x._id === this.state.selected)
                    .contacts.find((x) => x._id === e.target.value);
                this.setState({ cName, cEmail, cPhone });
            }
        }
    }

    handleAddAgency() {
        fetchApi('/campaigns/agencies', {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.name,
                type: this.state.type
            })
        }).then(async (id) => {
            toast.success('Agency added !');
            await this.getData();
            const s_id = await id.text();
            this.setState({ selected: s_id, selectedContact: 'new', cName: '', cPhone: '', cEmail: '' });
        });
    }

    handleUpdateAgency() {
        fetchApi(`/campaigns/agencies/${this.state.selected}`, {
            method: 'PUT',
            body: JSON.stringify({
                name: this.state.name,
                type: this.state.type
            })
        }).then(() => {
            toast.success('Agency updated !');
            this.getData();
        });
    }

    handleAddContact() {
        fetchApi('/campaigns/agencies/contact', {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.cName,
                phone: this.state.cPhone,
                email: this.state.cEmail,
                id: this.state.selected
            })
        }).then(() => {
            toast.success('Contact added !');
            this.setState({ cName: '', cPhone: '', cEmail: '' });
            this.getData();
        });
    }

    handleUpdateContact() {
        fetchApi('/campaigns/agencies/contact/' + this.state.selectedContact, {
            method: 'PUT',
            body: JSON.stringify({
                name: this.state.cName,
                phone: this.state.cPhone,
                email: this.state.cEmail,
                id: this.state.selected
            })
        }).then(() => {
            toast.success('Contact updated !');
            this.getData();
        });
    }

    handleDeleteContact() {
        fetchApi('/campaigns/agencies/contact/' + this.state.selectedContact, {
            method: 'DELETE',
            body: JSON.stringify({
                id: this.state.selected
            })
        }).then(() => {
            this.setState({
                cName: '',
                cPhone: '',
                cEmail: '',
                selectedContact: 'new'
            });
            this.getData();
            toast.success('Contact deleted !');
        });
    }

    getContacts() {
        if (this.state.selected !== 'new') {
            return this.state.agencies
                .find(({ _id }) => _id === this.state.selected)
                .contacts.map((c) => (
                    <option key={c._id} value={c._id}>
                        {c.name}
                    </option>
                ));
        }
    }

    render() {
        return (
            <div className="Agencies">
                <ToastContainer autoClose={1500} hideProgressBar={true} />
                <Row>
                    <Col sm={3}>
                        <Panel>
                            <div className="form-group">
                                <select
                                    value={this.state.selected}
                                    name="selected"
                                    className="form-control"
                                    onChange={this.handleChange}
                                >
                                    <option value="new">Nouvel annonceur</option>
                                    {this.state.agencies.map((a) => (
                                        <option key={a._id} value={a._id}>
                                            {a.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Panel>
                    </Col>
                    <Col sm={9}>
                        <Panel>
                            <div className="form-group">
                                <label htmlFor="name">Nom</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="type">Type</label>
                                <select
                                    onChange={this.handleChange}
                                    value={this.state.type}
                                    name="type"
                                    className="form-control"
                                >
                                    <option value="agency">Agence</option>
                                    <option value="advertiser">Annonceur</option>
                                </select>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                {this.state.selected !== 'new' ? (
                                    <button className="btn btn-info" onClick={this.handleUpdateAgency}>
                                        Modifier
                                    </button>
                                ) : (
                                    <button className="btn btn-info" onClick={this.handleAddAgency}>
                                        Ajouter
                                    </button>
                                )}
                            </div>
                        </Panel>
                        {this.state.selected !== 'new' ? (
                            <Panel>
                                <Row>
                                    <Col sm={3}>
                                        <div className="form-group">
                                            <select
                                                name="selectedContact"
                                                className="form-control"
                                                onChange={this.handleChange}
                                            >
                                                <option value="new">Nouveau contact</option>
                                                {this.getContacts()}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={9}>
                                        <div className="form-group">
                                            <label htmlFor="name">Nom</label>
                                            <input
                                                type="text"
                                                name="cName"
                                                autoComplete="off"
                                                className="form-control"
                                                value={this.state.cName}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone">Tel</label>
                                            <input
                                                type="text"
                                                name="cPhone"
                                                autoComplete="off"
                                                className="form-control"
                                                value={this.state.cPhone}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="text"
                                                name="cEmail"
                                                autoComplete="off"
                                                className="form-control"
                                                value={this.state.cEmail}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            {this.state.selectedContact !== 'new' ? (
                                                <div>
                                                    <button className="btn btn-info" onClick={this.handleUpdateContact}>
                                                        Modifier
                                                    </button>
                                                    <button
                                                        className="btn btn-danger right-btn"
                                                        onClick={this.handleDeleteContact}
                                                    >
                                                        Supprimer
                                                    </button>
                                                </div>
                                            ) : (
                                                <button className="btn btn-info" onClick={this.handleAddContact}>
                                                    Ajouter
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Panel>
                        ) : (
                            true
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}
