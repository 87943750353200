import React from 'react';
import PreviewAsset from '../PreviewAsset';
import dateFormat from 'dateformat';
import PreviewFruit from '../PreviewFruit';
import PreviewBakery from '../PreviewBakery';
import PreviewButchery from '../PreviewButchery';
import PreviewFishShop from '../PreviewFishShop';
import PreviewCheese from '../PreviewCheese';
import PreviewTemplate from '../FreeTemplates/PreviewTemplate';
import PreviewDelicatessen from '../PreviewDelicatessen';
import PreviewCaterer from '../PreviewCaterer';
import { PreviewWidget } from '../Widgets/WidgetsListModal';
import { PreviewDynamic } from '../DynamicHtml';
import PreviewProduct from '../PreviewProduct';

const Slide = (props) => {
    if (!props.creative) {
        return null;
    }

    let preview = (
        <PreviewAsset
            fromSlide={true}
            assets={[props.creative]}
            orientation={props.orientation}
            selected={props.creative._id}
            selectedSigns={props.selectedSigns}
        />
    );

    switch (props.creaType) {
        case 'Fruit':
            preview = <PreviewFruit {...props.creative} orientation={props.orientation} />;
            break;
        case 'Bakery':
            preview = <PreviewBakery {...props.creative} orientation={props.orientation} />;
            break;
        case 'Butchery':
            preview = <PreviewButchery {...props.creative} orientation={props.orientation} />;
            break;
        case 'FishShop':
            preview = <PreviewFishShop {...props.creative} orientation={props.orientation} />;
            break;
        case 'Cheese':
            preview = <PreviewCheese {...props.creative} orientation={props.orientation} />;
            break;
        case 'Delicatessen':
            preview = <PreviewDelicatessen {...props.creative} orientation={props.orientation} />;
            break;
        case 'Caterer':
            preview = <PreviewCaterer {...props.creative} orientation={props.orientation} />;
            break;
        case 'Product':
            preview = <PreviewProduct {...props.creative} orientation={props.orientation} />;
            break;
        case 'Template':
        case 'FreeTemplateInstance':
            preview = (
                <PreviewTemplate template={props.creative.templateId} elements={props.creative.elements} isEditor />
            );
            break;
        case 'Widget':
        case 'RSS':
            preview = <PreviewWidget {...props.creative} orientation={props.orientation} />;
            break;
        case 'DynamicHtml':
            preview = <PreviewDynamic {...props.creative} orientation={props.orientation} />;
            break;
        default:
            break;
    }

    const hasExpirationDate = (exp_date) => {
        if (!exp_date) {
            return false;
        }

        const future5Yrs = new Date();
        future5Yrs.setFullYear(future5Yrs.getFullYear() + 5);
        const date = new Date(exp_date);

        if (isNaN(date.getTime()) || date.getTime() === 0) {
            // Invalid date
            return false;
        }

        if (date.getTime() >= future5Yrs.getTime()) {
            return false;
        }

        return true;
    };

    function onClickSlide() {
        if (props.creaType === 'UserCreative' || props.creaType === 'Widget' || props.creaType === 'RSS') {
            if (props.creaType === 'Widget' && props.creative?.type === 'HotelPrices') {
                props.onClick();
            } else {
                props.onExpirationClick();
            }
        } else {
            props.onClick();
        }
    }

    return (
        <div className="SlideContainer" onDragOver={props.onDragOver}>
            <div
                draggable={props.editable && props.draggable}
                onDragStart={props.onDragStart}
                onDragEnd={props.onDragEnd}
            >
                {!props.red || <span style={{ fontSize: 9 }}>Affiche {props.red}</span>}
                {!props.editable || (
                    <div className="delete" onClick={props.onDelete}>
                        <i className="fa fa-times"></i>
                    </div>
                )}
                {!props.editable || !props.showDuplicateButton || (
                    <div className="duplicate" onClick={props.onDuplicate}>
                        <i className="fa fa-clone"></i>
                    </div>
                )}
                <div
                    onClick={onClickSlide}
                    className={
                        'Slide or' +
                        props.orientation +
                        (props.smallAdm ? ' adm' : '') +
                        (props.startDate && new Date(props.startDate) > new Date() ? ' future' : '') +
                        ` ${props.creaType}`
                    }
                >
                    {preview}
                </div>
                <div className="SlideBottom">
                    <div>
                        {!props.allowSlidesDurationChange || !props.duration || (
                            <p style={{ cursor: 'pointer' }} onClick={props.onExpirationClick}>
                                Durée: {props.duration}s
                            </p>
                        )}
                        {!(props.expirationDate || props.startDate) ||
                            [
                                {
                                    date: props.startDate,
                                    label: 'Dém. le ',
                                    dateMask: 'dd/mm à HH:MM',
                                    show:
                                        !!props.startDate && new Date(props.startDate).getTime() > new Date().getTime(),
                                },
                                {
                                    date: props.expirationDate,
                                    label: 'Exp. le ',
                                    dateMask: 'dd/mm à HH:MM',
                                    show:
                                        !!props.expirationDate &&
                                        new Date(props.expirationDate).getTime() < new Date().getTime() + 31556952000, // 1 year
                                },
                            ]
                                .filter((d) => !!d.show)
                                .map((d) => (
                                    <p onClick={props.onExpirationClick} key={d.label} style={{ cursor: 'pointer' }}>
                                        {d.label} {dateFormat(d.date, d.dateMask)}
                                    </p>
                                ))}
                        {hasExpirationDate(props.expirationDate) || (
                            <p
                                style={{
                                    display: props.assistant || props.smallAdm ? 'none' : 'inherit',
                                    cursor: 'pointer',
                                }}
                                className="addExpDate"
                                onClick={props.onExpirationClick}
                            >
                                <i className="fa fa-plus"></i>Programmer diffusion
                            </p>
                        )}
                    </div>
                    {hasExpirationDate(props.expirationDate) && (
                        <i onClick={props.onExpirationClick} className="fa fa-clock"></i>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Slide;
