import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { notify } from 'react-notify-toast';
import { AssetListBlock, AssetsSidebarBlock } from '../../Pages/AssetsV2';
import { getAllowedVariables } from '../../Services/EditorAuthorizations';
import { fetchApi } from '../../Services/NetworkServcice';
import Bakery from '../Bakery';
import Butchery from '../Butchery';
import Caterer from '../Caterer';
import Cheese from '../Cheese';
import Delicatessen from '../Delicatessen';
import { DynamicListModal } from '../DynamicHtml';
import FishShop from '../FishShop';
import FreeTemplateModal, { FixedTemplateModal } from '../FreeTemplates/FreeTemplateAssistant';
import FruitsAssistant from '../FruitsAssistant';
import Product from '../Product';
import { WidgetsListModal } from '../Widgets/WidgetsListModal';

const AssetsBlock = ({ onCreaSelect, categories, assets, signAssets, onMultipleCreaSelect, orientation }) => {
    const [state, setState] = useState({
        modalFilterVisible: false,
        selectedCreas: [],
    });

    const getAssetById = (id) => {
        let asset = assets.find(({ _id }) => _id === id);

        if (asset) {
            return asset;
        }

        asset = signAssets.find(({ _id }) => _id === id);

        if (asset) {
            return asset;
        }

        for (const c of categories) {
            asset = c.assets.find(({ _id }) => _id === id);

            if (asset) {
                if (c.expirationDate && !asset.defaultExpiration) {
                    asset.defaultExpiration = c.expirationDate;
                }

                if (c.visibleDate && !asset.defaultStart) {
                    asset.startDate = c.visibleDate;
                } else if (asset.defaultStart) {
                    asset.startDate = asset.defaultStart;
                }

                return asset;
            }
        }

        return null;
    };

    return (
        <div className="editor-assets">
            <div className={state.modalFilterVisible ? 'asset-side' : 'hidden-xs'}>
                <AssetsSidebarBlock
                    assetNb={assets.length}
                    categories={categories}
                    closeModal={() => setState({ ...state, modalFilterVisible: false })}
                />
                <div className="btn-close" onClick={() => setState({ ...state, modalFilterVisible: false })}>
                    <i className="fa fa-times"></i>
                </div>
                <div className="card selection modal-selection">
                    <h4>Sélection</h4>
                    <p>
                        {state.selectedCreas.length} élément
                        {state.selectedCreas.length === 1 || 's'} sélectionné
                        {state.selectedCreas.length === 1 || 's'}
                    </p>
                    <div className="buttons">
                        <button
                            disabled={state.selectedCreas.length === 0}
                            className="btn-dyl"
                            onClick={() => {
                                const assets = [];

                                for (const id of state.selectedCreas) {
                                    const asset = getAssetById(id);

                                    if (asset) {
                                        assets.push(asset);
                                    }
                                }

                                onMultipleCreaSelect(assets);
                            }}
                        >
                            <i className="fa fa-plus"></i>
                            Ajouter à la séquence
                        </button>
                    </div>
                </div>
            </div>
            <div className="mobile-controls">
                {state.selectedCreas.length < 2 || (
                    <div
                        className="button-add-assets"
                        onClick={() => {
                            const assets = [];

                            for (const id of state.selectedCreas) {
                                const asset = getAssetById(id);

                                if (asset) {
                                    assets.push(asset);
                                }
                            }

                            onMultipleCreaSelect(assets);
                        }}
                    >
                        <i className="fa fa-plus"></i>
                    </div>
                )}
                <div className="btn-add" onClick={() => setState({ ...state, modalFilterVisible: true })}>
                    <i className="fa fa-filter"></i>
                </div>
            </div>
            <div className="assets">
                <div className="card" id="home-editor-card">
                    <AssetListBlock
                        categories={categories}
                        magAssets={assets}
                        signAssets={signAssets}
                        selectable={true}
                        previewable={true}
                        onCreaSelect={(selectedCreas) => setState({ ...state, selectedCreas })}
                        onCreaClick={onCreaSelect}
                        selectedCreas={state.selectedCreas}
                        orientation={orientation === 'portrait_direct' ? 'portrait' : orientation}
                    />
                </div>
            </div>
        </div>
    );
};

const TemplatesRayonsBlock = ({
    orientation,
    slide,
    onSave,
    modalOpen,
    templateName,
    userObject,
    clickIndex,
    isSubsequence,
    allowSlidesDurationChange,
    maxDuration,
}) => {
    const [state, setState] = useState({
        loaded: false,
        selectedAssistant: window.innerWidth > 760 ? 0 : -1,
        clickIndex: -1,
    });

    const fruitsAssistant = useRef(null);
    const bakeryAssistant = useRef(null);
    const butcheryAssistant = useRef(null);
    const fishShopAssistant = useRef(null);
    const cheeseAssistant = useRef(null);
    const delicatessenAssistant = useRef(null);
    const catererAssistant = useRef(null);
    const productsAssistant = useRef(null);

    useEffect(() => {
        if (!modalOpen) {
            fruitsAssistant.current.reset();
            bakeryAssistant.current.reset();
            butcheryAssistant.current.reset();
            fishShopAssistant.current.reset();
            cheeseAssistant.current.reset();
            delicatessenAssistant.current.reset();
            catererAssistant.current.reset();
            productsAssistant.current.reset();

            if (window.innerWidth <= 760) {
                setState((prevState) => ({ ...prevState, selectedAssistant: -1, clickIndex }));
            }

            return;
        } else if (clickIndex !== state.clickIndex && window.innerWidth <= 760) {
            setState((prevState) => ({ ...prevState, selectedAssistant: -1, clickIndex }));
        }

        if (slide === null) {
            return;
        }

        if (slide.creaType === 'Fruit') {
            fruitsAssistant.current.setSlide(slide.creative, null, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 0 }));
        } else if (slide.creaType === 'Bakery') {
            bakeryAssistant.current.setSlide(slide.creative, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 2 }));
        } else if (slide.creaType === 'Butchery') {
            butcheryAssistant.current.setSlide(slide.creative, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 1 }));
        } else if (slide.creaType === 'FishShop') {
            fishShopAssistant.current.setSlide(slide.creative, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 4 }));
        } else if (slide.creaType === 'Cheese') {
            cheeseAssistant.current.setSlide(slide.creative, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 3 }));
        } else if (slide.creaType === 'Delicatessen') {
            delicatessenAssistant.current.setSlide(slide.creative, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 5 }));
        } else if (slide.creaType === 'Caterer') {
            catererAssistant.current.setSlide(slide.creative, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 6 }));
        } else if (slide.creaType === 'Product') {
            productsAssistant.current.setSlide(slide.creative, slide.duration || 5);
            setState((state) => ({ ...state, selectedAssistant: 7 }));
        }
    }, [slide, modalOpen, clickIndex, state.clickIndex]);

    const {
        allowBakeryEditor,
        allowButcheryEditor,
        allowCheeseEditor,
        allowFishShop,
        allowDelicatessen,
        allowCaterer,
        allowProducts,
    } = getAllowedVariables(userObject, isSubsequence, orientation);

    return (
        <div className="editor-assets">
            <div
                className="card home-editor-cards"
                style={{ display: state.selectedAssistant === -1 || window.innerWidth > 760 ? 'initial' : 'none' }}
            >
                <h4>Choisissez un rayon</h4>
                <div className="category manual">
                    <div
                        onClick={() => setState({ ...state, selectedAssistant: 0 })}
                        className={'cat rayon' + (state.selectedAssistant === 0 ? ' active' : '')}
                    >
                        <img src="/templates/fruits-legumes.svg" alt="Fruits et légumes" />
                        Fruits & Légumes
                    </div>
                </div>
                {!allowButcheryEditor || (
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: 1 })}
                            className={'cat rayon' + (state.selectedAssistant === 1 ? ' active' : '')}
                        >
                            <img src="/templates/boucherie.svg" alt="Boucherie" />
                            Boucherie
                        </div>
                    </div>
                )}
                {!allowBakeryEditor || (
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: 2 })}
                            className={'cat rayon' + (state.selectedAssistant === 2 ? ' active' : '')}
                        >
                            <img src="/templates/boulangerie.svg" alt="Boulangerie" />
                            Boulangerie
                        </div>
                    </div>
                )}
                {!allowCheeseEditor || (
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: 3 })}
                            className={'cat rayon' + (state.selectedAssistant === 3 ? ' active' : '')}
                        >
                            <img src="/templates/fromagerie.svg" alt="Fromage" />
                            Fromagerie
                        </div>
                    </div>
                )}
                {!allowFishShop || (
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: 4 })}
                            className={'cat rayon' + (state.selectedAssistant === 4 ? ' active' : '')}
                        >
                            <img src="/templates/poissonnerie.svg" alt="poissonerie" />
                            Poissonnerie
                        </div>
                    </div>
                )}
                {!allowDelicatessen || (
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: 5 })}
                            className={'cat rayon' + (state.selectedAssistant === 5 ? ' active' : '')}
                        >
                            <img src="/templates/charcuterie.svg" alt="charcuterie" className="delicatessen" />
                            Charcuterie
                        </div>
                    </div>
                )}
                {!allowCaterer || (
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: 6 })}
                            className={'cat rayon' + (state.selectedAssistant === 6 ? ' active' : '')}
                        >
                            <img src="/templates/caterer.svg" alt="charcuterie" className="delicatessen" />
                            Traiteur
                        </div>
                    </div>
                )}
                {!allowProducts || (
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: 7 })}
                            className={'cat rayon' + (state.selectedAssistant === 7 ? ' active' : '')}
                        >
                            <img src="/templates/products.svg" alt="produits" className="delicatessen" />
                            Produits
                        </div>
                    </div>
                )}
            </div>
            <div className="editor-window">
                <div className="card" style={{ visibility: state.selectedAssistant === -1 ? 'hidden' : 'visible' }}>
                    <div className={state.selectedAssistant === 0 ? 'visible' : 'hidden'}>
                        <FruitsAssistant
                            ref={fruitsAssistant}
                            orientation={orientation}
                            newTemplate={true}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                            shopId={userObject.role.Shop?.id}
                        />
                    </div>
                    <div className={state.selectedAssistant === 1 ? 'visible' : 'hidden'}>
                        <Butchery
                            ref={butcheryAssistant}
                            orientation={orientation}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                        />
                    </div>
                    <div className={state.selectedAssistant === 2 ? 'visible' : 'hidden'}>
                        <Bakery
                            ref={bakeryAssistant}
                            orientation={orientation}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                        />
                    </div>
                    <div className={state.selectedAssistant === 3 ? 'visible' : 'hidden'}>
                        <Cheese
                            ref={cheeseAssistant}
                            orientation={orientation}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                        />
                    </div>
                    <div className={state.selectedAssistant === 4 ? 'visible' : 'hidden'}>
                        <FishShop
                            ref={fishShopAssistant}
                            orientation={orientation}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                        />
                    </div>
                    <div className={state.selectedAssistant === 5 ? 'visible' : 'hidden'}>
                        <Delicatessen
                            ref={delicatessenAssistant}
                            orientation={orientation}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                        />
                    </div>
                    <div className={state.selectedAssistant === 6 ? 'visible' : 'hidden'}>
                        <Caterer
                            ref={catererAssistant}
                            orientation={orientation}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                        />
                    </div>
                    <div className={state.selectedAssistant === 7 ? 'visible' : 'hidden'}>
                        <Product
                            ref={productsAssistant}
                            orientation={orientation}
                            handleSaveCrea={onSave}
                            templateName={templateName}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                            signId={userObject.role.Shop?.SignId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const FixedTemplatesBlock = ({ templates, onSave, slide, modalOpen, clickIndex }) => {
    const [state, setState] = useState({
        selectedAssistant: window.innerWidth > 760 ? ['hoursMinsFlex'] : [null],
    });

    let templateInstance = null;

    if (slide !== null && ['Template', 'FreeTemplateInstance'].includes(slide.creaType)) {
        templateInstance = slide.creative;
    }

    useEffect(() => {
        if (slide !== null && ['Template', 'FreeTemplateInstance'].includes(slide.creaType)) {
            setState((prevState) => ({ ...prevState, selectedAssistant: [slide.creative.templateId.assistantName] }));
        } else if (
            window.innerWidth <= 760 &&
            (slide === null || ['Template', 'FreeTemplateInstance'].includes(slide.creaType))
        ) {
            setState((prevState) => ({ ...prevState, selectedAssistant: [null] }));
        }
    }, [modalOpen, clickIndex, slide]);

    return (
        <div className="editor-assets">
            <div className="fixed-templates-hours">
                <div
                    className="card home-editor-cards"
                    style={{
                        display: state.selectedAssistant[0] === null || window.innerWidth > 760 ? 'block' : 'none',
                    }}
                >
                    <h4>Choisissez un type</h4>
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: ['hoursMinsFlex'] })}
                            className={'cat rayon' + (state.selectedAssistant[0] === 'hoursMinsFlex' ? ' active' : '')}
                        >
                            <i className="fa fa-arrow-right"></i>Ouverture/Fermeture quotidienne
                        </div>
                    </div>
                    <div className="category manual">
                        <div
                            onClick={() =>
                                setState({
                                    ...state,
                                    selectedAssistant: [
                                        'openCloseHours',
                                        'openCloseHoursFixed',
                                        'openCloseHoursFixedNoDate',
                                    ],
                                })
                            }
                            className={'cat rayon' + (state.selectedAssistant[0] === 'openCloseHours' ? ' active' : '')}
                        >
                            <i className="fa fa-arrow-right"></i>Horaires/ouverture exceptionnelle
                        </div>
                    </div>
                    <div className="category manual">
                        <div
                            onClick={() => setState({ ...state, selectedAssistant: ['date'] })}
                            className={'cat rayon' + (state.selectedAssistant[0] === 'date' ? ' active' : '')}
                        >
                            <i className="fa fa-arrow-right"></i>Fermeture exceptionnelle
                        </div>
                    </div>
                </div>
            </div>
            <div className="editor-window">
                <div
                    className="card"
                    style={{ visibility: state.selectedAssistant[0] === null ? 'hidden' : 'visible' }}
                >
                    <FixedTemplateModal
                        selectedTemplate={templateInstance}
                        templates={templates}
                        assistant={state.selectedAssistant}
                        onSave={onSave}
                    />
                </div>
            </div>
        </div>
    );
};

const OtherBlock = ({
    templates,
    onSave,
    slide,
    orientation_,
    clickIndex,
    onSaveWidget,
    allowTemplates,
    allowWidgets,
    allowDynamicHtml,
    allowHotelPrices,
    dynamics,
    onSaveDynamic,
    hotelPricesSlide,
}) => {
    const [state, setState] = useState({
        selectedMenu: window.innerWidth > 760 ? 0 : -1,
    });

    let templateInstance = null;

    if (slide && ['Template', 'FreeTemplateInstance'].includes(slide.creaType)) {
        templateInstance = slide.creative;
    }

    useEffect(() => {
        if (window.innerWidth <= 760) {
            setState((prevState) => ({ ...prevState, selectedMenu: -1 }));
        }
    }, [clickIndex]);

    useEffect(() => {
        if (hotelPricesSlide) {
            setState((prevState) => ({ ...prevState, selectedMenu: 1 }));
        }
    }, [hotelPricesSlide]);

    return (
        <div className="editor-assets">
            <div className="fixed-templates-hours">
                <div
                    className="card home-editor-cards"
                    style={{ display: state.selectedMenu === -1 || window.innerWidth > 760 ? 'block' : 'none' }}
                >
                    <h4>Choisissez un type</h4>
                    {!allowTemplates || (
                        <div className="category manual">
                            <div
                                onClick={() => setState({ ...state, selectedMenu: 0 })}
                                className={'cat rayon' + (state.selectedMenu === 0 ? ' active' : '')}
                            >
                                <i className="fa fa-arrow-right"></i>Templates libres
                            </div>
                        </div>
                    )}
                    {!allowWidgets || (
                        <div className="category manual">
                            <div
                                onClick={() => setState({ ...state, selectedMenu: 1 })}
                                className={'cat rayon' + (state.selectedMenu === 1 ? ' active' : '')}
                            >
                                <i className="fa fa-arrow-right"></i>Widgets
                            </div>
                        </div>
                    )}
                    {!allowDynamicHtml || (
                        <div className="category manual">
                            <div
                                onClick={() => setState({ ...state, selectedMenu: 2 })}
                                className={'cat rayon' + (state.selectedMenu === 2 ? ' active' : '')}
                            >
                                <i className="fa fa-arrow-right"></i>Créatives dynamiques
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="editor-window">
                <div className="card" style={{ visibility: state.selectedMenu === -1 ? 'hidden' : 'visible' }}>
                    {!allowTemplates || (
                        <div className={state.selectedMenu === 0 ? 'visible' : 'hidden'}>
                            <FreeTemplateModal
                                templates={templates.filter(
                                    ({ defaultElements, orientation }) =>
                                        orientation === orientation_ && defaultElements.length === 0
                                )}
                                onSave={onSave}
                                selectedTemplate={templateInstance}
                            />
                        </div>
                    )}
                    {!allowWidgets || (
                        <div className={state.selectedMenu === 1 ? 'visible' : 'hidden'}>
                            <WidgetsListModal
                                onSave={onSaveWidget}
                                allowHotelPrices={allowHotelPrices}
                                hotelPricesSlide={hotelPricesSlide}
                                orientation={orientation_}
                            />
                        </div>
                    )}
                    {!allowDynamicHtml || (
                        <div className={state.selectedMenu === 2 ? 'visible' : 'hidden'}>
                            <DynamicListModal dynamics={dynamics} onSave={onSaveDynamic} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const EditorNewModal = ({
    show,
    onClose,
    orientation,
    onSaveUserSlide,
    selectedIndex,
    creatives = [],
    userObject,
    selectedSign,
    isSubsequence,
    isDylSequence,
    onAddMultipleCreas,
    allowSlidesDurationChange,
    maxDuration,
}) => {
    const [state, setState] = useState({
        selectedStack: 0,
        slide: null,
        assets: [],
        signAssets: [],
        categories: [],
        templates: [],
        dynamics: [],
        loaded: false,
        clickIndex: 0,
        ready: false,
        hotelPricesSlide: null,
    });

    const close = () => {
        setState({ ...state, selectedStack: 0, slide: null, ready: false, hotelPricesSlide: null });

        onClose();
    };

    useEffect(() => {
        let orientation_used = orientation;

        if (orientation_used === 'portrait_direct') {
            orientation_used = 'portrait';
        }

        if (isSubsequence) {
            if (selectedSign === null) {
                return;
            }

            fetchApi(`/sequences/subsequences/signAssets/${selectedSign}`)
                .then((res) => res.json())
                .then((res) =>
                    setState((prevState) => ({
                        ...prevState,
                        signAssets: res.signAssets.reverse().filter((a) => {
                            if (a.orientation !== orientation_used) {
                                return false;
                            }

                            if (!a.defaultExpiration) {
                                return true;
                            }

                            return new Date(a.defaultExpiration) >= new Date();
                        }),
                        categories: res.categories.filter(
                            ({ assets }) => assets.find((a) => a.orientation === orientation_used) !== undefined
                        ),
                        templates: res.templates.filter((t) => orientation_used === t.orientation && !t.dev),
                        loaded: true,
                    }))
                );
        } else if (isDylSequence) {
            fetchApi('/admin/managed-sequences/assets')
                .then((res) => res.json())
                .then((res) =>
                    setState((prevState) => ({
                        ...prevState,
                        categories: res.categories.filter(
                            ({ assets }) => assets.find((a) => a.orientation === orientation_used) !== undefined
                        ),
                    }))
                );
        } else {
            fetchApi(`/shop/assets`)
                .then((res) => res.json())
                .then((res) => {
                    setState((prevState) => ({
                        ...prevState,
                        assets: res.assets
                            .filter((a) => {
                                if (a.orientation !== orientation_used) {
                                    return false;
                                }

                                return true;
                            })
                            .reverse(),
                        signAssets: res.signAssets.reverse().filter((a) => {
                            if (a.orientation !== orientation_used) {
                                return false;
                            }

                            if (!a.defaultExpiration) {
                                return true;
                            }

                            return new Date(a.defaultExpiration) >= new Date();
                        }),
                        categories: res.categories.filter(
                            ({ assets }) => assets.find((a) => a.orientation === orientation_used) !== undefined
                        ),
                        templates: res.templates.filter((t) => orientation_used === t.orientation && !t.dev),
                        dynamics: res.dynamics,
                        loaded: true,
                    }));
                })
                .catch((err) => {
                    notify.show('La recuperation de donnee a echoue: ' + err.message, 'warning');
                });
        }
    }, [orientation, selectedSign, isSubsequence, isDylSequence]);

    useEffect(() => {
        if (!show) {
            return;
        }

        if (selectedIndex === null) {
            return setState((state) => ({ ...state, ready: true }));
        }

        const slide = creatives[selectedIndex];

        if (slide.creaType === 'Fruit') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'Bakery') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'Butchery') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'FishShop') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'Cheese') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'Delicatessen') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'Caterer') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'Product') {
            setState((state) => ({ ...state, selectedStack: 1, slide, ready: true }));
        } else if (slide.creaType === 'Widget') {
            if (slide.creative?.type === 'HotelPrices') {
                setState((state) => ({
                    ...state,
                    selectedStack: 3,
                    slide,
                    ready: true,
                    hotelPricesSlide: slide.creative,
                }));
            } else {
                setState((state) => ({ ...state, selectedStack: 3, slide, ready: true }));
            }
        } else if (slide.creaType === 'Template' || slide.creaType === 'FreeTemplateInstance') {
            if (slide.creative.templateId.defaultElements.length > 0) {
                setState((state) => ({
                    ...state,
                    selectedStack: 2,
                    slide,
                    ready: true,
                }));
            } else {
                setState((state) => ({
                    ...state,
                    selectedStack: 3,
                    slide,
                    ready: true,
                }));
            }
        }
    }, [selectedIndex, creatives, show]);

    const {
        allowFruitsEditor,
        allowBakeryEditor,
        allowButcheryEditor,
        allowCheeseEditor,
        allowFishShop,
        allowDelicatessen,
        allowCaterer,
        allowTemplates,
        allowFixedTemplates,
        templateName,
        allowWidgets,
        allowDynamicHtml,
        allowProducts,
        allowHotelPrices,
    } = getAllowedVariables(userObject, isSubsequence, orientation);

    const allowRayons =
        allowFruitsEditor ||
        allowBakeryEditor ||
        allowButcheryEditor ||
        allowCheeseEditor ||
        allowFishShop ||
        allowDelicatessen ||
        allowCaterer ||
        allowProducts;

    if (state.ready === false) {
        return null;
    }

    return (
        <div
            className={`dyl-home editor-modal background ${show ? 'visible' : 'hidden'}`}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    close();
                }
            }}
        >
            <div className="close-btn-editor" onClick={close}>
                <i className="fa fa-times"></i>
            </div>
            <div className="flex-row">
                <div className="card">
                    <h3>Ajout d’affiche</h3>
                    <p>Sélectionnez une affiche dans la bibliothèque ou créez-en une à partir d’un modèle:</p>
                    <div className="stacks">
                        <div
                            onClick={() =>
                                setState({ ...state, selectedStack: state.slide === null ? 0 : state.selectedStack })
                            }
                            className={
                                'stack-editor' +
                                (state.selectedStack === 0 ? ' selected' : '') +
                                (state.slide !== null ? ' disabled' : '')
                            }
                        >
                            <i className="fa fa-images"></i>
                            <span>Bibliothèque</span>
                        </div>
                        {!allowRayons || (
                            <div
                                onClick={() =>
                                    setState({
                                        ...state,
                                        selectedStack: state.slide === null ? 1 : state.selectedStack,
                                        clickIndex: state.clickIndex + 1,
                                    })
                                }
                                className={
                                    'stack-editor' +
                                    (state.selectedStack === 1 ? ' selected' : '') +
                                    (state.slide !== null ? ' disabled' : '')
                                }
                            >
                                <i className="fa fa-store"></i>
                                <span>Rayons</span>
                            </div>
                        )}
                        {!allowFixedTemplates ||
                            state.templates.filter(({ defaultElements }) => defaultElements.length > 0).length ===
                                0 || (
                                <div
                                    onClick={() =>
                                        setState({
                                            ...state,
                                            selectedStack: state.slide === null ? 2 : state.selectedStack,
                                            clickIndex: state.clickIndex + 1,
                                        })
                                    }
                                    className={
                                        'stack-editor' +
                                        (state.selectedStack === 2 ? ' selected' : '') +
                                        (state.slide !== null ? ' disabled' : '')
                                    }
                                >
                                    <i className="fa fa-clock"></i>
                                    <span>Horaires</span>
                                </div>
                            )}
                        {(!allowTemplates && !allowWidgets) || (
                            <div
                                onClick={() =>
                                    setState({
                                        ...state,
                                        selectedStack: state.slide === null ? 3 : state.selectedStack,
                                        clickIndex: state.clickIndex + 1,
                                    })
                                }
                                className={
                                    'stack-editor' +
                                    (state.selectedStack === 3 ? ' selected' : '') +
                                    (state.slide !== null ? ' disabled' : '')
                                }
                            >
                                <i className="fa fa-file"></i>
                                <span>Autres</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="content">
                    <div className={state.selectedStack === 0 ? 'visible' : 'hidden'}>
                        <AssetsBlock
                            orientation={orientation}
                            assets={state.assets}
                            categories={state.categories}
                            signAssets={state.signAssets}
                            onCreaSelect={(creative) => {
                                const data = {
                                    creative,
                                    creaType: 'UserCreative',
                                };

                                if (creative.defaultExpiration) {
                                    data.expirationDate = creative.defaultExpiration;
                                }

                                if (creative.startDate) {
                                    data.startDate = creative.startDate;
                                }

                                onSaveUserSlide(data);

                                onClose();
                            }}
                            onMultipleCreaSelect={(creatives) => {
                                onAddMultipleCreas(
                                    creatives.map((creative) => {
                                        const data = {
                                            creative,
                                            creaType: 'UserCreative',
                                        };

                                        if (creative.defaultExpiration) {
                                            data.expirationDate = creative.defaultExpiration;
                                        }

                                        if (creative.startDate) {
                                            data.startDate = creative.startDate;
                                        }

                                        return data;
                                    })
                                );

                                onClose();
                            }}
                        />
                    </div>
                    <div className={state.selectedStack === 1 ? 'visible' : 'hidden'}>
                        <TemplatesRayonsBlock
                            orientation={orientation}
                            slide={state.slide}
                            onSave={(s) => {
                                onSaveUserSlide(s);

                                onClose();
                            }}
                            modalOpen={show}
                            templateName={templateName}
                            userObject={userObject}
                            clickIndex={state.clickIndex}
                            isSubsequence={isSubsequence}
                            allowSlidesDurationChange={allowSlidesDurationChange}
                            maxDuration={maxDuration}
                        />
                    </div>
                    <div className={state.selectedStack === 2 ? 'visible' : 'hidden'}>
                        <FixedTemplatesBlock
                            orientation={orientation}
                            slide={state.slide}
                            onSave={(template) => {
                                onSaveUserSlide({
                                    creative: template,
                                    creaType: 'Template',
                                    expirationDate: template.expirationDate,
                                });

                                onClose();
                            }}
                            modalOpen={show}
                            templates={state.templates}
                            clickIndex={state.clickIndex}
                        />
                    </div>
                    <div className={state.selectedStack === 3 ? 'visible' : 'hidden'}>
                        <OtherBlock
                            templates={state.templates}
                            hotelPricesSlide={state.hotelPricesSlide}
                            onSave={(template) => {
                                onSaveUserSlide({
                                    creative: template,
                                    creaType: 'Template',
                                });

                                onClose();
                            }}
                            onSaveWidget={(w) => {
                                let creaType = 'Widget';
                                let creative = null;

                                if (w.type === 'RSS') {
                                    creaType = 'RSS';
                                    creative = {
                                        type: w.feed,
                                        widgetData: w.widgetData,
                                    };
                                } else {
                                    creative = w;
                                }

                                onSaveUserSlide({
                                    creative,
                                    creaType,
                                });

                                onClose();
                            }}
                            onSaveDynamic={(d) => {
                                onSaveUserSlide({
                                    creative: d,
                                    creaType: 'DynamicHtml',
                                });

                                onClose();
                            }}
                            allowTemplates={allowTemplates}
                            allowWidgets={allowWidgets}
                            allowDynamicHtml={allowDynamicHtml}
                            allowHotelPrices={allowHotelPrices}
                            orientation_={orientation}
                            slide={state.slide}
                            clickIndex={state.clickIndex}
                            dynamics={state.dynamics}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditorNewModal;
