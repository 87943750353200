import React from 'react';
import { Panel } from 'react-bootstrap';

export const Formation = () => {
    return (
        <div className="dyl-home formation">
            <Panel>
                <h4>Prendre rendez-vous pour une formation</h4>
                <p>
                    Vous pouvez sélectionner un créneau pour planifier une formation de 15-30 minutes sur l'application
                    DoohYouLike.
                </p>
                <hr />
                <iframe title="koalendar" src="https://koalendar.com/e/formation-application-dyl" frameBorder="0"></iframe>
            </Panel>
        </div>
    );
};
