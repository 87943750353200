import React, { useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import { fetchApi } from '../Services/NetworkServcice';
import { SequenceHomeList } from './Home';

const removeSubsequence = async (id) => {
    if (!window.confirm('Êtes-vous sûr?')) {
        return false;
    }

    return await fetchApi(`/sequences/subsequences/${id}`, {
        method: 'DELETE'
    })
        .then((res) => {
            if (res.status === 200) {
                notify.show('Sequence supprimee', 'success');

                return true;
            } else {
                notify.show('Impossible de supprimer', 'danger');
                return false;
            }
        })
        .catch(() => {
            notify.show('Impossible de supprimer', 'danger');
            return false;
        });
};

const Subsequences = (props) => {
    const [state, setState] = useState({
        sequences: [],
        devices: [],
        signs: [],
        newSeqName: '',
        orientation: 'portrait',
        availableOrientations: ['portrait']
    });

    useEffect(() => {
        fetchApi('/sequences/subsequences')
            .then((res) => res.json())
            .then((sequences) => setState((prevState) => ({ ...prevState, sequences })));

        fetchApi('/sequences/subsequences/devices')
            .then((res) => res.json())
            .then((devices) => {
                const availableOrientations = [...new Set(devices.map(({ orientation }) => orientation))];
                setState((prevState) => ({
                    ...prevState,
                    availableOrientations,
                    orientation: availableOrientations[0] || 'portrait',
                    devices
                }));
            });

        fetchApi('/sequences/subsequences/signs')
            .then((res) => res.json())
            .then((signs) => setState((prevState) => ({ ...prevState, signs })));
    }, []);

    if (state.devices.length === 0) {
        return null;
    }

    return (
        <SequenceHomeList
            availableOrientations={state.availableOrientations}
            history={props.history}
            notifsData={null}
            screens={state.devices}
            sequences={state.sequences}
            showScreensCard={false}
            onDelete={(id) => {
                const ok = removeSubsequence(id);

                if (ok) {
                    setState({
                        ...state,
                        sequences: state.sequences.filter(({ _id }) => _id !== id)
                    });
                }
            }}
        />
    );
};

export default Subsequences;
