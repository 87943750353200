import React, { useState } from 'react';
import { Panel } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { notify } from 'react-notify-toast';
import AuthService from '../Components/Auth/AuthService';
import constants from '../Constants';
import { fetchApi } from '../Services/NetworkServcice';

export const MaBoiteDeCom = (props) => {
    const [state, setState] = useState({
        uploading: false,
        file: null,
        done: false,
        doneAsset: false,
        date: null,
        assetDate: null,
        orientation: 'portrait',
        fileAsset: null,
    });

    const onUploadAsset = () => {
        setState((prevState) => ({ ...prevState, uploading: true }));

        const data = new FormData();
        data.append('file', state.fileAsset);
        data.append('exp', state.assetDate);

        fetch(`${constants.endpoint}/shop/mbdc/asset`, {
            headers: {
                'x-access-token': new AuthService().getToken(),
            },
            method: 'POST',
            body: data,
        })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error({ error: 'Error sending' });
                }

                notify.show('Envoi terminé', 'success');
                setState((prevState) => ({ ...prevState, uploading: false, doneAsset: true, fileAsset: null }));
            })
            .catch((e) => {
                notify.show('Erreur', 'error');
            });
    };

    const onUploadFile = () => {
        if (!state.file) return;

        setState((prevState) => ({ ...prevState, uploading: true }));

        const fr = new FileReader();
        fr.onload = function () {
            const typedArray = new Uint8Array(this.result);

            const loadingTask = window.pdfjsLib.getDocument(typedArray);
            loadingTask.promise
                .then(async (pdf) => {
                    const data = [];

                    for (let i = 0; i < pdf.numPages; i++) {
                        const page = await pdf.getPage(i + 1);
                        const vp = page.getViewport({ scale: 1.5 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = vp.height;
                        canvas.width = vp.width;

                        if (vp.width > 3000 || vp.height > 3000) {
                            canvas.remove();
                            alert(`La page ${i + 1} est trop grande`);
                            continue;
                        }

                        const renderContext = { canvasContext: context, viewport: vp };

                        await page.render(renderContext).promise;

                        data.push(canvas.toDataURL('image/jpeg', 80));

                        canvas.remove();
                    }

                    if (data.length === 0) return;

                    fetchApi('/shop/mbdc', {
                        method: 'POST',
                        body: JSON.stringify({
                            files: data,
                            defaultExpiration: state.date,
                            orientation: state.orientation,
                        }),
                    })
                        .then((res) => {
                            if (res.status !== 200) {
                                throw new Error({ error: 'Error sending' });
                            }

                            notify.show('Envoi terminé', 'success');
                            setState((prevState) => ({
                                ...prevState,
                                uploading: false,
                                done: true,
                                file: null,
                            }));
                        })
                        .catch((e) => {
                            console.log('An error occured:', e);
                        });
                })
                .catch((e) => {
                    console.error(e);
                    notify.show('Une erreur est survenue lors du chargement du document', 'error');
                    setState((prevState) => ({ ...prevState, uploading: false, done: false, file: null }));
                });
        };

        fr.readAsArrayBuffer(state.file);
    };

    return (
        <div className="dyl-home mbdc row">
            <div className="col-md-6">
                <Panel>
                    <h4>Upload de fichier PDF</h4>
                    <p>Importez ici vos affiches au format PDF.</p>
                    <p className="sub">
                        Note: Une fois votre fichier PDF envoyé, nos équipes procéderont à une validation rapide afin de
                        s'assurer que vos affiches sont conformes à vos écrans. Vous serez alors notifiés et pourrez
                        utiliser vos affiches.
                    </p>
                    <hr />
                    {!!state.done || (
                        <React.Fragment>
                            <strong>Etape 1: Ajoutez votre fichier</strong>
                            <input
                                type="file"
                                onChange={(e) => {
                                    const files = e.target.files;
                                    if (files.length > 0) setState((prevState) => ({ ...prevState, file: files[0] }));
                                }}
                            />
                            {![982].includes(props.user.role.id) || (
                                <div className="form-group" style={{ marginTop: 24 }}>
                                    <label>
                                        <strong>Etape 1.1: Format</strong>
                                    </label>
                                    <select
                                        className="form-control"
                                        value={state.orientation}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState((prevState) => ({ ...prevState, orientation: value }));
                                        }}
                                    >
                                        <option value="portrait">Portrait</option>
                                        <option value="led_448_340">LxH_448x340</option>
                                    </select>
                                </div>
                            )}
                            {![589].includes(props.user.role.id) || (
                                <div className="form-group" style={{ marginTop: 24 }}>
                                    <label>
                                        <strong>Etape 1.1: Format</strong>
                                    </label>
                                    <select
                                        className="form-control"
                                        value={state.orientation}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState((prevState) => ({ ...prevState, orientation: value }));
                                        }}
                                    >
                                        <option value="portrait">Portrait</option>
                                        <option value="landscape">Paysage</option>
                                        <option value="ring">LxH_960x540 (ring)</option>
                                    </select>
                                </div>
                            )}
                            {![1254].includes(props.user.role.id) || (
                                <div className="form-group" style={{ marginTop: 24 }}>
                                    <label>
                                        <strong>Etape 1.1: Format</strong>
                                    </label>
                                    <select
                                        className="form-control"
                                        value={state.orientation}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState((prevState) => ({ ...prevState, orientation: value }));
                                        }}
                                    >
                                        <option value="portrait">Portrait</option>
                                        <option value="landscape">Paysage</option>
                                        <option value="led_960_576">LxH_960x576</option>
                                        <option value="led_640_384">LxH_640x384</option>
                                        <option value="led_576_144">LxH_576x144</option>
                                    </select>
                                </div>
                            )}
                            {![2072].includes(props.user.role.id) || (
                                <div className="form-group" style={{ marginTop: 24 }}>
                                    <label>
                                        <strong>Etape 1.1: Format</strong>
                                    </label>
                                    <select
                                        className="form-control"
                                        value={state.orientation}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState((prevState) => ({ ...prevState, orientation: value }));
                                        }}
                                    >
                                        <option value="portrait">Portrait</option>
                                        <option value="landscape">Paysage</option>
                                        <option value="led_960_576">LxH_960x576</option>
                                    </select>
                                </div>
                            )}
                            {![9].includes(props.user.role.Agent?.id) || (
                                <div className="form-group" style={{ marginTop: 24 }}>
                                    <label>
                                        <strong>Etape 1.1: Format</strong>
                                    </label>
                                    <select
                                        className="form-control"
                                        value={state.orientation}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState((prevState) => ({ ...prevState, orientation: value }));
                                        }}
                                    >
                                        <option value="portrait">Portrait</option>
                                        <option value="landscape">Paysage</option>
                                        <option value="led_960_576">LxH_960x576</option>
                                    </select>
                                </div>
                            )}
                            <div className="form-group" style={{ marginTop: 24 }}>
                                <label>
                                    <strong>Etape 2: Date d'expiration.</strong>
                                    <br />
                                    La date d'expiration doit concorder avec vos affiches. Si vos affiches indiquent une
                                    promotion jusqu'au 17 janvier, alors vous devez renseigner cette date. Si vous ne
                                    souhaitez pas d'expiration de l'affiche, vous pouvez utiliser les flèches (») pour
                                    sélectionner une date dans plusieurs années.
                                </label>
                                <Calendar
                                    value={state.date}
                                    onChange={(e) => {
                                        const date = new Date(e.setHours(23, 59, 59, 999));
                                        setState((prevState) => ({ ...prevState, date }));
                                    }}
                                    minDate={new Date()}
                                />
                            </div>
                            <strong>Etape 3: Envoyez votre fichier</strong>
                            <br />
                            <button
                                onClick={onUploadFile}
                                disabled={state.uploading || state.file === null || state.date === null}
                                className="btn btn-info"
                            >
                                {state.uploading ? 'Envoi...' : 'Ajouter à ma bibliothèque'}
                            </button>
                        </React.Fragment>
                    )}
                    {!state.done || (
                        <div>
                            Envoi terminé nos équipes vont procéder à une validation de votre envoi. Une fois la
                            validation terminée, vous pourrez utiliser vos affiches !
                        </div>
                    )}
                </Panel>
            </div>
            {[1, 8, 10].includes(props.user.role.Agent.id) && (
                <div className="col-md-6">
                    <Panel>
                        <h4>Upload de média (image)</h4>
                        <p>Importez ici vos affiches.</p>
                        <p className="sub">
                            Note: Une fois votre affiche envoyée, nos équipes procéderont à une validation rapide afin
                            de s'assurer que vos affiches sont conformes à vos écrans. Vous serez alors notifiés et
                            pourrez utiliser vos affiches.
                        </p>
                        <hr />
                        {!!state.doneAsset || (
                            <React.Fragment>
                                <strong>Etape 1: Ajoutez votre fichier</strong>
                                <input
                                    type="file"
                                    accept="image/jpeg,image/png"
                                    onChange={(e) => {
                                        const files = e.target.files;
                                        if (files.length > 0)
                                            setState((prevState) => ({ ...prevState, fileAsset: files[0] }));
                                    }}
                                />
                                <div className="form-group" style={{ marginTop: 24 }}>
                                    <label>
                                        <strong>Etape 2: Date d'expiration.</strong>
                                        <br />
                                        La date d'expiration doit concorder avec vos affiches. Si vos affiches indiquent
                                        une date jusqu'au 17 janvier, alors vous devez renseigner cette date. Si vous ne
                                        souhaitez pas d'expiration de l'affiche, vous pouvez utiliser les flèches (»)
                                        pour sélectionner une date dans plusieurs années.
                                    </label>
                                    <Calendar
                                        value={state.assetDate}
                                        onChange={(e) => {
                                            const date = new Date(e.setHours(23, 59, 59, 999));
                                            setState((prevState) => ({ ...prevState, assetDate: date }));
                                        }}
                                        minDate={new Date()}
                                    />
                                </div>
                                <strong>Etape 3: Envoyez votre fichier</strong>
                                <br />
                                <button
                                    onClick={onUploadAsset}
                                    disabled={state.uploading || state.fileAsset === null || state.assetDate === null}
                                    className="btn btn-info"
                                >
                                    {state.uploading ? 'Envoi...' : 'Ajouter à ma bibliothèque'}
                                </button>
                            </React.Fragment>
                        )}
                        {!state.doneAsset || (
                            <div>
                                Envoi terminé nos équipes vont procéder à une validation de votre envoi. Une fois la
                                validation terminée, vous pourrez utiliser vos affiches !
                            </div>
                        )}
                    </Panel>
                </div>
            )}
        </div>
    );
};
