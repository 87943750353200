import React, { Component, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Notifications from 'react-notify-toast';

import './Assets/style.css';
import './Assets/animate.css';
import AuthService from './Components/Auth/AuthService';

import Login from './Pages/Login';
import Layout from './Components/Layout/Layout';
import ScrollToTop from './Components/Layout/ScrollToTop';

const Logout = (props) => {
    new AuthService().logout();

    useEffect(() => {
        setTimeout(() => props.history.push('/login'), 1000);
    }, [props.history]);

    return (
        <div className="dyl-logout-loading">
            <svg viewBox="0 0 100 100" height="75" width="75">
                <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#141414" stroke="none"></path>
            </svg>
        </div>
    );
};

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <ScrollToTop />
                <div>
                    <Notifications />
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/logout" component={Logout} />
                        <Route path="/" component={Layout} />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
