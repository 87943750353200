import React, { useState } from 'react';
import constants, { formats } from '../Constants';

const PreviewAsset = (props) => {
    const asset = props.assets.find(({ _id }) => _id === props.selected);
    const [state, setState] = useState({ isRatioInvalid: false });

    if (!asset) {
        return null;
    }

    let warning_data = null;

    if (props.selectedSigns !== null && !!props.selectedSigns) {
        const missing_signs = [];
        for (const sign of props.selectedSigns) {
            if (!asset.signId.includes(sign)) {
                missing_signs.push(sign);
            }
        }

        if (missing_signs.length > 0) {
            warning_data = (
                <div className="warning">
                    <i className="fas fa-exclamation-triangle"></i>
                    <p>
                        Cette affiche n'est pas disponible pour les enseignes suivantes:{' '}
                        {missing_signs.reduce((acc, s) => acc + ', ' + s)}
                    </p>
                </div>
            );
        }
    }

    if (asset.type === 'image') {
        return (
            <div className={`img-preview-container ${state.isRatioInvalid ? 'invalid' : ''}`}>
                <img
                    className="previewAsset"
                    onLoad={(e) => {
                        const ratio = e.target.width / e.target.height;

                        if (
                            props.fromSlide &&
                            Math.abs(parseInt(ratio * 100) - formats[props.orientation]?.ratio * 100) > 1
                        ) {
                            setState({ ...state, isRatioInvalid: true });
                        }
                    }}
                    src={`${constants.cloud_storage}/Assets/${asset.filename}`}
                    alt={asset.filename}
                />
                <div className="warning">
                    <i className="fas fa-exclamation-triangle"></i>
                    <p>
                        Le format de cette vignette n'est pas respecté. Elle apparaitra déformée à l'écran. Merci
                        d'uploader une version de cette image au bon format afin d'éviter les déformations
                    </p>
                </div>
            </div>
        );
    } else if (asset.type === 'video') {
        if (window.innerWidth >= 1024 && window.innerWidth <= 1200) {
            return (
                <img
                    className="previewAsset"
                    src={`${constants.cloud_storage}/Thumbs/${asset.filename}.png?tablet=1`}
                    alt=""
                />
            );
        }

        return (
            <div className="preview-asset-container">
                <video
                    className="previewAsset video"
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline={true}
                    poster={`${constants.cloud_storage}/Thumbs/${asset.filename}.png`}
                    key={`${asset.filename}`}
                >
                    <source src={`${constants.cloud_storage}/Assets/${asset.filename}`} />
                    <source src={`${constants.endpoint}/static/assets/${asset.filename}`} />
                </video>
                {warning_data}
            </div>
        );
    }

    return null;
};

export default PreviewAsset;
