import constants from '../../Constants';
import React, { useEffect, useState } from 'react';
import { fetchApi } from '../../Services/NetworkServcice';
import swal from 'sweetalert';

const Creative = (props) => {
    return <img src={`${constants.cloud_storage}/Thumbs/${props.filename}.png`} alt="" />;
};

// export const genData = (events, shops) => {
//     const carr = shops.filter((s) => [4, 19, 15, 25].includes(s.SignId)).map((s) => s.id);
//     const data = [];

//     for (const e of events) {
//         for (const c of e.creatives) {
//             const nSlides = c.slides.filter((s) => carr.includes(s.shop_id)).length;
//             if (nSlides > 0)
//                 data.push({
//                     crea: c,
//                     slides: nSlides
//                 });
//         }
//     }

//     data.sort((a, b) => b.slides - a.slides);

//     const d_f = [['Category name', 'tags', 'asset', 'usage']];
//     for (const d of data) {
//         d_f.push([
//             d.crea.event.name,
//             d.crea.creative.tags.reduce((acc, t) => acc + ', ' + t, ''),
//             d.crea.creative.filename,
//             d.slides
//         ]);
//     }
// };

export const SlideManagement = (props) => {
    const [state, setState] = useState({
        events: [],
        events_subseq: [],
        shops: []
    });

    useEffect(() => {
        fetchApi('/admin/maronniers/usage')
            .then((res) => res.json())
            .then(({ events, events_subseq }) => setState((prevState) => ({ ...prevState, events, events_subseq })));

        // fetchApi('/admin/shops/all-agents')
        //     .then((res) => res.json())
        //     .then(({ shops }) => {
        //         setState((prevState) => ({
        //             ...prevState,
        //             shops
        //         }));
        //     });
    }, []);

    const removeSlide = (slide_id, event_id, creative_id, slide_idx, array, arrayName) => {
        fetchApi(`/admin/maronniers/usage/${slide_id}`, { method: 'DELETE' }).then((res) => {
            if (res.status === 200) {
                const newArray = [...array];
                newArray[event_id].creatives[creative_id].slides.splice(slide_idx, 1);

                setState((prevState) => ({ ...prevState, [arrayName]: newArray }));

                return;
            }

            swal({
                icon: 'warning',
                text: 'Impossible de supprimer cette affiche',
                title: 'Erreur'
            });
        });
    };

    // if (state.events.length && state.shops.length) genData(state.events, state.shops);

    return (
        <div className="panel SlidesManagement">
            <div className="slides">
                User slides
                {state.events.map((e, event_id) => (
                    <div key={e._id} className="event">
                        <h3>{e.event.name}</h3>
                        <div className="slides-creas">
                            {e.creatives.map((c, creative_id) => (
                                <div className="crea" key={c._id}>
                                    <Creative {...c.creative} />
                                    <hr />
                                    {c.slides.map((s, slide_idx) => (
                                        <div style={{ marginTop: 4 }}>
                                            {s.shop_id}{' '}
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    swal({
                                                        icon: 'info',
                                                        text: "Supprimer l'affiche?",
                                                        buttons: [
                                                            {
                                                                text: 'Annuler',
                                                                visible: true
                                                            },
                                                            {
                                                                text: 'Oui',
                                                                className: 'info',
                                                                visible: true
                                                            }
                                                        ],
                                                        title: 'Confirmation'
                                                    }).then((res) => {
                                                        if (res) {
                                                            removeSlide(
                                                                s.slide_id,
                                                                event_id,
                                                                creative_id,
                                                                slide_idx,
                                                                state.events,
                                                                'events'
                                                            );
                                                        }
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                Group/Sign slides
                {state.events_subseq.map((e, event_id) => (
                    <div key={e._id} className="event">
                        <h3>{e.event.name}</h3>
                        <div className="slides-creas">
                            {e.creatives.map((c, creative_id) => (
                                <div className="crea" key={c._id}>
                                    <Creative {...c.creative} />
                                    <hr />
                                    {c.slides.map((s, slide_idx) => (
                                        <div style={{ marginTop: 4 }}>
                                            {'Seq '}

                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    swal({
                                                        icon: 'info',
                                                        text: "Supprimer l'affiche?",
                                                        buttons: [
                                                            {
                                                                text: 'Annuler',
                                                                visible: true
                                                            },
                                                            {
                                                                text: 'Oui',
                                                                className: 'info',
                                                                visible: true
                                                            }
                                                        ],
                                                        title: 'Confirmation'
                                                    }).then((res) => {
                                                        if (res) {
                                                            removeSlide(
                                                                s.slide_id,
                                                                event_id,
                                                                creative_id,
                                                                slide_idx,
                                                                state.events_subseq,
                                                                'events_subseq'
                                                            );
                                                        }
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
