import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Preview from '../Assets/Preview';
import constants from '../../../Constants';
import swal from 'sweetalert';
import { fetchApi } from '../../../Services/NetworkServcice';
import moment from 'moment';
import TagsInput from 'react-tagsinput';
import Select from 'react-select';
import Calendar from 'react-calendar';
import { AssetList } from './AssetList';

export const EditAssetDates = ({
    defaultExpiration,
    setDefaultExpiration,
    defaultStart,
    setDefaultStart,
    category
}) => {
    return (
        <React.Fragment>
            <p style={{ marginTop: 10 }}>
                Expiration (override){' '}
                <input
                    type="checkbox"
                    checked={!!defaultExpiration}
                    onChange={(e) => {
                        let checked = e.target.checked;

                        setDefaultExpiration(checked ? new Date(new Date().setHours(23, 59, 59, 999)) : null);
                    }}
                />
            </p>
            {!defaultExpiration || (
                <Calendar
                    maxDate={new Date(category.expirationDate)}
                    minDate={new Date(category.startDate)}
                    value={new Date(defaultExpiration)}
                    onChange={(e) => setDefaultExpiration(new Date(new Date(e).setHours(23, 59, 59, 999)))}
                ></Calendar>
            )}
            <p style={{ marginTop: 10 }}>
                Démarrage (override){' '}
                <input
                    type="checkbox"
                    checked={!!defaultStart}
                    onChange={(e) => {
                        let checked = e.target.checked;

                        setDefaultStart(checked ? new Date(new Date().setHours(0, 0, 0, 0)) : null);
                    }}
                />
            </p>
            {!defaultStart || (
                <Calendar
                    maxDate={new Date(category.expirationDate)}
                    minDate={new Date(category.startDate)}
                    value={new Date(defaultStart)}
                    onChange={(e) => setDefaultStart(new Date(new Date(e).setHours(0, 0, 0, 0)))}
                ></Calendar>
            )}
        </React.Fragment>
    );
};

export const AssetReplaceModal = ({ categories, asset, selectedReplaceById, onClose, onReplace }) => {
    const category = categories?.find((c) => c._id === asset?.event);
    let categoryAssets = [];

    if (!!category) {
        categoryAssets = category.assets.filter(
            ({ orientation, _id }) => orientation === asset.orientation && _id !== asset._id
        );
    }

    return (
        <Modal className="modal-assets-admin dyl-home assets" show={selectedReplaceById !== null} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Remplacer par</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row assets">
                    <div className="col-md-12">
                        <AssetList
                            selectedList={[]}
                            onSelect={() => {}}
                            onClick={(e) => {
                                swal({
                                    icon: 'warning',
                                    buttons: true,
                                    text: 'Voulez vos remplacer? Cette opération est irréversible',
                                    dangerMode: true
                                }).then((res) => {
                                    if (res) {
                                        onReplace(e._id, asset._id);
                                    }
                                });
                            }}
                            assets={categoryAssets}
                            groups={[]}
                            signs={[]}
                            slides={[]}
                            hideTags={true}
                            hideNew={true}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export const EditAssetModal = ({
    asset,
    onClose,
    slides,
    devices,
    signs,
    groups,
    onDelete,
    onUpdate,
    categories,
    onClickReplace
}) => {
    const [state, setState] = useState({
        asset: null,
        shops_using: {},
        cat_name: 'Changer catégorie'
    });

    useEffect(() => {
        if (asset !== null) {
            const shops_using = {};
            const slides_with_asset = slides.find(({ _id }) => _id === asset._id);
            if (slides_with_asset) {
                slides_with_asset.devices.forEach((assigned) => {
                    assigned.forEach((dev) => {
                        const device = devices.find(({ id }) => id === dev);
                        if (device && !(device.Shop.id in shops_using)) {
                            shops_using[device.Shop.id] = device.Shop;
                        }
                    });
                });
            }

            setState((prevState) => ({ ...prevState, asset, shops_using, cat_name: asset.category.name }));
        }
    }, [asset, devices, slides]);

    if (state.asset === null) {
        return null;
    }

    return (
        <Modal className="modal-assets-admin" show={asset !== null} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Edition d'affiche</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <span>Catégorie: {state.asset.category.name}</span>
                        <Preview
                            preview={`${constants.cloud_storage}/Assets/${state.asset.filename}`}
                            preview_fallback={`${constants.endpoint}/static/assets/${state.asset.filename}`}
                            type={state.asset.type}
                        />
                        <div style={{ marginTop: 10 }}>
                            <button
                                onClick={() => {
                                    swal({
                                        title: 'Désassigner',
                                        text: 'Etes vous sur?',
                                        icon: 'warning',
                                        buttons: ['Non', 'Oui']
                                    }).then((clicked) => {
                                        if (!clicked) {
                                            return;
                                        }

                                        fetchApi(`/admin/maronniers/desassign/${state.asset._id}`, {
                                            method: 'DELETE'
                                        });
                                    });
                                }}
                                className="btn btn-warning"
                            >
                                Désassigner
                            </button>
                            <button onClick={onClickReplace} className="btn btn-warning" style={{ marginLeft: 10 }}>
                                Remplacer par
                            </button>
                        </div>
                        <div style={{ marginTop: 10, display: 'flex', flexGrow: 1 }}>
                            <div style={{ flexGrow: 1 }}>
                                <Select
                                    options={categories.map(({ _id, name }) => ({ label: name, value: _id }))}
                                    value={{
                                        label: state.cat_name,
                                        value: state.asset.event
                                    }}
                                    onChange={(e) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            asset: {
                                                ...prevState.asset,
                                                event: e.value
                                            },
                                            cat_name: e.label
                                        }))
                                    }
                                ></Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h4>Informations</h4>
                        <p>Expiration: {moment(state.asset.category.expirationDate).format('DD/MM/yy HH:mm:ss')}</p>
                        {Object.keys(state.shops_using).length === 0 || (
                            <React.Fragment>
                                <p>Utilisé par les magasins:</p>
                                {console.log(state)}
                                <div className="dialog-scroll">
                                    {Object.values(state.shops_using).map((s) => (
                                        <p key={s.id}>
                                            {s.name} | {s.Sign.name}
                                        </p>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                        <p style={{ marginTop: 10 }}>Tags</p>
                        <TagsInput
                            value={state.asset.tags}
                            onChange={(tags) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    asset: {
                                        ...prevState.asset,
                                        tags
                                    }
                                }))
                            }
                        />
                        <EditAssetDates
                            category={state.asset.category}
                            defaultExpiration={state.asset.defaultExpiration}
                            defaultStart={state.asset.defaultStart}
                            setDefaultExpiration={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    asset: {
                                        ...prevState.asset,
                                        defaultExpiration: e
                                    }
                                }))
                            }
                            setDefaultStart={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    asset: {
                                        ...prevState.asset,
                                        defaultStart: e
                                    }
                                }))
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <h4>Enseignes</h4>
                        <input
                            type="checkbox"
                            checked={state.asset.signId.length === signs.length}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        asset: {
                                            ...asset,
                                            signId: signs.map(({ id }) => id)
                                        }
                                    }));
                                } else {
                                    setState((prevState) => ({
                                        ...prevState,
                                        asset: {
                                            ...asset,
                                            signId: []
                                        }
                                    }));
                                }
                            }}
                        />{' '}
                        Autoriser tous
                        <div className="data-scroll">
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>Enseigne</th>
                                        <th>Activé</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {signs.map((s) => (
                                        <tr key={s.id}>
                                            <td>{s.name}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={state.asset.signId.includes(s.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                asset: {
                                                                    ...asset,
                                                                    signId: [...prevState.asset.signId, s.id]
                                                                }
                                                            }));
                                                        } else {
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                asset: {
                                                                    ...asset,
                                                                    signId: prevState.asset.signId.filter(
                                                                        (id) => id !== s.id
                                                                    )
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <h4>Groupe:</h4>
                        <Select
                            options={[
                                { label: 'Tous', value: null },
                                ...groups.map(({ id, name }) => ({
                                    label: name,
                                    value: id
                                }))
                            ]}
                            value={{
                                label: state.asset.groupId
                                    ? groups.find(({ id }) => id === state.asset.groupId).name
                                    : 'Tous',
                                value: state.asset.groupId
                            }}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    asset: {
                                        ...prevState.asset,
                                        groupId: e.value
                                    }
                                }))
                            }
                        ></Select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={() => onDelete(state.asset._id, state.asset.category._id)}>
                    Supprimer
                </button>
                <button className="btn btn-success" onClick={() => onUpdate(state.asset, state.asset.category._id)}>
                    Enregistrer
                </button>
            </Modal.Footer>
        </Modal>
    );
};
