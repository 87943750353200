import constants from '../Constants';
import AuthService from '../Components/Auth/AuthService';

const Auth = new AuthService();

export const fetchApi = (url, options) =>
    fetch(`${constants.endpoint}${url}`, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': Auth.getToken()
        },
        ...options
    }).then(res => Promise.resolve(res));

export const sendFile = (url, options) =>
    fetch(`${constants.endpoint}${url}`, {
        headers: {
            'x-access-token': Auth.getToken()
        },
        ...options
    }).then(res => {
        if (res.status >= 400) {
            return Promise.reject(res);
        }

        return Promise.resolve(res);
    });
