import moment from 'moment';
import React from 'react';
import ReactSwitch from 'react-switch';
import { toggleDataList } from '.';
import { AssetList } from './AssetList';

export const Category = ({
    category,
    selectedCreas,
    onCreaSelect,
    onCreaClick,
    setCategoryActive,
    onCategorySelect,
    onAddAssetForCategory,
    slides,
    groups,
    signs
}) => {
    return (
        <div>
            <span className="card-asset-title">
                <h2>{category.name}</h2>{' '}
                <span>
                    {moment(category.startDate).format('DD/MM/yy')} - {moment(category.endDate).format('DD/MM/yy')}
                </span>{' '}
                <button
                    className="btn btn-rounded btn-default"
                    onClick={() => onCategorySelect(category)}
                    style={{ marginLeft: 10 }}
                >
                    <i className="fa fa-pen"></i>
                </button>
                <div className="ml-10 flex">
                    <ReactSwitch
                        uncheckedIcon={false}
                        onColor="#63CB89"
                        checkedIcon={false}
                        checked={category.active}
                        activeBoxShadow="0"
                        onChange={() => setCategoryActive(!category.active, category._id)}
                    />
                </div>
            </span>
            <AssetList
                selectedList={selectedCreas}
                onSelect={(asset) => onCreaSelect(toggleDataList(selectedCreas, asset))}
                onClick={(a) => onCreaClick({ ...a, category: category })}
                assets={category.assets}
                selectable={true}
                onAddAssetForCategory={() => onAddAssetForCategory(category)}
                slides={slides}
                signs={signs}
                groups={groups}
            />
        </div>
    );
};
