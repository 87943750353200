import React, { useState } from 'react';

const ProductList = (props) => {
    if (!props.results.count || props.results.count === 0) {
        return null;
    }

    return props.results.products.slice(0, 45).map((p) => (
        <div
            className={'product' + (p.image_url === props.selected ? ' selected' : '')}
            onClick={() => props.selectImage(p.image_url)}
            key={p.id}
        >
            <img src={p.image_url} alt={p.generic_name} />
        </div>
    ));
};

const ChooseProduct = (props) => {
    const [state, setState] = useState({
        query: '',
        results: {},
        searching: false
    });

    const query = () => {
        setState((prevState) => ({ ...prevState, searching: true }));

        fetch(
            `https://world.openfoodfacts.org/cgi/search.pl?search_terms=${state.query}&search_simple=1&action=process&json=1`
        )
            .then((res) => res.json())
            .then((results) => setState((prevState) => ({ ...prevState, results, searching: false })));
    };

    return (
        <div className="ProductChoose">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Rechercher un produit</label>
                        <div className="flex">
                            <input
                                type="text"
                                className="form-control"
                                value={state.query}
                                onChange={(e) => {
                                    const query = e.target.value;
                                    setState((prevState) => ({ ...prevState, query }));
                                }}
                            />
                            <button
                                disabled={state.searching}
                                className="btn btn-default"
                                style={{ marginLeft: 4 }}
                                onClick={query}
                            >
                                Rechercher
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="images-matching">
                        <ProductList results={state.results} selected={props.filename} selectImage={props.onSelectImage} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseProduct;
