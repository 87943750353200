import React from 'react';
import { Panel, Row } from 'react-bootstrap';
import { fetchApi, sendFile } from '../../../Services/NetworkServcice';
import Creative from './Creative';
import { notify } from 'react-notify-toast';

import '../../../Assets/adminassets.css';
import AddAsset from './Add';
import Loader from './Loading';
import Edit from './Edit';

export default class Assets extends React.Component {
    constructor() {
        super();

        this.state = {
            groups: [],
            signs: [],
            creatives: [],
            slides: [],
            subsequenceSlides: [],
            devices: [],
            selectedCreas: [],
            modalUploadOpened: false,
            modalEditOpened: false,
            loading: false,
            nbFilesUploading: 0,
            selected: null,
            filter: ''
        };

        this.getAssets = this.getAssets.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleChangeCrea = this.handleChangeCrea.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    getAssets() {
        fetchApi('/admin/assets')
            .then((res) => res.json())
            .then(({ creatives, signs, groups, slides, devices, subsequenceSlides }) => {
                this.setState({
                    creatives: creatives.map((c) => {
                        const toRet = c;

                        if (toRet.signId === null) {
                            toRet.signId = [];
                        }

                        return toRet;
                    }),
                    signs,
                    groups,
                    slides,
                    devices,
                    subsequenceSlides
                });
            });
    }

    componentDidMount() {
        this.getAssets();
    }

    async handleUpload(crea) {
        this.setState({ modalUploadOpened: false, loading: true, nbFilesUploading: crea.files.length });

        const data = new FormData();
        data.append('signId', JSON.stringify(crea.signId));
        data.append('groupId', crea.groupId);
        data.append('ledAuto', crea.ledAuto);
        data.append('tags', JSON.stringify(crea.tags));
        data.append('orientation', crea.orientation);

        for (const file of crea.files) {
            data.append(file.name, file);
        }

        sendFile('/admin/assets/upload', {
            method: 'POST',
            body: data
        })
            .then((res) => {
                if (res.status === 204) {
                    notify.show("L'envoi de fichier est termine!", 'success');
                    this.setState({
                        loading: false,
                        authorized: [this.state.sign]
                    });

                    this.getAssets();
                }

                if (res.status === 403) {
                    notify.show(
                        "Vous avez atteint le nombre maximal de créatives autorisées. Vous pouvez supprimer des affiches afin de libérer de l'espace",
                        'warning'
                    );
                    this.setState({
                        loading: false,
                        authorized: [this.state.sign]
                    });
                }
            })
            .catch(async (res) => {
                if (res.status === 500) {
                    notify.show("L'envoi de fichier a echoue", 'warning');
                } else if (res.status === 400) {
                    notify.show('L envoi de donnee a echoue: ' + (await res.text()), 'warning');
                }

                if (res.status === 403) {
                    notify.show(
                        "Vous avez atteint le nombre maximal de créatives autorisées. Vous pouvez supprimer des affiches afin de libérer de l'espace",
                        'warning'
                    );
                }

                this.setState({
                    loading: false
                });
            });
    }

    handleChangeCrea(crea) {
        fetchApi(`/admin/assets/creatives`, {
            method: 'PUT',
            body: JSON.stringify(crea)
        }).then((res) => {
            if (res.status === 200) {
                this.setState({ selected: null });
                return notify.show('OK !', 'success');
            }
        });
    }

    handleDelete(id) {
        fetchApi(`/admin/assets/${id}`, {
            method: 'DELETE'
        }).then((res) => {
            if (res.status === 200) {
                this.setState({ selected: null }, () => this.getAssets());
                return notify.show('OK !', 'success');
            } else if (res.status === 409) {
                this.setState({ selected: null });
                return notify.show('Cet affiche est utilisée par des magasins', 'error');
            } else {
                return notify.show('Erreur', 'warning');
            }
        });
    }

    render() {
        return (
            <Panel>
                <AddAsset
                    modalUploadOpened={this.state.modalUploadOpened}
                    close={(_) => this.setState({ modalUploadOpened: false })}
                    signs={this.state.signs}
                    groups={this.state.groups}
                    onUpload={this.handleUpload}
                    signRespo={this.props.signRespo}
                    user={this.props.user}
                />
                <Edit
                    modalEditOpened={this.state.selected !== null}
                    close={(_) => this.setState({ selected: null })}
                    signs={this.state.signs}
                    groups={this.state.groups}
                    slides={this.state.slides}
                    devices={this.state.devices}
                    creatives={this.state.creatives}
                    selected={this.state.selected}
                    handleSave={this.handleChangeCrea}
                    handleDelete={this.handleDelete}
                    refresh={this.getAssets}
                    signRespo={this.props.signRespo}
                />
                {!this.state.loading || <Loader nb={this.state.nbFilesUploading} />}
                <Panel.Heading className="AdminAssetsHeader">
                    <div>
                        <h5>
                            Affiches ({this.state.creatives.length}
                            {this.props.user.role.Agent.maxUploadedFiles
                                ? ' / ' + this.props.user.role.Agent.maxUploadedFiles
                                : ''}
                            )
                        </h5>
                        <div
                            style={{
                                display: this.state.selectedCreas.length > 0 ? 'inline-block' : 'none',
                                marginLeft: 20
                            }}
                        >
                            <button
                                className="btn btn-danger"
                                onClick={async () => {
                                    if (
                                        !window.confirm(
                                            'Etes vous sur de vouloir supprimer ' +
                                                this.state.selectedCreas.length +
                                                ' créatives?'
                                        )
                                    ) {
                                        return;
                                    }

                                    let failed = 0;

                                    for (const c of this.state.selectedCreas) {
                                        const res = await fetchApi(`/admin/assets/${c}`, {
                                            method: 'DELETE'
                                        });

                                        if (res.status !== 200) {
                                            failed++;
                                        }
                                    }

                                    if (failed === 0) {
                                        notify.show('OK !', 'success');
                                    } else {
                                        notify.show(
                                            'Une erreur est survenue lors de la suppression sur ' +
                                                failed +
                                                ' de ' +
                                                this.state.selectedCreas.length +
                                                ' créatives',
                                            'warning'
                                        );
                                    }

                                    this.setState({ selectedCreas: [] });

                                    this.getAssets();
                                }}
                            >
                                Supprimer la sélection
                            </button>
                        </div>
                        <div className="pull-right">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    className="form-control assetSearchBar"
                                    value={this.state.filter}
                                    onChange={(e) =>
                                        this.setState({
                                            filter: e.target.value
                                        })
                                    }
                                />
                                <i className="fa fa-search" />
                            </div>
                            <button
                                className="btn btn-info"
                                onClick={(_) => this.setState({ modalUploadOpened: true })}
                            >
                                Ajouter
                            </button>
                        </div>
                    </div>
                </Panel.Heading>
                <Panel.Body>
                    <Row>
                        <div className="AdminAssetList">
                            {this.state.creatives
                                .filter((c) => {
                                    const filter = this.state.filter.trim();
                                    if (filter.length === 0) {
                                        return true;
                                    }

                                    const tag = c.tags.find(
                                        (t) => t.toUpperCase().indexOf(filter.toUpperCase()) !== -1
                                    );

                                    let signMatch = null;

                                    if (c.signId.length === 1) {
                                        signMatch = this.state.signs.find(
                                            ({ name }) => name.toUpperCase().indexOf(filter.toUpperCase()) !== -1
                                        );
                                    }

                                    return !!tag || (!!signMatch && c.signId.indexOf(signMatch.id) !== -1);
                                })
                                .reverse()
                                .map((c) => (
                                    <Creative
                                        signs={this.state.signs.filter(({ id }) => c.signId.indexOf(id) !== -1)}
                                        group={this.state.groups.find(({ id }) => c.groupId === id)}
                                        key={c._id}
                                        {...c}
                                        onClick={() => this.setState({ selected: c._id })}
                                        slide={this.state.slides.find(({ _id }) => _id === c._id)}
                                        subsequenceSlide={this.state.subsequenceSlides.find(({ _id }) => _id === c._id)}
                                        checked={this.state.selectedCreas.indexOf(c._id) !== -1}
                                        onChecked={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ selectedCreas: [...this.state.selectedCreas, c._id] });
                                            } else {
                                                this.setState({
                                                    selectedCreas: this.state.selectedCreas.filter((cr) => cr !== c._id)
                                                });
                                            }
                                        }}
                                    />
                                ))}
                        </div>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}
