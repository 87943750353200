import React, { useState } from 'react';
import Calendar from 'react-calendar';
import dateFormat from 'dateformat';
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';

export default (props) => {
    const [state, setState] = useState({
        brandResults: [],
    });

    return (
        <Row>
            <Col lg={2}>
                <div className="form-group">
                    <label htmlFor="name">Nom de la Campagne</label>
                    <input
                        type="text"
                        name="name"
                        autoComplete="off"
                        placeholder="Campagne Coca-Cola"
                        value={props.name}
                        onChange={props.handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="groupName">Nom d'affichage (planning)</label>
                    <input
                        type="text"
                        name="groupName"
                        autoComplete="off"
                        placeholder="Campagne Coca-Cola"
                        value={props.groupName}
                        onChange={props.handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <div className="brand">
                        <div className="form-group">
                            <label>Marque (logo)</label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                    if (e.target.value.length < 2) return;

                                    fetch(
                                        `https://autocomplete.clearbit.com/v1/companies/suggest?query=${e.target.value}`
                                    )
                                        .then((res) => res.json())
                                        .then((json) =>
                                            setState((prevState) => ({ ...prevState, brandResults: json }))
                                        );
                                }}
                            />
                        </div>
                        <p>Resultats:</p>
                        <div className="logos">
                            {state.brandResults.map((r) => (
                                <div key={r.domain}>
                                    <img
                                        src={r.logo}
                                        alt={r.name}
                                        onClick={() =>
                                            props.handleChange({ target: { name: 'logoUrl', value: r.logo } })
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                        {!props.logoUrl || (
                            <React.Fragment>
                                <p>Actuel:</p>
                                <img src={props.logoUrl} alt="logo" />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </Col>
            <Col lg={4}>
                <div className="form-group">
                    <label htmlFor="dates">
                        Dates: {dateFormat(props.startDate, 'dd/mm/yyyy')} - {dateFormat(props.endDate, 'dd/mm/yyyy')}
                    </label>
                    <Calendar
                        value={[props.startDate, props.endDate]}
                        selectRange={true}
                        onChange={props.setDate}
                        showWeekNumbers={true}
                    />
                </div>
            </Col>
            <Col lg={2}>
                <div className="form-group">
                    <label htmlFor="agency">Agence</label>
                    <Select
                        value={
                            props.agency
                                ? {
                                      value: props.agency,
                                      label: props.availableAgencies.find(({ _id }) => _id === props.agency).name,
                                  }
                                : null
                        }
                        onChange={(e) => props.handleSelectChange(e, 'agency')}
                        options={props.availableAgencies
                            .filter(({ type }) => type === 'agency')
                            .map((a) => ({
                                value: a._id,
                                label: a.name,
                            }))}
                    />
                </div>
                {!props.agency || (
                    <div className="form-group">
                        <label htmlFor="contact">Contact</label>
                        <Select
                            value={
                                props.contact
                                    ? {
                                          value: props.contact,
                                          label: props.availableAgencies
                                              .find(({ _id }) => _id === props.agency)
                                              .contacts.find(({ _id }) => _id === props.contact)
                                              ? props.availableAgencies
                                                    .find(({ _id }) => _id === props.agency)
                                                    .contacts.find(({ _id }) => _id === props.contact).name
                                              : props.availableAgencies.find(({ _id }) => _id === props.agency)
                                                    .contacts[0].name,
                                      }
                                    : null
                            }
                            onChange={(e) => props.handleSelectChange(e, 'contact')}
                            options={props.availableAgencies
                                .find(({ _id }) => _id === props.agency)
                                .contacts.map((c) => ({
                                    value: c._id,
                                    label: c.name,
                                }))}
                        />
                    </div>
                )}
            </Col>
            <Col lg={2}>
                <div className="form-group">
                    <label htmlFor="advertiser">Annonceur</label>
                    <Select
                        value={
                            props.advertiser
                                ? {
                                      value: props.advertiser,
                                      label: props.availableAgencies.find(({ _id }) => _id === props.advertiser).name,
                                  }
                                : null
                        }
                        onChange={(e) => props.handleSelectChange(e, 'advertiser')}
                        options={props.availableAgencies
                            .filter(({ type }) => type === 'advertiser')
                            .map((a) => ({
                                value: a._id,
                                label: a.name,
                            }))}
                    />
                </div>
                {!props.advertiser || (
                    <div className="form-group">
                        <label htmlFor="adContact">Contact</label>
                        <Select
                            value={
                                props.adContact
                                    ? {
                                          value: props.adContact,
                                          label: props.availableAgencies
                                              .find(({ _id }) => _id === props.advertiser)
                                              .contacts.find(({ _id }) => _id === props.adContact)
                                              ? props.availableAgencies
                                                    .find(({ _id }) => _id === props.advertiser)
                                                    .contacts.find(({ _id }) => _id === props.adContact).name
                                              : props.availableAgencies.find(({ _id }) => _id === props.advertiser)
                                                    .contacts[0].name,
                                      }
                                    : null
                            }
                            onChange={(e) => props.handleSelectChange(e, 'adContact')}
                            options={props.availableAgencies
                                .find(({ _id }) => _id === props.advertiser)
                                .contacts.map((a) => ({
                                    value: a._id,
                                    label: a.name,
                                }))}
                        />
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="brand">Marque</label>
                    <input
                        type="text"
                        name="brand"
                        value={props.brand}
                        onChange={props.handleChange}
                        className="form-control"
                    />
                </div>
            </Col>
            <Col lg={2}>
                <div className="form-group">
                    <label htmlFor="commercial">Commercial{props.isDyl ? ' DOOHYOULIKE' : ''}</label>
                    <input
                        type="text"
                        name="commercial"
                        className="form-control"
                        value={props.commercial}
                        onChange={props.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="idContrat">ID Contrat</label>
                    <Select
                        onChange={(e) => props.handleSelectChange(e, 'idContrat')}
                        isDisabled={props.triggered}
                        options={[
                            {
                                value: '',
                                label: '-----',
                            },
                            ...props.orders.map((a) => ({
                                value: a.idContrat,
                                label: a.displayName,
                            })),
                        ]}
                        value={{
                            value: props.idContrat,
                            label: (() => {
                                const order = props.orders.find(({ idContrat }) => idContrat === props.idContrat);
                                if (order) {
                                    return order.displayName;
                                }

                                return props.idContrat;
                            })(),
                        }}
                    />
                </div>
                {!props.isDyl || (
                    <div className="form-group prog-camp">
                        <div className="prog-camp-box">
                            <input
                                type="checkbox"
                                checked={props.triggered}
                                className="form-control"
                                onChange={(e) => props.onCheckTriggered(e.target.checked)}
                            />
                            <label>Programmatique</label>
                        </div>
                        <div className="prog-camp-box">
                            <input
                                type="checkbox"
                                checked={props.hideFromInterface}
                                className="form-control"
                                onChange={(e) => props.onCheckHidden(e.target.checked)}
                            />
                            <label>Ne s'affiche pas dans "Marques en avant"</label>
                        </div>
                    </div>
                )}
            </Col>
        </Row>
    );
};
