import React from 'react';
import { Row, Col } from 'react-bootstrap';

const days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche'
];

const generateTimeBlocks = day => {
    const blocks = [];

    for (let i = 0; i < day.length; i++) {
        let j = i + 1;
        let end = day[i];
        while (j < day.length && day[j] === day[j - 1] + 1) {
            end = day[j];
            j++;
        }

        if (end === day[i]) {
            blocks.push(`${day[i]}h`);
        } else {
            blocks.push(`${day[i]} - ${end}`);
        }

        i = j - 1;
    }

    return blocks;
};

export default props => (
    <div className="Planning">
        <Row>
            <Col md={12}>
                <h5>Jours de la semaine</h5>
                <table className="table fixed">
                    <thead>
                        <tr>
                            <th>Tous</th>
                            {days.map((d, i) => (
                                <td className="center" key={d}>
                                    {d}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Option Horaires</td>
                            {Array(7)
                                .fill(0)
                                .map((_, i) => (
                                    <td
                                        onClick={() => props.openTimeModal(i)}
                                        className="center addTimeModal"
                                        key={i}
                                    >
                                        <i className="fa fa-plus" />
                                    </td>
                                ))}
                        </tr>
                        <tr>
                            <td>Sélection</td>
                            {props.planning.map((p, i) => (
                                <td key={i} className="center">
                                    <div key={p}>
                                        {generateTimeBlocks(p).map((p, i) => (
                                            <span
                                                key={i}
                                                className="label label-default timeRange"
                                            >
                                                {p}
                                            </span>
                                        ))}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    </div>
);
