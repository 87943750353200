import moment from 'moment';
import React from 'react';
import { Category } from './Category';

export const FeaturedContent = ({
    categories,
    selectedCreas,
    onCreaSelect,
    onCreaClick,
    setCategoryActive,
    onCategorySelect,
    onAddAssetForCategory,
    slides,
    groups,
    signs
}) => {
    const now = new Date();
    const featured = categories.filter(({ startDate, endDate }) => {
        return new Date(startDate) < now && new Date(endDate) > now;
    });

    const future = categories.filter(({ startDate }) => {
        return new Date(startDate) >= now;
    });

    return (
        <React.Fragment>
            <h1>À la une - {moment().format('DD/MM/yy')}</h1>
            {featured.map((c) => {
                return (
                    <Category
                        category={c}
                        onCategorySelect={onCategorySelect}
                        onCreaClick={onCreaClick}
                        onCreaSelect={onCreaSelect}
                        selectedCreas={selectedCreas}
                        setCategoryActive={setCategoryActive}
                        key={c._id}
                        onAddAssetForCategory={onAddAssetForCategory}
                        slides={slides}
                        signs={signs}
                        groups={groups}
                    />
                );
            })}
            <h1>À venir</h1>
            {future.map((c) => {
                return (
                    <Category
                        category={c}
                        onCategorySelect={onCategorySelect}
                        onCreaClick={onCreaClick}
                        onCreaSelect={onCreaSelect}
                        selectedCreas={selectedCreas}
                        setCategoryActive={setCategoryActive}
                        key={c._id}
                        onAddAssetForCategory={onAddAssetForCategory}
                        slides={slides}
                        signs={signs}
                        groups={groups}
                    />
                );
            })}
        </React.Fragment>
    );
};
