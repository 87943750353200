import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Switch from 'react-switch';
import Calendar from 'react-calendar';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

const priceRe = new RegExp(/^[0-9]+([.,][0-9]{1,2})?$/);

const ButcheryPrice = (props) => (
    <div>
        <div className="groupBox">
            <Row>
                <Col md={5} xs={12}>
                    <div className="form-group">
                        <label>Prix</label>
                        <div className="input-group" style={{ maxWidth: '175px' }}>
                            <input
                                name="price"
                                type="text"
                                className={'form-control' + (props.price.length === 0 ? ' error' : '')}
                                value={props.price}
                                onChange={props.handleChange}
                            />
                            <span className="input-group-addon">Euros</span>
                        </div>
                        {props.price.length > 0 || (
                            <span className="errorLabel" style={{ width: '175px' }}>
                                Ce champs est requis
                            </span>
                        )}
                        {props.price.length === 0 || priceRe.test(props.price) || (
                            <span className="errorLabel" style={{ width: '175px' }}>
                                Champs est invalide (exemple: 1,99)
                            </span>
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col xs={5}>
                            <div className="form-group">
                                <input
                                    type="radio"
                                    name="priceUnit"
                                    checked={props.priceUnit === 'kilo'}
                                    value="kilo"
                                    onChange={props.handleChange}
                                />
                                <label htmlFor="fruit-kilo">Le kg</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={5}>
                            <div className="form-group">
                                <input
                                    type="radio"
                                    name="priceUnit"
                                    checked={props.priceUnit === 'piece'}
                                    value="piece"
                                    onChange={props.handleChange}
                                />
                                <label htmlFor="fruit-piece">Pièce(s)</label>
                            </div>
                        </Col>
                        <Col
                            xs={7}
                            style={{
                                display: props.priceUnit !== 'piece' ? 'none' : 'initial'
                            }}
                        >
                            <div className="input-group">
                                <select
                                    value={props.pieceNb || 1}
                                    name="pieceNb"
                                    onChange={props.handleChange}
                                    className="form-control"
                                >
                                    {Array.apply(null, Array(15)).map((_, i) => (
                                        <option key={i} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                                <span className="input-group-addon">Pièce{props.pieceNb > 1 && 's'}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={5}>
                            <input
                                type="radio"
                                name="priceUnit"
                                value="number"
                                onChange={props.handleChange}
                                checked={props.priceUnit === 'number'}
                            />
                            <select
                                name="weightUnit"
                                style={{
                                    width: 'auto',
                                    display: 'initial'
                                }}
                                value={props.weightUnit}
                                onChange={props.handleChange}
                                className="form-control supportSelect"
                            >
                                <option value="g">Poids</option>
                                <option value="sachet">Sachet</option>
                                <option value="barquette">Barquette</option>
                            </select>
                        </Col>
                        <Col
                            xs={7}
                            style={{
                                display: props.priceUnit !== 'number' ? 'none' : 'initial'
                            }}
                        >
                            <div className="input-group">
                                <input
                                    type="number"
                                    name="weight"
                                    className="form-control weight"
                                    value={props.weight}
                                    onChange={props.handleChange}
                                />
                                <span className="input-group-addon">g</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        <div className="groupBox">
            <Row>
                <Col xs={12}>
                    <Switch
                        className="switch-class switchPromo"
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={40}
                        checked={props.promotion}
                        onChange={(e) => {
                            props.handleChange({
                                target: {
                                    name: 'promotion',
                                    value: e
                                }
                            });

                            const d = new Date();
                            d.setHours(23, 59, 59, 0);

                            props.handleChange({
                                target: {
                                    name: 'expirationDate',
                                    value: d
                                }
                            });
                        }}
                    />
                    <span>
                        Est-ce une{' '}
                        <label htmlFor="promo-input" className="promo-label">
                            Promotion
                        </label>{' '}
                        ?
                    </span>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <Switch
                                className="switch-class switchPromo"
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={40}
                                checked={props.expirationDate !== null}
                                onChange={(e) => {
                                    const d = new Date();
                                    d.setHours(23, 59, 59, 0);
                                    props.handleChange({
                                        target: {
                                            name: 'expirationDate',
                                            value: e ? d : props.promotion ? d : null
                                        }
                                    });
                                }}
                            />
                            <label htmlFor="promo-cal" className="promo-cal">
                                Date d'expiration
                            </label>
                            <p className="indications mt-8">
                                Choisissez une date et une heure d’expiration de cette affiche dans la séquence :
                                (l’affiche s’effacera automatiquement après cette date)
                            </p>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            display: props.expirationDate === null ? 'none' : 'initial'
                        }}
                    >
                        <Col md={12}>
                            <Calendar
                                className="fruit-expiration"
                                onChange={(date) => {
                                    props.handleChange({
                                        target: {
                                            name: 'expirationDate',
                                            value: new Date(date)
                                        }
                                    });
                                }}
                                value={new Date(props.expirationDate)}
                            />
                        </Col>
                    </Row>
                    {!props.allowSlidesDurationChange || (
                        <div>
                            <p>Durée d'affichage: {props.duration}s</p>
                            <input
                                onChange={props.onChangeDuration}
                                type="range"
                                min="1"
                                max={props.maxDuration}
                                value={props.duration}
                            />
                        </div>
                    )}
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <Switch
                                className="switch-class switchPromo"
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={40}
                                checked={props.startDate !== null}
                                onChange={(e) => {
                                    const d = new Date();
                                    d.setHours(0, 0, 0, 0);
                                    props.handleChange({
                                        target: {
                                            name: 'startDate',
                                            value: e ? d : null
                                        }
                                    });
                                }}
                            />
                            <label htmlFor="promo-cal" className="promo-cal" style={{ display: 'initial' }}>
                                Différer le démarrage
                            </label>
                            <p className="indications mt-8">
                                Choisissez une date et une heure pour un démarrage différé de cette affiche dans la
                                séquence :
                            </p>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            display: props.startDate === null ? 'none' : 'initial'
                        }}
                    >
                        <Col md={12}>
                            <Calendar
                                className="fruit-expiration"
                                onChange={(date) => {
                                    props.handleChange({
                                        target: {
                                            name: 'startDate',
                                            value: new Date(date)
                                        }
                                    });
                                }}
                                value={new Date(props.startDate)}
                            />
                            <p>à</p>
                            <TimePicker
                                value={moment(props.startDate)}
                                showSecond={false}
                                onChange={(m) =>
                                    props.handleChange({
                                        target: {
                                            name: 'startDate',
                                            value: m.toDate()
                                        }
                                    })
                                }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </div>
);

export default ButcheryPrice;
