import React, { useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import Loader from 'react-spinners/ScaleLoader';
import swal from 'sweetalert';
import { fetchApi } from '../../../Services/NetworkServcice';
import { CategoriesSidebarBlock } from './CategoriesSidebar';
import { Category } from './Category';
import { AssetReplaceModal, EditAssetModal } from './EditAsset';
import { EditAssetsModal } from './EditAssets';
import { EditCategory } from './EditCategory';
import { FeaturedContent } from './Featured';
import { UploadAsset } from './UploadAsset';

export const toggleDataList = (list, element) => {
    if (list.includes(element)) {
        return list.filter((e) => e !== element);
    } else {
        return [...list, element];
    }
};

const sortCategories = (categories) => {
    return categories.sort((a, b) => a.name.localeCompare(b.name));
};

export const MarronniersV2 = (props) => {
    const [state, setState] = useState({
        loading: true,
        slides: [],
        subsequenceSlides: [],
        categories: [],
        devices: [],
        signs: [],
        selectedCreas: [],
        selectedCrea: null,
        selectedReplaceById: null,
        selectedCategory: null,
        selectedCategoryPage: 'ALL',
        uploadModalForCategory: null,
        modalSelectionOpened: false
    });

    const saveCategory = (category) => {
        setState((prevState) => ({ ...prevState, selectedCategory: null }));

        if (category._id) {
            // Update
            fetchApi(`/admin/maronniers/${category._id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    startDate: category.startDate,
                    visibleDate: category.visibleDate,
                    endDate: category.endDate,
                    expirationDate: category.expirationDate,
                    name: category.name,
                    type: category.type
                })
            }).then((res) => {
                if (res.status === 200) {
                    const categories = [...state.categories];
                    const idx = categories.findIndex(({ _id }) => _id === category._id);
                    if (idx === -1) {
                        throw new Error("Can't find index"); // Can't realistically happen
                    }

                    categories[idx] = {
                        ...categories[idx],
                        startDate: category.startDate,
                        visibleDate: category.visibleDate,
                        endDate: category.endDate,
                        expirationDate: category.expirationDate,
                        name: category.name,
                        type: category.type
                    };

                    setState((prevState) => ({ ...prevState, categories }));

                    notify.show('Informations mises à jour', 'success');
                } else {
                    notify.show('An error occured', 'error');
                }
            });
        } else {
            fetchApi('/admin/maronniers', {
                method: 'POST',
                body: JSON.stringify(category)
            })
                .then((res) => res.json())
                .then((category) =>
                    setState((prevState) => ({
                        ...prevState,
                        categories: sortCategories([...prevState.categories, { ...category, assets: [] }])
                    }))
                );
        }
    };

    const setCategoryActive = (active, id) => {
        const categories = [...state.categories];
        const idx = categories.findIndex(({ _id }) => _id === id);
        if (idx === -1) {
            return; // Can't realistically happen
        }

        categories[idx] = {
            ...categories[idx],
            active
        };

        setState((prevState) => ({ ...prevState, categories }));

        fetchApi(`/admin/maronniers/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ active })
        })
            .then((res) => {
                if (res.status === 200) {
                    notify.show('Informations mises à jour', 'success');
                } else {
                    throw new Error();
                }
            })
            .catch(() => {
                notify.show('An error occured', 'error');

                categories[idx] = {
                    ...categories[idx],
                    active: !active
                };

                setState((prevState) => ({ ...prevState, categories }));
            });
    };

    const deleteCategory = (id) => {
        fetchApi(`/admin/maronniers/${id}`, {
            method: 'DELETE'
        })
            .then((res) => {
                if (res.status === 200) {
                    notify.show('Catégorie supprimée', 'success');
                    const categories = [...state.categories];
                    const idx = categories.findIndex(({ _id }) => _id === id);
                    if (idx === -1) {
                        return; // Can't realistically happen
                    }

                    categories.splice(idx, 1);

                    setState((prevState) => ({
                        ...prevState,
                        categories,
                        selectedCategory: null,
                        selectedCategoryPage: 'ALL'
                    }));
                } else {
                    throw new Error();
                }
            })
            .catch(() => {
                notify.show('An error occured', 'error');
            });
    };

    const deleteCrea = (asset_id, category_id) => {
        const categories = [...state.categories];
        const idx = categories.findIndex(({ _id }) => _id === category_id);
        if (idx === -1) return notify.show('Catégorie inexistante', 'warning');

        const aid = categories[idx].assets.findIndex(({ _id }) => _id === asset_id);
        if (aid === -1) return notify.show('Affiche introuvable dans la catégorie', 'warning');

        categories[idx].assets.splice(aid, 1);

        setState((prevState) => ({ ...prevState, categories, selectedCrea: null }));

        return fetchApi(`/admin/assets/${asset_id}`, {
            method: 'DELETE'
        }).then((res) => {
            if (res.status === 200) {
                return notify.show('OK !', 'success');
            } else {
                return notify.show('Erreur', 'warning');
            }
        });
    };

    const deleteMultipleCreas = async (assets) => {
        for (const a of assets) {
            await deleteCrea(a._id, a.event);
        }

        setState((prevState) => ({ ...prevState, selectedCreas: [] }));
    };

    const updateCrea = (asset, category_id) => {
        const { _id, signId, groupId, tags, defaultExpiration, defaultStart, event } = asset;

        const categories = [...state.categories];
        const idx = categories.findIndex(({ _id }) => _id === category_id);
        if (idx === -1) return notify.show('Erreur', 'warning');

        const aid = categories[idx].assets.findIndex(({ _id }) => _id === asset._id);
        if (aid === -1) return notify.show('Erreur', 'warning');

        categories[idx].assets.splice(aid, 1, asset);

        setState((prevState) => ({ ...prevState, categories, selectedCrea: null }));

        return fetchApi(`/admin/assets/creatives`, {
            method: 'PUT',
            body: JSON.stringify({ _id, signId, groupId, tags, defaultExpiration, defaultStart, event })
        }).then((res) => {
            if (res.status === 200) {
                return notify.show('OK !', 'success');
            }
        });
    };

    const replaceCreaUsage = (old_id, new_id) => {
        return fetchApi(`/admin/maronniers/replace/${old_id}/${new_id}`, {
            method: 'PATCH'
        }).then((res) => {
            if (res.status === 200) {
                return notify.show('OK !', 'success');
            }

            return notify.show('Error', 'error');
        });
    };

    const updateMultipleCreas = async (assets) => {
        for (const a of assets) {
            await updateCrea(a, a.event);
        }

        setState((prevState) => ({ ...prevState, selectedCreas: [], modalSelectionOpened: false }));
    };

    useEffect(() => {
        let to = null;

        const getData = () => {
            fetchApi('/admin/maronniers/signs')
                .then((res) => res.json())
                .then(({ signs }) =>
                    setState((prevState) => ({
                        ...prevState,
                        signs: signs.filter(({ AgentId }) => AgentId === props.user.role.Agent.id)
                    }))
                );

            fetchApi('/admin/maronniers/groups')
                .then((res) => res.json())
                .then((groups) => setState((prevState) => ({ ...prevState, groups })));

            fetchApi('/admin/maronniers')
                .then((res) => res.json())
                .then(({ slides, subsequenceSlides, categories, devices }) => {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                        slides,
                        subsequenceSlides,
                        categories: sortCategories(categories),
                        devices
                    }));

                    to = setTimeout(getData, 20000);
                });
        };

        getData();

        return () => {
            clearTimeout(to);
        };
    }, [props.user.role.Agent.id]);

    if (state.loading) {
        return (
            <div className="fadeInDyl" style={{ textAlign: 'center' }}>
                <Loader color="#596B8C" />
            </div>
        );
    }

    let content = null;

    if (state.selectedCategoryPage === 'ALL') {
        content = (
            <FeaturedContent
                categories={state.categories}
                selectedCreas={state.selectedCreas}
                onCreaSelect={(creas) => setState((prevState) => ({ ...prevState, selectedCreas: creas }))}
                onCreaClick={(crea) => setState((prevState) => ({ ...prevState, selectedCrea: crea }))}
                setCategoryActive={setCategoryActive}
                onCategorySelect={(category) => setState((prevState) => ({ ...prevState, selectedCategory: category }))}
                onAddAssetForCategory={(category) =>
                    setState((prevState) => ({ ...prevState, uploadModalForCategory: category }))
                }
                slides={state.slides}
                signs={state.signs}
                groups={state.groups}
            />
        );
    } else {
        content = (
            <Category
                category={state.categories.find(({ _id }) => _id === state.selectedCategoryPage)}
                selectedCreas={state.selectedCreas}
                onCreaSelect={(creas) => setState((prevState) => ({ ...prevState, selectedCreas: creas }))}
                onCreaClick={(crea) => setState((prevState) => ({ ...prevState, selectedCrea: crea }))}
                setCategoryActive={setCategoryActive}
                onCategorySelect={(category) => setState((prevState) => ({ ...prevState, selectedCategory: category }))}
                onAddAssetForCategory={(category) =>
                    setState((prevState) => ({ ...prevState, uploadModalForCategory: category }))
                }
                slides={state.slides}
                signs={state.signs}
                groups={state.groups}
            />
        );
    }

    return (
        <div className="fadeInDyl dyl-home assets assets-admin">
            <EditAssetModal
                asset={state.selectedCrea}
                slides={state.slides}
                devices={state.devices}
                signs={state.signs}
                groups={state.groups}
                onDelete={deleteCrea}
                categories={state.categories}
                onClose={() => setState((prevState) => ({ ...prevState, selectedCrea: null }))}
                onClickReplace={() =>
                    setState((prevState) => ({ ...prevState, selectedReplaceById: prevState.selectedCrea._id }))
                }
                onUpdate={updateCrea}
            />
            <AssetReplaceModal
                categories={state.categories}
                selectedReplaceById={state.selectedReplaceById}
                asset={state.selectedCrea}
                onReplace={(new_id, o_id) => {
                    replaceCreaUsage(o_id, new_id);
                    setState((prevState) => ({ ...prevState, selectedReplaceById: null, selectedCrea: null }));
                }}
                onClose={() => setState((prevState) => ({ ...prevState, selectedReplaceById: null }))}
            ></AssetReplaceModal>
            <EditAssetsModal
                assets={state.selectedCreas}
                onClose={() => setState((prevState) => ({ ...prevState, modalSelectionOpened: false }))}
                onUpdate={updateMultipleCreas}
                modalSelectionOpened={state.modalSelectionOpened}
                categories={state.categories}
            />
            <EditCategory
                category={state.selectedCategory}
                onClose={() => setState((prevState) => ({ ...prevState, selectedCategory: null }))}
                onSave={saveCategory}
                onDelete={deleteCategory}
            />
            <UploadAsset
                category={state.uploadModalForCategory}
                groups={state.groups}
                onClose={() => setState((prevState) => ({ ...prevState, uploadModalForCategory: null }))}
                onNewCreatives={(assets, category_id) => {
                    const categories = [...state.categories];
                    const idx = categories.findIndex(({ _id }) => _id === category_id);
                    if (idx === -1) return;

                    for (const a of assets) {
                        categories[idx].assets.push(a);
                    }

                    setState((prevState) => ({ ...prevState, categories }));
                }}
                signs={state.signs}
            />
            <div className="content">
                <div className="asset-columns">
                    <div className="side">
                        <div className="card home-editor-cards admin-mar" style={{ marginBottom: 10 }}>
                            <div style={{ marginBottom: 4 }}>Sélection</div>
                            <button
                                onClick={() =>
                                    swal({
                                        text: `Êtes-vous sur de vouloir supprimer ces affiches?`,
                                        title: 'Confirmation de suppression',
                                        icon: 'warning',
                                        buttons: ['Annuler', 'Oui'],
                                        dangerMode: true
                                    }).then((willDelete) => {
                                        if (willDelete) {
                                            deleteMultipleCreas(state.selectedCreas);
                                        }
                                    })
                                }
                                disabled={state.selectedCreas.length === 0}
                                className="btn btn-danger"
                            >
                                Supprimer
                            </button>
                            <button
                                onClick={() => setState((prevState) => ({ ...prevState, modalSelectionOpened: true }))}
                                disabled={
                                    state.selectedCreas.length === 0 ||
                                    !state.selectedCreas.every((a) => a.event === state.selectedCreas[0].event)
                                }
                                className="btn btn-info ml-10"
                            >
                                Modifier
                            </button>
                        </div>
                        <CategoriesSidebarBlock
                            categories={state.categories}
                            selectedCategoryPage={state.selectedCategoryPage}
                            selectCategory={(id) =>
                                setState((prevState) => ({ ...prevState, selectedCategoryPage: id, selectedCreas: [] }))
                            }
                            onNewCategory={(type) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    selectedCategory: {
                                        name: 'Nouvelle catégorie',
                                        startDate: new Date().setHours(0, 0, 0, 0),
                                        visibleDate: new Date().setHours(0, 0, 0, 0),
                                        endDate: new Date().setHours(23, 59, 59, 999),
                                        expirationDate: new Date().setHours(23, 59, 59, 999),
                                        type
                                    }
                                }))
                            }
                        />
                    </div>
                </div>
                <div className="assets">
                    <div className="card card-assets-lib" id="home-editor-card">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
};
