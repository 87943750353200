import React from 'react';
import { Route } from 'react-router-dom';
import withAuth from '../Auth/withAuth';
import Constants from '../../Constants';
import AuthService from '../Auth/AuthService';

import Sidebar from './Sidebar';

import Dashboard from '../../Pages/Admin/Dashboard';
import Library from '../../Pages/Admin/Library';
import AdminScreens from '../../Pages/Admin/Screens';
import ScreenManagement from '../../Pages/Admin/ScreenManagement';
import { SlideManagement } from '../../Pages/Admin/SlidesManagement';
import Stats from '../../Pages/Admin/Stats';
import StatsUser from '../../Pages/Admin/StatsUser';
import ShopWizard from '../../Pages/Admin/ShopWizard';
import Map from '../../Pages/Admin/Map';
import MapVisu from '../../Pages/Admin/MapVisu';

import History from '../../Pages/Admin/History';
import { Dashboard as ManagerDashboard } from '../../Pages/Manager/Shops';
import Newsletter from '../../Pages/Admin/Newsletter';
import CampaignsGallery from '../../Pages/Admin/CampaignsGallery';
import Campaigns from '../../Pages/Admin/Campaigns';
import CampaignEditor from '../../Pages/Admin/Campaigns/Editor';
import BilanCampaign from '../../Pages/Admin/Campaigns/BilanCampaign';
import CampaignUpload from '../../Pages/Admin/Campaigns/Upload/index';
import Agencies from '../../Pages/Admin/Agencies';
import AdminAssets from '../../Pages/Admin/Assets/index';
import Marronier from '../../Pages/Admin/Marronier';
import { AssistantDashboard } from '../../Pages/Assistants/Dashboard';
import Orders from '../../Pages/Admin/Orders';
import Rayon from '../../Pages/Rayon';
import '../../Assets/layout.css';
import ShopInfoReadOnly from '../../Pages/ShopInfoReadOnly';
import Ads from '../../Pages/Ads';
import ShopsLeo from '../../Pages/Admin/Shops';
import Templates from '../../Pages/Admin/Templates';
import HomeV4 from '../../Pages/Home';
import SequencesV2 from '../../Pages/SequencesV2';
import AssetsV2 from '../../Pages/AssetsV2';
import Messages from '../../Pages/Messages';
import Informations from '../../Pages/Informations';
import Tutorials from '../../Pages/Tutorials';
import { fetchApi } from '../../Services/NetworkServcice';
import Irina from '../../Pages/Admin/Irina';
import swal from 'sweetalert';
import Subsequences from '../../Pages/SubsequenceList';
import RayonSequenceList from '../../Pages/RayonsSequenceList';
import { BSCreativesCategories } from '../../Pages/Admin/BSCreativesCategories';
import { MarronniersV2 } from '../../Pages/Admin/Marronniers';
import { MaBoiteDeCom } from '../../Pages/MaBoiteDeCom';
import { Formation } from '../../Pages/Formation';
import Homepage from '../../Pages/ManagedSequence';
import ManagedSequenceEditor from '../../Pages/ManagedSequence/SequenceEditor';

class Header extends React.Component {
    render() {
        return (
            <div className="page-header">
                <nav className="navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header" />
                    </div>
                </nav>
            </div>
        );
    }
}

class WrapperPage extends React.Component {
    render() {
        return (
            <div className="page-inner">
                <div id="main-wrapper">
                    <div className="row show_poster fadeInDyl">
                        <div className="col-md-12">{this.props.children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

class Layout extends React.Component {
    constructor() {
        super();

        this.Auth = new AuthService();

        this.state = {
            user: null,
            sidebarOpen: false,
            unread: 0,
            notifsData: {
                messages: [],
                newAssets: 0,
                screens: []
            }
        };

        this.refreshInterval = null;
        this.refreshNotifsInterval = null;

        this.refreshUnread = this.refreshUnread.bind(this);
        this.refreshNotifs = this.refreshNotifs.bind(this);
        this.setup = this.setup.bind(this);
    }

    setup() {
        this.setState(
            {
                user: null,
                sidebarOpen: false,
                unread: 0,
                notifsData: {
                    messages: [],
                    newAssets: 0,
                    screens: []
                }
            },
            () => {
                this.Auth.fetch(`${Constants.endpoint}/login/role`)
                    .then((json) => {
                        localStorage.setItem('dyl_a_id', json.role?.Agent?.id);
                        setTimeout(
                            () =>
                                this.setState({ user: json }, () =>
                                    setTimeout(() => {
                                        this.refreshUnread();
                                    }, 1000)
                                ),
                            500
                        );

                        this.refreshInterval = setInterval(() => {
                            this.refreshUnread();
                        }, 5 * 60 * 1000);
                    })
                    .catch(() => {
                        swal({
                            icon: 'warning',
                            text: 'Une erreur est survenue lors de la comminucation avec le serveur',
                            buttons: [
                                {
                                    text: 'Se déconnecter',
                                    className: 'danger',
                                    visible: true
                                },
                                {
                                    text: 'Réessayer',
                                    className: 'info',
                                    visible: true
                                }
                            ],
                            title: 'Erreur de connexion'
                        }).then((res) => {
                            if (res) {
                                this.setup();
                            } else {
                                this.props.history.replace('/logout');
                            }
                        });
                    });

                this.refreshNotifs();

                this.refreshNotifsInterval = setInterval(() => {
                    this.refreshNotifs();
                }, 5 * 60 * 1000);
            }
        );
    }

    componentDidMount() {
        this.setup();
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        clearInterval(this.refreshNotifsInterval);
    }

    handleChangePage(title) {
        this.setState({ title: title });
    }

    refreshUnread() {
        this.Auth.fetch(
            `${Constants.endpoint}/messages/${this.state.user.role.role === 'admin' ? 'unreadA' : 'unread'}`
        ).then((res) => {
            this.setState({ unread: res.unread });
        });
    }

    refreshNotifs() {
        fetchApi(`/messages/notifications`)
            .then((res) => {
                if (res.status !== 200) {
                    throw Error('Failed to get notifications.');
                }

                return res.json();
            })
            .then(({ messages, screens, newAssets }) => {
                const now = new Date();

                this.setState({
                    notifsData: {
                        messages,
                        screens: screens.map((s) => ({ ...s, online: (now - new Date(s.lastUpdate)) / 5000 <= 30 })),
                        newAssets
                    }
                });
            });
    }

    render() {
        let routes = null;

        if (!this.state.user) {
            return (
                <div className="dyl-logout-loading">
                    <svg viewBox="0 0 100 100" height="75" width="75">
                        <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#141414" stroke="none"></path>
                    </svg>
                </div>
            );
        }

        if (this.state.user && this.state.user.role.role === 'commerce') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage title="Accueil">
                                <HomeV4 {...props} notifsData={this.state.notifsData} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/ads"
                        render={(props) => (
                            <WrapperPage title="Accueil">
                                <Ads notifsData={this.state.notifsData} {...props} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/assets"
                        render={(props) => (
                            <WrapperPage title="Images">
                                <AssetsV2 notifsData={this.state.notifsData} {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/tuto"
                        render={(props) => (
                            <WrapperPage title="Videos">
                                <Tutorials user={this.state.user} notifsData={this.state.notifsData} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/:id"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <SequencesV2
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    userObject={this.state.user}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/managed-sequences/:id"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <ManagedSequenceEditor
                                    userObject={this.state.user}
                                    {...props}
                                    notifsData={this.state.notifsData}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/new/:name/:orientation"
                        render={(props) => (
                            <WrapperPage title="Nouvelle sequence">
                                <SequencesV2
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    userObject={this.state.user}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/contact"
                        render={(props) => (
                            <WrapperPage title="Contact support">
                                <Messages
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    user={this.state.user}
                                    refreshUnread={this.refreshUnread}
                                    refreshNotifs={this.refreshNotifs}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/rayons"
                        render={(props) => (
                            <WrapperPage title="Contact support">
                                <Rayon {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/pdf-upload"
                        render={(props) => (
                            <WrapperPage title="Contact support">
                                <MaBoiteDeCom {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/formation"
                        render={(props) => (
                            <WrapperPage title="Formation">
                                <Formation {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/informations"
                        render={(props) => (
                            <WrapperPage title="">
                                <Informations notifsData={this.state.notifsData} {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'assetViewer') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage title="Assets">
                                <AssetsV2
                                    {...props}
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    user={this.state.user}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/assets"
                        render={(props) => (
                            <WrapperPage title="Assets">
                                <AssetsV2
                                    {...props}
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    user={this.state.user}
                                />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'admin') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage title="Dashboard">
                                <Dashboard {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/assets"
                        render={(props) => (
                            <WrapperPage title="Affiches">
                                <AdminAssets {...props} admin={true} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/library"
                        render={(props) => (
                            <WrapperPage title="Banque d'images">
                                <Library {...props} admin={true} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/screens/:id"
                        render={(props) => (
                            <WrapperPage title="Gestion écran">
                                <ScreenManagement {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/screens"
                        render={(props) => (
                            <WrapperPage title="Ecrans - Admin">
                                <AdminScreens {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/screensMonitor"
                        render={(props) => (
                            <WrapperPage title="Ecrans - Admin">
                                <AdminScreens {...props} monitorMode={true} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/stats"
                        render={(props) => (
                            <WrapperPage>
                                <Stats {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/stats/:id"
                        render={(props) => (
                            <WrapperPage>
                                <StatsUser {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/wizard"
                        render={(props) => (
                            <WrapperPage>
                                <ShopWizard {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/contact"
                        render={(props) => (
                            <WrapperPage title="Messages">
                                <Messages
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    user={this.state.user}
                                    refreshUnread={this.refreshUnread}
                                    refreshNotifs={this.refreshNotifs}
                                    admin={true}
                                    setupUser={this.setup}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/newsletter"
                        render={(props) => (
                            <WrapperPage title="Newsletter">
                                <Newsletter {...props} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/map"
                        render={(props) => (
                            <WrapperPage title="Map">
                                <Map {...props} user={this.state.user} admin={true} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/history"
                        render={(props) => (
                            <WrapperPage title="History">
                                <History {...props} user={this.state.user.role} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/history/:id"
                        render={(props) => (
                            <WrapperPage title="History">
                                <History {...props} user={this.state.user.role} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/campaignsGallery"
                        render={(props) => (
                            <WrapperPage>
                                <CampaignsGallery {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/campaigns"
                        render={(props) => (
                            <WrapperPage title="Campagnes">
                                <Campaigns {...props} user={this.state.user.role} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/bilan"
                        render={(props) => (
                            <WrapperPage title="Bilan Campagne">
                                <BilanCampaign {...props} user={this.state.user.role} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/orders"
                        render={(props) => (
                            <WrapperPage title="Ordres d'insertion">
                                <Orders {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/campaigns/upload"
                        render={(props) => (
                            <WrapperPage title="Campagnes Upload">
                                <CampaignUpload {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/campaigns/edit/:id"
                        render={(props) => (
                            <WrapperPage title="Campagnes">
                                <CampaignEditor user={this.state.user.role} {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/agencies"
                        render={(props) => (
                            <WrapperPage title="Agencies">
                                <Agencies />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/maronnier-old"
                        render={() => (
                            <WrapperPage>
                                <Marronier user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/maronnier"
                        render={() => (
                            <WrapperPage>
                                <MarronniersV2 user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/leo"
                        render={() => (
                            <WrapperPage>
                                <ShopsLeo></ShopsLeo>
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/irina"
                        render={() => (
                            <WrapperPage>
                                <Irina user={this.state.user}></Irina>
                            </WrapperPage>
                        )}
                    ></Route>
                    <Route
                        exact
                        path="/templates"
                        render={() => (
                            <WrapperPage>
                                <Templates />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/bs_creatives"
                        render={() => (
                            <WrapperPage>
                                <BSCreativesCategories></BSCreativesCategories>
                            </WrapperPage>
                        )}
                    ></Route>
                    <Route
                        exact
                        path="/slides-usage"
                        render={() => (
                            <WrapperPage>
                                <SlideManagement />
                            </WrapperPage>
                        )}
                    ></Route>
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'manager') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage title="Commerces">
                                <ManagerDashboard {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <Subsequences {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/new/:name/:orientation"
                        render={(props) => (
                            <WrapperPage title="Sequences - Nouvelle">
                                <SequencesV2 {...props} isSubsequence={true} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/:id"
                        render={(props) => (
                            <WrapperPage title="Edition sequence">
                                <SequencesV2 {...props} isSubsequence={true} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'assistant') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage>
                                <AssistantDashboard {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/map"
                        render={(props) => (
                            <WrapperPage title="Map">
                                <Map {...props} user={this.state.user} admin={false} assistant={true} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/screens/:id"
                        render={(props) => (
                            <WrapperPage title="Gestion écran">
                                <ScreenManagement {...props} assistant={true} />
                            </WrapperPage>
                        )}
                    />

                    <Route
                        exact
                        path="/screens"
                        render={(props) => (
                            <WrapperPage title="Ecrans - Admin">
                                <AdminScreens {...props} assistant={true} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/screensMonitor"
                        render={(props) => (
                            <WrapperPage title="Ecrans - Admin">
                                <AdminScreens {...props} monitorMode={true} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/history"
                        render={(props) => (
                            <WrapperPage title="History">
                                <History {...props} user={this.state.user.role} assistant={true} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/history/:id"
                        render={(props) => (
                            <WrapperPage title="History">
                                <History {...props} user={this.state.user.role} assistant={true} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/campaigns/upload"
                        render={(props) => (
                            <WrapperPage title="Campagnes Upload">
                                <CampaignUpload {...props} user={this.state.user.role} assistant={true} />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'commercial') {
            routes = (
                <div>
                    <Route exact path="/" render={(props) => <WrapperPage>Prospect</WrapperPage>} />
                    <Route
                        exact
                        path="/map"
                        render={(props) => (
                            <WrapperPage title="Map">
                                <Map {...props} user={this.state.user} admin={true} />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'dylSequence') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage>
                                <Homepage {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/:id"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <ManagedSequenceEditor userObject={this.state.user} {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/new/:name/:orientation"
                        render={(props) => (
                            <WrapperPage title="Nouvelle sequence">
                                <ManagedSequenceEditor userObject={this.state.user} {...props} />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (
            this.state.user &&
            (this.state.user.role.role === 'signRespo' ||
                this.state.user.role.role === 'signRespoBasic' ||
                this.state.user.role.role === 'signVisu')
        ) {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage title="Map">
                                <MapVisu
                                    {...props}
                                    user={this.state.user}
                                    admin={false}
                                    assistant={true}
                                    sign={true}
                                    userObject={this.state.user}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/contact"
                        render={(props) => (
                            <WrapperPage title="Messages">
                                <Messages
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    user={this.state.user}
                                    refreshUnread={this.refreshUnread}
                                    refreshNotifs={this.refreshNotifs}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/assets"
                        render={(props) => (
                            <WrapperPage title="Affiches">
                                <AdminAssets {...props} admin={false} user={this.state.user} signRespo={true} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <Subsequences {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/:id"
                        render={(props) => (
                            <WrapperPage title="Edition sequence">
                                <SequencesV2 {...props} isSubsequence={true} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/new/:name/:orientation"
                        render={(props) => (
                            <WrapperPage title="Sequences - Nouvelle">
                                <SequencesV2 {...props} isSubsequence={true} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/visu"
                        render={(props) => (
                            <WrapperPage title="Visualisation">
                                <ShopInfoReadOnly {...props} sign={true} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'rayon') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <RayonSequenceList notifsData={this.state.notifsData} {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <RayonSequenceList notifsData={this.state.notifsData} {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/new/:name/:orientation"
                        render={(props) => (
                            <WrapperPage title="Nouvelle sequence">
                                <SequencesV2
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    userObject={this.state.user}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/:id"
                        render={(props) => (
                            <WrapperPage title="Edition sequence">
                                <SequencesV2
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    userObject={this.state.user}
                                />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/contact"
                        render={(props) => (
                            <WrapperPage title="Messages">
                                <Messages
                                    notifsData={this.state.notifsData}
                                    {...props}
                                    user={this.state.user}
                                    refreshUnread={this.refreshUnread}
                                    refreshNotifs={this.refreshNotifs}
                                />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        } else if (this.state.user && this.state.user.role.role === 'groupAdmin') {
            routes = (
                <div>
                    <Route
                        exact
                        path="/sequences"
                        render={(props) => (
                            <WrapperPage title="Sequences">
                                <Subsequences {...props} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/new/:name/:orientation"
                        render={(props) => (
                            <WrapperPage title="Sequences - Nouvelle">
                                <SequencesV2 {...props} isSubsequence={true} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/sequences/:id"
                        render={(props) => (
                            <WrapperPage title="Edition sequence">
                                <SequencesV2 {...props} isSubsequence={true} userObject={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                    <Route
                        exact
                        path="/assets"
                        render={(props) => (
                            <WrapperPage title="Affiches">
                                <AdminAssets {...props} admin={false} user={this.state.user} />
                            </WrapperPage>
                        )}
                    />
                </div>
            );
        }

        return (
            <div className={'page-container' + (this.state.sidebarOpen ? ' page-sidebar-visible' : '')}>
                <Sidebar
                    userBO={this.state.user}
                    sideBarOpen={this.state.sidebarOpen}
                    {...this.props}
                    handleSwitchSidebar={() => {
                        this.setState({
                            sidebarOpen: !this.state.sidebarOpen
                        });
                    }}
                    unread={this.state.unread}
                    setupUser={this.setup}
                />
                <div className="page-content">
                    <Header
                        handleSwitchSidebar={() => {
                            this.setState({
                                sidebarOpen: !this.state.sidebarOpen
                            });
                        }}
                    />
                    {routes}
                </div>
            </div>
        );
    }
}

export default withAuth(Layout);
