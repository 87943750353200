import React from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import TagsInput from 'react-tagsinput';
import { formats } from '../../../Constants';

export default class AddAsset extends React.Component {
    constructor(props) {
        super();

        this.state = {
            files: [],
            groupId: null,
            signId: [],
            tags: [],
            orientation: props.user?.role?.Agent?.id === 11 ? 'landscape' : 'portrait',
            ledAuto: props.user?.role?.Agent?.id === 5,

            // Page
            label: 'Tous',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.hanldeDrop = this.hanldeDrop.bind(this);
        this.reset = this.reset.bind(this);
    }

    hanldeDrop(files) {
        this.setState((prevState) => ({ files: [...prevState.files, ...files] }));
    }

    reset() {
        this.setState({
            files: [],
            groupId: null,
            signId: [],
            tags: [],
            orientation: 'portrait',

            // Page
            label: 'Tous',
        });
    }

    handleSubmit() {
        if (!this.state.files.length === 0) {
            return alert('Fichier manquant');
        }

        if (this.props.signRespo) {
            this.props.onUpload(this.state);
            this.reset();
            return;
        }

        if (this.state.signId.length === 0) {
            return alert("Merci d'ajouter une enseigne");
        }

        this.props.onUpload(this.state);
        this.reset();
    }

    render() {
        const agentId = this.props.user?.role?.Agent?.id;
        let formatsUsable = { ...formats };
        if (agentId === 11) {
            formatsUsable = {
                landscape: formats.landscape
            };
        }

        return (
            <Modal show={this.props.modalUploadOpened} onHide={this.props.close}>
                <Modal.Header closeButton>Upload d'affiche</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <p>Fichiers (max 100Mo):</p>
                            <Dropzone
                                style={{
                                    width: '100%',
                                    height: '200px',
                                    borderWidth: '2px',
                                    borderColor: 'rgb(102, 102, 102)',
                                    borderStyle: 'dashed',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    padding: '25px',
                                    marginBottom: '24px',
                                }}
                                onDrop={this.hanldeDrop}
                                accept=".mp4,image/*"
                            >
                                {this.state.files.map((f) => (
                                    <p key={f.name}>{f.name}</p>
                                ))}
                            </Dropzone>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} hidden={this.props.signRespo}>
                            <div className="form-group">
                                <label htmlFor="signs">Enseignes</label>
                                <br />
                                Tous
                                <input
                                    type="checkbox"
                                    checked={this.props.signs.length === this.state.signId.length}
                                    onChange={(e) =>
                                        this.setState({
                                            signId: e.target.checked ? this.props.signs.map(({ id }) => id) : [],
                                        })
                                    }
                                />
                                <ul>
                                    {this.props.signs.map((s) => (
                                        <li key={s.id}>
                                            <input
                                                type="checkbox"
                                                checked={this.state.signId.indexOf(s.id) !== -1}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        this.setState({
                                                            signId: [...this.state.signId, s.id],
                                                        });
                                                    } else {
                                                        this.setState({
                                                            signId: this.state.signId.filter((i) => i !== s.id),
                                                        });
                                                    }
                                                }}
                                            />{' '}
                                            {s.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group" hidden={this.props.signRespo}>
                                <label htmlFor="group">Groupe</label>
                                <Select
                                    options={[
                                        { label: 'Tous', value: null },
                                        ...this.props.groups.map(({ id, name }) => ({
                                            label: name,
                                            value: id,
                                        })),
                                    ]}
                                    value={{
                                        label: this.state.label,
                                        value: this.state.groupId,
                                    }}
                                    onChange={(e) =>
                                        this.setState({
                                            groupId: e.value,
                                            label: e.label,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="tags">Tags:</label>
                                <TagsInput value={this.state.tags} onChange={(tags) => this.setState({ tags })} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="orientation">Orientation</label>
                                <select
                                    name="orientation"
                                    value={this.state.orientation}
                                    onChange={(e) => this.setState({ orientation: e.target.value })}
                                    className="form-control"
                                >
                                    {Object.keys(formatsUsable).map((f) => (
                                        <option key={f} value={f}>
                                            {formatsUsable[f].name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {agentId === 11 || (
                                <div className="form-group">
                                    <label htmlFor="ledAuto">Ajuster aux écrans LED</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        checked={this.state.ledAuto}
                                        onChange={(e) => this.setState({ ledAuto: e.target.checked })}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-info" onClick={this.handleSubmit}>
                        Ajouter
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
