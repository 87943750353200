import React, { useEffect, useState } from 'react';
import { formats, getShopName } from '../Constants';
import { fetchApi } from '../Services/NetworkServcice';
import dateFormat from 'dateformat';
import { Modal } from 'react-bootstrap';
import HeaderNotifications from '../Components/HeaderNotifications';
import { Link } from 'react-router-dom';
import ScreenManagementTimes from './Admin/ScreenManagementTimes';
import { notify } from 'react-notify-toast';
import { getSignName } from '../Constants';

const getDeviceStatus = (status, loadedDate, lastPing) => {
    if (!status) {
        return 'No status found';
    }

    if (status === 'Running') {
        return (loadedDate - new Date(lastPing)) / 5000 <= 30 ? (
            <span className="label label-success">Running</span>
        ) : (
            <span className="label label-danger">Off</span>
        );
    } else if (status === 'Downloading') {
        return <span className="label label-warning">Downloading</span>;
    } else if (status === 'Paired') {
        return <span className="label label-success">Linked</span>;
    }
};

const getScreenStatus = (status) => {
    if (!status) {
        return 'No status found';
    }

    let rStatus = <span className="label label-info">???</span>;

    switch (status) {
        case 'ON':
            rStatus = <span className="label label-success">ON</span>;
            break;
        case 'OFF':
            rStatus = <span className="label label-danger">OFF</span>;
            break;
        case 'VEILLE':
            rStatus = <span className="label label-warning">VEILLE</span>;
            break;
        default:
            break;
    }

    return rStatus;
};

const Informations = (props) => {
    const [state, setState] = useState({
        screens: [],
        statuses: [],
        modalOpen: false,
        linkToken: '',
        devName: '',
        orientation: 'portrait',
        loadDate: null,
        shop: null,
        selectedDevice: -1,
        showOnOffTimes: false,
        staticIp: '',
        routerIp: '',
        isStaticIp: false
    });

    useEffect(() => {
        fetchApi('/screens')
            .then((res) => res.json())
            .then(({ screens, statuses }) =>
                setState((prevState) => ({
                    ...prevState,
                    screens,
                    statuses: statuses.map((s) => {
                        if (!s.screenStatus) {
                            return { screenStatus: '???' };
                        }

                        return s;
                    }),
                    loadDate: new Date(),
                    selectedDevice: screens.length - 1
                }))
            );

        fetchApi('/shop')
            .then((res) => res.json())
            .then(({ shop }) =>
                setState((prevState) => ({
                    ...prevState,
                    shop
                }))
            );
    }, []);

    const onLink = () => {
        setState({
            ...state,
            modalOpen: false
        });

        fetchApi(`/devices/register`, {
            method: 'POST',
            body: JSON.stringify({
                token: state.linkToken,
                name: state.devName,
                orientation: state.orientation,
                isStaticIp: state.isStaticIp,
                staticIp: state.staticIp,
                routerIp: state.routerIp
            })
        }).then(() =>
            fetchApi('/screens')
                .then((res) => res.json())
                .then(({ screens, statuses }) =>
                    setState((prevState) => ({
                        ...prevState,
                        screens,
                        statuses: statuses.map((s) => {
                            if (!s.screenStatus) {
                                return { screenStatus: '???' };
                            }

                            return s;
                        }),
                        loadDate: new Date(),
                        selectedDevice: screens.length - 1
                    }))
                )
        );
    };

    if (state.shop === null) {
        return null;
    }

    const onOffTimes = state.selectedDevice === -1 ? null : JSON.parse(state.screens[state.selectedDevice].onOffTimes);

    return (
        <div className="dyl-home info">
            <Modal show={state.modalOpen} onHide={() => setState({ ...state, modalOpen: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un ecran</Modal.Title>
                    <Modal.Body>
                        <h5>Merci d'entrer le code qui s'affiche sur votre player</h5>
                        <label htmlFor="token">Token</label>
                        <input
                            className="form-control"
                            type="text"
                            onChange={(e) => setState({ ...state, linkToken: e.target.value })}
                            value={state.linkToken}
                            name="linkToken"
                        />
                        <label htmlFor="name">Nom:</label>
                        <input
                            type="text"
                            name="devName"
                            value={state.devName}
                            onChange={(e) => setState({ ...state, devName: e.target.value })}
                            className="form-control"
                        />
                        <label htmlFor="orientation">Orientation</label>
                        <select
                            className="form-control"
                            name="orientation"
                            value={state.orientation}
                            onChange={(e) => setState({ ...state, orientation: e.target.value })}
                        >
                            {Object.keys(formats)
                                .sort((a, b) => formats[b].name.localeCompare(formats[a].name))
                                .map((f) => (
                                    <option key={f} value={f}>
                                        {formats[f].name}
                                    </option>
                                ))}
                        </select>
                        <input
                            style={{ display: 'none' }}
                            type="checkbox"
                            checked={state.isStaticIp}
                            onChange={(e) => setState({ ...state, isStaticIp: !state.isStaticIp })}
                        />{' '}
                        {/* IP fixe: <br /> */}
                        <div style={{ display: state.isStaticIp ? 'block' : 'none' }}>
                            <label htmlFor="name">IP:</label>
                            <input
                                type="text"
                                value={state.staticIp}
                                onChange={(e) => setState({ ...state, staticIp: e.target.value })}
                                className="form-control"
                                placeholder="192.168.1.160"
                            />
                            <label htmlFor="name">IP Routeur:</label>
                            <input
                                type="text"
                                value={state.routerIp}
                                onChange={(e) => setState({ ...state, routerIp: e.target.value })}
                                className="form-control"
                                placeholder="192.168.1.1"
                            />
                        </div>
                        <button className="btn btn-info pull-right" onClick={onLink} style={{ marginTop: '10px' }}>
                            Lier
                        </button>
                    </Modal.Body>
                </Modal.Header>
            </Modal>
            <header>
                <Link to="/" className="logo">
                    <img src="/image.png" alt="logo" />
                </Link>
                <p className="hidden-xs">Informations</p>
                <h3>Informations du {getShopName().toLowerCase()}</h3>
                <HeaderNotifications {...props.notifsData} />
            </header>
            <div className="content">
                <div className="mobile-controls">
                    <div className="btn-add" onClick={() => setState({ ...state, modalOpen: true })}>
                        <i className="fa fa-plus"></i>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="mag">
                        <div className="card">
                            <h3>{getShopName()}</h3>
                            <div className="mag-info-flex m24">
                                <span>Nom</span>
                                <span>{state.shop.name}</span>
                            </div>
                            <div className="mag-info-flex">
                                <span>{getSignName()}</span>
                                <span>{state.shop.Sign.name}</span>
                            </div>
                            <div className="mag-info-flex">
                                <span>Adresse</span>
                                <span>{state.shop.address}</span>
                            </div>
                            <div className="mag-info-flex">
                                <span>Zip</span>
                                <span>{state.shop.zip}</span>
                            </div>
                            <div className="mag-info-flex">
                                <span>Ville</span>
                                <span>{state.shop.city}</span>
                            </div>
                            <div className="mag-info-flex">
                                <span>Écrans</span>
                                <span>{state.screens.length}</span>
                            </div>
                            {state.shop.name !== 'DOOHYOULIKE' || (
                                <div className="mag-info-flex">
                                    <span>User Agent</span>
                                    <span>{navigator.userAgent}</span>
                                </div>
                            )}
                        </div>
                        <div className="card">
                            <h3>Référent {getShopName().toLowerCase()}</h3>
                            <div className="mag-info-flex m24">
                                <span>Contact</span>
                                <span>{state.shop.contact || '-'}</span>
                            </div>
                            <div className="mag-info-flex">
                                <span>Tel</span>
                                <span>{state.shop.tel || '-'}</span>
                            </div>
                            <div className="mag-info-flex">
                                <span>Mail</span>
                                <span>{state.shop.mail || '-'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="screens">
                        <div className="card">
                            <h3>Ecrans</h3>
                            <div className="flex-screens">
                                <select
                                    className="form-control"
                                    value={state.selectedDevice}
                                    onChange={(e) => setState({ ...state, selectedDevice: parseInt(e.target.value) })}
                                >
                                    {state.screens.map((s, i) => (
                                        <option key={s['Ref #']} value={i}>
                                            {s.Nom}
                                        </option>
                                    ))}
                                </select>
                                <div
                                    className="btn-dyl hidden-xs"
                                    onClick={() => setState({ ...state, modalOpen: true })}
                                >
                                    <i className="fa fa-plus"></i>
                                    Ajouter écran
                                </div>
                                {!localStorage.getItem('old_token') || (
                                    <button
                                        className="btn-dyl ml-10"
                                        style={{ marginRight: '1em' }}
                                        onClick={() => {
                                            const name = window.prompt('Nouveau nom?');

                                            if (name.trim().length < 1) {
                                                return alert('Nom invalide');
                                            }

                                            fetchApi(
                                                '/admin/shops/screen/' + state.screens[state.selectedDevice]['Ref #'],
                                                {
                                                    method: 'PUT',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'x-access-token': localStorage.getItem('old_token')
                                                    },
                                                    body: JSON.stringify({
                                                        name
                                                    })
                                                }
                                            ).then((res) => {
                                                if (res.status === 200) {
                                                    setState({
                                                        ...state,
                                                        screens: state.screens.map((s) => {
                                                            if (
                                                                s['Ref #'] ===
                                                                state.screens[state.selectedDevice]['Ref #']
                                                            ) {
                                                                return {
                                                                    ...s,
                                                                    Nom: name
                                                                };
                                                            }

                                                            return s;
                                                        })
                                                    });

                                                    return notify.show('Ok!', 'success');
                                                }
                                            });
                                        }}
                                    >
                                        Renommer
                                    </button>
                                )}
                            </div>
                            {state.selectedDevice === -1 || (
                                <div className="screen-data">
                                    <div className="device-status">
                                        <div className="mag-info-flex">
                                            <span>
                                                <i className="fa fa-images"></i>Orientation:
                                            </span>
                                            <span>{formats[state.screens[state.selectedDevice].Orientation].name}</span>
                                        </div>
                                        <div className="mag-info-flex">
                                            <span>
                                                <i className="fa fa-wifi"></i>Connexion:
                                            </span>
                                            <span>
                                                {getDeviceStatus(
                                                    state.statuses[state.selectedDevice].status,
                                                    state.loadDate,
                                                    state.statuses[state.selectedDevice].lastPing
                                                )}
                                            </span>
                                        </div>
                                        <div className="mag-info-flex">
                                            <span>
                                                <i className="fa fa-power-off"></i>Etat:
                                            </span>
                                            <span>
                                                {getScreenStatus(
                                                    state.statuses[state.selectedDevice].screenStatus
                                                        .replace('IN TRANSITION FROM STANDBY TO ON', 'ON')
                                                        .replace('UNKNOWN', 'OFF')
                                                        .replace('STANDBY', 'VEILLE')
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="on-off-hours">
                                        <div className="mag-info-flex">
                                            <span style={{ width: 'auto' }}>
                                                <i className="fa fa-clock"></i>Allumage - Extinction:
                                            </span>
                                        </div>
                                        <div className="flex-days">
                                            {!state.screens[state.selectedDevice].onOffTimes ||
                                                ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((d, i) => (
                                                    <p className="on-off-time" key={d}>
                                                        <span>{d}:</span>
                                                        {onOffTimes[i][0]} - {onOffTimes[i][1]}
                                                    </p>
                                                ))}
                                            {!props.user.role.Agent.onOffTimesEditAllowed || (
                                                <button
                                                    onClick={() => setState({ ...state, showOnOffTimes: true })}
                                                    className="btn btn-info mt-16"
                                                >
                                                    Edit
                                                </button>
                                            )}
                                            <ScreenManagementTimes
                                                show={state.showOnOffTimes}
                                                hide={() => setState({ ...state, showOnOffTimes: false })}
                                                onOffTimes={onOffTimes || []}
                                                saveDateTimes={(dt) => {
                                                    fetchApi(
                                                        '/shop/onOffTimes/' +
                                                            state.screens[state.selectedDevice]['Ref #'],
                                                        {
                                                            method: 'PUT',
                                                            body: JSON.stringify({ times: dt })
                                                        }
                                                    ).then((res) => {
                                                        if (res.status === 200) {
                                                            setState({
                                                                ...state,
                                                                screens: state.screens.map((s) => {
                                                                    if (
                                                                        s['Ref #'] ===
                                                                        state.screens[state.selectedDevice]['Ref #']
                                                                    ) {
                                                                        return {
                                                                            ...s,
                                                                            onOffTimes: JSON.stringify(dt)
                                                                        };
                                                                    } else {
                                                                        return s;
                                                                    }
                                                                }),
                                                                showOnOffTimes: false
                                                            });
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="additionnal-info">
                                        <div className="mag-info-flex">
                                            <span>Référence n°:</span>
                                            <span>{state.screens[state.selectedDevice]['Ref #']}</span>
                                        </div>
                                        <div className="mag-info-flex">
                                            <span>Version player:</span>
                                            <span>{state.screens[state.selectedDevice]['Version Player']}</span>
                                        </div>
                                        <div className="mag-info-flex">
                                            <span>Dernier ping:</span>
                                            <span>
                                                {dateFormat(
                                                    state.statuses[state.selectedDevice].lastPing,
                                                    'dd/mm - HH:MM'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Informations;
