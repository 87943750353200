import React, { useState } from 'react';
import Select from 'react-select';
import constants from '../../Constants';

const getKeyFromValue = (obj, value) => {
    for (const k of Object.keys(obj).reverse()) {
        if (obj[k].indexOf(value) !== -1) {
            return k;
        }
    }

    return '';
};

const ChooseCategory = props => {
    const key = getKeyFromValue(props.options, props.filename);
    const nb = key ? props.options[key].length : 0;
    const selected = key ? props.options[key].indexOf(props.filename) : -1;

    const [animal, setAnimal] = useState('Poisson frais');

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Choisir Poisson/Fruits de mer:</label>
                        <Select
                            options={['Poisson frais', 'Fruits de mer'].map(f => ({
                                label: f,
                                value: f
                            }))}
                            onChange={e => setAnimal(e.value)}
                            value={{
                                label: animal,
                                value: animal
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Choisir un produit:</label>
                        <Select
                            options={Object.keys(props.options)
                                .filter(o => {
                                    const toCheck = [
                                        'Araignée',
                                        'Bigorneaux',
                                        'Bulots',
                                        'Crevettes',
                                        'Coques',
                                        'Concombre de Mer',
                                        'Couteau de Mer',
                                        'Etoile de Mer',
                                        'Homard',
                                        'Huîtres',
                                        'Huîtres de Marennes',
                                        'Langouste',
                                        'Langoustines',
                                        'Langoustines vivantes',
                                        'Moules',
                                        'Ormeaux ',
                                        'Palourdes',
                                        'Plateau De Fruits De Mer',
                                        'Saint-Jacques',
                                        'Tourteau (Crabe)'
                                    ];

                                    if (props.cat[o] === 'Fruits de mer') {
                                        return animal === 'Fruits de mer';
                                    }

                                    if (animal === 'Fruits de mer') {
                                        return toCheck.indexOf(o) !== -1;
                                    }

                                    return toCheck.indexOf(o) === -1;
                                })
                                .sort((a, b) => a.localeCompare(b))
                                .map(f => ({
                                    label: f,
                                    value: f
                                }))}
                            onChange={e => {
                                props.handleSelectOption(e.value);
                                props.handleSelectPiece(e.value);
                            }}
                            value={{
                                label: key,
                                value: key
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sub">Nom</label>
                        <input
                            type="text"
                            value={props.category}
                            onChange={e => props.handleSelectPiece(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sub">Sous titre</label>
                        <input
                            type="text"
                            value={props.fishSub}
                            onChange={e => props.handleChangeSub(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            {!key || (
                <div
                    className="controls"
                    style={{
                        display: nb > 1 ? 'block' : 'none'
                    }}
                >
                    <p>
                        Sélectionnez une image <i className="fa fa-angle-double-right ip5" />
                    </p>
                    <div>
                        {props.options[key].map((f, i) => (
                            <img
                                className={i === selected ? 'fruitSelection active' : 'fruitSelection'}
                                key={f}
                                src={`${constants.cloud_storage}/Assets/Library/FishShop/${f}`}
                                onClick={() => props.handleSwitchOption(i, key)}
                                height="100"
                                alt="Fish"
                            />
                        ))}
                    </div>
                    <hr className="visible-xs" style={{ marginBottom: '-10px' }} />
                </div>
            )}
        </React.Fragment>
    );
};

export default ChooseCategory;
