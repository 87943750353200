import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { fetchApi } from '../../Services/NetworkServcice';
import dateFormat from 'dateformat';

const groupHistoriesByDate = histories => {
    const dates = {};

    histories = histories.filter(({ shopId }) => shopId !== 2); // No doohyoulike

    for (const h of histories) {
        const d = new Date(h.date);
        d.setHours(0, 0, 0, 0);

        if (!dates[d.getTime()]) {
            dates[d.getTime()] = [];
        }

        dates[d.getTime()].push(h);
    }

    return dates;
};

export class AssistantDashboard extends React.Component {
    constructor() {
        super();

        this.state = {
            histories: [],
            shops: [],
            selectedHistory: -1
        };
    }

    componentDidMount() {
        fetchApi('/admin/history/stats')
            .then(res => res.json())
            .then(histories => this.setState({ histories }));

        fetchApi('/admin/shops')
            .then(res => res.json())
            .then(({ shops }) => this.setState({ shops }));
    }

    render() {
        const datesGrouped = groupHistoriesByDate(this.state.histories);

        return (
            <Panel>
                <h3>Résumé de l'activité</h3>
                <Row>
                    <Col md={10}>
                        <div className="tableAssistants">
                            <div className="tRow">
                                <span className="title">Date</span>
                                <span className="title">Nombre de visites</span>
                            </div>
                            {Object.keys(datesGrouped).map((d, i) => (
                                <React.Fragment key={d}>
                                    <div
                                        className="tRow"
                                        onClick={() =>
                                            this.setState(prevState => ({
                                                selectedHistory: prevState.selectedHistory === i ? -1 : i
                                            }))
                                        }
                                    >
                                        <span>{dateFormat(parseInt(d), 'dd/mm')}</span>
                                        <span>{[...new Set(datesGrouped[d].map(({ shopId }) => shopId))].length}</span>
                                    </div>
                                    {this.state.selectedHistory === i && (
                                        <table className="table responsive">
                                            <thead>
                                                <tr>
                                                    <th>Magasin</th>
                                                    <th>Assistant</th>
                                                    <th>Heure</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {datesGrouped[d].filter((v, i, arr) => {
                                                    for (let j = i + 1; j < arr.length; j++) {
                                                        if (v.shopId === arr[j].shopId) {
                                                            return false;
                                                        }
                                                    }

                                                    return true;
                                                }).map(v => {
                                                    const shop = this.state.shops.find(({ id }) => id === v.shopId);

                                                    if (!shop) {
                                                        return null;
                                                    }

                                                    return (
                                                        <tr
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() =>
                                                                this.props.history.push(`/history/${shop.id}`)
                                                            }
                                                            key={v._id}
                                                        >
                                                            <td>{shop.name}</td>
                                                            <td>{v.author}</td>
                                                            <td>{dateFormat(v.date, 'HH:MM')}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Panel>
        );
    }
}
