import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import dateFormat from 'dateformat';
import Select from 'react-select';
import Calendar from 'react-calendar';
import { notify } from 'react-notify-toast';
import Switch from 'react-switch';
import swal from 'sweetalert';
import { PropagateLoader } from 'react-spinners';

import '../../Assets/campaign.css';
import { fetchApi } from '../../Services/NetworkServcice';

const Auth = new AuthService();

const CampaignDetails = (props) => (
    <div>
        <div className="buttons">
            <button className="btn btn-info" disabled={props.activeCampaign === null} onClick={props.handleUpdate}>
                Enregistrer
            </button>
            <button className="btn btn-danger" disabled={props.activeCampaign === null} onClick={props.handleDelete}>
                Supprimer
            </button>
        </div>
        <hr />
        <div className="addImage">
            <button className="btn btn-info" disabled={props.activeCampaign === null} onClick={props.addImage}>
                <i className="fa fa-plus" />
            </button>
        </div>
        <Row className="mt-1">
            {props.images.map((newImage, i) => {
                if (i > 0) {
                    return null;
                }

                if (props.uploading) {
                    return (
                        <Col key={i} md={4}>
                            <div className="AdminAssetLoad">
                                <div className="Aloader">
                                    <PropagateLoader color="#328CE6" loading={true} />
                                </div>
                            </div>
                        </Col>
                    );
                }

                return (
                    <Col key={i} md={4}>
                        <div className="uploadimg">
                            <input type="file" name="file" onChange={(e) => props.handleChangeImage(i, e)} />
                        </div>
                        <p>{dateFormat(newImage.date, 'dd/mm/yy HH:MM')}</p>
                        <Select
                            options={props.shops.map((s) => ({
                                value: s.id,
                                label: `${s.name} - ${s.address} ${s.zip} ${s.city} - ${s.Sign.name}`
                            }))}
                            onChange={(e) => props.handleSelectShop(e.value, i)}
                            value={{
                                value: newImage.shopId,
                                label: newImage.label
                            }}
                        />
                        <button className="btn btn-info" onClick={() => props.handleUploadImage(i)}>
                            Save
                        </button>
                    </Col>
                );
            })}
            {!props.activeCampaign ||
                props.activeCampaign.images.map((image) => {
                    if (image.fileType === 'video/mp4') {
                        return (
                            <Col
                                key={image._id}
                                md={4}
                                style={{
                                    marginBottom: '1em',
                                    position: 'relative'
                                }}
                            >
                                <video
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                    autoPlay
                                    playsInline
                                    muted
                                >
                                    <source src={`${constants.cloud_storage}/Gallery/${image.src}`} />
                                </video>
                                <p>{image.sign}</p>
                                <p>{image.shop}</p>
                                <p>{dateFormat(image.date, 'dd/mm/yy HH:MM')}</p>
                                <div className="btn_all">
                                    <button
                                        className="btn btn-danger deleteGalleryImage"
                                        onClick={() => props.handleRemoveImage(image._id)}
                                    >
                                        <i className="fa fa-trash" />
                                    </button>
                                    <button className="btn_turn" onClick={() => props.TurnImg(image._id)}>
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                    <Switch
                                        className="switch-class"
                                        onChange={() => props.toggleButton(image._id)}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        checked={!image.hidden}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={40}
                                    />
                                </div>
                            </Col>
                        );
                    }

                    return (
                        <Col
                            key={image._id}
                            md={4}
                            style={{
                                marginBottom: '1em',
                                position: 'relative'
                            }}
                        >
                            <img
                                src={`${constants.cloud_storage}/Gallery/${image.src}?ts=${props.imageTs}`}
                                alt={image._id}
                                style={{
                                    maxWidth: '100%'
                                }}
                            />
                            <p>{image.sign}</p>
                            <p>{image.shop}</p>
                            <p>{dateFormat(image.date, 'dd/mm/yy HH:MM')}</p>
                            <div className="btn_all">
                                <button
                                    className="btn btn-danger deleteGalleryImage"
                                    onClick={() => props.handleRemoveImage(image._id)}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                                <button className="btn_turn" onClick={() => props.TurnImg(image._id)}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                                <Switch
                                    className="switch-class"
                                    onChange={() => props.toggleButton(image._id)}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={true}
                                    checkedIcon={false}
                                    checked={!image.hidden}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                />
                                {/* <Switch onChange={props.toggleButton} checked={props.checked} /> */}
                            </div>
                        </Col>
                    );
                })}
        </Row>
    </div>
);

export default class CampaignsGallery extends React.Component {
    constructor() {
        super();

        this.state = {
            campaigns: [],
            activeCampaign: null,
            name: '',
            password: '',
            agency: '',
            startDate: new Date(),
            endDate: new Date(),
            info: '',
            images: [],
            shops: [],
            campaignAsset: null,
            campaignFile: null,
            imageTs: new Date().getTime(),
            checked: true,
            uploading: false
        };

        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNewCampaign = this.handleNewCampaign.bind(this);
        this.handleSelectShop = this.handleSelectShop.bind(this);
        this.handleUploadImage = this.handleUploadImage.bind(this);
        this.handleChangeImage = this.handleChangeImage.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.toggleButton = this.toggleButton.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    getData() {
        return Auth.fetch(`${constants.endpoint}/admin/campaignsGallery`).then((campaigns) => {
            this.setState({ campaigns: campaigns });
            return Promise.resolve();
        });
    }

    toggleButton(imageId) {
        fetchApi('/admin/campaignsGallery/togglehidden/' + this.state.activeCampaign._id, {
            method: 'PATCH',
            body: JSON.stringify({
                imageId
            })
        }).then(async (res) => {
            if (res.status === 200) {
                await this.getData();

                this.setState({
                    activeCampaign: this.state.campaigns.find(({ _id }) => _id === this.state.activeCampaign._id)
                });
            }
        });
    }

    handleUpdate() {
        if (!this.state.activeCampaign) {
            return;
        }

        if (this.state.campaignFile) {
            const data = new FormData();
            data.append('image', this.state.campaignFile);
            Auth.fetchMultipart(
                `${constants.endpoint}/admin/campaignsGallery/campaignUpload/${this.state.activeCampaign._id}`,
                { method: 'POST', body: data }
            ).then((res) => {
                if (!res.success) {
                    return alert('File upload error');
                }

                this.setState({ campaignAsset: res.asset });
                this.getData();
            });
        }

        Auth.fetch(`${constants.endpoint}/admin/campaignsGallery/${this.state.activeCampaign._id}`, {
            method: 'PUT',
            body: JSON.stringify({
                name: this.state.name,
                password: this.state.password,
                agency: this.state.agency,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                info: this.state.info
            })
        }).then(() => {
            this.getData();
            notify.show('Mis a jour!', 'success');
        });
    }

    handleNewCampaign() {
        Auth.fetch(`${constants.endpoint}/admin/campaignsGallery`, {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.name,
                password: new Date().getTime().toString(),
                agency: '',
                startDate: new Date(),
                endDate: new Date(),
                info: ''
            })
        }).then((res) => {
            if (res.success !== true) {
                return alert('Impossible de creer la campagne: ' + res.message);
            }

            if (this.state.campaignFile) {
                const data = new FormData();
                data.append('image', this.state.campaignFile);
                Auth.fetchMultipart(`${constants.endpoint}/admin/campaignsGallery/campaignUpload/${res._id}`, {
                    method: 'POST',
                    body: data
                }).then((res) => {
                    if (!res.success) {
                        return alert('File upload error');
                    }

                    this.setState({ campaignAsset: res.asset });
                    this.getData();
                });
            }

            this.getData();
            this.setState({ name: '' });
            alert('Campagne creee');
        });
    }

    handleChangeImage(i, e) {
        const images = this.state.images;
        images[i][e.target.name] = e.target.files[0];
        this.setState({ images: images });
    }

    handleSelectShop(shopId, imageId) {
        const images = this.state.images;
        const shop = this.state.shops.find(({ id }) => id === shopId);
        images[imageId].label = shop.name;
        images[imageId].shopId = shop.id;
        images[imageId].shop = shop.name;
        this.setState({ images: images });
    }

    handleUploadImage(imageId) {
        // Send file
        const data = new FormData();
        const image = this.state.images[imageId];
        if (!image.file) {
            return alert('Pas de fichier ajoute');
        }

        this.setState({
            checked: false,
            uploading: true
        });

        const shop = this.state.shops.find(({ id }) => id === image.shopId);

        data.append('image', image.file);
        data.append('type', image.file.type);
        data.append('id', this.state.activeCampaign._id);
        data.append('sign', shop.Sign.name);
        data.append('shop', shop.name);
        data.append('address', shop.address);
        data.append('zip_code', shop.zip);
        data.append('city', shop.city);
        data.append('lng', shop.long);
        data.append('lat', shop.lat);
        Auth.fetchMultipart(`${constants.endpoint}/admin/campaignsGallery/upload/${this.state.activeCampaign._id}`, {
            method: 'POST',
            body: data
        }).then((res) => {
            if (res.success !== true) {
                return alert('Error uploading image');
            }

            this.getData().then(() =>
                this.setState({
                    activeCampaign: this.state.campaigns.find(({ _id }) => _id === this.state.activeCampaign._id),
                    images: this.state.images.filter((_, i) => i !== imageId),
                    uploading: false
                })
            );
        });
    }

    async componentDidMount() {
        await this.getData();
        Auth.fetch(`${constants.endpoint}/admin/shops`).then((shops) => {
            this.setState({ shops: shops.shops });
            if (window.location.hash) {
                const c = this.state.campaigns.find(({ _id }) => _id === window.location.hash.slice(1));
                if (!c) {
                    return;
                }
                this.setState({
                    activeCampaign: c,
                    images: [],
                    password: c.password,
                    name: c.name,
                    agency: c.agency,
                    startDate: c.startDate,
                    endDate: c.endDate,
                    info: c.info,
                    campaignAsset: c.campaignAsset || null
                });
            }
        });
    }

    render() {
        return (
            <Panel className="Campaign">
                <Row>
                    <Col lg={6}>
                        <h3 style={{ margin: '6px' }}>Galerie</h3>
                        <div className="new">
                            <input
                                type="text"
                                className="form-control newName"
                                name="name"
                                autoComplete="off"
                                placeholder="Nom de la galerie"
                                onChange={this.handleChange}
                                value={this.state.name}
                            />
                            <button className="btn btn-info" onClick={this.handleNewCampaign}>
                                Nouvelle galerie
                            </button>
                        </div>
                        <hr />
                        <Row>
                            <Col md={6}>
                                <div className="all_li">
                                    <ul className="ul_img">
                                        {this.state.campaigns
                                            .map((c, i) => {
                                                const hid = c.images.filter(({ hidden }) => {
                                                    return !!hidden;
                                                }).length;
                                                return (
                                                    <li
                                                        className={
                                                            this.state.activeCampaign &&
                                                            c._id === this.state.activeCampaign._id
                                                                ? 'active'
                                                                : ''
                                                        }
                                                        key={c._id}
                                                        onClick={() =>
                                                            this.setState({
                                                                activeCampaign: c,
                                                                images: [],
                                                                password: c.password,
                                                                name: c.name,
                                                                agency: c.agency,
                                                                startDate: c.startDate,
                                                                endDate: c.endDate,
                                                                info: c.info,
                                                                campaignAsset: c.campaignAsset || null
                                                            })
                                                        }
                                                    >
                                                        {c.name}

                                                        {hid === 0 || (
                                                            <div className="img_check">
                                                                <div className="imagetocheck"></div>
                                                                {hid}
                                                            </div>
                                                        )}
                                                    </li>
                                                );
                                            })
                                            .reverse()}
                                    </ul>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div
                                    style={{
                                        display: this.state.activeCampaign ? 'block' : 'none'
                                    }}
                                >
                                    <div className="form-group">
                                        <label htmlFor="name">Nom</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pass">Password</label>
                                        <input
                                            type="text"
                                            name="password"
                                            className="form-control"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="campaignAsset">Asset de campagne</label>
                                        {this.state.campaignAsset === null ? (
                                            <input
                                                type="file"
                                                name="campaignFile"
                                                onChange={(e) =>
                                                    this.setState({
                                                        campaignFile: e.target.files[0]
                                                    })
                                                }
                                            />
                                        ) : (
                                            <React.Fragment>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`${constants.cloud_storage}/Gallery/${this.state.campaignAsset}`}
                                                >
                                                    <br />
                                                    Voir l'asset
                                                </a>
                                                <button
                                                    style={{ marginLeft: 10 }}
                                                    onClick={() => this.setState({ campaignAsset: null })}
                                                >
                                                    Changer
                                                </button>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="agency">Agence: </label>
                                        {this.state.activeCampaign && this.state.activeCampaign.agency ? (
                                            <span>{this.state.activeCampaign.agency}</span>
                                        ) : (
                                            <input
                                                type="text"
                                                name="agency"
                                                value={this.state.agency}
                                                onChange={this.handleChange}
                                                className="form-control"
                                            />
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fromDate">Dates: </label>
                                        <Calendar
                                            value={[new Date(this.state.startDate), new Date(this.state.endDate)]}
                                            selectRange={true}
                                            onChange={(e) =>
                                                this.setState({
                                                    startDate: e[0],
                                                    endDate: e[1]
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="info">Particularités</label>
                                        <textarea
                                            name="info"
                                            value={this.state.info}
                                            onChange={this.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6}>
                        <CampaignDetails
                            activeCampaign={this.state.activeCampaign}
                            images={this.state.images}
                            imageTs={this.state.imageTs}
                            addImage={() =>
                                this.setState({
                                    images: [
                                        {
                                            date: new Date(),
                                            file: null,
                                            label: '',
                                            shopId: null,
                                            hidden: true
                                        },
                                        ...this.state.images
                                    ]
                                })
                            }
                            uploading={this.state.uploading}
                            TurnImg={(e) => {
                                fetchApi('/admin/assets/rotate/' + this.state.activeCampaign._id, {
                                    method: 'PATCH',
                                    body: JSON.stringify({
                                        angle: 90,
                                        image: e
                                    })
                                }).then((res) => {
                                    if (res.status === 200) {
                                        this.setState({ imageTs: new Date().getTime() });
                                    }
                                });
                            }}
                            handleRemoveImage={(id) => {
                                if (!window.confirm('Etes vous sur?')) {
                                    return;
                                }

                                Auth.fetch(
                                    `${constants.endpoint}/admin/campaignsGallery/${this.state.activeCampaign._id}/${id}`,
                                    {
                                        method: 'DELETE'
                                    }
                                ).then(() =>
                                    this.getData().then(() =>
                                        this.setState({
                                            activeCampaign: this.state.campaigns.find(
                                                ({ _id }) => _id === this.state.activeCampaign._id
                                            ),
                                            images: this.state.images.filter((_, i) => i !== id)
                                        })
                                    )
                                );
                            }}
                            shops={this.state.shops}
                            handleSelectShop={this.handleSelectShop}
                            handleUploadImage={this.handleUploadImage}
                            handleChangeImage={this.handleChangeImage}
                            toggleButton={this.toggleButton}
                            handleUpdate={this.handleUpdate}
                            password={this.state.password}
                            checked={this.state.checked}
                            handleDelete={() => {
                                swal({
                                    text: `Êtes-vous sur de vouloir supprimer cette galerie ?`,
                                    title: 'La commande ne pourra pas être annulée',
                                    icon: 'warning',
                                    buttons: ['Annuler', 'Go'],
                                    dangerMode: true
                                }).then((willDelete) => {
                                    if (willDelete) {
                                        Auth.fetch(
                                            `${constants.endpoint}/admin/campaignsGallery/${this.state.activeCampaign._id}`,
                                            { method: 'DELETE' }
                                        ).then((res) => {
                                            if (res.success) {
                                                this.getData();
                                            }
                                        });
                                        swal(`Commmande effectué avec succés`, {
                                            icon: 'success'
                                        });
                                    } else {
                                        swal('Commande annulée');
                                    }
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Panel>
        );
    }
}
