import React, { useEffect, useState } from 'react';
import FixedTemplateList from '../FixedTemplateList';
import FixedTemplateEdition from '../FixedTemplates/FixedTemplatesAssistant';
import TemplateList from '../TemplateList';
import ElementEditor from './ElementsEditor';
import PreviewTemplate from './PreviewTemplate';

export const ASSISTANT_MD_PRES = {
    portrait: [7, 5],
    landscape: [12, 12],
    led_448_340: [12, 12]
};

const FreeTemplateSelection = ({ templates, selected, onClick, next }) => {
    return (
        <React.Fragment>
            <header>
                <div className="progress-assistant">
                    <div className={`item active`}>
                        <div className="round">1</div>
                        <span>Choisissez un template</span>
                    </div>
                    <div className={`item`}>
                        <div className="round">2</div>
                    </div>
                </div>
                <div className="buttons">
                    <button className="button-assistant" disabled={selected === null} onClick={next}>
                        Suivant
                    </button>
                </div>
            </header>
            <div>
                <TemplateList selected={selected && selected._id} templates={templates} onClick={onClick} />
            </div>
        </React.Fragment>
    );
};

const FixedTemplateSelection = ({ templates, selected, onClick, next }) => {
    return (
        <React.Fragment>
            <header>
                <div className="progress-assistant">
                    <div className={`item active`}>
                        <div className="round">1</div>
                        <span>Choisissez un template</span>
                    </div>
                    <div className={`item`}>
                        <div className="round">2</div>
                    </div>
                </div>
                <div className="buttons">
                    <button className="button-assistant" disabled={selected === null} onClick={next}>
                        Suivant
                    </button>
                </div>
            </header>
            <div>
                <FixedTemplateList
                    selected={selected && selected._id}
                    templates={templates}
                    onClick={onClick}
                    big={true}
                    showText={false}
                />
            </div>
        </React.Fragment>
    );
};

const TemplateEdition = ({ templateInstance, onSave, prev }) => {
    const [template, setTemplate] = useState({
        templateId: null,
        elements: []
    });

    useEffect(() => {
        if (templateInstance._id) {
            setTemplate({
                ...templateInstance,
                elements: [...templateInstance.elements]
            });
        } else {
            setTemplate({
                ...templateInstance,
                elements: []
            });
        }
    }, [templateInstance]);

    if (template.templateId === null) {
        return null;
    }

    return (
        <React.Fragment>
            <header>
                <div className="progress-assistant">
                    <div className={`item active`}>
                        <div className="round" onClick={prev}>
                            1
                        </div>
                    </div>
                    <div className={`item active`}>
                        <div className="round">2</div>
                        <span>Remplir</span>
                    </div>
                </div>
                <div className="buttons">
                    <button className="prev" onClick={prev}>
                        Retour
                    </button>
                    <button
                        className="button-assistant"
                        disabled={template.elements.length === 0}
                        onClick={() => onSave(template)}
                    >
                        Suivant
                    </button>
                </div>
            </header>
            <div>
                <div className="row Assistant">
                    <div className={`col-md-${ASSISTANT_MD_PRES[template.templateId.orientation][0]}`}>
                        <ElementEditor
                            baseTemplateInstance={templateInstance}
                            onChange={(elements) =>
                                setTemplate({
                                    ...template,
                                    elements
                                })
                            }
                            template={template.templateId}
                        />
                    </div>
                    <div className={`col-md-${ASSISTANT_MD_PRES[template.templateId.orientation][1]}`}>
                        <PreviewTemplate template={template.templateId} elements={template.elements} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export const FixedTemplateModal = ({ templates, selectedTemplate, onSave, assistant }) => {
    const [state, setState] = useState({
        selectedTemplate: {
            templateId: null,
            elements: []
        },
        step: 0
    });

    useEffect(() => {
        if (selectedTemplate) {
            setState((prevState) => ({
                ...prevState,
                selectedTemplate: {
                    ...selectedTemplate
                },
                step: 2,
                templates
            }));
        } else {
            const templatesFiltered = templates.filter((template) => assistant.indexOf(template.assistantName) !== -1);

            if (templatesFiltered.length === 1) {
                setState((prevState) => ({
                    ...prevState,
                    selectedTemplate: {
                        templateId: templatesFiltered[0],
                        elements: []
                    },
                    step: 2
                }));
            } else if (templatesFiltered.length > 1) {
                setState((prevState) => ({
                    ...prevState,
                    step: 1,
                    templates: templatesFiltered,
                    selectedTemplate: {
                        templateId: null,
                        elements: []
                    }
                }));
            }
        }
    }, [templates, selectedTemplate, assistant]);

    let component = null;

    switch (state.step) {
        case 1:
            component = (
                <FixedTemplateSelection
                    templates={state.templates || []}
                    onClick={(i) =>
                        setState({
                            ...state,
                            selectedTemplate: {
                                templateId: state.templates[i],
                                elements: []
                            }
                        })
                    }
                    next={() =>
                        setState({
                            ...state,
                            step: state.step + 1
                        })
                    }
                    selected={state.selectedTemplate.templateId}
                />
            );
            break;
        case 2:
            component = <FixedTemplateEdition onSave={onSave} templateInstance={state.selectedTemplate} />;
            break;
        default:
            break;
    }

    return <div>{component}</div>;
};

const FreeTemplateModal = ({ templates, selectedTemplate, onSave }) => {
    const [state, setState] = useState({
        selectedTemplate: {
            templateId: null,
            elements: []
        },
        step: 0
    });

    useEffect(() => {
        if (selectedTemplate) {
            setState({
                selectedTemplate: {
                    ...selectedTemplate
                },
                step: 1
            });
        } else {
            setState({
                selectedTemplate: {
                    templateId: null,
                    elements: []
                },
                step: 0
            });
        }
    }, [templates, selectedTemplate]);

    let component = null;

    switch (state.step) {
        case 0:
            component = (
                <FreeTemplateSelection
                    templates={templates || []}
                    onClick={(i) =>
                        setState({
                            ...state,
                            selectedTemplate: {
                                templateId: templates[i],
                                elements: []
                            }
                        })
                    }
                    next={() =>
                        setState({
                            ...state,
                            step: state.step + 1
                        })
                    }
                    selected={state.selectedTemplate.templateId}
                />
            );
            break;
        case 1:
            component = (
                <TemplateEdition
                    onSave={onSave}
                    templateInstance={state.selectedTemplate}
                    prev={() =>
                        setState({
                            ...state,
                            selectedTemplate: {
                                templateId: null,
                                elements: []
                            },
                            step: 0
                        })
                    }
                />
            );
            break;
        default:
            break;
    }

    return <div>{component}</div>;
};

export default FreeTemplateModal;
