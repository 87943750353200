import React from 'react';
import { PreviewComponent } from './PreviewFruit';

const PreviewCaterer = props => (
    <div className="PreviewFruit">
        <div className={'CenterFruit o' + props.orientation}>
            <div className="PreviewFruitContainer">
                <PreviewComponent rayon="caterer" {...props} />
            </div>
        </div>
    </div>
);

export default PreviewCaterer;
