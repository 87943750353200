import React, { useEffect, useState } from 'react';
import { ASSISTANT_MD_PRES } from '../FreeTemplates/FreeTemplateAssistant';
import PreviewTemplate from '../FreeTemplates/PreviewTemplate';
import Calendar from 'react-calendar';
import dateFormat from 'dateformat';

const pad = (num) => {
    return num < 10 ? `0${num}` : num;
};

export const ASSISTANTS_DATA = {
    hoursFlex: {
        name: 'Horaires (Flexible)',
        fields: new Array(24).fill().map((_, i) => ({
            label: `Heure ${i + 1}`,
            type: 'select',
            options: new Array(24).fill().map((_, i) => `${i}`)
        }))
    },
    hoursMinsFlex: {
        name: 'Horaires + Minutes (Flexible)',
        fields: new Array(24)
            .fill()
            .map((_, i) => [
                {
                    label: `Heure ${i + 1}`,
                    type: 'select',
                    options: new Array(24).fill().map((_, i) => `${i}`),
                    md: 6
                },
                {
                    label: `Minute ${i + 1}`,
                    type: 'select',
                    options: ['', ...new Array(11).fill().map((_, i) => `${pad((i + 1) * 5)}`)],
                    md: 6
                }
            ])
            .reduce((acc, e) => [...acc, e[0], e[1]])
    },
    dayOff: {
        name: 'Jour férié (H1 - H2)',
        fields: [
            {
                label: 'Jour',
                type: 'date'
            },
            {
                type: 'datePlaceholder'
            },
            {
                type: 'datePlaceholder'
            },
            {
                label: "Heure d'ouverture",
                type: 'select',
                options: new Array(24).fill().map((_, i) => `${i}`)
            },
            {
                label: 'Heure de fermeture',
                type: 'select',
                options: new Array(24).fill().map((_, i) => `${i}`)
            }
        ]
    },
    openCloseHours: {
        name: 'Ouverture/Horaires exceptionnels',
        fields: [
            {
                label: `Type`,
                type: 'radio',
                options: ['Ouverture exceptionnelle', 'Horaires exceptionnels']
            },
            {
                label: 'Jour',
                type: 'date'
            },
            {
                type: 'datePlaceholder'
            },
            {
                type: 'datePlaceholder'
            },
            {
                label: `Heure 1`,
                type: 'select',
                options: new Array(24).fill().map((_, i) => `${i}`),
                md: 6
            },
            {
                label: `Minute 1`,
                type: 'select',
                options: ['', ...new Array(11).fill().map((_, i) => `${pad((i + 1) * 5)}`)],
                md: 6
            },
            {
                label: `Heure 2`,
                type: 'select',
                options: new Array(24).fill().map((_, i) => `${i}`),
                md: 6
            },
            {
                label: `Minute 2`,
                type: 'select',
                options: ['', ...new Array(11).fill().map((_, i) => `${pad((i + 1) * 5)}`)],
                md: 6
            }
        ]
    },
    openCloseHoursFixed: {
        name: 'Ouverture/Horaires exceptionnels (Fixes)',
        fields: [
            {
                label: 'Jour',
                type: 'date'
            },
            {
                type: 'datePlaceholder'
            },
            {
                type: 'datePlaceholder'
            },
            ...new Array(24)
                .fill()
                .map((_, i) => [
                    {
                        label: `Heure ${i + 1}`,
                        type: 'select',
                        options: new Array(24).fill().map((_, i) => `${i}`),
                        md: 6
                    },
                    {
                        label: `Minute ${i + 1}`,
                        type: 'select',
                        options: ['', ...new Array(11).fill().map((_, i) => `${pad((i + 1) * 5)}`)],
                        md: 6
                    }
                ])
                .reduce((acc, e) => [...acc, e[0], e[1]])
        ]
    },
    openCloseHoursFixedNoDate: {
        name: 'Ouverture/Horaires exceptionnels (Fixes/NoDate)',
        fields: [
            ...new Array(24)
                .fill()
                .map((_, i) => [
                    {
                        label: `Heure ${i + 1}`,
                        type: 'select',
                        options: new Array(24).fill().map((_, i) => `${i}`),
                        md: 6
                    },
                    {
                        label: `Minute ${i + 1}`,
                        type: 'select',
                        options: ['', ...new Array(11).fill().map((_, i) => `${pad((i + 1) * 5)}`)],
                        md: 6
                    }
                ])
                .reduce((acc, e) => [...acc, e[0], e[1]])
        ]
    },
    date: {
        name: 'Date',
        fields: [
            {
                label: 'Jour',
                type: 'date'
            },
            {
                type: 'datePlaceholder'
            },
            {
                type: 'datePlaceholder'
            },
            ...new Array(24)
                .fill()
                .map((_, i) => [
                    {
                        label: `Heure ${i + 1}`,
                        type: 'select',
                        options: new Array(24).fill().map((_, i) => `${i}`),
                        md: 6
                    },
                    {
                        label: `Minute ${i + 1}`,
                        type: 'select',
                        options: ['', ...new Array(11).fill().map((_, i) => `${pad((i + 1) * 5)}`)],
                        md: 6
                    }
                ])
                .reduce((acc, e) => [...acc, e[0], e[1]])
        ]
    }
};

const getForm = (template, setTemplate) => {
    if (!(template.templateId.assistantName in ASSISTANTS_DATA)) {
        return null;
    }

    const genSelect = (options, onChange, value) => {
        return (
            <select onChange={onChange} value={value} className="form-control">
                {options.map((f) => (
                    <option key={f} value={f}>
                        {f || '00'}
                    </option>
                ))}
            </select>
        );
    };

    const changeElementAt = (i, text) => {
        const elements = [...template.elements];

        elements[i].text = text;

        setTemplate((template) => ({
            ...template,
            elements
        }));
    };

    const assistant = ASSISTANTS_DATA[template.templateId.assistantName];

    const elements = [];

    for (let i = 0; i < template.elements.length; i++) {
        if (assistant.fields[i]) {
            if (template.elements[i].isFree) {
                elements.push(
                    <div
                        key={i}
                        className={`col-md-${assistant.fields[i].md || 12} col-xs-${assistant.fields[i].md || 12}`}
                    >
                        <div className="form-group">
                            <label>Champs libre</label>
                            <input
                                onChange={(e) => changeElementAt(i, e.target.value)}
                                value={template.elements[i].text}
                                className="form-control"
                            />
                        </div>
                    </div>
                );

                continue;
            }

            switch (assistant.fields[i].type) {
                case 'select':
                    elements.push(
                        <div
                            key={i}
                            className={`col-md-${assistant.fields[i].md || 12} col-xs-${assistant.fields[i].md || 12}`}
                        >
                            <div className="form-group">
                                <label>{assistant.fields[i].label}</label>
                                {genSelect(
                                    assistant.fields[i].options,
                                    (e) => changeElementAt(i, e.target.value),
                                    template.elements[i].text
                                )}
                            </div>
                        </div>
                    );
                    break;
                case 'radio':
                    elements.push(
                        <div key={i} className="col-md-12">
                            <div className="form-group">
                                {assistant.fields[i].options.map((o) => (
                                    <div key={o}>
                                        <input
                                            type="radio"
                                            onChange={(e) => !e.target.checked || changeElementAt(i, o)}
                                            checked={template.elements[i].text === o}
                                        />
                                        <label style={{ marginLeft: 10 }}>{o}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                    break;
                case 'date':
                    let oI = i;
                    elements.push(
                        <div key={oI} className="col-md-12">
                            <div className="form-group">
                                <label>{assistant.fields[oI].label}</label>
                                <Calendar
                                    onChange={(e) => {
                                        const eOD = new Date(e);
                                        eOD.setHours(23, 59, 59, 999);
                                        setTemplate((template) => ({ ...template, expirationDate: eOD }));
                                        changeElementAt(oI, dateFormat(e, 'dddd'));
                                        changeElementAt(oI + 1, dateFormat(e, 'd'));
                                        changeElementAt(oI + 2, dateFormat(e, 'mmmm'));
                                    }}
                                    minDate={new Date(new Date().setHours(23, 59, 59, 59, 999))}
                                />
                            </div>
                        </div>
                    );
                    i += 2;
                    break;
                default:
                    elements.push(
                        <div key={i} className="form-group">
                            <label>Type non géré:</label>
                            <p>{template.elements[i].text}</p>
                        </div>
                    );
                    break;
            }
        } else {
            elements.push(
                <div key={i} className="form-group">
                    <label>Valeur fixe:</label>
                    <p>{template.elements[i].text}</p>
                </div>
            );
        }
    }

    return elements;
};

const genElements = (template) => {
    if (template._id) {
        // Existing, merge
        if (template.templateId.defaultElements.length > 0) {
            let i = 0;
            const elements = [];

            for (const id of template.templateId.allowModificationOn) {
                if (template.elements[i]) {
                    const element = { ...template.elements[i] };
                    elements.push(element);
                    i++;
                } else {
                    const element = { ...template.templateId.defaultElements[id] };
                    delete element._id;
                    delete element.__v;
                    elements.push(element);
                }
            }

            return elements;
        } else {
            return [...template.elements];
        }
    }

    if (template.templateId.defaultElements.length > 0) {
        // Special
        const elements = [];
        for (const id of template.templateId.allowModificationOn) {
            const element = { ...template.templateId.defaultElements[id] };
            delete element._id;
            delete element.__v;

            elements.push(element);
        }

        return elements;
    } else {
        return [];
    }
};

const FixedTemplateEdition = ({ onSave, templateInstance }) => {
    const [template, setTemplate] = useState({
        templateId: null,
        elements: [],
        expirationDate: null
    });

    useEffect(() => {
        if (templateInstance._id) {
            setTemplate({
                ...templateInstance,
                elements: genElements(templateInstance)
            });
        } else {
            setTemplate({
                ...templateInstance,
                elements: genElements(templateInstance)
            });
        }
    }, [templateInstance]);

    if (template.templateId === null) {
        return null;
    }

    return (
        <React.Fragment>
            <header>
                <div className="progress-assistant">
                    <div className={`item active`}>
                        <div className="round">1</div>
                    </div>
                    <div className={`item active`}>
                        <div className="round">2</div>
                        <span>Remplir</span>
                    </div>
                </div>
                <div className="buttons">
                    <button
                        className="button-assistant"
                        disabled={template.elements.length === 0}
                        onClick={() => onSave(template)}
                    >
                        Enregistrer
                    </button>
                </div>
            </header>
            <div>
                <div className="row Assistant">
                    <div className={`col-md-${ASSISTANT_MD_PRES[template.templateId.orientation][0]}`}>
                        <div className="row">{getForm(template, setTemplate)}</div>
                    </div>
                    <div className={`col-md-${ASSISTANT_MD_PRES[template.templateId.orientation][1]}`}>
                        <PreviewTemplate template={template.templateId} elements={template.elements} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FixedTemplateEdition;
