import React from 'react';
import { Col, Row, Panel, Table, Tab, Tabs, Modal, DropdownButton, MenuItem } from 'react-bootstrap';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import { notify } from 'react-notify-toast';
import { PlacesWithStandaloneSearchBox, SimpleMarkerOnMap } from './Map';
import { sendFile, fetchApi } from '../../Services/NetworkServcice';
import ShopImg from './ShopImg';
import '../../Assets/shopwizard.css';
import dateFormat from 'dateformat';
import Calendar from 'react-calendar';
import TimePicker from 'rc-time-picker';

import pdfIcon from '../../Assets/pdf-icon.png';
import { DefaultAssets } from '../../Components/DefaultAssets';
import moment from 'moment';

const WizardPages = {
    OVERVIEW: 'OVERVIEW',
    SHOP_FORM: 'SHOP_FORM',
    SIGN_FORM: 'SIGN_FORM',
    USER_FORM: 'USER_FORM',
    GROUP_FORM: 'GROUP_FORM',
    SHOW_IMG: 'SHOW_IMG'
};

const validate = (elt, model) => {
    let required = ['name'];

    if (model === 'Sign') {
        required = ['name', 'domain'];
    } else if (model === 'User') {
        required = ['login', 'password', 'GroupId', 'ShopId'];
    } else if (model === 'Shop') {
        required = ['name', 'SignId'];
    }

    const keys = Object.keys(elt);
    const missingFields = required.filter((r) => keys.indexOf(r) === -1 || elt[r] === '');

    if (missingFields.length > 0) {
        alert('Des champs sont manquants: ' + missingFields.reduce((prev, cur) => prev + ', ' + cur));

        return false;
    }

    return true;
};

// Function that defines the correct creative using the shop Id
const CreaFromSign = (signId, id) => {
    const creas = {
        1: {
            name: 'i3min',
            targetUrl: `https://www.doohyoulike.com/d2s/index.html#${id}`,
            imageUrl: `${constants.endpoint}/static/crea/i3min/${id}`
        },
        2: {
            name: 'ue3min',
            targetUrl: `https://www.doohyoulike.com/d2s/index.html#${id}`,
            imageUrl: `${constants.endpoint}/static/crea/ue3min/${id}`
        },
        3: {
            name: 'f3min',
            targetUrl: `https://www.doohyoulike.com/d2s/index.html#${id}`,
            imageUrl: `${constants.endpoint}/static/crea/f3min/${id}`
        },
        4: {
            name: 'c3min',
            targetUrl: `https://www.doohyoulike.com/d2s/index.html#${id}`,
            imageUrl: `${constants.endpoint}/static/crea/c3min/${id}`
        },
        5: {
            name: 'g20a3min',
            targetUrl: `https://www.doohyoulike.com/d2s/index.html#${id}`,
            imageUrl: `${constants.endpoint}/static/crea/g20a3min/${id}`
        },
        6: {
            name: 'a2pas3min',
            targetUrl: `https://www.doohyoulike.com/d2s/index.html#${id}`,
            imageUrl: `${constants.endpoint}/static/crea/a2pas3min/${id}`
        },
        7: {
            name: 'omf3min',
            targetUrl: `https://www.doohyoulike.com/d2s/index.html#${id}`,
            imageUrl: `${constants.endpoint}/static/crea/omf3min/${id}`
        }
    };

    return creas[signId];
};

const ShopForm = (props) => (
    <form onSubmit={props.handleSubmit} data-model="Shop" autoComplete="nope" role="presentation">
        <div className="form-group">
            <label htmlFor="name">Nom du shop</label>
            <input onChange={props.handleChange} type="text" name="name" value={props.name} className="form-control" />
        </div>
        <div className="form-group">
            <label htmlFor="shopAddress">Adresse</label>
            <input
                onChange={props.handleChange}
                type={props.id ? 'block' : 'hidden'}
                name="address"
                value={props.address}
                className="form-control"
            />
            {props.id ? null : (
                <PlacesWithStandaloneSearchBox
                    onPlacesChanged={(e) => {
                        const place = e[0];

                        if (
                            place.types[0] !== 'street_address' &&
                            place.types[0] !== 'premise' &&
                            place.types[0] !== 'train_station' &&
                            place.types[0] !== 'point_of_interest' &&
                            place.types[0] !== 'transit_station' &&
                            place.types[0] !== 'intersection' &&
                            place.types[0] !== 'subpremise' &&
                            place.types[0] !== 'post_box' &&
                            place.types[0] !== 'university' &&
                            place.types[0] !== 'school' &&
                            place.types[0] !== 'route'
                        ) {
                            return;
                        }

                        props.handleChange({
                            target: {
                                name: 'city',
                                value: place.address_components.find((e) => e.types.indexOf('locality') !== -1)
                                    .long_name
                            }
                        });

                        props.handleChange({
                            target: {
                                name: 'zip',
                                value: place.address_components.find((e) => e.types.indexOf('postal_code') !== -1)
                                    .long_name
                            }
                        });

                        props.handleChange({
                            target: {
                                name: 'country',
                                value: place.address_components.find((e) => e.types.indexOf('country') !== -1).long_name
                            }
                        });

                        props.handleChange({
                            target: {
                                name: 'long',
                                value: place.geometry.location.lng()
                            }
                        });

                        props.handleChange({
                            target: {
                                name: 'lat',
                                value: place.geometry.location.lat()
                            }
                        });

                        props.handleChange({
                            target: {
                                name: 'address',
                                value: place.name
                            }
                        });

                        props.handleChange({
                            target: {
                                name: 'region',
                                value: place.address_components
                                    .find((e) => e.types.indexOf('administrative_area_level_1') !== -1)
                                    .long_name.replace('Î', 'I')
                            }
                        });

                        props.handleChange({
                            target: {
                                name: 'departement',
                                value: place.address_components
                                    .find((e) => e.types.indexOf('administrative_area_level_2') !== -1)
                                    .long_name.replace('Arrondissement de Paris', 'Paris')
                            }
                        });
                    }}
                />
            )}
        </div>
        <div className="form-group">
            <label htmlFor="zip">Zip</label>
            <input onChange={props.handleChange} type="text" name="zip" value={props.zip} className="form-control" />
        </div>
        <div className="form-group">
            <label htmlFor="city">City</label>
            <input onChange={props.handleChange} type="text" name="city" value={props.city} className="form-control" />
        </div>
        <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
                onChange={props.handleChange}
                type="text"
                name="country"
                value={props.country}
                className="form-control"
            />
        </div>

        <div className="form-group">
            <label htmlFor="departement">Departement</label>
            <select onChange={props.handleChange} name="departement" value={props.departement} className="form-control">
                <option>---</option>

                {props.mydepart.map((s) => (
                    <option value={s.name} key={s.name}>
                        {s.name}
                    </option>
                ))}
            </select>
        </div>

        <div className="form-group">
            <label htmlFor="region">Region</label>
            <select onChange={props.handleChange} name="region" value={props.region} className="form-control">
                <option>---</option>
                {props.myregion.map((s) => (
                    <option value={s.name} key={s.name}>
                        {s.name}
                    </option>
                ))}
            </select>
        </div>

        {props.user.role.Shop.AgentId !== 1 || (
            <div className="form-group">
                <label>Type de contrat</label>
                <select onChange={props.handleChange} name="isMulti" value={props.isMulti} className="form-control">
                    <option value="">---</option>
                    <option value="dyl">DoohYouLike</option>
                    <option value="multi">Multiproxy</option>
                    <option value="villomedia">Villomedia</option>
                </select>
            </div>
        )}

        {props.user.role.Shop.AgentId === 5 || (
            <React.Fragment>
                <div className="form-group">
                    <label>Prismaflex</label>
                    <input
                        type="checkbox"
                        checked={props.isPrismaflex}
                        onChange={(e) =>
                            props.handleChange({ target: { name: 'isPrismaflex', value: e.target.checked } })
                        }
                    />
                </div>
                <div className="form-group">
                    <label>MagicVision</label>
                    <input
                        type="checkbox"
                        checked={props.isMagicVision}
                        onChange={(e) =>
                            props.handleChange({ target: { name: 'isMagicVision', value: e.target.checked } })
                        }
                    />
                </div>
                <div className="form-group">
                    <label>Programmatic Only</label>
                    <input
                        type="checkbox"
                        checked={props.programmaticOnly}
                        onChange={(e) =>
                            props.handleChange({ target: { name: 'programmaticOnly', value: e.target.checked } })
                        }
                    />
                </div>
            </React.Fragment>
        )}

        <div className="form-group">
            <label>Date signature contrat</label>
            <Calendar
                value={new Date(props.dateContract || new Date())}
                onChange={(e) => props.handleChange({ target: { name: 'dateContract', value: e } })}
            />
        </div>

        <div className="form-group">
            <label>Date formation</label>
            <Calendar
                value={new Date(props.dateFormation || new Date())}
                onChange={(e) => props.handleChange({ target: { name: 'dateFormation', value: e } })}
            />
            {!props.dateFormation || (
                <TimePicker
                    value={moment(props.dateFormation)}
                    onChange={(m) =>
                        props.handleChange({
                            target: {
                                name: 'dateFormation',
                                value: m.toDate()
                            }
                        })
                    }
                />
            )}
        </div>

        <div className="form-group">
            <label htmlFor="shopContact">Contact</label>
            <input
                onChange={props.handleChange}
                type="text"
                name="contact"
                value={props.contact}
                className="form-control"
            />
        </div>
        <div className="form-group">
            <label htmlFor="shopTel">Telephone</label>
            <input onChange={props.handleChange} type="text" name="tel" value={props.tel} className="form-control" />
        </div>
        <div className="form-group">
            <label htmlFor="shopMail">Email</label>
            <input onChange={props.handleChange} type="email" name="mail" value={props.mail} className="form-control" />
        </div>
        <div className="form-group">
            <label htmlFor="shopMail">Email 2</label>
            <input
                onChange={props.handleChange}
                type="email"
                name="mail2"
                value={props.mail2}
                className="form-control"
            />
        </div>
        <div className="form-group">
            <label htmlFor="shopMail">Email 3</label>
            <input
                onChange={props.handleChange}
                type="email"
                name="mail3"
                value={props.mail3}
                className="form-control"
            />
        </div>
        <div className="form-group">
            <label htmlFor="shopMail">Email 4</label>
            <input
                onChange={props.handleChange}
                type="email"
                name="mail4"
                value={props.mail4}
                className="form-control"
            />
        </div>
        <div className="form-group">
            <label htmlFor="shopMail">Email 5</label>
            <input
                onChange={props.handleChange}
                type="email"
                name="mail5"
                value={props.mail5}
                className="form-control"
            />
        </div>
        <div className="form-group">
            <label htmlFor="shopLongLat">Long/Lat: </label>
            <input
                onChange={props.handleChange}
                type="text"
                name="long"
                value={props.long}
                className="form-control"
                style={{ display: 'inline', marginLeft: '1em', width: 'auto' }}
            />
            <input
                onChange={props.handleChange}
                type="text"
                name="lat"
                value={props.lat}
                className="form-control"
                style={{ display: 'inline', marginLeft: '1em', width: 'auto' }}
            />
            {!props.lat || (
                <SimpleMarkerOnMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={
                        <div
                            style={{
                                height: `100%`
                            }}
                        />
                    }
                    containerElement={
                        <div
                            style={{
                                height: `300px`
                            }}
                        />
                    }
                    mapElement={
                        <div
                            style={{
                                height: `100%`,
                                width: '40%'
                            }}
                        />
                    }
                    position={{
                        lng: parseFloat(props.long),
                        lat: parseFloat(props.lat)
                    }}
                />
            )}
        </div>
        <div className="form-group">
            <label htmlFor="shopTel">Enseigne</label>
            <select name="SignId" value={props.SignId} onChange={props.handleChange} className="form-control">
                <option>---</option>
                {props.signs
                    .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
                    .map((s) => (
                        <option value={s.id} key={s.id}>
                            {s.name}
                        </option>
                    ))}
            </select>
        </div>
        <div className="form-group">
            <label htmlFor="shopGroup">Group</label>
            <select name="GroupId" value={props.GroupId} onChange={props.handleChange} className="form-control">
                <option>---</option>
                {props.groups
                    .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
                    .map((s) => (
                        <option value={s.id} key={s.id}>
                            {s.name}
                        </option>
                    ))}
            </select>
        </div>
        <div className="form-group">
            <label>Contrat</label>
            {(() => {
                if (!props.id) {
                    return null;
                }

                const file = props.contracts.find((id) => id === props.id.toString());

                if (file) {
                    return (
                        <div>
                            <a
                                href={`${constants.endpoint}/admin/wizard/contracts/${props.id}.pdf?key=8cbb11504345d10209f50ae4646ab5fee9d3a2bd`}
                            >
                                <img
                                    src={pdfIcon}
                                    alt="pdf"
                                    style={{
                                        height: 30,
                                        cursor: 'pointer'
                                    }}
                                />
                            </a>
                            <button type="button" onClick={() => props.handleDeleteContract(props.id)}>
                                Supprimer
                            </button>
                        </div>
                    );
                }

                return <input type="file" onChange={(e) => props.onUploadContract(props.id, e)} />;
            })()}
        </div>
        <div className="form-group">
            <input
                type="submit"
                disabled={props.user.role.Shop.AgentId === 1 && (!props.isMulti || !props.lat || !props.long)}
                className="btn btn-default ml-1"
                value="Enregistrer"
            />
            <input
                type="button"
                style={{ marginLeft: '1em' }}
                className="btn btn-default ml-1"
                value="Retour"
                onClick={props.handleClose}
            />
        </div>
    </form>
);

const SignForm = (props) => (
    <form onSubmit={props.handleSubmit} data-model="Sign" role="presentation">
        <div className="form-group">
            <label htmlFor="signName">Nom</label>
            <input
                autoComplete="false"
                type="text"
                name="name"
                value={props.name}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="signLogo">Logo</label>
            <input type="text" name="logo" value={props.logo} className="form-control" onChange={props.handleChange} />
        </div>
        <div className="form-group">
            <label htmlFor="signColor">Couleur</label>
            <input
                type="text"
                name="color"
                value={props.color}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="domain">Domain: </label>
            <input
                type="text"
                name="domain"
                value={props.domain}
                onChange={props.handleChange}
                className="form-control"
            />
        </div>
        <div className="form-group">
            <input type="submit" className="btn btn-default ml-1" value="Enregistrer" />
            <input
                type="button"
                style={{ marginLeft: '1em' }}
                className="btn btn-default ml-1"
                value="Retour"
                onClick={props.handleClose}
            />
        </div>
    </form>
);

const UserForm = (props) => (
    <form
        onSubmit={(e) => {
            props.handleSubmit(e);
            props.handleEmail(props.email, props.login, props.password, props.id);
        }}
        data-model="User"
        autoComplete="false"
        role="presentation"
    >
        <div className="form-group">
            <label htmlFor="userLogin">Login</label>
            <input
                type="text"
                name="login"
                value={props.login}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="userPass">Password</label>
            <input
                type="text"
                name="password"
                value={props.password}
                className="form-control"
                disabled={!!props.id && ![106, 1435, 1235].includes(props.current_user.role.id)}
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="userFirstName">Prenom</label>
            <input
                type="text"
                name="first_name"
                value={props.first_name}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="userLastName">Nom</label>
            <input
                type="text"
                name="last_name"
                value={props.last_name}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group foremail">
            <label htmlFor="userMail">Email</label>
            <div className="emailcredentials">
                <input
                    type="email"
                    name="email"
                    value={props.email}
                    className="form-control"
                    onChange={props.handleChange}
                />
                {!!props.id || (
                    <div className="emailchecked">
                        <label class="mycontainer">
                            <input
                                className="mycheckbox"
                                type="checkbox"
                                checked={props.checked}
                                onClick={props.toggleChange}
                            />
                            <span class="checkmark"></span>
                        </label>
                        <label className="mycredentials" htmlFor="emailcredentials">
                            Envoyer identifiants/mot de passe par email
                        </label>
                    </div>
                )}
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="userPhone">Telephone</label>
            <input type="text" name="tel" value={props.tel} className="form-control" onChange={props.handleChange} />
        </div>
        <div className="form-group">
            <label htmlFor="userGroup">Groupe</label>
            <select name="GroupId" value={props.GroupId} className="form-control" onChange={props.handleChange}>
                <option>---</option>
                {props.groups
                    .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
                    .map((g) => (
                        <option key={g.id} value={g.id}>
                            {g.name}
                        </option>
                    ))}
            </select>
        </div>
        <div className="form-group">
            <label htmlFor="userShop">Shop</label>
            <select name="ShopId" value={props.ShopId} className="form-control" onChange={props.handleChange}>
                <option>---</option>
                {props.shops
                    .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
                    .map((s) => (
                        <option key={s.id} value={s.id}>
                            {s.name}
                        </option>
                    ))}
            </select>
        </div>
        <div className="form-group btn_email">
            <input type="submit" className="btn btn-default ml-1" value="Enregistrer" />
            <input
                type="button"
                style={{ marginLeft: '1em' }}
                className="btn btn-default ml-1"
                value="Retour"
                onClick={props.handleClose}
            />
            {!props.id || (
                <div className="btn_credentials">
                    <button
                        type="button"
                        onClick={(e) => {
                            props.handleEmail(props.email, props.login, props.password);
                            props.handleClose(e);
                        }}
                        className="btn btn-default ml-1"
                    >
                        Envoyer identifiants/mot de passe par email
                    </button>
                </div>
            )}
        </div>
    </form>
);

const GroupForm = (props) => (
    <form onSubmit={props.handleSubmit} data-model="Group" autoComplete="false" role="presentation">
        <div className="form-group">
            <label htmlFor="name">Nom du groupe</label>
            <input
                autoComplete="false"
                type="text"
                name="name"
                value={props.name}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="name">Adresse 1</label>
            <input
                autoComplete="false"
                type="text"
                name="address"
                value={props.address}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="name">Adresse 2</label>
            <input
                autoComplete="false"
                type="text"
                name="address2"
                value={props.address2}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="zip">Zip</label>
            <input
                autoComplete="false"
                type="text"
                name="zip"
                value={props.zip}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="country">Pays</label>
            <input
                autoComplete="false"
                type="text"
                name="country"
                value={props.country}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="phone">Tel</label>
            <input
                autoComplete="false"
                type="text"
                name="phone"
                value={props.phone}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
                autoComplete="false"
                type="text"
                name="email"
                value={props.email}
                className="form-control"
                onChange={props.handleChange}
            />
        </div>
        <div className="form-group">
            <input type="submit" className="btn btn-default ml-1" value="Enregistrer" />
            <input
                type="button"
                style={{ marginLeft: '1em' }}
                className="btn btn-default ml-1"
                value="Retour"
                onClick={props.handleClose}
            />
        </div>
    </form>
);

export default class ShopWizard extends React.Component {
    constructor() {
        super();

        this.state = {
            signs: [],
            shops: [],
            users: [],
            groups: [],
            contracts: [],
            page: WizardPages.OVERVIEW,
            loading: true,
            currentElt: null,
            key: 1,
            modalOpen: false,
            buttonClicked: false,
            modalBody: <div>Why am I here?</div>,
            query: '',
            visibleCrea: -1,
            checked: true,
            showimg: -1,
            id: '',
            mydepart: [
                { id: 1, region_code: '84', code: '01', name: 'Ain', slug: 'ain' },
                { id: 2, region_code: '32', code: '02', name: 'Aisne', slug: 'aisne' },
                { id: 3, region_code: '84', code: '03', name: 'Allier', slug: 'allier' },
                {
                    id: 4,
                    region_code: '93',
                    code: '04',
                    name: 'Alpes-de-Haute-Provence',
                    slug: 'alpes de haute provence'
                },
                { id: 5, region_code: '93', code: '05', name: 'Hautes-Alpes', slug: 'hautes alpes' },
                { id: 6, region_code: '93', code: '06', name: 'Alpes-Maritimes', slug: 'alpes maritimes' },
                { id: 7, region_code: '84', code: '07', name: 'Ard\u00e8che', slug: 'ardeche' },
                { id: 8, region_code: '44', code: '08', name: 'Ardennes', slug: 'ardennes' },
                { id: 9, region_code: '76', code: '09', name: 'Ari\u00e8ge', slug: 'ariege' },
                { id: 10, region_code: '44', code: '10', name: 'Aube', slug: 'aube' },
                { id: 11, region_code: '76', code: '11', name: 'Aude', slug: 'aude' },
                { id: 12, region_code: '76', code: '12', name: 'Aveyron', slug: 'aveyron' },
                { id: 13, region_code: '93', code: '13', name: 'Bouches-du-Rh\u00f4ne', slug: 'bouches du rhone' },
                { id: 14, region_code: '28', code: '14', name: 'Calvados', slug: 'calvados' },
                { id: 15, region_code: '84', code: '15', name: 'Cantal', slug: 'cantal' },
                { id: 16, region_code: '75', code: '16', name: 'Charente', slug: 'charente' },
                { id: 17, region_code: '75', code: '17', name: 'Charente-Maritime', slug: 'charente maritime' },
                { id: 18, region_code: '24', code: '18', name: 'Cher', slug: 'cher' },
                { id: 19, region_code: '75', code: '19', name: 'Corr\u00e8ze', slug: 'correze' },
                { id: 20, region_code: '27', code: '21', name: 'C\u00f4te-d Or', slug: 'cote dor' },
                { id: 21, region_code: '53', code: '22', name: 'C\u00f4tes-d Armor', slug: 'cotes darmor' },
                { id: 22, region_code: '75', code: '23', name: 'Creuse', slug: 'creuse' },
                { id: 23, region_code: '75', code: '24', name: 'Dordogne', slug: 'dordogne' },
                { id: 24, region_code: '27', code: '25', name: 'Doubs', slug: 'doubs' },
                { id: 25, region_code: '84', code: '26', name: 'Dr\u00f4me', slug: 'drome' },
                { id: 26, region_code: '28', code: '27', name: 'Eure', slug: 'eure' },
                { id: 27, region_code: '24', code: '28', name: 'Eure-et-Loir', slug: 'eure et loir' },
                { id: 28, region_code: '53', code: '29', name: 'Finist\u00e8re', slug: 'finistere' },
                { id: 29, region_code: '94', code: '2A', name: 'Corse-du-Sud', slug: 'corse du sud' },
                { id: 30, region_code: '94', code: '2B', name: 'Haute-Corse', slug: 'haute corse' },
                { id: 31, region_code: '76', code: '30', name: 'Gard', slug: 'gard' },
                { id: 32, region_code: '76', code: '31', name: 'Haute-Garonne', slug: 'haute garonne' },
                { id: 33, region_code: '76', code: '32', name: 'Gers', slug: 'gers' },
                { id: 34, region_code: '75', code: '33', name: 'Gironde', slug: 'gironde' },
                { id: 35, region_code: '76', code: '34', name: 'H\u00e9rault', slug: 'herault' },
                { id: 36, region_code: '53', code: '35', name: 'Ille-et-Vilaine', slug: 'ille et vilaine' },
                { id: 37, region_code: '24', code: '36', name: 'Indre', slug: 'indre' },
                { id: 38, region_code: '24', code: '37', name: 'Indre-et-Loire', slug: 'indre et loire' },
                { id: 39, region_code: '84', code: '38', name: 'Is\u00e8re', slug: 'isere' },
                { id: 40, region_code: '27', code: '39', name: 'Jura', slug: 'jura' },
                { id: 41, region_code: '75', code: '40', name: 'Landes', slug: 'landes' },
                { id: 42, region_code: '24', code: '41', name: 'Loir-et-Cher', slug: 'loir et cher' },
                { id: 43, region_code: '84', code: '42', name: 'Loire', slug: 'loire' },
                { id: 44, region_code: '84', code: '43', name: 'Haute-Loire', slug: 'haute loire' },
                { id: 45, region_code: '52', code: '44', name: 'Loire-Atlantique', slug: 'loire atlantique' },
                { id: 46, region_code: '24', code: '45', name: 'Loiret', slug: 'loiret' },
                { id: 47, region_code: '76', code: '46', name: 'Lot', slug: 'lot' },
                { id: 48, region_code: '75', code: '47', name: 'Lot-et-Garonne', slug: 'lot et garonne' },
                { id: 49, region_code: '76', code: '48', name: 'Loz\u00e8re', slug: 'lozere' },
                { id: 50, region_code: '52', code: '49', name: 'Maine-et-Loire', slug: 'maine et loire' },
                { id: 51, region_code: '28', code: '50', name: 'Manche', slug: 'manche' },
                { id: 52, region_code: '44', code: '51', name: 'Marne', slug: 'marne' },
                { id: 53, region_code: '44', code: '52', name: 'Haute-Marne', slug: 'haute marne' },
                { id: 54, region_code: '52', code: '53', name: 'Mayenne', slug: 'mayenne' },
                { id: 55, region_code: '44', code: '54', name: 'Meurthe-et-Moselle', slug: 'meurthe et moselle' },
                { id: 56, region_code: '44', code: '55', name: 'Meuse', slug: 'meuse' },
                { id: 57, region_code: '53', code: '56', name: 'Morbihan', slug: 'morbihan' },
                { id: 58, region_code: '44', code: '57', name: 'Moselle', slug: 'moselle' },
                { id: 59, region_code: '27', code: '58', name: 'Ni\u00e8vre', slug: 'nievre' },
                { id: 60, region_code: '32', code: '59', name: 'Nord', slug: 'nord' },
                { id: 61, region_code: '32', code: '60', name: 'Oise', slug: 'oise' },
                { id: 62, region_code: '28', code: '61', name: 'Orne', slug: 'orne' },
                { id: 63, region_code: '32', code: '62', name: 'Pas-de-Calais', slug: 'pas de calais' },
                { id: 64, region_code: '84', code: '63', name: 'Puy-de-D\u00f4me', slug: 'puy de dome' },
                {
                    id: 65,
                    region_code: '75',
                    code: '64',
                    name: 'Pyr\u00e9n\u00e9es-Atlantiques',
                    slug: 'pyrenees atlantiques'
                },
                { id: 66, region_code: '76', code: '65', name: 'Hautes-Pyr\u00e9n\u00e9es', slug: 'hautes pyrenees' },
                {
                    id: 67,
                    region_code: '76',
                    code: '66',
                    name: 'Pyr\u00e9n\u00e9es-Orientales',
                    slug: 'pyrenees orientales'
                },
                { id: 68, region_code: '44', code: '67', name: 'Bas-Rhin', slug: 'bas rhin' },
                { id: 69, region_code: '44', code: '68', name: 'Haut-Rhin', slug: 'haut rhin' },
                { id: 70, region_code: '84', code: '69', name: 'Rh\u00f4ne', slug: 'rhone' },
                { id: 71, region_code: '27', code: '70', name: 'Haute-Sa\u00f4ne', slug: 'haute saone' },
                { id: 72, region_code: '27', code: '71', name: 'Sa\u00f4ne-et-Loire', slug: 'saone et loire' },
                { id: 73, region_code: '52', code: '72', name: 'Sarthe', slug: 'sarthe' },
                { id: 74, region_code: '84', code: '73', name: 'Savoie', slug: 'savoie' },
                { id: 75, region_code: '84', code: '74', name: 'Haute-Savoie', slug: 'haute savoie' },
                { id: 76, region_code: '11', code: '75', name: 'Paris', slug: 'paris' },
                { id: 77, region_code: '28', code: '76', name: 'Seine-Maritime', slug: 'seine maritime' },
                { id: 78, region_code: '11', code: '77', name: 'Seine-et-Marne', slug: 'seine et marne' },
                { id: 79, region_code: '11', code: '78', name: 'Yvelines', slug: 'yvelines' },
                { id: 80, region_code: '75', code: '79', name: 'Deux-S\u00e8vres', slug: 'deux sevres' },
                { id: 81, region_code: '32', code: '80', name: 'Somme', slug: 'somme' },
                { id: 82, region_code: '76', code: '81', name: 'Tarn', slug: 'tarn' },
                { id: 83, region_code: '76', code: '82', name: 'Tarn-et-Garonne', slug: 'tarn et garonne' },
                { id: 84, region_code: '93', code: '83', name: 'Var', slug: 'var' },
                { id: 85, region_code: '93', code: '84', name: 'Vaucluse', slug: 'vaucluse' },
                { id: 86, region_code: '52', code: '85', name: 'Vend\u00e9e', slug: 'vendee' },
                { id: 87, region_code: '75', code: '86', name: 'Vienne', slug: 'vienne' },
                { id: 88, region_code: '75', code: '87', name: 'Haute-Vienne', slug: 'haute vienne' },
                { id: 89, region_code: '44', code: '88', name: 'Vosges', slug: 'vosges' },
                { id: 90, region_code: '27', code: '89', name: 'Yonne', slug: 'yonne' },
                { id: 91, region_code: '27', code: '90', name: 'Territoire de Belfort', slug: 'territoire de belfort' },
                { id: 92, region_code: '11', code: '91', name: 'Essonne', slug: 'essonne' },
                { id: 93, region_code: '11', code: '92', name: 'Hauts-de-Seine', slug: 'hauts de seine' },
                { id: 94, region_code: '11', code: '93', name: 'Seine-Saint-Denis', slug: 'seine saint denis' },
                { id: 95, region_code: '11', code: '94', name: 'Val-de-Marne', slug: 'val de marne' },
                { id: 96, region_code: '11', code: '95', name: 'Val-d Oise', slug: 'val doise' },
                { id: 97, region_code: '01', code: '971', name: 'Guadeloupe', slug: 'guadeloupe' },
                { id: 98, region_code: '02', code: '972', name: 'Martinique', slug: 'martinique' },
                { id: 99, region_code: '03', code: '973', name: 'Guyane', slug: 'guyane' },
                { id: 100, region_code: '04', code: '974', name: 'La R\u00e9union', slug: 'la reunion' },
                { id: 101, region_code: '06', code: '976', name: 'Mayotte', slug: 'mayotte' },
                {
                    id: 102,
                    region_code: 'COM',
                    code: '975',
                    name: 'Saint-Pierre-et-Miquelon',
                    slug: 'saint pierre et miquelon'
                },
                { id: 103, region_code: 'COM', code: '977', name: 'Saint-Barth\u00e9lemy', slug: 'saint barthelemy' },
                { id: 104, region_code: 'COM', code: '978', name: 'Saint-Martin', slug: 'saint martin' },
                {
                    id: 105,
                    region_code: 'COM',
                    code: '984',
                    name: 'Terres australes et antarctiques fran\u00e7aises',
                    slug: 'terres australes et antarctiques francaises'
                },
                { id: 106, region_code: 'COM', code: '986', name: 'Wallis et Futuna', slug: 'wallis et futuna' },
                {
                    id: 107,
                    region_code: 'COM',
                    code: '987',
                    name: 'Polyn\u00e9sie fran\u00e7aise',
                    slug: 'polynesie francaise'
                },
                {
                    id: 108,
                    region_code: 'COM',
                    code: '988',
                    name: 'Nouvelle-Cal\u00e9donie',
                    slug: 'nouvelle caledonie'
                },
                { id: 109, region_code: 'COM', code: '989', name: '\u00cele de Clipperton', slug: 'ile de clipperton' }
            ],
            myregion: [
                { id: 1, name: 'Auvergne-Rhône-Alpes' },
                { id: 2, name: 'Bourgogne-Franche-Comté' },
                { id: 3, name: 'Bretagne' },
                { id: 4, name: 'Centre-Val de Loire' },
                { id: 5, name: 'Corse' },
                { id: 6, name: 'Grand Est' },
                { id: 7, name: 'Hauts-de-France' },
                { id: 8, name: 'Ile-de-France' },
                { id: 9, name: 'Normandie' },
                { id: 10, name: 'Nouvelle-Aquitaine' },
                { id: 11, name: 'Occitanie' },
                { id: 12, name: 'Pays de la Loire' },
                { id: 13, name: "Provence-Alpes-Côte d'Azur" }
            ],
            imagecount: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getData = this.getData.bind(this);
        this.handleUploadContract = this.handleUploadContract.bind(this);
        this.handleDeleteContract = this.handleDeleteContract.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.chargeImg = this.chargeImg.bind(this);

        this.Auth = new AuthService();
    }

    componentDidMount() {
        this.getData();
        fetchApi(`/admin/wizard/imagecount`)
            .then((res) => res.json())
            .then((e) => {
                this.setState({ imagecount: e });
            });
    }

    getData() {
        this.Auth.fetch(`${constants.endpoint}/admin/wizard`)
            .then((res) => {
                this.setState({
                    signs: res.signs,
                    users: res.users,
                    shops: res.shops,
                    groups: res.groups,
                    contracts: res.contracts,
                    loading: false
                });
            })
            .catch((err) => console.error(err));
    }

    handleChange(e) {
        const currentElt = this.state.currentElt;

        currentElt[e.target.name] = e.target.value;

        this.setState({
            currentElt: currentElt
        });
    }

    toggleChange() {
        this.setState({
            checked: !this.state.checked
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        const model = e.target.dataset.model;
        const currentElt = this.state.currentElt;

        if (!validate(currentElt, model)) {
            return;
        }

        this.setState({ loading: true });

        if (currentElt.id) {
            // Update
            this.Auth.fetch(`${constants.endpoint}/admin/wizard/${model}/${currentElt.id}`, {
                method: 'PUT',
                body: JSON.stringify(currentElt)
            }).then((res) => {
                if (!res.success) {
                    return console.error('Error updating');
                }

                this.setState({ loading: true, page: WizardPages.OVERVIEW }, () => {
                    this.getData();
                });

                notify.show('Mis a jour', 'success');
            });
        } else {
            // Create
            this.Auth.fetch(`${constants.endpoint}/admin/wizard/${model}`, {
                method: 'POST',
                body: JSON.stringify(currentElt)
            }).then((res) => {
                if (!res.success) {
                    return console.error('Error updating');
                }

                notify.show('Element cree', 'success');
                let modalBody = null;

                if (this.props.user.role.Shop.AgentId !== 1) {
                    return this.setState({ loading: true, page: WizardPages.OVERVIEW }, () => {
                        this.getData();
                    });
                }

                switch (model) {
                    case 'Group':
                        modalBody = (
                            <button
                                style={{
                                    marginLeft: '1em'
                                }}
                                className="btn btn-info"
                                onClick={() =>
                                    this.setState({
                                        page: WizardPages.SHOP_FORM,
                                        currentElt: {
                                            GroupId: res.id
                                        },
                                        modalOpen: false
                                    })
                                }
                            >
                                Creer Shop
                            </button>
                        );

                        this.setState(
                            {
                                modalOpen: true,
                                buttonClicked: false,
                                modalBody: modalBody
                            },
                            () => {
                                this.getData();
                            }
                        );
                        break;

                    case 'Shop':
                        modalBody = (
                            <div>
                                <button
                                    style={{ marginLeft: '1em' }}
                                    className="btn btn-info"
                                    onClick={() =>
                                        this.setState({
                                            page: WizardPages.USER_FORM,
                                            currentElt: {
                                                GroupId: currentElt.GroupId,
                                                ShopId: res.id
                                            },
                                            modalOpen: false,
                                            buttonClicked: false
                                        })
                                    }
                                >
                                    Creer User
                                </button>
                            </div>
                        );

                        this.setState(
                            {
                                modalOpen: true,
                                buttonClicked: false,
                                modalBody: modalBody
                            },
                            () => {
                                this.getData();
                            }
                        );
                        break;

                    default:
                        this.setState({ loading: true, page: WizardPages.OVERVIEW }, () => {
                            this.getData();
                        });
                        break;
                }
            });
        }
    }

    handleClose(e) {
        e.preventDefault();
        this.setState({
            currentElt: null,
            page: WizardPages.OVERVIEW
        });
    }

    handleEdit(page, id) {
        this.setState({ loading: true, page: page });

        let model = null;

        switch (page) {
            case WizardPages.SHOP_FORM:
                model = 'Shop';
                break;

            case WizardPages.SIGN_FORM:
                model = 'Sign';
                break;

            case WizardPages.USER_FORM:
                model = 'User';
                break;

            case WizardPages.GROUP_FORM:
                model = 'Group';
                break;
            default:
                break;
        }

        this.Auth.fetch(`${constants.endpoint}/admin/wizard/${model}/${id}`).then((res) => {
            if (!res.success) {
                return console.error('Error getting data');
            }

            this.setState({ loading: false, currentElt: res.data });
        });
    }

    handleUploadContract(shopId, e) {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            if (!window.confirm('Upload ' + file.name + '?')) {
                return;
            }

            const data = new FormData();
            data.append(file.name, file);

            sendFile(`/admin/wizard/contract/${shopId}`, {
                body: data,
                method: 'POST'
            }).then((res) => {
                if (res.status === 200) {
                    this.getData();
                }
            });
        }
    }

    handleDeleteContract(shopId) {
        fetchApi(`/admin/wizard/contracts/${shopId}`, { method: 'DELETE' }).then((res) => {
            if (res.status === 200) {
                this.getData();
            }
        });
    }

    handleNew(page) {
        this.setState({ page: page, currentElt: {} });
    }

    chargeImg(page, id, shopImage, isSign = false) {
        this.setState({ page: page, id: id, shopImage, isSign });
    }

    handleEmail(email, login, password, id) {
        if (this.state.checked === true && !id) {
            fetchApi('/admin/wizard/email', {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                    login: login,
                    password: password
                })
            })
                .then((res) => {
                    notify.show('Mail envoyé', 'success');
                })
                .catch(function (error) {
                    console.log("Il y a eu un problème avec l'opération : " + error.message);
                });
        } else {
            this.setState({
                checked: true
            });
        }
    }

    render() {
        if (this.state.loading) {
            return <div>Loading...</div>;
        }

        if (this.state.page === WizardPages.OVERVIEW) {
            return (
                <Panel>
                    <Row>
                        <Col md={12}>
                            <Tabs id="tabs" activeKey={this.state.key} onSelect={(key) => this.setState({ key: key })}>
                                <input
                                    type="text"
                                    onChange={(e) => this.setState({ query: e.target.value })}
                                    className="form-control search-wizard-input"
                                />
                                <Tab id="signs" eventKey={1} title="Enseignes">
                                    <Table className="wizard-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th
                                                    style={{
                                                        position: 'relative'
                                                    }}
                                                >
                                                    Nom
                                                    <button
                                                        className="btn btn-add-entity btn-sm"
                                                        onClick={() => this.handleNew(WizardPages.SIGN_FORM)}
                                                    >
                                                        <i className="fa fa-plus" />
                                                    </button>
                                                </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.signs
                                                .filter((e) => {
                                                    if (this.state.query.trim().length === 0 || !this.state.query) {
                                                        return true;
                                                    }

                                                    if (
                                                        e.name.toUpperCase().indexOf(this.state.query.toUpperCase()) !==
                                                        -1
                                                    ) {
                                                        return true;
                                                    }

                                                    return false;
                                                })
                                                .map((s) => (
                                                    <tr key={s.id}>
                                                        <td>{s.id}</td>
                                                        <td>
                                                            {s.name}
                                                            <button
                                                                className="btn btn-info btn-sm right-btn"
                                                                onClick={() => {
                                                                    this.handleEdit(WizardPages.SIGN_FORM, s.id);
                                                                }}
                                                            >
                                                                <i className="fa fa-pen" />
                                                            </button>
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: 'right',
                                                                width: 20
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-info btn-sm"
                                                                onClick={() =>
                                                                    this.chargeImg(
                                                                        WizardPages.SHOW_IMG,
                                                                        s.id,
                                                                        null,
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Voir les Images ({this.state.imagecount[s.id] || '0'})
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab id="2" eventKey={2} title="Groups">
                                    <Table className="wizard-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nom</th>
                                                <th
                                                    style={{
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <button
                                                        className="btn btn-add-entity"
                                                        onClick={() => this.handleNew(WizardPages.GROUP_FORM)}
                                                    >
                                                        <i className="fa fa-plus" />
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.groups
                                                .filter((e) => {
                                                    if (this.state.query.trim().length === 0) {
                                                        return true;
                                                    }

                                                    if (
                                                        e.name.toUpperCase().indexOf(this.state.query.toUpperCase()) !==
                                                        -1
                                                    ) {
                                                        return true;
                                                    }

                                                    return false;
                                                })
                                                .map((g) => (
                                                    <tr key={g.id}>
                                                        <td>{g.id}</td>
                                                        <td
                                                            style={{
                                                                wordBreak: 'break-all'
                                                            }}
                                                        >
                                                            {g.name}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            <DropdownButton
                                                                bsStyle="info"
                                                                bsSize="small"
                                                                title="Actions"
                                                                pullRight={true}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        this.handleEdit(WizardPages.GROUP_FORM, g.id);
                                                                    }}
                                                                    eventKey="1"
                                                                >
                                                                    <i className="fa fa-pen" />
                                                                    Edit
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            page: WizardPages.SHOP_FORM,
                                                                            currentElt: {
                                                                                GroupId: g.id
                                                                            }
                                                                        });
                                                                    }}
                                                                    eventKey="2"
                                                                >
                                                                    <i className="fa fa-plus" />
                                                                    Add
                                                                </MenuItem>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab id="3" title="Shops" eventKey={3}>
                                    <Table className="wizard-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Groupe</th>
                                                <th>Contrat</th>
                                                <th>Date signature contrat</th>
                                                <th>Date de formation</th>
                                                <th>Nom</th>
                                                <th>Nom</th>
                                                <th></th>
                                                <th
                                                    style={{
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <button
                                                        className="btn btn-add-entity btn-sm"
                                                        onClick={() => this.handleNew(WizardPages.SHOP_FORM)}
                                                    >
                                                        <i className="fa fa-plus" />
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.shops
                                                .filter((e) => {
                                                    if (this.state.query.trim().length === 0) {
                                                        return true;
                                                    }

                                                    const GroupName = (
                                                        this.state.groups.find(({ id }) => id === e.GroupId) || {
                                                            name: 'Unknown'
                                                        }
                                                    ).name.toUpperCase();

                                                    if (
                                                        e.name.toUpperCase().indexOf(this.state.query.toUpperCase()) !==
                                                            -1 ||
                                                        GroupName.indexOf(this.state.query.toUpperCase()) !== -1 ||
                                                        (e.city || 'xcity')
                                                            .toUpperCase()
                                                            .indexOf(this.state.query.toUpperCase()) !== -1
                                                    ) {
                                                        return true;
                                                    }

                                                    return false;
                                                })
                                                .map((s) => (
                                                    <tr key={s.id}>
                                                        <td>{s.id}</td>
                                                        <td>
                                                            {
                                                                (
                                                                    this.state.groups.find(
                                                                        ({ id }) => id === s.GroupId
                                                                    ) || { name: 'No Group' }
                                                                ).name
                                                            }
                                                        </td>
                                                        <td>
                                                            {(() => {
                                                                const file = this.state.contracts.find(
                                                                    (id) => id === s.id.toString()
                                                                );

                                                                if (file) {
                                                                    return (
                                                                        <a
                                                                            href={`${constants.endpoint}/admin/wizard/contracts/${s.id}.pdf?key=8cbb11504345d10209f50ae4646ab5fee9d3a2bd`}
                                                                        >
                                                                            <img
                                                                                src={pdfIcon}
                                                                                alt="pdf"
                                                                                style={{
                                                                                    height: 30,
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    );
                                                                }

                                                                return (
                                                                    <input
                                                                        type="file"
                                                                        onChange={(e) =>
                                                                            this.handleUploadContract(s.id, e)
                                                                        }
                                                                    />
                                                                );
                                                            })()}
                                                        </td>
                                                        <td>
                                                            {s.dateContract
                                                                ? dateFormat(s.dateContract, 'dd/mm/yyyy')
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {s.dateFormation
                                                                ? dateFormat(s.dateFormation, 'dd/mm/yyyy')
                                                                : ''}
                                                        </td>
                                                        <td>{s.name}</td>
                                                        <td>{s.isMulti}</td>
                                                        <td
                                                            style={{
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            <DropdownButton
                                                                bsStyle="info"
                                                                bsSize="small"
                                                                title="Actions"
                                                                pullRight={true}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        this.handleEdit(WizardPages.SHOP_FORM, s.id);
                                                                    }}
                                                                    eventKey="1"
                                                                >
                                                                    <i className="fa fa-pen" />
                                                                    Edit
                                                                </MenuItem>
                                                                <li>
                                                                    {!s.offerId ||
                                                                        (this.state.visibleCrea === s.id ? (
                                                                            <div
                                                                                style={{
                                                                                    position: 'relative',
                                                                                    width: '245px',
                                                                                    height: '50px'
                                                                                }}
                                                                            >
                                                                                <iframe
                                                                                    title={s.offerId}
                                                                                    style={{
                                                                                        height: '50px',
                                                                                        outline: 'none',
                                                                                        border: 'none',
                                                                                        overflow: 'hidden',
                                                                                        position: 'absolute'
                                                                                    }}
                                                                                    scrolling="no"
                                                                                    src={
                                                                                        CreaFromSign(s.SignId, s.id)
                                                                                            .imageUrl
                                                                                    }
                                                                                />
                                                                                <a
                                                                                    style={{
                                                                                        display: 'block',
                                                                                        height: '50px',
                                                                                        width: '300px',
                                                                                        position: 'absolute'
                                                                                    }}
                                                                                    href={
                                                                                        CreaFromSign(s.SignId, s.id)
                                                                                            .targetUrl
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    {' '}
                                                                                </a>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        visibleCrea: s.id
                                                                                    })
                                                                                }
                                                                            >
                                                                                <i className="fa fa-eye" />
                                                                                Creative
                                                                            </div>
                                                                        ))}
                                                                </li>
                                                            </DropdownButton>
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: 'right',
                                                                width: 20
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-info btn-sm"
                                                                onClick={() =>
                                                                    this.chargeImg(
                                                                        WizardPages.SHOW_IMG,
                                                                        s.id,
                                                                        s.shopImage
                                                                    )
                                                                }
                                                            >
                                                                Voir les Images ({this.state.imagecount[s.name] || '0'})
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab id="4" title="Users" eventKey={4}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Login</th>
                                                <th
                                                    style={{
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <button
                                                        className="btn btn-add-entity btn-sm"
                                                        onClick={() => this.handleNew(WizardPages.USER_FORM)}
                                                    >
                                                        <i className="fa fa-plus" />
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users
                                                .filter((e) => {
                                                    if (this.state.query.trim().length === 0) {
                                                        return true;
                                                    }

                                                    if (
                                                        e.login
                                                            .toUpperCase()
                                                            .indexOf(this.state.query.toUpperCase()) !== -1
                                                    ) {
                                                        return true;
                                                    }

                                                    return false;
                                                })
                                                .map((u) => (
                                                    <tr key={u.id}>
                                                        <td>{u.id}</td>
                                                        <td>{u.login}</td>
                                                        <td
                                                            style={{
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-info btn-sm"
                                                                onClick={() => {
                                                                    this.handleEdit(WizardPages.USER_FORM, u.id);
                                                                }}
                                                            >
                                                                <i className="fa fa-pen" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Panel>
            );
        }

        let page = null;

        if (this.state.page === WizardPages.SIGN_FORM) {
            page = (
                <React.Fragment>
                    <SignForm
                        {...this.state.currentElt}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        handleClose={this.handleClose}
                    />
                    <DefaultAssets sign={this.state.currentElt} />
                </React.Fragment>
            );
        } else if (this.state.page === WizardPages.SHOP_FORM) {
            page = (
                <ShopForm
                    {...this.state.currentElt}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    handleClose={this.handleClose}
                    signs={this.state.signs}
                    mydepart={this.state.mydepart}
                    myregion={this.state.myregion}
                    groups={this.state.groups}
                    contracts={this.state.contracts}
                    onUploadContract={this.handleUploadContract}
                    handleDeleteContract={this.handleDeleteContract}
                    user={this.props.user}
                />
            );
        } else if (this.state.page === WizardPages.USER_FORM) {
            page = (
                <UserForm
                    {...this.state.currentElt}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    handleClose={this.handleClose}
                    groups={this.state.groups}
                    shops={this.state.shops}
                    handleEmail={this.handleEmail}
                    toggleChange={this.toggleChange}
                    checked={this.state.checked}
                    current_user={this.props.user}
                />
            );
        } else if (this.state.page === WizardPages.GROUP_FORM) {
            page = (
                <GroupForm
                    {...this.state.currentElt}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    handleClose={this.handleClose}
                />
            );
        } else if (this.state.page === WizardPages.SHOW_IMG) {
            page = (
                <ShopImg
                    history={this.props.history}
                    setShopImage={(id, image) => {
                        const shops = this.state.shops;
                        const shop = shops.find((s) => s.id === id);
                        shop.shopImage = image;
                        this.setState({ shops });
                    }}
                    shopImage={this.state.shopImage}
                    id={this.state.id}
                    shop={this.state.shops.find((s) => s.id === this.state.id)}
                    sign={this.state.signs.find((s) => s.id === this.state.id)}
                    showimg={this.state.showimg}
                    handleClose={this.handleClose}
                    isSign={this.state.isSign}
                />
            );
        }

        return (
            <Panel>
                <Row>
                    <Col md={12}>
                        <Modal show={this.state.modalOpen}>
                            <Modal.Header>
                                <Modal.Title>Etape suivante</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>{this.state.modalBody}</Modal.Body>

                            <Modal.Footer>
                                <button onClick={() => this.setState({ modalOpen: false })}>Close</button>
                            </Modal.Footer>
                        </Modal>
                        {page}
                    </Col>
                </Row>
            </Panel>
        );
    }
}
