import React from 'react';
import { Col } from 'react-bootstrap';

const CheeseAttributes = (props) => {
    let availableLabels = ['ab.jpg'];

    return (
        <div>
            <div className="form-group">
                <label htmlFor="label">Label</label>
                <div className="LabelsHorizontalScroll">
                    <Col
                        lg={2}
                        md={3}
                        xs={4}
                        className={'labelCol' + (props.labels.length === 0 ? ' active' : '')}
                        onClick={() => props.handleSetLabels([])}
                    >
                        <div className="noLabel">
                            <i className="fa fa-ban"></i>
                            <span>Aucun</span>
                        </div>
                    </Col>
                    {availableLabels.map((l) => (
                        <Col
                            lg={2}
                            md={3}
                            xs={4}
                            key={l}
                            onClick={() => {
                                if (props.labels.indexOf(l) === -1) {
                                    props.handleSetLabels([...props.labels, l].slice(0, 2));
                                } else {
                                    props.handleSetLabels(props.labels.filter((lb) => lb !== l));
                                }
                            }}
                            className={'labelCol' + (props.labels.indexOf(l) !== -1 ? ' active' : '')}
                        >
                            <img className="labelImg" src={`/labels/${l}`} alt={l} />
                        </Col>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CheeseAttributes;
