import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import PreviewAsset from '../PreviewAsset';
import Switch from 'react-switch';
import Calendar from 'react-calendar';
import { fetchApi } from '../../Services/NetworkServcice';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import PreviewTemplate from '../FreeTemplates/PreviewTemplate';

const canEditTime = (allowSlidesDurationChange, creative) => {
    return (
        !!creative &&
        allowSlidesDurationChange &&
        ((creative.creaType === 'UserCreative' && creative.creative.type === 'image') ||
            creative.creaType === 'FreeTemplateInstance' ||
            creative.creaType === 'Template')
    );
};

const hasExpDate = (expDate) => {
    const future2Yrs = new Date();
    future2Yrs.setFullYear(future2Yrs.getFullYear() + 2);
    const date = new Date(expDate);

    if (isNaN(date.getTime()) || date.getTime() === 0) {
        return false;
    }

    if (date.getTime() >= future2Yrs.getTime())
        return false;

    return true;
}

const EditorDateModal = ({
    creatives,
    i,
    onClose,
    visible,
    extended,
    isSubsequence,
    onSave,
    allowSlidesDurationChange,
    maxDuration = 5
}) => {
    const [state, setState] = useState({
        slide: null,
        startDate: null,
        expirationDate: null,
        categories: [],
        duration: 5
    });

    let preview = null;

    if (state.slide) {
        switch (state.slide.creaType) {
            case 'Template':
            case 'FreeTemplateInstance':
                preview = (
                    <PreviewTemplate
                        template={state.slide.creative.templateId}
                        elements={state.slide.creative.elements}
                    />
                );
                break;
            default:
                preview = <PreviewAsset assets={[state.slide.creative]} selected={state.slide.creative._id} />;
                break;
        }
    }

    useEffect(() => {
        if (!isSubsequence) {
            fetchApi(`/shop/assets`)
                .then((res) => res.json())
                .then((res) => {
                    setState((prevState) => ({
                        ...prevState,
                        categories: res.categories
                    }));
                });
        }
    }, [isSubsequence]);

    useEffect(() => {
        if (i === null) {
            return;
        }

        const slide = creatives[i];

        setState((prevState) => ({
            ...prevState,
            slide,
            startDate: slide.startDate,
            expirationDate: slide.expirationDate,
            duration: slide.duration || 5
        }));
    }, [creatives, i]);

    const isSameDateAsEvent = (slide) => {
        const d = new Date(state.startDate);
        const event = state.categories.find(({ _id }) => _id === slide.creative.event);

        if (!event) {
            return false;
        }

        const eventStartDate = new Date(event.startDate);

        return d.getTime() === eventStartDate.getTime();
    };

    return (
        <Modal dialogClassName="custom-modal Assistant" show={visible} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'center' }}>PROGRAMMATION DE DIFFUSION</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4} xs={12}>
                        {preview}
                    </Col>
                    <Col className="mb-16" xs={12} md={4}>
                        <div style={{ display: extended ? 'block' : 'none' }}>
                            <div className="flex center">
                                <Switch
                                    className="switch-class ml-0"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    onChange={(e) => {
                                        let startDate = null;

                                        if (e) {
                                            const d = new Date();
                                            d.setHours(0, 0, 0, 0);
                                            startDate = d;
                                        } else {
                                            startDate = state.slide.startDate;
                                        }

                                        setState({
                                            ...state,
                                            startDate
                                        });
                                    }}
                                    checked={!!state.startDate && !isSameDateAsEvent(state.slide)}
                                />
                                <span style={{ marginLeft: 10, marginTop: 3 }}>Date de démarrage</span>
                            </div>
                            {!state.startDate || isSameDateAsEvent(state.slide) || (
                                <React.Fragment>
                                    <p className="indications mt-8">
                                        Choisissez une date et une heure pour un démarrage différé de cette affiche dans
                                        la séquence : (l’affiche sera visible à partir de cette date)
                                    </p>
                                    <Calendar
                                        className="fruit-expiration"
                                        onChange={(date) => {
                                            setState({
                                                ...state,
                                                startDate: date
                                            });
                                        }}
                                        minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                        value={new Date(state.startDate)}
                                    />
                                    <div className="flex center">
                                        <p style={{ lineHeight: 1, margin: 0, marginRight: 4 }}>à</p>
                                        <TimePicker
                                            value={moment(state.startDate)}
                                            showSecond={false}
                                            onChange={(m) => setState({ ...state, startDate: m.toDate() })}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </Col>
                    <Col className="mb-16" xs={12} md={4}>
                        <div>
                            <div className="flex center">
                                <Switch
                                    className="switch-class ml-0"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    onChange={(e) => {
                                        let expirationDate = null;

                                        if (e) {
                                            const d = new Date();
                                            d.setHours(23, 59, 59, 999);
                                            d.setDate(d.getDate() + 1);
                                            expirationDate = d;
                                        }

                                        setState({
                                            ...state,
                                            expirationDate
                                        });
                                    }}
                                    checked={!!state.expirationDate && hasExpDate(state.expirationDate)}
                                />
                                <span style={{ marginLeft: 10, marginTop: 3 }}>Date d'expiration</span>
                            </div>
                            {!state.expirationDate || !hasExpDate(state.expirationDate) || (
                                <React.Fragment>
                                    <p className="indications mt-8">
                                        Choisissez une date et une heure d’expiration de cette affiche dans la séquence
                                        : (l’affiche s’effacera automatiquement après cette date)
                                    </p>
                                    <Calendar
                                        className="fruit-expiration"
                                        onChange={(date) => {
                                            date.setHours(23, 59, 59, 999);

                                            setState({
                                                ...state,
                                                expirationDate: date
                                            });
                                        }}
                                        value={new Date(state.expirationDate)}
                                    />
                                    {!extended || (
                                        <div className="flex center">
                                            <p style={{ lineHeight: 1, margin: 0, marginRight: 4 }}>à</p>
                                            <TimePicker
                                                value={moment(state.expirationDate)}
                                                showSecond={false}
                                                onChange={(m) =>
                                                    setState({
                                                        ...state,
                                                        expirationDate: m.toDate()
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </Col>
                    <Col className="mb-16" xs={12} md={4}>
                        {!canEditTime(allowSlidesDurationChange, state.slide) || (
                            <div>
                                <p>Durée d'affichage: {state.duration || 5}s</p>
                                <input
                                    onChange={(e) => setState({ ...state, duration: parseInt(e.target.value) })}
                                    type="range"
                                    min="1"
                                    max={maxDuration}
                                    value={state.duration || 5}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <hr />
                <button
                    className="btn btn-success"
                    onClick={() => {
                        const crea = {
                            creative: state.slide.creative,
                            creaType: state.slide.creaType,
                            expirationDate: state.expirationDate,
                            startDate: state.startDate
                        };

                        if (canEditTime(allowSlidesDurationChange, state.slide)) {
                            crea.duration = state.duration;
                        }

                        onSave(crea);

                        onClose();
                    }}
                >
                    OK
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditorDateModal;
