import React from 'react';
import { Panel, Table, Col, Row } from 'react-bootstrap';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import { Link } from 'react-router-dom';
import { Bar as BarChart } from 'react-chartjs';
import { Line as LineChart } from 'react-chartjs';

const colors = [
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
];

class Stats extends React.Component {
    constructor() {
        super();

        this.state = {
            users: [],
            stats: [],
            usage: []
        };

        this.getData = this.getData.bind(this);

        this.Auth = new AuthService();
    }

    getData(from) {
        this.Auth.fetch(`${constants.endpoint}/admin/stats?from=${from}`)
            .then(res => {
                this.setState({
                    users: res.users,
                    stats: res.stats.sort((a, b) => {
                        if (a._id.year > b._id.year) {
                            return 1;
                        } else if (b._id.year > a._id.year) {
                            return -1;
                        }

                        if (a._id.month > b._id.month) {
                            return 1;
                        } else if (b._id.month > a._id.month) {
                            return -1;
                        }

                        if (a._id.dayOfMonth > b._id.dayOfMonth) {
                            return 1;
                        }

                        return -1;
                    })
                });
            })
            .catch(err => {
                console.error(err);
            });

        this.Auth.fetch(
            `${constants.endpoint}/admin/stats/usage?from=${from}`
        ).then(res =>
            this.setState({
                usage: res.usage.sort((a, b) => {
                    if (a._id.year > b._id.year) {
                        return 1;
                    } else if (b._id.year > a._id.year) {
                        return -1;
                    }

                    if (a._id.month > b._id.month) {
                        return 1;
                    } else if (b._id.month > a._id.month) {
                        return -1;
                    }

                    if (a._id.dayOfMonth > b._id.dayOfMonth) {
                        return 1;
                    }

                    return -1;
                })
            })
        );
    }

    componentDidMount() {
        this.getData(new Date() - 7 * 60 * 60 * 24 * 1000);
    }

    render() {
        const actions = [];

        this.state.stats.forEach(d =>
            d.actions.forEach(a => {
                if (actions.indexOf(a) === -1) {
                    actions.push(a);
                }
            })
        );

        const datasets = actions.map((a, i) => {
            return {
                label: a,
                fillColor: colors[i],
                strokeColor: colors[i] + 'd0',
                highlightFill: colors[i] + 'd0',
                highlightStroke: colors[i],
                data: this.state.stats.map(d => {
                    const i = d.actions.indexOf(a);
                    if (i !== -1) {
                        return d.counts[i];
                    } else {
                        return 0;
                    }
                })
            };
        });

        const data = {
            labels: this.state.stats.map(
                d => d._id.dayOfMonth + '/' + d._id.month + '/' + d._id.year
            ),
            datasets: datasets
        };

        if (this.state.stats.length === 0) {
            return 'Loading...';
        }

        return (
            <Panel>
                <span>Statistiques</span>
                <Row>
                    <Col md={7}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Login</th>
                                    <th>Enseigne</th>
                                    <th>Shop</th>
                                    <th>Logins (7j)</th>
                                    <th>MAJ Sequences (7j)</th>
                                    <th>Logins (1j)</th>
                                    <th>MAJ Sequences (1j)</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users
                                    .sort((a, b) => {
                                        const totA =
                                            (a.seq ? a.seq : 0) +
                                            (a.logins ? a.logins : 0);

                                        const totB =
                                            (b.seq ? b.seq : 0) +
                                            (b.logins ? b.logins : 0);

                                        return totB - totA;
                                    })
                                    .map(u => (
                                        <tr key={u.id}>
                                            <td>{u.login}</td>
                                            <td>{u['Shop.Sign.name']}</td>
                                            <td>{u['Shop.name']}</td>
                                            <td>{u.logins ? u.logins : 0}</td>
                                            <td>{u.seq ? u.seq : 0}</td>
                                            <td>
                                                {u.loginsToday
                                                    ? u.loginsToday
                                                    : 0}
                                            </td>
                                            <td>
                                                {u.seqToday ? u.seqToday : 0}
                                            </td>
                                            <td>
                                                <Link
                                                    to={'/stats/' + u.id}
                                                    className="btn btn-default"
                                                >
                                                    <i className="fa fa-eye" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={5}>
                        <Row>
                            Operations/jour:
                            <Col md={12}>
                                <BarChart
                                    data={data}
                                    options={{
                                        responsive: true
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            Utilisation de la plateforme:
                            <Col md={12}>
                                <LineChart
                                    data={{
                                        labels: this.state.usage.map(
                                            d =>
                                                d._id.dayOfMonth +
                                                '/' +
                                                d._id.month +
                                                '/' +
                                                d._id.year
                                        ),
                                        datasets: [
                                            {
                                                label: 'Usage',
                                                fillColor: '#DAA52075',
                                                strokeColor: '#DAA52075',
                                                highlightFill: '#DAA52075',
                                                highlightStroke: '#DAA520',
                                                data: this.state.usage.map(
                                                    u => u.count
                                                )
                                            }
                                        ]
                                    }}
                                    options={{ responsive: true }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Panel>
        );
    }
}

export default Stats;
