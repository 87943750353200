import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import AuthService from '../../../Components/Auth/AuthService';
import constants from '../../../Constants';

function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

export const arrayReplaceCopy = (array, i, elt) => {
    const newArray = [...array];
    newArray[i] = elt;

    return newArray;
};

const getDimensions = async (file) => {
    const src = URL.createObjectURL(file);

    switch (file.type) {
        case 'image/jpeg':
        case 'image/png':
            return await new Promise((resolve) => {
                const img = new Image();
                img.onload = (e) => {
                    resolve({ height: e.target.height, width: e.target.width });
                    URL.revokeObjectURL(src);
                };

                img.src = src;
            });
        case 'video/mp4':
        case 'video/m4v':
            return await new Promise((resolve) => {
                const video = document.createElement('video');

                video.addEventListener('loadedmetadata', (e) => {
                    resolve({ height: e.target.videoHeight, width: e.target.videoWidth });
                    URL.revokeObjectURL(src);
                    video.remove();
                });

                video.src = src;
            });
        default:
            URL.revokeObjectURL(src);
            return { height: 0, width: 0 };
    }
};

const Dropzone = ({ files, addFiles, removeFile, uploadingState, formats }) => {
    const fileInput = useRef(null);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            addFiles(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };

    return (
        <React.Fragment>
            <input
                type="file"
                ref={fileInput}
                accept=".mp4,image/*,.m4v"
                multiple={true}
                style={{ display: 'none' }}
                onChange={(e) => addFiles(e.target.files)}
            />
            <div
                className="dropzone"
                onDrag={handleDrag}
                onDragLeave={handleDragOut}
                onDragOver={handleDragIn}
                onDrop={handleDrop}
            >
                {files.map((f, i) => {
                    let icon = <i onClick={() => removeFile(i)} className="fa fa-times"></i>;
                    if (uploadingState.length !== 0) {
                        if (uploadingState[i] === -1) {
                            icon = <i className="fa fa-exclamation-triangle"></i>;
                        } else if (uploadingState[i] === 100) {
                            icon = <i className="fa fa-check"></i>;
                        } else {
                            icon = <i className="fa fa-sync rotate"></i>;
                        }
                    }

                    return (
                        <div className="file" key={f.name}>
                            <div className="icon">
                                <i className={`fa fa-${f.type.split('/')[0]}`}></i>
                            </div>
                            <div className="data">
                                <p>{f.name}</p>
                                <p>
                                    Type: {f.type.split('/')[0]}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Format:{' '}
                                    {formats[f.name] + ' (autodetected)' || '<...>'}
                                </p>
                                <p>Size: {humanFileSize(f.size)}</p>
                            </div>
                            <div className="actions">{icon}</div>
                            <div
                                className="progress"
                                style={{
                                    width: uploadingState.length === 0 ? 0 : `${uploadingState[i]}%`
                                }}
                            ></div>
                        </div>
                    );
                })}
                <div className="file add" onClick={() => fileInput.current.click()}>
                    <i className="fa fa-plus"></i>
                </div>
            </div>
        </React.Fragment>
    );
};

export const UploadAsset = ({ signs, groups, category, onClose, onNewCreatives }) => {
    const [state, setState] = useState({
        files: [],
        uploading: false,
        formats: {},
        uploadingState: [],
        signId: [],
        tags: [],
        groupId: null,
        finished: false
    });

    useEffect(() => {
        if (category !== null) {
            setState((prevState) => ({
                ...prevState,
                files: [],
                uploadingState: [],
                uploading: false,
                formats: {},
                signId: [],
                tags: [],
                groupId: null,
                finished: false
            }));
        }
    }, [category]);

    const uploadFiles = async () => {
        const uploadFile = (url, file, idx) =>
            new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.addEventListener('progress', (e) => {
                    setState((prevState) => ({
                        ...prevState,
                        uploadingState: arrayReplaceCopy(
                            [...prevState.uploadingState],
                            idx,
                            Math.floor(e.loaded / e.total)
                        )
                    }));
                });

                xhr.onreadystatechange = function () {
                    if (4 === this.readyState) {
                        if (xhr.status !== 200) {
                            return reject(xhr.response);
                        }

                        return resolve(xhr.response);
                    }
                };

                const data = new FormData();
                data.append('signId', JSON.stringify(state.signId));
                data.append('groupId', state.groupId);
                data.append('event', category._id);
                data.append('orientation', state.formats[file.name]);
                data.append('tags', JSON.stringify(state.tags));
                data.append(file.name, file);

                xhr.open('POST', url, true);
                xhr.setRequestHeader('x-access-token', new AuthService().getToken());
                xhr.send(data);
            });

        setState((prevState) => ({
            ...prevState,
            uploading: true,
            uploadingState: [...prevState.files.map(() => 0)]
        }));

        const assets = [];

        for (let i = 0; i < state.files.length; i++) {
            if (!state.formats[state.files[i].name]) {
                setState((prevState) => ({
                    ...prevState,
                    uploadingState: arrayReplaceCopy([...prevState.uploadingState], i, -1)
                }));

                continue;
            }

            try {
                const body = await uploadFile(
                    `${constants.endpoint}/admin/maronniers/${category._id}`,
                    state.files[i],
                    i
                );

                assets.push(JSON.parse(body));

                setState((prevState) => ({
                    ...prevState,
                    uploadingState: arrayReplaceCopy([...prevState.uploadingState], i, 100)
                }));
            } catch (e) {
                setState((prevState) => ({
                    ...prevState,
                    uploadingState: arrayReplaceCopy([...prevState.uploadingState], i, -1)
                }));

                notify.show(e, 'error');
            }
        }

        setState((prevState) => ({ ...prevState, uploading: false, finished: true }));
        onNewCreatives(assets, category._id);
    };

    const buttonText = state.finished ? 'Fermer' : 'Ajouter';
    const clickEvent = state.finished ? onClose : uploadFiles;

    return (
        <Modal className="modal-assets-admin" show={category !== null} onHide={state.uploading ? () => {} : onClose}>
            <Modal.Header>
                <Modal.Title>Ajouter une affiche</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <h4>Enseignes</h4>
                        <input
                            type="checkbox"
                            checked={state.signId.length === signs.length}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        signId: signs.map(({ id }) => id)
                                    }));
                                } else {
                                    setState((prevState) => ({
                                        ...prevState,
                                        signId: []
                                    }));
                                }
                            }}
                        />{' '}
                        Autoriser tous
                        <div className="data-scroll">
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>Enseigne</th>
                                        <th>Activé</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {signs.map((s) => (
                                        <tr key={s.id}>
                                            <td>{s.name}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={state.signId.includes(s.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                signId: [...prevState.signId, s.id]
                                                            }));
                                                        } else {
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                signId: prevState.signId.filter((id) => id !== s.id)
                                                            }));
                                                        }
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <h4>Groupe:</h4>
                        <Select
                            options={[
                                { label: 'Tous', value: null },
                                ...groups.map(({ id, name }) => ({
                                    label: name,
                                    value: id
                                }))
                            ]}
                            value={{
                                label: state.groupId ? groups.find(({ id }) => id === state.groupId).name : 'Tous',
                                value: state.groupId
                            }}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    groupId: e.value
                                }))
                            }
                        ></Select>
                    </div>
                    <div className="col-md-8">
                        <h4>Fichiers</h4>
                        <Dropzone
                            files={state.files}
                            addFiles={async (files) => {
                                const formats = {};

                                for (const f of files) {
                                    getDimensions(f).then(({ width, height }) => {
                                        if (width === 1080) {
                                            formats[f.name] = 'portrait';
                                        } else if (width === 1920) {
                                            formats[f.name] = 'landscape';
                                        } else {
                                            formats[f.name] = 'Unknown';
                                        }

                                        setState((prevState) => ({ ...prevState, formats }));
                                    });
                                }

                                setState((prevState) => ({ ...prevState, files: [...prevState.files, ...files] }));
                            }}
                            removeFile={(i) => {
                                const files = state.files;
                                files.splice(i, 1);
                                setState((prevState) => ({ ...prevState, files }));
                            }}
                            uploadingState={state.uploadingState}
                            formats={state.formats}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p style={{ marginTop: 10, marginBottom: 0 }}>Tags</p>
                        <TagsInput
                            value={state.tags}
                            onChange={(tags) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    tags
                                }))
                            }
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-success"
                    disabled={state.signId.length === 0 || state.files.length === 0}
                    onClick={() => state.uploading || clickEvent()}
                    style={{ cursor: state.uploading ? 'wait' : 'pointer' }}
                >
                    {state.uploading ? <i className="fa fa-sync rotate-sync"></i> : buttonText}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
