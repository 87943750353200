import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AuthService from '../../../Components/Auth/AuthService';
import constants from '../../../Constants';
import dateFormat from 'dateformat';

export default class Timeline extends React.Component {
    constructor() {
        super();

        this.state = {
            history: [],
            loading: false
        };

        this.getData = this.getData.bind(this);

        this.Auth = new AuthService();
    }

    getData(selected) {
        if (selected === -1) {
            return;
        }

        this.setState({ loading: true });

        this.Auth.fetch(`${constants.endpoint}/admin/history/${selected}`).then(res => {
            this.setState({ loading: false, history: res.history });
        });
    }

    componentDidMount() {
        this.getData(this.props.selected);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.selected === newProps.selected && this.props.upd === newProps.upd) {
            return;
        }

        this.getData(newProps.selected);
    }

    render() {
        if (this.state.loading) {
            return (
                <Row className={this.props.className}>
                    <Col md={12}>
                        <p style={{ textAlign: 'center', color: '#777' }}>Loading...</p>
                    </Col>
                </Row>
            );
        }

        if (this.state.history.length === 0) {
            return (
                <Row className={this.props.className}>
                    <Col md={12}>
                        <p style={{ textAlign: 'center', color: '#777' }}>No data.</p>
                    </Col>
                </Row>
            );
        }

        return (
            <Row className={this.props.className}>
                <Col md={12}>
                    <section id="cd-timeline" className="cd-container">
                        {this.state.history.map(h => (
                            <div key={h._id} className="cd-timeline-block">
                                <div className="cd-timeline-img cd-success">
                                    <i className={h.type} />
                                </div>
                                <div
                                    className="cd-timeline-img min cd-danger"
                                    style={this.props.assistant ? { display: 'none' } : {}}
                                    onClick={() => {
                                        if (!window.confirm('Etes vous sur de bien vouloir supprimer cet entree?')) {
                                            return;
                                        }

                                        this.props.handleDelete(h._id).then(() => {
                                            this.getData(this.props.selected);
                                        });
                                    }}
                                >
                                    <i className="fa fa-trash" />
                                </div>
                                <div className="cd-timeline-content">
                                    <h4>{h.title}</h4>
                                    <p>{h.message}</p>
                                    {h.attachments.map(a => {
                                        const rMp4 = new RegExp(/[A-Za-z0-9_]+\.mp4$/);

                                        if (rMp4.test(a)) {
                                            return (
                                                <video
                                                    key={a.name || a}
                                                    src={`${
                                                        constants.cloud_storage
                                                    }/History/${a.name || a}?key=U3RhcnJpbmcgUnVmdXMgU2V3ZWxsIChKb2huIEFkYW1zKSwgTHVrZSBLbGVpbnRhbmsK`}
                                                    controls={true}
                                                />
                                            );
                                        } else {
                                            return (
                                                <a
                                                    href={`${
                                                        constants.cloud_storage
                                                    }/History/${a.name || a}?key=U3RhcnJpbmcgUnVmdXMgU2V3ZWxsIChKb2huIEFkYW1zKSwgTHVrZSBLbGVpbnRhbmsK`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    key={a.name || a}
                                                >
                                                    <img
                                                        src={`${
                                                            constants.cloud_storage
                                                        }/History/${a.name || a}?key=U3RhcnJpbmcgUnVmdXMgU2V3ZWxsIChKb2huIEFkYW1zKSwgTHVrZSBLbGVpbnRhbmsK`}
                                                        alt="Attachment"
                                                    />
                                                    <div style={{textAlign: 'center'}}>
                                                        {a.date && dateFormat(a.date, 'dd/mm/yyyy HH:MM')}
                                                    </div>
                                                </a>
                                            );
                                        }
                                    })}
                                    <span className="cd-date">
                                        {dateFormat(h.date, 'dd/mm/yyyy HH:MM')}
                                        <br />
                                        <span>{h.author}</span>
                                    </span>
                                </div>
                            </div>
                        ))}
                    </section>
                </Col>
            </Row>
        );
    }
}
