import React, { useEffect, useState } from 'react';

const COLORS = [
    '#63cb89',
    '#2f7ed8',
    '#0d233a',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a'
];

const DAYS = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

const DayBlock = ({ dayName, hours = [], onChange }) => {
    return (
        <div className="day-block">
            <h6>{dayName}</h6>
            <div className="timings">
                {new Array(15).fill(null).map((_, i) => (
                    <div
                        className={`block-hr ${hours.includes(i + 7) ? 'selected' : ''}`}
                        onClick={() => onChange(i + 7)}
                        key={i}
                    >
                        <span>{i + 7}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Planning = ({ planning, onPlanningChange }) => {
    const dayBlocks = [];

    for (let i = 0; i < 7; i++) {
        dayBlocks.push(
            <DayBlock
                key={i}
                dayName={DAYS[i]}
                hours={planning[i]}
                onChange={(h) => {
                    const newPlanning = [...planning];
                    newPlanning[i] = [...newPlanning[i]];
                    const id = newPlanning[i].indexOf(h);
                    if (id === -1) {
                        newPlanning[i].push(h);
                        newPlanning[i].sort((a, b) => a - b);
                    } else {
                        newPlanning[i].splice(id, 1);
                    }

                    onPlanningChange(newPlanning);
                }}
            />
        );
    }

    return (
        <React.Fragment>
            <div>
                <h5>Planning</h5>
            </div>
            <div className="days">{dayBlocks}</div>
        </React.Fragment>
    );
};

const SequenceRecapBlocks = ({ isDefault, planning, index }) => {
    if (isDefault) {
        return <div className="bar-full"></div>;
    }

    // Create blocks
    const blocks = [];
    for (let i = 0; i < 7; i++) {
        if (planning[i].length === 0) continue;

        let block = {
            width: 1,
            startDay: i,
            startHour: planning[i][0],
            toEnd: planning[i][0] === 21,
            toBegin: planning[i][0] === 7
        };

        for (let j = 0; j < planning[i].length - 1; j++) {
            if (planning[i][j] + 1 === planning[i][j + 1]) {
                block.width++;
                block.toEnd = planning[i][j + 1] === 21;
            } else {
                blocks.push(block);
                block = {
                    width: 1,
                    startDay: i,
                    startHour: planning[i][j + 1],
                    toEnd: planning[i][j + 1] === 21
                };
            }
        }

        blocks.push(block);
    }

    return (
        <React.Fragment>
            {blocks.map((b, i) => (
                <div
                    key={i}
                    className="bar-planned"
                    style={{
                        left: `calc(${100 * (b.startDay / 7) + 100 * ((b.startHour - 7) / (15 * 7))}% + ${
                            b.startDay
                        }px - 2px)`,
                        width: `calc(${(b.width / (7 * 15)) * 100}% - 2px)`,
                        backgroundColor: COLORS[index % COLORS.length]
                    }}
                ></div>
            ))}
        </React.Fragment>
    );
};

const Recap = ({ devices, sequences, planning, currentSequenceId }) => {
    return (
        <div className="recap">
            <h5>Détails</h5>
            {devices.map((d) => {
                if (!d) return null;

                // Get used sequences for this device
                const sequenceAssigned = sequences.filter(
                    ({ devices, _id }) => devices.includes(d.id) && _id !== currentSequenceId
                );

                const sequencesPlanned = sequenceAssigned.filter(
                    ({ devices, isPlanned }) => devices.includes(d) && isPlanned
                );
                const mainSequence = sequenceAssigned.filter(({ isPlanned }) => !isPlanned)[0];

                // Store all sequences in new format
                const storedSequences = [];

                if (mainSequence) {
                    storedSequences.push({
                        name: mainSequence.name,
                        isDefault: true,
                        id: mainSequence._id
                    });
                }

                for (const s of sequencesPlanned) {
                    storedSequences.push({
                        name: s.name,
                        isDefault: false,
                        planning: s.planning,
                        id: s._id
                    });
                }

                return (
                    <div key={d.id} className="device-planning-recap">
                        <span className="name">{d.name}:</span>
                        <div className="progress-bar-planning">
                            <SequenceRecapBlocks isDefault={false} planning={planning} key={1} index={0} />
                            {storedSequences.map((s, i) => (
                                <SequenceRecapBlocks
                                    isDefault={s.isDefault}
                                    planning={s.planning}
                                    index={i + 1}
                                    key={s.id}
                                />
                            ))}
                        </div>
                    </div>
                );
            })}
            <div className="device-planning-recap">
                <span className="name time">Temps:</span>
                <div className="days-range">
                    {new Array(7).fill(null).map((_, i) => (
                        <div className="day" key={i}>
                            <div className="hours">
                                <div>7h</div>
                                <div>22h</div>
                            </div>
                            <div className="flex">
                                <div className="let">{DAYS[i][0]}</div>
                                <div className="rest">{DAYS[i].slice(1)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const PlanningModal = ({ onClose, show, devices, sequences, currentSequenceId, onSave, planning }) => {
    const [state, setState] = useState({
        planning: [[], [], [], [], [], [], []]
    });

    const close = () => {
        setState((prevState) => ({ ...prevState, planning: [[], [], [], [], [], [], []] }));
        onClose();
    };

    useEffect(() => {
        if (planning && planning.length === 7) {
            setState((prevState) => ({ ...prevState, planning }));
        }
    }, [planning]);

    return (
        <div
            className={`dyl-home planning-modal background ${show ? 'visible' : 'hidden'}`}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    close();
                }
            }}
        >
            <div className="close-btn-editor" onClick={close}>
                <i className="fa fa-times"></i>
            </div>
            <div className="card">
                <h3>Planning des séquences</h3>
                <p>Planifiez vos séquences et programmez les horaires et jours d'affichage.</p>
                <p className="explanation">
                    <i className="fa fa-question-circle"></i> Vous pouvez assigner plusieurs séquences à un même écran.
                    Les séquences que vous déciderez de planifier prendront alors le dessus sur la séquence déjà
                    assignée lors des horaires ou des jours sélectionnés dans cet assistant
                </p>
                <Planning planning={state.planning} onPlanningChange={(planning) => setState({ ...state, planning })} />
                <Recap
                    devices={devices}
                    planning={state.planning}
                    sequences={sequences}
                    currentSequenceId={currentSequenceId}
                />
                <button className="btn btn-info" onClick={() => onSave(state.planning)}>
                    Enregistrer
                </button>
            </div>
        </div>
    );
};

export default PlanningModal;
