import React, { useState } from 'react';
import HeaderNotifications from '../Components/HeaderNotifications';
import dateFormat from 'dateformat';
import { Link } from 'react-scroll';
import { Link as RRDLink } from 'react-router-dom';

const TUTORIALS_DATA = {
    desktop: [
        {
            id: 'accueil',
            name: 'Accueil',
            src: '1BfxS79Llu8'
        },
        {
            id: 'crea',
            name: "Créer d'une séquence",
            src: '8SAihN46Ays'
        },
        {
            id: 'seq',
            name: 'Ajouter une affiche',
            src: 'J5SSOmj1IYg'
        },
        {
            name: "Bibliothèque d'affiches",
            src: 'wZs30bY2tn8',
            id: 'bibli'
        },
        {
            id: 'fl',
            name: 'Créer fruits et légumes',
            src: 'FItTOTC8nkQ'
        },
        {
            id: 'prog',
            name: 'Programmer',
            src: 'fx8Nb94-Nco'
        },
        {
            id: 'contact',
            name: 'Contact',
            src: 'kDv5dZhy3ns'
        }
    ],
    udesktop: [
        {
            id: 'upload',
            name: 'Ajouter une affiche à la bibliothèque',
            src: 'kJ6X_EYxERU'
        },
        {
            id: 'ufl',
            name: 'Fruits et légumes',
            src: 'T8D5osNyr9Y'
        },
        {
            id: 'horaires',
            name: 'Affiches horaires',
            src: 'eKF6OxUCnCg'
        }
    ],
    mobile: [
        // { name: 'Authentification', src: 'https://www.youtube.com/embed/73Uq6t5K3j4', id: 'auth' },
        // { name: "Bibliothèque d'affiches", src: 'https://www.youtube.com/embed/Bo3ywCnFCQk', id: 'bibli' },
        // { name: "Création d'une séquence", src: 'https://www.youtube.com/embed/zzWuUvQaKas', id: 'create' },
        // {
        //     name: 'Intégrer une affiche dans une séquence',
        //     src: 'https://www.youtube.com/embed/7KpO66fP_uk',
        //     id: 'integrate'
        // },
        // {
        //     name: 'Intégrer des fruits et légumes dans votre séquence',
        //     src: 'https://www.youtube.com/embed/UyHU-ZnZgyc',
        //     id: 'fruits'
        // },
        // { name: 'Ajouter expiration', src: 'https://www.youtube.com/embed/OTQxIpXOvWY', id: 'exp' },
        // { name: "Demande d'affiches", src: 'https://www.youtube.com/embed/Fcue8-CRUrg', id: 'ask' }
    ]
};

const getTutorials = (mobile = false, userAgent = 1) => {
    if (mobile) {
        return TUTORIALS_DATA.mobile;
    } else {
        if (userAgent === 5) {
            return [...TUTORIALS_DATA.desktop, ...TUTORIALS_DATA.udesktop];
        }

        return TUTORIALS_DATA.desktop;
    }
};

const Tutorials = (props) => {
    const [state, setState] = useState({
        isDesktop: true || window.innerWidth > 425,
        filter: '',
        modalFiltersOpen: false
    });

    return (
        <div className="dyl-home tutorials">
            <header>
                <RRDLink to="/" className="logo">
                    <img src="/image.png" alt="logo" />
                </RRDLink>
                <p className="hidden-xs">{dateFormat(new Date(), 'dddd dd mmmm yyyy')}</p>
                <h3>Tutoriels</h3>
                <HeaderNotifications {...props.notifsData} />
            </header>
            <div className="search-filters">
                <input
                    type="text"
                    className="search"
                    placeholder="Entrez un mot clé"
                    value={state.filter}
                    onChange={(e) => setState({ ...state, filter: e.target.value })}
                />
                <div className="stacks" style={{ display: 'none' }}>
                    <div
                        className={state.isDesktop ? 'active' : ''}
                        onClick={() => setState({ ...state, isDesktop: true })}
                    >
                        Desktop
                    </div>
                    {/* <div
                        className={state.isDesktop ? '' : 'active'}
                        onClick={() => setState({ ...state, isDesktop: false })}
                    >
                        Mobile
                    </div> */}
                </div>
            </div>
            <div className="flex-row">
                <div className={`table-of-contents` + (state.modalFiltersOpen ? ' active' : '')}>
                    <div className="card">
                        <h4>Vidéos disponibles</h4>
                        {getTutorials(!state.isDesktop, props.user.role.Agent.id).map((d) => (
                            <Link
                                key={d.id}
                                to={d.id}
                                onClick={() => setState({ ...state, modalFiltersOpen: false })}
                                containerId="tutorial-content-card"
                                smooth="true"
                                activeClass="active"
                                spy={true}
                            >
                                {d.name}
                            </Link>
                        ))}
                    </div>
                    <div className="btn-close" onClick={() => setState({ ...state, modalFiltersOpen: false })}>
                        <i className="fa fa-times"></i>
                    </div>
                </div>
                <div className="tutorials">
                    <div className="mobile-controls">
                        <div className="btn-add" onClick={() => setState({ ...state, modalFiltersOpen: true })}>
                            <i className="fa fa-filter"></i>
                        </div>
                    </div>
                    <div>
                        <div className="banner-tutos">{state.isDesktop ? 'Desktop' : 'Mobile'}</div>
                        <div className="card" id="tutorial-content-card">
                            {getTutorials(!state.isDesktop, props.user.role.Agent.id)
                                .filter((d) => d.name.toUpperCase().indexOf(state.filter.toUpperCase().trim()) !== -1)
                                .map((d, i) => (
                                    <div id={d.id} key={d.id}>
                                        <h3 className={i === 0 ? 'pt-0' : ''}>{d.name}</h3>
                                        <iframe
                                            width="1280"
                                            height="800"
                                            src={`https://www.youtube.com/embed/${d.src}`}
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        ></iframe>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tutorials;
