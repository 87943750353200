import React from 'react';
import dateFormat from 'dateformat';

import '../../Assets/Templates/MFTemplate.css';
import {
    getPathFromCategory,
    getTitleFromCategory,
    getFlag,
    getLabels,
    padNum,
    getQuantityText,
    getContainer,
    getKiloPrice,
    getPiecePrice
} from './MainTemplate';

const MFTemplate = (props) => (
    <div key={props._id} className={`MFTemplate ${props.orientation} ${props.rayon} ${props.templateName}`}>
        <div className="image">
            <img src={`${getPathFromCategory(props.rayon, props.filename)}`} alt={props.filename} />
        </div>
        <div className="content">
            <div className="price">
                <div className="actualPrice">
                    <span className="int">{props.price.toString().split(/[.,]/)[0]}</span>
                    <span className="dec">,{props.price ? padNum(props.price.toString().split(/[.,]/)[1]) : ''}</span>
                    <span className="eur">&euro;</span>
                </div>
                <div className="quantity">
                    <p className="qContainer">
                        {getContainer(props)} {getQuantityText(props)}
                    </p>
                    <div className="or">
                        {props.priceUnit !== 'number' || <p>Soit: {getKiloPrice(props.weight, props.price)}€ le Kg</p>}
                        {props.priceUnit !== 'piece' || !props.pieceNb || props.pieceNb <= 1 || (
                            <p>Soit: {getPiecePrice(props.pieceNb, props.price)}€ pièce</p>
                        )}
                        {!props.expirationDate || (
                            <div className="expirationDate">
                                Jusqu'au {dateFormat(props.expirationDate, 'dddd d mmmm yyyy')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="labels">
                <div>
                    {getLabels(props).map((l) => (
                        <img src={`/labels/${l}`} alt={l} className="u-label" key={l} />
                    ))}
                </div>
            </div>
            <div className="data">
                <h1>{getTitleFromCategory(props.rayon, props)}</h1>
                <div className="origin">
                    {getFlag(props.originCountry, props.displayFlag)}
                    <p className="originLabel">
                        {!props.originCountry || 'Origine'} {props.originCountry}
                    </p>
                </div>
                <div className="infos">
                    {!props.category || !props.fruitName || <p>CAT {props.category}</p>}
                    {!props.caliber || <p>Calibre: {props.caliber}</p>}
                </div>
            </div>
        </div>
        {!props.promotion || <div className="promo"></div>}
    </div>
);

export default MFTemplate;
