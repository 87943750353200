import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { fetchApi } from '../Services/NetworkServcice';
import { formats } from '../Constants';
import { Link } from 'react-router-dom';
import HeaderNotifications from '../Components/HeaderNotifications';
import swal from 'sweetalert';

const getActiveSequences = (sequences = []) => {
    let count = 0;

    for (const s of sequences) {
        if (!s.virtualDevices) {
            s.virtualDevices = [];
        }

        if ((s.devices.length > 0 || s.virtualDevices.length > 0) && !s.startDate) {
            count++;
        }
    }

    return count;
};

const getFutureSequences = (sequences = []) => {
    let count = 0;

    for (const s of sequences) {
        if (s.devices.length > 0 && !!s.startDate) {
            count++;
        }
    }

    return count;
};

const getOrphanedScreens = (sequences = [], screens = []) => {
    const screensSet = new Set(screens);

    for (const s of sequences) {
        for (const d of s.devices) {
            if (screensSet.has(d)) {
                screensSet.delete(d);
            }
        }
    }

    return screensSet.size;
};

export const getDeviceLength = (sequence) => {
    let len = 0;

    len += sequence.devices.length;

    if (sequence.virtualDevices) {
        len += sequence.virtualDevices.length;
    }

    return len;
};

const ManagedSequenceCard = (props) => {
    return (
        <div className="card seq">
            <div className="content">
                <h3>Séquence automatique : {props.name}</h3>
                <p>
                    <i className="fa fa-images"></i> Format : {formats[props.orientation]?.name} -{' '}
                    {props.creatives.length} affiche{props.creatives.length <= 1 ? '' : 's'}
                </p>
                <div className="labels">
                    <span style={{ backgroundColor: '#e70000' }}>Nouveau</span>
                    <span style={{ backgroundColor: '#13aafb' }}>Séquence automatique</span>
                </div>
                <p>
                    {!props.screens.length || <i className="fa fa-tv"></i>}
                    {props.screens.length === 0 || (
                        <React.Fragment>
                            {' '}
                            {props.screens.length} Ecran{props.screens.length <= 1 ? '' : 's'} :{' '}
                            <strong>{props.screens[0].name}</strong>
                        </React.Fragment>
                    )}
                    {props.screens.slice(1, 4).map((s) => (
                        <strong key={s.id}>, {s.name}</strong>
                    ))}
                    {props.screens.length < 4 || ', ...'}
                </p>
                <p>
                    <i className="fa fa-info-circle"></i> Vous avez la possibilité d'activer cette séquence automatique
                    sur vos écrans. Son contenu se mélangera à vos autres séquences. Cette séquence est automatiquement
                    gérée par DoohYouLike afin de mettre en valeur votre magasin. Vous pouvez à tout moment désactiver
                    cette séquence de vos écrans. Appuyez sur le bouton "Voir" pour en voir le contenu et assigner vos
                    écrans.
                </p>
            </div>
            <div className="btn-container">
                <Link to={`/managed-sequences/${props._id}`} className="btn-large btn-large-full">
                    <i className="fa fa-eye"></i> Voir
                </Link>
            </div>
        </div>
    );
};

const SequenceCard = (props) => {
    const isFuture = !!props.startDate;
    const [state, setState] = useState({
        tooltipVisible: false
    });

    const devices = [...props.screens, ...props.virtual_devices];

    return (
        <div className="card seq">
            <div className="content">
                <h3>Séquence : {props.name}</h3>
                <p>
                    <i className="fa fa-images"></i> Format : {formats[props.orientation]?.name} -{' '}
                    {props.creatives.length} affiche{props.creatives.length <= 1 ? '' : 's'}
                </p>
                <div className="labels">
                    {!!isFuture || getDeviceLength(props) === 0 || <span>Séquence active</span>}
                    {!isFuture || <span className="future">Séquence prog.</span>}
                    {!isFuture || (
                        <span className="future">
                            <i className="fa fa-clock"></i> Dém. {dateFormat(props.startDate, 'dd/mm/yy')}
                        </span>
                    )}
                    {getDeviceLength(props) !== 0 || !!isFuture || <span className="draft">Brouillon</span>}
                </div>
                <p>
                    {!devices.length || <i className="fa fa-tv"></i>}
                    {devices.length === 0 || (
                        <React.Fragment>
                            {' '}
                            {devices.length} Ecran{devices.length <= 1 ? '' : 's'} :{' '}
                            <strong>{devices[0]?.name ?? 'No name'}</strong>
                        </React.Fragment>
                    )}
                    {devices.slice(1, 4).map((s) => (
                        <strong key={s?.id}>, {s?.name}</strong>
                    ))}
                    {devices.length < 4 || ', ...'}
                </p>
            </div>
            <div className="btn-container">
                <Link to={`/sequences/${props._id}`} className="btn-large">
                    <i className="fa fa-pen"></i> Éditer
                </Link>
                <button
                    className="btn-large-arrow"
                    onClick={() =>
                        setState({
                            ...state,
                            tooltipVisible: !state.tooltipVisible
                        })
                    }
                >
                    <i className="fa fa-chevron-down"></i>
                </button>
                <div className={`tooltip-menu${state.tooltipVisible ? ' active' : ''}`}>
                    <Link to={`/sequences/${props._id}?action=rename`}>Renommer</Link>
                    {props.name === 'Défaut' || <span onClick={() => props.delete(props._id)}>Supprimer</span>}
                </div>
            </div>
        </div>
    );
};

const NumberSummary = ({ number, text, color }) => (
    <div className="number-summary" style={{ color: color }}>
        <div className="num">{number}</div>
        <div className="txt">{text}</div>
    </div>
);

/**
 * @param {{ availableOrientations: number[], onCreate: (name: string, orientation: string) => any }} param0
 */
const NewSequence = ({ availableOrientations, onCreate }) => {
    const [state, setState] = useState({
        name: '',
        selectedOrientation: 'portrait'
    });

    useEffect(() => {
        setState((prevState) => ({ ...prevState, selectedOrientation: availableOrientations[0] || 'portrait' }));
    }, [availableOrientations]);

    return (
        <div className="card">
            <h3>Nouvelle séquence</h3>
            <div className="form-group">
                <label>Titre:</label>
                <input
                    value={state.name}
                    onChange={(e) => setState({ ...state, name: e.target.value })}
                    type="text"
                    className="form-control"
                />
            </div>
            {availableOrientations.length <= 1 || (
                <div className="form-group">
                    <label>Orientation:</label>
                    <select
                        className="form-control"
                        onChange={(e) => setState({ ...state, selectedOrientation: e.target.value })}
                        value={state.selectedOrientation}
                    >
                        {availableOrientations.map((o) => (
                            <option key={o} value={o}>
                                {formats[o].name}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <button
                className={`btn-dyl-seq ${state.name.trim().length > 2 ? '' : 'disabled'}`}
                onClick={() => onCreate(state.name, state.selectedOrientation)}
            >
                <i className="fa fa-plus"></i> Créer la séquence
            </button>
        </div>
    );
};

const UserActions = ({ user }) => {
    const [state, setState] = useState({
        contractClosed: false,
        formationClosed: false
    });

    if (!user) {
        return null;
    }

    if (user.role.Agent.id !== 1) {
        return null;
    }

    if (user.role.id !== 3) {
        return null;
    }

    return (
        <div style={{ marginTop: 24 }}>
            <div className="alert alert-warning" style={{ display: state.contractClosed ? 'none' : 'block' }}>
                <h4>Contrat non signé</h4>
                <p>
                    Votre contrat n'a pas été signé. Afin de pouvoir profiter pleinement de la plateforme, vous devez
                    signer le contrat à l'aide du bouton ci dessous. La signature ne prend que quelques instants.
                </p>
                <p>
                    <button className="btn btn-default btn-rounded">Signer</button>
                </p>
                <button
                    className="btn-close"
                    onClick={() => setState((prevState) => ({ ...prevState, contractClosed: true }))}
                ></button>
            </div>
            <div className="alert alert-info" style={{ display: state.formationClosed ? 'none' : 'block' }}>
                <h4>Prendre un RDV pour votre formation</h4>
                <p>
                    Vous pouvez prendre un RDV afin de vous former à notre plateforme si vous le souhaitez. La formation
                    dure entre 15 et 30 minutes. Vous pouvez également prendre une formation à n'importe quel moment à
                    l'aide du menu "RDV Formation" dans la barre de menus.
                </p>
                <p>
                    <Link to="/formation" className="btn btn-default btn-rounded">
                        Prendre RDV
                    </Link>
                    <button
                        className="ml-10 btn btn-default btn-rounded"
                        onClick={() => setState((prevState) => ({ ...prevState, formationClosed: true }))}
                    >
                        Ne plus afficher
                    </button>
                </p>
                <button
                    className="btn-close"
                    onClick={() => setState((prevState) => ({ ...prevState, formationClosed: true }))}
                ></button>
            </div>
        </div>
    );
};

export const SequenceHomeList = ({
    sequences,
    managedSequences,
    screens,
    virtual_devices,
    onDelete,
    notifsData,
    history,
    availableOrientations,
    title = 'Accueil',
    showScreensCard = true,
    user
}) => {
    const [state, setState] = useState({ modalNewSequenceOpened: false });

    return (
        <div className="dyl-home home">
            <header>
                <Link to="/" className="logo">
                    <img src="/image.png" alt="logo" />
                </Link>
                <p className="hidden-xs">{title}</p>
                <h3>{dateFormat(new Date(), 'dddd dd mmmm yyyy')}</h3>
                {!notifsData || <HeaderNotifications {...notifsData} />}
                <UserActions user={user} />
            </header>
            <div className="content">
                <div className="mobile-controls">
                    <div className="btn-add" onClick={() => setState({ ...state, modalNewSequenceOpened: true })}>
                        <i className="fa fa-plus"></i>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="summary">
                        <div className="card">
                            <h3>Résumé</h3>
                            <div className="summary">
                                <NumberSummary
                                    number={getActiveSequences(sequences)}
                                    text={`Séquence${getActiveSequences(sequences) > 1 ? 's' : ''} active${
                                        getActiveSequences(sequences) > 1 ? 's' : ''
                                    }`}
                                    color="#52cc52"
                                />
                                <NumberSummary
                                    number={getFutureSequences(sequences)}
                                    text={`Séquence${getFutureSequences(sequences) > 1 ? 's' : ''} programmée${
                                        getFutureSequences(sequences) > 1 ? 's' : ''
                                    }`}
                                    color="#FF9900"
                                />
                                {!showScreensCard || (
                                    <NumberSummary
                                        number={getOrphanedScreens(
                                            sequences,
                                            screens.map(({ id }) => id)
                                        )}
                                        text={`Ecran${
                                            getOrphanedScreens(
                                                sequences,
                                                screens.map(({ id }) => id)
                                            ) > 1
                                                ? 's'
                                                : ''
                                        } sans séq.`}
                                        color="#777f8c"
                                    />
                                )}
                                {!showScreensCard || (
                                    <NumberSummary
                                        number={screens.filter(({ online }) => !online).length}
                                        text={`Ecran${
                                            screens.filter(({ online }) => !online).length > 1 ? 's' : ''
                                        } déconnecté${screens.filter(({ online }) => !online).length > 1 ? 's' : ''}`}
                                        color="#f8485e"
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={`new-sequence ${state.modalNewSequenceOpened}`}
                            onClick={(e) => {
                                if (e.currentTarget === e.target) {
                                    setState({
                                        ...state,
                                        modalNewSequenceOpened: false
                                    });
                                }
                            }}
                        >
                            <NewSequence
                                availableOrientations={availableOrientations}
                                onCreate={(name, orientation) => history.push(`/sequences/new/${name}/${orientation}`)}
                            />
                        </div>
                    </div>
                    <div className="sequences">
                        {user?.role.id !== 3 || managedSequences.map((s) => (
                            <ManagedSequenceCard
                                key={s._id}
                                {...s}
                                screens={s.devices.map((s) => screens.find(({ id }) => id === s))}
                            />
                        ))}
                        {sequences
                            .sort((a, b) => {
                                const aLen = a.devices.length + a.virtualDevices.length;
                                const bLen = b.devices.length + b.virtualDevices.length;

                                if (aLen > bLen) {
                                    return -1;
                                } else if (aLen < bLen) {
                                    return 1;
                                } else if (!!a.startDate && !b.startDate) {
                                    return 1;
                                } else if (!a.startDate && !!b.startDate) {
                                    return -1;
                                } else {
                                    return a.name.localeCompare(b.name);
                                }
                            })
                            .map((s) => (
                                <SequenceCard
                                    delete={(s) => onDelete(s)}
                                    key={s._id}
                                    {...s}
                                    screens={s.devices.map((s) => screens.find(({ id }) => id === s))}
                                    virtual_devices={s.virtualDevices.map((s) => virtual_devices.find(({ id }) => id === s))}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Home = (props) => {
    const [state, setState] = useState({
        selectedTab: 0,
        sequences: [],
        managedSequences: [],
        screens: [],
        virtual_devices: [],
        availableOrientations: ['portrait'],
        modalNewSequenceOpened: false
    });

    const getData = () =>
        fetchApi('/sequences/home/v2')
            .then((res) => res.json())
            .then(({ sequences, screens, managedSequences, virtual_screens }) => {
                const availableOrientations = [...new Set(screens.map(({ orientation }) => orientation))];
                const now = new Date();

                setState((prevState) => ({
                    ...prevState,
                    sequences,
                    managedSequences,
                    screens: screens.map((s) => ({ ...s, online: (now - new Date(s.lastUpdate)) / 5000 <= 30 })),
                    virtual_devices: virtual_screens,
                    availableOrientations
                }));
            });

    useEffect(() => {
        getData();
    }, []);

    return (
        <SequenceHomeList
            title="Accueil"
            user={props.userObject}
            notifsData={props.notifsData}
            onDelete={(id) => {
                swal({
                    title: 'Supprimer cette séquence ?',
                    text: 'Êtes-vous sûr ?',
                    icon: 'warning',
                    buttons: ['Annuler', 'OK'],
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete) {
                        fetchApi(`/sequences/${id}`, {
                            method: 'DELETE'
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    swal({ text: 'Séquence supprimée', icon: 'success' });
                                    setState({
                                        ...state,
                                        sequences: state.sequences.filter(({ _id }) => _id !== id)
                                    });
                                } else {
                                    swal({ text: 'Impossible de supprimer', icon: 'danger' });
                                }
                            })
                            .catch(() => {
                                swal({ text: 'Impossible de supprimer', icon: 'danger' });
                            });
                    }
                });
            }}
            availableOrientations={state.availableOrientations}
            history={props.history}
            screens={state.screens}
            virtual_devices={state.virtual_devices}
            sequences={state.sequences}
            managedSequences={state.managedSequences}
        />
    );
};

export default Home;
