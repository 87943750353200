import decode from 'jwt-decode';
import constants from '../../Constants';

export default class AuthService {
    constructor() {
        this.domain = constants.endpoint;
        this.fetch = this.fetch.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    loggedIn() {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        localStorage.setItem('id_token', idToken);
    }

    tempSession(token, oldRole) {
        localStorage.setItem('old_token', this.getToken());
        localStorage.setItem('old_role', oldRole);
        localStorage.setItem('id_token', token);
    }

    restoreSession() {
        localStorage.setItem('id_token', localStorage.getItem('old_token'));
        localStorage.removeItem('old_token');
    }

    hasTempSession() {
        const oldToken = localStorage.getItem('old_token');
        return !!oldToken && !this.isTokenExpired(oldToken);
    }

    getToken() {
        return localStorage.getItem('id_token');
    }

    getTempToken() {
        return localStorage.getItem('old_token');
    }

    logout() {
        localStorage.removeItem('old_token');
        localStorage.removeItem('old_role');
        localStorage.removeItem('filter');
        localStorage.removeItem('id_token');
    }

    getProfile() {
        return decode(this.getToken());
    }

    fetch(url, options) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };

        if (this.loggedIn()) {
            headers['x-access-token'] = this.getToken();
        }

        if (
            this.hasTempSession() &&
            (localStorage.getItem('old_role') === 'admin' ||
                localStorage.getItem('old_role') === 'commercial')
        ) {
            let newUrl = url;
            if (newUrl.indexOf('?') !== -1) {
                newUrl += '&noStats=true';
            } else {
                newUrl += '?noStats=true';
            }

            return fetch(newUrl, { headers, ...options }).then(response =>
                response.json()
            );
        }

        return fetch(url, {
            headers,
            ...options
        }).then(response => response.json());
    }

    fetchMultipart(url, options) {
        const headers = {};

        if (this.loggedIn()) {
            headers['x-access-token'] = this.getToken();
        }

        return fetch(url, {
            headers,
            ...options
        }).then(response => response.json());
    }

    fetchText(url, options) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };

        if (this.loggedIn()) {
            headers['x-access-token'] = this.getToken();
        }

        return fetch(url, {
            headers,
            ...options
        }).then(response => response);
    }
}
