import React from 'react';

import { Row, Col } from 'react-bootstrap';
import PreviewSlide from './PreviewFruit';
import constants, { countries } from '../Constants';
import { notify } from 'react-notify-toast';
import Switch from 'react-switch';
import Calendar from 'react-calendar';
import Select from 'react-select';
import { fetchApi } from '../Services/NetworkServcice';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import '../Assets/editor.css';

const getKeyFromValue = (obj, value) => {
    for (const k of Object.keys(obj)) {
        if (obj[k].indexOf(value) !== -1) {
            return k;
        }
    }

    return '';
};

const priceRe = new RegExp(/^[0-9]+([.,][0-9]{1,2})?$/);

const FruitsSelectionPage = (props) => {
    const fruitKey = getKeyFromValue(props.fruits, props.filename);
    const nbFruits = fruitKey ? props.fruits[fruitKey].length : 0;
    const selected = fruitKey ? props.fruits[fruitKey].indexOf(props.filename) : -1;

    return (
        <div>
            <header>
                <div className="progress-assistant">
                    <div className="item active">
                        <div className="round">1</div>
                        <span>Choisissez un produit</span>
                    </div>
                    <div className="item">
                        <div className="round">2</div>
                    </div>
                    <div className="item">
                        <div className="round">3</div>
                    </div>
                    <div className="item">
                        <div className="round">4</div>
                    </div>
                </div>
                <div className="buttons">
                    <button className="button-assistant" disabled={!props.filename} onClick={props.handleStep}>
                        Suivant
                    </button>
                </div>
            </header>
            <div className="BodyEditorSelectFruit">
                <Row>
                    <Col md={9} style={{ marginBottom: 18 }}>
                        {!!props._id || (
                            <div className="form-group">
                                <label>Sélectionnez un fruit ou légume:</label>
                                <Select
                                    options={Object.keys(props.fruits)
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((f) => ({
                                            label: f,
                                            value: f
                                        }))}
                                    onChange={(e) => props.handleSelectFruit(e.value)}
                                    value={{
                                        label: fruitKey,
                                        value: fruitKey
                                    }}
                                />
                            </div>
                        )}
                        {!fruitKey || (
                            <div
                                className="controls"
                                style={{
                                    display: nbFruits > 1 ? 'block' : 'none'
                                }}
                            >
                                <p>
                                    Sélectionnez une image <i className="fa fa-angle-double-right ip5" />
                                </p>
                                <div>
                                    {props.fruits[fruitKey].map((f, i) => (
                                        <img
                                            className={i === selected ? 'fruitSelection active' : 'fruitSelection'}
                                            key={f}
                                            src={`${constants.cloud_storage}/Assets/Library/Fruits/${f}`}
                                            onClick={() => props.handleSwitchFruit(i, fruitKey)}
                                            height="100"
                                            alt="fruit"
                                        />
                                    ))}
                                </div>
                                <hr className="visible-xs" style={{ marginBottom: '-10px' }} />
                            </div>
                        )}
                    </Col>
                    <Col md={3}>
                        <p className="center">Prévisualisation</p>
                        <PreviewSlide {...props} />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const FruitData = (props) => (
    <div>
        <header>
            <div className="progress-assistant">
                <div className="item active">
                    <div className="round" onClick={() => props.setStep(0)}>
                        1
                    </div>
                </div>
                <div className="item active">
                    <div className="round">2</div>
                    <span>Renseignez plus d’informations</span>
                </div>
                <div className="item">
                    <div className="round">3</div>
                </div>
                <div className="item">
                    <div className="round">4</div>
                </div>
            </div>
            <div className="buttons">
                <button className="prev" onClick={props.handleBackStep}>
                    Retour
                </button>
                <button
                    className="button-assistant"
                    disabled={props.originCountry.length === 0}
                    onClick={props.handleStep}
                >
                    Suivant
                </button>
            </div>
        </header>
        <div>
            <Row>
                <Col md={9}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="fruitName">Titre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="fruitName"
                                    value={props.fruitName}
                                    onChange={props.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="subtitle">Sous-Titre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={props.subtitle}
                                    onChange={props.handleChange}
                                    name="subtitle"
                                />
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div className="form-group">
                                <label htmlFor="originCountry">Pays d'origine (obligatoire)</label>
                                <Select
                                    options={[
                                        ...[
                                            {
                                                value: 'France',
                                                label: 'France'
                                            },
                                            {
                                                value: 'Espagne',
                                                label: 'Espagne'
                                            },
                                            {
                                                value: 'U.E.',
                                                label: 'U.E.'
                                            },
                                            {
                                                value: '---------------------',
                                                label: '---------------------',
                                                disabled: true
                                            }
                                        ],
                                        ...countries
                                    ]}
                                    name="originCountry"
                                    className={props.originCountry ? '' : 'rSelectError'}
                                    onChange={(e) =>
                                        props.handleChange({
                                            target: {
                                                name: 'originCountry',
                                                value: e.value
                                            }
                                        })
                                    }
                                    isOptionDisabled={(o) => o.disabled === true}
                                    value={{
                                        label: props.originCountry,
                                        value: props.originCountry
                                    }}
                                />
                                {!!props.originCountry || <span className="errorLabel">Ce champs est requis</span>}
                            </div>
                        </Col>
                        {(props.originCountry !== 'France' && props.originCountry !== 'Espagne') || (
                            <Col md={6}>
                                <div className="form-group flegCheck">
                                    <label>Afficher le drapeau</label>
                                    <input
                                        type="checkbox"
                                        name="displayFlag"
                                        onChange={(e) => props.handleSetFlag(e.target.checked)}
                                        checked={props.displayFlag}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="category">Catégorie</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="category"
                                    value={props.category}
                                    onChange={props.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="variety">Variété</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="variety"
                                    value={props.variety}
                                    onChange={props.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="caliber">Calibre</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="caliber"
                                    value={props.caliber}
                                    onChange={props.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={3}>
                    <p className="center">Prévisualisation</p>
                    <PreviewSlide {...props} />
                </Col>
            </Row>
        </div>
    </div>
);

const FruitPrice = (props) => (
    <div>
        <header>
            <div className="progress-assistant">
                <div className="item active">
                    <div className="round" onClick={() => props.setStep(0)}>
                        1
                    </div>
                </div>
                <div className="item active">
                    <div className="round" onClick={() => props.setStep(1)}>
                        2
                    </div>
                </div>
                <div className="item active">
                    <div className="round">3</div>
                    <span>Prix et programmation</span>
                </div>
                <div className="item">
                    <div className="round">4</div>
                </div>
            </div>
            <div className="buttons">
                <button className="prev" onClick={props.handleBackStep}>
                    Retour
                </button>
                <button
                    className="button-assistant"
                    disabled={props.priceUnit.length === 0 || props.price.length === 0 || !priceRe.test(props.price)}
                    onClick={props.handleStep}
                >
                    Suivant
                </button>
            </div>
        </header>
        <div className="mobPad">
            <Row>
                <Col md={9}>
                    <div className="groupBox">
                        <Row>
                            <Col md={5} xs={12}>
                                <div className="form-group">
                                    <label>Prix</label>
                                    <div className="input-group" style={{ maxWidth: '175px' }}>
                                        <input
                                            name="price"
                                            type="text"
                                            className={
                                                'price form-control' + (props.price.length === 0 ? ' error' : '')
                                            }
                                            value={props.price}
                                            onChange={props.handleChange}
                                        />
                                        <span className="input-group-addon">Euros</span>
                                    </div>
                                    {props.price.length > 0 || (
                                        <span className="errorLabel" style={{ width: '175px' }}>
                                            Ce champs est requis
                                        </span>
                                    )}
                                    {props.price.length === 0 || priceRe.test(props.price) || (
                                        <span className="errorLabel" style={{ width: '175px' }}>
                                            Champs est invalide (exemple: 1,99)
                                        </span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pricenumber">
                                <select
                                    name="priceUnit"
                                    onChange={(e) => {
                                        if (e.target.value.indexOf(':') !== -1) {
                                            props.handleChange({
                                                target: { name: e.target.name, value: e.target.value.split(':')[0] }
                                            });
                                            props.handleChange({
                                                target: { name: 'priceSupport', value: e.target.value.split(':')[1] }
                                            });
                                        } else {
                                            props.handleChange(e);
                                        }
                                    }}
                                    value={(() => {
                                        if (props.priceUnit === 'number' || props.priceUnit === 'text') {
                                            return `${props.priceUnit}:${props.priceSupport}`;
                                        } else {
                                            return props.priceUnit;
                                        }
                                    })()}
                                    className="form-control supportSelect priceunit"
                                >
                                    <option value="kilo">Le kilo</option>
                                    <option value="piece">Pièce(s)</option>
                                    <option value="lot">Lot(s)</option>
                                    <option value="number:weight">Poids</option>
                                    <option value="number:barquette">Barquette</option>
                                    <option value="number:cagette">Cagette</option>
                                    <option value="number:sachet">Sachet</option>
                                    <option value="number:filet">Filet</option>
                                    <option value="text:Le panier">Le panier</option>
                                    <option value="text:La barquette">La barquette</option>
                                    <option value="text:Le lot">Le lot</option>
                                    <option value="text:La part">La part</option>
                                    <option value="text:La botte">La botte</option>
                                </select>
                                <Col
                                    xs={7}
                                    style={{
                                        display: props.priceUnit !== 'kilo' ? 'none' : 'initial'
                                    }}
                                >
                                    <div className="form-group">
                                        <input
                                            type="radio"
                                            name="priceUnit"
                                            checked={props.priceUnit === 'kilo'}
                                            value="kilo"
                                            onChange={props.handleChange}
                                            className="hidinput"
                                        />
                                    </div>
                                </Col>

                                <div
                                    xs={10}
                                    style={{
                                        display: !['piece', 'lot'].includes(props.priceUnit) ? 'none' : 'initial'
                                    }}
                                >
                                    <div className="input-group">
                                        <select
                                            style={{ minWidth: 64 }}
                                            value={props.pieceNb || 1}
                                            name="pieceNb"
                                            onChange={props.handleChange}
                                            className="form-control"
                                        >
                                            {Array.apply(null, Array(15)).map((_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="input-group-addon">Pièce{props.pieceNb > 1 && 's'}</span>
                                    </div>
                                </div>
                                <div
                                    xs={10}
                                    style={{
                                        display: props.priceUnit !== 'number' ? 'none' : 'initial'
                                    }}
                                >
                                    <div className="input-group" style={{ paddingRight: 4 }}>
                                        <input
                                            type="number"
                                            name="weight"
                                            className="form-control weight"
                                            value={props.weight}
                                            onChange={props.handleChange}
                                        />
                                        <span className="input-group-addon">Grammes</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {!props.promotion || props.orientation !== 'portrait' || props.templateName.length !== 0 || (
                            <Row>
                                <Col md={5} xs={12}>
                                    <div className="form-group">
                                        <label>Prix original (si promotion, optionel)</label>
                                        <div className="input-group" style={{ maxWidth: '175px' }}>
                                            <input
                                                name="origPrice"
                                                type="text"
                                                className={
                                                    'origPrice form-control' +
                                                    (props.origPrice.length === 0 ? ' error' : '')
                                                }
                                                value={props.origPrice}
                                                onChange={props.handleChange}
                                            />
                                            <span className="input-group-addon">Euros</span>
                                        </div>
                                        {props.origPrice.length === 0 || priceRe.test(props.origPrice) || (
                                            <span className="errorLabel" style={{ width: '175px' }}>
                                                Champs est invalide (exemple: 1,99)
                                            </span>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div className="groupBox">
                        <Row>
                            <Col xs={12}>
                                <Switch
                                    className="switch-class switchPromo"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    checked={props.promotion}
                                    onChange={(e) => {
                                        props.handleChange({
                                            target: {
                                                name: 'promotion',
                                                value: e
                                            }
                                        });

                                        const d = new Date();
                                        d.setHours(23, 59, 59);

                                        props.handleChange({
                                            target: {
                                                name: 'expirationDate',
                                                value: d
                                            }
                                        });
                                    }}
                                />
                                <span>
                                    Est-ce une{' '}
                                    <label htmlFor="promo-input" className="promo-label">
                                        Promotion
                                    </label>{' '}
                                    ?
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Switch
                                    className="switch-class switchPromo"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    checked={props.expirationDate !== null}
                                    onChange={(e) => {
                                        const d = new Date();
                                        d.setHours(23, 59, 59, 0);
                                        props.handleChange({
                                            target: {
                                                name: 'expirationDate',
                                                value: e ? d : props.promotion ? d : null
                                            }
                                        });
                                    }}
                                />
                                <label htmlFor="promo-cal" className="promo-cal">
                                    Date d'expiration
                                </label>
                                <p className="indications mt-8">
                                    Choisissez une date et une heure d’expiration de cette affiche dans la séquence :
                                    (l’affiche s’effacera automatiquement après cette date)
                                </p>
                                <div
                                    style={{
                                        display: props.expirationDate === null ? 'none' : 'initial'
                                    }}
                                >
                                    <Calendar
                                        className="fruit-expiration"
                                        onChange={(date) => {
                                            date.setHours(23, 59, 59, 0);

                                            props.handleChange({
                                                target: {
                                                    name: 'expirationDate',
                                                    value: new Date(date)
                                                }
                                            });
                                        }}
                                        value={new Date(props.expirationDate)}
                                    />
                                    {/* <p>à</p>
                                    <TimePicker
                                        value={moment(props.expirationDate)}
                                        onChange={m =>
                                            props.handleChange({
                                                target: {
                                                    name: 'expirationDate',
                                                    value: m.toDate()
                                                }
                                            })
                                        }
                                    /> */}
                                </div>
                                {!props.allowSlidesDurationChange || (
                                    <div>
                                        <p>Durée d'affichage: {props.duration}s</p>
                                        <input
                                            onChange={props.onChangeDuration}
                                            type="range"
                                            min="1"
                                            max={props.maxDuration}
                                            value={props.duration}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col md={6}>
                                <Switch
                                    className="switch-class switchPromo"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    checked={props.startDate !== null}
                                    onChange={(e) => {
                                        const d = new Date();
                                        d.setHours(0, 0, 0, 0);
                                        props.handleChange({
                                            target: {
                                                name: 'startDate',
                                                value: e ? d : null
                                            }
                                        });
                                    }}
                                />
                                <label htmlFor="promo-cal" className="promo-cal" style={{ display: 'initial' }}>
                                    Différer le démarrage
                                </label>
                                <p className="indications mt-8">
                                    Choisissez une date et une heure pour un démarrage différé de cette affiche dans la
                                    séquence :
                                </p>
                                <div
                                    style={{
                                        display: props.startDate === null ? 'none' : 'initial'
                                    }}
                                >
                                    <Calendar
                                        className="fruit-expiration"
                                        onChange={(date) => {
                                            date.setHours(23, 59, 59, 0);
                                            props.handleChange({
                                                target: {
                                                    name: 'startDate',
                                                    value: new Date(date)
                                                }
                                            });
                                        }}
                                        value={new Date(props.startDate)}
                                    />
                                    <p>à</p>
                                    <TimePicker
                                        value={moment(props.startDate)}
                                        showSecond={false}
                                        onChange={(m) =>
                                            props.handleChange({
                                                target: {
                                                    name: 'startDate',
                                                    value: m.toDate()
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={3}>
                    <p className="center">Prévisualisation</p>
                    <PreviewSlide {...props} />
                </Col>
            </Row>
        </div>
    </div>
);

const FruitLabel = (props) => (
    <div>
        <header className="fourth">
            <div className="progress-assistant">
                <div className="item active">
                    <div className="round" onClick={() => props.setStep(0)}>
                        1
                    </div>
                </div>
                <div className="item active">
                    <div className="round" onClick={() => props.setStep(1)}>
                        2
                    </div>
                </div>
                <div className="item active">
                    <div className="round" onClick={() => props.setStep(2)}>
                        3
                    </div>
                </div>
                <div className="item active">
                    <div className="round">4</div>
                    <span>Labels</span>
                </div>
            </div>
            <div className="buttons">
                <button className="prev" onClick={props.handleBackStep}>
                    Retour
                </button>
                <button className="button-assistant" onClick={props.handleSave}>
                    Enregistrer
                </button>
            </div>
        </header>

        <div>
            <Row>
                <Col md={9}>
                    <Row>
                        <div className="LabelsHorizontalScroll">
                            <Col
                                lg={3}
                                md={3}
                                xs={4}
                                onClick={() => props.handleSetLabels([])}
                                className={'labelCol' + (props.labels.length === 0 ? ' active' : '')}
                            >
                                <div className="noLabel">
                                    <i className="fa fa-ban"></i>
                                    <span>Aucun</span>
                                </div>
                            </Col>
                            {props.availableLabels.map((l) => (
                                <Col
                                    lg={2}
                                    md={3}
                                    xs={4}
                                    key={l}
                                    onClick={() => {
                                        if (props.labels.indexOf(l) === -1) {
                                            props.handleSetLabels([...props.labels, l].slice(0, 2));
                                        } else {
                                            props.handleSetLabels(props.labels.filter((lb) => lb !== l));
                                        }
                                    }}
                                    className={'labelCol' + (props.labels.indexOf(l) !== -1 ? ' active' : '')}
                                >
                                    <img className="labelImg" src={`/labels/${l}`} alt={l} />
                                </Col>
                            ))}
                        </div>
                    </Row>
                </Col>
                <Col
                    md={3}
                    style={{
                        paddingBottom: '10px'
                    }}
                >
                    <p className="center">Prévisualisation</p>
                    <PreviewSlide {...props} />
                </Col>
            </Row>
        </div>
    </div>
);

class FruitsAssistant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Dynamic
            fruits: [],
            suggestions: [],
            step: 0,

            // Slide
            duration: 5,

            // Fruit
            _id: null,
            filename: '',
            fruitName: '',
            subtitle: '',
            originCountry: '',
            originRegion: '',
            category: '',
            variety: '',
            caliber: '',
            price: '',
            pieceNb: 1,
            priceUnit: 'kilo',
            priceSupport: '',
            promotion: false,
            expirationDate: null,
            startDate: null,
            weight: '300',
            labels: [],
            newTemplate: props.newTemplate || false,
            displayFlag: true,
            templateName: props.templateName,
            origPrice: ''
        };

        this.labels = ['ab.jpg', 'labelrouge.png', 'flf.png', 'ploc.png', 'upc.png', 'selprim.png'];

        if (props.signId === 4 || props.signId === 11) {
            this.labels.push('refletdefrance.png');
            this.labels.push('aff.png');
        }

        if (props.signId === 1 || props.signId === 37) {
            this.labels.push('pci.png');
        }

        if (props.shopId === 604) {
            this.labels.push('flar.png');
            this.labels.push('flav.png');
        }

        this.handleStep = this.handleStep.bind(this);
        this.handleBackStep = this.handleBackStep.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectFruit = this.handleSelectFruit.bind(this);
        this.handleSaveCrea = this.handleSaveCrea.bind(this);
        this.reset = this.reset.bind(this);
        this.setSlide = this.setSlide.bind(this);
    }

    handleSaveCrea() {
        const crea = {
            filename: this.state.filename,
            fruitName: this.state.fruitName,
            subtitle: this.state.subtitle,
            originCountry: this.state.originCountry,
            originRegion: this.state.originRegion,
            category: this.state.category,
            variety: this.state.variety,
            caliber: this.state.caliber,
            price: this.state.price,
            priceUnit: this.state.priceUnit,
            pieceNb: this.state.pieceNb,
            displayFlag: this.state.displayFlag || false,
            newTemplate: this.state.newTemplate || false,
            priceSupport: this.state.priceSupport,
            promotion: this.state.promotion,
            expirationDate: this.state.expirationDate,
            startDate: this.state.startDate,
            weight: this.state.weight,
            labels: this.state.labels,
            templateName: this.state.templateName,
            origPrice: this.state.origPrice,
            _id: this.state._id
        };

        this.props.handleSaveCrea({
            creative: crea,
            creaType: 'Fruit',
            expirationDate: this.state.expirationDate,
            startDate: this.state.startDate,
            duration: this.state.duration
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleStep() {
        this.setState({
            step: this.state.step + 1
        });
    }

    handleBackStep() {
        this.setState({
            step: this.state.step - 1
        });
    }

    handleSave() {
        this.props.handleSave(this.state);
    }

    handleClose() {
        this.reset();
        this.props.handleClose();
    }

    handleSelectFruit(e) {
        this.setState({
            fruitName: e,
            filename: this.state.fruits[e][0]
        });
    }

    reset() {
        this.setState({
            filename: '',
            fruitName: '',
            subtitle: '',
            originCountry: '',
            originRegion: '',
            category: '',
            variety: '',
            caliber: '',
            price: '',
            pieceNb: 1,
            priceUnit: 'kilo',
            priceSupport: '',
            promotion: false,
            expirationDate: null,
            startDate: null,
            weight: '300',
            labels: [],
            _id: null,
            displayFlag: true,
            newTemplate: this.props.newTemplate || false,
            templateName: this.props.templateName,
            duration: 5,
            origPrice: '',

            step: 0
        });
    }

    setSlide(s, num, duration) {
        this.setState({ ...s, num, step: 2, duration });
    }

    componentDidMount() {
        fetchApi(`/sequences/fruits`)
            .then((res) => res.json())
            .then((res) => {
                const fruits = {};
                res.forEach((entry) => {
                    if (!fruits[entry.category]) {
                        fruits[entry.category] = [];
                    }

                    fruits[entry.category].push(entry.filename);
                });

                this.setState({
                    fruits: fruits
                });
            })
            .catch((err) => {
                this.props.handleClose();
                return notify.show('Impossible de recuperer les donees: ' + err.message, 'warning');
            });
    }

    render() {
        switch (this.state.step) {
            case 0:
                return (
                    <FruitsSelectionPage
                        {...this.state}
                        handleChange={this.handleChange}
                        handleStep={this.handleStep}
                        handleSelectFruit={this.handleSelectFruit}
                        handleSwitchFruit={(index, fruitName) =>
                            this.setState({
                                filename:
                                    this.state.fruits[fruitName][
                                        index < 0
                                            ? 0
                                            : index >= this.state.fruits[fruitName].length
                                            ? this.state.fruits[fruitName].length - 1
                                            : index
                                    ]
                            })
                        }
                        handleSaveCrea={this.handleSaveCrea}
                        onDelete={() => {
                            if (this.props.onDelete()) this.handleClose();
                        }}
                        orientation={this.props.orientation}
                        setStep={(i) => this.setState({ step: Math.min(this.state.step, i) })}
                    />
                );
            case 1:
                return (
                    <FruitData
                        {...this.state}
                        handleChange={this.handleChange}
                        handleSetFlag={(flagVisible) => this.setState({ displayFlag: flagVisible })}
                        handleBackStep={this.handleBackStep}
                        handleStep={this.handleStep}
                        handleSaveCrea={this.handleSaveCrea}
                        onDelete={() => {
                            if (this.props.onDelete()) this.handleClose();
                        }}
                        orientation={this.props.orientation}
                        setStep={(i) => this.setState({ step: Math.min(this.state.step, i) })}
                    />
                );
            case 2:
                return (
                    <FruitPrice
                        {...this.state}
                        handleChange={this.handleChange}
                        handleBackStep={this.handleBackStep}
                        handleStep={this.handleStep}
                        handleSaveCrea={this.handleSaveCrea}
                        onDelete={() => {
                            if (this.props.onDelete()) this.handleClose();
                        }}
                        orientation={this.props.orientation}
                        setStep={(i) => this.setState({ step: Math.min(this.state.step, i) })}
                        allowSlidesDurationChange={this.props.allowSlidesDurationChange}
                        maxDuration={this.props.maxDuration}
                        onChangeDuration={(e) => this.setState({ duration: parseInt(e.target.value) })}
                    />
                );
            case 3:
                return (
                    <FruitLabel
                        {...this.state}
                        handleBackStep={this.handleBackStep}
                        handleSave={this.handleSaveCrea}
                        availableLabels={this.labels}
                        handleSetLabels={(labels) => this.setState({ labels, label: null })}
                        handleChange={this.handleChange}
                        onDelete={() => {
                            if (this.props.onDelete()) this.handleClose();
                        }}
                        orientation={this.props.orientation}
                        setStep={(i) => this.setState({ step: Math.min(this.state.step, i) })}
                    />
                );
            default:
                break;
        }

        return null;
    }
}

export default FruitsAssistant;
