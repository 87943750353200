import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, OverlayView, InfoWindow } from 'react-google-maps';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import constants from '../../Constants';
import { Link } from 'react-router-dom';
import AuthService from '../../Components/Auth/AuthService';
import { compose, withProps, lifecycle } from 'recompose';
import ReactTags from 'react-tag-autocomplete';
import { notify } from 'react-notify-toast';
import dateFormat from 'dateformat';
import Select from 'react-select';

import '../../Assets/map.css';

// Signs icons
import a2pas from '../../Assets/Signs/a2pas.png';
import carrefour from '../../Assets/Signs/carrefour.png';
import carrefourc from '../../Assets/Signs/ccontact.png';
import cmontagne from '../../Assets/Signs/ccontact.png';
import franprix from '../../Assets/Signs/franprix.png';
import g20 from '../../Assets/Signs/g20.png';
import inter from '../../Assets/Signs/inter.png';
import U from '../../Assets/Signs/U.png';
import cmarket from '../../Assets/Signs/cmarket.png';
import monop from '../../Assets/Signs/monop.png';
import coccinelle from '../../Assets/Signs/coccinelle.png';
import myauchan from '../../Assets/Signs/myauchan.png';
import omf from '../../Assets/Signs/omf.png';
import mac from '../../Assets/Signs/mac.png';
import diagonal from '../../Assets/Signs/diagonal.png';
import { fetchApi } from '../../Services/NetworkServcice';

let fixedDate;

const icons = {
    INTERMARCHE: inter,
    FRANPRIX: franprix,
    'CARREFOUR EXPRESS': carrefour,
    'CARREFOUR CONTACT': carrefourc,
    'CARREFOUR MONTAGNE': cmontagne,
    'CARREFOUR CITY': carrefour,
    'G 20': g20,
    G20: g20,
    'U EXPRESS': U,
    MONOP: monop,
    A2PAS: a2pas,
    'MY AUCHAN': myauchan,
    'INTERMARCHE EXPRESS': inter,
    'CARREFOUR MARKET': cmarket,
    DIAGONAL: diagonal,
    'O MARCHE FRAIS': omf,
    'COCCINELLE EXPRESS': coccinelle,
    UTILE: U,
    "LE MARCHE D'A COTE": mac
};

export const getLastSuivi = (sId, suivis, details = false) => {
    const res = suivis[sId];

    if (!res) {
        return (
            <span style={{ backgroundColor: '#E24734', color: '#fff', padding: '5px 10px', marginLeft: 10 }}>
                Inconnue
            </span>
        );
    }

    const d = new Date(res.date);

    const style = () => {
        const now = new Date();

        now.setMonth(now.getMonth() - 1);
        if (d > now) {
            return { backgroundColor: '#A0CD62', color: '#fff', padding: '5px 10px', marginLeft: 10 };
        }

        now.setMonth(now.getMonth() - 5);
        if (d > now) {
            return { backgroundColor: '#EDC05B', color: '#fff', padding: '5px 10px', marginLeft: 10 };
        }

        return { backgroundColor: '#E24734', color: '#fff', padding: '5px 10px', marginLeft: 10 };
    };

    return (
        <div className="lastSuivi">
            <span style={style()}>{dateFormat(d, 'dd/mm/yyyy')}</span>
            {!details || (
                <div className="tooltip-suivi">
                    <span>{res.message}</span>
                </div>
            )}
        </div>
    );
};

const filterByLastCheck = (screens, lastSuivi, suivis) => {
    let minDate = new Date();
    let maxDate = new Date();

    if (lastSuivi === 0) {
        return screens;
    } else if (lastSuivi === 2) {
        minDate.setMonth(minDate.getMonth() - 1);
    } else if (lastSuivi === 1) {
        minDate.setMonth(minDate.getMonth() - 6);
        maxDate.setMonth(maxDate.getMonth() - 1);
    } else if (lastSuivi === 3) {
        maxDate.setMonth(maxDate.getMonth() - 6);
        minDate.setFullYear(2000);
    }

    return screens.filter((s) => {
        const comment = suivis[s.id];

        if (!comment) {
            return false;
        }

        const d = new Date(comment.date);

        return d >= minDate && d <= maxDate;
    });
};

/**
 * Following is for other pages, reusable component
 */
export const ScreenMapComponent = withScriptjs(
    withGoogleMap((props) => {
        return (
            <GoogleMap
                defaultZoom={12}
                defaultCenter={{
                    lat: parseFloat(props.screen.lat),
                    lng: parseFloat(props.screen.long)
                }}
            >
                <ScreenMarker unique={true} screen={props.screen} />
            </GoogleMap>
        );
    })
);

export const SimpleMarkerOnMap = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap defaultZoom={11} defaultCenter={props.position}>
            <Marker position={props.position} />
        </GoogleMap>
    ))
);

export const PlacesWithStandaloneSearchBox = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />
    }),
    lifecycle({
        componentDidMount() {
            const refs = {};

            this.setState({
                places: [],
                onSearchBoxMounted: (ref) => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();

                    this.props.onPlacesChanged(places);
                }
            });
        }
    }),
    withScriptjs
)((props) => (
    <div data-standalone-searchbox="">
        <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                className="noph"
                placeholder="e.g. 100 Av. Charles de Gaulle"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `100%`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`
                }}
            />
        </StandaloneSearchBox>
    </div>
));

// ------------ END ------------
class ScreenMarker extends React.Component {
    constructor() {
        super();

        this.state = {
            isOpen: false,
            deviceSelected: 0
        };

        this.onToggleOpen = this.onToggleOpen.bind(this);
    }

    onToggleOpen() {
        if (this.props.unique) {
            return;
        }

        this.setState({ isOpen: !this.state.isOpen });
    }

    getDeviceStatus(dev) {
        if (!dev.status) {
            return 'No status found';
        }

        if (dev.status.status === 'Running') {
            return (fixedDate - new Date(dev.status.lastPing)) / 1000 <= 30 ? (
                <span className="label label-success" style={{ marginLeft: 'auto' }}>
                    RUNNING
                </span>
            ) : (
                <span className="label label-danger" style={{ marginLeft: 'auto' }}>
                    OFF
                </span>
            );
        } else if (dev.status.status === 'Downloading') {
            return (
                <span className="label label-warning" style={{ marginLeft: 'auto' }}>
                    Downloading
                </span>
            );
        } else if (dev.status.status === 'Paired') {
            return (
                <span className="label label-success" style={{ marginLeft: 'auto' }}>
                    Linked
                </span>
            );
        }
    }

    render() {
        if (this.props.dev && this.props.dev.length === 0) {
            return null; // Do not display shops without screens
        }

        const selectedId = this.props.screen.id;

        let icon = null;

        if (icons[this.props.screen.Sign.name]) {
            icon = {
                url: icons[this.props.screen.Sign.name],
                scaledSize: { height: 45, width: 30 }
            };
        }

        return (
            <Marker
                position={{
                    lat: parseFloat(this.props.screen.lat),
                    lng: parseFloat(this.props.screen.long)
                }}
                onClick={this.onToggleOpen}
                icon={icon}
                animation={window.google.maps.Animation.DROP}
            >
                {this.state.isOpen && (
                    <InfoWindow onCloseClick={this.onToggleOpen}>
                        <div>
                            <h4>
                                {this.props.screen.Sign.name} - {this.props.screen.name}
                            </h4>
                            <div>{this.props.screen.Group.name}</div>
                            <div>{this.props.screen.address}</div>
                            <div>
                                {this.props.screen.zip} - {this.props.screen.city}
                            </div>
                            <div>
                                {this.props.dev &&
                                    this.props.dev[0] &&
                                    this.props.dev[0].status &&
                                    `Dernier ping (Ecran 1): ${dateFormat(
                                        new Date(this.props.dev[0].status.lastPing),
                                        'dd/mm HH:MM'
                                    )}`}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>Dernier suivi: </span>
                                {getLastSuivi(this.props.dev[0].ShopId, this.props.stats, true)}
                            </div>

                            <div style={{ display: 'flex', paddingTop: '10px' }}>
                                <a
                                    className="btn btn-default"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://waze.com/ul?q=${this.props.screen.address}%20${this.props.screen.zip}%20${this.props.screen.city}&navigate=yes`}
                                >
                                    Waze
                                </a>
                                <Link
                                    style={{ marginLeft: 'auto' }}
                                    to={`/history/${this.props.screen.id}`}
                                    className="btn btn-default"
                                >
                                    Suivi
                                </Link>
                            </div>

                            <div className="separatingBar"></div>
                            {this.props.assistant && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        className="screenIcon"
                                        onClick={(e) => {
                                            if (this.state.deviceSelected > 0) {
                                                this.setState({
                                                    deviceSelected: this.state.deviceSelected - 1
                                                });
                                            }
                                        }}
                                    >
                                        <i className="fas fa-caret-left fa-2x" />
                                    </div>
                                    <div style={{ flex: 1 }} className="noWrap">
                                        <div
                                            className="screenTitle"
                                            style={{ textAlign: 'center', position: 'relative', fontWeight: 'bold' }}
                                        >
                                            <div>{this.props.dev[this.state.deviceSelected].name}</div>

                                            <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
                                                {this.state.deviceSelected + 1} / {this.props.dev.length}
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <div>PLAYER:</div>
                                            {this.getDeviceStatus(this.props.dev[this.state.deviceSelected])}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div>ID:</div>
                                            <div style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                                                {this.props.dev[this.state.deviceSelected].id}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '100px' }}>ECRAN:</div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                {this.props.devs.store[selectedId] &&
                                                    this.props.devs.store[selectedId].screenStatus
                                                        .replace('IN TRANSITION FROM STANDBY TO ON', 'ON')
                                                        .replace('IN_TRANSITION_STANDBY_TO_ON', 'ON')
                                                        .replace('UNKNOWN', 'OFF')
                                                        .replace('STANDBY', 'VEILLE')}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ whiteSpace: 'nowrap' }}>DERNIER PING:</div>
                                            <div style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                                                {this.props.devs.store[selectedId] &&
                                                    dateFormat(
                                                        this.props.dev[this.state.deviceSelected].status.lastPing,
                                                        'dd/mm HH:MM'
                                                    )}
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'center', paddingBottom: '10px', paddingTop: '20px' }}>
                                            <Link
                                                to={`/screens/${this.props.dev[this.state.deviceSelected].id}`}
                                                className="btn btn-primary"
                                            >
                                                Gestion
                                            </Link>
                                        </div>
                                    </div>
                                    <div
                                        className="screenIcon"
                                        onClick={(e) => {
                                            if (this.state.deviceSelected + 1 < this.props.dev.length) {
                                                this.setState({
                                                    deviceSelected: this.state.deviceSelected + 1
                                                });
                                            }
                                        }}
                                    >
                                        <i className="fas fa-caret-right fa-2x" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        );
    }
}

const states = {
    0: 'Aucun',
    1: 'A relancer',
    2: 'RDV',
    3: 'Attente signature contrat',
    4: 'A installer/En installation'
};

class ProspectMarker extends React.Component {
    constructor(props) {
        super();
        this.state = {
            isOpen: false,
            selectedState: props.prospect.state,
            notes: props.prospect.notes
        };

        this.onToggleOpen = this.onToggleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
    }

    onToggleOpen() {
        if (this.props.unique) {
            return;
        }

        this.setState({ isOpen: !this.state.isOpen });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangeState() {
        const Auth = new AuthService();

        Auth.fetch(`${constants.endpoint}/admin/map/${this.props.prospect.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                state: this.state.selectedState,
                notes: this.state.notes
            })
        }).then((res) => {
            this.props.prospect.state = this.state.selectedState;
            this.props.prospect.notes = this.state.notes;

            if (!res.success) {
                return notify.show('Error', 'error');
            }

            return notify.show('Saved', 'success');
        });
    }

    render() {
        return (
            <Marker
                position={this.props.position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                onClick={this.onToggleOpen}
                icon={{
                    url: icons[this.props.prospect.enseigne],
                    scaledSize: { height: 45, width: 30 }
                }}
            >
                {this.state.isOpen && (
                    <InfoWindow onCloseClick={this.onToggleOpen}>
                        <div>
                            <div className="panelProspect">
                                <div>
                                    <h5>
                                        {this.props.prospect.enseigne} - {this.props.prospect.name} -{' '}
                                        {this.props.prospect.zip}
                                    </h5>
                                    <p>{this.props.prospect.address}</p>
                                    <p>
                                        Contact: {this.props.prospect.contact_first_name}{' '}
                                        {this.props.prospect.contact_last_name} -{' '}
                                        <a href={`tel:${this.props.prospect.phone}`}>{this.props.prospect.phone}</a>
                                    </p>
                                    <p>Surface: {this.props.prospect.surface_vente}</p>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://www.google.com/maps/dir/?api=1&destination=${this.props.prospect.lat},${this.props.prospect.lng}&mode=walking`}
                                        className="btn btn-default"
                                    >
                                        Y aller
                                    </a>
                                    <div className="form-group" style={{ marginTop: '1em' }}>
                                        <label>Etat</label>
                                        <select
                                            className="form-control"
                                            value={this.state.selectedState}
                                            name="selectedState"
                                            onChange={this.handleChange}
                                        >
                                            {Object.keys(states).map((k) => (
                                                <option value={k} key={k}>
                                                    {states[k]}
                                                </option>
                                            ))}
                                        </select>
                                        <label>Notes:</label>
                                        <textarea
                                            name="notes"
                                            className="form-control"
                                            defaultValue={this.state.notes}
                                            onChange={this.handleChange}
                                        />
                                        <button
                                            style={{ marginTop: '1em' }}
                                            className="btn btn-info"
                                            onClick={this.handleChangeState}
                                        >
                                            Enregistrer
                                        </button>
                                        <button
                                            style={{
                                                marginTop: '2em',
                                                marginLeft: '2em'
                                            }}
                                            className="btn btn-info"
                                            onClick={this.onToggleOpen}
                                        >
                                            Fermer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        );
    }
}

const PosMarker = (props) => {
    return (
        <OverlayView position={props.position} mapPaneName="overlayMouseTarget">
            <div className="mapMyPos" />
        </OverlayView>
    );
};

class GMap extends React.Component {
    constructor() {
        super();

        this.maps = window.google.maps;

        this.state = {
            bounds: new this.maps.LatLngBounds()
        };
    }

    render() {
        let prospects = null;
        const candidates = this.props.prospects.filter(({ lat, lng }) => {
            const LL = {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            };

            if (!LL.lat || !LL.lng) {
                return false;
            }

            const pos = new this.maps.LatLngBounds(LL);

            return this.state.bounds.intersects(pos);
        });

        if (this.props.pChecked) {
            prospects = candidates.map((p) => (
                <ProspectMarker
                    position={{
                        lat: parseFloat(p.lat),
                        lng: parseFloat(p.lng)
                    }}
                    key={p.id}
                    prospect={p}
                />
            ));
        }

        return (
            <GoogleMap
                ref="map"
                defaultZoom={this.props.zoom}
                zoom={this.props.zoom}
                defaultCenter={this.props.center}
                center={this.props.center}
                fullscreenControl={true}
                options={{ streetViewControl: false, mapTypeControl: false }}
                onBoundsChanged={() => this.setState({ bounds: this.refs.map.getBounds() })}
                onZoomChanged={() => this.props.onZoomChange(this.refs.map.getZoom())}
            >
                {!this.props.eChecked ||
                    this.props.screens.map((s) => {
                        return (
                            <ScreenMarker
                                stats={this.props.stats || {}}
                                key={s.id}
                                screen={s}
                                dev={s.devices}
                                devs={this.props.devs}
                                assistant={this.props.assistant}
                            />
                        );
                    })}
                {prospects}
                {!this.props.myPosition || <PosMarker position={this.props.myPosition} />}
            </GoogleMap>
        );
    }
}

const MyMapComponent = withScriptjs(withGoogleMap((props) => <GMap {...props} />));

class Map extends React.Component {
    constructor() {
        super();

        this.state = {
            screens: [],
            statuses: [],
            devices: [],
            prospects: [],
            center: { lat: 48.8588377, lng: 2.2770207 },
            zoom: 12,
            tags: [],
            tagsProspects: [],
            eChecked: true,
            pChecked: false,
            pCheckedI: false,
            playerChecked: false,
            screenChecked: false,
            screenStatusSelected: '',
            pGT200: true,
            pHidden: [],
            lastSuivi: 0,
            devs: {},
            stats: {}
        };

        this.limitScreenActiveUntil = new Date();
        this.limitScreenActiveUntil.setHours(this.limitScreenActiveUntil.getHours() - 1);

        this.getGeoLocation = this.getGeoLocation.bind(this);
        this.handleCheckFilter = this.handleCheckFilter.bind(this);
        this.handleZoomChange = this.handleZoomChange.bind(this);

        this.suggestions = [];
        this.suggestionsP = [];
        this.Auth = new AuthService();
    }

    async componentDidMount() {
        this.getGeoLocation();
        this.suggestions = [];
        this.suggestionsP = [];

        const apiResults = await Promise.all([
            this.Auth.fetch(`${constants.endpoint}/admin/map`),
            this.Auth.fetch(`${constants.endpoint}/admin/devStatus`),
            this.Auth.fetch(`${constants.endpoint}/admin/map/prospects`),
            this.Auth.fetch(`${constants.endpoint}/admin/devices`)
        ]);

        if (this.props.assistant || this.props.admin) {
            await fetchApi('/admin/history/stats')
                .then((res) => res.json())
                .then((stats) => {
                    const statsHash = {};

                    for (const s of stats) {
                        statsHash[s.shopId] = s;
                    }

                    this.setState({ stats: statsHash });
                });
        }

        fixedDate = Date.now();

        const [{ devices, shops }, { statuses }, prospects, devs] = apiResults;

        devices.forEach((device) => {
            device.status = devs.store[device.id] || statuses.find((status) => status.sqlId === device.id);
            device.isActive =
                device.status &&
                device.status.status === 'Running' &&
                (new Date() - new Date(device.status.lastPing)) / 1000 < 30;
        });

        shops.forEach((shop) => {
            shop.devices = devices.filter((device) => {
                return device.ShopId === shop.id;
            });
        });

        this.setState({ screens: shops, devices, prospects, devs });

        shops.forEach((d) => {
            const entries = [
                {
                    type: 'sign',
                    name: d.Sign.name + ' - Enseigne',
                    id: d.Sign.name
                },
                {
                    type: 'shop',
                    name: d.name + ' - ' + d.address + ' - ' + d.city + ' - Shop',
                    id: d.name
                },
                {
                    type: 'zip',
                    name: d.zip + ' - Zip',
                    id: d.zip
                },
                {
                    type: 'group',
                    name: d.Group.name + ' - Groupe',
                    id: d.Group.name
                }
            ];

            entries.forEach((e) => {
                if (!this.suggestions.find((s) => s.type === e.type && s.id === e.id)) {
                    if (e.id) {
                        this.suggestions.push(e);
                    }
                }
            });
        });

        prospects.forEach((s) => {
            const entries = [
                {
                    type: 'sign',
                    name: s.enseigne + ' (Enseigne)',
                    id: s.enseigne
                },
                {
                    type: 'shop',
                    name: s.name + ' (Shop)',
                    id: s.name
                },
                {
                    type: 'zip',
                    name: s.zip + ' (Zip)',
                    id: s.zip
                },
                {
                    type: 'group',
                    name: s.contact_first_name + ' (Group)',
                    id: s.contact_first_name
                },
                {
                    type: 'city',
                    name: s.city + ' (City)',
                    id: s.city
                }
            ];

            entries.forEach((e) => {
                if (!this.suggestionsP.find((s) => s.type === e.type && s.id === e.id)) {
                    if (e.id) {
                        this.suggestionsP.push(e);
                    }
                }
            });
        });
    }

    handleCheckFilter(e) {
        if (e.target.checked) {
            this.setState({
                pHidden: this.state.pHidden.filter((h) => h !== e.target.value)
            });
        } else {
            this.setState({ pHidden: [...this.state.pHidden, e.target.value] });
        }
    }

    handleZoomChange(e) {
        this.setState({ zoom: e });
    }

    getGeoLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                this.setState({ center: center, zoom: 15 });
            });
        } else {
            console.error('Impossible de géolocaliser');
        }
    }

    render() {
        let screens = this.state.screens;
        let prospects = this.state.prospects;

        this.state.tags.forEach((sf) => {
            screens = screens.filter((s) => {
                if (sf.type === 'sign') {
                    return s.Sign.name === sf.id;
                } else if (sf.type === 'zip') {
                    return s.zip === sf.id;
                } else if (sf.type === 'shop') {
                    return s.name === sf.id;
                } else if (sf.type === 'group') {
                    return s.Group.name === sf.id;
                }

                return null;
            });
        });

        this.state.tagsProspects.forEach((sf) => {
            prospects = prospects.filter((s) => {
                if (sf.type === 'shop') {
                    return s.name === sf.id;
                } else if (sf.type === 'group') {
                    return s.contact_first_name === sf.id;
                } else if (sf.type === 'zip' || sf.type === 'city' || sf.type === 'sign') {
                    return true;
                }

                return null;
            });
        });

        const zipFilters = this.state.tagsProspects.filter(({ type }) => type === 'zip').map(({ id }) => id);
        if (zipFilters.length > 0) {
            prospects = prospects.filter(({ zip }) => zipFilters.indexOf(zip) !== -1);
        }

        const cityFilters = this.state.tagsProspects.filter(({ type }) => type === 'city').map(({ id }) => id);
        if (cityFilters.length > 0) {
            prospects = prospects.filter(({ city }) => cityFilters.indexOf(city) !== -1);
        }

        const signFilter = this.state.tagsProspects.filter(({ type }) => type === 'sign').map(({ id }) => id);
        if (signFilter.length > 0) {
            prospects = prospects.filter(({ enseigne }) => signFilter.indexOf(enseigne) !== -1);
        }

        if (this.state.pHidden) {
            prospects = prospects.filter(({ state }) => this.state.pHidden.indexOf(state.toString()) === -1);
        }

        if (!this.state.pCheckedI) {
            prospects = prospects.filter(({ ind }) => ind === 'independant');
        }

        if (this.state.playerChecked) {
            screens = screens.filter(
                (shop) =>
                    !!shop.devices.find((device) => {
                        if (device.status && device.status.lastPing) {
                            return new Date(device.status.lastPing) < this.limitScreenActiveUntil;
                        }

                        return false;
                    })
            );
        }

        screens = filterByLastCheck(screens, this.state.lastSuivi, this.state.stats);
        prospects = filterByLastCheck(prospects, this.state.lastSuivi, this.state.stats);

        if (this.state.screenChecked) {
            screens = screens.filter((shop) => {
                return shop.devices.find((device) => {
                    if (device.status && device.status.screenStatus) {
                        return (
                            this.state.screenStatusSelected ===
                            device.status.screenStatus
                                .replace('IN TRANSITION FROM STANDBY TO ON', 'ON')
                                .replace('UNKNOWN', 'OFF')
                                .replace('STANDBY', 'VEILLE')
                        );
                    } else {
                        return -1;
                    }
                });
            });
        }

        if (!this.state.pChecked) {
            prospects = prospects.filter(({ ind }) => ind === 'integre');
        }

        if (this.state.pGT200) {
            prospects = prospects.filter(({ surface_vente }) => surface_vente >= 200);
        }

        return (
            <Panel style={{ padding: this.props.assistant ? '5px' : '25px' }}>
                <div style={{ display: this.props.assistant ? 'none' : '' }}>
                    <h3>Maps</h3>
                    <Row>
                        <Col md={12}>
                            <button className="btn btn-info" onClick={this.getGeoLocation}>
                                Géolocaliser
                            </button>
                        </Col>
                    </Row>
                </div>

                <div style={{ display: this.props.assistant ? 'flex' : 'none' }}>
                    <div style={{ display: 'flex' }}>
                        <h3 style={{ margin: 'auto' }}>Maps</h3>
                    </div>

                    <button
                        className="btn btn-info"
                        style={{ marginLeft: 'auto', height: '40px' }}
                        onClick={this.getGeoLocation}
                    >
                        Géolocaliser
                    </button>
                </div>

                <Row style={{ margin: '20px 0px' }}>
                    <Col md={4} style={{ padding: 0 }}>
                        <div className="group">
                            <label htmlFor="filter">Filtre (Shops enregistrés):</label>
                            <ReactTags
                                tags={this.state.tags}
                                suggestions={this.suggestions}
                                suggestionsFilter={(item, query) => {
                                    return item.name.toUpperCase().includes(query.toUpperCase());
                                }}
                                handleDelete={(e) =>
                                    this.setState({
                                        tags: this.state.tags.filter((_, i) => i !== e)
                                    })
                                }
                                handleAddition={(e) =>
                                    this.setState({
                                        tags: [...this.state.tags, e]
                                    })
                                }
                            />
                        </div>
                    </Col>

                    <div className="suiviAsstsDiv">
                        <div className="suiviAsstsCtr">
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        this.setState({
                                            playerChecked: !this.state.playerChecked
                                        })
                                    }
                                />
                                <div className="filter-padding">Player:</div>
                                <div className="label label-danger">OFF</div>
                            </div>
                            <div className="groupBox">
                                <label>Date dernier suivi</label>
                                <div className="mapBtnsFilterSuivi">
                                    <span>
                                        <div
                                            className={'buttonSuivi' + (this.state.lastSuivi === 3 ? ' active' : '')}
                                            onClick={() => this.setState({ lastSuivi: 3 })}
                                            style={{ backgroundColor: '#E73323' }}
                                        ></div>{' '}
                                        {'>'} 6M
                                    </span>
                                    <span>
                                        <div
                                            className={'buttonSuivi' + (this.state.lastSuivi === 1 ? ' active' : '')}
                                            onClick={() => this.setState({ lastSuivi: 1 })}
                                            style={{ backgroundColor: '#F4C043' }}
                                        ></div>{' '}
                                        {'<='} 6M
                                    </span>
                                    <span>
                                        <div
                                            className={'buttonSuivi' + (this.state.lastSuivi === 2 ? ' active' : '')}
                                            onClick={() => this.setState({ lastSuivi: 2 })}
                                            style={{ backgroundColor: '#A0CD62' }}
                                        ></div>{' '}
                                        {'<='} 1M
                                    </span>
                                    <span>
                                        <div
                                            className={'buttonSuivi' + (this.state.lastSuivi === 0 ? ' active' : '')}
                                            onClick={() => this.setState({ lastSuivi: 0 })}
                                        ></div>{' '}
                                        TOUS
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }} className="v-center">
                            <input
                                type="checkbox"
                                onChange={(e) =>
                                    this.setState({
                                        screenChecked: !this.state.screenChecked
                                    })
                                }
                            />
                            <div className="filter-padding">Ecran:</div>
                            <div style={{ width: '100px' }}>
                                <Select
                                    onChange={(e) =>
                                        this.setState({
                                            screenStatusSelected: e.value
                                        })
                                    }
                                    options={[
                                        { value: 'ON', label: 'ON' },
                                        { value: 'OFF', label: 'OFF' },
                                        { value: 'VEILLE', label: 'VEILLE' }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <Col md={4} style={{ display: this.props.assistant ? 'none' : 'flex' }}>
                        <div className="group">
                            <label htmlFor="filter">Filtre (Prospects):</label>
                            <ReactTags
                                tags={this.state.tagsProspects}
                                suggestions={this.suggestionsP}
                                handleDelete={(e) =>
                                    this.setState({
                                        tagsProspects: this.state.tagsProspects.filter((_, i) => i !== e)
                                    })
                                }
                                handleAddition={(e) =>
                                    this.setState({
                                        tagsProspects: [...this.state.tagsProspects, e]
                                    })
                                }
                            />
                            <label style={{ width: '100%' }}>Afficher</label>

                            {Object.keys(states).map((k) => (
                                <div key={k} className="Map-filtersProspect">
                                    <input
                                        type="checkbox"
                                        value={k}
                                        checked={this.state.pHidden.indexOf(k) === -1}
                                        onChange={this.handleCheckFilter}
                                    />
                                    <span>{states[k]}</span>
                                </div>
                            ))}
                            <div className="Map-filtersProspect">
                                <input
                                    type="checkbox"
                                    checked={this.state.pGT200}
                                    onChange={(e) =>
                                        this.setState({
                                            pGT200: e.target.checked
                                        })
                                    }
                                />
                                <span>Magasins >= 200 m2</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} style={{ display: this.props.assistant ? 'none' : '' }}>
                        <div className="group">
                            <label htmlFor="filter">Filtre d'affichage</label>
                            <div className="form-group" style={{ lineHeight: 0 }}>
                                <input
                                    type="checkbox"
                                    checked={this.state.eChecked}
                                    onChange={(e) =>
                                        this.setState({
                                            eChecked: e.target.checked
                                        })
                                    }
                                />
                                <label>Enregistrés</label>
                            </div>
                            <div className="form-group" style={{ lineHeight: 0 }}>
                                <input
                                    type="checkbox"
                                    checked={this.state.pChecked}
                                    onChange={(e) =>
                                        this.setState({
                                            pChecked: e.target.checked
                                        })
                                    }
                                />
                                <label>Prospects (Indépendants)</label>
                            </div>
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    checked={this.state.pCheckedI}
                                    onChange={(e) =>
                                        this.setState({
                                            pCheckedI: e.target.checked
                                        })
                                    }
                                />
                                <label>Prospects (Integrés)</label>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <MyMapComponent
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `600px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            screens={screens}
                            prospects={prospects}
                            center={this.state.center}
                            zoom={this.state.zoom}
                            pChecked={this.state.pChecked || this.state.pCheckedI}
                            eChecked={this.state.eChecked}
                            myPosition={this.state.center}
                            devices={this.state.devices}
                            statuses={this.state.statuses}
                            assistant={this.props.assistant}
                            onZoomChange={this.handleZoomChange}
                            devs={this.state.devs}
                            options={{
                                mapTypeControl: false
                            }}
                            stats={this.state.stats}
                        />
                    </Col>
                </Row>
            </Panel>
        );
    }
}

export default Map;
