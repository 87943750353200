import React from 'react';
import { Tabs, Tab, Panel, Row, Col } from 'react-bootstrap';
import Timeline from './Timeline';
import '../../../Assets/cd.css';
import AuthService from '../../../Components/Auth/AuthService';
import constants from '../../../Constants';
import { notify } from 'react-notify-toast';
import Dropzone from 'react-dropzone';
import { ScaleLoader } from 'react-spinners';
import Sidebar from './Sidebar';

const getFiles = files => {
    if (files === null) {
        return <p>Glissez déposez ou cliquez</p>;
    }

    return files.map((file, i) => (
        <p key={i}>
            {file.name} - {file.size}
        </p>
    ));
};

export default class HistoryLayout extends React.Component {
    constructor() {
        super();

        this.state = {
            shops: [],
            selected: -1,
            type: 'fa fa-comment',
            title: '',
            message: '',
            files: null,
            filter: '',
            uploading: false
        };

        this.getData = this.getData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.upd = 0;
        this.Auth = new AuthService();
    }

    getData() {
        this.Auth.fetch(`${constants.endpoint}/admin/history/`)
            .then(res => {
                const shops = res.shops;

                res.history.forEach(h => {
                    const shop = shops.find(({ id }) => id === h._id);

                    if (!shop) {
                        return;
                    }

                    shops.find(({ id }) => id === h._id).date = h.date;
                });

                this.setState({
                    shops: shops
                });
            })
            .catch(err => console.error(err));
    }

    handleSubmit(e, gallery = false) {
        e.preventDefault();

        if (this.state.uploading) {
            return;
        }

        const data = {
            type: this.state.type,
            title: this.state.title,
            message: this.state.message,
            gallery: !!gallery,
            author: `${this.props.user.first_name} ${this.props.user.last_name}`
        };

        this.setState({ uploading: true });

        this.Auth.fetch(`${constants.endpoint}/admin/history/${this.state.selected}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(res => {
            if (!res.success) {
                return notify.show(res.message, 'error');
            }

            if (this.state.files) {
                const data = new FormData();
                for (let file of this.state.files) {
                    data.append(file.name, file);
                }

                const id = res.id;

                data.append('id', res.id);
                this.Auth.fetchMultipart(`${constants.endpoint}/admin/history/attachment`, {
                    method: 'POST',
                    body: data
                }).then(res => {
                    if (!res.success) {
                        this.handleDelete(id).then(() => {
                            return notify.show(res.message, 'error');
                        });
                    } else {
                        this.upd++;
                        this.setState({
                            type: 'fa fa-comment',
                            title: '',
                            message: '',
                            files: null,
                            uploading: false
                        });

                        this.getData();
                    }
                });
            } else {
                this.upd++;
                this.setState({
                    type: 'fa fa-comment',
                    title: '',
                    message: '',
                    files: null,
                    uploading: false
                });

                this.getData();
            }
        });
    }

    handleDelete(id) {
        return this.Auth.fetch(`${constants.endpoint}/admin/history/${id}`, {
            method: 'DELETE'
        }).then(() => {
            return Promise.resolve();
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    getShopsList(gallery = false) {
        return this.state.shops
            .sort((a, b) => {
                if (a.date && b.date) {
                    return new Date(b.date) - new Date(a.date);
                }

                if (a.date) {
                    return -1;
                }

                if (b.date) {
                    return 1;
                }

                return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            })
            .filter(s => {
                let isValid = true;
                const filter = this.state[gallery ? 'filterGallery' : 'filter'].toUpperCase();
                const searchable = `${(s.name || '').toUpperCase()} ${(s.Sign?.name || '').toUpperCase()} ${(s.Group?.name || '').toUpperCase()} ${(s.address || '').toUpperCase()} ${(s.city || '').toUpperCase()} ${(s.zip || '').toUpperCase()}`;
                if (filter.length === 0) { return isValid; }

                filter.split(' ').forEach(word => {
                    if(!searchable.includes(word)) { isValid = false; }
                });

                return isValid;
            });
    }

    componentDidMount() {
        this.getData();

        if(this.props.match.params.id) {
            this.setState({ selected: this.props.match.params.id });
        }
    }

    render() {
        return (
            <Panel>
                <Tabs defaultActiveKey={1} id="tabs">
                    <Tab eventKey={1} title="Suivi">
                        <Row>
                            <Col md={3} className={this.state.selected !== -1 ? 'hidden-xs hidden-sm' : ''}>
                                <Sidebar
                                    shops={this.getShopsList()}
                                    handleClick={id =>
                                        this.setState({
                                            selected: id
                                        })
                                    }
                                    filter={this.state.filter}
                                    handleChange={this.handleChange}
                                    selected={this.state.selected}
                                    filterName="filter"
                                />
                            </Col>
                            <Col md={9}>
                                <button
                                    className={
                                        this.state.selected === -1
                                            ? 'hidden-xs hidden-sm hidden-lg'
                                            : 'visible-xs visible-sm btn btn-info btn-retour'
                                    }
                                    onClick={() => {
                                        if (this.state.uploading) {
                                            return;
                                        }

                                        this.setState({ selected: -1 });
                                    }}
                                >
                                    Retour
                                </button>
                                <form
                                    className={this.state.selected === -1 ? 'addTl hidden-xs hidden-sm' : 'addTl'}
                                    onSubmit={this.handleSubmit}
                                    autoComplete="off"
                                >
                                    <Row>
                                        <Col md={12} xs={12} className="visible-xs visible-sm">
                                            <h6>
                                                {this.state.shops.find(({ id }) => id === this.state.selected)
                                                    ? this.state.shops.find(({ id }) => id === this.state.selected).name
                                                    : ''}
                                            </h6>
                                        </Col>
                                        <Col md={4} xs={6} style={{ display: this.props.assistant ? 'none' : '' }}>
                                            <div className="form-group">
                                                <label htmlFor="type">Type</label>
                                                <select
                                                    name="type"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={this.state.type}
                                                >
                                                    <option value="fa fa-comments">Commentaire</option>
                                                    <option value="fa fa-check-square">Resolution probleme</option>
                                                    <option value="fa fa-phone">Appel</option>
                                                    <option value="fa fa-bug">Bug</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={6} style={{ display: this.props.assistant ? 'none' : '' }}>
                                            <div className="form-group">
                                                <label htmlFor="title">Titre</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={this.state.title}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4} xs={12}>
                                            <div className="form-group">
                                                <label htmlFor="photo">Photo(s)/Video(s)</label>
                                                <Dropzone
                                                    multiple={true}
                                                    onDrop={files =>
                                                        this.setState({
                                                            files: files
                                                        })
                                                    }
                                                    style={{
                                                        width: 'initial',
                                                        height: 'initial',
                                                        border: '1px solid #777',
                                                        borderRadius: '6px',
                                                        paddingLeft: '1em'
                                                    }}
                                                >
                                                    {getFiles(this.state.files)}
                                                </Dropzone>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="form-group">
                                                <label htmlFor="msg">Message</label>
                                                <textarea
                                                    name="message"
                                                    onChange={this.handleChange}
                                                    value={this.state.message}
                                                    className="form-control"
                                                    cols="30"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <button
                                                disabled={this.state.selected === -1}
                                                className="btn btn-info pull-right"
                                            >
                                                {this.state.uploading ? (
                                                    <ScaleLoader height={15} width={3} color={'#fff'} loading={true} />
                                                ) : (
                                                    'Envoyer'
                                                )}
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                                <hr />
                                <Timeline
                                    className={this.state.selected === -1 ? 'hidden-xs hidden-sm' : ''}
                                    selected={this.state.selected}
                                    upd={this.upd}
                                    handleDelete={this.handleDelete}
                                    assistant={this.props.assistant}
                                />
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Panel>
        );
    }
}
