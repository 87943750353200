import React from 'react';

const Products = (props) => (
    <div className="row">
        <div className="col-md-6">
            <div className="form-group">
                <label>Produits allergènes</label>
                <br />
                <input
                    type="checkbox"
                    checked={props.containsArachide}
                    onChange={(e) => props.setBoolean('containsArachide', e.target.checked)}
                />{' '}
                Arachides
                <br />
                <input
                    type="checkbox"
                    checked={props.containsLactose}
                    onChange={(e) => props.setBoolean('containsLactose', e.target.checked)}
                />{' '}
                Lactose
                <br />
                <input
                    type="checkbox"
                    checked={props.containsEggs}
                    onChange={(e) => props.setBoolean('containsEggs', e.target.checked)}
                />{' '}
                Contient des oeufs
                <br />
                <input
                    type="checkbox"
                    checked={props.containsGluten}
                    onChange={(e) => props.setBoolean('containsGluten', e.target.checked)}
                />{' '}
                Contient du gluten
                <br />
                <input
                    type="checkbox"
                    checked={props.containsNuts}
                    onChange={(e) => props.setBoolean('containsNuts', e.target.checked)}
                />{' '}
                Fruits à coques
                <br />
                <input
                    type="checkbox"
                    checked={props.containsSesame}
                    onChange={(e) => props.setBoolean('containsSesame', e.target.checked)}
                />{' '}
                Sésame
                <br />
                <input
                    type="checkbox"
                    checked={props.containsSoja}
                    onChange={(e) => props.setBoolean('containsSoja', e.target.checked)}
                />{' '}
                Soja
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group">
                <label>Attributs</label>
                <br />
                <input
                    type="checkbox"
                    checked={props.madeHere}
                    onChange={(e) => props.setBoolean('madeHere', e.target.checked)}
                />{' '}
                Cuit sur place
                <br />
                <input
                    type="checkbox"
                    checked={props.isFrozen}
                    onChange={(e) => props.setBoolean('isFrozen', e.target.checked)}
                />{' '}
                Produit congelé
                <br />
                <input
                    type="checkbox"
                    checked={props.withoutGluten}
                    onChange={(e) => props.setBoolean('withoutGluten', e.target.checked)}
                />{' '}
                Sans gluten
            </div>
            <div className="form-group">
                <label>Label</label>
                <br />
                <input
                    type="checkbox"
                    checked={props.bio}
                    onChange={(e) => {
                        props.setBoolean('bio', e.target.checked);
                        props.setBoolean('labelrouge', false);
                    }}
                />{' '}
                Certifié agriculture biologique
                <br />
                <input
                    type="checkbox"
                    checked={props.labelrouge}
                    onChange={(e) => {
                        props.setBoolean('labelrouge', e.target.checked);
                        props.setBoolean('bio', false);
                    }}
                />{' '}
                Label rouge
                <br />
            </div>
        </div>
    </div>
);

export default Products;
