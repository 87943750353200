export const getAllowedVariables = (userObject, isSubsequence, orientation = 'portrait') => {
    let [
        allowFruitsEditor,
        allowBakeryEditor,
        allowButcheryEditor,
        allowCheeseEditor,
        allowFishShop,
        allowDelicatessen,
        allowCaterer,
        allowAssets,
        allowTemplates,
        allowFixedTemplates,
        templateName,
        allowWidgets,
        allowDynamicHtml,
        allowProducts,
        allowHotelPrices
    ] = [false, false, false, false, false, false, false, true, false, false, '', false, false, false, false];

    if (userObject.role.role === 'rayon' && userObject.role.Rayons) {
        // Get all available rayons
        const rayons = userObject.role.Rayons;

        for (const r of rayons) {
            allowFruitsEditor |= r.allowFruits;
            allowBakeryEditor |= r.allowBakery;
            allowButcheryEditor |= r.allowButchery;
            allowCheeseEditor |= r.allowCheese;
            allowFishShop |= r.allowFishShop;
            allowDelicatessen |= r.allowDelicatessen;
            allowCaterer |= r.allowCaterer;
        }
    } else if (userObject.role.role === 'dylSequence') {
        // No other editor than assets
        return {
            allowFruitsEditor,
            allowBakeryEditor,
            allowButcheryEditor,
            allowCheeseEditor,
            allowFishShop,
            allowDelicatessen,
            allowCaterer,
            allowAssets,
            allowTemplates,
            allowFixedTemplates,
            templateName,
            allowWidgets,
            allowDynamicHtml,
            allowProducts,
            allowHotelPrices
        };
    } else {
        // Get all available rayons
        if (isSubsequence && userObject.role.Agent.id !== 8 && userObject.role.Agent.id !== 10 && userObject.role.Agent.id !== 11) {
            allowFruitsEditor = true;
            allowBakeryEditor = true;
            allowButcheryEditor = true;
            allowDelicatessen = true;
            allowCheeseEditor = true;
            allowFishShop = true;
            allowCaterer = true;
        } else {
            const rayons = userObject.role.Shop?.Rayons ?? [];

            for (const r of rayons) {
                allowFruitsEditor |= r.allowFruits;
                allowBakeryEditor |= r.allowBakery;
                allowButcheryEditor |= r.allowButchery;
                allowCheeseEditor |= r.allowCheese;
                allowFishShop |= r.allowFishShop;
                allowDelicatessen |= r.allowDelicatessen;
                allowCaterer |= r.allowCaterer;
            }

            if (userObject.role.Agent.id !== 7 && userObject.role.Agent.id !== 8 && userObject.role.Agent.id !== 10 && userObject.role.Agent.id !== 11 && userObject.role.Agent.id !== 13) {
                allowFruitsEditor = true;
            }

            if (userObject.role.Agent.id === 3) {
                allowFishShop = true;
            }
        }
    }

    if (userObject.role.Agent.id === 1 || userObject.role.Agent.id === 12) {
        allowFruitsEditor = allowBakeryEditor = allowButcheryEditor = allowWidgets = true;
    }

    if (userObject.role.Agent.id === 10 || userObject.role.Agent.id === 13) {
        allowWidgets = true;
    }

    if (
        orientation === 'bandeau' ||
        orientation === 'ligne_caisse' ||
        orientation === 'led_960_676' ||
        orientation === 'led_792_192' ||
        orientation === 'led_288_420' ||
        orientation === 'led_288_384' ||
        orientation === 'led_462_320' ||
        orientation === 'led_1440_240' ||
        orientation === 'led_240_440' ||
        orientation === 'led_912_228' ||
        orientation === 'led_512_256' ||
        orientation === 'led_770_512' ||
        orientation === 'led_1316_790' ||
        orientation === 'led_1920_540' ||
        orientation === 'led_1152_256' ||
        orientation === 'led_576_320' ||
        orientation === 'led_1408_384' ||
        orientation === 'led_864_576' ||
        orientation === 'led_384_272' ||
        orientation === 'led_512_204' ||
        orientation === 'led_1024_136' ||
        orientation === 'led_1024_576' ||
        orientation === 'led_256_448' ||
        orientation === 'led_576_144' ||
        orientation === 'led_768_102' ||
        orientation === 'led_512_340' ||
        orientation === 'led_384_255' ||
        orientation === 'led_1920_576' ||
        orientation === 'led_1140_228' ||
        orientation === 'led_512_108' ||
        orientation === 'led_960_576' ||
        orientation === 'led_576_306' ||
        orientation === 'led_1536_768' ||
        orientation === 'led_1536_384' ||
        orientation === 'led_640_384'
    ) {
        allowBakeryEditor =
            allowButcheryEditor =
            allowFishShop =
            allowFruitsEditor =
            allowCheeseEditor =
            allowDelicatessen =
            allowCaterer =
                false;
    }

    if (
        orientation === 'led_288_416' ||
        orientation === 'led_192_272' ||
        orientation === 'led_448_340' ||
        orientation === 'led_336_255' ||
        orientation === 'led_768_204' ||
        orientation === 'led_576_153'
    ) {
        templateName = 'u';
    }

    if (orientation === 'ligne_caisse' || orientation === 'led_3840_1056') {
        templateName = 'mf';
        allowFruitsEditor = true;
    }

    if (userObject.role.role === 'signRespoBasic') {
        allowAssets = false;
    }

    // DoohYouLike override permissions
    if (userObject.role.Shop && userObject.role.Shop.id === 2) {
        allowDelicatessen = true;
        allowCaterer = true;
        allowTemplates = true;
        allowFixedTemplates = true;
        allowDynamicHtml = true;
        allowHotelPrices = true;
    }

    if (userObject.role.Shop && (userObject.role.Shop.id === 2300 || userObject.role.Shop.id === 2485)) {
        allowHotelPrices = true;
    }

    // [Carrefour City, IM, A2PAS, ...] Fixed templates
    if (userObject.role.Shop && [4, 1, 6, 11, 26, 14, 5, 9, 3, 8, 33, 7].includes(userObject.role.Shop.SignId)) {
        allowFixedTemplates = true;
    }

    // Allow widgets for FREMADIS/Bertin
    if (userObject.role.Shop && userObject.role.Shop.id === 668) {
        allowWidgets = true;
    }

    // Agent DYL U Templates
    if (
        userObject.role.Agent.id === 1 &&
        userObject.role.Shop &&
        (userObject.role.Shop.SignId === 2 ||
            userObject.role.Shop.SignId === 12 ||
            userObject.role.Shop.SignId === 19 ||
            userObject.role.Shop.SignId === 17)
    ) {
        allowFixedTemplates = true;
        allowBakeryEditor =
            allowButcheryEditor =
            allowFishShop =
            allowFruitsEditor =
            allowCheeseEditor =
            allowDelicatessen =
            allowCaterer =
                true;

        if (userObject.role.Shop.SignId === 2) {
            templateName = 'uexpress';
        } else if (userObject.role.Shop.SignId === 17) {
            templateName = 'u';
        } else if (userObject.role.Shop.SignId === 12) {
            templateName = 'utile';
        }
    }

    // Special rule for OHANA
    if (userObject.role.Shop && userObject.role.Shop.id === 507) {
        allowTemplates = true;
    }

    // Special rule for capsud group (DYL Agent)
    if (userObject.role.id === 272) {
        templateName = 'utile';
    }

    // Allow templates for agent U
    if (userObject.role.Agent.id === 5) {
        allowTemplates = true;
        allowFixedTemplates = true;

        // All U will have U fruit templates
        if (userObject.role.Shop) {
            if (userObject.role.Shop.SignId === 23) {
                templateName = 'u';
            } else if (userObject.role.Shop.SignId === 24) {
                templateName = 'hyperu';
            } else if (userObject.role.Shop.SignId === 27) {
                templateName = 'uexpress';
            } else if (userObject.role.Shop.SignId === 35) {
                templateName = 'utile';
            }
        }
    }

    // Allow templates for waitcom
    if (userObject.role.Agent.id === 8) {
        allowTemplates = true;
        allowWidgets = true;
    }

    if (userObject.role.Shop?.id === 2 || userObject.role.Shop?.id === 255 || userObject.role.Shop?.id === 420) {
        allowProducts = true;
    }

    // Templates MyAuchan, Carrefour, Franprix, Intermarche
    if (userObject.role.Shop?.SignId === 9) {
        templateName = 'myauchan';
    } else if (
        userObject.role.Shop?.SignId === 4 ||
        userObject.role.Shop?.SignId === 11 ||
        userObject.role.Shop?.SignId === 19 ||
        userObject.role.Shop?.SignId === 25 ||
        userObject.role.Shop?.SignId === 26
    ) {
        templateName = 'carrefour';
    } else if (userObject.role.Shop?.SignId === 3) {
        templateName = 'franprix';
    } else if (userObject.role.Shop?.SignId === 1) {
        templateName = 'intermarche';
    }

    if (userObject.role.Agent.id === 15) {
        allowFruitsEditor = false;
    }

    return {
        allowFruitsEditor,
        allowBakeryEditor,
        allowButcheryEditor,
        allowCheeseEditor,
        allowFishShop,
        allowDelicatessen,
        allowCaterer,
        allowAssets,
        allowTemplates,
        allowFixedTemplates,
        templateName,
        allowWidgets,
        allowDynamicHtml,
        allowProducts,
        allowHotelPrices,
    };
};
