import React from 'react';
import { CarrefourTemplate } from './Templates/Carrefour';
import { FranprixTemplate } from './Templates/Franprix';
import { IntermarcheTemplate } from './Templates/Intermarche';

import MainTemplate from './Templates/MainTemplate';
import MFTemplate from './Templates/MFTemplate';
import { MyAuchanTemplate } from './Templates/MyAuchan';
import UTemplate from './Templates/UTemplate';

export const PreviewComponent = (props) => {
    if (props.orientation === 'portrait' || props.orientation === 'portrait_direct') {
        switch (props.templateName) {
            case 'myauchan':
                return <MyAuchanTemplate {...props} />;
            case 'franprix':
                return <FranprixTemplate {...props} />;
            case 'intermarche':
                return <IntermarcheTemplate {...props} />;
            case 'carrefour':
                return <CarrefourTemplate {...props} />;
            default:
                return <MainTemplate {...props} />;
        }
    }

    switch (props.templateName) {
        case 'u':
        case 'uexpress':
        case 'hyperu':
        case 'utile':
            return <UTemplate {...props} />;
        case 'mf':
            return <MFTemplate {...props} />;
        default:
            break;
    }

    return <MainTemplate {...props} />;
};

const PreviewFruit = (props) => (
    <div className="PreviewFruit">
        <div className={'CenterFruit o' + props.orientation}>
            <div className="PreviewFruitContainer">
                <PreviewComponent rayon="fruits" {...props} />
            </div>
        </div>
    </div>
);

export default PreviewFruit;
