import React from 'react';
import { Modal } from 'react-bootstrap';
import { PropagateLoader } from 'react-spinners';

const Loader = props => (
    <Modal show={true}>
        <Modal.Header>
            <p className="center">Upload et conversion... ({props.nb} Fichiers)</p>
        </Modal.Header>
        <Modal.Body>
            <div className="AdminAssetLoad">
                <div className="Aloader">
                    <PropagateLoader color="#328CE6" loading={true} />
                </div>
            </div>
        </Modal.Body>
    </Modal>
);

export default Loader;
