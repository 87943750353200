import React from 'react';
import constants from '../../Constants';
import { getTemplateRatio } from '../../Pages/Admin/Templates';
import { templateElementsToHtml } from './ElementsEditor';

const PreviewTemplate = ({ template, elements, isEditor = false }) => {
    let updatedElements = [...elements];

    if (template.defaultElements.length > 0) {
        updatedElements = [...template.defaultElements];

        for (let i = 0; i < elements.length && i < template.allowModificationOn.length; i++) {
            updatedElements[template.allowModificationOn[i]] = elements[i];
        }
    }

    return (
        <div className={`PreviewTemplate s${template.orientation}`}>
            <img
                style={{
                    transform: `scale(${
                        1 / getTemplateRatio(template.orientation, isEditor, window.innerWidth <= 1024)
                    })`
                }}
                src={`${constants.cloud_storage}/Assets/Library/Templates/${template.src}`}
                alt={template.name}
            />
            <div
                className="zone"
                style={{
                    top: template.yStart / getTemplateRatio(template.orientation, isEditor, window.innerWidth <= 1024),
                    left: template.xStart / getTemplateRatio(template.orientation, isEditor, window.innerWidth <= 1024),
                    height: template.height,
                    width: template.width,
                    transform: `scale(${
                        1 / getTemplateRatio(template.orientation, isEditor, window.innerWidth <= 1024)
                    })`
                }}
                dangerouslySetInnerHTML={{ __html: templateElementsToHtml(updatedElements, template.orientation, true) }}
            ></div>
        </div>
    );
};

export default PreviewTemplate;
