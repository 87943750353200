import React from 'react';
import { Panel, Row, Col, Table } from 'react-bootstrap';
import { fetchApi } from '../Services/NetworkServcice';
import { notify } from 'react-notify-toast';

export default class Rayon extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            screens: [],
            users: [],
            rayons: [],

            rayonName: '',
            userName: '',
            allowFruits: true,
            allowButchery: true,
            allowBakery: true,
            allowFishShop: true,
            allowCheese: false,
            allowDelicatessen: false,
            allowCaterer: false,
            selectedRayon: null
        };

        this.getData = this.getData.bind(this);
        this.handleAddRayon = this.handleAddRayon.bind(this);
        this.handleChangeScreenRayon = this.handleChangeScreenRayon.bind(this);
        this.handleAddUser = this.handleAddUser.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        fetchApi('/shop/rayon')
            .then(res => res.json())
            .then(({ screens, users, rayons }) =>
                this.setState({ screens, users, rayons, selectedRayon: rayons[0] ? rayons[0].id : 0 })
            );
    }

    handleAddRayon() {
        if (this.state.rayonName.length < 3) {
            return alert("Merci d'enter un nom de rayon avec au minimun 3 lettres");
        }

        fetchApi('/shop/rayon', {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.rayonName,
                allowFruits: this.state.allowFruits,
                allowButchery: this.state.allowButchery,
                allowBakery: this.state.allowBakery,
                allowCheese: this.state.allowCheese,
                allowDelicatessen: this.state.allowDelicatessen,
                allowCaterer: this.state.allowCaterer,
                allowFishShop: this.state.allowFishShop
            })
        }).then(async res => {
            if (res.status === 200) {
                const rId = await res.text();
                this.setState(prevState => ({
                    rayonName: '',
                    allowFruits: true,
                    rayons: [...prevState.rayons, { id: rId, name: prevState.rayonName }]
                }));
            } else {
                return alert('Erreur lors de la création du rayon');
            }
        });
    }

    handleAddUser() {
        if (this.state.userName.length < 3) {
            return alert("Merci d'enter un nom d'utilisateur avec au moins 3 lettres");
        }

        fetchApi('/shop/rayon/user/' + this.state.selectedRayon, {
            method: 'POST',
            body: JSON.stringify({ userName: this.state.userName })
        }).then(res => {
            if (res.status === 200) {
                this.setState({ userName: '' });
                this.getData();
            } else {
                notify.show('Une erreur est survenue', 'warning');
            }
        });
    }

    handleChangeScreenRayon(screenId, rayonId) {
        fetchApi(`/shop/rayon/${rayonId}/${screenId}`, { method: 'PATCH' }).then(res => {
            if (res.status === 200) {
                this.getData();
                notify.show('OK!', 'success');
            }
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={6}>
                        <Panel>
                            <h3>Rayons</h3>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Ecrans</th>
                                        <th>Utilisateurs</th>
                                        <th>Fruits</th>
                                        <th>Boucherie</th>
                                        <th>Boulangerie</th>
                                        <th>Poissonnerie</th>
                                        <th>Fromagerie</th>
                                        <th>Charcuterie</th>
                                        <th>Traiteur</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.rayons.map(r => (
                                        <tr key={r.id}>
                                            <td>{r.name}</td>
                                            <td>
                                                {this.state.screens.filter(({ RayonId }) => RayonId === r.id).length}
                                            </td>
                                            <td>0</td>
                                            <td>{r.allowFruits ? 'Oui' : 'Non'}</td>
                                            <td>{r.allowButchery ? 'Oui' : 'Non'}</td>
                                            <td>{r.allowBakery ? 'Oui' : 'Non'}</td>
                                            <td>{r.allowFishShop ? 'Oui' : 'Non'}</td>
                                            <td>{r.allowCheese ? 'Oui' : 'Non'}</td>
                                            <td>{r.allowDelicatessen ? 'Oui' : 'Non'}</td>
                                            <td>{r.allowCaterer ? 'Oui' : 'Non'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <br />
                            <div className="groupBox">
                                <label>Ajouter un rayon</label>
                                <br />
                                <input
                                    type="checkbox"
                                    checked={this.state.allowFruits}
                                    onChange={e => this.setState({ allowFruits: e.target.checked })}
                                />{' '}
                                Accès affiches fruits
                                <br />
                                <input
                                    type="checkbox"
                                    checked={this.state.allowButchery}
                                    onChange={e => this.setState({ allowButchery: e.target.checked })}
                                />{' '}
                                Accès affiches boucherie
                                <br />
                                <input
                                    type="checkbox"
                                    checked={this.state.allowBakery}
                                    onChange={e => this.setState({ allowBakery: e.target.checked })}
                                />{' '}
                                Accès affiches boulangerie
                                <br />
                                <input
                                    type="checkbox"
                                    checked={this.state.allowCheese}
                                    onChange={e => this.setState({ allowCheese: e.target.checked })}
                                />{' '}
                                Accès affiches Fromagerie
                                <br />
                                <input
                                    type="checkbox"
                                    checked={this.state.allowFishShop}
                                    onChange={e => this.setState({ allowFishShop: e.target.checked })}
                                />{' '}
                                Accès affiches poissonnerie
                                <br />
                                <input
                                    type="checkbox"
                                    checked={this.state.allowDelicatessen}
                                    onChange={e => this.setState({ allowDelicatessen: e.target.checked })}
                                />{' '}
                                Accès affiches charcuterie
                                <br />
                                <input
                                    type="checkbox"
                                    checked={this.state.allowCaterer}
                                    onChange={e => this.setState({ allowCaterer: e.target.checked })}
                                />{' '}
                                Accès affiches traiteur
                                <div className="input-group">
                                    <input
                                        value={this.state.rayonName}
                                        onChange={e => this.setState({ rayonName: e.target.value })}
                                        type="text"
                                        className="form-control"
                                        placeholder="Nom du rayon"
                                    />
                                    <span className="input-group-btn">
                                        <button className="btn btn-success" onClick={this.handleAddRayon}>
                                            Ajouter
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </Col>
                    <Col md={6}>
                        <Panel>
                            <h3>Ecrans</h3>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Ecran</th>
                                        <th>Rayon</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.screens.map(s => (
                                        <tr key={s.id}>
                                            <td>{s.name}</td>
                                            <td>
                                                <select
                                                    value={s.RayonId || ''}
                                                    onChange={e => this.handleChangeScreenRayon(s.id, e.target.value)}
                                                    className="form-control"
                                                >
                                                    <option value="none">Aucun</option>
                                                    {this.state.rayons.map(r => (
                                                        <option key={r.id} value={r.id}>
                                                            {r.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Panel>
                            <h3>Chefs de rayon</h3>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Utilisateur</th>
                                        <th>Mot de passe</th>
                                        <th>Rayons</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.map(u => (
                                        <tr key={u.id}>
                                            <td>{u.login}</td>
                                            <td>{u.password}</td>
                                            <td>{u.Rayons.reduce((acc, r, i) => acc + r.name + ' ', [])}</td>
                                            <td>
                                                <button className="btn" disabled>
                                                    Ajouter un rayon
                                                </button>
                                                <button className="ml-10 btn btn-danger">Supprimer</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <br />
                            <Row>
                                <Col md={4}>
                                    <div className="groupBox">
                                        <label>Ajouter un utilisateur rayon</label>
                                        <div className="form-group">
                                            <label>Rayon:</label>
                                            <select
                                                className="form-control"
                                                value={this.state.selectedRayon || 0}
                                                onChange={e => this.setState({ selectedRayon: e.target.value })}
                                            >
                                                {this.state.rayons.map(r => (
                                                    <option value={r.id} key={r.id}>
                                                        {r.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="input-group">
                                            <input
                                                value={this.state.userName}
                                                onChange={e => this.setState({ userName: e.target.value })}
                                                type="text"
                                                className="form-control"
                                                placeholder="Nom d'utilisateur"
                                            />
                                            <span className="input-group-btn">
                                                <button className="btn btn-success" onClick={this.handleAddUser}>
                                                    Ajouter
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
