import React, { useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import Switch from 'react-switch';
import xlsxParser from 'xlsx-parse-json';

export default (props) => {
    const [state, setState] = useState({
        invertedXlsSelection: false
    });

    return (
        <div>
            <Row>
                <Col md={12}>
                    <p className="center">
                        A date: {[...new Set(props.availableDevices.map(({ ShopId }) => ShopId))].length} magasins /{' '}
                        {props.availableDevices.length} écrans
                    </p>
                </Col>
            </Row>
            <Row>
                <Modal show={props.manualTarget} onHide={() => props.setParentState({ manualTarget: false })}>
                    <Modal.Header closeButton>S&eacute;lection manuelle</Modal.Header>
                    <Modal.Body>
                        <span>Filtres:</span>
                        <ReactTags
                            tags={props.manualFilters}
                            suggestions={props.suggestions}
                            handleAddition={(e) =>
                                props.setParentState({
                                    manualFilters: [...props.manualFilters, e],
                                    editedTargeting: true
                                })
                            }
                            handleDelete={(e) =>
                                props.setParentState({
                                    manualFilters: props.manualFilters.filter((_, i) => i !== e),
                                    editedTargeting: true
                                })
                            }
                        />
                        <div className="modalManualSelect">
                            <ul className="mt-1">
                                {props.manualFiltered.map((deviceId) => {
                                    const device = props.availableDevices.find(({ id }) => id === deviceId);

                                    return (
                                        <li key={deviceId}>
                                            <span>
                                                {device.Shop.Sign.name} - {device.Shop.name} - {device.name}
                                            </span>
                                            <Switch
                                                className="switch-class"
                                                onChange={(e) => {
                                                    if (e) {
                                                        props.setParentState({
                                                            devices: [...props.selected, deviceId],
                                                            editedTargeting: true
                                                        });
                                                    } else {
                                                        props.setParentState({
                                                            devices: props.selected.filter((id) => id !== deviceId),
                                                            editedTargeting: true
                                                        });
                                                    }
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={40}
                                                checked={props.selected.indexOf(deviceId) !== -1}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-default"
                            onClick={() => props.setParentState({ manualTarget: false })}
                        >
                            OK
                        </button>
                    </Modal.Footer>
                </Modal>
                <Col sm={8}>
                    <h5>Sélection écrans :</h5>
                    Tous{' '}
                    <input
                        type="checkbox"
                        checked={props.allDevices}
                        onChange={(e) => props.handleSelectAll(e.target.checked)}
                    />
                    Excluant{' '}
                    <input
                        type="checkbox"
                        checked={props.deviceFilterExclude}
                        onChange={(e) => props.handleChangeExclude(e.target.checked)}
                    />
                    <div>
                        <span>Filtres:</span>
                        {/*<Row>
                        <Col md={6} className="mobMb">
                            <ReactTags
                                tags={props.filters.filter(
                                    ({ type }) => type === 'sign'
                                )}
                                suggestions={props.signsSuggestions}
                                handleAddition={e =>
                                    props.handleSelectDevices(e, 'add')
                                }
                                handleDelete={e =>
                                    props.handleSelectDevices(e, 'delete')
                                }
                                placeholder="Enseigne"
                                minQueryLength={0}
                                maxSuggestionsLength={25}
                            />
                        </Col>
                        <Col md={6}>
                            <ReactTags
                                tags={props.filters.filter(
                                    ({ type }) => type !== 'sign'
                                )}
                                suggestions={props.suggestions}
                                handleAddition={e =>
                                    props.handleSelectDevices(e, 'add')
                                }
                                handleDelete={e =>
                                    props.handleSelectDevices(e, 'delete')
                                }
                                placeholder="Code postal, ville"
                            />
                        </Col>
                    </Row> */}

                        <ReactTags
                            tags={props.filters}
                            suggestions={props.suggestions}
                            handleAddition={(e) => props.handleSelectDevices(e, 'add')}
                            handleDelete={(e) => props.handleSelectDevices(e, 'delete')}
                        />
                    </div>
                    <button className="btn btn-info mt-1" onClick={() => props.setParentState({ manualTarget: true })}>
                        S&eacute;lection manuelle
                    </button>
                    <div>
                        <p style={{ marginTop: '24px', marginBottom: 0 }}>
                            Sélection par fichier
                            <br />
                            <span>
                                Sélection inverse:{' '}
                                <input
                                    checked={state.invertedXlsSelection}
                                    onChange={() =>
                                        setState({ ...state, invertedXlsSelection: !state.invertedXlsSelection })
                                    }
                                    type="checkbox"
                                />
                            </span>
                        </p>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx"
                            onChange={(e) => {
                                const file = e.target.files[0];

                                xlsxParser.onFileSelection(file).then((data) => {
                                    const shops = data[Object.keys(data)[0]].map((line) =>
                                        parseInt(Object.values(line)[0])
                                    );

                                    let devices = [];

                                    if (state.invertedXlsSelection) {
                                        devices = props.selected
                                            .map((sid) => props.availableDevices.find(({ id }) => id === sid))
                                            .filter(({ ShopId }) => shops.indexOf(ShopId) === -1)
                                            .map(({ id }) => id);
                                    } else {
                                        devices = props.availableDevices
                                            .filter(({ ShopId }) => shops.indexOf(ShopId) !== -1)
                                            .map(({ id }) => id);
                                    }

                                    props.setParentState({
                                        devices,
                                        editedTargeting: true,
                                        deviceFilters: [],
                                        deviceFilterExclude: false,
                                        xlsData: data
                                    });

                                    props.handleXlsUpload(file);
                                });
                            }}
                        />
                        {!props.xlsFile ||
                            (() => {
                                const data = Object.values(props.xlsData)[0];

                                const mags = {};

                                for (const s of data) {
                                    let shopSign = null;

                                    if ('enseigne' in s) {
                                        shopSign = s['enseigne'];
                                    } else {
                                        shopSign = Object.values(s)[2];
                                    }

                                    if (Object.keys(mags).indexOf(shopSign) === -1) {
                                        mags[shopSign] = 0;
                                    }

                                    mags[shopSign]++;
                                }

                                return (
                                    <div style={{ marginTop: '12px' }}>
                                        <p>Fichier: {props.xlsFile}</p>
                                        <p>Contient:</p>
                                        <ul style={{ listStyleType: 'none' }}>
                                            <li>{data.length} Magasins dont:</li>
                                            {Object.keys(mags).map((k) => (
                                                <li key={k}>
                                                    {mags[k]} {k}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                );
                            })()}
                    </div>
                </Col>
                <Col sm={4}>
                    <h5>Sélection :</h5>
                    <p>
                        {
                            [
                                ...new Set(
                                    props.selected
                                        .filter((_id) => !!props.availableDevices.find(({ id }) => id === _id))
                                        .map((_id) => props.availableDevices.find(({ id }) => id === _id).ShopId)
                                )
                            ].length
                        }{' '}
                        Magasins
                    </p>
                    <p>{props.selected.length} Ecrans</p>
                    <div className="controlsScreen">
                        <span onClick={props.handleDownloadCSV}>Télécharger liste</span>
                        <span onClick={props.handleOpenMap}>Voir sur la carte</span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
