import React from 'react';
import { Col, Panel } from 'react-bootstrap';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import dateFormat from 'dateformat';

import swal from 'sweetalert';
import { fetchApi } from '../../Services/NetworkServcice';

import '../../Assets/shopwizard.css';

const Slideshow = (props) => (
    <div className="Slideshow">
        <div className="window">
            <div className="content">
                <img
                    src={
                        constants.cloud_storage + '/Gallery/' + props.images[props.activeImage].src &&
                        constants.cloud_storage + '/History/' + props.imagesuivi[props.activeImage].src
                    }
                    alt="Current"
                />
            </div>
        </div>
        <div className="controls">
            {props.activeImage === 0 || (
                <div className="prev" onClick={props.prev}>
                    <i className="fa fa-angle-left" />
                </div>
            )}
            {props.activeImage === props.images.length - 1 || (
                <div className="next" onClick={props.next}>
                    <i className="fa fa-angle-right" />
                </div>
            )}
            <div className="close" onClick={props.closeSlideshow}>
                <div className="fa fa-times" />
            </div>
        </div>
    </div>
);

export default class ShopWizard extends React.Component {
    constructor(props) {
        super();

        this.state = {
            galerie: [],
            activeImage: 0,
            imageTs: new Date().getTime(),
            defaultSelected: props.shopImage,
            showimage: false,
            selectedimg: false,
            data: [],
            imgsuivi: []
        };
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.turnImg = this.turnImg.bind(this);
        this.getDefault = this.getDefault.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getTitle = this.getTitle.bind(this);

        this.Auth = new AuthService();
    }

    handleKeyPress(e) {
        if (this.state.activeImage !== null) {
            if (e.keyCode === 37) {
                this.handlePrev();
            }

            if (e.keyCode === 39) {
                this.handleNext();
            }

            if (e.keyCode === 27) {
                this.setState({ activeImage: null });
            }
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyPress);

        if (this.props.isSign) {
            this.Auth.fetch(`${constants.endpoint}/admin/wizard/images/sign/${this.props.id}`).then((json) => {
                this.setState({ galerie: json });
            });

            fetchApi(`/admin/history/images/sign/${this.props.id}`)
                .then((res) => res.json())
                .then((e) => {
                    this.setState({ imgsuivi: e });
                });
        } else {
            this.Auth.fetch(`${constants.endpoint}/admin/wizard/images/${this.props.id}`).then((json) => {
                this.setState({ galerie: json });
            });

            fetchApi(`/admin/history/images/${this.props.id}`)
                .then((res) => res.json())
                .then((e) => {
                    this.setState({ imgsuivi: e });
                });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleRemoveImage(activeCampaign, id) {
        if (!window.confirm('Etes vous sur?')) {
            return;
        }

        this.Auth.fetch(`${constants.endpoint}/admin/campaignsGallery/${activeCampaign}/${id}`, {
            method: 'DELETE'
        }).then(() => this.setState({ galerie: this.state.galerie.filter((g) => g.id !== id) }));
    }

    turnImg(activeCampaign, e) {
        fetchApi('/admin/assets/rotate/' + activeCampaign, {
            method: 'PATCH',
            body: JSON.stringify({
                angle: 90,
                image: e
            })
        }).then((res) => {
            if (res.status === 200) {
                this.setState({ imageTs: new Date().getTime() });
            }
        });
    }

    handleNext() {
        if (this.state.activeImage !== null && this.state.activeImage < this.state.galerie.length - 1) {
            this.setState({ activeImage: this.state.activeImage + 1 });
        }
    }

    handlePrev() {
        if (this.state.activeImage !== null && this.state.activeImage > 0) {
            this.setState({ activeImage: this.state.activeImage - 1 });
        }
    }

    getDefault(id, name) {
        fetchApi(`/admin/wizard/setimg/${this.props.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ image: name })
        })
            .then((res) => {
                if (res.status === 200) {
                    return swal(`Photo Sélectionnée`, {
                        icon: 'success'
                    });
                } else {
                    return swal(`Erreur pour selectionner la photo`, {
                        icon: 'error'
                    });
                }
            })
            .then(() => {
                this.setState({ defaultSelected: name, selectedimg: true });
                this.props.setShopImage(this.props.id, name);
            });
    }

    getTitle() {
        if (!this.props.isSign && this.props.shop) {
            return `${this.props.shop.name} - ${this.props.shop.address} - ${this.props.shop.departement}`;
        } else if (this.props.sign) {
            return this.props.sign.name;
        }
    }

    render() {
        return (
            <Panel className="panelcheck">
                <div className="wizhelp">
                    <input
                        type="button"
                        style={{ marginLeft: '1em' }}
                        className="btn btn-default ml-1"
                        value="Retour"
                        onClick={this.props.handleClose}
                    />
                    <div className="titleinfo">{this.getTitle()}</div>
                </div>

                {this.state.galerie.map((s, i) => {
                    return (
                        <div key={i}>
                            <Col md={3}>
                                <div className="gal_2">
                                    <img
                                        onClick={() => {
                                            this.setState({ showimage: true, activeImage: i });
                                        }}
                                        src={`${constants.cloud_storage}/Gallery/${s.src}?ts=${this.state.imageTs}`}
                                        alt={i.id}
                                        className="widthgalerie"
                                    />
                                    <p onClick={() => this.props.history.replace(`/campaignsGallery#${s.campaignId}`)}>
                                        {dateFormat(s.date, 'dd/mm/yy HH:MM')} - {s.campaignName}
                                    </p>
                                    <div className="btn_all">
                                        <button
                                            className="btn btn-danger deleteGalleryImage"
                                            onClick={() => {
                                                this.handleRemoveImage(s.campaignId, s.id);
                                            }}
                                        >
                                            <i className="fa fa-trash" />
                                        </button>
                                        <button className="btn_turn" onClick={() => this.turnImg(s.campaignId, s.id)}>
                                            <i className="fas fa-sync-alt"></i>
                                        </button>
                                        {!!this.props.isSign ||
                                            (this.state.defaultSelected === s.src ? (
                                                <button
                                                    className="btn btn-info"
                                                    onClick={() => this.getDefault(s.id, s.src)}
                                                >
                                                    Image par défaut
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-default"
                                                    onClick={() => this.getDefault(s.id, s.src)}
                                                >
                                                    Sélectionner
                                                </button>
                                            ))}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    );
                })}

                {this.state.imgsuivi.map((s, i) => {
                    return (
                        <div key={i}>
                            <Col md={3}>
                                <div className="gal_2">
                                    <img
                                        onClick={() => {
                                            this.setState({ showimage: true, activeImage: i });
                                        }}
                                        src={`${constants.cloud_storage}/History/${s.src}`}
                                        alt={i.id}
                                        className="widthgalerie"
                                    />
                                    <p> Suivi </p>
                                    <div className="btn_all">
                                        {!!this.props.isSign ||
                                            (this.state.defaultSelected === s.src ? (
                                                <button
                                                    className="btn btn-info"
                                                    onClick={() => this.getDefault(s.id, s.src)}
                                                >
                                                    Image par défaut
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-default"
                                                    onClick={() => this.getDefault(s.id, s.src)}
                                                >
                                                    Sélectionner
                                                </button>
                                            ))}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    );
                })}
                {this.state.showimage === false || (
                    <Slideshow
                        activeImage={this.state.activeImage}
                        images={[...this.state.galerie, ...this.state.imgsuivi]}
                        imagesuivi={this.state.imgsuivi}
                        closeSlideshow={() => this.setState({ showimage: false })}
                        next={this.handleNext}
                        prev={this.handlePrev}
                    />
                )}
            </Panel>
        );
    }
}
