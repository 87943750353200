import React from 'react';
import { Panel, Row, Modal } from 'react-bootstrap';
import { fetchApi, sendFile } from '../../Services/NetworkServcice';
import Calendar from 'react-calendar';
import dateFormat from 'dateformat';
import TagsInput from 'react-tagsinput';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { notify } from 'react-notify-toast';

import '../../Assets/marronier.css';
import Creative from './Assets/Creative';
import Loader from './Assets/Loading';
import Preview from './Assets/Preview';
import constants, { formats } from '../../Constants';
import swal from 'sweetalert';

const MarAssetsBlock = (props) => (
    <Row>
        <div className="category">
            <p>
                {props.name} - {dateFormat(props.startDate, 'dd/mm')}
                {!props.endDate || ' au ' + dateFormat(props.endDate, 'dd/mm')}
                {!props.expirationDate || ' - expire le ' + dateFormat(props.expirationDate, 'dd/mm')} -{' '}
                {props.active ? 'Actif' : 'Inactif'}
            </p>
            <div className="add">
                <div className="buttons">
                    <button style={{ marginRight: '10px' }} onClick={props.handleEditCategory} className="btn btn-info">
                        <i className="fa fa-pen" />
                    </button>
                    <button
                        style={{ marginRight: '10px' }}
                        onClick={() => props.handleOpenCategory(props._id)}
                        className="btn btn-info"
                    >
                        <i className="fa fa-plus" />
                    </button>
                    <button onClick={() => props.handleDeleteCategory(props._id)} className="btn btn-danger">
                        <i className="fa fa-trash" />
                    </button>
                </div>
            </div>
            <div className="row">
                {props.assets.map((a) => (
                    <Creative
                        signs={props.signs.filter(({ id }) => a.signId.indexOf(id) !== -1)}
                        group={props.groups.find(({ id }) => a.groupId === id)}
                        key={a._id}
                        {...a}
                        slide={props.slides.find(({ _id }) => _id === a._id)}
                        subsequenceSlide={props.subsequenceSlides.find(({ _id }) => _id === a._id)}
                        onClick={() => {
                            if (a.isProcessing) {
                                return swal({
                                    text: "Cette affiche est en cours de traitement. La prévisualisation est impossible pour l'instant"
                                });
                            }

                            props.handleOpenAsset(a);
                        }}
                    />
                ))}
            </div>
        </div>
    </Row>
);

class CategoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            startDate: new Date(),
            endDate: new Date(),
            active: true,
            expirationDate: new Date()
        };

        if (props.selectedCategoryObject) {
            this.state.name = props.selectedCategoryObject.name || this.state.name;
            this.state.startDate = props.selectedCategoryObject.startDate || this.state.startDate;
            this.state.endDate = props.selectedCategoryObject.endDate || this.state.endDate;
            this.state.active = props.selectedCategoryObject.active;
            this.state.expirationDate = props.selectedCategoryObject.expirationDate || this.state.expirationDate;
        }

        this.close = this.close.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    close() {
        this.setState({
            name: '',
            startDate: new Date(),
            endDate: new Date(),
            active: true,
            expirationDate: new Date()
        });

        this.props.close();
    }

    handleSubmit() {
        if (this.props.selectedCategoryObject !== null) {
            this.props.hanldeUpdateCategory({
                ...this.state,
                _id: this.props.selectedCategoryObject._id
            });
        } else {
            this.props.handleAddCategory(this.state);
        }
    }

    render() {
        return (
            <Modal show={this.props.modalOpen} onHide={this.close}>
                <Modal.Header closeButton>Ajout catégorie</Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="name">Nom</label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="active">Visible</label>
                        <input
                            type="checkbox"
                            className="form-control"
                            checked={this.state.active}
                            onChange={(e) => this.setState({ active: e.target.checked })}
                        />
                    </div>
                    <div className="row" style={{ display: this.state.startDate === null ? 'none' : 'initial' }}>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="startDate">Date de début</label>
                                <Calendar
                                    value={new Date(this.state.startDate)}
                                    onChange={(e) => this.setState({ startDate: e })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="endDate">Date de fin</label>
                                {this.state.endDate === null || (
                                    <Calendar
                                        value={new Date(this.state.endDate)}
                                        onChange={(e) => {
                                            e.setHours(23, 59, 59, 0);
                                            this.setState({ endDate: e });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group" style={{ display: this.state.startDate === null ? 'none' : 'initial' }}>
                        <label htmlFor="expirationDate">Date d'expiration</label>
                        {this.state.expirationDate === null || (
                            <Calendar
                                value={new Date(this.state.expirationDate)}
                                onChange={(e) => {
                                    const d = new Date(e);
                                    d.setHours(23, 59, 59);
                                    this.setState({ expirationDate: d });
                                }}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-info" onClick={this.handleSubmit}>
                        Enregistrer
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class MoadlUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupId: null,
            signId: [],
            tags: [],
            files: [],
            orientation: 'portrait',
            filename: null,
            defaultExpiration: null
        };

        if (props.selectedAsset !== null) {
            this.state.creative = props.selectedAsset;
            this.state.groupId = props.selectedAsset.groupId;
            this.state.signId = props.selectedAsset.signId;
            this.state.tags = props.selectedAsset.tags;

            if (props.selectedAsset.defaultExpiration)
                this.state.defaultExpiration = props.selectedAsset.defaultExpiration;
        }

        this.reset = this.reset.bind(this);
        this.hanldeDrop = this.hanldeDrop.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    reset() {
        this.setState({
            groupId: null,
            signId: [],
            tags: [],
            files: [],
            orientation: 'portrait',
            defaultExpiration: null
        });

        this.props.close();
    }

    hanldeDrop(files) {
        this.setState((prevState) => ({ files: [...prevState.files, ...files] }));
    }

    handleSubmit() {
        if (this.state.signId.length === 0) {
            return console.error('No signs');
        }

        if (this.props.selectedAsset !== null) {
            this.props.handleUpdateCrea({
                ...this.state,
                _id: this.props.selectedAsset._id
            });
        } else {
            if (this.state.files.length === 0) {
                return console.error('No files');
            }

            this.props.handleAddCrea({
                ...this.state,
                event: this.props.selectedCategory
            });
        }

        this.reset();
    }

    render() {
        let shopsUsing = null;
        let exp = null;

        if (this.props.selectedAsset) {
            const slides = this.props.slides.find(({ _id }) => _id === this.props.selectedAsset._id);

            shopsUsing = new Set();

            if (slides) {
                slides.devices.forEach((assigned) =>
                    assigned.forEach((dev) => shopsUsing.add(this.props.devices.find(({ id }) => id === dev).Shop.name))
                );
            }

            const category = this.props.categories.find(({ _id }) => _id === this.props.selectedAsset.event);
            exp = category.expirationDate;
        }

        return (
            <Modal show={this.props.modalOpen} onHide={this.reset}>
                <Modal.Header closeButton={true}>{this.props.selectedAsset ? 'Update' : 'Upload'}</Modal.Header>
                <Modal.Body>
                    <div className="row" style={{ maxWidth: 200, margin: '0 auto' }}>
                        {!this.state.creative || (
                            <div>
                                <Preview
                                    preview={`${constants.cloud_storage}/Assets/${this.state.creative.filename}`}
                                    preview_fallback={`${constants.endpoint}/static/assets/${this.state.creative.filename}`}
                                    type={this.state.creative.type}
                                />
                                {!exp || 'Expire le ' + dateFormat(exp, 'dd/mm HH:MM')}
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="form-group">
                                <label htmlFor="signId">Enseignes:</label>
                                <br />
                                Tous
                                <input
                                    type="checkbox"
                                    checked={this.props.signs.length === this.state.signId.length}
                                    onChange={(e) =>
                                        this.setState({
                                            signId: e.target.checked ? this.props.signs.map(({ id }) => id) : []
                                        })
                                    }
                                />
                                <ul>
                                    {this.props.signs.map((s) => (
                                        <li key={s.id}>
                                            <input
                                                type="checkbox"
                                                checked={this.state.signId.indexOf(s.id) !== -1}
                                                onChange={(e) =>
                                                    this.setState({
                                                        signId: e.target.checked
                                                            ? [...this.state.signId, s.id]
                                                            : this.state.signId.filter((sid) => sid !== s.id)
                                                    })
                                                }
                                            />{' '}
                                            {s.name}
                                        </li>
                                    ))}
                                </ul>
                                {!this.state.creative || (
                                    <div>
                                        <label htmlFor="defaultExpiration">
                                            <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                    this.setState({
                                                        defaultExpiration: e.target.checked ? new Date() : null
                                                    })
                                                }
                                            />{' '}
                                            Date d'expiration
                                        </label>
                                        <div
                                            style={{
                                                display: this.state.defaultExpiration === null ? 'none' : 'initial'
                                            }}
                                        >
                                            <Calendar
                                                value={new Date(this.state.defaultExpiration)}
                                                onChange={(e) => {
                                                    const d = new Date(e);
                                                    d.setHours(23, 59, 59);
                                                    this.setState({ defaultExpiration: d });
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="group">Groupe:</label>
                                <Select
                                    options={[
                                        { label: 'Tous', value: null },
                                        ...this.props.groups.map(({ id, name }) => ({
                                            label: name,
                                            value: id
                                        }))
                                    ]}
                                    value={{
                                        label: this.state.groupId
                                            ? this.props.groups.find(({ id }) => id === this.state.groupId).name
                                            : 'Tous',
                                        value: this.state.groupId
                                    }}
                                    onChange={(e) =>
                                        this.setState({
                                            groupId: e.value,
                                            label: e.label
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="orientation">Orientation</label>
                                <select
                                    className="form-control"
                                    value={this.state.orientation}
                                    onChange={(e) => this.setState({ orientation: e.target.value })}
                                >
                                    {Object.keys(formats).map((f) => (
                                        <option key={f} value={f}>
                                            {formats[f].name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {this.props.selectedAsset === null || (
                                <div>
                                    <p>Utilisé par les magasins:</p>
                                    <ul style={{ maxHeight: 160, overflowY: 'scroll' }}>
                                        {[...shopsUsing].map((s) => (
                                            <li key={s}>{s}</li>
                                        ))}
                                    </ul>
                                    <button
                                        className="btn btn-warning"
                                        onClick={() => {
                                            swal({
                                                title: 'Désassigner',
                                                text: 'Etes vous sur?',
                                                icon: 'warning',
                                                buttons: ['Non', 'Oui']
                                            }).then((clicked) => {
                                                if (!clicked) {
                                                    return;
                                                }

                                                fetchApi(
                                                    `/admin/maronniers/desassign/${this.props.selectedAsset._id}`,
                                                    {
                                                        method: 'DELETE'
                                                    }
                                                );
                                            });
                                        }}
                                    >
                                        Désassigner
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="tags">Tags:</label>
                        <TagsInput value={this.state.tags} onChange={(tags) => this.setState({ tags })} />
                    </div>
                    {this.props.selectedAsset !== null || (
                        <div className="form-group">
                            <label htmlFor="files">Fichiers:</label>
                            <Dropzone
                                style={{
                                    width: '100%',
                                    height: '200px',
                                    borderWidth: '2px',
                                    borderColor: 'rgb(102, 102, 102)',
                                    borderStyle: 'dashed',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    padding: '25px'
                                }}
                                onDrop={this.hanldeDrop}
                            >
                                {this.state.files.map((f) => (
                                    <p key={f.name}>{f.name}</p>
                                ))}
                            </Dropzone>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.selectedAsset === null || (
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                if (window.confirm('Remove?')) {
                                    this.props.handleDelete(this.props.selectedAsset._id);
                                }
                            }}
                        >
                            Supprimer
                        </button>
                    )}
                    <button onClick={this.handleSubmit} className="btn btn-info">
                        {this.props.selectedAsset === null ? 'Ajouter' : 'Enregistrer'}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default class Maronnier extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            modalOpen: false, // boolean
            selectedCategory: null, // ObjectId
            selectedAsset: null, // Object
            editingCategory: null, // Object
            loading: false,

            // consts
            signs: [],
            groups: [],
            slides: [],
            subsequenceSlides: [],
            devices: []
        };

        this.getCategories = this.getCategories.bind(this);
        this.handleAddCategory = this.handleAddCategory.bind(this);
        this.handleOpenCategory = this.handleOpenCategory.bind(this);
        this.handleAddCrea = this.handleAddCrea.bind(this);
        this.handleUpdateCrea = this.handleUpdateCrea.bind(this);
        this.hanldeUpdateCategory = this.hanldeUpdateCategory.bind(this);
        this.handleDeleteCrea = this.handleDeleteCrea.bind(this);
        this.handleRemoveCategory = this.handleRemoveCategory.bind(this);
    }

    componentDidMount() {
        this.getCategories();

        fetchApi('/admin/maronniers/signs')
            .then((res) => res.json())
            .then(({ signs }) =>
                this.setState({ signs: signs.filter(({ AgentId }) => AgentId === this.props.user.role.Agent.id) })
            );

        fetchApi('/admin/maronniers/groups')
            .then((res) => res.json())
            .then((groups) => this.setState({ groups }));
    }

    getCategories() {
        fetchApi('/admin/maronniers')
            .then((res) => res.json())
            .then(({ categories, slides, devices, subsequenceSlides }) =>
                this.setState({ categories: categories.reverse(), slides, devices, subsequenceSlides })
            );
    }

    // @param mar: { name, startDate, endDate, active, expirationDate }
    handleAddCategory(mar) {
        if (!mar.name || mar.name.length < 3) {
            return;
        }

        this.setState({ modalOpen: false });

        fetchApi('/admin/maronniers', {
            method: 'POST',
            body: JSON.stringify(mar)
        }).then((res) => {
            if (res.status === 200) {
                this.getCategories();
            }
        });
    }

    handleRemoveCategory(id) {
        swal({
            text: `Êtes-vous sur de vouloir supprimer cette catégorie ?`,
            title: 'La commande ne pourra pas être annulée',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true
        }).then((accepted) => {
            if (accepted) {
                fetchApi(`/admin/maronniers/${id}`, { method: 'DELETE' }).then((res) => {
                    if (res.status === 200) {
                        this.setState({ categories: this.state.categories.filter(({ _id }) => _id !== id) });

                        swal(`Commmande effectué avec succés`, {
                            icon: 'success'
                        });
                    }
                });
            }
        });
    }

    // @param crea: { signId, groupId, tags, files, event }
    handleAddCrea(d) {
        const data = new FormData();
        data.append('signId', JSON.stringify(d.signId));
        data.append('groupId', d.groupId);
        data.append('event', d.event);
        data.append('orientation', d.orientation);
        data.append('tags', JSON.stringify(d.tags));

        for (const file of d.files) {
            data.append(file.name, file);
        }

        this.setState({ loading: true });

        sendFile('/admin/assets/upload', { method: 'POST', body: data })
            .then((res) => {
                if (res.status === 204) {
                    notify.show("L'envoi de fichier est termine!", 'success');

                    this.getCategories();
                    this.setState({ loading: false });
                }
            })
            .catch(async (res) => {
                if (res.status === 500) {
                    notify.show("L'envoi de fichier a echoue", 'warning');
                } else if (res.status === 400) {
                    notify.show('L envoi de donnee a echoue: ' + (await res.text()), 'warning');
                }

                this.setState({
                    loading: false
                });
            });
    }

    // @param c: { _id, signId, groupId, tags }
    handleUpdateCrea(c) {
        const { _id, signId, groupId, tags, orientation, defaultExpiration } = c;

        fetchApi(`/admin/assets/creatives`, {
            method: 'PUT',
            body: JSON.stringify({ _id, signId, groupId, tags, orientation, defaultExpiration })
        }).then((res) => {
            if (res.status === 200) {
                this.getCategories();
                return notify.show('OK !', 'success');
            }
        });
    }

    // @param cat: { _id, name, startDate, endDate, active, expirationDate }
    hanldeUpdateCategory(cat) {
        this.setState({ modalOpen: false, editingCategory: null });

        fetchApi(`/admin/maronniers/${cat._id}`, {
            method: 'PUT',
            body: JSON.stringify(cat)
        }).then((res) => {
            if (res.status === 200) {
                this.getCategories();
            }
        });
    }

    handleOpenCategory(cId) {
        this.setState({ selectedCategory: cId });
    }

    handleDeleteCrea(id) {
        fetchApi(`/admin/assets/${id}`, {
            method: 'DELETE'
        }).then((res) => {
            if (res.status === 200) {
                this.setState({ selectedAsset: null }, () => this.getCategories());
                return notify.show('OK !', 'success');
            } else if (res.status === 409) {
                this.setState({ selectedAsset: null });
                return notify.show('Cet affiche est utilisée par des magasins', 'error');
            } else {
                return notify.show('Erreur', 'warning');
            }
        });
    }

    render() {
        if (!this.state.signs.length || !this.state.groups.length) {
            return 'Loading...';
        }

        return (
            <div className="Marronier">
                {!this.state.modalOpen || (
                    <CategoryModal
                        modalOpen={this.state.modalOpen}
                        handleAddCategory={this.handleAddCategory}
                        selectedCategoryObject={this.state.editingCategory}
                        close={() =>
                            this.setState({
                                modalOpen: false,
                                editingCategory: null
                            })
                        }
                        hanldeUpdateCategory={this.hanldeUpdateCategory}
                    />
                )}
                {(this.state.selectedCategory === null && this.state.selectedAsset === null) || (
                    <MoadlUpload
                        modalOpen={true}
                        signs={this.state.signs}
                        groups={this.state.groups}
                        slides={this.state.slides}
                        devices={this.state.devices}
                        categories={this.state.categories}
                        close={() =>
                            this.setState({
                                selectedCategory: null,
                                selectedAsset: null
                            })
                        }
                        handleAddCrea={this.handleAddCrea}
                        handleUpdateCrea={this.handleUpdateCrea}
                        selectedCategory={this.state.selectedCategory}
                        selectedAsset={this.state.selectedAsset}
                        handleDelete={this.handleDeleteCrea}
                    />
                )}
                {!this.state.loading || <Loader />}
                <Panel>
                    <Panel.Heading>
                        <div className="AssetsHeader">
                            <h4 style={{ textAlign: 'center' }}>Marronnier</h4>
                            <button
                                className="btn btn-info addCategory"
                                onClick={() =>
                                    this.setState({
                                        modalOpen: true,
                                        editingCategory: null
                                    })
                                }
                            >
                                Ajouter catégorie
                            </button>
                        </div>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.state.categories.map((c) => (
                            <MarAssetsBlock
                                key={c._id}
                                handleOpenCategory={this.handleOpenCategory}
                                handleDeleteCategory={this.handleRemoveCategory}
                                handleOpenAsset={(a) => this.setState({ selectedAsset: a })}
                                handleEditCategory={() =>
                                    this.setState({
                                        editingCategory: c,
                                        modalOpen: true
                                    })
                                }
                                signs={this.state.signs}
                                groups={this.state.groups}
                                slides={this.state.slides}
                                subsequenceSlides={this.state.subsequenceSlides}
                                {...c}
                            />
                        ))}
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
