import React from 'react';
import constants from '../Constants';

const TemplateItem = ({ _id, src, name, onClick, isSelected, usage, orientation }) => (
    <div className={isSelected ? `templateItemFree selected ${orientation}` : `templateItemFree`}>
        <img src={`${constants.cloud_storage}/Assets/Library/Templates/Thumbs/${src}`} alt={src} onClick={onClick} />
        <p className="nomargin">{name}</p>
        {usage === null || <p>Utilisations: {(usage.find((u) => _id === u._id) || { count: 0 }).count}</p>}
    </div>
);

const TemplateList = ({ templates = [], onClick = (_ = -1) => null, selected = null, usage = null }) => (
    <div className="templateList">
        {templates.map((t, i) => (
            <TemplateItem key={t._id} {...t} onClick={() => onClick(i)} isSelected={selected === t._id} usage={usage} />
        ))}
    </div>
);

export default TemplateList;
