import React from 'react';
import { Panel, Col, Row } from 'react-bootstrap';
import { Pie as PieChart } from 'react-chartjs';
import { Line as LineChart } from 'react-chartjs';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import dateFormat from 'dateformat';

const colors = ['#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba'];

const transparent = colors.map((c) => c + 'd0');

class Dashboard extends React.Component {
    constructor() {
        super();

        this.Auth = new AuthService();

        this.changelog = [
            {
                version: '10.1.5',
                status: 'Prod',
                changes: ['Ajout Broadsign']
            },
            {
                version: '10.1.4',
                status: 'Prod',
                changes: ['Ajout séquences programmées', 'Ajout date/time sync retry']
            },
            {
                version: '10.1.3',
                status: 'Prod',
                changes: ['Ajout MF template']
            },
            {
                version: '10.0.0',
                status: 'Prod',
                changes: ['Nouvelle version FW', 'Refactoring FW']
            },
            {
                version: '5.1.1',
                status: 'Prod',
                changes: ['Update templates', 'Bug fixes', 'Add programmatic sequences']
            },
            {
                version: '5.0.3',
                status: 'Prod',
                changes: ['Update report interval']
            },
            {
                version: '5.0.2',
                status: 'Prod',
                changes: ['Update downloading procedure', 'Add timeouts', 'Add cached data']
            },
            {
                version: '4.0.9',
                status: 'Prod',
                changes: ['Add filet inside FruitAssistants']
            },
            {
                version: '4.0.8',
                status: 'Prod',
                changes: ['Add new formats support', 'Fix screen on bug', 'Add on/off HDMI', 'Add bandeau format']
            },
            {
                version: '4.0.3',
                status: 'Prod',
                changes: [
                    'Add new templates/edit templates',
                    'Add new formats support',
                    'Fix black screen bug',
                    'Add bakery template',
                    'Fix led screen',
                    'Add variable on off times',
                    'Add start date for slides'
                ]
            },
            {
                version: '4.0.1',
                status: 'Prod',
                changes: ['Add new templates']
            },
            {
                version: '3.3.1',
                status: 'Prod',
                changes: [
                    'Add screen HDMI restart',
                    'Add led_961_577 support',
                    'Add factory reset',
                    'Add screen orientation change'
                ]
            },
            {
                version: '3.3.0',
                status: 'Prod',
                changes: ['Add `boulangerie` template']
            },
            {
                version: '3.2.3',
                status: 'Prod',
                changes: ['Add fruits piece number choice', 'Add country flags']
            },
            {
                version: '3.2.2',
                status: 'Prod',
                changes: ['Add ring support', 'Add black screen for ring extinction']
            },
            {
                version: '3.2.1',
                status: 'Prod',
                changes: ['Fruits auto removal when expired', 'Add landscape orientation', 'Security improvements']
            },
            {
                version: '3.1.0',
                status: 'Prod',
                changes: ['Update download checking process', 'Duplicates removal', 'Screenshot every 30 mn']
            },
            {
                version: '3.0.9',
                status: 'Prod',
                changes: [
                    'Update download process',
                    'Sequential downloading',
                    'Lock on active download (timeout guard)'
                ]
            },
            {
                version: '3.0.8',
                status: 'Prod',
                changes: ['SIGKILL for timed out processes']
            },
            {
                version: '3.0.7',
                status: 'Prod',
                changes: [
                    'Recuperation des erreurs lors de screenshot',
                    'Clean de la stack de processus automatique',
                    'RS232/HDMI CEC exclusif'
                ]
            },
            {
                version: '3.0.5',
                status: 'Prod',
                changes: [
                    'Ajout support RS232 hybride',
                    'Fix cas special telechargement connexion instable (Ecrans noirs)',
                    'Amelioration de la stabilite'
                ]
            },
            {
                version: '3.0.4',
                status: 'Prod',
                changes: ['Reactivation statut ecran', 'Amelioration de la stabilite']
            },
            {
                version: '3.0.3',
                status: 'Prod',
                changes: ['Finalisation de la mise en production']
            },
            {
                version: '3.0.1',
                status: 'Prod',
                changes: [
                    'Ajout du nouveau systeme de sequence',
                    "Fix de l'auto reboot",
                    'Amelioration du process de download',
                    'Refactorisation du code',
                    'Ajout de la granularite horaire annnceur',
                    "Changement de l'intervalle de reporting",
                    'Amelioration du support de commande',
                    'Ajout du nouvel ecran de loading',
                    'Ajout du nouveau systeme de rotation dynamique des sequences annonceur',
                    'Ajout du concept de campagne annonceur'
                ]
            },
            {
                version: '2.4.0',
                status: 'Prod',
                changes: ['Code refactorise', 'Ajout support Kg (fruits)', 'Ajout stats wireless']
            },
            {
                version: '2.3.2',
                status: 'Prod',
                changes: [
                    'Check systematique de fichiers corrompus',
                    'Changement temps offline 24h => 12h. Ecran offline pdt >= 12h entre 22h et minuit => reboot',
                    'Ajout sachet'
                ]
            },
            {
                version: '2.3.1',
                status: 'Prod',
                changes: ['Ajout barquette fruits']
            },
            {
                version: '2.3.0',
                status: 'Prod',
                changes: [
                    'Reecriture systeme de telechargement (+ stable)',
                    'Changement de video provider (pas de gaps entre les videos)',
                    'Fix du script reboot => 1 seul a 23h',
                    'Changement endpoint HTTPS => HTTP'
                ]
            },
            {
                version: '2.2.6',
                status: 'Prod',
                changes: [
                    'Reboot si hors ligne depuis >= 24h. Le reboot aura lieu entre 22h et minuit',
                    'Log persistent apres reboot. LOG_MAX_SIZE=10% (Default)'
                ]
            },
            {
                version: '2.2.5',
                status: 'Prod',
                changes: ['Fix cadre video pour video enseigne']
            },
            {
                version: '2.2.4',
                status: 'Prod',
                changes: ['Ajout support video commercant', 'Ajout checksum', 'Ajout asset par defaut']
            },
            {
                version: '2.2.3',
                status: 'Prod',
                changes: ['Ajout support format paysage', 'Vieo en plein ecran']
            },
            {
                version: '2.2.2',
                status: 'Prod',
                changes: [
                    'Changement statut ecran',
                    'Ajout rapport detaille',
                    'Amelioration telechargement assets',
                    'Correction bug ecran blanc'
                ]
            },
            {
                version: '2.1.9',
                status: 'Prod',
                changes: ['Changement statut ecran', 'Ajout padding heure logs']
            },
            {
                version: '2.1.8',
                changes: [
                    'Ajout de log',
                    'Ajout commande controle a distance',
                    'Ajout commande rapport',
                    'Amelioration screenshot',
                    'Ajout script reset interface reseau si coupure'
                ],
                status: 'Prod'
            },
            {
                version: '2.1.7',
                changes: ['Ajout texte expiration promotion'],
                status: 'Prod'
            },
            {
                version: '2.1.6',
                changes: ['Correctif firmware update dev', 'Promo animation', 'Capacite du disque'],
                status: 'Prod'
            },
            {
                version: '2.1.5',
                changes: ['Etat ecran (debranche)'],
                status: 'Passed'
            },
            {
                version: '2.1.4',
                changes: ['Cadre dooh you like', 'Ajout systeme label promotion'],
                status: 'Prod'
            }
        ];

        this.state = {
            chartData: [],
            deviceData: [],
            devicesStats: {
                labels: [],
                data: []
            }
        };
    }

    componentDidMount() {
        this.Auth.fetch(`${constants.endpoint}/admin/stats/pie`).then((data) => {
            this.setState({
                chartData: data.stats.map((s, i) => {
                    return {
                        value: s.count,
                        color: transparent[i],
                        highlight: colors[i],
                        label: s._id.request
                    };
                })
            });
        });

        this.Auth.fetch(`${constants.endpoint}/admin/stats/devicesWatch`).then((res) => {
            if (!res.success) {
                return;
            }

            this.setState({ deviceData: res.watch });
        });

        this.Auth.fetch(`${constants.endpoint}/admin/stats/devices`).then((data) =>
            this.setState({ devicesStats: data })
        );
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <Panel>Monitoring admin</Panel>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Panel>
                            <h1>Changelog des firmwares</h1>
                            <hr />
                            {this.changelog.map((c, i) => (
                                <section key={i}>
                                    <h4>
                                        <span className={`label label-${c.status === 'Dev' ? 'warning' : 'success'}`}>
                                            {c.status}
                                        </span>{' '}
                                        {c.version}
                                    </h4>
                                    <ul>
                                        {c.changes.map((ch) => (
                                            <li key={ch}>{ch}</li>
                                        ))}
                                    </ul>
                                </section>
                            ))}
                        </Panel>
                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col md={8}>
                                <Panel>
                                    <h1>Active devices</h1>
                                    <hr />
                                    {!this.state.devicesStats.labels.length || (
                                        <LineChart
                                            data={{
                                                labels: [
                                                    ...this.state.devicesStats.labels.map((d) =>
                                                        dateFormat(d, 'dd/mm/yy HH:MM')
                                                    ),
                                                    'Now'
                                                ],
                                                datasets: [
                                                    {
                                                        label: 'Online',
                                                        fillColor: '#DAA52075',
                                                        strokeColor: '#DAA52075',
                                                        highlightFill: '#DAA52075',
                                                        highlightStroke: '#DAA520',
                                                        data: this.state.devicesStats.data
                                                    }
                                                ]
                                            }}
                                            options={{
                                                responsive: true
                                            }}
                                        />
                                    )}
                                </Panel>
                            </Col>
                            <Col md={4}>
                                <Panel>
                                    <h1>Weekly statistics</h1>
                                    <hr />
                                    Actions:
                                    {!this.state.chartData || (
                                        <PieChart
                                            data={this.state.chartData}
                                            options={{
                                                responsive: true
                                            }}
                                        />
                                    )}
                                </Panel>
                            </Col>
                        </Row>
                        <Panel>
                            <h1>Device watching</h1>
                            <hr />
                            <Row>
                                <div className="table-responsive invoice-table">
                                    <table
                                        className="table"
                                        style={{
                                            display: 'block',
                                            maxHeight: '800px',
                                            overflowY: 'scroll',
                                            width: 'fit-content',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <tbody>
                                            {this.state.deviceData.map((d) => {
                                                let classLabel = 'success';

                                                if (d.type === 'disconnected') {
                                                    classLabel = 'danger';
                                                } else if (d.type === 'connected') {
                                                    classLabel = 'default';
                                                }

                                                return (
                                                    <tr key={`${d.deviceId}-${new Date(d.date).getTime()}`}>
                                                        <th>{d.deviceId}</th>
                                                        <td>{d.name}</td>
                                                        <td>
                                                            <span
                                                                style={{
                                                                    textTransform: 'capitalize'
                                                                }}
                                                                className={'label label-' + classLabel}
                                                            >
                                                                {d.type}
                                                            </span>
                                                        </td>
                                                        <td>{dateFormat(d.date, 'dd/mm/yy HH:MM')}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Dashboard;
