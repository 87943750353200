import React from 'react';
import { Col } from 'react-bootstrap';
import constants, { formats } from '../../../Constants';
import dateFormat from 'dateformat';

const getCount = (slide, subSlide) => {
    let count = 0;

    if (slide && slide.count) {
        count += slide.count;
    }

    if (subSlide && subSlide.count) {
        count += subSlide.count;
    }

    return count;
};

const Creative = (props) => (
    <Col xs={4} md={3} lg={2}>
        <div className={'AdminAsset oldAssets o' + props.orientation}>
            <input type="checkbox" checked={props.checked} onChange={props.onChecked} />
            <img
                className="animated brightness"
                onClick={props.onClick}
                src={`${constants.cloud_storage}/Thumbs/${props.filename}.png`}
                alt="Creative"
                height="160"
            />
        </div>
        <div className="AdminCreativeStaticText">
            <div className="tags">
                {props.signs.length > 1 ? (
                    <span className="tag sign">
                        <i className="fa fa-shopping-cart" /> Multi enseigne
                    </span>
                ) : (
                    <span className="tag sign">
                        <i className="fa fa-shopping-cart" /> {props.signs.length === 0 ? '' : props.signs[0].name}
                    </span>
                )}
                {!props.group || (
                    <span className="tag group">
                        <i className="fa fa-users" /> {props.group.name}
                    </span>
                )}
                {props.tags.map((t) => (
                    <span key={t} className="tag">
                        <i className="fa fa-tag" /> {t}
                    </span>
                ))}
            </div>
            <div>
                <i className={`fa fa-${props.type.replace('video', 'play-circle')}`} />
                {props.orientation === 'portrait' || props.orientation === 'landscape' || (
                    <span className="label label-success">{formats[props.orientation].name}</span>
                )}{' '}
                Utilisations: {getCount(props.slide, props.subsequenceSlide)}
            </div>
            <div>{!props.defaultExpiration || 'Expire le ' + dateFormat(props.defaultExpiration, 'dd/mm/yyyy HH:MM')}</div>
        </div>
    </Col>
);

export default Creative;
