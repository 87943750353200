import React, { useState } from 'react';
import swal from 'sweetalert';
import EditorDateModal from '../../Components/Editor/EditorDateModal';
import EditorNewModal from '../../Components/Editor/EditorNewModal';
import EditorSlides from '../../Components/Editor/EditorSlides';
import { formats } from '../../Constants';

const SidePanel = ({
    onToggleSign,
    availableSigns,
    signs,
    onToggleDevice,
    availableScreens,
    screens,
    editable,
    isUpdating,
    sequence_orientation
}) => {
    if (editable) {
        return (
            <div className="card">
                <h4 style={{ marginBottom: 12 }}>Rendre disponible pour les enseignes:</h4>
                {availableSigns.map((s) => (
                    <div className="avail-sign" key={s.id}>
                        <p>
                            {s.id} | {s.name}
                        </p>
                        <input type="checkbox" onChange={() => onToggleSign(s.id)} checked={signs.includes(s.id)} />
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className="card">
                <h4 style={{ marginBottom: 12 }}>Activer sur les écrans:</h4>
                {availableScreens
                    .filter(({ orientation }) => orientation === sequence_orientation)
                    .map((s) => (
                        <div className="avail-sign" key={s.id}>
                            <p>
                                {s.id} | {s.name}
                            </p>
                            <input
                                type="checkbox"
                                disabled={isUpdating}
                                onChange={() => onToggleDevice(s.id)}
                                checked={screens.includes(s.id)}
                            />
                        </div>
                    ))}
            </div>
        );
    }
};

const SequenceDisplay = ({
    creatives,
    orientation,
    onUpdateCreatives,
    onToggleSign,
    onToggleDevice,
    userObject,
    name,
    editable,
    availableSigns,
    availableScreens,
    signs,
    screens,
    onSave,
    buttonSaveDisabled,
    isUpdating
}) => {
    const [state, setState] = useState({
        modalVisible: false,
        selectedIndex: null,
        modalDateVisible: false,
        nameEdition: false
    });

    const handleSaveCrea = (crea) => {
        if (state.selectedIndex !== null) {
            // Update creative
            const creas = [...creatives];
            creas[state.selectedIndex] = { ...creas[state.selectedIndex], ...crea };
            onUpdateCreatives(creas);
        } else {
            onUpdateCreatives([...creatives, crea]);
        }
    };

    const removeSlide = (num) => {
        swal({
            title: 'Supprimer cette affiche?',
            text: 'Êtes-vous sûr ?',
            icon: 'warning',
            buttons: ['Annuler', 'OK'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                setState((prevState) => ({ ...prevState, selectedIndex: null }));

                onUpdateCreatives(creatives.filter((_, i) => i !== num));

                return true;
            }
        });
    };

    return (
        <div className="sequence-details">
            {!editable || (
                <React.Fragment>
                    <EditorNewModal
                        show={state.modalVisible}
                        onClose={() => {
                            setState((prevState) => ({ ...prevState, modalVisible: false }));
                        }}
                        orientation={orientation}
                        creatives={creatives}
                        selectedIndex={state.selectedIndex}
                        allowSlidesDurationChange={false}
                        maxDuration={userObject.role.Agent.maxUserSlideLength}
                        userObject={userObject}
                        isDylSequence={true}
                        onSaveUserSlide={handleSaveCrea}
                    />
                    <EditorDateModal
                        creatives={creatives}
                        i={state.selectedIndex}
                        onClose={() => setState((prevState) => ({ ...prevState, modalDateVisible: false }))}
                        visible={state.modalDateVisible}
                        extended={true}
                        onSave={handleSaveCrea}
                        isSubsequence={false}
                        allowSlidesDurationChange={false}
                        maxDuration={5}
                    />
                </React.Fragment>
            )}
            <div className="card seq-editor">
                <div className="flex center">
                    <div className="title-seq">
                        {state.nameEdition ? (
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setState({ ...state, name: e.target.value })}
                                onKeyDown={(e) => e.key === 'Enter' && setState({ ...state, nameEdition: false })}
                            />
                        ) : (
                            <h3>
                                {name}{' '}
                                {name === 'Défaut' || !editable || (
                                    <i
                                        className="fa fa-pen"
                                        onClick={() => setState({ ...state, nameEdition: true })}
                                    ></i>
                                )}
                            </h3>
                        )}
                        <p>
                            <i className="fa fa-images"></i> Format : {formats[orientation].name} - {creatives.length}{' '}
                            affiche{creatives.length === 1 ? '' : 's'}
                        </p>
                    </div>
                    {!editable || (
                        <button onClick={onSave} className="btn-save" disabled={buttonSaveDisabled}>
                            <i className="fa fa-save"></i>
                            Enregistrer
                        </button>
                    )}
                </div>
            </div>
            <div className="flex">
                <div className="home-editor">
                    <div className="card">
                        <EditorSlides
                            creatives={creatives}
                            orientation={orientation}
                            maxUserSlides={userObject.role.Agent.maxUserSlides}
                            maxDuration={userObject.role.Agent.maxUserSlideLength}
                            handleAddExpirationClick={(type, i) => {
                                if (type !== 'UserCreative') {
                                    return;
                                }

                                setState((prevState) => ({
                                    ...prevState,
                                    modalDateVisible: true,
                                    selectedIndex: i
                                }));
                            }}
                            onClickNew={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    selectedIndex: null,
                                    modalVisible: true
                                }));
                            }}
                            removeSlide={removeSlide}
                            showDuplicateButton={false}
                            draggable={false}
                            selectedSigns={signs}
                            editable={editable}
                        />
                    </div>
                </div>
                <div className="home-editor-cards dylSequence">
                    <SidePanel
                        availableSigns={availableSigns}
                        availableScreens={availableScreens}
                        onToggleSign={onToggleSign}
                        signs={signs}
                        screens={screens}
                        onToggleDevice={onToggleDevice}
                        editable={editable}
                        isUpdating={isUpdating}
                        sequence_orientation={orientation}
                    />
                </div>
            </div>
        </div>
    );
};

export default SequenceDisplay;
