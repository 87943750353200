import React, { useState } from 'react';
import Select from 'react-select';
import constants from '../../Constants';

const getKeyFromValue = (obj, value) => {
    for (const k of Object.keys(obj)) {
        if (obj[k].indexOf(value) !== -1) {
            return k;
        }
    }

    return '';
};

const ChooseCategory = props => {
    const [animal, setAnimal] = useState('Vache');

    const key = getKeyFromValue(props.options[animal] || [], props.filename);
    const nb = key ? props.options[animal][key].length : 0;
    const selected = key ? props.options[animal][key].indexOf(props.filename) : -1;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Choisir un animal</label>
                        <Select
                            options={['Vache', 'Chèvre', 'Brebis', 'Brebis / Vache', 'Brebis / Chèvre'].map(f => ({
                                label: f,
                                value: f
                            }))}
                            onChange={e => setAnimal(e.value)}
                            value={{
                                label: animal,
                                value: animal
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Choisir une pièce</label>
                        <Select
                            options={Object.keys(props.options[animal] || [])
                                .sort((a, b) => a.localeCompare(b))
                                .map(f => ({
                                    label: f,
                                    value: f
                                }))}
                            onChange={e => {
                                props.handleSelectOption(e.value, animal);
                                props.handleSelectPiece(e.value);
                            }}
                            value={{
                                label: key,
                                value: key
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sub">Nom</label>
                        <input
                            type="text"
                            value={props.category}
                            onChange={e => props.handleSelectPiece(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sub">Sous-Titre</label>
                        <input
                            type="text"
                            name="subtitle"
                            value={props.subtitle}
                            onChange={e => props.handleSetSub(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            {!key || (
                <div
                    className="controls"
                    style={{
                        display: nb > 1 ? 'block' : 'none'
                    }}
                >
                    <p>
                        Sélectionnez une image <i className="fa fa-angle-double-right ip5" />
                    </p>
                    <div>
                        {props.options[animal][key].map((f, i) => (
                            <img
                                className={i === selected ? 'fruitSelection active' : 'fruitSelection'}
                                key={f}
                                src={`${constants.cloud_storage}/Assets/Library/Cheese/${f}`}
                                onClick={() => props.handleSwitchOption(i, key)}
                                height="100"
                                alt="cheese"
                            />
                        ))}
                    </div>
                    <hr className="visible-xs" style={{ marginBottom: '-10px' }} />
                </div>
            )}
        </React.Fragment>
    );
};

export default ChooseCategory;
