import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../Auth/AuthService';
import Selector from '../../Pages/Manager/Shops';
import '../../Assets/tuto.css';

const MobileSidebar = ({ elements, featuredElements, selectedElement, hasTempSession, setupUser }) => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <div className="sidebar-mobile">
                {featuredElements.map((e) => (
                    <Link
                        key={e.href}
                        to={e.href}
                        onClick={() => setOpen(false)}
                        className={`menu${selectedElement === e.href.substr(1) ? ' selected' : ''}`}
                    >
                        <i className={e.icon}></i>
                    </Link>
                ))}
                <div
                    className={
                        featuredElements.map((e) => e.href.substr(1)).find((e) => e === selectedElement) !== undefined
                            ? 'menu'
                            : 'menu selected'
                    }
                    onClick={() => setOpen(!open)}
                >
                    <i className="fa fa-ellipsis-v"></i>
                </div>
            </div>
            <div
                className={`sidebar-mobile-ctr${open ? ' expanded' : ''}`}
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        setOpen(false);
                    }
                }}
            >
                <div className={`sidebar-mobile-side${open ? ' expanded' : ''}`}>
                    {(() => {
                        if (hasTempSession) {
                            return (
                                <a
                                    href="# "
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        localStorage.setItem('id_token', localStorage.getItem('old_token'));
                                        localStorage.removeItem('old_token');

                                        setupUser();
                                    }}
                                >
                                    <i className="menu-icon icon-key" />
                                    <span>Retour</span>
                                </a>
                            );
                        }

                        return (
                            <Link to="/logout">
                                <i className="fa fa-power-off" />
                                <span>Fermer la session</span>
                            </Link>
                        );
                    })()}
                    <Link
                        onClick={() => setOpen(false)}
                        to={'/informations'}
                        className={selectedElement === 'informations' ? ' selected' : ''}
                    >
                        <i className={'fa fa-user'}></i> Informations
                    </Link>
                    <div className="divider"></div>
                    {elements.map((e) => {
                        if (e.divider || e.name === 'Informations') {
                            return null;
                        }

                        return (
                            <Link
                                key={e.href}
                                onClick={() => setOpen(false)}
                                to={e.href}
                                className={selectedElement === e.href.substr(1) ? ' selected' : ''}
                            >
                                <i className={e.icon}></i> {e.name}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

class Sidebar extends React.Component {
    constructor() {
        super();

        this.menus = {
            commerce: [
                {
                    name: 'Accueil - Séquences',
                    icon: 'fa fa-home',
                    href: '/'
                },
                // {
                //     name: 'Mon compte',
                //     icon: 'menu-icon icon-user',
                //     href: '/account'
                // },
                {
                    name: 'Bibliothèque d’affiches',
                    icon: 'fa fa-images',
                    href: '/assets'
                },
                {
                    name: 'Upload PDF',
                    icon: 'fa fa-print',
                    href: '/pdf-upload',
                    agentRequired: 1,
                    mobileOnly: true
                },
                {
                    name: 'Marques en avant',
                    icon: 'fa fa-film',
                    href: '/ads',
                    matchAgent: [1, 10]
                },
                {
                    name: 'Tutoriels',
                    icon: 'fa fa-book-open',
                    href: '/tuto',
                    matchAgent: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                },
                // {
                //     name: 'Users chef de rayon',
                //     icon: 'menu-icon icon-user-plus',
                //     href: '/rayons'
                // },
                {
                    name: 'Contact',
                    icon: 'fa fa-envelope',
                    href: '/contact',
                    action: 'messages'
                },
                {
                    name: 'RDV Formation',
                    icon: 'fa fa-graduation-cap',
                    href: '/formation',
                    agentRequired: 1
                },
                {
                    divider: true
                },
                {
                    name: 'Informations',
                    icon: 'fa fa-user',
                    href: '/informations'
                }
            ],
            assetViewer: [
                {
                    name: 'Bibliothèque d’affiches',
                    icon: 'fa fa-images',
                    href: '/assets'
                },
                {
                    divider: true
                }
            ],
            admin: [
                {
                    name: 'Dashboard',
                    icon: 'menu-icon icon-home4',
                    href: '/',
                    allowedIC: true
                },
                {
                    name: 'Affiches',
                    icon: 'menu-icon icon-images',
                    href: '/assets',
                    excludedUsers: [1442]
                },
                {
                    name: 'Templates',
                    icon: 'menu-icon icon-magic-wand',
                    href: '/templates',
                    matchRole: 'templateManagerRole'
                },
                {
                    name: 'Marronnier',
                    icon: 'menu-icon icon-image',
                    href: '/maronnier',
                    matchRole: 'regieRole',
                    matchAgent: [1, 8, 10, 13]
                },
                {
                    name: 'Annonceurs',
                    icon: 'menu-icon icon-office',
                    href: '/agencies',
                    forUsers: [106, 103, 105, 1235, 1354, 2044],
                    matchRole: 'regieRole'
                },
                {
                    name: 'Broadsign',
                    icon: 'menu-icon icon-book',
                    href: '/bs_creatives',
                    forUsers: [106, 103, 105, 1354],
                    matchRole: 'regieRole'
                },
                {
                    name: "Ordres d'insertion",
                    icon: 'menu-icon icon-clipboard',
                    href: '/orders',
                    matchRole: 'regieRole',
                    forUsers: [106, 103, 105, 1354, 530, 1235, 2044]
                },
                {
                    name: 'Campagnes',
                    icon: 'menu-icon icon-stack',
                    href: '/campaigns',
                    matchRole: 'regieRole'
                },
                {
                    name: 'Bilan Campagne',
                    icon: 'menu-icon icon-info',
                    href: '/bilan',
                    matchRole: 'regieRole',
                    matchAgent: [1, 2, 8, 10]
                },
                {
                    name: 'Ecrans',
                    icon: 'menu-icon icon-film',
                    href: '/screens'
                },
                {
                    name: 'Monitoring ecrans',
                    icon: 'menu-icon icon-wrench',
                    href: '/screensMonitor',
                    forUsers: [106, 105, 593, 1218, 1435, 1235, 1992, 2044],
                    allowedIC: true
                },
                {
                    name: 'Menu de Léo',
                    icon: 'menu-icon icon-cool',
                    href: '/leo',
                    forUsers: [106, 110, 277, 587]
                },
                {
                    name: "Menu d'Irina",
                    icon: 'fa fa-heart',
                    href: '/irina',
                    forUsers: [106, 530, 1435]
                },
                {
                    name: 'Map',
                    icon: 'menu-icon icon-map',
                    href: '/map',
                    matchRole: 'maintenanceRole'
                },
                {
                    name: 'Stats',
                    icon: 'menu-icon icon-stats-bars',
                    href: '/stats',
                    forUsers: [106, 103, 105]
                },
                {
                    name: 'Contact',
                    icon: 'menu-icon icon-envelop',
                    href: '/contact',
                    action: 'messages',
                    excludedUsers: [1442]
                },
                {
                    name: 'Newsletter',
                    icon: 'menu-icon icon-upload',
                    href: '/newsletter',
                    matchRole: 'superAdminRole',
                    forUsers: [106, 103, 105, 587, 1354]
                },
                {
                    name: 'Assistant Shops',
                    icon: 'menu-icon icon-user-plus',
                    href: '/wizard',
                    allowedIC: true,
                    excludedUsers: [1442]
                },
                {
                    name: 'Suivi',
                    icon: 'menu-icon icon-history',
                    href: '/history',
                    matchAgent: [1, 8, 10]
                },
                {
                    name: 'Galerie',
                    icon: 'menu-icon icon-play',
                    href: '/campaignsGallery',
                    matchRole: 'regieRole'
                },
                {
                    name: 'Bibliotheque',
                    icon: 'menu-icon icon-books',
                    href: '/library',
                    matchRole: 'superAdminRole'
                },
                {
                    divider: true,
                    allowedIC: true
                }
            ],
            manager: [
                {
                    name: 'Séquences',
                    icon: 'menu-icon icon-stack',
                    href: '/sequences'
                },
                {
                    divider: true
                }
            ],
            commercial: [
                {
                    name: 'Map',
                    icon: 'menu-icon icon-map',
                    href: '/map'
                },
                {
                    divider: true
                }
            ],
            assistant: [
                {
                    name: 'Dashboard',
                    icon: 'menu-icon icon-home4',
                    href: '/'
                },
                {
                    name: 'Map',
                    icon: 'menu-icon icon-map',
                    href: '/map'
                },
                {
                    name: 'Ecrans',
                    icon: 'menu-icon icon-film',
                    href: '/screens'
                },
                {
                    name: 'Monitoring ecrans',
                    icon: 'menu-icon icon-wrench',
                    href: '/screensMonitor'
                },
                {
                    name: 'Suivi',
                    icon: 'menu-icon icon-history',
                    href: '/history'
                },
                {
                    name: 'Galerie',
                    icon: 'menu-icon icon-play',
                    href: '/campaigns/upload'
                },
                {
                    divider: true
                }
            ],
            signRespo: [
                {
                    name: 'Map',
                    icon: 'menu-icon icon-map',
                    href: '/'
                },
                {
                    name: 'Affiches',
                    icon: 'menu-icon icon-images',
                    href: '/assets'
                },
                {
                    name: 'Séquences',
                    icon: 'menu-icon icon-stack',
                    href: '/sequences'
                },
                {
                    name: 'Contact',
                    icon: 'menu-icon icon-envelop',
                    href: '/contact',
                    action: 'messages'
                },
                // {
                //     name: 'Visualisation',
                //     icon: 'menu-icon icon-eye',
                //     href: '/visu'
                // },
                {
                    divider: true
                }
            ],
            signRespoBasic: [
                {
                    name: 'Dashboard',
                    icon: 'menu-icon icon-home4',
                    href: '/'
                },
                {
                    name: 'Séquences',
                    icon: 'menu-icon icon-stack',
                    href: '/sequences'
                },
                {
                    name: 'Contact',
                    icon: 'menu-icon icon-envelop',
                    href: '/contact',
                    action: 'messages'
                },
                {
                    name: 'Visualisation',
                    icon: 'menu-icon icon-eye',
                    href: '/visu'
                },
                {
                    divider: true
                }
            ],
            signVisu: [
                {
                    name: 'Dashboard',
                    icon: 'menu-icon icon-home4',
                    href: '/'
                },
                {
                    name: 'Contact',
                    icon: 'menu-icon icon-envelop',
                    href: '/contact',
                    action: 'messages'
                },
                {
                    name: 'Visualisation',
                    icon: 'menu-icon icon-eye',
                    href: '/visu'
                },
                {
                    divider: true
                }
            ],
            rayon: [
                {
                    name: 'Séquences',
                    icon: 'menu-icon icon-stack',
                    href: '/'
                },
                {
                    name: 'Contact',
                    icon: 'menu-icon icon-envelop',
                    href: '/contact',
                    action: 'messages'
                },
                {
                    divider: true
                }
            ],
            dylSequence: [
                {
                    name: 'Séquences',
                    icon: 'menu-icon icon-stack',
                    href: '/'
                },
                {
                    divider: true
                }
            ],
            groupAdmin: [
                {
                    name: 'Séquences',
                    icon: 'menu-icon icon-stack',
                    href: '/sequences'
                },
                {
                    name: 'Affiches',
                    icon: 'menu-icon icon-images',
                    href: '/assets'
                },
                {
                    divider: true
                }
            ],
            demo: [
                {
                    divider: true
                }
            ]
        };

        this.Auth = new AuthService();
    }

    render() {
        let logo = '/image.png';
        if (this.props.userBO && this.props.userBO.role && this.props.userBO.role.Shop && this.props.userBO.role.Shop.logoUrl) {
            logo = this.props.userBO.role.Shop.logoUrl;
        } else if (this.props.userBO && this.props.userBO.role && this.props.userBO.role.Agent.id === 8) {
            logo =
                'https://evolution.doohyoulike.com/assets/images/dyl_sport.svg';
        } else if (this.props.userBO && this.props.userBO.role && this.props.userBO.role.Agent.id === 10) {
            logo =
                'https://evolution.doohyoulike.com/assets/images/dyl_campus.svg';
        } else if (this.props.userBO && this.props.userBO.role && this.props.userBO.role.Agent.id === 11) {
            logo =
                'https://www.esis-paris.fr/images/GES.png';
        } else if (this.props.userBO && this.props.userBO.role && this.props.userBO.role.Agent.id === 13) {
            logo =
                '/Logo-Doohyoulike-bleu-sombre.png';
        }

        let menus = <div>Loading...</div>;

        if (this.props.userBO !== null) {
            menus = this.menus[this.props.userBO.role.role].map((e) => {
                if (this.props.userBO.role.id === 1218 && !e.allowedIC) {
                    return null;
                }

                if (e.mobileOnly) {
                    return null;
                }

                if (e.matchRole && !this.props.userBO.role.Agent[e.matchRole]) {
                    return null;
                }

                if (e.matchAgent && !e.matchAgent.includes(this.props.userBO.role.Agent.id)) {
                    return null;
                }

                if (e.excludedAgents && e.excludedAgents.includes(this.props.userBO.role.Agent.id)) {
                    return null;
                }

                if (e.excludedUsers && e.excludedUsers.includes(this.props.userBO.role.id)) {
                    return null;
                }

                if (e.forUsers && e.forUsers.indexOf(this.props.userBO.role.id) === -1) {
                    return null;
                }

                if (
                    e.signRequired &&
                    this.props.userBO.role.Shop &&
                    e.signRequired !== this.props.userBO.role.Shop.SignId
                ) {
                    return null;
                }

                if (
                    this.props.userBO.role.Agent &&
                    e.agentRequired &&
                    this.props.userBO.role.Agent.id !== e.agentRequired
                ) {
                    return null;
                }

                if (e.action) {
                    if (e.action === 'messages') {
                        return (
                            <li
                                key={e.icon}
                                className={
                                    this.props.location.pathname.split('/')[1] === e.href.substr(1) ? 'active-page' : ''
                                }
                            >
                                <Link to={e.href}>
                                    <i className={e.icon} />
                                    <span>{e.name} </span>
                                    {this.props.unread === 0 || (
                                        <span className="label label-warning">{this.props.unread}</span>
                                    )}
                                </Link>
                            </li>
                        );
                    }
                }

                if (e.divider) {
                    return <li key="divider" className="menu-divider-80" />;
                }

                return (
                    <li
                        key={e.icon}
                        className={this.props.location.pathname.split('/')[1] === e.href.substr(1) ? 'active-page' : ''}
                    >
                        <Link to={e.href}>
                            <i className={e.icon} />
                            <span>{e.name}</span>
                        </Link>
                    </li>
                );
            });
        }

        return (
            <React.Fragment>
                {!this.props.userBO || this.props.userBO.role.role !== 'commerce' || (
                    <MobileSidebar
                        featuredElements={[
                            {
                                icon: 'fa fa-home',
                                href: '/'
                            },
                            {
                                icon: 'fa fa-images',
                                href: '/assets'
                            },
                            {
                                icon: 'fa fa-film',
                                href: '/ads'
                            }
                        ]}
                        elements={this.menus[this.props.userBO.role.role]}
                        selectedElement={this.props.location.pathname.split('/')[1]}
                        hasTempSession={this.Auth.hasTempSession()}
                        setupUser={() => {
                            this.props.setupUser();
                            this.props.history.replace('/');
                        }}
                    />
                )}
                <div
                    className={
                        'page-sidebar' +
                        (!this.props.sideBarOpen ? ' page-sidebar-closed ' : ' ') +
                        (this.props.userBO ? this.props.userBO.role.role : '')
                    }
                >
                    <div className="logo-sm">
                        <div id="sidebar-toggle-button" onClick={this.props.handleSwitchSidebar}>
                            <i className="fa fa-bars" />
                        </div>
                        <span className="close-mobile-sidebar">
                            <i
                                className="fa fa-times hidden-lg"
                                style={{ color: '#596b8c' }}
                                onClick={this.props.handleSwitchSidebar}
                            />
                        </span>
                    </div>
                    <span className="logo-box">
                        <Link to="/">
                            <img className="logo-sidebar" src={logo} alt="" />
                        </Link>
                    </span>
                    <div className="page-sidebar-inner">
                        <div className="page-sidebar-menu">
                            <ul className="accordion-menu">
                                {(() => {
                                    if (
                                        (this.props.userBO &&
                                            ['manager', 'demo'].indexOf(this.props.userBO.role.role) !== -1) ||
                                        (this.Auth.hasTempSession() &&
                                            ['manager', 'demo'].indexOf(localStorage.getItem('old_role')) !== -1)
                                    ) {
                                        return (
                                            <div>
                                                <Selector
                                                    shopName={
                                                        this.props.userBO && this.props.userBO.role.Shop
                                                            ? this.props.userBO.role.Shop.name
                                                            : null
                                                    }
                                                    setupUser={() => {
                                                        this.props.setupUser();
                                                        this.props.history.replace('/');
                                                    }}
                                                    isDemo={
                                                        (this.props.userBO && this.props.userBO.role.role === 'demo') ||
                                                        (this.Auth.hasTempSession() &&
                                                            localStorage.getItem('old_role') === 'demo')
                                                    }
                                                />
                                                <li className="menu-divider" />
                                            </div>
                                        );
                                    }
                                })()}
                                {menus}
                                <li>
                                    {(() => {
                                        if (this.Auth.hasTempSession()) {
                                            return (
                                                <a
                                                    href="# "
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        localStorage.setItem(
                                                            'id_token',
                                                            localStorage.getItem('old_token')
                                                        );

                                                        localStorage.removeItem('old_token');

                                                        this.props.setupUser();
                                                        this.props.history.replace('/');
                                                    }}
                                                >
                                                    <i className="menu-icon icon-key" />
                                                    <span>Retour</span>
                                                </a>
                                            );
                                        }

                                        return (
                                            <Link to="/logout">
                                                <i className="fa fa-power-off" />
                                                <span>Fermer la session</span>
                                            </Link>
                                        );
                                    })()}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Sidebar;
