import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Switch from 'react-switch';
import Calendar from 'react-calendar';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

const priceRe = new RegExp(/^[0-9]+([.,][0-9]{1,2})?$/);

const SetPrice = (props) => {
    return (
        <div>
            <div className="groupBox">
                <Row>
                    <Col md={5} xs={12}>
                        <div className="form-group">
                            <label>Prix</label>
                            <div className="input-group" style={{ maxWidth: '175px' }}>
                                <input
                                    name="price"
                                    type="text"
                                    className={'form-control' + (props.price.length === 0 ? ' error' : '')}
                                    value={props.price}
                                    onChange={props.handleChange}
                                />
                                <span className="input-group-addon">Euros</span>
                            </div>
                            {props.price.length > 0 || (
                                <span className="errorLabel" style={{ width: '175px' }}>
                                    Ce champs est requis
                                </span>
                            )}
                            {props.price.length === 0 || priceRe.test(props.price) || (
                                <span className="errorLabel" style={{ width: '175px' }}>
                                    Champs est invalide (exemple: 1,99)
                                </span>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="pricenumber">
                        <select
                            name="priceUnit"
                            onChange={(e) => {
                                if (e.target.value.indexOf(':') !== -1) {
                                    props.handleChange({
                                        target: { name: e.target.name, value: e.target.value.split(':')[0] }
                                    });
                                    props.handleChange({
                                        target: { name: 'weightUnit', value: e.target.value.split(':')[1] }
                                    });
                                } else {
                                    props.handleChange(e);
                                }
                            }}
                            value={(() => {
                                if (props.priceUnit === 'number' || props.priceUnit === 'text') {
                                    return `${props.priceUnit}:${props.weightUnit}`;
                                } else {
                                    return props.priceUnit;
                                }
                            })()}
                            className="form-control supportSelect priceunit"
                        >
                            <option value="kilo">Le kilo</option>
                            <option value="piece">Pièce(s)</option>
                            <option value="number:g">Poids</option>
                            <option value="number:barquette">Barquette</option>
                            <option value="number:sachet">Sachet</option>
                            <option value="text:Le paquet">Le paquet</option>
                            <option value="text:Le sachet">Le sachet</option>
                        </select>
                        <div
                            xs={10}
                            style={{
                                display: props.priceUnit !== 'piece' ? 'none' : 'initial'
                            }}
                        >
                            <div className="input-group">
                                <select
                                    style={{ minWidth: 64 }}
                                    value={props.pieceNb || 1}
                                    name="pieceNb"
                                    onChange={props.handleChange}
                                    className="form-control"
                                >
                                    {Array.apply(null, Array(15)).map((_, i) => (
                                        <option key={i} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                                <span className="input-group-addon">Pièce{props.pieceNb > 1 && 's'}</span>
                            </div>
                        </div>
                        <div
                            xs={10}
                            style={{
                                display: props.priceUnit !== 'number' ? 'none' : 'initial'
                            }}
                        >
                            <div className="input-group" style={{ paddingRight: 4 }}>
                                <input
                                    type="number"
                                    name="weight"
                                    className="form-control weight"
                                    value={props.weight}
                                    onChange={props.handleChange}
                                />
                                <span className="input-group-addon">Grammes</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                {!props.promotion || (
                    <Row>
                        <Col md={5} xs={12}>
                            <div className="form-group">
                                <label>Prix original (si promotion, optionel)</label>
                                <div className="input-group" style={{ maxWidth: '175px' }}>
                                    <input
                                        name="origPrice"
                                        type="text"
                                        className={
                                            'origPrice form-control' + (props.origPrice.length === 0 ? ' error' : '')
                                        }
                                        value={props.origPrice}
                                        onChange={props.handleChange}
                                    />
                                    <span className="input-group-addon">Euros</span>
                                </div>
                                {props.origPrice.length === 0 || priceRe.test(props.origPrice) || (
                                    <span className="errorLabel" style={{ width: '175px' }}>
                                        Champs est invalide (exemple: 1,99)
                                    </span>
                                )}
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
            <div className="groupBox">
                <Row>
                    <Col xs={12}>
                        <Switch
                            className="switch-class switchPromo"
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={25}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={40}
                            checked={props.promotion}
                            onChange={(e) => {
                                props.handleChange({
                                    target: {
                                        name: 'promotion',
                                        value: e
                                    }
                                });

                                const d = new Date();
                                d.setHours(23, 59, 59, 0);

                                props.handleChange({
                                    target: {
                                        name: 'expirationDate',
                                        value: d
                                    }
                                });
                            }}
                        />
                        <span>
                            Est-ce une{' '}
                            <label htmlFor="promo-input" className="promo-label">
                                Promotion
                            </label>{' '}
                            ?
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <Switch
                                    className="switch-class switchPromo"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    checked={props.expirationDate !== null}
                                    onChange={(e) => {
                                        const d = new Date();
                                        d.setHours(23, 59, 59, 0);
                                        props.handleChange({
                                            target: {
                                                name: 'expirationDate',
                                                value: e ? d : props.promotion ? d : null
                                            }
                                        });
                                    }}
                                />
                                <label htmlFor="promo-cal" className="promo-cal">
                                    Date d'expiration
                                </label>
                                <p className="indications mt-8">
                                    Choisissez une date et une heure d’expiration de cette affiche dans la séquence :
                                    (l’affiche s’effacera automatiquement après cette date)
                                </p>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                display: props.expirationDate === null ? 'none' : 'initial'
                            }}
                        >
                            <Col md={12}>
                                <Calendar
                                    className="fruit-expiration"
                                    onChange={(date) => {
                                        date.setHours(23, 59, 59, 0);
                                        props.handleChange({
                                            target: {
                                                name: 'expirationDate',
                                                value: new Date(date)
                                            }
                                        });
                                    }}
                                    value={new Date(props.expirationDate)}
                                />
                            </Col>
                        </Row>
                        {!props.allowSlidesDurationChange || (
                            <div>
                                <p>Durée d'affichage: {props.duration}s</p>
                                <input
                                    onChange={props.onChangeDuration}
                                    type="range"
                                    min="1"
                                    max={props.maxDuration}
                                    value={props.duration}
                                />
                            </div>
                        )}
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <Switch
                                    className="switch-class switchPromo"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    checked={props.startDate !== null}
                                    onChange={(e) => {
                                        const d = new Date();
                                        d.setHours(0, 0, 0, 0);
                                        props.handleChange({
                                            target: {
                                                name: 'startDate',
                                                value: e ? d : null
                                            }
                                        });
                                    }}
                                />
                                <label htmlFor="promo-cal" className="promo-cal" style={{ display: 'initial' }}>
                                    Différer le démarrage
                                </label>
                                <p className="indications mt-8">
                                    Choisissez une date et une heure pour un démarrage différé de cette affiche dans la
                                    séquence :
                                </p>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                display: props.startDate === null ? 'none' : 'initial'
                            }}
                        >
                            <Col md={12}>
                                <Calendar
                                    className="fruit-expiration"
                                    onChange={(date) => {
                                        props.handleChange({
                                            target: {
                                                name: 'startDate',
                                                value: new Date(date)
                                            }
                                        });
                                    }}
                                    value={new Date(props.startDate)}
                                />
                                <p>à</p>
                                <TimePicker
                                    value={moment(props.startDate)}
                                    showSecond={false}
                                    onChange={(m) =>
                                        props.handleChange({
                                            target: {
                                                name: 'startDate',
                                                value: m.toDate()
                                            }
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SetPrice;
