import React, { useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import swal from 'sweetalert';
import { fetchApi } from '../../Services/NetworkServcice';
import SequenceDisplay from './SequenceDisplay';

const ManagedSequenceEditor = (props) => {
    const [state, setState] = useState({
        sequence: {
            id: null,
            creatives: [],
            signs: [],
            devices: [],
            name: props.match.params.name,
            orientation: props.match.params.orientation
        },
        sequences: [],
        signs: [],
        screens: [],
        loading: true,
        buttonSaveDisabled: false,
        isUpdating: false
    });

    useEffect(() => {
        const fetchData = async () => {
            const new_stateobj = {
                sequences: [],
                signs: [],
                sequence: {
                    id: null,
                    creatives: [],
                    signs: [],
                    devices: [],
                    name: props.match.params.name,
                    orientation: props.match.params.orientation
                },
                screens: []
            };

            if (props.userObject.role.role === 'dylSequence') {
                await fetchApi('/admin/managed-sequences/home')
                    .then((res) => res.json())
                    .then(({ sequences, signs }) => {
                        new_stateobj.sequences = sequences;
                        new_stateobj.signs = signs;
                    });

                if (props.match.params.id) {
                    await fetchApi(`/admin/managed-sequences/${props.match.params.id}`)
                        .then((res) => res.json())
                        .then((sequence) => {
                            new_stateobj.sequence.creatives = sequence.creatives.map((c) => ({
                                ...c,
                                creative: c.crea
                            }));
                            new_stateobj.sequence.signs = sequence.signId;
                            new_stateobj.sequence.name = sequence.name;
                            new_stateobj.sequence.orientation = sequence.orientation;
                        });

                    new_stateobj.sequence.id = props.match.params.id;
                }
            } else {
                // Fetch data from sequence router
                await fetchApi(`/sequences/managed/${props.match.params.id}`)
                    .then((res) => res.json())
                    .then(({ managedSequence, screens }) => {
                        new_stateobj.sequence.creatives = managedSequence.creatives.map((c) => ({
                            ...c,
                            creative: c.crea
                        }));
                        new_stateobj.sequence.signs = managedSequence.signId;
                        new_stateobj.sequence.name = managedSequence.name;
                        new_stateobj.sequence.devices = managedSequence.devices;
                        new_stateobj.sequence.orientation = managedSequence.orientation;
                        new_stateobj.screens = screens;
                    });

                new_stateobj.sequence.id = props.match.params.id;
            }

            setState((prevState) => ({
                ...prevState,
                ...new_stateobj,
                loading: false
            }));
        };

        fetchData();
    }, [props.match.params.id, props.match.params.name, props.match.params.orientation, props.userObject.role.role]);

    if (state.loading) {
        return <div>Loading...</div>;
    }

    const handleSave = () => {
        setState((prevState) => ({ ...prevState, buttonSaveDisabled: true }));

        if (state.sequence.id === null) {
            fetchApi(`/admin/managed-sequences/new`, {
                method: 'POST',
                body: JSON.stringify(state.sequence)
            }).then(async (res) => {
                if (res.status === 204) {
                    await swal({
                        title: 'Succès',
                        text: 'La séquence a bien été créée !',
                        icon: 'success'
                    });

                    props.history.push('/');
                }
            });
        } else {
            fetchApi(`/admin/managed-sequences/${state.sequence.id}`, {
                method: 'PUT',
                body: JSON.stringify(state.sequence)
            }).then(async (res) => {
                if (res.status === 200) {
                    await swal({
                        title: 'Succès',
                        text: 'La séquence a bien été mise à jour !',
                        icon: 'success'
                    });

                    props.history.push('/');
                } else {
                    await swal({
                        title: 'Erreur',
                        text: `Une erreur est survenue lors de la création de votre séquence. Merci de vérifier les affiches et paramètres. Il se peut que votre version du logiciel ne soit pas à jour. Dans ce cas vous pouvez rafraichir la page avec les touches [CTRL]+[Shift]+[R] (ou [Command]+[Shift]+[R] sur macOS) de votre clavier. Informations sur l'erreur: code: ${res.status}. Vous pouvez nous transmettre une copie écran de ce message si le problème persiste.`,
                        icon: 'warning'
                    });
                }
            });
        }
    };

    const handleToggleScreen = (deviceId) => {
        setState((prevState) => ({ ...prevState, isUpdating: true }));
        const devices = [...state.sequence.devices];
        const id = devices.findIndex((i) => i === deviceId);

        if (id === -1) devices.push(deviceId);
        else devices.splice(id, 1);

        fetchApi(`/sequences/managed/${props.match.params.id}/${deviceId}`, {
            method: 'PATCH'
        }).then((res) => {
            if (res.status === 200) {
                setState((prevState) => ({
                    ...prevState,
                    sequence: { ...prevState.sequence, devices },
                    isUpdating: false
                }));

                notify.show('Modifications enregistrées', 'success');
            } else {
                notify.show("Impossible d'appliquer les modifications", 'error');
            }
        });
    };

    return (
        <div className="dyl-home dyl-sequence-page">
            <header>
                <h3>Edition de séquence</h3>
            </header>
            <div className="content">
                <SequenceDisplay
                    orientation={state.sequence.orientation}
                    creatives={state.sequence.creatives}
                    screens={state.sequence.devices}
                    onUpdateCreatives={(creatives) =>
                        setState((prevState) => ({ ...prevState, sequence: { ...prevState.sequence, creatives } }))
                    }
                    onToggleSign={(signId) => {
                        const signs = [...state.sequence.signs];
                        const id = signs.findIndex((i) => i === signId);

                        if (id === -1) signs.push(signId);
                        else signs.splice(id, 1);

                        setState((prevState) => ({ ...prevState, sequence: { ...prevState.sequence, signs } }));
                    }}
                    userObject={props.userObject}
                    name={state.sequence.name}
                    editable={props.userObject.role.role === 'dylSequence'}
                    availableSigns={state.signs}
                    availableScreens={state.screens}
                    signs={state.sequence.signs}
                    buttonSaveDisabled={state.buttonSaveDisabled}
                    onSave={handleSave}
                    onToggleDevice={handleToggleScreen}
                    isUpdating={state.isUpdating}
                />
            </div>
        </div>
    );
};

export default ManagedSequenceEditor;
