const constants = {
    endpoint: process.env.REACT_APP_ENDPOINT || 'https://api.doohyoulike.com',
    cloud_storage: 'https://storage.googleapis.com/doohyoulike-files'
};

export default constants;

export const getSignName = () => {
    if (window.location.host === 'campus.doohyoulike.com' ||
        [11, 10].includes(parseInt(localStorage.getItem('dyl_a_id')))) return 'Groupe';
    if (window.location.host === 'hotels.doohyoulike.com' ||
        [13].includes(parseInt(localStorage.getItem('dyl_a_id')))) return 'Groupe';
    return 'Enseigne';
};

export const getShopName = () => {
    if (window.location.host === 'campus.doohyoulike.com' ||
        [11, 10].includes(parseInt(localStorage.getItem('dyl_a_id')))) return 'Campus';
    if (window.location.host === 'hotels.doohyoulike.com' ||
        [13].includes(parseInt(localStorage.getItem('dyl_a_id')))) return 'Hotel';
    return 'Magasin';
};

export const formats = {
    portrait: {
        name: 'Portrait',
        ratio: 0.5625,
        height: 1920,
        width: 1080
    },
    portrait_direct: {
        name: 'Portrait (direct)',
        ratio: 0.5625,
        height: 1920,
        width: 1080
    },
    landscape: {
        name: 'Paysage',
        ratio: 1.77777777778,
        height: 1080,
        width: 1920
    },
    ring: {
        name: 'LxH_960x540',
        ratio: 1.77777777778
    },
    led_961_577: {
        name: 'LxH_961x577',
        ratio: 1.66551126516
    },
    bandeau: {
        name: 'LxH_2400x270',
        ratio: 8.88888888889
    },
    fl_simple: {
        name: 'LxH_1440x810',
        ratio: 1.77777777778
    },
    ligne_caisse: {
        name: 'LxH_2880x406',
        ratio: 7.09359605911
    },
    fl_1680_946: {
        name: 'LxH_1680x946',
        ratio: 1.77589852008
    },
    led_960_676: {
        name: 'LxH_960x676',
        ratio: 1.4201183432
    },
    led_792_192: {
        name: 'LxH_792x192',
        ratio: 4.125
    },
    led_288_420: {
        name: 'LxH_288x420',
        ratio: 0.68571428571
    },
    led_288_384: {
        name: 'LxH_288x384',
        ratio: 0.75
    },
    led_462_320: {
        name: 'LxH_462x320',
        ratio: 1.44375
    },
    led_1440_240: {
        name: 'LxH_1440x240',
        ratio: 6
    },
    led_240_440: {
        name: 'LxH_240x440',
        ratio: 0.54545454545
    },
    led_912_228: {
        name: 'LxH_912x228',
        ratio: 4
    },
    led_512_256: {
        name: 'LxH_512x256',
        ratio: 2
    },
    led_770_512: {
        name: 'LxH_770x512',
        ratio: 1.5
    },
    led_1316_790: {
        name: 'LxH_1316x790',
        ratio: 1.666
    },
    led_1920_540: {
        name: 'LxH_1920x540',
        ratio: 3.56
    },
    led_1152_256: {
        name: 'LxH_1152x256',
        ratio: 4.5
    },
    led_576_320: {
        name: 'LxH_576x320',
        ratio: 1.8
    },
    led_1408_384: {
        name: 'LxH_1408x384',
        ratio: 3.667
    },
    led_864_576: {
        name: 'LxH_864x576',
        ratio: 1.5
    },
    led_288_416: {
        name: 'LxH_288x416',
        ratio: 288 / 416
    },
    led_192_272: {
        name: 'LxH_192x272',
        ratio: 192 / 272
    },
    led_384_272: {
        name: 'LxH_384x272',
        ratio: 384 / 272
    },
    led_512_204: {
        name: 'LxH_512x204',
        ratio: 512 / 204
    },
    led_448_340: {
        name: 'LxH_448x340',
        ratio: 448 / 340
    },
    led_336_255: {
        name: 'LxH_336x255',
        ratio: 336 / 255
    },
    led_768_204: {
        name: 'LxH_768x204',
        ratio: 768 / 204
    },
    led_576_153: {
        name: 'LxH_576x153',
        ratio: 576 / 153
    },
    led_576_144: {
        name: 'LxH_576x144',
        ratio: 576 / 144
    },
    led_1024_136: {
        name: 'LxH_1024x136',
        ratio: 1024 / 136
    },
    led_1024_576: {
        name: 'LxH_1024x576',
        ratio: 1024 / 576
    },
    led_256_448: {
        name: 'LxH_256x448',
        ratio: 256 / 448
    },
    led_768_102: {
        name: 'LxH_768x102',
        ratio: 768 / 102
    },
    led_512_340: {
        name: 'LxH_512x340',
        ratio: 512 / 340
    },
    led_384_255: {
        name: 'LxH_384x255',
        ratio: 384 / 255
    },
    led_576_306: {
        name: 'LxH_576x306',
        ratio: 576 / 306
    },
    led_1920_576: {
        name: 'LxH_1920x576',
        ratio: 1920 / 576
    },
    led_1140_228: {
        name: 'LxH_1140x228',
        ratio: 1140 / 228
    },
    led_512_108: {
        name: 'LxH_512x108',
        ratio: 512 / 108
    },
    led_960_576: {
        name: 'LxH_960x576',
        ratio: 960 / 576
    },
    led_7296_1056: {
        name: 'LxH_7296x1056',
        ratio: 7296 / 1056
    },
    led_3840_1056: {
        name: 'LxH_3840x1056',
        ratio: 7296 / 1056
    },
    led_1536_1024: {
        name: 'LxH_1536x1024',
        ratio: 1536 / 1024
    },
    led_5040_405: {
        name: 'LxH_5040x405',
        ratio: 5040 / 405
    },
    led_4032_288: {
        name: 'LxH_4032x288',
        ratio: 4032 / 288
    },
    led_1536_768: {
        name: 'LxH_1536x768',
        ratio: 1536 / 768
    },
    led_1536_384: {
        name: 'LxH_1536x384',
        ratio: 1536 / 384
    },
    led_640_384: {
        name: 'LxH_640x384'
    },
    led_1824_1056: {
        name: 'LxH_1824x1056'
    },
    led_962_578: {
        name: 'LxH_962x578'
    },
    led_800_480: {
        name: 'LxH_800x480'
    },
    led_896_512: {
        name: 'LxH_896x512'
    },
    led_1008_144: {
        name: 'LxH_1008x144'
    },
    led_2688_384: {
        name: 'LxH_2688x384'
    },
    led_432_288: {
        name: 'LxH_432x288'
    },
    led_1154_385: {
        name: 'LxH_1154x385'
    }
};

export const countries = [
    {
        value: 'Afghanistan',
        label: 'Afghanistan'
    },
    {
        value: 'Afrique Centrale',
        label: 'Afrique Centrale'
    },
    {
        value: 'Afrique du sud',
        label: 'Afrique du Sud'
    },
    {
        value: 'Albanie',
        label: 'Albanie'
    },
    {
        value: 'Algerie',
        label: 'Algerie'
    },
    {
        value: 'Allemagne',
        label: 'Allemagne'
    },
    {
        value: 'Amérique du Sud',
        label: 'Amérique du Sud'
    },
    {
        value: 'Andorre',
        label: 'Andorre'
    },
    {
        value: 'Angola',
        label: 'Angola'
    },
    {
        value: 'Anguilla',
        label: 'Anguilla'
    },
    {
        value: 'Antilles',
        label: 'Antilles'
    },
    {
        value: 'Arabie Saoudite',
        label: 'Arabie Saoudite'
    },
    {
        value: 'Argentine',
        label: 'Argentine'
    },
    {
        value: 'Armenie',
        label: 'Armenie'
    },
    {
        value: 'Australie',
        label: 'Australie'
    },
    {
        value: 'Autriche',
        label: 'Autriche'
    },
    {
        value: 'Azerbaidjan',
        label: 'Azerbaidjan'
    },

    {
        value: 'Bahamas',
        label: 'Bahamas'
    },
    {
        value: 'Bangladesh',
        label: 'Bangladesh'
    },
    {
        value: 'Barbade',
        label: 'Barbade'
    },
    {
        value: 'Bahrein',
        label: 'Bahrein'
    },
    {
        value: 'Belgique',
        label: 'Belgique'
    },
    {
        value: 'Belize',
        label: 'Belize'
    },
    {
        value: 'Benin',
        label: 'Benin'
    },
    {
        value: 'Bermudes',
        label: 'Bermudes'
    },
    {
        value: 'Bielorussie',
        label: 'Bielorussie'
    },
    {
        value: 'Bolivie',
        label: 'Bolivie'
    },
    {
        value: 'Botswana',
        label: 'Botswana'
    },
    {
        value: 'Bhoutan',
        label: 'Bhoutan'
    },
    {
        value: 'Boznie Herzegovine',
        label: 'Boznie Herzegovine'
    },
    {
        value: 'Bresil',
        label: 'Bresil'
    },
    {
        value: 'Brunei',
        label: 'Brunei'
    },
    {
        value: 'Bulgarie',
        label: 'Bulgarie'
    },
    {
        value: 'Burkina Faso',
        label: 'Burkina Faso'
    },
    {
        value: 'Burundi',
        label: 'Burundi'
    },

    {
        value: 'Caiman',
        label: 'Caiman'
    },
    {
        value: 'Cambodge',
        label: 'Cambodge'
    },
    {
        value: 'Cameroun',
        label: 'Cameroun'
    },
    {
        value: 'Canada',
        label: 'Canada'
    },
    {
        value: 'Canaries',
        label: 'Canaries'
    },
    {
        value: 'Cap vert',
        label: 'Cap Vert'
    },
    {
        value: 'Chili',
        label: 'Chili'
    },
    {
        value: 'Chine',
        label: 'Chine'
    },
    {
        value: 'Chypre',
        label: 'Chypre'
    },
    {
        value: 'Colombie',
        label: 'Colombie'
    },
    {
        value: 'Comores',
        label: 'Colombie'
    },
    {
        value: 'Congo',
        label: 'Congo'
    },
    {
        value: 'Congo democratique',
        label: 'Congo democratique'
    },
    {
        value: 'Cook',
        label: 'Cook'
    },
    {
        value: 'Coree du Nord',
        label: 'Coree du Nord'
    },
    {
        value: 'Coree du Sud',
        label: 'Coree du Sud'
    },
    {
        value: 'Costa Rica',
        label: 'Costa Rica'
    },
    {
        value: 'Cote d Ivoire',
        label: 'Côte d Ivoire'
    },
    {
        value: 'Croatie',
        label: 'Croatie'
    },
    {
        value: 'Cuba',
        label: 'Cuba'
    },

    {
        value: 'Danemark',
        label: 'Danemark'
    },
    {
        value: 'Djibouti',
        label: 'Djibouti'
    },
    {
        value: 'Dominique',
        label: 'Dominique'
    },
    {
        value: 'Ecosse',
        label: 'Ecosse'
    },
    {
        value: 'Egypte',
        label: 'Egypte'
    },
    {
        value: 'Emirats Arabes Unis',
        label: 'Emirats Arabes Unis'
    },
    {
        value: 'Equateur',
        label: 'Equateur'
    },
    {
        value: 'Erythree',
        label: 'Erythree'
    },
    {
        value: 'Espagne',
        label: 'Espagne'
    },
    {
        value: 'Estonie',
        label: 'Estonie'
    },
    {
        value: 'Etats Unis',
        label: 'Etats Unis'
    },
    {
        value: 'Ethiopie',
        label: 'Ethiopie'
    },

    {
        value: 'Falkland',
        label: 'Falkland'
    },
    {
        value: 'Feroe',
        label: 'Feroe'
    },
    {
        value: 'Fidji',
        label: 'Fidji'
    },
    {
        value: 'Finlande',
        label: 'Finlande'
    },
    {
        value: 'France',
        label: 'France'
    },
    {
        value: 'Gabon',
        label: 'Gabon'
    },
    {
        value: 'Gambie',
        label: 'Gambie'
    },
    {
        value: 'Georgie',
        label: 'Georgie'
    },
    {
        value: 'Ghana',
        label: 'Ghana'
    },
    {
        value: 'Gibraltar',
        label: 'Gibraltar'
    },
    {
        value: 'Grece',
        label: 'Grece'
    },
    {
        value: 'Grenade',
        label: 'Grenade'
    },
    {
        value: 'Groenland',
        label: 'Groenland'
    },
    {
        value: 'Guadeloupe',
        label: 'Guadeloupe'
    },
    {
        value: 'Guam',
        label: 'Guam'
    },
    {
        value: 'Guatemala',
        label: 'Guatemala'
    },
    {
        value: 'Guernesey',
        label: 'Guernesey'
    },
    {
        value: 'Guinee',
        label: 'Guinee'
    },
    {
        value: 'Guinee Bissau',
        label: 'Guinee Bissau'
    },
    {
        value: 'Guinee equatoriale',
        label: 'Guinee Equatoriale'
    },
    {
        value: 'Guyana',
        label: 'Guyana'
    },
    {
        value: 'Guyane Francaise ',
        label: 'Guyane Francaise'
    },

    {
        value: 'Haiti',
        label: 'Haiti'
    },
    {
        value: 'Hawaii',
        label: 'Hawaii'
    },
    {
        value: 'Honduras',
        label: 'Honduras'
    },
    {
        value: 'Hong Kong',
        label: 'Hong Kong'
    },
    {
        value: 'Hongrie',
        label: 'Hongrie'
    },

    {
        value: 'Inde',
        label: 'Inde'
    },
    {
        value: 'Indonesie',
        label: 'Indonesie'
    },
    {
        value: 'Iran',
        label: 'Iran'
    },
    {
        value: 'Iraq',
        label: 'Iraq'
    },
    {
        value: 'Irlande',
        label: 'Irlande'
    },
    {
        value: 'Islande',
        label: 'Islande'
    },
    {
        value: 'Israel',
        label: 'Israel'
    },
    {
        value: 'Italie',
        label: 'italie'
    },

    {
        value: 'Jamaique',
        label: 'Jamaique'
    },
    {
        value: 'Jan Mayen',
        label: 'Jan Mayen'
    },
    {
        value: 'Japon',
        label: 'Japon'
    },
    {
        value: 'Jersey',
        label: 'Jersey'
    },
    {
        value: 'Jordanie',
        label: 'Jordanie'
    },

    {
        value: 'Kazakhstan',
        label: 'Kazakhstan'
    },
    {
        value: 'Kenya',
        label: 'Kenya'
    },
    {
        value: 'Kirghizstan',
        label: 'Kirghizistan'
    },
    {
        value: 'Kiribati',
        label: 'Kiribati'
    },
    {
        value: 'Koweit',
        label: 'Koweit'
    },

    {
        value: 'Laos',
        label: 'Laos'
    },
    {
        value: 'Lesotho',
        label: 'Lesotho'
    },
    {
        value: 'Lettonie',
        label: 'Lettonie'
    },
    {
        value: 'Liban',
        label: 'Liban'
    },
    {
        value: 'Liberia',
        label: 'Liberia'
    },
    {
        value: 'Liechtenstein',
        label: 'Liechtenstein'
    },
    {
        value: 'Lituanie',
        label: 'Lituanie'
    },
    {
        value: 'Luxembourg',
        label: 'Luxembourg'
    },
    {
        value: 'Lybie',
        label: 'Lybie'
    },

    {
        value: 'Macao',
        label: 'Macao'
    },
    {
        value: 'Macedoine',
        label: 'Macedoine'
    },
    {
        value: 'Madagascar',
        label: 'Madagascar'
    },
    {
        value: 'Madère',
        label: 'Madère'
    },
    {
        value: 'Malaisie',
        label: 'Malaisie'
    },
    {
        value: 'Malawi',
        label: 'Malawi'
    },
    {
        value: 'Maldives',
        label: 'Maldives'
    },
    {
        value: 'Mali',
        label: 'Mali'
    },
    {
        value: 'Malte',
        label: 'Malte'
    },
    {
        value: 'Man',
        label: 'Man'
    },
    {
        value: 'Mariannes du Nord',
        label: 'Mariannes du Nord'
    },
    {
        value: 'Maroc',
        label: 'Maroc'
    },
    {
        value: 'Marshall',
        label: 'Marshall'
    },
    {
        value: 'Martinique',
        label: 'Martinique'
    },
    {
        value: 'Maurice',
        label: 'Maurice'
    },
    {
        value: 'Mauritanie',
        label: 'Mauritanie'
    },
    {
        value: 'Mayotte',
        label: 'Mayotte'
    },
    {
        value: 'Mexique',
        label: 'Mexique'
    },
    {
        value: 'Micronesie',
        label: 'Micronesie'
    },
    {
        value: 'Midway',
        label: 'Midway'
    },
    {
        value: 'Moldavie',
        label: 'Moldavie'
    },
    {
        value: 'Monaco',
        label: 'Monaco'
    },
    {
        value: 'Mongolie',
        label: 'Mongolie'
    },
    {
        value: 'Montserrat',
        label: 'Montserrat'
    },
    {
        value: 'Mozambique',
        label: 'Mozambique'
    },

    {
        value: 'Namibie',
        label: 'Namibie'
    },
    {
        value: 'Nauru',
        label: 'Nauru'
    },
    {
        value: 'Nepal',
        label: 'Nepal'
    },
    {
        value: 'Nicaragua',
        label: 'Nicaragua'
    },
    {
        value: 'Niger',
        label: 'Niger'
    },
    {
        value: 'Nigeria',
        label: 'Nigeria'
    },
    {
        value: 'Niue',
        label: 'Niue'
    },
    {
        value: 'Norfolk',
        label: 'Norfolk'
    },
    {
        value: 'Norvege',
        label: 'Norvege'
    },
    {
        value: 'Nouvelle Caledonie',
        label: 'Nouvelle Caledonie'
    },
    {
        value: 'Nouvelle Zelande',
        label: 'Nouvelle Zelande'
    },

    {
        value: 'Oman',
        label: 'Oman'
    },
    {
        value: 'Ouganda',
        label: 'Ouganda'
    },
    {
        value: 'Ouzbekistan',
        label: 'Ouzbekistan'
    },

    {
        value: 'Pakistan',
        label: 'Pakistan'
    },
    {
        value: 'Palau',
        label: 'Palau'
    },
    {
        value: 'Palestine',
        label: 'Palestine'
    },
    {
        value: 'Panama',
        label: 'Panama'
    },
    {
        value: 'Papouasie Nouvelle Guinee',
        label: 'Papouasie Nouvelle Guinee'
    },
    {
        value: 'Paraguay',
        label: 'Paraguay'
    },
    {
        value: 'Pays Bas',
        label: 'Pays Bas'
    },
    {
        value: 'Perou',
        label: 'Perou'
    },
    {
        value: 'Philippines',
        label: 'Philippines'
    },
    {
        value: 'Pologne',
        label: 'Pologne'
    },
    {
        value: 'Polynesie',
        label: 'Polynesie'
    },
    {
        value: 'Porto Rico',
        label: 'Porto Rico'
    },
    {
        value: 'Portugal',
        label: 'Portugal'
    },

    {
        value: 'Qatar',
        label: 'Qatar'
    },

    {
        value: 'Republique Dominicaine',
        label: 'Republique Dominicaine'
    },
    {
        value: 'Republique Tcheque',
        label: 'Republique Tcheque'
    },
    {
        value: 'Reunion',
        label: 'Reunion'
    },
    {
        value: 'Roumanie',
        label: 'Roumanie'
    },
    {
        value: 'Royaume Uni',
        label: 'Royaume Uni'
    },
    {
        value: 'Russie',
        label: 'Russie'
    },
    {
        value: 'Rwanda',
        label: 'Rwanda'
    },

    {
        value: 'Sahara Occidental',
        label: 'Sahara Occidental'
    },
    {
        value: 'Sainte Lucie',
        label: 'Sainte Lucie'
    },
    {
        value: 'Saint Marin',
        label: 'Saint Marin'
    },
    {
        value: 'Salomon',
        label: 'Salomon'
    },
    {
        value: 'Salvador',
        label: 'Salvador'
    },
    {
        value: 'Samoa Occidentales',
        label: 'Samoa Occidentales'
    },
    {
        value: 'Samoa Americaine',
        label: 'Samoa Americaine'
    },
    {
        value: 'Sao Tome et Principe',
        label: 'Sao Tome et Principe'
    },
    {
        value: 'Senegal',
        label: 'Senegal'
    },
    {
        value: 'Seychelles',
        label: 'Seychelles'
    },
    {
        value: 'Sierra Leone',
        label: 'Sierra Leone'
    },
    {
        value: 'Singapour',
        label: 'Singapour'
    },
    {
        value: 'Slovaquie',
        label: 'Slovaquie'
    },
    {
        value: 'Slovenie',
        label: 'Slovenie'
    },
    {
        value: 'Somalie',
        label: 'Somalie'
    },
    {
        value: 'Soudan',
        label: 'Soudan'
    },
    {
        value: 'Sri Lanka',
        label: 'Sri Lanka'
    },
    {
        value: 'Suede',
        label: 'Suede'
    },
    {
        value: 'Suisse',
        label: 'Suisse'
    },
    {
        value: 'Surinam',
        label: 'Surinam'
    },
    {
        value: 'Swaziland',
        label: 'Swaziland'
    },
    {
        value: 'Syrie',
        label: 'Syrie'
    },

    {
        value: 'Tadjikistan',
        label: 'Tadjikistan'
    },
    {
        value: 'Taiwan',
        label: 'Taiwan'
    },
    {
        value: 'Tonga',
        label: 'Tonga'
    },
    {
        value: 'Tanzanie',
        label: 'Tanzanie'
    },
    {
        value: 'Tchad',
        label: 'Tchad'
    },
    {
        value: 'Thailande',
        label: 'Thailande'
    },
    {
        value: 'Tibet',
        label: 'Tibet'
    },
    {
        value: 'Timor Oriental',
        label: 'Timor Oriental'
    },
    {
        value: 'Togo',
        label: 'Togo'
    },
    {
        value: 'Trinite et Tobago',
        label: 'Trinite et Tobago'
    },
    {
        value: 'Tristan da cunha',
        label: 'Tristan de cuncha'
    },
    {
        value: 'Tunisie',
        label: 'Tunisie'
    },
    {
        value: 'Turkmenistan',
        label: 'Turmenistan'
    },
    {
        value: 'Turquie',
        label: 'Turquie'
    },

    {
        value: 'Ukraine',
        label: 'Ukraine'
    },
    {
        value: 'Uruguay',
        label: 'Uruguay'
    },

    {
        value: 'Vanuatu',
        label: 'Vanuatu'
    },
    {
        value: 'Vatican',
        label: 'Vatican'
    },
    {
        value: 'Venezuela',
        label: 'Venezuela'
    },
    {
        value: 'Vierges Americaines',
        label: 'Vierges Americaines'
    },
    {
        value: 'Vierges Britanniques',
        label: 'Vierges Britanniques'
    },
    {
        value: 'Vietnam',
        label: 'Vietnam'
    },

    {
        value: 'Wake',
        label: 'Wake'
    },
    {
        value: 'Wallis et Futuma',
        label: 'Wallis et Futuma'
    },

    {
        value: 'Yemen',
        label: 'Yemen'
    },
    {
        value: 'Yougoslavie',
        label: 'Yougoslavie'
    },

    {
        value: 'Zambie',
        label: 'Zambie'
    },
    {
        value: 'Zimbabwe',
        label: 'Zimbabwe'
    }
];
