import React, { useState, useEffect } from 'react';
import { Panel, Table } from 'react-bootstrap';
import { fetchApi } from '../../Services/NetworkServcice';

const nameContains = (shop, filter) => shop.name.toUpperCase().indexOf(filter) !== -1;
const signContains = (shop, filter) => shop.Sign.name.toUpperCase().indexOf(filter) !== -1;
const groupContains = (shop, filter) => shop.Group.name.toUpperCase().indexOf(filter) !== -1;

const filterContains = (shop, filterRaw) => {
    const filter = filterRaw.trim().toUpperCase();

    if (filter.length <= 1) {
        return true;
    }

    return nameContains(shop, filter) || signContains(shop, filter) || groupContains(shop, filter);
};

const Shops = () => {
    const [shops, setShops] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        fetchApi('/admin/shops')
            .then((res) => res.json())
            .then(({ shops }) => setShops(shops));
    }, []);

    const filteredShops = shops.filter((s) => filterContains(s, filter));

    return (
        <Panel>
            <div className="leoTopBar">
                <span>{filteredShops.length} Magasins</span>
                <div>
                    <input
                        type="text"
                        className="form-control"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        placeholder="Filtre"
                    />
                </div>
            </div>
            <Table striped bordered condensed hover>
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Enseigne</th>
                        <th>Groupe</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredShops.map((s) => (
                        <tr key={s.id}>
                            <td>{s.name}</td>
                            <td>{s.Sign.name}</td>
                            <td>{s.Group.name}</td>
                            <td>
                                <button
                                    className="btn btn-default ml-1 btn_desa_clone"
                                    onClick={() => {
                                        fetchApi(`/admin/loginAs/${s.id}`)
                                            .then((res) => res.json())
                                            .then((res) => {
                                                if (res.success === false) {
                                                    return window.alert('Erreur');
                                                }

                                                localStorage.setItem('old_token', localStorage.getItem('id_token'));
                                                localStorage.setItem('old_role', 'admin');
                                                localStorage.setItem('id_token', res.token);

                                                window.location = '/';
                                            });
                                    }}
                                >
                                    Login
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Panel>
    );
};

export default Shops;
