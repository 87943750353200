import React, { useState } from 'react';
import Select from 'react-select';
import constants from '../../Constants';

const getKeyFromValue = (obj, value) => {
    for (const k of Object.keys(obj)) {
        if (obj[k].indexOf(value) !== -1) {
            return k;
        }
    }

    return '';
};

const ChooseCategory = (props) => {
    const [category, setCategory] = useState('Andouilles');

    const key = getKeyFromValue(props.options[category] || [], props.filename);
    const nb = key ? props.options[category][key].length : 0;
    const selected = key ? props.options[category][key].indexOf(props.filename) : -1;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Choisir une catégorie</label>
                        <Select
                            options={[
                                'Andouilles',
                                'Andouillettes',
                                'Boudins blancs',
                                'Boudins noirs',
                                'Charcuterie pâtissières',
                                'Jambon cuits',
                                'Jambon blanc',
                                'Jambons secs et salaisons sèches',
                                'Lardons et aides culinaires',
                                'Pâtés et Terrines',
                                'Pieds et Paquets',
                                'Rillettes',
                                'Saucisses et saucissons secs',
                                'Saucisses et saucissons cuits ou à cuire',
                                'Tripes et Tripoux'
                            ].map((f) => ({
                                label: f,
                                value: f
                            }))}
                            onChange={(e) => setCategory(e.value)}
                            value={{
                                label: category,
                                value: category
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Choisir un produit</label>
                        <Select
                            options={Object.keys(props.options[category] || [])
                                .sort((a, b) => a.localeCompare(b))
                                .map((f) => ({
                                    label: f,
                                    value: f
                                }))}
                            onChange={(e) => {
                                props.handleSelectOption(e.value, category);
                                props.handleSelectPiece(e.value);
                            }}
                            value={{
                                label: key,
                                value: key
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sub">Nom</label>
                        <input
                            type="text"
                            value={props.category}
                            onChange={(e) => props.handleSelectPiece(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sub">Sous-Titre</label>
                        <input
                            type="text"
                            name="subtitle"
                            value={props.subtitle}
                            onChange={(e) => props.handleSetSub(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            {!key || (
                <div
                    className="controls"
                    style={{
                        display: nb > 1 ? 'block' : 'none'
                    }}
                >
                    <p>
                        Sélectionnez une image <i className="fa fa-angle-double-right ip5" />
                    </p>
                    <div>
                        {props.options[category][key].map((f, i) => (
                            <img
                                className={i === selected ? 'fruitSelection active' : 'fruitSelection'}
                                key={f}
                                src={`${constants.cloud_storage}/Assets/Library/Delicatessen/${f}`}
                                onClick={() => props.handleSwitchOption(f)}
                                height="100"
                                alt="delicatessen"
                            />
                        ))}
                    </div>
                    <hr className="visible-xs" style={{ marginBottom: '-10px' }} />
                </div>
            )}
        </React.Fragment>
    );
};

export default ChooseCategory;
