import React from 'react';
import { PreviewComponent } from './PreviewFruit';

const PreviewBakery = props => (
    <div className="PreviewFruit">
        <div className={'CenterFruit o' + props.orientation}>
            <div className="PreviewFruitContainer">
                <div className={'o' + props.orientation}>
                    <PreviewComponent rayon="bakery" {...props} />
                </div>
            </div>
        </div>
    </div>
);

export default PreviewBakery;
