import React from 'react';
import { countries } from '../../Constants';
import Select from 'react-select';
import { Col } from 'react-bootstrap';

const CheeseAttributes = (props) => {
    let availableLabels = ['AOP.png', 'AOC.png', 'upc.png'];

    if (props.originCountry === 'France') {
        availableLabels = [...availableLabels];
    }

    return (
        <div>
            <div className="form-group">
                <label htmlFor="originCountry">Pays d'origine (obligatoire)</label>
                <Select
                    options={[
                        ...[
                            {
                                value: 'France',
                                label: 'France'
                            },
                            {
                                value: 'Espagne',
                                label: 'Espagne'
                            },
                            {
                                value: 'Argentine',
                                label: 'Argentine'
                            },
                            {
                                value: 'Bresil',
                                label: 'Bresil'
                            },
                            {
                                value: 'Ecosse',
                                label: 'Ecosse'
                            },
                            {
                                value: 'Irlande',
                                label: 'Irlande'
                            },
                            {
                                value: 'Uruguay',
                                label: 'Uruguay'
                            },
                            {
                                value: 'Europe',
                                label: 'Europe'
                            },
                            {
                                value: '---------------------',
                                label: '---------------------',
                                disabled: true
                            }
                        ],
                        ...countries
                    ]}
                    name="originCountry"
                    className={props.originCountry ? '' : 'rSelectError'}
                    onChange={(e) =>
                        props.handleChange({
                            target: {
                                name: 'originCountry',
                                value: e.value
                            }
                        })
                    }
                    isOptionDisabled={(o) => o.disabled === true}
                    value={{
                        label: props.originCountry,
                        value: props.originCountry
                    }}
                />
            </div>
            <div className="form-group">
                <label>Choisir :</label>
                <Select
                    options={[
                        {
                            value: 'Lait cru',
                            label: 'Lait cru'
                        },
                        {
                            value: 'Lait pasteurisé',
                            label: 'Lait pasteurisé'
                        },
                        {
                            value: 'Lait thermisé',
                            label: 'Lait thermisé'
                        },
                        {
                            value: 'Pâte pressée',
                            label: 'Pâte pressée'
                        }
                    ]}
                    onChange={(e) =>
                        props.handleChange({
                            target: {
                                name: 'milk',
                                value: e.value
                            }
                        })
                    }
                    value={{
                        label: props.milk,
                        value: props.milk
                    }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="label">Label</label>
                <div className="LabelsHorizontalScroll">
                    <Col
                        lg={2}
                        md={3}
                        xs={4}
                        className={'labelCol' + (props.labels.length === 0 ? ' active' : '')}
                        onClick={() => props.handleSetLabels([])}
                    >
                        <div className="noLabel">
                            <i className="fa fa-ban"></i>
                            <span>Aucun</span>
                        </div>
                    </Col>
                    {availableLabels.map((l) => (
                        <Col
                            lg={2}
                            md={3}
                            xs={4}
                            key={l}
                            onClick={() => {
                                if (props.labels.indexOf(l) === -1) {
                                    props.handleSetLabels([...props.labels, l].slice(0, 2));
                                } else {
                                    props.handleSetLabels(props.labels.filter((lb) => lb !== l));
                                }
                            }}
                            className={'labelCol' + (props.labels.indexOf(l) !== -1 ? ' active' : '')}
                        >
                            <img className="labelImg" src={`/labels/${l}`} alt={l} />
                        </Col>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CheeseAttributes;
