import React, { useState } from 'react';

export const CategoriesSidebarBlock = ({ categories, selectedCategoryPage, selectCategory, onNewCategory }) => {
    const [search, setSearch] = useState('');

    let filteredCategories = categories;

    if (search.trim().length >= 2) {
        filteredCategories = categories.filter(
            ({ name }) => name.toLowerCase().indexOf(search.trim().toLocaleLowerCase()) !== -1
        );
    }

    return (
        <div className="card home-editor-cards admin-mar">
            <input
                type="text"
                className="form-control"
                placeholder="Rechercher..."
                onChange={(e) => setSearch(e.target.value)}
            />
            <div className={'category manual' + (selectedCategoryPage === 'ALL' ? ' selected' : '')}>
                <div className="cat" style={{ cursor: 'pointer' }} onClick={() => selectCategory('ALL')}>
                    <i className="fa fa-calendar"></i>Marronniers
                </div>
                <button className="btn btn-info btn-flat" onClick={() => onNewCategory('mar')}>
                    <i className="fa fa-plus"></i>
                </button>
            </div>
            {filteredCategories
                .filter(({ type }) => !type || type === 'mar')
                .map((c) => (
                    <div className="category" key={c._id} onClick={() => selectCategory(c._id)}>
                        <div className={'link' + (selectedCategoryPage === c._id ? ' active' : '')}>{c.name}</div>
                    </div>
                ))}
            <div className="category manual">
                <div className="cat">
                    <i className="fa fa-percentage"></i>Promotions
                </div>
                <button className="btn btn-info btn-flat" onClick={() => onNewCategory('pro')}>
                    <i className="fa fa-plus"></i>
                </button>
            </div>
            {filteredCategories
                .filter(({ type }) => type === 'pro')
                .map((c) => (
                    <div className="category" key={c._id} onClick={() => selectCategory(c._id)}>
                        <div className={'link' + (selectedCategoryPage === c._id ? ' active' : '')}>{c.name}</div>
                    </div>
                ))}
            <div className="category manual">
                <div className="cat">
                    <i className="fa fa-concierge-bell"></i>Services
                </div>
                <button className="btn btn-info btn-flat" onClick={() => onNewCategory('svc')}>
                    <i className="fa fa-plus"></i>
                </button>
            </div>
            {filteredCategories
                .filter(({ type }) => type === 'svc')
                .map((c) => (
                    <div className="category" key={c._id} onClick={() => selectCategory(c._id)}>
                        <div className={'link' + (selectedCategoryPage === c._id ? ' active' : '')}>{c.name}</div>
                    </div>
                ))}
        </div>
    );
};
