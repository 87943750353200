import '../Assets/home.css';

import React, { useEffect, useState } from 'react';
import EditorSlides from '../Components/Editor/EditorSlides';
import { fetchApi } from '../Services/NetworkServcice';
import swal from 'sweetalert';
import Switch from 'react-switch';
import Calendar from 'react-calendar';
import { Link, Prompt } from 'react-router-dom';
import { formats } from '../Constants';
import dateFormat from 'dateformat';
import HeaderNotifications from '../Components/HeaderNotifications';
import EditorNewModal from '../Components/Editor/EditorNewModal';
import EditorDateModal from '../Components/Editor/EditorDateModal';
import ReactTags from 'react-tag-autocomplete';
import PlanningModal from '../Components/Editor/PlanningModal';
import xlsxParse from 'xlsx-parse-json';

export const validateDuration = (maxDuration, input, agentId) => {
    if (agentId === 8 || agentId === 10) return 10;

    if (!input) {
        return 5;
    }

    if (input.toString().trim().length === 0) {
        return 5;
    }

    const number = parseInt(input);

    if (isNaN(input)) {
        return 5;
    }

    if (number <= 0) {
        return 1;
    }

    if (number > maxDuration) {
        return maxDuration;
    }

    return number;
};

const filterScreens = (s, filter) => {
    if (filter.length === 0) {
        return true;
    }

    let matches = false;
    for (const sf of filter) {
        if (sf.type === 'shop') {
            matches |= s.Shop.name === sf.id;
        } else if (sf.type === 'zip') {
            matches |= s.Shop.zip === sf.id;
        } else if (sf.type === 'city') {
            matches |= s.Shop.city === sf.id;
        } else if (sf.type === 'group') {
            matches |= s.Group.name === sf.id;
        } else if (sf.type === 'address') {
            matches |= s.Shop.address === sf.id;
        } else if (sf.type === 'region') {
            matches |= s.Shop.region === sf.id;
        } else if (sf.type === 'departement') {
            matches |= s.Shop.departement === sf.id;
        } else if (sf.type === 'sign') {
            matches |= s.Shop.Sign.id === sf.id;
        }
    }

    return matches;
};

const getScreenIdsFromXls = async (screenList, xls) => {
    const devices = new Set();

    return xlsxParse
        .onFileSelection(xls)
        .then((data) => {
            const xlsList = Object.values(data)[0];

            if (!xlsList) {
                return null;
            }

            for (const entry of xlsList) {
                if (!entry.id_shop) {
                    continue;
                }

                const id_shop = parseInt(entry.id_shop);

                for (const device of screenList) {
                    if (device.ShopId === id_shop) {
                        devices.add(device.id);
                    }
                }
            }

            return [...devices];
        })
        .catch(() => Promise.resolve(null));
};

const TabSequenceDetails = ({
    sequence,
    screens,
    virtualDevices,
    userObject,
    isNew = false,
    name = '',
    sequences = [],
    pushHistory = () => null,
    nameEdition = false,
    isSubsequence = false,
    signId = null,
    setScrollState = () => null
}) => {
    const [state, setState] = useState({
        i: null,
        edited: false,
        modalVisible: false,
        forceDateEdition: false,
        modalDateVisible: false,
        modalPlanningVisible: false,
        creatives: [],
        devices: [],
        virtualDevices: [],
        planning: [],
        isPlanned: false,
        name,
        startDate: null,
        nameEdition,
        screenFilter: [],
        suggestions: [],
        buttonSaveDisabled: false
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            creatives: [...sequence.creatives],
            devices: [...sequence.devices],
            virtualDevices: [...(sequence.virtualDevices || [])],
            name: sequence.name,
            startDate: sequence.startDate || null,
            planning: sequence.planning,
            isPlanned: !!sequence.isPlanned,
            edited: false
        }));
    }, [sequence]);

    useEffect(() => {
        if (!isSubsequence) {
            return;
        }

        const suggestions = [];

        screens.forEach((d) => {
            const entries = [
                {
                    type: 'sign',
                    name: `${d.Shop.Sign.name} (Enseigne)`,
                    id: d.Shop.Sign.name
                },
                {
                    type: 'shop',
                    name: `${d.Shop.name} (Shop)`,
                    id: d.Shop.name
                },
                {
                    type: 'zip',
                    name: `${d.Shop.zip}`,
                    id: d.Shop.zip
                },
                {
                    type: 'city',
                    name: `${d.Shop.city} (Ville)`,
                    id: d.Shop.city
                },
                {
                    type: 'departement',
                    name: `${d.Shop.departement} (Departement)`,
                    id: d.Shop.departement
                },
                {
                    type: 'region',
                    name: `${d.Shop.region} (Region)`,
                    id: d.Shop.region
                },
                {
                    type: 'address',
                    name: `${d.Shop.name} / ${d.Shop.Sign.name} / ${d.Shop.address} / ${d.Shop.zip} ${d.Shop.city} (Adresse)`,
                    id: d.Shop.address
                },
                {
                    type: 'group',
                    name: `${d.Group?.name} (Groupe)`,
                    id: d.Group?.name
                }
            ];

            entries.forEach((e) => {
                if (!suggestions.find((s) => s.type === e.type && s.id === e.id)) {
                    if (e.id) {
                        suggestions.push(e);
                    }
                }
            });

            setState((prevState) => ({ ...prevState, suggestions }));
        });
    }, [screens, isSubsequence]);

    const handleAddExpirationClick = (type, i) => {
        if (type !== 'FreeTemplateInstance' && type !== 'Template' && type !== 'UserCreative' && type !== 'Widget' && type !== 'RSS') {
            return handleSlideClick(i);
        }

        setState((prevState) => ({ ...prevState, modalDateVisible: true, i, forceDateEdition: true }));
    };

    const removeSlide = (num) => {
        swal({
            title: 'Supprimer cette affiche?',
            text: 'Êtes-vous sûr ?',
            icon: 'warning',
            buttons: ['Annuler', 'OK'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                setState((prevState) => ({
                    ...prevState,
                    creatives: state.creatives.filter((_, i) => i !== num),
                    edited: true,
                    i: null
                }));

                return true;
            }
        });
    };

    const handleSaveCrea = (crea) => {
        if (state.i !== null) {
            const creatives = state.creatives;
            creatives[state.i] = { ...creatives[state.i], ...crea };
            setState((prevState) => ({ ...prevState, creatives, edited: true }));
        } else {
            setState((prevState) => ({
                ...prevState,
                creatives: [...state.creatives, crea],
                edited: true
            }));
        }
    };

    const duplicateSlide = (crea) => {
        if (state.creatives.length >= userObject.role.Agent.maxUserSlides) {
            return swal({
                title: 'Erreur',
                text: "Pas assez d'espace dans la séquence",
                icon: 'error'
            });
        }

        if (crea.creaType === 'FreeTemplateInstance' || crea.creaType === 'Template') {
            return swal({
                title: 'Erreur',
                text: 'Impossible de dupliquer cette créative',
                icon: 'error'
            });
        }

        const newCrea = {
            ...crea,
            creative: {
                ...crea.creative
            }
        };

        delete newCrea._id;
        delete newCrea.creationDate;
        delete newCrea.creative._id;
        delete newCrea.creative._v;

        setState((prevState) => ({
            ...prevState,
            creatives: [...state.creatives, crea],
            edited: true
        }));
    };

    const handleSlideClick = (i) => {
        setState((prevState) => ({ ...prevState, i, modalVisible: true, forceDateEdition: false }));
        setScrollState(true);
    };

    const handleSave = () => {
        setState((prevState) => ({
            ...prevState,
            buttonSaveDisabled: true
        }));

        if (!!state.startDate && state.virtualDevices.length > 0) {
            setState((prevState) => ({
                ...prevState,
                buttonSaveDisabled: false
            }));

            return swal({
                title: 'Erreur',
                text: "Vous ne pouvez pas différer le démarrage d'une séquence sur un écran hors DoohYouLike.",
                icon: 'error'
            });
        }

        if (isNew) {
            if (!!state.startDate && state.devices.length === 0) {
                setState((prevState) => ({
                    ...prevState,
                    buttonSaveDisabled: false
                }));

                return swal({
                    title: 'Erreur',
                    text: 'Vous devez sélectionner au moins un écran pour une planification',
                    icon: 'error'
                });
            }

            fetchApi(`/sequences${isSubsequence ? '/subsequences' : ''}`, {
                method: 'POST',
                body: JSON.stringify({
                    ...sequence,
                    creatives: state.creatives.map((c) => {
                        if (c.creaType !== 'video') {
                            return {
                                ...c,
                                duration: validateDuration(
                                    userObject.role.Agent.maxUserSlideLength,
                                    c.duration,
                                    userObject.role.Agent.id
                                )
                            };
                        }

                        return c;
                    }),
                    devices: state.devices,
                    virtualDevices: state.virtualDevices,
                    startDate: state.startDate,
                    name: state.name,
                    isPlanned: state.isPlanned,
                    planning: state.planning,
                    signId
                })
            }).then(async (res) => {
                if (res.status === 204) {
                    setState({ ...state, edited: false });

                    await swal({
                        title: 'Succès',
                        text: 'La séquence a bien été créée !',
                        icon: 'success'
                    });

                    pushHistory('/');
                } else {
                    await swal({
                        title: 'Erreur',
                        text: `Une erreur est survenue lors de la création de votre séquence. Merci de vérifier les affiches et paramètres. Il se peut que votre version du logiciel ne soit pas à jour. Dans ce cas vous pouvez rafraichir la page avec les touches [CTRL]+[Shift]+[R] (ou [Command]+[Shift]+[R] sur macOS) de votre clavier. Informations sur l'erreur: code: ${res.status}`,
                        icon: 'warning'
                    });
                }
            });
        } else {
            fetchApi(`/sequences${isSubsequence ? '/subsequences' : ''}/${sequence._id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    ...sequence,
                    creatives: state.creatives.map((c) => {
                        if (c.creaType !== 'video') {
                            return {
                                ...c,
                                duration: validateDuration(
                                    userObject.role.Agent.maxUserSlideLength,
                                    c.duration,
                                    userObject.role.Agent.id
                                )
                            };
                        }

                        return c;
                    }),
                    devices: state.devices,
                    virtualDevices: state.virtualDevices,
                    startDate: state.startDate,
                    name: state.name,
                    isPlanned: state.isPlanned,
                    planning: state.planning,
                    signId
                })
            }).then(async (res) => {
                if (res.status === 200) {
                    setState({ ...state, edited: false });

                    await swal({
                        title: 'Succès',
                        text: 'La séquence a bien été mise à jour !',
                        icon: 'success'
                    });

                    pushHistory('/');
                } else if (res.status === 400) {
                    const json = await res.json();
                    await swal({
                        title: 'Erreur',
                        text: `Une erreur est survenue lors de la création de votre séquence. Merci de vérifier les affiches et paramètres. Il se peut que votre version du logiciel ne soit pas à jour. Dans ce cas vous pouvez rafraichir la page avec les touches [CTRL]+[Shift]+[R] (ou [Command]+[Shift]+[R] sur macOS) de votre clavier. Informations sur l'erreur: code: ${
                            res.status
                        }, errors: ${json.errors.reduce(
                            (acc, e) => `${acc}, ${e}`
                        )}. Vous pouvez nous transmettre une copie écran de ce message si le problème persiste.`,
                        icon: 'warning'
                    });
                } else {
                    await swal({
                        title: 'Erreur',
                        text: `Une erreur est survenue lors de la création de votre séquence. Merci de vérifier les affiches et paramètres. Il se peut que votre version du logiciel ne soit pas à jour. Dans ce cas vous pouvez rafraichir la page avec les touches [CTRL]+[Shift]+[R] (ou [Command]+[Shift]+[R] sur macOS) de votre clavier. Informations sur l'erreur: code: ${res.status}. Vous pouvez nous transmettre une copie écran de ce message si le problème persiste.`,
                        icon: 'warning'
                    });
                }
            });
        }
    };

    const screensMatching = screens
        .filter(({ orientation }) => orientation === sequence.orientation)
        .filter((s) => filterScreens(s, state.screenFilter));

    return (
        <div className="sequence-details">
            <Prompt
                when={state.edited}
                message="Certains changements n'ont pas été enregistrés, voulez-vous vraiment quitter?"
            />
            <div className="mobile-buttons">
                <div className={'btn-save' + (state.edited ? ' blink' : '')} onClick={handleSave}>
                    <i className="fa fa-save"></i>
                </div>
                <Link to="/" className="btn-ret">
                    <i className="fa fa-chevron-left"></i>
                </Link>
            </div>
            <EditorNewModal
                show={state.modalVisible}
                onClose={() => {
                    setState((prevState) => ({ ...prevState, modalVisible: false, forceDateEdition: false, i: null }));
                    setScrollState(false);
                }}
                orientation={sequence.orientation}
                onSaveUserSlide={handleSaveCrea}
                onAddMultipleCreas={(creas) => {
                    if (creas.length + state.creatives.length > userObject.role.Agent.maxUserSlides) {
                        return swal({
                            title: 'Erreur',
                            text: "Pas assez d'espace dans la séquence",
                            icon: 'error'
                        });
                    }

                    setState((prevState) => ({
                        ...prevState,
                        creatives: [...state.creatives, ...creas],
                        edited: true
                    }));
                }}
                creatives={state.creatives}
                userObject={userObject}
                selectedIndex={state.i}
                selectedSign={signId}
                isSubsequence={isSubsequence}
                allowSlidesDurationChange={userObject.role.Agent.allowSlidesDurationChange}
                maxDuration={userObject.role.Agent.maxUserSlideLength || 5}
            />
            <EditorDateModal
                creatives={state.creatives}
                i={state.i}
                onClose={() => setState({ ...state, modalDateVisible: false, forceDateEdition: false })}
                visible={state.modalDateVisible}
                extended={true}
                onSave={handleSaveCrea}
                isSubsequence={isSubsequence}
                allowSlidesDurationChange={userObject.role.Agent.allowSlidesDurationChange}
                maxDuration={userObject.role.Agent.maxUserSlideLength || 5}
            />
            <PlanningModal
                onClose={() => setState((prevState) => ({ ...prevState, modalPlanningVisible: false }))}
                onSave={(planning) =>
                    setState((prevState) => ({ ...prevState, planning, modalPlanningVisible: false }))
                }
                show={state.modalPlanningVisible}
                devices={state.devices.map((sid) => screens.find(({ id }) => id === sid))}
                sequences={sequences}
                currentSequenceId={sequence._id}
                planning={state.planning}
            />
            <div className="card seq-editor">
                <div className="flex center">
                    <div className="title-seq">
                        {state.nameEdition ? (
                            <input
                                type="text"
                                value={state.name}
                                onChange={(e) => setState({ ...state, name: e.target.value })}
                                onKeyDown={(e) => e.key === 'Enter' && setState({ ...state, nameEdition: false })}
                            />
                        ) : (
                            <h3>
                                {state.name}{' '}
                                {state.name === 'Défaut' || (
                                    <i
                                        className="fa fa-pen"
                                        onClick={() => setState({ ...state, nameEdition: true })}
                                    ></i>
                                )}
                            </h3>
                        )}
                        <p>
                            <i className="fa fa-images"></i> Format : {formats[sequence.orientation || 'portrait'].name}{' '}
                            - {sequence.creatives.length} affiche{sequence.creatives.length === 1 ? '' : 's'}
                        </p>
                        <p>
                            {!sequence.devices.length || <i className="fa fa-tv"></i>}
                            <span>
                                {sequence.devices.length === 0 || (
                                    <React.Fragment>
                                        {' '}
                                        {sequence.devices.length} Ecran
                                        {sequence.devices.length <= 1 ? '' : 's'} :{' '}
                                        <strong>
                                            {
                                                (
                                                    screens.find(({ id }) => id === sequence.devices[0]) || {
                                                        name: 'Inconnu'
                                                    }
                                                ).name
                                            }
                                        </strong>
                                    </React.Fragment>
                                )}
                                {sequence.devices
                                    .slice(1, 4)
                                    .map((s) => screens.find(({ id }) => id === s) || { name: 'Inconnu', id: s })
                                    .map((s) => (
                                        <strong key={s.id}>, {s.name}</strong>
                                    ))}
                                {sequence.devices.length < 4 || ', ...'}
                            </span>
                        </p>
                    </div>
                    <button
                        onClick={handleSave}
                        disabled={state.buttonSaveDisabled}
                        className={'btn-save' + (state.edited ? ' blink' : '')}
                    >
                        <i className="fa fa-save"></i>
                        Enregistrer
                    </button>
                </div>
            </div>
            <div className="flex">
                <div className="home-editor">
                    <div className="card">
                        <h4 className="card-title">Ajoutez ou supprimez des affiches dans la séquence</h4>
                        <EditorSlides
                            creatives={state.creatives}
                            orientation={sequence.orientation}
                            handleAddExpirationClick={handleAddExpirationClick}
                            handleSlideClick={handleSlideClick}
                            onDuplicate={duplicateSlide}
                            onClickNew={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    i: null,
                                    modalVisible: true
                                }));

                                setScrollState(true);
                            }}
                            removeSlide={removeSlide}
                            maxUserSlides={userObject.role.Agent.maxUserSlides}
                            maxDuration={userObject.role.Agent.maxUserSlideLength}
                            setCreatives={(creatives) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    creatives,
                                    edited: true
                                }))
                            }
                            allowSlidesDurationChange={userObject.role.Agent.allowSlidesDurationChange}
                        />
                    </div>
                </div>
                <div className="home-editor-cards">
                    <div className="card">
                        <h4 style={{ marginBottom: 24 }}>Assignez la séquence à un ou plusieurs écrans:</h4>
                        {!isSubsequence || (
                            <div style={{ marginBottom: 24, marginTop: -8 }}>
                                <ReactTags
                                    tags={state.screenFilter}
                                    suggestions={state.suggestions}
                                    handleAddition={(e) =>
                                        setState({
                                            ...state,
                                            screenFilter: [...state.screenFilter, e]
                                        })
                                    }
                                    handleDelete={(e) =>
                                        setState({
                                            ...state,
                                            screenFilter: state.screenFilter.filter((_, i) => i !== e)
                                        })
                                    }
                                />
                                <div className="screen">
                                    <div className="flex center">
                                        <div
                                            onClick={() => {
                                                if (state.devices.length === screensMatching.length) {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        devices: [],
                                                        edited: true
                                                    }));
                                                } else {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        devices: [...screensMatching.map(({ id }) => id)],
                                                        edited: true
                                                    }));
                                                }
                                            }}
                                            className={
                                                `checkbox` +
                                                (state.devices.length === screensMatching.length ? ' checked' : '')
                                            }
                                        ></div>
                                        <span>Sélectionner tous</span>
                                    </div>
                                    <p className="currently"> </p>
                                </div>
                                <div className="screen">
                                    <input
                                        type="file"
                                        onChange={async (e) => {
                                            const data = await getScreenIdsFromXls(screensMatching, e.target.files[0]);

                                            setState((prevState) => ({
                                                ...prevState,
                                                devices: data,
                                                edited: true
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {screensMatching.map((s) => {
                            const assignedSequences = sequences
                                .filter(({ devices }) => devices.includes(s.id))
                                .map(({ name }) => name);

                            return (
                                <div className="screen" key={s.id}>
                                    <div className="flex center">
                                        <div
                                            onClick={() => {
                                                if (state.devices.indexOf(s.id) !== -1) {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        devices: state.devices.filter((i) => i !== s.id),
                                                        edited: true
                                                    }));
                                                } else {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        devices: [...state.devices, s.id],
                                                        edited: true
                                                    }));
                                                }
                                            }}
                                            className={
                                                `checkbox` + (state.devices.indexOf(s.id) !== -1 ? ' checked' : '')
                                            }
                                        ></div>
                                        <span>
                                            {!isSubsequence || `${s.Shop.name} - `}
                                            {s.name}
                                        </span>
                                    </div>
                                    {assignedSequences.length === 0 || (
                                        <p className="currently">
                                            Actuellement: {assignedSequences.reduce((prev, c) => prev + ', ' + c)}
                                        </p>
                                    )}
                                </div>
                            );
                        })}
                        {virtualDevices.map((s) => {
                            const assignedSequences = [];

                            return (
                                <div className="screen" key={s.id}>
                                    <div className="flex center">
                                        <div
                                            onClick={() => {
                                                if (state.virtualDevices.indexOf(s.id) !== -1) {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        virtualDevices: state.virtualDevices.filter((i) => i !== s.id),
                                                        edited: true
                                                    }));
                                                } else {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        virtualDevices: [...state.virtualDevices, s.id],
                                                        edited: true
                                                    }));
                                                }
                                            }}
                                            className={
                                                `checkbox` +
                                                (state.virtualDevices.indexOf(s.id) !== -1 ? ' checked' : '')
                                            }
                                        ></div>
                                        <span style={{ color: '#F8485E' }}>
                                            {!isSubsequence || `${s.Shop.name} - `}
                                            {s.name}
                                        </span>
                                    </div>
                                    {assignedSequences.length === 0 || (
                                        <p className="currently">
                                            Actuellement: {assignedSequences.reduce((prev, c) => prev + ', ' + c)}
                                        </p>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="card">
                        <h4 className="flex center space-between" style={{ textAlign: 'left' }}>
                            [Option] Choisissez une date pour un démarrage différé de la séquence
                            <Switch
                                options={{ color: '#596b8c' }}
                                checked={state.startDate !== null}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offColor="#eee"
                                onColor="#5893df"
                                height={20}
                                width={38}
                                boxShadow="1px 2px 4px 0 rgba(0, 0, 0, 0.25)"
                                className="device-home-switch"
                                onChange={(e) =>
                                    setState((prevState) => ({ ...prevState, startDate: e ? new Date() : null }))
                                }
                            ></Switch>
                        </h4>
                        {state.startDate === null || (
                            <Calendar
                                tileDisabled={() => state.startDate === null}
                                value={new Date(state.startDate)}
                                onChange={(e) => setState({ ...state, startDate: e })}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display:
                                !isSubsequence &&
                                userObject.role.Shop &&
                                [2, 275, 514, 254, 445, 519, 281, 1561, 503].includes(userObject.role.Shop.id)
                                    ? 'block'
                                    : 'none'
                        }}
                        className="card planning"
                    >
                        <h4>Planification de la séquence</h4>
                        <h5>
                            Actif:{' '}
                            <Switch
                                options={{ color: '#596b8c' }}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offColor="#eee"
                                onColor="#5893df"
                                height={20}
                                width={38}
                                boxShadow="1px 2px 4px 0 rgba(0, 0, 0, 0.25)"
                                className="planning-home-switch"
                                checked={state.isPlanned && state.devices.length > 0}
                                onChange={(e) => {
                                    if (state.devices.length === 0) {
                                        return swal({
                                            icon: 'warning',
                                            title: "Pas d'écrans assignés",
                                            text: "Avant de planifier cette séquence, merci de l'assigner à au moins un écran."
                                        });
                                    }

                                    setState((prevState) => ({ ...prevState, isPlanned: e }));
                                }}
                            />
                        </h5>
                        <p className="mt-8 info">
                            <i className="fa fa-question-circle"></i> Si cette option est activée, cette séquence
                            prendra le dessus lors des horaires choisis sur toute autre séquence assigné aux écrans
                            sélectionnés.
                        </p>
                        <button
                            className="mt-16 btn btn-info"
                            onClick={() => {
                                if (state.devices.length === 0) {
                                    return swal({
                                        icon: 'warning',
                                        title: "Pas d'écrans assignés",
                                        text: "Avant de planifier cette séquence, merci de l'assigner à au moins un écran."
                                    });
                                }

                                setState({ ...state, modalPlanningVisible: true });
                            }}
                        >
                            Planifier
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SequencesV2 = (props) => {
    const [state, setState] = useState({
        selectedTab: 0,
        sequence: {
            creatives: [],
            devices: [],
            virtualDevices: [],
            name: props.match.params.name,
            orientation: props.match.params.orientation
        },
        sequences: [],
        screens: [],
        virtualDevices: [],
        signAvailables: [],
        signId: null,
        scrollDisabled: false,
        loading: true
    });

    useEffect(() => {
        const promises = [];

        if (props.isSubsequence) {
            if (props.match.params.id) {
                const onPromiseEnd = fetchApi(`/sequences/subsequences/${props.match.params.id}`)
                    .then((res) => res.json())
                    .then((sequence) =>
                        setState((prevState) => ({
                            ...prevState,
                            sequence: {
                                ...sequence,
                                creatives: sequence.creatives
                                    .map((creative) => ({
                                        creative: creative.crea,
                                        creationDate: creative.creationDate,
                                        creaType: creative.creaType,
                                        expirationDate: creative.expirationDate,
                                        startDate: creative.startDate,
                                        duration: creative.duration,
                                        _id: creative._id
                                    }))
                                    .filter(({ expirationDate, creative }) => {
                                        if (creative === null) {
                                            return false;
                                        }

                                        if (expirationDate) {
                                            return new Date() <= new Date(expirationDate);
                                        }

                                        return true;
                                    })
                            },
                            signId: sequence.signId
                        }))
                    );

                promises.push(onPromiseEnd);
            } else {
                const onPromiseEnd = fetchApi('/sequences/subsequences/signs')
                    .then((res) => res.json())
                    .then((signs) =>
                        setState((prevState) => ({
                            ...prevState,
                            signAvailables: signs,
                            signId:
                                (props.userObject.role.role === 'manager' || props.userObject.role.role === 'groupAdmin')
                                    ? signs[0].id
                                    : props.userObject.role.Shop.SignId,
                            loading: false
                        }))
                    );

                promises.push(onPromiseEnd);
            }

            const onPromiseEnd = fetchApi('/sequences/subsequences/devices')
                .then((res) => res.json())
                .then((devices) => {
                    if (props.userObject?.role?.id === 456) {
                        // Capsud modifier
                        devices = devices.filter(({ Shop }) => Shop?.GroupId === 97);
                        console.log(devices);
                    }
                    setState((prevState) => ({ ...prevState, screens: devices }));
                });

            promises.push(onPromiseEnd);
        } else {
            promises.push(
                fetchApi('/sequences')
                    .then((res) => res.json())
                    .then((sequences) => setState((prevState) => ({ ...prevState, sequences })))
            );

            if (props.match.params.id) {
                const onPromiseEnd = fetchApi(`/sequences/${props.match.params.id}`)
                    .then((res) => res.json())
                    .then((sequence) =>
                        setState((prevState) => ({
                            ...prevState,
                            sequence: {
                                ...sequence,
                                creatives: sequence.creatives
                                    .map((creative) => ({
                                        creative: creative.crea,
                                        creationDate: creative.creationDate,
                                        creaType: creative.creaType,
                                        expirationDate: creative.expirationDate,
                                        startDate: creative.startDate,
                                        duration: creative.duration,
                                        _id: creative._id
                                    }))
                                    .filter(({ expirationDate, creative }) => {
                                        if (creative === null) {
                                            return false;
                                        }

                                        if (expirationDate) {
                                            return new Date() <= new Date(expirationDate);
                                        }

                                        return true;
                                    })
                            }
                        }))
                    );

                promises.push(onPromiseEnd);
            }

            promises.push(
                fetchApi('/shop/devices')
                    .then((res) => res.json())
                    .then((devices) => setState((prevState) => ({ ...prevState, screens: devices })))
            );

            promises.push(
                fetchApi('/shop/virtualDevices')
                    .then((res) => res.json())
                    .then((devices) => setState((prevState) => ({ ...prevState, virtualDevices: devices })))
            );
        }

        Promise.all(promises).then(() => setState((prevState) => ({ ...prevState, loading: false })));
    }, [props.match.params.id, props.userObject, props.isSubsequence]);

    if (state.loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={`dyl-home dyl-sequences-page${state.scrollDisabled ? ' noScroll' : ''}`}>
            <header>
                <Link to="/" className="logo">
                    <img src="/image.png" alt="logo" />
                </Link>
                <p className="hidden-xs">{dateFormat(new Date(), 'dddd dd mmmm yyyy')}</p>
                <h3>Modification de séquence</h3>
                <HeaderNotifications {...props.notifsData} />
            </header>
            <div className="content">
                {state.signAvailables.length <= 1 || (
                    <div className="form-group">
                        <select
                            value={state.signId}
                            onChange={(e) => setState({ ...state, signId: parseInt(e.target.value) })}
                            className="form-control"
                        >
                            {state.signAvailables.map((s) => (
                                <option value={s.id} key={s.id}>
                                    {s.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <TabSequenceDetails
                    userObject={props.userObject}
                    sequence={state.sequence}
                    screens={state.screens}
                    virtualDevices={state.virtualDevices}
                    isNew={!!!props.match.params.id}
                    name={state.sequence.name || props.match.params.name}
                    pushHistory={props.history.push}
                    nameEdition={window.location.search.slice(8) === 'rename'}
                    isSubsequence={props.isSubsequence}
                    signId={state.signId}
                    setScrollState={(scrollDisabled) => setState({ ...state, scrollDisabled })}
                    sequences={state.sequences}
                />
            </div>
        </div>
    );
};

export default SequencesV2;
