import React from 'react';
import AuthService from '../../../Components/Auth/AuthService';
import { fetchApi } from '../../../Services/NetworkServcice';
import { notify } from 'react-notify-toast';
import constants from '../../../Constants';
import swal from 'sweetalert';

import '../../../Assets/editor.css';

const Auth = new AuthService();

// const getLiveColor = (startDate, endDate) => {
//     const now = new Date();

//     if (new Date(startDate) >= now) {
//         return 'rgb(23, 171, 77)';
//     }

//     if (now > new Date(endDate)) {
//         return 'rgb(189, 0, 0)'; // Red
//     }

//     return 'inherit';
// };

// const computeNonAckScreens = (screenIds = [], ack = [], screenList = [], store) => {
//     const notAck = [];

//     for (const id of screenIds) {
//         if (ack.indexOf(id) === -1) {
//             notAck.push(id);
//         }
//     }

//     return notAck
//         .map((sid) => {
//             const screenObject = screenList.find(({ id }) => id === sid);

//             if (!screenObject) {
//                 return { name: 'Unknown screen', id: sid, lastPing: new Date('1970-01-01T00:00:00.000Z') };
//             }

//             const lastPing = new Date((store[sid] || { lastPing: '1970-01-01T00:00:00.000Z' }).lastPing);

//             return {
//                 id: sid,
//                 name: `${screenObject.Shop.name} - ${screenObject.name}`,
//                 lastPing
//             };
//         })
//         .sort((a, b) => b.lastPing.getTime() - a.lastPing.getTime());
// };

// const getCampaignIcon = (c) => {
//     if (c.state === 'draft') {
//         return <i className="fa fa-user-edit"></i>;
//     }

//     return null;
// };

// const getDeviceStatus = (loadedDate, lastPing) => {
//     return (loadedDate - new Date(lastPing)) / 5000 <= 30 ? (
//         <span className="label label-success">Running</span>
//     ) : (
//         <span className="label label-danger">Off</span>
//     );
// };

export default class Campaigns extends React.Component {
    constructor() {
        super();

        this.state = {
            campaigns: [],
            displayArchived: false,
            hasArchived: false,
            screens: [],
            store: {},
            sortDateAsc: true,
            showAck: -1
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.getData = this.getData.bind(this);
        this.handleSwitchActivation = this.handleSwitchActivation.bind(this);
        this.handleNewCampaign = this.handleNewCampaign.bind(this);

        this.now = new Date();

        this.btnLoadMoreRef = null;
    }

    getData(extended = false) {
        fetchApi(`/campaigns${extended ? '' : '/recent'}`)
            .then((res) => res.json())
            .then(({ campaigns, screens, store }) => this.setState({ campaigns, screens, store }));
    }

    componentDidMount() {
        // this.getData();
    }

    handleDelete(id) {
        fetchApi(`/campaigns/${id}`, { method: 'DELETE' }).then((res) => {
            if (res.status === 200) {
                this.getData();
            }
        });
    }

    handleSwitchActivation(id, activated) {
        if (![106, 103, 105, 1235, 1354, 2044].includes(this.props.user.id)) {
            return swal({
                title: 'Opération non autorisée',
                icon: 'info',
                text: "Vous n'avez pas les permissions pour effectuer cette action"
            });
        }

        fetchApi(`/campaigns/activation/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ active: activated })
        }).then((res) => {
            if (res.status === 200) {
                // OK
                this.getData();
            } else if (res.status === 409) {
                notify.show("Impossible d'activer cette séquence: certains écrans sont pleins", 'error');
            } else if (res.status === 500) {
                console.error('Error setting status');
            }
        });
    }

    handleNewCampaign(name, agence, startDate, endDate, id) {
        Auth.fetch(`${constants.endpoint}/admin/campaignsGallery`, {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                password: new Date().getTime().toString(),
                agency: agence,
                startDate: startDate,
                endDate: endDate,
                info: '',
                autoFile: true,
                autoCampaignId: id
            })
        }).then((res) => {
            if (res.success !== true) {
                return alert('Impossible de creer la campagne: ' + res.message);
            }

            this.getData();
            this.setState({ name: '' });
            alert('Nouvelle Galerie crée');
        });
    }

    render() {
        return (
            <div>
                Voir <a href="https://campaigns.doohyoulike.com">https://campaigns.doohyoulike.com</a>
            </div>
        );
    }
}
