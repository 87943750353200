import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { fetchApi } from '../Services/NetworkServcice';
import { SequenceHomeList } from './Home';

const RayonSequenceList = (props) => {
    const [state, setState] = useState({
        sequences: [],
        devices: [],
        newSeqName: '',
        orientation: 'portrait',
        availableOrientations: ['portrait']
    });

    useEffect(() => {
        fetchApi('/sequences')
            .then((res) => res.json())
            .then((sequences) => setState((prevState) => ({ ...prevState, sequences })));

        fetchApi('/shop/devices')
            .then((res) => res.json())
            .then((devices) => {
                const availableOrientations = [...new Set(devices.map(({ orientation }) => orientation))];
                setState((prevState) => ({
                    ...prevState,
                    devices: devices,
                    availableOrientations,
                    orientation: availableOrientations[0] || 'portrait'
                }));
            });
    }, []);

    if (state.devices.length === 0) {
        return null;
    }

    return (
        <SequenceHomeList
            availableOrientations={state.availableOrientations}
            history={props.history}
            notifsData={props.notifsData}
            screens={state.devices}
            sequences={state.sequences}
            onDelete={(id) => {
                swal({
                    title: 'Supprimer cette séquence ?',
                    text: 'Êtes-vous sûr ?',
                    icon: 'warning',
                    buttons: ['Annuler', 'OK'],
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete) {
                        fetchApi(`/sequences/${id}`, {
                            method: 'DELETE'
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    swal({ text: 'Séquence supprimée', icon: 'success' });
                                    setState({
                                        ...state,
                                        sequences: state.sequences.filter(({ _id }) => _id !== id)
                                    });
                                } else {
                                    swal({ text: 'Impossible de supprimer', icon: 'danger' });
                                }
                            })
                            .catch(() => {
                                swal({ text: 'Impossible de supprimer', icon: 'danger' });
                            });
                    }
                });
            }}
        />
    );
};

export default RayonSequenceList;
