import React from 'react';
import { countries } from '../../Constants';
import Select from 'react-select';
import { Col } from 'react-bootstrap';

const FishShopAttributes = (props) => {
    let availableLabels = ['logo-msc.png', 'ploc.png', 'upc.png', 'labelrouge.png', 'fdvm.png'];

    return (
        <div>
            <div className="form-group">
                <label htmlFor="originCountry">Pays d'origine</label>
                <Select
                    options={[
                        ...[
                            {
                                label: '---',
                                value: ''
                            },
                            {
                                value: 'France',
                                label: 'France'
                            },
                            {
                                value: 'Espagne',
                                label: 'Espagne'
                            },
                            {
                                value: 'Argentine',
                                label: 'Argentine'
                            },
                            {
                                value: 'Bresil',
                                label: 'Bresil'
                            },
                            {
                                value: 'Ecosse',
                                label: 'Ecosse'
                            },
                            {
                                value: 'Irlande',
                                label: 'Irlande'
                            },
                            {
                                value: 'Uruguay',
                                label: 'Uruguay'
                            },
                            {
                                value: 'Europe',
                                label: 'Europe'
                            },
                            {
                                value: '---------------------',
                                label: '---------------------',
                                disabled: true
                            }
                        ],
                        ...countries
                    ]}
                    name="originCountry"
                    onChange={(e) =>
                        props.handleChange({
                            target: {
                                name: 'originCountry',
                                value: e.value
                            }
                        })
                    }
                    isOptionDisabled={(o) => o.disabled === true}
                    value={{
                        label: props.originCountry,
                        value: props.originCountry
                    }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="sub">Zone de pêche</label>
                <select
                    className="form-control"
                    value={props.subtitle}
                    onChange={(e) => props.handleSetSub(e.target.value)}
                    name="subtitle"
                >
                    <option value="">---</option>
                    <option value="Atlantique Nord-Ouest">Atlantique Nord-Ouest</option>
                    <option value="Atlantique Nord-Est">Atlantique Nord-Est (sauf Baltique)</option>
                    <option value="Mer Baltique">Mer Baltique</option>
                    <option value="Atlantique Centre-Ouest">Atlantique Centre-Ouest</option>
                    <option value="Atlantique Centre-Est">Atlantique Centre-Est</option>
                    <option value="Atlantique Sud-Ouest">Atlantique Sud-Ouest</option>
                    <option value="Atlantique Sud-Est">Atlantique Sud-Est</option>
                    <option value="Mer Méditerranée">Mer Méditerranée</option>
                    <option value="Mer Noire">Mer Noire</option>
                    <option value="Océan Indien">Océan Indien</option>
                    <option value="Océan Pacifique">Océan Pacifique</option>
                    <option value="Antarctique">Antarctique</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="sub">Sous-zone de pêche</label>
                <select
                    className="form-control"
                    value={props.subzone}
                    onChange={(e) => props.handleSetSubzone(e.target.value)}
                    name="subzone"
                >
                    <option value="">---</option>
                    <option value="27-I : Mer de Barents">27-I : Mer de Barents</option>
                    <option value="27-II : Mers de Norvège ">27-II : Mers de Norvège </option>
                    <option value="27-III : Mer Baltique ">27-III : Mer Baltique </option>
                    <option value="27-IV : Mer du Nord">27-IV : Mer du Nord</option>
                    <option value="27-V : Islande et Féroé">27-V : Islande et Féroé</option>
                    <option value="27-VI : Ouest Ecosse">27-VI : Ouest Ecosse</option>
                    <option value="27-VII : Manche et Mers Celtiques ">27-VII : Manche et Mers Celtiques </option>
                    <option value="27-VIII : Golfe de Gascogne ">27-VIII : Golfe de Gascogne </option>
                    <option value="27-IX : Ouest Portugal">27-IX : Ouest Portugal</option>
                    <option value="27-X : Açores">27-X : Açores</option>
                    <option value="27-XII : Nord Açores">27-XII : Nord Açores</option>
                    <option value="27-XIV : Est Groenland">27-XIV : Est Groenland</option>
                    <option value="37-I : Ouest Méditerranée">37-I : Ouest Méditerranée</option>
                    <option value="37-II : Centre Méditerranée">37-II : Centre Méditerranée</option>
                    <option value="37-III : Est Méditerranée">37-III : Est Méditerranée</option>
                    <option value="37-IV : Mer Noire">37-IV : Mer Noire</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="label">Label</label>
                <div className="LabelsHorizontalScroll">
                    <Col
                        lg={2}
                        md={3}
                        xs={4}
                        className={'labelCol' + (props.labels.length === 0 ? ' active' : '')}
                        onClick={() => props.handleSetLabels([])}
                    >
                        <div className="noLabel">
                            <i className="fa fa-ban"></i>
                            <span>Aucun</span>
                        </div>
                    </Col>
                    {availableLabels.map((l) => (
                        <Col
                            lg={2}
                            md={3}
                            xs={4}
                            key={l}
                            onClick={() => {
                                if (props.labels.indexOf(l) === -1) {
                                    props.handleSetLabels([...props.labels, l].slice(0, 2));
                                } else {
                                    props.handleSetLabels(props.labels.filter((lb) => lb !== l));
                                }
                            }}
                            className={'labelCol' + (props.labels.indexOf(l) !== -1 ? ' active' : '')}
                        >
                            <img className="labelImg" src={`/labels/${l}`} alt={l} />
                        </Col>
                    ))}
                </div>
            </div>
            <div className="form-group">
                <label>Moyen de pêche</label>
                <select
                    className="form-control"
                    value={props.fishingMethod}
                    onChange={props.handleChange}
                    name="fishingMethod"
                >
                    <option value="">---</option>
                    <option value="Pêché">Pêché</option>
                    <option value="Pêché en eaux douces">Pêché en eaux douces</option>
                    <option value="Élevé">Élevé</option>
                </select>
            </div>
        </div>
    );
};

export default FishShopAttributes;
