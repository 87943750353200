import React from 'react';
import dateFormat from 'dateformat';

export default class Sidebar extends React.Component {
    render() {
        return (
            <div className="magsList">
                <input
                    type="text"
                    name={this.props.filterName}
                    className="form-control"
                    value={this.props.filter}
                    onChange={this.props.handleChange}
                />
                <ul>
                    {this.props.shops.map(s => (
                        <div
                            key={s.id}
                            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: '10px', borderBottom: '1px solid #EDEDED'}}
                            className={
                                s.id === this.props.selected ? 'active' : ''
                            }
                            onClick={() => this.props.handleClick(s.id)}
                        >
                            <div>{s.name}</div>
                            <div style={{ fontSize: '12px'}}>
                                {s.address} - {s.zip} - {s.city}
                            </div>
                            <div style={{ fontSize: '10px', color: 'grey'}}>
                                {s.Group?.name} - {s.Sign?.name}
                            </div>
                            <span>
                                {!s.date ||
                                    dateFormat(s.date, 'dd/mm/yy HH:MM')}
                            </span>
                        </div>
                    ))}
                </ul>
            </div>
        );
    }
}
