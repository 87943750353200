import React from 'react';

import '../../Assets/Templates/Carrefour.css';
import {
    getPathFromCategory,
    getTitleFromCategory,
    getDataFromCategory,
    getFlag,
    getLabels,
    padNum,
    getQuantityText,
    getContainer,
    getKiloPrice,
    getPiecePrice
} from './MainTemplate';
import { getDates } from './MyAuchan';

export const CarrefourTemplate = (props) => {
    return (
        <div className={`CarrefourTemplate Carrefour ${props.orientation}`}>
            <div className="promo">
                {!props.promotion || (
                    <React.Fragment>
                        <div className="promoBlock">BON PLAN</div>
                        <p>{getDates(props.startDate, props.expirationDate)}</p>
                    </React.Fragment>
                )}
            </div>
            <div className="image">
                <img src={getPathFromCategory(props.rayon, props.filename)} alt="fr" />
            </div>
            <div className="data">
                <h1>{getTitleFromCategory(props.rayon, props)}</h1>
                <div className="origin">
                    {!props.originCountry || (
                        <p>
                            {!!props.originType ? props.originType : 'Origine'} : {props.originCountry} {getFlag(props.originCountry, props.displayFlag)}
                        </p>
                    )}
                </div>
                <div className="data-list">{getDataFromCategory(props.rayon, props)}</div>
            </div>
            <div className="data-price">
                <div className="price">
                    <div className="num">{props.price.toString().split(/[.,]/)[0]}</div>
                    <div className="sec-part">
                        <div className="eur">&euro;</div>
                        <span className="cts">{padNum(props.price.toString().split(/[.,]/)[1])}</span>
                    </div>
                </div>
                <div className="price-ctr">
                    {getContainer(props)} {getQuantityText(props)}
                </div>
                {props.priceUnit !== 'number' || (
                    <span className="kilo-price">Soit {getKiloPrice(props.weight, props.price)}€ le Kg</span>
                )}
                {props.priceUnit !== 'piece' || !props.pieceNb || props.pieceNb <= 1 || (
                    <span className="kilo-price">Soit {getPiecePrice(props.pieceNb, props.price)}€ pièce</span>
                )}
                {props.priceUnit !== 'lot' || !props.pieceNb || props.pieceNb <= 1 || (
                    <span className="kilo-price">Soit {getPiecePrice(props.pieceNb, props.price)}€ pièce</span>
                )}
            </div>
            <div className="labels">
                {getLabels(props).map((l) => (
                    <img key={l} src={`/labels/${l}`} alt="label" />
                ))}
            </div>
            <img src="/templates/logo-carrefour.svg" alt="logo" className="logo" />
        </div>
    );
};
