import React from 'react';

import '../../Assets/Templates/Intermarche.css';
import {
    getPathFromCategory,
    getTitleFromCategory,
    getDataFromCategory,
    getFlag,
    getLabels,
    padNum,
    getQuantityText,
    getContainer,
    getKiloPrice,
    getPiecePrice
} from './MainTemplate';
import { getDates } from './MyAuchan';

export const IntermarcheTemplate = (props) => {
    const badgeStyle = {};

    if (props.promotion) {
        badgeStyle.backgroundColor = '#fff14b';
        badgeStyle.color = '#ed1c24';
    }

    return (
        <div className={`IntermarcheTemplate Intermarche ${props.orientation}`}>
            <img src="/templates/itm-logo.png" alt="logo" className="itm-logo" />
            <div className="promo">
                <p>{getDates(props.startDate, props.expirationDate)}</p>
            </div>
            <div className="origin">
                <div className="origin-ctr">
                    {!props.originCountry || <span>Origine</span>}
                    {getFlag(props.originCountry, props.displayFlag)}
                </div>
                <h5>{props.originCountry}</h5>
            </div>
            <div className="image">
                <img src={getPathFromCategory(props.rayon, props.filename)} alt="product" />
            </div>
            <div className="data-price" style={badgeStyle}>
                <div className="price">
                    <div className="num">{props.price.toString().split(/[.,]/)[0]}</div>
                    <div className="sec-part">
                        <div className="eur">&euro;</div>
                        <span className="cts">{padNum(props.price.toString().split(/[.,]/)[1])}</span>
                    </div>
                </div>
                <div className="price-ctr">
                    {getContainer(props)} {getQuantityText(props)}
                </div>
            </div>
            <div className="price-precision">
                {props.priceUnit !== 'number' || (
                    <span className="kilo-price">
                        Soit {getKiloPrice(props.weight, props.price)}
                        <span style={{ fontFamily: 'ProximaNovaBold' }}>€</span> le Kg
                    </span>
                )}
                {props.priceUnit !== 'piece' || !props.pieceNb || props.pieceNb <= 1 || (
                    <span className="kilo-price">
                        Soit {getPiecePrice(props.pieceNb, props.price)}
                        <span style={{ fontFamily: 'ProximaNovaBold' }}>€</span> pièce
                    </span>
                )}
                {props.priceUnit !== 'lot' || !props.pieceNb || props.pieceNb <= 1 || (
                    <span className="kilo-price">
                        Soit {getPiecePrice(props.pieceNb, props.price)}
                        <span style={{ fontFamily: 'ProximaNovaBold' }}>€</span> pièce
                    </span>
                )}
            </div>
            <div className="data">
                <h1>{getTitleFromCategory(props.rayon, props)}</h1>
                {getDataFromCategory(props.rayon, props)}
            </div>
            <div className="bg">
                {!props.promotion || <div className="promo-text">bon plan</div>}
                <p>tous unis contre la vie chere</p>
            </div>
            <div className="labels">
                {getLabels(props).map((l) => (
                    <img key={l} src={`/labels/${l}`} alt="label" />
                ))}
            </div>
        </div>
    );
};
