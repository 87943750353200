import React from 'react';
import constants from '../Constants';
import { templateElementsToHtml } from './FreeTemplates/ElementsEditor';

const RATIOS = {
    portrait: 12,
    landscape: 12,
    led_792_192: 12,
    led_288_420: 12,
    led_288_384: 12,
    led_462_320: 12
};

const RATIOS_BIG = {
    portrait: 6,
    landscape: 6,
    led_792_192: 6,
    led_288_420: 6,
    led_288_384: 6,
    led_462_320: 6
};

const DIMENSIONS = {
    portrait: [90, 160],
    landscape: [160, 90],
    led_792_192: [160, 39],
    led_288_420: [160, 233],
    led_288_384: [160, 213],
    led_462_320: [160, 145]
};

const DIMENSIONS_BIG = {
    portrait: [180, 320],
    landscape: [320, 180],
    led_792_192: [160, 39],
    led_288_420: [160, 233],
    led_288_384: [160, 213],
    led_462_320: [160, 145]
};

const TemplateItem = ({
    _id,
    src,
    name,
    defaultElements,
    xStart,
    yStart,
    height,
    width,
    orientation,
    onClick,
    isSelected,
    usage,
    big,
    showText
}) => (
    <div className={isSelected ? 'templateItem selected' : 'templateItem'}>
        <div
            className="fixedTemplateContainer"
            style={{
                width: (big ? DIMENSIONS_BIG : DIMENSIONS)[orientation][0],
                height: (big ? DIMENSIONS_BIG : DIMENSIONS)[orientation][1]
            }}
        >
            <img
                src={`${constants.cloud_storage}/Assets/Library/Templates${big ? '' : '/Thumbs'}/${src}`}
                alt={src}
                onClick={onClick}
            />
            <div
                className="zone"
                style={{
                    top: yStart / (big ? RATIOS_BIG : RATIOS)[orientation],
                    left: xStart / (big ? RATIOS_BIG : RATIOS)[orientation],
                    height: height,
                    width: width,
                    transform: `scale(${1 / (big ? RATIOS_BIG : RATIOS)[orientation]})`
                }}
                onClick={onClick}
                dangerouslySetInnerHTML={{
                    __html: templateElementsToHtml(defaultElements, orientation, true)
                }}
            ></div>
        </div>
        {!showText || <p className="nomargin">{name}</p>}
        {usage === null || (
            <p className="templateUsage">Utilisations: {(usage.find((u) => _id === u._id) || { count: 0 }).count}</p>
        )}
        {usage === null || usage.length === 0 || (
            <div className="tooltipMags">
                {((usage.find((u) => _id === u._id) || { shops: [] }).shops || []).map((s) => (
                    <p>{s}</p>
                ))}
            </div>
        )}
    </div>
);

const FixedTemplateList = ({
    templates = [],
    onClick = (_ = -1) => null,
    selected = null,
    usage = null,
    big = false,
    showText = true
}) => (
    <div className="templateList">
        {templates.map((t, i) => (
            <TemplateItem
                key={t._id}
                {...t}
                onClick={() => onClick(i)}
                isSelected={selected === t._id}
                usage={usage}
                big={big}
                showText={showText}
            />
        ))}
    </div>
);

export default FixedTemplateList;
