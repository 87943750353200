import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import swal from 'sweetalert';

export const EditCategory = ({ category, onClose, onSave, onDelete }) => {
    const [state, setState] = useState({
        category: null
    });

    useEffect(() => {
        if (category !== null) {
            if (!category.visibleDate) {
                category.visibleDate = new Date(category.startDate);
            }

            setState({ category });
        }
    }, [category]);

    if (state.category === null) {
        return null;
    }

    return (
        <Modal className="modal-assets-admin" show={category !== null} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Catégorie</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Nom</label>
                            <input
                                type="text"
                                className="form-control"
                                value={state.category.name}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            name: value
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Type</label>
                            <select
                                value={state.category.type || 'mar'}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            type: value
                                        }
                                    }));
                                }}
                                className="form-control"
                            >
                                <option value="mar">Marronnier</option>
                                <option value="pro">Promotion</option>
                                <option value="svc">Service</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex calendars">
                    <div>
                        Date de début
                        <Calendar
                            value={new Date(state.category.startDate)}
                            onChange={(d) => {
                                let endDate = new Date(state.category.endDate);

                                if (d.getTime() > endDate.getTime()) {
                                    endDate = new Date(d);
                                    endDate.setHours(23, 59, 59, 999);
                                }

                                setState((prevState) => ({
                                    ...prevState,
                                    category: {
                                        ...prevState.category,
                                        startDate: new Date(d),
                                        visibleDate: new Date(d),
                                        endDate,
                                        expirationDate: new Date(endDate)
                                    }
                                }));
                            }}
                        ></Calendar>
                    </div>
                    <div>
                        Date de fin / expiration
                        <Calendar
                            minDate={new Date(state.category.startDate)}
                            value={new Date(state.category.endDate)}
                            onChange={(d) => {
                                d.setHours(23, 59, 59, 999);

                                setState((prevState) => ({
                                    ...prevState,
                                    category: {
                                        ...prevState.category,
                                        endDate: new Date(d),
                                        expirationDate: new Date(d)
                                    }
                                }));
                            }}
                        ></Calendar>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={
                                new Date(state.category.visibleDate).getTime() !==
                                new Date(state.category.startDate).getTime()
                            }
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const startDate = new Date(state.category.startDate);
                                    startDate.setDate(startDate.getDate() + 1);

                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            visibleDate: startDate
                                        }
                                    }));
                                } else {
                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            visibleDate: new Date(prevState.category.startDate)
                                        }
                                    }));
                                }
                            }}
                        />
                        Visible mais avec date de démarrage ?
                        {new Date(state.category.visibleDate || state.category.startDate).getTime() ===
                            new Date(state.category.startDate).getTime() || (
                            <Calendar
                                value={new Date(state.category.visibleDate)}
                                minDate={new Date()}
                                maxDate={new Date(state.category.endDate)}
                                onChange={(d) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            visibleDate: new Date(d)
                                        }
                                    }));
                                }}
                            ></Calendar>
                        )}
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={
                                new Date(state.category.endDate).getTime() !==
                                new Date(state.category.expirationDate).getTime()
                            }
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const endDate = new Date(state.category.endDate);
                                    endDate.setDate(endDate.getDate() + 1);

                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            expirationDate: endDate
                                        }
                                    }));
                                } else {
                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            expirationDate: prevState.category.endDate
                                        }
                                    }));
                                }
                            }}
                        />
                        Date d'expiration différente de la date de fin ?
                        {new Date(state.category.endDate).getTime() ===
                            new Date(state.category.expirationDate).getTime() || (
                            <Calendar
                                minDate={new Date(state.category.endDate)}
                                value={new Date(state.category.expirationDate)}
                                onChange={(d) => {
                                    d.setHours(23, 59, 59, 999);

                                    setState((prevState) => ({
                                        ...prevState,
                                        category: {
                                            ...prevState.category,
                                            expirationDate: new Date(d)
                                        }
                                    }));
                                }}
                            ></Calendar>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-danger"
                    onClick={() => {
                        swal({
                            title: 'Suppression',
                            icon: 'error',
                            text: 'Êtes-vous sûr de vouloir supprimer cette catégorie? Toutes les affiches seront supprimées et enlevées immédiatement des écrans. Cette opération est irréversible.',
                            buttons: ['Annuler', 'Confirmer'],
                            dangerMode: true
                        }).then((value) => {
                            if (value) {
                                onDelete(state.category._id);
                            }
                        });
                    }}
                >
                    Supprimer
                </button>
                <button className="btn btn-success" onClick={() => onSave(state.category)}>
                    Enregistrer
                </button>
            </Modal.Footer>
        </Modal>
    );
};
