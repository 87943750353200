import React from 'react';
import { Row, Col, Panel, Modal } from 'react-bootstrap';

import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';

import '../../../Assets/campaignsEditor.css';
import General from './Modules/General';
import Targeting from './Modules/Targeting';
import { fetchApi } from '../../../Services/NetworkServcice';
import Planning from './Modules/Planning';
import Creatives from './Modules/Creatives';
import constants from '../../../Constants';
import { notify } from 'react-notify-toast';
import Pricing from './Modules/Pricing';
import CKEditor from 'react-ckeditor-component';

// Signs icons
import a2pas from '../../../Assets/Signs/a2pas.png';
import carrefour from '../../../Assets/Signs/carrefour.png';
import carrefourc from '../../../Assets/Signs/ccontact.png';
import franprix from '../../../Assets/Signs/franprix.png';
import g20 from '../../../Assets/Signs/g20.png';
import inter from '../../../Assets/Signs/inter.png';
import U from '../../../Assets/Signs/U.png';
import cmarket from '../../../Assets/Signs/cmarket.png';
import monop from '../../../Assets/Signs/monop.png';
import coccinelle from '../../../Assets/Signs/coccinelle.png';
import myauchan from '../../../Assets/Signs/myauchan.png';
import omf from '../../../Assets/Signs/omf.png';
import mac from '../../../Assets/Signs/mac.png';
import diagonal from '../../../Assets/Signs/diagonal.png';
import { useRef } from 'react';

const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

const getFilteredDevices = (devices, filters, allDevices, exclusion) => {
    const selected = [];

    if (allDevices) {
        return devices.map(({ id }) => id);
    }

    if (!filters.length) {
        return selected;
    }

    for (const device of devices) {
        const matchesSign = !!filters.find((sf) => {
            if (sf.type === 'sign') {
                return device.Shop.Sign.name === sf.id;
            }

            return false;
        });

        const filterBySign = !!filters.find((sf) => sf.type === 'sign');

        const matchesOther = !!filters.find((sf) => {
            if (sf.type === 'shop') {
                return device.Shop.name === sf.id;
            } else if (sf.type === 'zip') {
                return device.Shop.zip === sf.id;
            } else if (sf.type === 'city') {
                return device.Shop.city.toUpperCase() === sf.id;
            } else if (sf.type === 'group' && device.Group) {
                return device.Group.name === sf.id;
            } else if (sf.type === 'address') {
                return device.Shop.address === sf.id;
            } else if (sf.type === 'region') {
                return device.Shop.region === sf.id;
            } else if (sf.type === 'departement') {
                return device.Shop.departement === sf.id;
            }

            return false;
        });

        const filterByOther = !!filters.find((sf) => sf.type !== 'sign');

        if (!exclusion) {
            if ((!filterBySign || matchesSign) && (!filterByOther || matchesOther)) {
                selected.push(device.id);
            }
        } else {
            if (!matchesSign && !matchesOther) {
                selected.push(device.id);
            }
        }
    }

    return selected;
};

const SelectProgrammaticCategory = ({ availableProgammaticCategories, onClick, selected }) => {
    const ref = useRef(null);

    const usableProgrammaticCategories = availableProgammaticCategories.filter(
        ({ _id }) => selected.indexOf(_id) === -1
    );

    return (
        <div className="col-md-4">
            <select style={{ marginTop: 10 }} ref={ref} className="form-control">
                {usableProgrammaticCategories.map((c) => (
                    <option value={c._id}>{c.name}</option>
                ))}
            </select>
            <button style={{ marginTop: 10 }} className="btn btn-info" onClick={() => onClick(ref.current.value)}>
                Ajouter
            </button>
        </div>
    );
};

const icons = {
    INTERMARCHE: inter,
    FRANPRIX: franprix,
    'CARREFOUR EXPRESS': carrefour,
    'CARREFOUR CONTACT': carrefourc,
    'CARREFOUR CITY': carrefour,
    'G 20': g20,
    G20: g20,
    'U EXPRESS': U,
    MONOP: monop,
    A2PAS: a2pas,
    'MY AUCHAN': myauchan,
    'INTERMARCHE EXPRESS': inter,
    'CARREFOUR MARKET': cmarket,
    DIAGONAL: diagonal,
    'O MARCHE FRAIS': omf,
    'COCCINELLE EXPRESS': coccinelle,
    UTILE: U,
    "LE MARCHE D'A COTE": mac
};

const MapCpt = withScriptjs(
    withGoogleMap((props) => {
        const shops = [];

        // Make unique
        for (const screen of props.screens) {
            if (!shops.find(({ id }) => id === screen.id)) {
                shops.push(screen);
            }
        }

        return (
            <GoogleMap defaultZoom={11} defaultCenter={{ lat: 48.8588377, lng: 2.2770207 }}>
                {shops.map((s) => (
                    <Marker
                        key={s.id}
                        position={{
                            lat: parseFloat(s.lat),
                            lng: parseFloat(s.long)
                        }}
                        icon={{
                            url: icons[s.Sign.name],
                            scaledSize: { height: 45, width: 30 }
                        }}
                        animation={window.google.maps.Animation.DROP}
                    />
                ))}
            </GoogleMap>
        );
    })
);

export const MapModal = (props) => (
    <Modal show={props.modalMap} onHide={props.handleClose}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
            <MapCpt
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `600px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                screens={props.screens.map(({ Shop }) => Shop)}
            />
        </Modal.Body>
    </Modal>
);

export default class CampaignEditor extends React.Component {
    constructor() {
        super();

        this.state = {
            // State
            _id: null,
            availableAgencies: [],
            availableDevices: [],
            deviceSelectionSuggestions: [],
            deviceFilters: [],
            allDevices: false,
            selectedDay: null,
            fileUploading: 0,
            errors: [],
            conflicts: [],
            manualTarget: false,
            manualFilters: [],
            editedTargeting: false,
            deviceFilterExclude: false,
            modalMap: false,
            orders: [],
            availableProgammaticCategories: [],

            // Data
            agency: '',
            name: '',
            groupName: '',
            startDate: new Date(),
            endDate: new Date(),
            planning: [[], [], [], [], [], [], []],
            notes: '',
            creatives: [],
            contact: '',
            advertiser: '',
            adContact: '',
            brand: '',
            commercial: '',
            devices: [],
            slots: 1,
            strategy: 'linear',
            xlsFile: null,
            xlsData: null,
            idContrat: '',
            deviceSignsSuggestions: [],
            triggered: false,
            message: '',
            eans: [],
            hideFromInterface: false,
            programmaticCategories: []
        };

        this.editorConfig = {
            toolbar: [
                {
                    name: 'clipboard',
                    items: ['Undo', 'Redo']
                },
                {
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Underline']
                },
                {
                    name: 'paragraph',
                    items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                },
                { name: 'colors', items: ['TextColor'] }
            ],
            autoGrow_maxHeight: '600',
            colorButton_colors: 'FF9900,0066CC,F00',
            colorButton_enableAutomatic: false,
            colorButton_enableMore: false,
            removePlugins: 'elementspath',
            resize_enabled: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectDevices = this.handleSelectDevices.bind(this);
        this.handlePlanningHour = this.handlePlanningHour.bind(this);
        this.handleSelectRange = this.handleSelectRange.bind(this);
        this.handleApplyTimeToAll = this.handleApplyTimeToAll.bind(this);
        this.handleUploadCreative = this.handleUploadCreative.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRemoveCreative = this.handleRemoveCreative.bind(this);
        this.setParentState = this.setParentState.bind(this);
        this.handleXlsUpload = this.handleXlsUpload.bind(this);
        this.handleDownloadCSV = this.handleDownloadCSV.bind(this);
    }

    async componentDidMount() {
        // First, get available{Agencies,Advertisers}, available devices, etc...
        await fetchApi('/admin/devices')
            .then((res) => res.json())
            .then((devices) => {
                devices = devices.devices.filter(
                    ({ campaigns, orientation }) =>
                        !!campaigns && (orientation === 'portrait' || orientation === 'portrait_direct')
                );

                const suggestions = [];
                const signs = [];

                devices.forEach((d) => {
                    const entries = [
                        {
                            type: 'sign',
                            name: `${d.Shop.Sign.name} (Enseigne)`,
                            id: d.Shop.Sign.name
                        },
                        {
                            type: 'shop',
                            name: `${d.Shop.name} (Shop)`,
                            id: d.Shop.name
                        },
                        {
                            type: 'zip',
                            name: `${d.Shop.zip}`,
                            id: d.Shop.zip
                        },
                        {
                            type: 'city',
                            name: `${d.Shop.city.toUpperCase()} (Ville)`,
                            id: d.Shop.city.toUpperCase()
                        },
                        {
                            type: 'departement',
                            name: `${d.Shop.departement} (Departement)`,
                            id: d.Shop.departement
                        },
                        {
                            type: 'region',
                            name: `${d.Shop.region} (Region)`,
                            id: d.Shop.region
                        },
                        {
                            type: 'address',
                            name: `${d.Shop.name} / ${d.Shop.Sign.name} / ${d.Shop.address} / ${d.Shop.zip} ${d.Shop.city} (Adresse)`,
                            id: d.Shop.address
                        },
                        {
                            type: 'group',
                            name: `${(d.Group || { name: 'No Group' }).name} (Groupe)`,
                            id: (d.Group || { name: 'No Group' }).name
                        }
                    ];

                    const signsEntries = [
                        {
                            type: 'sign',
                            name: `${d.Shop.Sign.name}`,
                            id: d.Shop.Sign.name
                        }
                    ];

                    entries.forEach((e) => {
                        if (!suggestions.find((s) => s.type === e.type && s.id === e.id)) {
                            if (e.id) {
                                suggestions.push(e);
                            }
                        }
                    });

                    signsEntries.forEach((e) => {
                        if (!signs.find((s) => s.type === e.type && s.id === e.id)) {
                            if (e.id) {
                                signs.push(e);
                            }
                        }
                    });
                });

                this.setState({
                    availableDevices: devices,
                    deviceSelectionSuggestions: suggestions,
                    deviceSignsSuggestions: signs
                });

                return Promise.resolve();
            });

        await fetchApi('/campaigns/agencies').then(async (res) =>
            this.setState({ availableAgencies: await res.json() })
        );
        await fetchApi('/admin/orders').then(async (res) => this.setState({ orders: await res.json() }));

        fetchApi('/admin/programmaticCategories/categories')
            .then((res) => res.json())
            .then((categories) => this.setState({ availableProgammaticCategories: categories }));

        if (this.props.match.params.id !== 'new') {
            // Get data
            fetchApi(`/campaigns/${this.props.match.params.id}`)
                .then((res) => res.json())
                .then((campaign) => {
                    campaign.startDate = new Date(campaign.startDate);
                    campaign.endDate = new Date(campaign.endDate);
                    campaign.selected = campaign.devices;
                    if (campaign.deviceFilters) {
                        campaign.deviceFilters = JSON.parse(campaign.deviceFilters);
                    }
                    if (campaign.xlsData) {
                        campaign.xlsData = JSON.parse(campaign.xlsData);
                    }

                    campaign.idContrat = campaign.idContrat || '';

                    campaign.allDevices = this.state.availableDevices.length === campaign.devices.length;

                    this.setState(campaign);
                });
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    setParentState(s) {
        this.setState(s);
    }

    handleSelectChange(option, name) {
        this.setState({
            [name]: option.value
        });
    }

    handleRemoveCreative(id) {
        this.setState({
            creatives: this.state.creatives.filter(({ _id }) => _id !== id)
        });
    }

    handleSelectDevices(filter, operation) {
        if (operation === 'add') {
            this.setState(
                {
                    deviceFilters: [...this.state.deviceFilters, filter]
                },
                () =>
                    this.setState({
                        devices: getFilteredDevices(
                            this.state.availableDevices,
                            this.state.deviceFilters,
                            this.state.allDevices,
                            this.state.deviceFilterExclude
                        ),
                        editedTargeting: true
                    })
            );
        } else if (operation === 'delete') {
            this.setState(
                {
                    deviceFilters: this.state.deviceFilters.filter((_, i) => i !== filter)
                },
                () =>
                    this.setState({
                        devices: getFilteredDevices(
                            this.state.availableDevices,
                            this.state.deviceFilters,
                            this.state.allDevices,
                            this.state.deviceFilterExclude
                        ),
                        editedTargeting: true
                    })
            );
        }
    }

    handlePlanningHour(h) {
        const planning = this.state.planning;
        const day = planning[this.state.selectedDay];

        const hIndex = day.indexOf(h);
        if (hIndex !== -1) {
            day.splice(hIndex, 1);
        } else {
            day.push(h);
        }

        day.sort((a, b) => a - b);

        this.setState({ planning });
    }

    handleSelectRange(range) {
        const planning = this.state.planning;
        const day = planning[this.state.selectedDay];

        const hIndex = day.indexOf(range[0]);
        if (hIndex !== -1) {
            // Remove all from range
            range.forEach((r) => {
                const i = day.indexOf(r);

                if (i !== -1) {
                    day.splice(i, 1);
                }
            });
        } else {
            // Remove to avoid duplicates
            range.forEach((r) => {
                const i = day.indexOf(r);

                if (i !== -1) {
                    day.splice(i, 1);
                }
            });

            range.forEach((r) => day.push(r));
        }

        day.sort((a, b) => a - b);

        this.setState({ planning });
    }

    handleApplyTimeToAll() {
        const planning = this.state.planning;
        const refDay = planning[this.state.selectedDay].map((d) => d);
        for (const day of planning) {
            day.splice(0, day.length);
            refDay.forEach((d) => day.push(d));
        }

        this.setState({ planning });
    }

    async handleUploadCreative(e) {
        if (!e.target.files.length === 0) {
            return;
        }

        this.setState({ fileUploading: e.target.files.length });

        for (const file of e.target.files) {
            let type = '';
            if (file.type === 'video/mp4') {
                type = 'video';
            } else {
                type = 'image';
            }

            const data = new FormData();
            data.append('asset', file);

            try {
                const url = await fetch(`${constants.endpoint}/campaigns/creatives/upload`, {
                    headers: {
                        'x-access-token': localStorage.getItem('id_token')
                    },
                    method: 'POST',
                    body: data
                }).then(async (res) => {
                    if (res.status === 400) {
                        const { message } = await res.json();
                        throw message;
                    }

                    return res.text();
                });

                const creative = await fetchApi('/campaigns/creatives', {
                    method: 'POST',
                    body: JSON.stringify({
                        filename: url,
                        type: type
                    })
                }).then((res) => res.json());

                this.setState({
                    creatives: [...this.state.creatives, creative],
                    fileUploading: this.state.fileUploading - 1
                });
            } catch (error) {
                console.error(error);
                notify.show(error.toString(), 'error');
                this.setState({ fileUploading: 0 });
            }
        }
    }

    handleDownloadCSV() {
        const shops = [];

        shops.push([
            'id_shop',
            'nom',
            'enseigne',
            'adresse',
            'code_postal',
            'ville',
            'nb_ecrans',
            'code_lsa',
            'lat',
            'lng'
        ]);

        this.state.devices.forEach((_id) => {
            const dev = this.state.availableDevices.find(({ id }) => id === _id);
            if (!dev) return;
            const shop = dev.Shop;

            let found = false;

            for (let i = 0; i < shops.length; i++) {
                if (shops[i][0] === shop.id) {
                    shops[i][6]++;
                    found = true;
                }
            }

            if (!found) {
                shops.push([
                    shop.id,
                    shop.name,
                    shop.Sign.name,
                    shop.address,
                    shop.zip,
                    shop.city,
                    1,
                    shop.lsa,
                    (shop.lat || '0').replace('.', ','),
                    (shop.long || '0').replace('.', ',')
                ]);
            }
        });

        window.XlsxPopulate.fromBlankAsync()
            .then((workbook) => {
                const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
                for (let li = 0; li < shops.length; li++) {
                    for (let i = 0; i < letters.length; i++) {
                        workbook
                            .sheet('Sheet1')
                            .cell(`${letters[i]}${li + 1}`)
                            .value(shops[li][i]);
                    }
                }

                return workbook.outputAsync();
            })
            .then((blob) => {
                window.download(blob, 'Sélection.xlsx');
            });
    }

    handleSubmit() {
        if (this.state.fileUploading > 0) {
            return alert('Your file is uploading, please wait...');
        }

        if (this.state.slots > 5 || this.state.slots <= 0) {
            return alert('Invalid slots value');
        }

        if (
            this.state.editedTargeting &&
            !window.confirm(`La campagne sera assignée à ${this.state.devices.length} écrans. Enregistrer?`)
        ) {
            return;
        }

        if (this.state.planning.every((d) => d.length === 0)) {
            return alert('Merci de renseigner les horaires de la campagne');
        }

        const {
            _id,
            name,
            groupName,
            startDate,
            endDate,
            agency,
            planning,
            notes,
            creatives,
            contact,
            advertiser,
            adContact,
            commercial,
            deviceFilters,
            deviceFilterExclude,
            brand,
            devices,
            slots,
            strategy,
            xlsData,
            xlsFile,
            idContrat,
            triggered,
            message,
            eans,
            hideFromInterface,
            programmaticCategories,
            logoUrl
        } = this.state;

        if (!this.state._id) {
            // Creation
            fetchApi('/campaigns', {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    groupName: groupName || undefined,
                    agency,
                    startDate,
                    endDate,
                    devices,
                    planning,
                    notes,
                    contact,
                    advertiser,
                    adContact,
                    commercial,
                    brand,
                    slots,
                    strategy,
                    deviceFilters: JSON.stringify(deviceFilters),
                    deviceFilterExclude,
                    creatives: creatives.map(({ _id }) => _id),
                    xlsData: JSON.stringify(xlsData),
                    xlsFile,
                    idContrat,
                    triggered,
                    message,
                    eans,
                    hideFromInterface,
                    programmaticCategories,
                    logoUrl
                })
            }).then(async (res) => {
                if (res.status === 204) {
                    this.props.history.push('/campaigns');
                } else if (res.status === 400) {
                    const errors = await res.json();
                    this.setState({ errors: errors });
                    console.log('Bad Request:', errors);
                    notify.show('Error creating campaign', 'error');
                } else if (res.status === 409) {
                    const conflicts = await res.json();
                    this.setState({
                        errors: [conflicts.message, ...conflicts.devices.map((d) => `    - Device with id #${d.id}`)]
                    });
                    console.log(conflicts);
                }
            });
        } else {
            // Update
            fetchApi('/campaigns/' + _id, {
                method: 'PUT',
                body: JSON.stringify({
                    name,
                    groupName: groupName || undefined,
                    agency,
                    startDate,
                    endDate,
                    devices,
                    planning,
                    notes,
                    contact,
                    advertiser,
                    adContact,
                    commercial,
                    brand,
                    slots,
                    strategy,
                    deviceFilters: JSON.stringify(deviceFilters),
                    deviceFilterExclude,
                    creatives: creatives.map(({ _id }) => _id),
                    xlsData: JSON.stringify(xlsData),
                    xlsFile,
                    idContrat,
                    triggered,
                    message,
                    eans,
                    hideFromInterface,
                    programmaticCategories,
                    logoUrl
                })
            }).then(async (res) => {
                if (res.status === 200) {
                    this.props.history.push('/campaigns');
                } else if (res.status === 400) {
                    const errors = await res.json();
                    this.setState({ errors: errors });
                    console.log('Bad Request:', errors);
                    notify.show('Error creating campaign', 'error');
                } else if (res.status === 409) {
                    const conflicts = await res.json();
                    this.setState({
                        errors: [conflicts.message]
                    });
                    console.log(conflicts);
                }
            });
        }
    }

    async handleXlsUpload(file) {
        const data = new FormData();
        data.append('file', file);

        if (!this.state._id) {
            return alert("Merci d'enregistrer la campagne avant d'ajouter un fichier");
        }

        const fileName = await fetch(`${constants.endpoint}/campaigns/xls/${this.state._id}`, {
            headers: {
                'x-access-token': localStorage.getItem('id_token')
            },
            method: 'POST',
            body: data
        }).then((res) => res.text());

        this.setState({ xlsFile: fileName });
    }

    render() {
        const isDyl = this.props.user.Agent.id === 1;

        return (
            <div className="CampaignsEditor">
                <MapModal
                    modalMap={this.state.modalMap}
                    handleClose={(_) => this.setState({ modalMap: false })}
                    screens={this.state.availableDevices.filter(({ id }) => this.state.devices.indexOf(id) !== -1)}
                />
                <Panel>
                    <div style={{ textAlign: 'right' }}>
                        <button className="btn btn-info saveall" onClick={this.handleSubmit}>
                            Enregistrer
                        </button>
                    </div>
                    <Panel>
                        <Panel.Heading>
                            <h4>Informations générales</h4>
                        </Panel.Heading>
                        <Panel.Body>
                            <General
                                {...this.state}
                                setDate={(e) =>
                                    this.setState({
                                        startDate: e[0],
                                        endDate: e[1]
                                    })
                                }
                                isDyl={isDyl}
                                handleChange={this.handleChange}
                                handleSelectChange={this.handleSelectChange}
                                onCheckTriggered={(e) => this.setState({ triggered: e, idContrat: '' })}
                                onCheckHidden={(e) => this.setState({ hideFromInterface: e })}
                            />
                            {!isDyl || (
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="msg">Message</label>
                                        <CKEditor
                                            content={this.state.message}
                                            events={{
                                                change: (e) => {
                                                    this.setState({ message: e.editor.getData() });
                                                }
                                            }}
                                            scriptUrl="https://cdn.ckeditor.com/4.9.2/full/ckeditor.js"
                                            config={this.editorConfig}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>EAN / Identifiants</label>
                                                {this.state.eans.map((e, i) => (
                                                    <div key={i} className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    onChange={(e) => {
                                                                        const data = this.state.eans;
                                                                        const elt = data[i];
                                                                        const comps = elt.split(';');
                                                                        data[i] = `${e.target.value};${comps[1]}`;

                                                                        this.setState({ eans: data });
                                                                    }}
                                                                    value={e.split(';')[0]}
                                                                />
                                                            </div>
                                                            <div className="col-md-5">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    onChange={(e) => {
                                                                        const data = this.state.eans;
                                                                        const elt = data[i];
                                                                        const comps = elt.split(';');
                                                                        data[i] = `${comps[0]};${e.target.value}`;

                                                                        this.setState({ eans: data });
                                                                    }}
                                                                    value={e.split(';')[1]}
                                                                />
                                                            </div>
                                                            <div className="col-md-2">
                                                                <i
                                                                    className="fa fa-times"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            eans: this.state.eans.filter(
                                                                                (_, idx) => i !== idx
                                                                            )
                                                                        })
                                                                    }
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button
                                                            className="btn btn-info"
                                                            onClick={() =>
                                                                this.setState({
                                                                    eans: [...this.state.eans, ';']
                                                                })
                                                            }
                                                        >
                                                            Ajouter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row" style={{ marginTop: 24 }}>
                                <div className="col-md-12">
                                    <label>Catégories programmatiques</label>
                                    {this.state.programmaticCategories.map((e) => {
                                        const category = this.state.availableProgammaticCategories.find(
                                            ({ _id }) => _id === e
                                        );

                                        if (!category) {
                                            return <div className="label">Unknown category</div>;
                                        }

                                        return (
                                            <div className="label-bs-grouped" key={e}>
                                                <span className="label label-default">{category.name}</span>
                                                <span
                                                    className="label label-default second"
                                                    onClick={() =>
                                                        this.setState({
                                                            programmaticCategories:
                                                                this.state.programmaticCategories.filter(
                                                                    (id) => id !== e
                                                                )
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        );
                                    })}
                                    <div className="row">
                                        <SelectProgrammaticCategory
                                            availableProgammaticCategories={
                                                this.state.availableProgammaticCategories
                                            }
                                            onClick={(value) =>
                                                this.setState({
                                                    programmaticCategories: [
                                                        ...this.state.programmaticCategories,
                                                        value
                                                    ]
                                                })
                                            }
                                            selected={this.state.programmaticCategories}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Panel.Body>
                    </Panel>
                    <Row>
                        <Col md={5}>
                            <Panel>
                                <Panel.Heading>
                                    <h4>Targeting</h4>
                                </Panel.Heading>
                                <Panel.Body>
                                    <Targeting
                                        selected={this.state.devices}
                                        availableDevices={this.state.availableDevices}
                                        suggestions={this.state.deviceSelectionSuggestions}
                                        signsSuggestions={this.state.deviceSignsSuggestions}
                                        handleSelectDevices={this.handleSelectDevices}
                                        filters={this.state.deviceFilters}
                                        handleSelectAll={(isChecked) =>
                                            this.setState({ allDevices: isChecked }, () =>
                                                this.setState({
                                                    devices: getFilteredDevices(
                                                        this.state.availableDevices,
                                                        this.state.deviceFilters,
                                                        this.state.allDevices,
                                                        this.state.deviceFilterExclude
                                                    )
                                                })
                                            )
                                        }
                                        xlsData={this.state.xlsData}
                                        xlsFile={this.state.xlsFile}
                                        setParentState={this.setParentState}
                                        manualFilters={this.state.manualFilters}
                                        manualTarget={this.state.manualTarget}
                                        manualFiltered={getFilteredDevices(
                                            this.state.availableDevices,
                                            this.state.manualFilters,
                                            this.state.manualFilters.length === 0,
                                            this.state.deviceFilterExclude
                                        )}
                                        handleChangeExclude={(isExclude) =>
                                            this.setState({ deviceFilterExclude: isExclude }, () => {
                                                this.setState({
                                                    devices: getFilteredDevices(
                                                        this.state.availableDevices,
                                                        this.state.deviceFilters,
                                                        this.state.allDevices,
                                                        this.state.deviceFilterExclude
                                                    )
                                                });
                                            })
                                        }
                                        handleXlsUpload={this.handleXlsUpload}
                                        handleDownloadCSV={this.handleDownloadCSV}
                                        handleOpenMap={(_) => this.setState({ modalMap: true })}
                                        deviceFilterExclude={this.state.deviceFilterExclude}
                                        allDevices={this.state.allDevices}
                                    />
                                </Panel.Body>
                            </Panel>
                        </Col>
                        {!isDyl || (
                            <Col md={5}>
                                <Pricing
                                    selected={this.state.devices}
                                    planning={this.state.planning}
                                    slots={this.state.slots}
                                    _id={this.state._id}
                                    revenue={this.state.revenue}
                                />
                            </Col>
                        )}
                        <Col md={2}>
                            <Panel>
                                <Panel.Heading>
                                    <h4>Slots</h4>
                                </Panel.Heading>
                                <Panel.Body>
                                    <div className="form-group">
                                        <label htmlFor="slots">Nombre de slots</label>
                                        <input
                                            type="number"
                                            name="slots"
                                            className="form-control"
                                            value={this.state.slots}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Panel.Body>
                            </Panel>
                        </Col>
                    </Row>
                    <Panel>
                        <Modal show={this.state.selectedDay !== null} className="ModalPlanning">
                            <Modal.Header>
                                <h4>{days[this.state.selectedDay]}</h4>
                            </Modal.Header>
                            {this.state.selectedDay === null || (
                                <Modal.Body>
                                    <h6 className="center">Choix tranches</h6>
                                    <div className="slices">
                                        <div
                                            className={`block${
                                                [7, 8, 9, 10].every(
                                                    (hc) =>
                                                        this.state.planning[this.state.selectedDay].indexOf(hc) !== -1
                                                )
                                                    ? ' selected'
                                                    : ''
                                            }`}
                                            onClick={() => this.handleSelectRange([7, 8, 9, 10])}
                                        >
                                            <span>7h-11h</span>
                                        </div>
                                        <div
                                            className={`block${
                                                [11, 12, 13, 14, 15, 16].every(
                                                    (hc) =>
                                                        this.state.planning[this.state.selectedDay].indexOf(hc) !== -1
                                                )
                                                    ? ' selected'
                                                    : ''
                                            }`}
                                            onClick={() => this.handleSelectRange([11, 12, 13, 14, 15, 16])}
                                        >
                                            <span>11h-17h</span>
                                        </div>
                                        <div
                                            className={`block${
                                                [17, 18, 19, 20, 21, 22].every(
                                                    (hc) =>
                                                        this.state.planning[this.state.selectedDay].indexOf(hc) !== -1
                                                )
                                                    ? ' selected'
                                                    : ''
                                            }`}
                                            onClick={() => this.handleSelectRange([17, 18, 19, 20, 21, 22])}
                                        >
                                            <span>17h-22h</span>
                                        </div>
                                    </div>
                                    <h6 className="center">Choix heures</h6>
                                    <div className="timing">
                                        {Array(24)
                                            .fill(0)
                                            .map((_, h) => (
                                                <div
                                                    className={`block${
                                                        this.state.planning[this.state.selectedDay].indexOf(h) !==
                                                        -1
                                                            ? ' selected'
                                                            : ''
                                                    }`}
                                                    key={h}
                                                    onClick={() => this.handlePlanningHour(h)}
                                                >
                                                    <span>{h}</span>
                                                </div>
                                            ))}
                                    </div>
                                </Modal.Body>
                            )}
                            <Modal.Footer>
                                <button className="btn btn-default" onClick={this.handleApplyTimeToAll}>
                                    Appliquer à tous
                                </button>
                                <button
                                    className="btn btn-default"
                                    onClick={() => this.setState({ selectedDay: null })}
                                >
                                    Enregistrer
                                </button>
                            </Modal.Footer>
                        </Modal>
                        <Panel.Heading>
                            <h4>Planning</h4>
                        </Panel.Heading>
                        <Panel.Body>
                            <Row>
                                <Col md={12}>
                                    <Planning
                                        planning={this.state.planning}
                                        openTimeModal={(d) => this.setState({ selectedDay: d })}
                                    />
                                </Col>
                            </Row>
                        </Panel.Body>
                    </Panel>
                    <Row>
                        <Col lg={8}>
                            <Panel>
                                <Panel.Heading>
                                    <h4>Cr&eacute;atives</h4>
                                </Panel.Heading>
                                <Panel.Body>
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*,video/*"
                                        ref="creaFile"
                                        style={{ display: 'none' }}
                                        onChange={this.handleUploadCreative}
                                    />
                                    <Creatives
                                        handleFileClick={() => this.refs.creaFile.click()}
                                        creatives={this.state.creatives}
                                        loading={this.state.fileUploading}
                                        handleRemoveCreative={this.handleRemoveCreative}
                                        updateCreatives={(creatives) => this.setState({ creatives })}
                                    />
                                </Panel.Body>
                            </Panel>
                        </Col>
                        {!isDyl || (
                            <Col lg={4}>
                                <Panel>
                                    <Panel.Heading>
                                        <h4>Strat&eacute;gie de rotation</h4>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <select
                                            className="form-control"
                                            name="strategy"
                                            value={this.state.strategy}
                                            onChange={this.handleChange}
                                        >
                                            <option value="linear">Lin&eacute;aire</option>
                                            <option value="minute">Minute</option>
                                            <option value="hour">Heure</option>
                                            <option value="day">Jour</option>
                                        </select>
                                    </Panel.Body>
                                </Panel>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col lgOffset={3} lg={6}>
                            {this.state.errors.length === 0 || (
                                <div className="CampaignErrors">
                                    <ul>
                                        {this.state.errors.map((e) => (
                                            <li key={e}>{e}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </Col>
                    </Row>

                    <div style={{ textAlign: 'right' }}>
                        <button className="btn btn-info saveall" onClick={this.handleSubmit}>
                            Enregistrer
                        </button>
                    </div>
                </Panel>
            </div>
        );
    }
}
