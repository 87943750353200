import React from 'react';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import { Bar as BarChart } from 'react-chartjs';
import { Panel } from 'react-bootstrap';

const colors = [
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
];

export default class StatsUser extends React.Component {
    constructor() {
        super();

        this.state = {
            from: new Date() - 15 * 60 * 60 * 24 * 1000,
            data: []
        };

        this.Auth = new AuthService();

        this.getData = this.getData.bind(this);
    }

    getData() {
        this.Auth.fetch(
            `${constants.endpoint}/admin/stats/${
                this.props.match.params.id
            }?from=${this.state.from}`
        )
            .then(res => {
                this.setState({
                    data: res.sort((a, b) => {
                        if (a._id.year > b._id.year) {
                            return 1;
                        } else if (b._id.year > a._id.year) {
                            return -1;
                        }

                        if (a._id.month > b._id.month) {
                            return 1;
                        } else if (b._id.month > a._id.month) {
                            return -1;
                        }

                        if (a._id.dayOfMonth > b._id.dayOfMonth) {
                            return 1;
                        }

                        return -1;
                    })
                });
            })
            .catch(err => console.error(err));
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        if (this.state.data.length === 0) {
            return 'No data';
        }

        const actions = [];

        this.state.data.forEach(d =>
            d.actions.forEach(a => {
                if (actions.indexOf(a) === -1) {
                    actions.push(a);
                }
            })
        );

        const datasets = actions.map((a, i) => {
            return {
                label: a,
                fillColor: colors[i],
                strokeColor: colors[i] + 'd0',
                highlightFill: colors[i] + 'd0',
                highlightStroke: colors[i],
                data: this.state.data.map(d => {
                    const i = d.actions.indexOf(a);
                    if (i !== -1) {
                        return d.counts[i];
                    } else {
                        return 0;
                    }
                })
            };
        });

        const data = {
            labels: this.state.data.map(
                d => d._id.dayOfMonth + '/' + d._id.month + '/' + d._id.year
            ),
            datasets: datasets
        };

        return (
            <Panel>
                <p>Utilisation de l'application</p>
                <BarChart data={data} options={{ responsive: true }} />
            </Panel>
        );
    }
}
