import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Preview from './Preview';
import constants, { formats } from '../../../Constants';
import TagsInput from 'react-tagsinput';
import Calendar from 'react-calendar';

export default class Edit extends React.Component {
    constructor() {
        super();

        this.creative = null;

        this.handleChangeTags = this.handleChangeTags.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSwitchSign = this.handleSwitchSign.bind(this);
    }

    handleChangeTags(tags) {
        this.creative.tags = tags;
        this.forceUpdate();
    }

    handleSwitchSign(signId) {
        const id = this.creative.signId.indexOf(signId);
        if (id !== -1) {
            this.creative.signId.splice(id, 1);
        } else {
            this.creative.signId.push(signId);
        }

        this.forceUpdate();
    }

    handleSave() {
        this.props.handleSave(this.creative);
    }

    render() {
        if (this.props.selected === null) {
            return null;
        }

        const creative = this.props.creatives.find(({ _id }) => _id === this.props.selected);

        if (!creative) {
            return null;
        }

        this.creative = creative;

        const slides = this.props.slides.find(({ _id }) => _id === this.props.selected);

        const shopsUsing = new Set();

        if (slides) {
            slides.devices.forEach((assigned) =>
                assigned.forEach((dev) => shopsUsing.add(this.props.devices.find(({ id }) => id === dev).Shop.name))
            );
        }

        return (
            <Modal show={this.props.modalEditOpened} onHide={this.props.close}>
                <Modal.Header>Edition d'une affiche</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <Preview
                                preview={`${constants.cloud_storage}/Assets/${creative.filename}`}
                                preview_fallback={`${constants.endpoint}/static/assets/${creative.filename}`}
                                type={creative.type}
                            />
                            <div className="mT-10">Orientation: {formats[creative.orientation].name}</div>
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={12}>
                                    <div className="infos">
                                        {!creative.groupId || (
                                            <p>
                                                Appartient au groupe:{' '}
                                                {this.props.groups.find(({ id }) => id === creative.groupId).name}
                                            </p>
                                        )}
                                        {!!this.props.signRespo || (
                                            <React.Fragment>
                                                <p>Disponible pour les enseignes:</p>
                                                <ul>
                                                    {this.props.signs.map((s) => (
                                                        <li key={s.id}>
                                                            <input
                                                                type={this.props.signRespo ? 'hidden' : 'checkbox'}
                                                                checked={creative.signId.indexOf(s.id) !== -1}
                                                                onChange={() => this.handleSwitchSign(s.id)}
                                                            />
                                                            {s.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                        )}
                                        <p>Utilisé par les lieux:</p>
                                        <ul style={{ maxHeight: 160, overflowY: 'scroll' }}>
                                            {[...shopsUsing].map((s) => (
                                                <li key={s}>{s}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <label htmlFor="tags">Tags</label>
                                    <TagsInput value={creative.tags} onChange={this.handleChangeTags} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <label className="mT-20" htmlFor="expiration">
                                        Expiration
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="expiration"
                                        checked={!!this.creative.defaultExpiration}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.creative.defaultExpiration = new Date();
                                            } else {
                                                this.creative.defaultExpiration = null;
                                            }

                                            this.forceUpdate();
                                        }}
                                    />
                                    {!this.creative.defaultExpiration || (
                                        <Calendar
                                            value={new Date(this.creative.defaultExpiration)}
                                            onChange={(e) => {
                                                this.creative.defaultExpiration = e;
                                                this.forceUpdate();
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {!!this.props.signRespo || (
                        <button className="btn btn-danger" onClick={() => this.props.handleDelete(creative._id)}>
                            Supprimer
                        </button>
                    )}
                    <button className="btn btn-info" onClick={this.handleSave}>
                        Enregistrer
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
