import React from 'react';
import constants from '../../../Constants';

const AssetThumb = ({ id, src, orientation, onClick, type, onSelect, selected, selectable, isProcessing }) => {
    return (
        <div className="asset-thumb" data-id={id}>
            <img
                className={`${orientation}${selected ? ' selected' : ''}`}
                alt={src}
                onClick={() => {
                    if (isProcessing) return;
                    onClick();
                }}
                onLoad={(e) => {}}
                src={`${constants.cloud_storage}/Thumbs/${src}.png`}
            />
            <div className="controls">
                {!selectable || !!isProcessing || (
                    <div className={`round check` + (selected ? ' selected' : '')} onClick={onSelect}>
                        <i className="fas fa-check"></i>
                    </div>
                )}
                <div className="round type">
                    <i className={`fa fa-${type.replace('video', 'film')}`}></i>
                </div>
                {!isProcessing || (
                    <div className="round processing">
                        <i className="fas fa-sync"></i>
                    </div>
                )}
            </div>
        </div>
    );
};

const getCount = (slide, subSlide) => {
    let count = 0;

    if (slide && slide.count) {
        count += slide.count;
    }

    if (subSlide && subSlide.count) {
        count += subSlide.count;
    }

    return count;
};

export const AssetList = ({
    assets,
    onClick,
    onSelect,
    selectedList,
    selectable,
    onAddAssetForCategory,
    signs,
    groups,
    slides,
    hideTags,
    hideNew
}) => {
    return (
        <div className="asset-list">
            {assets.map((a) => {
                const tags = [];
                for (const sign of signs) {
                    if (a.signId.includes(sign.id)) {
                        if (tags.length === 0) {
                            tags.push(
                                <span key={sign.id} className="tag sign">
                                    <i className="fa fa-shopping-cart"></i>
                                    {sign.name
                                        .replace('CARREFOUR', 'C.')
                                        .replace('INTERMARCHE EXPRESS', 'ITM. EXP.')
                                        .replace('INTERMARCHE', 'ITM.')
                                        .replace('O MARCHE FRAIS', 'OMF')
                                        .replace('COCCINELLE EXPRESS', 'COCC. EXPR.')}
                                </span>
                            );
                        } else {
                            tags[0] = (
                                <span key={sign.id} className="tag sign">
                                    <i className="fa fa-shopping-cart"></i>
                                    Multi
                                </span>
                            );
                        }
                    }
                }

                if (a.groupId) {
                    const group = groups.find(({ id }) => id === a.groupId);
                    if (!!group) {
                        tags.push(
                            <span key={group.id} className="tag group">
                                <i className="fa fa-users"></i>
                                {group.name}
                            </span>
                        );
                    }
                }

                tags.push(
                    <span key={a._id} className="tag usage">
                        <i className="fa fa-play"></i>Util: {getCount(slides.find(({ _id }) => _id === a._id))}
                    </span>
                );

                return (
                    <div key={a._id} className="asset">
                        <AssetThumb
                            onSelect={() => onSelect(a)}
                            selected={selectedList.findIndex(({ _id }) => _id === a._id) !== -1}
                            src={a.filename}
                            type={a.type}
                            id={a._id}
                            orientation={a.orientation}
                            onClick={() => onClick(a)}
                            selectable={selectable}
                            isProcessing={a.isProcessing}
                        />
                        {!!hideTags || <div className="tags">{tags}</div>}
                    </div>
                );
            })}
            {!!hideNew || <div className="add-asset" onClick={onAddAssetForCategory}></div>}
        </div>
    );
};
