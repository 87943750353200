import React from 'react';

const Attributes = (props) => {
    return (
        <div>
            <div className="form-group">
                <label>Titre</label>
                <input
                    type="text"
                    name="category"
                    value={props.category}
                    onChange={props.onChange}
                    className="form-control"
                />
            </div>
            <div className="form-group">
                <label>Sous-titre</label>
                <input
                    type="text"
                    name="subtitle"
                    value={props.subtitle}
                    onChange={props.onChange}
                    className="form-control"
                />
            </div>
        </div>
    );
};

export default Attributes;
