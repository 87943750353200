import React, { useState } from 'react';

const DynItem = ({ thumbnail, name, onClick, isSelected, orientation }) => (
    <div className={isSelected ? `templateItemFree selected ${orientation}` : `templateItemFree`}>
        <img src={thumbnail} alt={name} onClick={onClick} />
        <p className="nomargin">{name}</p>
    </div>
);

export const DynamicListModal = (props) => {
    const [state, setState] = useState({
        selected: null
    });

    return (
        <div>
            <header>
                <div className="buttons">
                    <button
                        className="button-assistant"
                        disabled={state.selected === null}
                        onClick={() => props.onSave(state.selected)}
                    >
                        Enregistrer
                    </button>
                </div>
            </header>
            <div className="templateList">
                {props.dynamics.map((d) => (
                    <DynItem
                        {...d}
                        key={d._id}
                        onClick={() => setState({ ...state, selected: d })}
                        isSelected={!!state.selected && d._id === state.selected._id}
                    />
                ))}
            </div>
        </div>
    );
};

export const PreviewDynamic = (props) => {
    return (
        <div>
            <img style={{ maxWidth: '100%' }} src={props.thumbnail} alt={props.name} />
        </div>
    );
};
