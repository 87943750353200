import React from 'react';

import { Table, Panel, Row, Col } from 'react-bootstrap';

import dateFormat from 'dateformat';
import { Line as LineChart } from 'react-chartjs';
import { notify } from 'react-notify-toast';

import AuthService from '../../Components/Auth/AuthService';
import constants, { formats } from '../../Constants';
import { ScreenMapComponent } from './Map';
import swal from 'sweetalert';

import Slide from '../../Components/Editor/Slide';
import { fetchApi } from '../../Services/NetworkServcice';
import ScreenManagementTimes from './ScreenManagementTimes';

const getLabel = (status) => {
    switch (status) {
        case 'info':
            return <span className="label label-info">info</span>;
        case 'warning':
            return <span className="label label-warning">warning</span>;
        case 'error':
            return <span className="label label-danger">error</span>;
        default:
            return <span className="label">other</span>;
    }
};

const getAdditionalInfo = (additionalInformation) => {
    if (typeof additionalInformation === 'object') {
        return JSON.stringify(additionalInformation);
    }

    return additionalInformation.toString();
};

const formatDevStatus = (status) => {
    if (status[0] === '{') {
        const s = JSON.parse(status);

        return (
            <div>
                <h5>Section: {s.section}</h5>
                <p>{getLabel(s.level)}</p>
                <p>{dateFormat(new Date(s.date || 0), 'dd/mm HH:MM')}</p>
                <p>Details: {s.details}</p>
                {!s.additionalInformation || <p>Additional info: {getAdditionalInfo(s.additionalInformation)}</p>}
            </div>
        );
    } else {
        return status;
    }
};

class ScreenManagement extends React.Component {
    constructor() {
        super();
        this.state = {
            powerOn: 'XX:XX:XX',
            powerOff: 'XX:XX:XX',
            name: '',
            change: false,
            changePower: false,
            autoOnOff: 1,
            ledOff: false,
            screenName: null,
            staticIp: null,
            routerIp: null,
            archives: [],
            screenSelected: 0,
            shopScreens: [],
            onOffTimes: null,
            modalEditTimes: false,
            subsequence: null,
            signSubsequence: null,
            orientation: '',
            model: 'v0.1prod',
            history: [],
            isDisabled: false
        };

        this.Auth = new AuthService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSendCommand = this.handleSendCommand.bind(this);
        this.getData = this.getData.bind(this);
        this.clearLog = this.clearLog.bind(this);
        this.decomission = this.decomission.bind(this);
        this.handleChangeVariable = this.handleChangeVariable.bind(this);
        this.handleChangeVariableText = this.handleChangeVariableText.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.disableScreen = this.disableScreen.bind(this);
        this.enableScreen = this.enableScreen.bind(this);

        this.interval = null;
    }

    getData() {
        this.Auth.fetch(`${constants.endpoint}/admin/devInfos/${this.props.match.params.id}`).then((dev) => {
            if (dev.stats) {
                dev.stats.dev.log.reverse();
                dev.stats.log.reverse();
                dev.stats.dev.errors.reverse();
            } else {
                dev.stats = {
                    dev: {
                        log: [],
                        errors: [],
                        stats: []
                    },
                    log: []
                };
            }

            this.setState(
                {
                    dev: dev,
                    powerOn: dev.device.powerOn,
                    powerOff: dev.device.powerOff,
                    name: dev.device.name,
                    screenName: dev.device.name,
                    onOffTimes: JSON.parse(dev.device.onOffTimes),
                    sequence: dev.userSequence,
                    subsequence: dev.subsequence,
                    signSubsequence: dev.signSubsequence,
                    autoOnOff: dev.device.autoOnOff,
                    ledOff: dev.device.ledOff,
                    archives: dev.archives.reverse(),
                    orientation: dev.device.orientation,
                    isDisabled: dev.device.isDisabled,
                    model: dev.device.model
                },
                () => {
                    if (this.props.assistant) {
                        fetchApi(`/admin/shops/${dev.device.ShopId}/devices`)
                            .then((res) => res.json())
                            .then((devs) => this.setState({ shopScreens: devs }));
                    }

                    fetchApi(`/admin/history/${dev.device.ShopId}`)
                        .then((res) => res.json())
                        .then(({ history }) => this.setState({ history }));
                }
            );
        });
    }

    componentDidMount() {
        this.getData();

        this.interval = setInterval(() => {
            this.getData();
        }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleSubmit() {
        const data = {
            powerOn: this.state.powerOn,
            powerOff: this.state.powerOff,
            autoOnOff: this.state.autoOnOff,
            ledOff: this.state.ledOff,
            dylProperty: this.state.dylProperty,
            model: this.state.model
        };

        this.Auth.fetch(`${constants.endpoint}/admin/onOff/${this.props.match.params.id}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then((res) => {
            this.setState({
                change: false,
                changeName: false
            });

            return notify.show('Mis à jour!', 'success');
        });
    }

    handleChangeName() {
        fetchApi(`/admin/devName/${this.props.match.params.id}`, {
            method: 'PUT',
            body: JSON.stringify({ name: this.state.name, orientation: this.state.orientation })
        }).then((res) => {
            if (res.status === 200) {
                this.setState({
                    change: false,
                    changeName: false
                });

                return notify.show('Mis à jour!', 'success');
            }
        });
    }

    handleSendCommand() {
        swal({
            text: `Êtes-vous sur de vouloir cette commande ' ${this.refs.command.value} '`,
            title: 'La commande ne pourra pas être annulée',
            icon: 'warning',
            buttons: ['Annuler', 'Go'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this.Auth.fetch(`${constants.endpoint}/admin/command/${this.props.match.params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify({ command: this.refs.command.value })
                });

                swal(`Commmande effectué avec succés`, {
                    icon: 'success'
                });

                setTimeout(() => this.getData(), 1500);
            } else {
                swal('Commande annulée');
            }
        });
    }

    handleChange(e, submit = false) {
        let varToUpdate = 'change';

        switch (e.target.name) {
            case 'name':
                varToUpdate = 'changeName';
                break;
            default:
                break;
        }

        this.setState(
            {
                [e.target.name]: e.target.value,
                [varToUpdate]: true
            },
            () => {
                if (submit) {
                    this.handleSubmit();
                }
            }
        );
    }

    clearLog(name) {
        if (window.confirm('Êtes-vous sûr ?')) {
            this.Auth.fetch(`${constants.endpoint}/admin/clearLogs/${this.props.match.params.id}/${name}`, {
                method: 'DELETE'
            })
                .then((res) => {
                    if (res.success) {
                        return notify.show('Log cleared', 'success');
                    }
                })
                .catch((err) => console.error(err));
        }
    }

    decomission() {
        if (
            window.confirm(
                "Cette opération va supprimer définitivement l'écran ainsi que toutes ses données. Êtes-vous sûr de bien vouloir continuer?"
            )
        ) {
            if (window.confirm('Appuyer sur OK pour confirmer la SUPPRESSION DEFINITIVE')) {
                this.Auth.fetch(`${constants.endpoint}/admin/screen/${this.props.match.params.id}`, {
                    method: 'DELETE'
                })
                    .then((res) => {
                        if (res.success) {
                            this.props.history.replace('/screens');
                            return notify.show('Log cleared', 'success');
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    }

    disableScreen() {
        swal({
            title: 'Désactivation',
            text: 'Cet écran sera désactivé temporairement et ne sera pas visible sur les outils de planification de campagne.',
            buttons: ['Annuler', 'Continuer']
        }).then((res) => {
            if (res) {
                fetchApi(`/admin/disabled/${this.props.match.params.id}`, {
                    method: 'PATCH',
                    body: JSON.stringify({ disabled: true })
                }).then(this.getData);
            }
        });
    }

    enableScreen() {
        swal({
            title: 'Activation',
            text: "Cet écran a été désactivé temporairement n'est pas visible sur les outils de planification de campagne. Voulez-vous le réactiver?",
            buttons: ['Non', 'Oui']
        }).then((res) => {
            if (res) {
                fetchApi(`/admin/disabled/${this.props.match.params.id}`, {
                    method: 'PATCH',
                    body: JSON.stringify({ disabled: false })
                }).then(this.getData);
            }
        });
    }

    handleChangeVariable(e) {
        fetchApi('/admin/deviceLabels/' + this.props.match.params.id, {
            method: 'PUT',
            body: JSON.stringify({
                type: e.target.name,
                value: e.target.checked
            })
        }).then((res) => {
            if (res.status === 200) {
                this.getData();
            }
        });
    }

    handleChangeVariableText(e) {
        fetchApi('/admin/deviceLabels/' + this.props.match.params.id, {
            method: 'PUT',
            body: JSON.stringify({
                type: e.target.name,
                value: e.target.value
            })
        }).then((res) => {
            if (res.status === 200) {
                this.getData();
            }
        });
    }

    render() {
        if (!this.state.dev) {
            return null;
        }

        const chartDataMem = {
            labels: this.state.dev.stats.dev.stats
                .slice(Math.max(this.state.dev.stats.dev.stats.length - 24, 0))
                .map((s) => dateFormat(s.date, 'dd/mm hh:MM')),
            datasets: [
                {
                    label: 'Memory',
                    fill: false,
                    fillColor: 'rgba(100,100,100,0.5)',
                    pointHoverRadius: 5,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.dev.stats.dev.stats
                        .slice(Math.max(this.state.dev.stats.dev.stats.length - 24, 0))
                        .map((s) => s.mem),
                    spanGaps: false
                }
            ]
        };

        const chartDataHeat = {
            labels: this.state.dev.stats.dev.stats
                .slice(Math.max(this.state.dev.stats.dev.stats.length - 24, 0))
                .map((s) => dateFormat(s.date, 'dd/mm hh:MM')),
            datasets: [
                {
                    label: 'Temperature',
                    fill: false,
                    fillColor: 'rgba(200,150,100,0.5)',
                    pointHoverRadius: 5,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.dev.stats.dev.stats
                        .slice(Math.max(this.state.dev.stats.dev.stats.length - 24, 0))
                        .map((s) => s.temp),
                    spanGaps: false
                }
            ]
        };

        const chartDataDisk = {
            labels: this.state.dev.stats.dev.stats
                .slice(Math.max(this.state.dev.stats.dev.stats.length - 24, 0))
                .map((s) => dateFormat(s.date, 'dd/mm hh:MM')),
            datasets: [
                {
                    label: 'Disk',
                    fill: false,
                    fillColor: 'rgba(0,112, 224, 0.5)',
                    pointHoverRadius: 5,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.dev.stats.dev.stats
                        .slice(Math.max(this.state.dev.stats.dev.stats.length - 24, 0))
                        .map((s) => s.disk),
                    spanGaps: false
                }
            ]
        };

        let chartDataNet = {
            labels: ['No data'],
            datasets: [
                {
                    label: 'Network',
                    fill: false,
                    data: [0]
                }
            ]
        };

        if (this.state.dev.stats.dev.net) {
            try {
                const netData = JSON.parse(this.state.dev.stats.dev.net);

                if (!netData.interfaces || netData.interfaces.length < 2) {
                    netData.interfaces = [{ traffic: { months: [] } }, { traffic: { months: [] } }];
                }

                chartDataNet = {
                    labels: netData.interfaces[1].traffic.months
                        .sort((a, b) => {
                            if (a.date.year > b.date.year) {
                                return 1;
                            } else if (a.date.year < b.date.year) {
                                return -1;
                            }

                            if (a.date.month > b.date.month) {
                                return 1;
                            }

                            return -1;
                        })
                        .map((d) => `${d.date.month}/${d.date.year}`),
                    datasets: [
                        {
                            label: 'Network (Total)',
                            fill: false,
                            fillColor: 'rgba(100, 100, 100, 0.5)',
                            pointHoverRadius: 5,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: netData.interfaces[1].traffic.months.map((d) => Math.round((d.rx + d.tx) / 1024)),
                            spanGaps: false
                        },
                        {
                            label: 'Network (Upload)',
                            fill: false,
                            fillColor: 'rgba(0,112, 224, 0.5)',
                            pointHoverRadius: 5,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: netData.interfaces[1].traffic.months.map((d) => Math.round(d.tx / 1024)),
                            spanGaps: false
                        },
                        {
                            label: 'Network (Download)',
                            fill: false,
                            fillColor: 'rgba(114, 208, 149, 0.5)',
                            pointHoverRadius: 5,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: netData.interfaces[1].traffic.months.map((d) => Math.round(d.rx / 1024)),
                            spanGaps: false
                        }
                    ]
                };
            } catch (err) {
                console.error(err);
            }
        }

        return (
            <Panel style={{ position: 'relative' }}>
                <ScreenManagementTimes
                    show={this.state.modalEditTimes}
                    hide={() => this.setState({ modalEditTimes: false })}
                    onOffTimes={this.state.onOffTimes}
                    saveDateTimes={(dt) => {
                        fetchApi('/admin/onOffTimes/' + this.props.match.params.id, {
                            method: 'PUT',
                            body: JSON.stringify({ times: dt })
                        }).then((res) => {
                            if (res.status === 200) {
                                this.setState({ onOffTimes: dt, modalEditTimes: false });
                            }
                        });
                    }}
                />
                <h3 style={{ marginTop: '0' }}>
                    GESTION - {this.state.dev.device.Shop.name} - {this.state.screenName}
                </h3>
                {!this.props.assistant || (
                    <div>
                        <p>
                            {this.state.dev.device.Shop.address} {this.state.dev.device.Shop.zip}{' '}
                            {this.state.dev.device.Shop.city}
                        </p>
                        <div>
                            <a
                                href={`https://waze.com/ul?q=${this.state.dev.device.Shop.address}%20${this.state.dev.device.Shop.zip}%20${this.state.dev.device.Shop.city}&navigate=yes`}
                                className="btn btn-default"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Waze
                            </a>
                        </div>
                        <hr />
                        {this.state.shopScreens.length <= 1 || (
                            <div style={{ display: 'flex' }}>
                                <a
                                    href={`/screens/${
                                        this.state.shopScreens.map(({ id }) => id).indexOf(this.state.dev.device.id) ===
                                        0
                                            ? this.state.dev.device.id
                                            : this.state.shopScreens[
                                                  this.state.shopScreens
                                                      .map(({ id }) => id)
                                                      .indexOf(this.state.dev.device.id) - 1
                                              ].id
                                    }`}
                                    style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
                                >
                                    <i className="fa fa-arrow-left" />
                                </a>
                                <div style={{ flexGrow: 1 }}>
                                    <p>
                                        <strong>
                                            {this.state.dev.device.name} -{' '}
                                            {this.state.shopScreens
                                                .map(({ id }) => id)
                                                .indexOf(this.state.dev.device.id) + 1}{' '}
                                            / {this.state.shopScreens.length}
                                        </strong>
                                    </p>
                                    <p>
                                        Player:{' '}
                                        {(new Date() - new Date(this.state.dev.stats.lastPing)) / 1000 <= 30 ? (
                                            <span className="label label-success">RUNNING</span>
                                        ) : (
                                            <span className="label label-danger">OFF</span>
                                        )}
                                    </p>
                                    <p>Player: {this.state.dev.stats.screenStatus}</p>
                                    <p>DERNIER PING: {dateFormat(this.state.dev.stats.lastPing, 'dd/mm - HH:MM')}</p>
                                </div>
                                <a
                                    href={`/screens/${
                                        this.state.shopScreens.map(({ id }) => id).indexOf(this.state.dev.device.id) ===
                                        this.state.shopScreens.length - 1
                                            ? this.state.dev.device.id
                                            : this.state.shopScreens[
                                                  this.state.shopScreens
                                                      .map(({ id }) => id)
                                                      .indexOf(this.state.dev.device.id) + 1
                                              ].id
                                    }`}
                                    style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}
                                >
                                    <i className="fa fa-arrow-right" />
                                </a>
                                <hr />
                            </div>
                        )}
                    </div>
                )}
                <Row>
                    <button
                        className="btn btn-info"
                        style={{
                            position: 'absolute',
                            right: '15px',
                            top: '15px',
                            display: this.props.assistant ? 'none' : 'inline-block'
                        }}
                    >
                        Clear
                    </button>
                    {this.props.assistant}
                    {!this.props.assistant && (
                        <button
                            className="btn btn-default ml-1 btn_desa_clone"
                            style={{
                                position: 'absolute',
                                right: '90px',
                                top: '15px'
                            }}
                            onClick={() => {
                                this.Auth.fetch(
                                    `${constants.endpoint}/admin/loginAs/${this.state.dev.device.ShopId}`
                                ).then((res) => {
                                    if (res.success === false) {
                                        return window.alert('Erreur');
                                    }

                                    this.Auth.tempSession(res.token, 'admin');
                                    this.Auth.tempSession(res.token, 'admin');

                                    window.location = '/';
                                });
                            }}
                        >
                            Login
                        </button>
                    )}
                    <Col md={6}>
                        Memory (%):
                        <LineChart
                            data={chartDataMem}
                            options={{
                                scaleOverride: true,
                                scaleSteps: 10,
                                scaleStepWidth: 10,
                                scaleStartValue: 0,
                                responsive: true,
                                maintainAspectRatio: false
                            }}
                            width="600"
                            height="250"
                        />
                    </Col>
                    <Col md={6}>
                        Temperature (Celcius):
                        <LineChart
                            data={chartDataHeat}
                            options={{
                                scaleOverride: true,
                                scaleSteps: 10,
                                scaleStepWidth: 10,
                                scaleStartValue: 0,
                                responsive: true,
                                maintainAspectRatio: false
                            }}
                            width="600"
                            height="250"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        % Disk ({this.state.dev.stats.dev.capacity}
                        ):
                        <LineChart
                            data={chartDataDisk}
                            options={{
                                scaleOverride: true,
                                scaleSteps: 10,
                                scaleStepWidth: 10,
                                scaleStartValue: 0,
                                responsive: true,
                                maintainAspectRatio: false
                            }}
                            width="600"
                            height="250"
                        />
                    </Col>
                    <Col md={6}>
                        Network (MiB):
                        <LineChart
                            data={chartDataNet}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false
                            }}
                            width="600"
                            height="250"
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={5}>
                        <div>
                            <div>
                                {this.state.screenSelected + 1} / {this.state.archives.length + 1}
                            </div>
                            <div className="screenShotsSlider">
                                <button
                                    disabled={this.state.screenSelected === this.state.archives.length}
                                    onClick={() =>
                                        this.setState({
                                            screenSelected: this.state.screenSelected + 1
                                        })
                                    }
                                >
                                    {'<'}
                                </button>
                                <ul
                                    style={{
                                        padding: 0,
                                        flexGrow: 1,
                                        height: this.state.dev.device.orientation === 'portrait' ? 375 : null
                                    }}
                                >
                                    <li
                                        style={{
                                            display: this.state.screenSelected === 0 ? 'block' : 'none',
                                            width: this.state.dev.device.orientation === 'portrait' ? 180 : null,
                                            margin: '0 auto'
                                        }}
                                    >
                                        <p>
                                            Screenshot ({dateFormat(new Date(this.state.dev.screenDate), 'dd/mm HH:MM')}
                                            ):
                                        </p>
                                        <img
                                            className={
                                                this.state.dev.device.orientation === 'portrait' ? 'imgPort' : ''
                                            }
                                            src={`${constants.endpoint}/admin/screenshot/${
                                                this.props.match.params.id
                                            }?d=${new Date().getTime()}`}
                                            alt="screenshot"
                                        />
                                    </li>
                                    {this.state.archives.map((a, i) => {
                                        return (
                                            <li
                                                key={a}
                                                style={{
                                                    display: this.state.screenSelected === i + 1 ? 'block' : 'none',
                                                    width:
                                                        this.state.dev.device.orientation === 'portrait' ? 180 : null,
                                                    margin: '0 auto'
                                                }}
                                            >
                                                <p>
                                                    [Archive] Screenshot (
                                                    {dateFormat(new Date(parseInt(a, 10)), 'dd/mm HH:MM')})
                                                </p>
                                                <img
                                                    className={
                                                        this.state.dev.device.orientation === 'portrait'
                                                            ? 'imgPort'
                                                            : ''
                                                    }
                                                    src={`${constants.endpoint}/admin/archive/${this.props.match.params.id}/${a}`}
                                                    alt="screenshot archive"
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                                <button
                                    disabled={this.state.screenSelected === 0}
                                    onClick={() =>
                                        this.setState({
                                            screenSelected: this.state.screenSelected - 1
                                        })
                                    }
                                >
                                    {'>'}
                                </button>
                            </div>
                        </div>
                        {this.props.assistant || (
                            <div>
                                <ScreenMapComponent
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `300px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    screen={this.state.dev.device.Shop}
                                />
                            </div>
                        )}
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={6}>
                                <Panel>
                                    <h3>Changer nom écran</h3>
                                    <div className="form-group">
                                        <label htmlFor="">Nom écran</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            className="form-control"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    Orientation: {formats[this.state.dev.device.orientation]?.name}
                                    <br />
                                    <select
                                        className="form-control"
                                        value={this.state.orientation}
                                        onChange={(e) => this.setState({ orientation: e.target.value })}
                                    >
                                        {Object.keys(formats)
                                            .sort((a, b) => formats[b].name.localeCompare(formats[a].name))
                                            .map((f) => (
                                                <option key={f} value={f}>
                                                    {formats[f].name}
                                                </option>
                                            ))}
                                    </select>
                                    <br />
                                    <button
                                        className={`btn btn-${this.state.changeName ? 'warning' : 'info'}`}
                                        onClick={this.handleChangeName}
                                    >
                                        Enregistrer
                                    </button>
                                    <br />
                                    <br />
                                    <button
                                        className="btn btn-info"
                                        onClick={() => {
                                            fetchApi(`/admin/refresh/${this.props.match.params.id}`, {
                                                method: 'PATCH'
                                            }).then(() => swal('Timestamp écran mis à jour!'));
                                        }}
                                    >
                                        Rafraichir l'écran
                                    </button>
                                </Panel>
                            </Col>
                            <Col md={6}>
                                <Panel>
                                    <h3>Auto allumage/extinction</h3>
                                    Actif{' '}
                                    <input
                                        type="checkbox"
                                        name="autoOnOff"
                                        onChange={(e) =>
                                            this.handleChange(
                                                {
                                                    target: {
                                                        name: 'autoOnOff',
                                                        value: e.target.checked ? 1 : 0
                                                    }
                                                },
                                                true
                                            )
                                        }
                                        value={this.state.autoOnOff === 1 ? true : false}
                                        checked={this.state.autoOnOff === 1 ? true : false}
                                    />
                                    <br />
                                    Technique led{' '}
                                    <input
                                        type="checkbox"
                                        name="ledOff"
                                        onChange={(e) =>
                                            this.handleChange(
                                                {
                                                    target: {
                                                        name: 'ledOff',
                                                        value: e.target.checked
                                                    }
                                                },
                                                true
                                            )
                                        }
                                        value={this.state.ledOff || this.state.dev.device.orientation === 'ring'}
                                        checked={this.state.ledOff || this.state.dev.device.orientation === 'ring'}
                                    />
                                    <div>
                                        {!this.state.onOffTimes ||
                                            ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((d, i) => (
                                                <p key={d}>
                                                    {d}: {this.state.onOffTimes[i][0]} - {this.state.onOffTimes[i][1]}
                                                </p>
                                            ))}
                                    </div>
                                    <button
                                        className={`btn btn-info`}
                                        onClick={() => this.setState({ modalEditTimes: true })}
                                    >
                                        Edit
                                    </button>
                                </Panel>
                            </Col>
                            <Col md={6}>
                                <Panel>
                                    <h3>Commandes</h3>
                                    <p>
                                        Commande en attente:{' '}
                                        <strong>{this.state.dev.device.command || 'Aucune commande en attente'}</strong>
                                    </p>
                                    <select className="form-control" ref="command">
                                        <option value="screenshot">Screenshot</option>
                                        <option value="firmwareUpdate">Firmware Update</option>
                                        <option value="changeOrientation">Changement de l'orientation</option>
                                        <option value="hdmiRestart">Relancer HDMI</option>
                                        <option value="testCec">Test CEC</option>
                                        <option value="wireless">[TESTING] Wireless data</option>
                                        <option value="report">Envoi du rapport</option>
                                        <option value="picontrol">Ouverture tunnel</option>
                                        <option value="stopControl">Fermeture tunnel</option>
                                        <option value="reboot">Reboot</option>
                                        <option value="resetConfig">[ATTENTION] Factory Reset</option>
                                    </select>
                                    <br />
                                    <button className={`btn btn-info`} onClick={this.handleSendCommand}>
                                        Envoyer
                                    </button>
                                    <br />
                                    <br />
                                    <button
                                        className="btn btn-info"
                                        onClick={() => {
                                            const staticIp = window.prompt('IP Fixe Player');

                                            if (staticIp === null) {
                                                return;
                                            }

                                            const routerIp = window.prompt('IP routeur');

                                            if (routerIp === null) {
                                                return;
                                            }

                                            swal({
                                                text: `IP Fixe: ${staticIp}; IP Routeur: ${routerIp}. Continuer?`,
                                                title: 'La commande ne pourra pas être annulée',
                                                icon: 'warning',
                                                buttons: ['Annuler', 'Oui'],
                                                dangerMode: true
                                            }).then((confirmed) => {
                                                if (confirmed) {
                                                    this.Auth.fetch(
                                                        `${constants.endpoint}/admin/command/${this.props.match.params.id}`,
                                                        {
                                                            method: 'PUT',
                                                            body: JSON.stringify({
                                                                command: `staticIp;${staticIp};${routerIp}`
                                                            })
                                                        }
                                                    ).then((res) => {
                                                        fetchApi(`/admin/staticIp/${this.props.match.params.id}`, {
                                                            method: 'PUT',
                                                            body: JSON.stringify({
                                                                staticIp: `${staticIp};${routerIp}`
                                                            })
                                                        });

                                                        this.getData();
                                                    });
                                                } else {
                                                    swal('Opération annulée');
                                                }
                                            });
                                        }}
                                    >
                                        IP Statique
                                    </button>
                                </Panel>
                            </Col>
                            <Col md={6}>
                                <Panel>
                                    <h3>Variables</h3>
                                    <input
                                        type="checkbox"
                                        name="isLan"
                                        checked={this.state.dev.device.isLan}
                                        onChange={this.handleChangeVariable}
                                    />{' '}
                                    Ethernet
                                    <br />
                                    <input
                                        type="checkbox"
                                        name="isWifi"
                                        checked={this.state.dev.device.isWifi}
                                        onChange={this.handleChangeVariable}
                                    />{' '}
                                    Wi-Fi
                                    <br />
                                    <input
                                        type="checkbox"
                                        name="is4G"
                                        checked={this.state.dev.device.is4G}
                                        onChange={this.handleChangeVariable}
                                    />{' '}
                                    4G
                                    <br />
                                    <input
                                        type="checkbox"
                                        name="isRs232"
                                        checked={this.state.dev.device.isRs232}
                                        onChange={this.handleChangeVariable}
                                    />{' '}
                                    RS232
                                    <br />
                                    <input
                                        type="checkbox"
                                        name="dylProperty"
                                        checked={this.state.dev.device.dylProperty}
                                        onChange={this.handleChangeVariable}
                                    />
                                    Propriété DoohYouLike
                                    <br />
                                    <input
                                        type="checkbox"
                                        name="orientationSwitch"
                                        checked={this.state.dev.device.orientationSwitch}
                                        onChange={this.handleChangeVariable}
                                    />
                                    Ecran inversé (orientation)
                                    <br />
                                    <input
                                        type="checkbox"
                                        name="programmaticOnly"
                                        checked={this.state.dev.device.programmaticOnly}
                                        onChange={this.handleChangeVariable}
                                    />
                                    Ecran programmatique only
                                    {/* <br /> */}
                                    {/* <select
                                        name="model"
                                        className="form-control"
                                        value={this.state.model}
                                        onChange={this.handleChangeVariableText}
                                    >
                                        <option value="v0.1prod">RPi</option>
                                        <option value="coofun">Coofun</option>
                                        <option value="ak7">AK7</option>
                                    </select> */}
                                </Panel>
                            </Col>
                        </Row>
                        <Row>
                            {!this.state.sequence ||
                                this.state.sequence.creatives
                                    .filter(({ expirationDate }) => {
                                        if (expirationDate) {
                                            return new Date() <= new Date(expirationDate);
                                        }

                                        return true;
                                    })
                                    .map((c) => (
                                        <Col key={c._id} md={4}>
                                            <Slide
                                                {...c}
                                                creative={c.crea}
                                                deleteButton={false}
                                                orientation={this.state.sequence.orientation}
                                                smallAdm={true}
                                                assistant={this.props.assistant}
                                            />
                                        </Col>
                                    ))}
                            {!this.state.subsequence ||
                                this.state.subsequence.creatives
                                    .filter(({ expirationDate }) => {
                                        if (expirationDate) {
                                            return new Date() <= new Date(expirationDate);
                                        }

                                        return true;
                                    })
                                    .map((c) => (
                                        <Col key={c._id} md={4}>
                                            <Slide
                                                {...c}
                                                red={'Groupe'}
                                                creative={c.crea}
                                                deleteButton={false}
                                                orientation={this.state.subsequence.orientation}
                                                smallAdm={true}
                                                assistant={this.props.assistant}
                                            />
                                        </Col>
                                    ))}
                            {!this.state.signSubsequence ||
                                this.state.signSubsequence.creatives
                                    .filter(({ expirationDate }) => {
                                        if (expirationDate) {
                                            return new Date() <= new Date(expirationDate);
                                        }

                                        return true;
                                    })
                                    .map((c) => (
                                        <Col key={c._id} md={4}>
                                            <Slide
                                                {...c}
                                                red={'Enseigne'}
                                                creative={c.crea}
                                                deleteButton={false}
                                                orientation={this.state.signSubsequence.orientation}
                                                smallAdm={true}
                                                assistant={this.props.assistant}
                                            />
                                        </Col>
                                    ))}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} style={{ marginTop: '1em' }}>
                        <Panel>
                            Danger zone
                            <br />
                            <button className="btn btn-danger" onClick={this.decomission}>
                                Décomissioner l'ecran
                            </button>
                            {this.state.isDisabled ? (
                                <button className="btn btn-success ml-10" onClick={this.enableScreen}>
                                    Réactiver l'écran
                                </button>
                            ) : (
                                <button className="btn btn-danger ml-10" onClick={this.disableScreen}>
                                    Désactiver l'écran
                                </button>
                            )}
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    <th>
                                        Server log{' '}
                                        <button
                                            className="btn pull-right"
                                            style={{ display: this.props.assistant ? 'none' : 'inline-block' }}
                                            onClick={() => {
                                                this.clearLog('server');
                                                const dev = this.state.dev;
                                                dev.stats.log = [];
                                                this.setState({ dev: dev });
                                            }}
                                        >
                                            Clear
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ wordBreak: 'break-all' }}>
                                {this.state.dev.stats.log.map((l, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{l}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={4}>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    <th>
                                        Device log{' '}
                                        <button
                                            className="btn pull-right"
                                            style={{ display: this.props.assistant ? 'none' : 'inline-block' }}
                                            onClick={() => {
                                                this.clearLog('device');
                                                const dev = this.state.dev;
                                                dev.stats.dev.log = [];
                                                this.setState({ dev: dev });
                                            }}
                                        >
                                            Clear
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ wordBreak: 'break-all' }}>
                                {this.state.dev.stats.dev.log.map((l, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{formatDevStatus(l)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={4}>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    <th>
                                        Suivi{' '}
                                        <button className="btn pull-right" style={{ display: 'inline-block' }} disabled>
                                            Clear
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ wordBreak: 'all' }}>
                                {this.state.history.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <div className="history-admin-screen-management">
                                                    <p>Date: {dateFormat(e.date, 'dd/mm/yy HH:MM')}</p>
                                                    <p>
                                                        Title: {e.title} <i className={e.type}></i>
                                                    </p>
                                                    <p>Message: {e.message}</p>
                                                    <p>Author: {e.author}</p>
                                                    <div className="attachments">
                                                        {e.attachments.map((a) => {
                                                            if (typeof a === 'string') {
                                                                return (
                                                                    <a
                                                                        href={`${constants.cloud_storage}/History/${a}`}
                                                                        key={a}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img
                                                                            src={`${constants.cloud_storage}/History/${a}`}
                                                                            alt={a}
                                                                        />
                                                                    </a>
                                                                );
                                                            }

                                                            return (
                                                                <a
                                                                    href={`${constants.cloud_storage}/History/${a.name}`}
                                                                    key={a.name}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <img
                                                                        src={`${constants.cloud_storage}/History/${a.name}`}
                                                                        alt={a.name}
                                                                    />
                                                                </a>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Panel>
        );
    }
}

export default ScreenManagement;
