import React from 'react';
import dateFormat from 'dateformat';

import '../../Assets/Templates/UTemplate.css';
import {
    getPathFromCategory,
    getTitleFromCategory,
    getDataFromCategory,
    getFlag,
    getLabels,
    padNum,
    getQuantityText,
    getContainer,
    getKiloPrice,
    getPiecePrice
} from './MainTemplate';

dateFormat.i18n = {
    dayNames: [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi'
    ],
    monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'Janvier',
        'Fevrier',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Decembre'
    ],
    timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM']
};

const UTemplate = (props) => (
    <div className={`UTemplate ${props.orientation} ${props.rayon} ${props.templateName}`}>
        <div className="image">
            <img src={`${getPathFromCategory(props.rayon, props.filename)}`} alt={props.filename} />
        </div>
        <div className="price">
            <div className="actualPrice">
                <span className="int">{props.price.toString().split(/[.,]/)[0]}</span>
                <span className="eur">&euro;</span>
                <span className="dec">,{props.price ? padNum(props.price.toString().split(/[.,]/)[1]) : ''}</span>
            </div>
            <div className="quantity">
                <p className="qContainer">{getContainer(props)}</p>
                {getQuantityText(props)}
            </div>
        </div>
        <div className="data">
            <h1>{getTitleFromCategory(props.rayon, props)}</h1>
            <div className="infos">
                {getDataFromCategory(props.rayon, props)}
                <div>
                    {props.priceUnit !== 'number' || <p>Soit: {getKiloPrice(props.weight, props.price)}€ le Kg</p>}
                    {props.priceUnit !== 'piece' || !props.pieceNb || props.pieceNb <= 1 || (
                        <p>Soit: {getPiecePrice(props.pieceNb, props.price)}€ pièce</p>
                    )}
                </div>
            </div>
        </div>
        <div className="origin">
            <div
                className={
                    'originContainer' + (getFlag(props.originCountry, props.displayFlag) !== null ? ' flag' : '')
                }
                style={{ display: props.rayon === 'bakery' ? 'none' : 'flex' }}
            >
                <p className="originLabel">{!props.originCountry || 'Origine'}</p>
                <h2>{props.originCountry}</h2>
            </div>
            <div className="flex-cols">
                {getFlag(props.originCountry, props.displayFlag)}
                {getLabels(props).map((l) => (
                    <img src={`/labels/${l}`} alt={l} className="u-label" key={l} />
                ))}
            </div>
        </div>
        {!props.promotion || (
            <div className="promo">
                <h3>Prix</h3>
                <h4>Promo</h4>
            </div>
        )}
        {!props.expirationDate || (
            <div className="expirationDate">Jusqu'au {dateFormat(props.expirationDate, 'dddd d mmmm')}</div>
        )}
    </div>
);

export default UTemplate;
