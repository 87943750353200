import React from 'react';

const Preview = (props) => {
    if (props.type === 'image') {
        return (
            <a href={props.preview} target="__blank">
                <img src={props.preview} alt="Preview" className="AdminAssetPreview" />
            </a>
        );
    } else {
        return (
            <a href={props.preview} target="__blank">
                <video className="AdminAssetPreview" autoPlay loop playsInline muted>
                    <source src={props.preview} />
                    <source src={props.preview_fallback} />
                </video>
            </a>
        );
    }
};

export default Preview;
