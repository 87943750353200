import React from 'react';
import { Row, Col, Panel } from 'react-bootstrap';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import ReactTags from 'react-tag-autocomplete';
import Switch from 'react-switch';
import swal from 'sweetalert';
import Select from 'react-select';
import '../../Assets/newsletters.css';
import CKEditor from 'react-ckeditor-component';
import xlsxParser from 'xlsx-parse-json';

const AssetBlockInpl = (props) => (
    <div className="AssetBlock">
        <div className="form-group">
            <label htmlFor="title">Titre de la feature/asset</label>
            <CKEditor
                content={props.title}
                events={{
                    change: (e) => {
                        props.handleChange({ target: { name: 'title', value: e.editor.getData() } });
                    }
                }}
                scriptUrl="http://cdn.ckeditor.com/4.9.2/full/ckeditor.js"
                config={props.editorConfig}
            />
        </div>
        <div className="form-group">
            <label htmlFor="text">Texte</label>
            <CKEditor
                content={props.text}
                events={{
                    change: (e) => {
                        props.handleChange({ target: { name: 'text', value: e.editor.getData() } });
                    }
                }}
                scriptUrl="http://cdn.ckeditor.com/4.9.2/full/ckeditor.js"
                config={props.editorConfig}
            />
        </div>
        <div className="form-group">
            <label htmlFor="img">Image</label>
            {typeof props.file === 'string' ? props.file : <input type="file" name="asset" ref={props.file} />}
        </div>
        <button className="btn btn-danger" onClick={props.handleRemoveAsset}>
            <i className="fa fa-minus" />
        </button>
        <hr />
    </div>
);

const AssetBlock = React.memo(AssetBlockInpl, (prevProps, nextProps) => {
    return true;
});

export default class Newsletter extends React.Component {
    constructor() {
        super();

        this.state = {
            _id: null,
            type: 'newassets',
            title: '',
            subtitle: '',
            assets: [],
            appLink: true,
            message: '',
            subject: '',
            shops: [],
            tags: [],
            sendTo: [],
            additionnal: '',
            saves: []
        };

        this.types = {
            newassets: 'Nouveaux assets',
            newfeatures: 'Nouvelles features',
            message: 'Information generale'
        };

        this.editorConfig = {
            toolbar: [
                {
                    name: 'clipboard',
                    items: ['Undo', 'Redo']
                },
                {
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Underline']
                },
                {
                    name: 'paragraph',
                    items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                },
                { name: 'colors', items: ['TextColor'] }
            ],
            autoGrow_maxHeight: '600',
            colorButton_colors: 'FF9900,0066CC,F00',
            colorButton_enableAutomatic: false,
            colorButton_enableMore: false,
            removePlugins: 'elementspath',
            resize_enabled: false
        };

        this.id = 0;

        this.Auth = new AuthService();
        this.suggestions = [];

        this.handleChange = this.handleChange.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.handleChangeAsset = this.handleChangeAsset.bind(this);
        this.handleRemoveAsset = this.handleRemoveAsset.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleRestoreSave = this.handleRestoreSave.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangeAsset(i, e) {
        const asset = { ...this.state.assets[i] };

        asset[e.target.name] = e.target.value;

        const assets = [...this.state.assets];
        assets.splice(i, 1, asset);

        this.setState({
            assets
        });
    }

    handleRemoveAsset(_id) {
        this.setState({
            assets: this.state.assets.filter(({ id }) => id !== _id)
        });
    }

    componentDidMount() {
        this.Auth.fetch(`${constants.endpoint}/admin/shops/with-screens`).then(({ shops, screens }) => {
            const shops_devices = new Set();
            for (const d of screens) {
                if (!d.ShopId) {
                    continue;
                }

                if (!shops_devices.has(d.ShopId)) shops_devices.add(d.ShopId);
            }

            const filteredShops = shops.filter((s) => shops_devices.has(s.id));

            this.setState({ shops: filteredShops });
            filteredShops.forEach((d) => {
                const entries = [
                    {
                        type: 'sign',
                        name: `${d.Sign.name} (Enseigne)`,
                        id: d.Sign.name
                    },
                    {
                        type: 'shop',
                        name: `${d.name} (Shop)`,
                        id: d.name
                    },
                    {
                        type: 'zip',
                        name: `${d.zip} (Zip)`,
                        id: d.zip
                    },
                    {
                        type: 'city',
                        name: `${d.city} (Ville)`,
                        id: d.city
                    },
                    {
                        type: 'address',
                        name: `${d.name} / ${d.Sign.name} / ${d.address} / ${d.zip} ${d.city} (Adresse)`,
                        id: d.address
                    },
                    {
                        type: 'region',
                        name: `${d.region} (Region)`,
                        id: d.region
                    },
                    {
                        type: 'group',
                        name: `${d.Group?.name} (Groupe)`,
                        id: d.Group?.name
                    }
                ];

                entries.forEach((e) => {
                    if (!this.suggestions.find((s) => s.type === e.type && s.id === e.id)) {
                        if (e.id) {
                            this.suggestions.push(e);
                        }
                    }
                });
            });
        });

        this.Auth.fetch(`${constants.endpoint}/admin/newsletter/saves`).then((saves) => {
            this.setState({ saves: saves });

            if (window.location.search && window.location.search.slice(0, 2) === '?k') {
                this.handleRestoreSave(window.location.search.slice(3));
            }
        });
    }

    handleRestoreSave(id) {
        this.Auth.fetch(`${constants.endpoint}/admin/newsletter/${id}`).then((res) => {
            if (res.success === false) {
                return;
            }

            delete res.__v;
            res.assets.forEach((a) => (a.id = this.id++));
            this.setState({ _id: id, ...res });
        });
    }

    handleSwitch(emails, add) {
        const sendTo = this.state.sendTo;

        for (const email of emails) {
            const i = this.state.sendTo.indexOf(email);

            if (!add && i !== -1) {
                sendTo.splice(i, 1);
            } else {
                sendTo.push(email);
            }
        }

        this.setState({
            sendTo: sendTo
        });
    }

    handlePreview() {
        const data = new FormData();
        let valid = true;
        this.state.assets.forEach((a) => {
            if (typeof a.file !== 'string') {
                if (a.file != null && a.file.current.files.length !== 0) {
                    data.append(a.file.current.files[0].name, a.file.current.files[0]);
                }
            }
        });

        if (!valid) {
            return;
        }

        this.Auth.fetchMultipart(`${constants.endpoint}/admin/newsletter/upload`, { method: 'POST', body: data }).then(
            ({ names }) => {
                const assets = this.state.assets.map((a) => ({
                    title: a.title,
                    text: a.text,
                    file:
                        typeof a.file === 'string'
                            ? a.file
                            : a.file != null && a.file.current.files.length > 0
                            ? names[a.file.current.files[0].name]
                            : null
                }));

                this.Auth.fetch(`${constants.endpoint}/admin/newsletter/preview/${this.state.type}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        title: this.state.title,
                        subtitle: this.state.subtitle,
                        appLink: this.state.appLink,
                        assets: assets,
                        updates: this.state.updates,
                        message: this.state.message
                    })
                }).then(({ html }) => {
                    setTimeout(() => {
                        const win = window.open(
                            '',
                            'Title',
                            'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top='
                        );

                        win.document.body.innerHTML = html;
                    }, 500);
                });
            }
        );
    }

    handleSend() {
        const data = new FormData();
        let valid = true;
        this.state.assets.forEach((a) => {
            if (typeof a.file !== 'string') {
                if (a.file != null && a.file.current.files.length !== 0) {
                    data.append(a.file.current.files[0].name, a.file.current.files[0]);
                }
            }
        });

        if (!valid) {
            return;
        }

        swal({
            text: `Êtes-vous sur de vouloir envoyer le mail à ${this.state.sendTo.length} contacts`,
            title: 'La commande ne pourra pas être annulée',
            icon: 'warning',
            buttons: ['Annuler', 'Go'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this.Auth.fetchMultipart(`${constants.endpoint}/admin/newsletter/upload`, {
                    method: 'POST',
                    body: data
                }).then(({ names }) => {
                    const assets = this.state.assets.map((a) => ({
                        title: a.title,
                        text: a.text,
                        file:
                            typeof a.file === 'string'
                                ? a.file
                                : a.file != null && a.file.current.files.length > 0
                                ? names[a.file.current.files[0].name]
                                : null
                    }));

                    this.Auth.fetch(`${constants.endpoint}/admin/newsletter/send/${this.state.type}`, {
                        method: 'POST',
                        body: JSON.stringify({
                            title: this.state.title,
                            subtitle: this.state.subtitle,
                            appLink: this.state.appLink,
                            assets: assets,
                            updates: this.state.updates,
                            subject: this.state.subject,
                            message: this.state.message,
                            to: [...new Set(this.state.sendTo)]
                        })
                    });
                });
                swal(`Mail envoyés à ${this.state.sendTo.length} contacts`, {
                    icon: 'success'
                });
            } else {
                swal('Commande annulée');
            }
        });
    }

    handleSave() {
        const data = new FormData();
        let valid = true;
        this.state.assets.forEach((a) => {
            if (typeof a.file !== 'string') {
                if (a.file != null && a.file.current.files.length !== 0) {
                    data.append(a.file.current.files[0].name, a.file.current.files[0]);
                }
            }
        });

        if (!valid) {
            return;
        }

        this.Auth.fetchMultipart(`${constants.endpoint}/admin/newsletter/upload`, { method: 'POST', body: data }).then(
            ({ names }) => {
                const assets = this.state.assets.map((a) => ({
                    title: a.title,
                    text: a.text,
                    file:
                        typeof a.file === 'string'
                            ? a.file
                            : a.file != null && a.file.current.files.length > 0
                            ? names[a.file.current.files[0].name]
                            : null
                }));

                if (this.state._id) {
                    this.Auth.fetch(`${constants.endpoint}/admin/newsletter/save/${this.state._id}`, {
                        method: 'PUT',
                        body: JSON.stringify({
                            type: this.state.type,
                            title: this.state.title,
                            subtitle: this.state.subtitle,
                            appLink: this.state.appLink,
                            assets: assets,
                            subject: this.state.subject,
                            message: this.state.message,
                            sendTo: this.state.sendTo
                        })
                    }).then((res) => {
                        alert('Mail sauvegarde');
                    });
                } else {
                    this.Auth.fetch(`${constants.endpoint}/admin/newsletter/save`, {
                        method: 'POST',
                        body: JSON.stringify({
                            type: this.state.type,
                            title: this.state.title,
                            subtitle: this.state.subtitle,
                            assets: assets,
                            subject: this.state.subject,
                            message: this.state.message,
                            sendTo: this.state.sendTo
                        })
                    }).then((res) => {
                        alert('Nouveau mail sauvegarde');
                    });
                }
            }
        );
    }

    render() {
        let shops = this.state.shops;
        let shopsfiltered = [];
        this.state.tags.forEach((sf) => {
            shopsfiltered = [
                ...shopsfiltered,
                ...shops.filter((s) => {
                    if (sf.type === 'sign') {
                        return s.Sign.name === sf.id;
                    } else if (sf.type === 'zip') {
                        return s.zip === sf.id;
                    } else if (sf.type === 'shop') {
                        return s.name === sf.id;
                    } else if (sf.type === 'group') {
                        return s.Group.name === sf.id;
                    } else if (sf.type === 'address') {
                        return s.address === sf.id;
                    }

                    return null;
                })
            ];

            shopsfiltered = [...new Set(shopsfiltered)];
        });

        this.state.tags.forEach((sf) => {
            shopsfiltered = [
                ...shopsfiltered.filter((s) => {
                    if (sf.type === 'region') {
                        return s.region === sf.id;
                    } else if (sf.type === 'city') {
                        return s.city === sf.id;
                    }

                    return true;
                })
            ];

            shopsfiltered = [...new Set(shopsfiltered)];
        });

        if (this.state.tags.length > 0) {
            shops = shopsfiltered;
        }

        return (
            <div className="Newsletter">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Row>
                                <Col md={12}>
                                    <h4>Newsletter</h4>
                                </Col>
                            </Row>
                            <Row className="mT-20">
                                <Col md={5} mdOffset={1}>
                                    Envoyer à
                                    <ReactTags
                                        tags={this.state.tags}
                                        suggestions={this.suggestions}
                                        handleDelete={(e) =>
                                            this.setState({
                                                tags: this.state.tags.filter((_, i) => i !== e)
                                            })
                                        }
                                        handleAddition={(e) =>
                                            this.setState({
                                                tags: [...this.state.tags, e]
                                            })
                                        }
                                    />
                                    <div className="form-group">
                                        <label htmlFor="all">Tout selectioner</label>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    const emails = [];

                                                    shops.forEach((shop) => {
                                                        if (shop.mail) {
                                                            emails.push(shop.mail);
                                                        }

                                                        for (let i = 2; i <= 5; i++) {
                                                            if (shop[`mail${i}`]) {
                                                                emails.push(shop[`mail${i}`]);
                                                            }
                                                        }

                                                        if (shop.Group.email) {
                                                            emails.push(shop.Group.email);
                                                        }
                                                    });

                                                    this.setState({
                                                        sendTo: [...new Set(emails)]
                                                    });
                                                } else {
                                                    this.setState({
                                                        sendTo: []
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="myemail">
                                        {shops.map((shop) => {
                                            if (!shop?.mail && !shop?.Group?.email) {
                                                return null;
                                            }

                                            const emails = [];

                                            if (shop.mail) {
                                                emails.push(shop.mail);
                                            }

                                            for (let i = 2; i <= 5; i++) {
                                                if (shop[`mail${i}`]) {
                                                    emails.push(shop[`mail${i}`]);
                                                }
                                            }

                                            if (shop.Group?.email) {
                                                emails.push(shop.Group?.email);
                                            }

                                            return (
                                                <table key={shop.id} className="emailfornews">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div key={shop.id} style={{ display: 'table' }}>
                                                                    <span
                                                                        style={{
                                                                            display: 'table-cell',
                                                                            verticalAlign: 'middle'
                                                                        }}
                                                                    >
                                                                        {shop.Group?.name} - {shop.name} -{' '}
                                                                        {emails.reduce(
                                                                            (acc, email) => acc + ', ' + email
                                                                        )}
                                                                    </span>
                                                                    <Switch
                                                                        className="switch-class"
                                                                        onChange={(e) => {
                                                                            this.handleSwitch(emails, e);
                                                                        }}
                                                                        onColor="#86d3ff"
                                                                        onHandleColor="#2693e6"
                                                                        handleDiameter={25}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                        height={20}
                                                                        width={40}
                                                                        checked={emails.every(
                                                                            (e) => this.state.sendTo.indexOf(e) !== -1
                                                                        )}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            );
                                        })}
                                    </div>
                                    <hr />
                                    {[...new Set(this.state.sendTo)].map((mail) => (
                                        <p key={mail}>{mail}</p>
                                    ))}
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{
                                                display: 'inline',
                                                width: 'auto'
                                            }}
                                            name="additionnal"
                                            value={this.state.additionnal}
                                            onChange={this.handleChange}
                                        />
                                        <button
                                            style={{ marginLeft: '1em' }}
                                            className="btn btn-info"
                                            onClick={() =>
                                                this.setState({
                                                    sendTo: [...this.state.sendTo, this.state.additionnal],
                                                    additionnal: ''
                                                })
                                            }
                                        >
                                            Ajouter
                                        </button>
                                        <input
                                            type="file"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx"
                                            onChange={(e) => {
                                                const file = e.target.files[0];

                                                xlsxParser.onFileSelection(file).then((data) => {
                                                    const shops = data[Object.keys(data)[0]].map((line) =>
                                                        parseInt(line.id_shop)
                                                    );

                                                    const selected = this.state.shops.filter(
                                                        ({ id }) => shops.indexOf(id) !== -1
                                                    );

                                                    const emails = new Set();

                                                    for (const shop of selected) {
                                                        if (!shop.mail && !shop.Group.email) {
                                                            continue;
                                                        }

                                                        if (shop.mail) {
                                                            emails.add(shop.mail);
                                                        }

                                                        for (let i = 2; i <= 5; i++) {
                                                            if (shop[`mail${i}`]) {
                                                                emails.add(shop[`mail${i}`]);
                                                            }
                                                        }

                                                        if (shop.Group.email) {
                                                            emails.add(shop.Group.email);
                                                        }
                                                    }

                                                    this.setState({ sendTo: [...emails] });
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className="form-group">
                                        <label htmlFor="resotore">Restaurer une sauvegarde</label>
                                        <Select
                                            options={this.state.saves
                                                .map((s, i) => ({
                                                    label: s.subject + ' - ' + s.title,
                                                    value: s._id
                                                }))
                                                .reverse()}
                                            name="resore"
                                            className="newsassets"
                                            onChange={(e) => {
                                                const value = e.value;

                                                if (!value) {
                                                    this.setState({
                                                        _id: null
                                                    });
                                                } else {
                                                    this.handleRestoreSave(value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <hr />
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="form-group">
                                            <label htmlFor="type">Type de newsletter</label>
                                            <select
                                                name="type"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                value={this.state.type}
                                            >
                                                {Object.keys(this.types).map((k) => (
                                                    <option key={k} value={k}>
                                                        {this.types[k]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="subject">Sujet du mail</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                name="subject"
                                                value={this.state.subject}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="title">Titre</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                name="title"
                                                value={this.state.title}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="subtitle">Sous titre</label>
                                            <CKEditor
                                                content={this.state.subtitle}
                                                events={{
                                                    change: (e) => {
                                                        this.setState({ subtitle: e.editor.getData() });
                                                    }
                                                }}
                                                scriptUrl="https://cdn.ckeditor.com/4.9.2/full/ckeditor.js"
                                                config={this.editorConfig}
                                            />
                                        </div>
                                        <hr />
                                        <div className="form-group">
                                            <span style={{ verticalAlign: 'top' }}>Lien vers l'application</span>
                                            <Switch
                                                className="switch-class"
                                                onChange={(checked) => {
                                                    this.setState({ appLink: checked });
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={40}
                                                checked={this.state.appLink}
                                            />
                                        </div>
                                        <hr />
                                        Assets/Features
                                        {this.state.assets.map((a, i) => (
                                            <AssetBlock
                                                {...a}
                                                i={a.id}
                                                key={a.id}
                                                handleChange={(e) => this.handleChangeAsset(i, e)}
                                                handleRemoveAsset={() => this.handleRemoveAsset(a.id)}
                                                editorConfig={this.editorConfig}
                                            />
                                        ))}
                                        <button
                                            className="btn btn-info"
                                            onClick={() =>
                                                this.setState({
                                                    assets: [
                                                        ...this.state.assets,
                                                        {
                                                            id: this.id++,
                                                            title: '',
                                                            text: '',
                                                            file: React.createRef()
                                                        }
                                                    ]
                                                })
                                            }
                                        >
                                            <i className="fa fa-plus" />
                                        </button>
                                        <hr />
                                        {this.state.type !== 'message' || (
                                            <div className="form-group">
                                                <label htmlFor="message">Message</label>
                                                <CKEditor
                                                    content={this.state.message}
                                                    events={{
                                                        change: (e) => {
                                                            this.setState({ message: e.editor.getData() });
                                                        }
                                                    }}
                                                    scriptUrl="https://cdn.ckeditor.com/4.9.2/full/ckeditor.js"
                                                    config={this.editorConfig}
                                                />
                                            </div>
                                        )}
                                        <div className="form-group">
                                            <button className="btn btn-info" onClick={this.handlePreview}>
                                                Preview
                                            </button>
                                            <button
                                                className="btn btn-info"
                                                onClick={this.handleSave}
                                                style={{ marginLeft: '1em' }}
                                            >
                                                Sauvegarder
                                            </button>
                                            {!this.state._id || (
                                                <button
                                                    style={{
                                                        marginLeft: '1em'
                                                    }}
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        this.Auth.fetch(
                                                            `${constants.endpoint}/admin/newsletter/${this.state._id}`,
                                                            { method: 'DELETE' }
                                                        ).then(() => {
                                                            this.setState({
                                                                _id: null
                                                            });

                                                            this.Auth.fetch(
                                                                `${constants.endpoint}/admin/newsletter/saves`
                                                            ).then((saves) =>
                                                                this.setState({
                                                                    saves: saves
                                                                })
                                                            );
                                                        });
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            )}
                                            <button
                                                className="btn btn-info"
                                                onClick={this.handleSend}
                                                style={{ marginLeft: '1em' }}
                                            >
                                                Envoyer
                                            </button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
