import React from 'react';
import { Row, Col, Table, Panel, Modal, Tabs, Tab } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import AuthService from '../../Components/Auth/AuthService';
import constants from '../../Constants';
import { notify } from 'react-notify-toast';
import { fetchApi } from '../../Services/NetworkServcice';
import Select from 'react-select';

function humanFileSize(bytes, si) {
    let thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

const getUsageFromStats = (fileNames, stats) => {
    let count = 0;

    for (const fileName of fileNames) {
        if (stats[fileName]) {
            count += stats[fileName].length;
        }
    }

    return count;
};

const RAYONS = ['', 'F&L', 'Boucherie', 'Boulangerie', 'Poissonnerie', 'Fromagerie', 'Charcuterie', 'Traiteur'];
export const ALLOWED_BUTCHERY = ['Boeuf', 'Agneau', 'Veau', 'Porc', 'Volaille', 'Oie', 'Lapin'];
export const ALLOWED_BUTCHERY_VOLAILLE = [
    'Volaille',
    'Canard',
    'Poulet',
    'Dinde',
    'Canette',
    'Pintade',
    'Poule',
    'Coquelet',
    'Chapon fermier',
    'Dinde fermière',
    'Alouettes'
];
export const ALLOWED_CHEESE = ['Vache', 'Chèvre', 'Brebis', 'Brebis / Vache', 'Brebis / Chèvre'];

const ALLOWED_BUTCHERY_ALL = [...ALLOWED_BUTCHERY, ...ALLOWED_BUTCHERY_VOLAILLE];

class Library extends React.Component {
    constructor() {
        super();
        this.state = {
            buttonActive: true,
            category: '',
            files: [],
            fruits: [],
            butchery: [],
            delicatessen: [],
            caterer: [],
            bakery: [],
            fishShop: [],
            cheese: [],
            selectedId: -1,
            modalPOpen: false,
            modalOpen: false,
            visibleImage: 0,
            stats: {},
            selectedCategory: '',
            selectedRayon: '',
            activeKey: 1,
            shops: [],
            updateMode: false,
            newCategoryName: '',
            subcategory: ''
        };

        this.Auth = new AuthService();

        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUpdateImage = this.handleUpdateImage.bind(this);
        this.getData = this.getData.bind(this);
        this.getSelectFromRayon = this.getSelectFromRayon.bind(this);

        this.getAssets = this.getAssets.bind(this);
    }

    handleUpload() {
        if (!this.state.buttonActive) {
            return;
        }

        this.setState({
            buttonActive: false
        });

        this.state.files.forEach((file) => {
            const data = new FormData();
            data.append('file', file);

            switch (this.state.activeKey) {
                case 1:
                    data.append('type', 'Fruits');
                    break;
                case 2:
                    data.append('type', 'Butchery');
                    break;
                case 3:
                    data.append('type', 'Bakery');
                    break;
                case 4:
                    data.append('type', 'FishShop');
                    break;
                case 5:
                    data.append('type', 'Cheese');
                    break;
                case 6:
                    data.append('type', 'Delicatessen');
                    break;
                case 7:
                    data.append('type', 'Caterer');
                    break;
                default:
                    break;
            }

            data.append('category', this.state.category);
            data.append('subcategory', this.state.subcategory);

            this.Auth.fetchMultipart(`${constants.endpoint}/admin/upload/library`, {
                method: 'POST',
                body: data
            })
                .then((res) => {
                    this.handleClose();

                    if (res.success === false) {
                        return notify.show("Impossible d'uploader: " + res.message, 'warning');
                    }

                    this.getAssets();
                    return notify.show('Fichier envoye!', 'success');
                })
                .catch((err) => {
                    this.handleClose();

                    return notify.show("Impossible d'uploader: " + err, 'warning');
                });
        });
    }

    handleDelete(id) {
        if (!window.confirm('Etes vous sur?')) {
            return;
        }

        this.Auth.fetch(`${constants.endpoint}/admin/library`, {
            method: 'DELETE',
            body: JSON.stringify({ id: id })
        })
            .then((res) => {
                if (res.success === false) {
                    return notify.show('Impossible de supprimer: ' + res.message, 'warning');
                }

                this.getAssets();
            })
            .catch((err) => {
                return notify.show('Impossible de supprimer: ' + err.message, 'warning');
            });
    }

    handleClose() {
        this.setState({
            modalAOpen: false,
            modalOpen: false,
            modalPOpen: false,
            buttonActive: true,
            files: [],
            category: '',
            selectedId: -1,
            visibleImage: 0,
            selectedCategory: '',
            selectedRayon: '',
            updateMode: false,
            newCategoryName: '',
            subcategory: ''
        });
    }

    handleDrop(files) {
        this.setState({
            files: files
        });
    }

    handleChange(e) {
        this.setState({
            category: e.target.value
        });
    }

    handleUpdateImage(id, category) {
        fetchApi('/admin/library', {
            method: 'PUT',
            body: JSON.stringify({
                id,
                category
            })
        }).then(() => this.getData());
    }

    getAssets() {
        this.Auth.fetch(`${constants.endpoint}/admin/library`)
            .then((res) => {
                if (res.success === false) {
                    return notify.show('Impossible de charger les donnees: ' + res.message, 'warning');
                }

                this.setState({
                    fruits: res.assets.filter(({ assets }) => assets[0].type === 'Fruits'),
                    butchery: res.assets.filter(({ assets }) => assets[0].type === 'Butchery'),
                    cheese: res.assets.filter(({ assets }) => assets[0].type === 'Cheese'),
                    bakery: res.assets.filter(({ assets }) => assets[0].type === 'Bakery'),
                    fishShop: res.assets.filter(({ assets }) => assets[0].type === 'FishShop'),
                    delicatessen: res.assets.filter(({ assets }) => assets[0].type === 'Delicatessen'),
                    caterer: res.assets.filter(({ assets }) => assets[0].type === 'Caterer')
                });
            })
            .catch((err) => {
                return notify.show('Impossible de charger les donnees: ' + err.message, 'warning');
            });
    }

    async getData() {
        await this.Auth.fetch(`${constants.endpoint}/admin/stats/fruitsUsage`).then((res) => {
            this.setState({ stats: res.srcs });
        });

        await fetchApi('/admin/shops')
            .then((res) => res.json())
            .then(({ shops }) => this.setState({ shops }));

        this.getAssets();
    }

    getSelectFromRayon(category) {
        let options = [];

        switch (category) {
            case 'Fromagerie':
                options = ['Vache', 'Chèvre', 'Brebis', 'Brebis / Vache', 'Brebis / Chèvre'];
                break;
            case 'Charcuterie':
                options = [
                    'Andouilles',
                    'Andouillettes',
                    'Boudins blancs',
                    'Boudins noirs',
                    'Charcuterie pâtissières',
                    'Jambon cuits',
                    'Jambon blanc',
                    'Jambons secs et salaisons sèches',
                    'Lardons et aides culinaires',
                    'Pâtés et Terrines',
                    'Pieds et Paquets',
                    'Rillettes',
                    'Saucisses et saucissons secs',
                    'Saucisses et saucissons cuits ou à cuire',
                    'Tripes et Tripoux'
                ];
                break;
            case 'Poissonnerie':
                options = ['Poisson frais', 'Fruits de mer'];
                break;
            default:
                break;
        }

        if (options.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <hr />
                <div className="form-group">
                    <label>Catégorie</label>
                    <Select
                        onChange={(e) => this.setState({ subcategory: e.value })}
                        options={options.map((o) => ({ label: o, value: o }))}
                    ></Select>
                </div>
            </React.Fragment>
        );
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let stateContainer = 'fruits';

        switch (this.state.activeKey) {
            case 1:
                break;
            case 2:
                stateContainer = 'butchery';
                break;
            case 3:
                stateContainer = 'bakery';
                break;
            case 4:
                stateContainer = 'fishShop';
                break;
            case 5:
                stateContainer = 'cheese';
                break;
            case 6:
                stateContainer = 'delicatessen';
                break;
            case 7:
                stateContainer = 'caterer';
                break;
            default:
                break;
        }

        return (
            <Row>
                <Modal show={this.state.modalOpen} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload</Modal.Title>
                        <Modal.Body>
                            <h5>Déposez un fichiers ici</h5>
                            <Dropzone
                                onDrop={this.handleDrop}
                                style={{
                                    width: 'initial',
                                    height: '200px',
                                    border: '1px solid black',
                                    color: 'gray',
                                    borderRadius: '5px'
                                }}
                            >
                                {this.state.files ? this.state.files.length + ' Files' : 'Déposer un fichier ici'}
                            </Dropzone>
                            {this.getSelectFromRayon(this.state.selectedRayon)}
                            <hr />
                            <div className="form-group">
                                <label htmlFor="">Nom</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    placeholder="Nom de catégorie"
                                    value={this.state.category}
                                />
                            </div>
                            <button
                                disabled={!this.state.buttonActive}
                                onClick={this.handleUpload}
                                className="btn btn-info pull-right"
                            >
                                Upload
                            </button>
                        </Modal.Body>
                    </Modal.Header>
                </Modal>
                <Col md={12}>
                    <Panel>
                        <button
                            className="btn btn-info pull-right"
                            onClick={() => {
                                this.setState({
                                    modalOpen: true,
                                    selectedRayon: RAYONS[this.state.activeKey]
                                });
                            }}
                        >
                            Ajouter
                        </button>
                        <Tabs
                            id="tabs"
                            defaultActiveKey={1}
                            activeKey={this.state.activeKey}
                            onSelect={(e) => this.setState({ activeKey: e })}
                        >
                            <Tab title="F&L" eventKey={1}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Categorie</th>
                                            <th>Utilisation</th>
                                            <th>Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.fruits
                                            .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
                                            .map((fruit) => {
                                                return (
                                                    <tr key={fruit._id}>
                                                        <td>{fruit.assets.length}</td>
                                                        <td>{fruit._id}</td>
                                                        <td>
                                                            {getUsageFromStats(
                                                                fruit.assets.map(({ filename }) => filename),
                                                                this.state.stats
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-default ml-1 btn_desa_clone"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalPOpen: true,
                                                                        selectedId: fruit._id,
                                                                        selectedCategory: fruit._id
                                                                    });
                                                                }}
                                                            >
                                                                Voir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab title="Boucherie" eventKey={2}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Categorie</th>
                                            <th>Utilisation</th>
                                            <th>Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.butchery
                                            .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
                                            .map((butchery) => {
                                                const isVisible =
                                                    ALLOWED_BUTCHERY_ALL.findIndex(
                                                        (a) =>
                                                            butchery._id.toLowerCase().indexOf(a.toLowerCase()) !== -1
                                                    ) !== -1;

                                                return (
                                                    <tr
                                                        style={{ color: isVisible ? 'initial' : '#F32013' }}
                                                        key={butchery._id}
                                                    >
                                                        <td>{butchery.assets.length}</td>
                                                        <td>{butchery._id}</td>
                                                        <td>
                                                            {getUsageFromStats(
                                                                butchery.assets.map(({ filename }) => filename),
                                                                this.state.stats
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-default ml-1 btn_desa_clone"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalPOpen: true,
                                                                        selectedId: butchery._id,
                                                                        selectedCategory: butchery._id
                                                                    });
                                                                }}
                                                            >
                                                                Voir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab title="Boulangerie" eventKey={3}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Categorie</th>
                                            <th>Utilisation</th>
                                            <th>Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.bakery
                                            .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
                                            .map((bakery) => {
                                                return (
                                                    <tr key={bakery._id}>
                                                        <td>{bakery.assets.length}</td>
                                                        <td>{bakery._id}</td>
                                                        <td>
                                                            {getUsageFromStats(
                                                                bakery.assets.map(({ filename }) => filename),
                                                                this.state.stats
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-default ml-1 btn_desa_clone"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalPOpen: true,
                                                                        selectedId: bakery._id,
                                                                        selectedCategory: bakery._id
                                                                    });
                                                                }}
                                                            >
                                                                Voir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab title="Poissonnerie" eventKey={4}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Categorie</th>
                                            <th>Utilisation</th>
                                            <th>Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.fishShop
                                            .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
                                            .map((fishShop) => {
                                                return (
                                                    <tr key={fishShop._id}>
                                                        <td>{fishShop.assets.length}</td>
                                                        <td>{fishShop._id}</td>
                                                        <td>
                                                            {getUsageFromStats(
                                                                fishShop.assets.map(({ filename }) => filename),
                                                                this.state.stats
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-default ml-1 btn_desa_clone"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalPOpen: true,
                                                                        selectedId: fishShop._id,
                                                                        selectedCategory: fishShop._id
                                                                    });
                                                                }}
                                                            >
                                                                Voir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab title="Fromagerie" eventKey={5}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Nom</th>
                                            <th>Utilisation</th>
                                            <th>Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.cheese
                                            .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
                                            .map((cheese) => {
                                                const isVisible =
                                                    ALLOWED_CHEESE.findIndex(
                                                        (a) =>
                                                            cheese.assets.findIndex((c) => c.subcategory === a) !== -1
                                                    ) !== -1;

                                                return (
                                                    <tr
                                                        style={{ color: isVisible ? 'initial' : '#ccc' }}
                                                        key={cheese._id}
                                                    >
                                                        <td>{cheese.assets.length}</td>
                                                        <td>{cheese._id}</td>
                                                        <td>
                                                            {getUsageFromStats(
                                                                cheese.assets.map(({ filename }) => filename),
                                                                this.state.stats
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-default ml-1 btn_desa_clone"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalPOpen: true,
                                                                        selectedId: cheese._id,
                                                                        selectedCategory: cheese._id
                                                                    });
                                                                }}
                                                            >
                                                                Voir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab title="Charcuterie" eventKey={6}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Nom</th>
                                            <th>Category</th>
                                            <th>Utilisation</th>
                                            <th>Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.delicatessen
                                            .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
                                            .map((delicatessen) => {
                                                return (
                                                    <tr key={delicatessen._id}>
                                                        <td>{delicatessen.assets.length}</td>
                                                        <td>{delicatessen._id}</td>
                                                        <td>
                                                            {getUsageFromStats(
                                                                delicatessen.assets.map(({ filename }) => filename),
                                                                this.state.stats
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-default ml-1 btn_desa_clone"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalPOpen: true,
                                                                        selectedId: delicatessen._id,
                                                                        selectedCategory: delicatessen._id
                                                                    });
                                                                }}
                                                            >
                                                                Voir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab title="Traiteur" eventKey={7}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Nom</th>
                                            <th>Utilisation</th>
                                            <th>Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.caterer
                                            .sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()))
                                            .map((caterer) => {
                                                return (
                                                    <tr key={caterer._id}>
                                                        <td>{caterer.assets.length}</td>
                                                        <td>{caterer._id}</td>
                                                        <td>
                                                            {getUsageFromStats(
                                                                caterer.assets.map(({ filename }) => filename),
                                                                this.state.stats
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-default ml-1 btn_desa_clone"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalPOpen: true,
                                                                        selectedId: caterer._id,
                                                                        selectedCategory: caterer._id
                                                                    });
                                                                }}
                                                            >
                                                                Voir
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Tab>
                        </Tabs>
                    </Panel>
                </Col>
                <Modal show={this.state.modalPOpen} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Prévisualisation</Modal.Title>
                        <Modal.Body>
                            <div style={{ textAlign: 'center' }}>
                                <ul
                                    style={{
                                        listStyleType: 'none',
                                        padding: 0
                                    }}
                                >
                                    {this.state.selectedId === -1 ||
                                        this.state[stateContainer]
                                            .find(({ _id }) => _id === this.state.selectedId)
                                            .assets.map((a, i, array) => (
                                                <li
                                                    key={a.filename}
                                                    style={{
                                                        display: i === this.state.visibleImage ? 'inherit' : 'none'
                                                    }}
                                                >
                                                    <img
                                                        className="adminPreviewLibrary"
                                                        src={`${constants.cloud_storage}/Assets/Library/${a.type}/${a.filename}`}
                                                        alt=""
                                                    />
                                                    <p>
                                                        <button
                                                            className="btn"
                                                            onClick={() => {
                                                                if (this.state.visibleImage <= 0) {
                                                                    return;
                                                                }

                                                                this.setState({
                                                                    visibleImage: this.state.visibleImage - 1
                                                                });
                                                            }}
                                                        >
                                                            {'<<'}
                                                        </button>
                                                        <button
                                                            className="btn"
                                                            onClick={() => {
                                                                if (this.state.visibleImage >= array.length - 1) {
                                                                    return;
                                                                }

                                                                this.setState({
                                                                    visibleImage: this.state.visibleImage + 1
                                                                });
                                                            }}
                                                        >
                                                            >>
                                                        </button>
                                                    </p>
                                                    <p>
                                                        Sur {getUsageFromStats([a.filename], this.state.stats)} slide -{' '}
                                                        {a.filename} - {a.dimensions.width}x{a.dimensions.height} -{' '}
                                                        {humanFileSize(a.size)} - {i + 1} / {array.length}
                                                    </p>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Shop</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!this.state.stats[a.filename] ||
                                                                this.state.stats[a.filename].map((sId) => (
                                                                    <tr key={sId}>
                                                                        <td style={{ textAlign: 'left' }}>
                                                                            {
                                                                                this.state.shops.find(
                                                                                    ({ id }) => id === sId
                                                                                ).name
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                    {!this.state.updateMode || (
                                                        <div className="form-group">
                                                            <label>Changement de catégorie</label>
                                                            <input
                                                                style={{ marginBottom: 10 }}
                                                                className="form-control"
                                                                value={this.state.newCategoryName}
                                                                onChange={(e) =>
                                                                    this.setState({ newCategoryName: e.target.value })
                                                                }
                                                            ></input>
                                                        </div>
                                                    )}
                                                    <p>
                                                        <button
                                                            className="btn btn-danger"
                                                            onClick={() => {
                                                                this.handleDelete(a.id);

                                                                this.setState({
                                                                    visibleImage: 0,
                                                                    selectedId: -1,
                                                                    modalPOpen: false
                                                                });
                                                            }}
                                                        >
                                                            Supprimer
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                this.setState({
                                                                    modalOpen: true,
                                                                    modalPOpen: false,
                                                                    category: this.state.selectedCategory,
                                                                    subcategory: a.subcategory || ''
                                                                })
                                                            }
                                                            style={{
                                                                marginLeft: '1em'
                                                            }}
                                                            className="btn btn-info"
                                                        >
                                                            Ajouter {this.state.selectedCategory}
                                                        </button>
                                                        <button
                                                            className="btn btn-info"
                                                            style={{
                                                                marginLeft: '1em'
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.updateMode) {
                                                                    return this.setState({
                                                                        updateMode: true,
                                                                        newCategoryName: this.state.selectedId
                                                                    });
                                                                }

                                                                this.handleUpdateImage(
                                                                    a.id,
                                                                    this.state.newCategoryName
                                                                );

                                                                this.setState({
                                                                    updateMode: false,
                                                                    newCategoryName: '',
                                                                    visibleImage: 0,
                                                                    selectedId: -1,
                                                                    modalPOpen: false
                                                                });
                                                            }}
                                                        >
                                                            {this.state.updateMode ? 'Enregistrer' : 'Mettre à jour'}
                                                        </button>
                                                    </p>
                                                </li>
                                            ))}
                                </ul>
                            </div>
                        </Modal.Body>
                    </Modal.Header>
                </Modal>
            </Row>
        );
    }
}

export default Library;
