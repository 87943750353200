import React, { useEffect, useState } from 'react';
import CKEditor from 'react-ckeditor-component';
import { getTemplateRatio } from '../../Pages/Admin/Templates';

const toElements = (html, template, isAdmin = false) => {
    const elts = [];

    const vdom = document.createElement('div');
    vdom.innerHTML = html;

    const processLine = (pElement, style) => {
        for (const ch of pElement.childNodes) {
            if (ch.nodeType === ch.TEXT_NODE) {
                const text = ch.textContent.trim();

                if (!isAdmin || !new RegExp(/{([^}]+)}/gi).test(text)) {
                    const elt = { ...style };
                    elt.text = ch.textContent;

                    elts.push(elt);
                } else {
                    let cursor = 0;
                    const regexp = new RegExp(/{([^}]+)}/gi);
                    let match = null;

                    do {
                        match = regexp.exec(text);

                        if (match) {
                            const beforeText = text.slice(cursor, match.index);
                            const matchedText = text.slice(match.index + 1, match.index + match[1].length + 1);

                            if (beforeText !== '') {
                                elts.push({ ...style, text: beforeText });
                            }

                            if (matchedText !== '') {
                                if (matchedText[0] === '!') {
                                    elts.push({
                                        ...style,
                                        text: matchedText.slice(1),
                                        isModificationAllowed: true,
                                        isFree: true
                                    });
                                } else {
                                    elts.push({
                                        ...style,
                                        text: matchedText,
                                        isModificationAllowed: true
                                    });
                                }
                            }

                            cursor = match.index + match[0].length;
                        }
                    } while (match);

                    if (cursor < text.length) {
                        elts.push({ ...style, text: text.slice(cursor) });
                    }
                }
            } else {
                const elt = { ...style };

                if (ch.style.color) {
                    elt.color = ch.style.color;
                }

                if (ch.style.fontFamily) {
                    elt.font = ch.style.fontFamily;
                }

                if (ch.style.fontSize) {
                    elt.size = parseInt(ch.style.fontSize) * getTemplateRatio(template.orientation);
                }

                if (ch.style.lineHeight) {
                    elt.lineHeight = parseFloat(ch.style.lineHeight);
                }

                if (ch.style.animation) {
                    const components = /([0-9]+)ms[^0-9]+([0-9]+)ms.*\s([A-Za-z]+)/.exec(ch.style.animation);
                    elt.animation = components[3];
                    elt.animationDelay = parseInt(components[2]);
                    elt.animationDuration = parseInt(components[1]);
                }

                if (ch.tagName === 'STRONG') {
                    elt.bold = true;
                }

                if (ch.tagName === 'EM') {
                    elt.italic = true;
                }

                if (ch.tagName === 'U') {
                    elt.underline = true;
                }

                processLine(ch, elt);
            }
        }
    };

    for (const line of vdom.children) {
        processLine(line, {
            text: '',
            color: template.allowedColors[0],
            font: template.allowedFonts[0],
            align: line.style.textAlign || 'left',
            size: 14 * 4
        });
        elts.push({ lineBreak: true });
    }

    elts.splice(elts.length - 1, 1);

    return elts;
};

export const templateElementsToHtml = (elements, orientation, isRender = true, allowModificationOn = []) => {
    const html = document.createElement('div');

    let currentElt = document.createElement('p');

    let i = 0;
    let cursor = allowModificationOn.length === 0 ? -1 : 0;

    for (const e of elements) {
        if (e.lineBreak) {
            if (currentElt.innerHTML.length === 0) {
                currentElt.innerHTML = '&nbsp;';
                currentElt.style.fontSize = `${14 * (isRender ? getTemplateRatio(orientation) : 1)}px`;
            }

            html.appendChild(currentElt);
            currentElt = document.createElement('p');
            i++;
            continue;
        }

        // Base span
        const span = document.createElement('span');
        let eltPointer = span;

        // Line height
        if (e.lineHeight) {
            const spanLineHeight = document.createElement('span');
            spanLineHeight.style.lineHeight = e.lineHeight;
            eltPointer.appendChild(spanLineHeight);
            eltPointer = spanLineHeight;
        } else {
            const spanLineHeight = document.createElement('span');
            spanLineHeight.style.lineHeight = 1;
            eltPointer.appendChild(spanLineHeight);
            eltPointer = spanLineHeight;
        }

        // Color
        if (e.color) {
            const spanColor = document.createElement('span');
            spanColor.style.color = e.color;
            eltPointer.appendChild(spanColor);
            eltPointer = spanColor;
        }

        // Font size
        if (e.size) {
            const spanSize = document.createElement('span');
            spanSize.style.fontSize = `${isRender ? e.size : e.size / getTemplateRatio(orientation)}px`;
            eltPointer.appendChild(spanSize);
            eltPointer = spanSize;
        }

        // Animation
        if (e.animation) {
            if (e.animation === 'tpBlinkWhite') {
                const span = document.createElement('span');
                span.style.display = 'inline-block';
                span.style.animation = `${e.animationDuration}ms ease-in-out ${e.animationDelay}ms infinite both ${e.animation}`;
                eltPointer.appendChild(span);
                eltPointer = span;
            } else if (e.animation === 'tpBlinkBlack') {
                const span = document.createElement('span');
                span.style.display = 'inline-block';
                span.style.animation = `${e.animationDuration}ms ease-in-out ${e.animationDelay}ms infinite both ${e.animation}`;
                eltPointer.appendChild(span);
                eltPointer = span;
            } else {
                const span = document.createElement('span');
                span.style.display = 'inline-block';
                span.style.animation = `${e.animationDuration}ms ease-in-out ${e.animationDelay}ms 1 both ${e.animation}`;
                eltPointer.appendChild(span);
                eltPointer = span;
            }
        }

        // Font family
        if (e.font) {
            const spanFamily = document.createElement('span');
            spanFamily.style.fontFamily = e.font;
            eltPointer.appendChild(spanFamily);
            eltPointer = spanFamily;
        }

        // Text alignment
        currentElt.style.textAlign = e.align;

        if (e.bold) {
            const strong = document.createElement('strong');
            eltPointer.appendChild(strong);
            eltPointer = strong;
        }

        if (e.italic) {
            const em = document.createElement('em');
            eltPointer.appendChild(em);
            eltPointer = em;
        }

        if (e.underline) {
            const u = document.createElement('u');
            eltPointer.appendChild(u);
            eltPointer = u;
        }

        if (cursor !== -1 && i === allowModificationOn[cursor]) {
            // Modification allowed on this elt
            eltPointer.textContent = '{' + (e.isFree ? '!' + e.text : e.text) + '}';

            cursor++;

            if (cursor === allowModificationOn.length) {
                cursor = -1;
            }
        } else {
            eltPointer.textContent = e.text;
            eltPointer.style.whiteSpace = 'pre-wrap';
        }

        currentElt.appendChild(span);

        i++;
    }

    html.appendChild(currentElt);
    return html.innerHTML;
};

const getColorsList = (allowedColorsList = []) => {
    let allowedColors = allowedColorsList[0].slice(1);
    for (let i = 1; i < allowedColorsList.length; i++) {
        allowedColors += ',' + allowedColorsList[i].slice(1);
    }

    return allowedColors;
};

const ElementEditor = ({ baseTemplateInstance, onChange, template, isAdmin = false, allowModificationOn = [] }) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (baseTemplateInstance.elements.length > 0 && message.length === 0) {
            const html = templateElementsToHtml(
                baseTemplateInstance.elements,
                baseTemplateInstance.templateId.orientation,
                false,
                allowModificationOn
            );

            setMessage(html);
            onChange(toElements(html, baseTemplateInstance.templateId, isAdmin));
        }
    }, [baseTemplateInstance, onChange, message.length, isAdmin, allowModificationOn]);

    return (
        <div className="ElementEditor">
            <CKEditor
                content={message}
                events={{
                    change: (e) => {
                        setMessage(e.editor.getData());
                        onChange(toElements(e.editor.getData(), template, isAdmin));
                    }
                }}
                scriptUrl="/ckeditor/ckeditor.js"
                config={{
                    toolbar: [
                        {
                            name: 'basicstyles',
                            items: ['Bold', 'Italic', 'Underline']
                        },
                        {
                            name: 'paragraph',
                            items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                        },
                        { name: 'styles', items: ['Font', 'FontSize'] },
                        { name: 'colors', items: ['TextColor'] },
                        { name: 'lineheight', items: ['lineheight'] }
                    ],
                    autoGrow_maxHeight: '600',
                    colorButton_colors: getColorsList(template.allowedColors),
                    colorButton_enableAutomatic: false,
                    colorButton_enableMore: false,
                    removePlugins: 'elementspath',
                    resize_enabled: false,
                    height: '403',
                    font_names: template.allowedFonts.reduce((acc, f) => acc + ';' + f),
                    font_defaultLabel: 'Open Sans',
                    contentsCss: ['/templates/FreeTemplates.css'],
                    line_height: '1;1.1;1.2;1.3;1.4;1.5;1.6;1.7;1.8;1.9;2;2.1;2.2;2.3;2.4;2.5',
                    extraPlugins: 'lineheight',
                    fontSize_sizes:
                        '8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;36/36px;48/48px;52/52px;58/58px;72/72px;78/78px;84/84px;102/102px'
                }}
            />
        </div>
    );
};

export default ElementEditor;
