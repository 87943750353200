import React from 'react';
import dateFormat from 'dateformat';

import '../../Assets/Templates/MainTemplate.css';
import constants from '../../Constants';

export const getKiloPrice = (weight, total) => {
    let kiloPrice = Math.round(100 * ((1000 / weight) * total.toString().replace(',', '.'))) / 100;

    if (kiloPrice.toString().indexOf('.') !== -1) {
        const parts = kiloPrice.toString().split('.');
        if (parts[1].length === 1) {
            parts[1] += '0';
        }

        kiloPrice = parts[0] + ',' + parts[1];
    }

    return kiloPrice;
};

export const getPiecePrice = (pieceNb, total) => {
    const res = Math.round((100 * parseFloat(total.toString().replace(',', '.'))) / pieceNb) / 100;

    return res.toString().replace('.', ',');
};

const getRayonImage = (rayon) => {
    let fileName = '';

    switch (rayon) {
        case 'fruits':
            fileName = 'fruits-legumes.svg';
            break;
        case 'butchery':
            fileName = 'boucherie.svg';
            break;
        case 'fishShop':
            fileName = 'poissonnerie.svg';
            break;
        case 'bakery':
            fileName = 'boulangerie.svg';
            break;
        case 'cheese':
            fileName = 'fromagerie.svg';
            break;
        case 'delicatessen':
            fileName = 'charcuterie.svg';
            break;
        case 'caterer':
            fileName = 'caterer.svg';
            break;
        case 'product':
            fileName = 'products.svg';
            break;
        default:
            break;
    }

    return fileName;
};

const getColorFromCategory = (rayon) => {
    let color = '#444';

    if (rayon === 'fruits') {
        color = '#7ED321';
    } else if (rayon === 'butchery') {
        color = '#FF7F7F';
    } else if (rayon === 'fishShop') {
        color = '#17B5B4';
    } else if (rayon === 'bakery') {
        color = '#FFC20E';
    } else if (rayon === 'cheese') {
        color = '#FFC20E';
    } else if (rayon === 'delicatessen') {
        color = '#ee7654';
    } else if (rayon === 'caterer') {
        color = '#A77EC1';
    } else if (rayon === 'product') {
        color = '#5F7EE0';
    }

    return color;
};

export const getPathFromCategory = (rayon, filename) => {
    let path = '';

    switch (rayon) {
        case 'fruits':
            path = `${constants.cloud_storage}/Assets/Library/Fruits/${filename}`;
            break;
        case 'butchery':
            path = `${constants.cloud_storage}/Assets/Library/Butchery/${filename}`;
            break;
        case 'cheese':
            path = `${constants.cloud_storage}/Assets/Library/Cheese/${filename}`;
            break;
        case 'fishShop':
            path = `${constants.cloud_storage}/Assets/Library/FishShop/${filename}`;
            break;
        case 'bakery':
            path = `${constants.cloud_storage}/Assets/Library/Bakery/${filename}`;
            break;
        case 'delicatessen':
            path = `${constants.cloud_storage}/Assets/Library/Delicatessen/${filename}`;
            break;
        case 'caterer':
            path = `${constants.cloud_storage}/Assets/Library/Caterer/${filename}`;
            break;
        case 'product':
            path = filename;
            break;
        default:
            break;
    }

    return path;
};

export const getTitleFromCategory = (rayon, obj) => {
    let title = '';

    switch (rayon) {
        case 'fruits':
            title = obj.fruitName;
            break;
        case 'butchery':
        case 'cheese':
        case 'delicatessen':
        case 'fishShop':
        case 'bakery':
        case 'caterer':
        case 'product':
            title = obj.category;
            break;
        default:
            break;
    }

    return title;
};

export const getDataFromCategory = (rayon, obj) => {
    let data = null;

    switch (rayon) {
        case 'fruits':
            data = (
                <React.Fragment>
                    {!obj.subtitle || (
                        <p className="subtitle">
                            <strong>{obj.subtitle}</strong>
                        </p>
                    )}
                    {!obj.variety || (
                        <p>
                            Variété: <strong>{obj.variety}</strong>
                        </p>
                    )}
                    {!obj.category || (
                        <p>
                            Catégorie: <strong>{obj.category}</strong>
                        </p>
                    )}
                    {!obj.caliber || (
                        <p>
                            Calibre: <strong>{obj.caliber}</strong>
                        </p>
                    )}
                </React.Fragment>
            );
            break;
        case 'butchery':
        case 'delicatessen':
            data = (
                <React.Fragment>
                    <p>{obj.subtitle}</p>
                </React.Fragment>
            );
            break;
        case 'cheese':
            data = (
                <React.Fragment>
                    <p>{obj.subtitle}</p>
                    <p>{obj.milk}</p>
                </React.Fragment>
            );
            break;
        case 'fishShop':
            data = (
                <React.Fragment>
                    <p>{obj.subtitle}</p>
                    <p>{obj.fishSub}</p>
                    <p>{obj.subzone}</p>
                    <p>{obj.fishingMethod}</p>
                </React.Fragment>
            );
            break;
        case 'bakery':
            data = (
                <React.Fragment>
                    <div className="flex-bakery">
                        {!obj.madeHere || (
                            <div className="card">
                                <img src="/templates/csp.png" alt="csp" />
                                <p>Cuit sur place</p>
                            </div>
                        )}
                        {!obj.withoutGluten || (
                            <div className="card">
                                <img src="/templates/gluten.png" alt="gluten" />
                                <p>Sans gluten</p>
                            </div>
                        )}
                        {!obj.isFrozen || (
                            <div className="card">
                                <img src="/templates/surgele.png" alt="surgele" />
                                <p>Produit surgelé</p>
                            </div>
                        )}
                    </div>
                    <p>{obj.subtitle}</p>
                    <p>
                        {getAllergProducts(
                            obj.containsArachide,
                            obj.containsLactose,
                            obj.containsNuts,
                            obj.containsSesame,
                            obj.containsGluten,
                            obj.containsEggs,
                            obj.containsSoja
                        )}
                    </p>
                </React.Fragment>
            );
            break;
        case 'caterer':
            data = (
                <React.Fragment>
                    <p>{obj.subtitle}</p>
                    <p>{obj.ingredients}</p>
                </React.Fragment>
            );
            break;
        case 'product':
            data = (
                <React.Fragment>
                    <p>{obj.subtitle}</p>
                </React.Fragment>
            );
            break;
        default:
            break;
    }

    return data;
};

const getAllergProducts = (
    containsArachide,
    containsLactose,
    containsNuts,
    containsSesame,
    containsGluten,
    containsEggs,
    containsSoja
) => {
    const allerg = [];

    if (containsArachide) {
        allerg.push('Arachide');
    }

    if (containsLactose) {
        allerg.push('Lactose');
    }

    if (containsNuts) {
        allerg.push('Fruits à coque');
    }

    if (containsSesame) {
        allerg.push('Sésame');
    }

    if (containsGluten) {
        allerg.push('Gluten');
    }

    if (containsEggs) {
        allerg.push('Oeufs');
    }

    if (containsSoja) {
        allerg.push('Soja');
    }

    if (allerg.length === 0) {
        return 'Ne contient pas de produits allergènes';
    }

    return 'Contient des produits allergènes : ' + allerg.reduce((acc, a) => acc + ', ' + a);
};

export const getFlag = (country, displayFlag) => {
    let flag = null;

    if (displayFlag === false) {
        return null;
    }

    switch (country) {
        case 'France':
            flag = <img className="countryFlag" src="/flags/fr.png" alt="france" />;
            break;
        case 'Espagne':
            flag = <img className="countryFlag" src="/flags/es.png" alt="spain" />;
            break;
        case 'Argentine':
            flag = <img className="countryFlag" src="/flags/ar.png" alt="argentina" />;
            break;
        case 'Bresil':
            flag = <img className="countryFlag" src="/flags/br.png" alt="brazil" />;
            break;
        case 'Ecosse':
            flag = <img className="countryFlag" src="/flags/ec.png" alt="scotland" />;
            break;
        case 'Irlande':
            flag = <img className="countryFlag" src="/flags/ir.png" alt="ireland" />;
            break;
        case 'Europe':
            flag = <img className="countryFlag" src="/flags/ue.png" alt="europeean_union" />;
            break;
        case 'Uruguay':
            flag = <img className="countryFlag" src="/flags/ur.png" alt="uruguay" />;
            break;
        default:
            break;
    }

    return flag;
};

export const getContainer = (obj) => {
    const unit = obj.priceUnit;

    if ((obj.priceSupport === 'barquette' || obj.weightUnit === 'barquette') && unit === 'number') {
        return 'la barquette';
    } else if ((obj.priceSupport === 'cagette' || obj.weightUnit === 'cagette') && unit === 'number') {
        return 'la cagette';
    } else if ((obj.priceSupport === 'sachet' || obj.weightUnit === 'sachet') && unit === 'number') {
        return 'le sachet';
    } else if ((obj.priceSupport === 'filet' || obj.weightUnit === 'filet') && unit === 'number') {
        return 'le filet';
    } else if (unit === 'number') {
        return 'les';
    }

    return null;
};

const screenSizeFactor = (orientation) => {
    let f = 1;

    switch (orientation) {
        case 'ring':
            f = 1 / 2;
            break;
        case 'ligne_caisse':
            f = 1.25;
            break;
        default:
            break;
    }

    return f;
};

export const padNum = (num) => {
    if (!num || num.length > 2) {
        return '00';
    }

    const n = parseInt(num) || 0;

    if (num[0] === '0') {
        return `0${n}`;
    }

    if (n <= 9) {
        return `${n}0`;
    }

    return n;
};

export const getLabels = (obj) => {
    let labels = obj.labels || [];

    if (obj.rayon === 'bakery' && obj.bio) {
        labels.push('ab.jpg');
    } else if (obj.rayon === 'bakery' && obj.labelrouge) {
        labels.push('labelrouge.png');
    } else if (obj.label) {
        if (
            obj.rayon === 'fruits' ||
            obj.rayon === 'butchery' ||
            obj.rayon === 'cheese' ||
            obj.rayon === 'fishShop' ||
            obj.rayon === 'delicatessen' ||
            obj.rayon === 'caterer'
        ) {
            labels = obj.label ? [obj.label] : [];
        }
    }

    return labels;
};

export const getQuantityText = (obj) => {
    if (obj.priceUnit === 'number') {
        if (obj.weight >= 1000) {
            return (obj.weight / 1000).toString().replace('.', ',') + 'kg';
        }

        return obj.weight + 'g';
    }

    if (obj.priceUnit === 'kilo') {
        return 'le kg';
    }

    if (obj.priceUnit === 'text') {
        return obj.weightUnit || obj.priceSupport;
    }

    if (obj.priceUnit === 'piece') {
        return (
            <span style={{ fontSize: screenSizeFactor(obj.orientation) * 50 }}>
                {(() => {
                    if (!obj.pieceNb || parseInt(obj.pieceNb) === 1) {
                        return 'la pièce';
                    }

                    return `les ${obj.pieceNb} pièces`;
                })()}
            </span>
        );
    }

    if (obj.priceUnit === 'lot') {
        return (
            <span style={{ fontSize: screenSizeFactor(obj.orientation) * 50 }}>
                {(() => {
                    if (!obj.pieceNb || parseInt(obj.pieceNb) === 1) {
                        return 'le lot';
                    }

                    return `le lot de ${obj.pieceNb}`;
                })()}
            </span>
        );
    }
};

class MainTemplate extends React.Component {
    render() {
        let labels = getLabels(this.props);

        return (
            <div className={`MainTemplate ${this.props.orientation} ${this.props.rayon}`}>
                {!this.props.filename || (
                    <div className="img-ctr">
                        <img
                            className="mainImg"
                            src={getPathFromCategory(this.props.rayon, this.props.filename)}
                            alt={this.props.filename}
                        />
                    </div>
                )}
                {!this.props.promotion || (
                    <div style={{ backgroundColor: getColorFromCategory(this.props.rayon) }} className="promo">
                        Promo
                    </div>
                )}
                <div className="rayon" style={{ borderColor: getColorFromCategory(this.props.rayon) }}>
                    <img src={`/templates/${getRayonImage(this.props.rayon)}`} alt="" />
                </div>
                <div className="label">
                    {labels.map((l) => (
                        <img key={l} src={`/labels/${l}`} alt={l} />
                    ))}
                </div>
                <div className="data">
                    <div className="origin" style={{ opacity: !!this.props.originCountry ? 1 : 0 }}>
                        <div className="title">
                            {getFlag(this.props.originCountry, this.props.displayFlag)}
                            {this.props.rayon === 'bakery' || (
                                <span>{!!this.props.originType ? this.props.originType : 'Origine'}</span>
                            )}
                        </div>
                        <h2>{this.props.originCountry}</h2>
                    </div>
                    <div className="infos">
                        <div className="left">
                            <div className="id">
                                <h1>{getTitleFromCategory(this.props.rayon, this.props)}</h1>
                                {getDataFromCategory(this.props.rayon, this.props)}
                                {this.props.priceUnit !== 'number' || (
                                    <p>Soit: {getKiloPrice(this.props.weight, this.props.price)}€ le Kg</p>
                                )}
                                {this.props.priceUnit !== 'piece' || !this.props.pieceNb || this.props.pieceNb <= 1 || (
                                    <p>Soit: {getPiecePrice(this.props.pieceNb, this.props.price)}€ pièce</p>
                                )}
                                {this.props.priceUnit !== 'lot' || !this.props.pieceNb || this.props.pieceNb <= 1 || (
                                    <p>Soit: {getPiecePrice(this.props.pieceNb, this.props.price)}€ pièce</p>
                                )}
                            </div>
                            <div
                                className="lasting"
                                style={{
                                    color: this.props.promotion ? getColorFromCategory(this.props.rayon) : '#fff',
                                    backgroundColor: this.props.expirationDate ? '#111' : 'transparent'
                                }}
                            >
                                {!this.props.expirationDate ||
                                    `Offre valable jusqu'au ${dateFormat(this.props.expirationDate, 'dd/mm')}`}
                            </div>
                        </div>
                        <div
                            className="price"
                            style={{
                                backgroundColor: this.props.promotion ? getColorFromCategory(this.props.rayon) : '#222'
                            }}
                        >
                            <div
                                className="value"
                                style={{
                                    color: this.props.promotion ? '#222' : getColorFromCategory(this.props.rayon)
                                }}
                            >
                                <span className="int">{this.props.price.toString().split(/[.,]/)[0]}</span>
                                <span className="eur">&euro;</span>
                                <span className="dec">
                                    {this.props.price ? padNum(this.props.price.toString().split(/[.,]/)[1]) : ''}
                                </span>
                            </div>
                            {!this.props.origPrice || (
                                <div className="crossedValue">
                                    <span className="int">{this.props.origPrice.toString().split(/[.,]/)[0]}</span>
                                    <span className="eur">&euro;</span>
                                    <span className="dec">
                                        {this.props.origPrice
                                            ? padNum(this.props.origPrice.toString().split(/[.,]/)[1])
                                            : ''}
                                    </span>
                                    <div className="bar"></div>
                                </div>
                            )}
                            <div className="unit">
                                <div className="contenant">{getContainer(this.props)}</div>
                                <div className="quantity">{getQuantityText(this.props)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainTemplate;
