import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PreviewProduct from '../PreviewProduct';
import Attributes from './Atributes';
import ChooseProduct from './Choose';
import SetPrice from './SetPrice';

class Product extends React.Component {
    constructor(props) {
        super();

        this.state = {
            // State
            options: [],
            step: 0,

            duration: 5,

            // Crea
            _id: null,
            filename: '',
            subtitle: '',
            category: '',
            ingredients: '',
            price: '',
            origPrice: '',
            priceUnit: 'kilo',
            pieceNb: '1',
            weight: '100',
            weightUnit: 'g',
            promotion: false,
            expirationDate: null,
            startDate: null,
            labels: [],
            templateName: props.templateName
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setSlide = this.setSlide.bind(this);
        this.handleSaveCrea = this.handleSaveCrea.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentDidMount() {}

    reset() {
        this.setState({
            _id: null,
            filename: '',
            subtitle: '',
            category: '',
            ingredients: '',
            price: '',
            origPrice: '',
            priceUnit: 'kilo',
            pieceNb: '1',
            weight: '100',
            weightUnit: 'g',
            promotion: false,
            expirationDate: null,
            startDate: null,
            labels: [],
            templateName: this.props.templateName,
            duration: 5,

            step: 0
        });
    }

    handleClose() {
        this.reset();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSaveCrea() {
        const crea = {
            _id: this.state._id,
            filename: this.state.filename,
            category: this.state.category,
            subtitle: this.state.subtitle,
            ingredients: this.state.ingredients,
            price: this.state.price.toString().replace(',', '.'),
            origPrice: this.state.origPrice.toString().replace(',', '.'),
            priceUnit: this.state.priceUnit,
            pieceNb: this.state.pieceNb,
            weight: this.state.weight,
            weightUnit: this.state.weightUnit,
            promotion: this.state.promotion,
            expirationDate: this.state.expirationDate,
            startDate: this.state.startDate,
            labels: this.state.labels,
            templateName: this.state.templateName
        };

        this.props.handleSaveCrea({
            creative: crea,
            creaType: 'Product',
            startDate: this.state.startDate,
            expirationDate: this.state.expirationDate,
            duration: this.state.duration
        });

        this.handleClose();
    }

    setSlide(s, duration) {
        this.setState({ ...s, price: s.price.toString().replace('.', ','), step: 1, duration });
    }

    render() {
        let component = null;

        switch (this.state.step) {
            case 0:
                component = (
                    <ChooseProduct
                        filename={this.state.filename}
                        onSelectImage={(img) => this.setState({ filename: img })}
                    />
                );
                break;
            case 1:
                component = (
                    <Attributes
                        subtitle={this.state.subtitle}
                        category={this.state.category}
                        onChange={this.handleChange}
                    />
                );
                break;
            case 2:
                component = (
                    <SetPrice
                        price={this.state.price}
                        origPrice={this.state.origPrice}
                        priceUnit={this.state.priceUnit}
                        pieceNb={this.state.pieceNb}
                        weight={this.state.weight}
                        weightUnit={this.state.weightUnit}
                        promotion={this.state.promotion}
                        expirationDate={this.state.expirationDate}
                        handleChange={this.handleChange}
                        startDate={this.state.startDate}
                        allowSlidesDurationChange={this.props.allowSlidesDurationChange}
                        maxDuration={this.props.maxDuration}
                        onChangeDuration={(e) => this.setState({ duration: parseInt(e.target.value) })}
                        duration={this.state.duration}
                    />
                );
                break;
            default:
                break;
        }

        return (
            <div>
                <header>
                    <div className="progress-assistant">
                        <div className={`item${this.state.step >= 0 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(0, this.state.step) })}
                            >
                                1
                            </div>
                            {this.state.step !== 0 || <span>Choisissez un produit</span>}
                        </div>
                        <div className={`item${this.state.step >= 1 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(1, this.state.step) })}
                            >
                                2
                            </div>
                            {this.state.step !== 1 || <span>Renseignez plus d’informations</span>}
                        </div>
                        <div className={`item${this.state.step >= 2 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(2, this.state.step) })}
                            >
                                3
                            </div>
                            {this.state.step !== 2 || <span>Prix et programmation</span>}
                        </div>
                    </div>
                    <div className="buttons">
                        {this.state.step <= 0 || (
                            <button
                                className="prev"
                                onClick={() => this.setState((prevState) => ({ step: prevState.step - 1 }))}
                            >
                                Retour
                            </button>
                        )}
                        <button
                            className="button-assistant"
                            disabled={
                                (this.state.step === 2 && this.state.price.trim().length === 0) ||
                                (this.state.step === 0 && this.state.filename.length === 0)
                            }
                            onClick={() => {
                                if (this.state.step < 2) {
                                    return this.setState((prevState) => ({ step: prevState.step + 1 }));
                                }

                                this.handleSaveCrea();
                            }}
                        >
                            {this.state.step < 2 ? 'Suivant' : 'Enregistrer'}
                        </button>
                    </div>
                </header>
                <div className="ProductsAssistant">
                    <Row>
                        <Col md={9}>{component}</Col>
                        <Col md={3}>
                            <p className="center">Prévisualisation</p>
                            <PreviewProduct {...this.state} orientation={this.props.orientation} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Product;
