import React from 'react';
import { PreviewComponent } from './PreviewFruit';

const PreviewFishShop = props => (
    <div className="PreviewFruit">
        <div className={'CenterFruit o' + props.orientation}>
            <div className="PreviewFruitContainer">
                <PreviewComponent rayon="fishShop" {...props} />
            </div>
        </div>
    </div>
);

export default PreviewFishShop;
