import React, { useEffect, useRef, useState } from 'react';
import { fetchApi, sendFile } from '../Services/NetworkServcice';
import dateFormat from 'dateformat';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { notify } from 'react-notify-toast';
import swal from 'sweetalert';
import constants from '../Constants';
import HeaderNotifications from '../Components/HeaderNotifications';
import { Link } from 'react-router-dom';

const CREA_PEOPLE = {
    0: '---',
    1: 'Laura',
    2: 'Marianne',
    6: 'Alyssia',
    7: 'Fantine',
    3: 'Jade',
    4: 'Direction',
    5: 'Camille',
    8: 'Rthur(A)',
    9: 'Dany(N)'
};

function clearTags(html) {
    if (!html) {
        return null;
    }

    html = html.replace(/<a>.*<\/a>/, '[Link removed]');
    html = html.replace(/<script>.*<\/script>/, '[Script tag removed]');
    return html;
}

function strip(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = clearTags(html);
    return tmp.textContent || tmp.innerText || '';
}

const fileListToList = (fileList) => {
    const list = [];

    for (let i = 0; i < fileList.length; i++) {
        list.push(fileList.item(i));
    }

    return list;
};

const getTag = (status) => {
    if (!status || status === 0) {
        return null;
    }

    const STATUSES_COLORS = ['', '#d1ecf1', '#e2e3e5', '#d4edda', '#fff3cd', '#f8d7da'];

    return <div className="status" style={{ backgroundColor: STATUSES_COLORS[status] }}></div>;
};

const ThreadItem = (props) => {
    const lastMessage = props.messages[props.messages.length - 1];
    const unread = props.admin ? !props.readSupport : !props.read;

    let lastMessageFrom = lastMessage.from || 'Support';
    if (props.admin && lastMessageFrom === 'Support') {
        for (const message of props.messages) {
            if (message.from !== 'Support') {
                lastMessageFrom = message.from;
                break;
            }
        }
    }

    let signFrom = '';
    if (props.admin) {
        for (const message of props.messages) {
            if (message.signFrom) {
                signFrom = message.signFrom;
            }
        }
    }

    return (
        <li
            onClick={(e) => {
                if (e.target.dataset && e.target.dataset.action === 'delete') {
                    return;
                }

                props.onClick();
            }}
            className={`${props.selected ? 'selected' : ''} ${unread ? 'unread' : ''}`}
        >
            <div className="info" data={props._id}>
                <div>
                    <p>De: {lastMessageFrom}</p>
                    <p>{signFrom}</p>
                </div>
                {!props.admin || <div className="pastille">{getTag(props.status)}</div>}
                <p>Reçu le {dateFormat(lastMessage.date, 'dd/mm/yyyy HH:MM')}</p>
            </div>
            <div className="unread-round"></div>
            <h3>{props.subject}</h3>
            <p>{strip(lastMessage.message)}</p>
            <div className="delete" data-action="delete" onClick={props.onDelete}>
                <i data-action="delete" className="fa fa-times"></i>
            </div>
            {!props.admin || !props.pplAssigned || <div className="assigned">{CREA_PEOPLE[props.pplAssigned][0]}</div>}
        </li>
    );
};

const ThreadBody = (props) => {
    const [state, setState] = useState({
        body: '',
        files: [],
        read: true
    });

    const fileInput = useRef(null);

    const sendMessage = () => {
        swal({
            title: 'Envoyer?',
            text: 'Le message va être envoyé',
            icon: 'info',
            buttons: true
        }).then((willSend) => {
            if (willSend) {
                const data = new FormData();
                const fileNameList = [];

                for (let i = 0; i < state.files.length; i++) {
                    data.append(state.files[i].name, state.files[i]);
                    fileNameList.push(state.files[i].name);
                }

                const to = props.admin ? (props.from === 'Support' ? props.to : props.from) : 'Support';

                data.append('id', props._id);
                data.append('message', state.body || '(pas de message)');
                data.append('to', to);

                sendFile(`/messages`, {
                    method: 'POST',
                    body: data
                })
                    .then((res) => res.json())
                    .then(({ files, from }) => {
                        props.onNewMessage({
                            date: new Date(),
                            files,
                            from,
                            message: state.body,
                            to,
                            _id: new Date().getTime()
                        });

                        setState({
                            body: '',
                            files: []
                        });

                        notify.show("L'email a bien été envoyé.", 'success');
                    })
                    .catch(() => {
                        notify.show('Une erreur est survenue. Veuillez ré-essayer', 'error');
                    });
            }
        });
    };

    if (!props.messages) {
        return null;
    }

    return (
        <React.Fragment>
            <h4>{props.subject}</h4>
            <div className="buttons-contact">
                {!props.admin || (
                    <button
                        className="btn btn-info"
                        onClick={() => {
                            fetchApi(`/admin/loginAsUser/${props.userId}`)
                                .then((res) => res.json())
                                .then(({ token }) => {
                                    localStorage.setItem('old_token', localStorage.getItem('id_token'));
                                    localStorage.setItem('old_role', 'admin');
                                    localStorage.setItem('id_token', token);

                                    props.setupUser();

                                    props.history.replace('/');
                                });
                        }}
                    >
                        Se connecter
                    </button>
                )}
                {!state.read || (
                    <button
                        className="btn btn-info"
                        onClick={() =>
                            fetchApi(`/messages/unread/${props._id}`, { method: 'PATCH' }).then(() => {
                                setState({ ...state, read: false });
                                props.updateCurrentThread({ read: false, readSupport: false });
                            })
                        }
                    >
                        Marquer non lu
                    </button>
                )}
                {!props.admin || (
                    <React.Fragment>
                        <div className="element">Etat:</div>
                        <select
                            className="form-control"
                            value={props.status}
                            onChange={(e) => {
                                const status = parseInt(e.target.value);

                                fetchApi(`/messages/status/${props._id}`, {
                                    method: 'PATCH',
                                    body: JSON.stringify({ status })
                                }).then(() => {
                                    notify.show('Mis à jour', 'success');
                                    props.updateCurrentThread({ status });
                                });
                            }}
                        >
                            <option value="0">---</option>
                            <option value="4">A traiter</option>
                            <option value="5">A traiter / Important</option>
                            <option value="1">Réponse envoyée</option>
                            <option value="2">En cours...</option>
                            <option value="3">Terminé</option>
                        </select>
                        <div className="element" style={{ marginLeft: 12 }}>
                            Assigné:
                        </div>
                        <select
                            className="form-control"
                            value={props.pplAssigned || 0}
                            onChange={(e) => {
                                const pplAssigned = parseInt(e.target.value);

                                fetchApi(`/messages/assign/${props._id}`, {
                                    method: 'PATCH',
                                    body: JSON.stringify({ assigned: pplAssigned })
                                }).then(() => {
                                    notify.show('Mis à jour', 'success');
                                    props.updateCurrentThread({ pplAssigned });
                                });
                            }}
                        >
                            {Object.keys(CREA_PEOPLE).map(k => (
                                <option value={k}>{CREA_PEOPLE[k]}</option>
                            ))}
                        </select>
                    </React.Fragment>
                )}
            </div>
            <div>
                {props.messages.map((m, i) => (
                    <div key={m._id} className="message">
                        <h6>
                            De {m.from} - {dateFormat(m.date, 'dd/mm/yyyy HH:MM')}{' '}
                            {i < props.messages.length - 1 || m.from === 'Support' || (
                                <button
                                    style={{ display: props.admin ? 'initial' : 'none' }}
                                    onClick={() =>
                                        swal({
                                            title: 'Suppression de message',
                                            text: 'Ce message sera supprimé et impossible à récupérer. Confirmer?',
                                            icon: 'error',
                                            buttons: ['Non', 'Oui']
                                        }).then((res) => {
                                            if (res) {
                                                fetchApi(`/messages/unsend/${props._id}/${m._id}`, {
                                                    method: 'PATCH'
                                                }).then((res) => {
                                                    if (res.status === 200) {
                                                        swal({
                                                            title: 'Opération réussie',
                                                            text: 'Message supprimé',
                                                            icon: 'success'
                                                        });

                                                        const messages = props.messages.filter(
                                                            ({ _id }) => _id !== m._id
                                                        );

                                                        props.updateCurrentThread({ messages });
                                                    }
                                                });
                                            }
                                        })
                                    }
                                    className="btn btn-danger unsend-button "
                                >
                                    Unsend
                                </button>
                            )}
                        </h6>
                        <div className="body">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: clearTags(m.message)
                                }}
                            ></span>
                        </div>
                        {!m.files || m.files.length === 0 || (
                            <div className="attachements">
                                <div className="flex-cols">
                                    <i className="fa fa-paperclip"></i>
                                    <span>{m.files.length} fichiers joints:</span>
                                    <div className="files">
                                        {m.files.map((f) => (
                                            <a
                                                key={f}
                                                className="file"
                                                target="__blank"
                                                href={`${constants.cloud_storage}/Attachments/${f}`}
                                            >
                                                {f}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="contact-infos">
                {!props.contactName || <p>Nom: {props.contactName}</p>}
                {!props.contact || <p>Contact: {props.contact}</p>}
            </div>
            <div className="message-add new-message">
                <div className="form-group">
                    {!props.admin || (
                        <div style={{ textAlign: 'right' }}>
                            Réponse auto:{' '}
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        const data =
                                            '<p>Bonjour,</p>\n\n<p>Nous avons bien re&ccedil;u votre demande, nous y travaillons et revenons vers vous dans les meilleurs d&eacute;lais.</p>\n\n<p>Cordialement,</p>\n\n<p>&nbsp;</p>\n\n<p>L&#39;&eacute;quipe DOOHYOULIKE</p>\n';
                                        setState({ ...state, body: data });
                                    } else {
                                        setState({ ...state, body: '' });
                                    }
                                }}
                            />
                        </div>
                    )}
                    <CKEditor
                        activeClass="p10"
                        editor={ClassicEditor}
                        data={state.body}
                        onChange={(e, editor) =>
                            setState((prevState) => ({
                                ...prevState,
                                body: editor.getData()
                            }))
                        }
                        config={{
                            toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline'],
                            autoGrow_maxHeight: '600',
                            // removePlugins: 'elementspath',
                            resize_enabled: true,
                            height: '100%'
                        }}
                    />
                </div>
                <div className="buttons">
                    <input
                        className="file"
                        type="file"
                        multiple={true}
                        ref={fileInput}
                        onChange={(e) =>
                            setState({
                                ...state,
                                files: [...state.files, ...fileListToList(e.target.files)]
                            })
                        }
                    />
                    <div className="attachements-list">
                        {state.files.map((f, i) => (
                            <div
                                key={f.name}
                                className="att-item"
                                onClick={() =>
                                    setState({
                                        ...state,
                                        files: state.files.filter((_, id) => id !== i)
                                    })
                                }
                            >
                                <i className="fa fa-file"></i>
                                <span>{f.name}</span>
                            </div>
                        ))}
                    </div>
                    <span className="attachement" onClick={() => fileInput.current.click()}>
                        <i className="fa fa-paperclip"></i>Ajouter un fichier
                    </span>
                    <button className="btn btn-info send" onClick={sendMessage}>
                        Envoyer
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const NewMessage = ({ onNewThread }) => {
    const [state, setState] = useState({
        subject: 'Veuillez selectionner un sujet',
        contactName: '',
        contact: '',
        subjectDetails: '',
        files: [],
        body: ''
    });

    const fileInput = useRef(null);

    const SUBJECTS = [
        'Veuillez selectionner un sujet',
        "Problème d'écran",
        'Demande de nouvelle affiche',
        'Je souhaite un écran DOOHYOULIKE supplémentaire',
        'Autre'
    ];

    if (window.location.host === 'campus.doohyoulike.com')
        SUBJECTS.splice(3, 1);

    const sendMessage = () => {
        if (!state.contactName || !state.contact) {
            return notify.show('Merci de rentrer vos informations de contact (nom, numéro)', 'warning');
        }

        if (state.subject === 'Veuillez selectionner un sujet') {
            return notify.show('Veuillez selectionner un sujet.', 'warning');
        }

        swal({
            title: 'Envoyer?',
            text: 'Le message va être envoyé',
            icon: 'info',
            buttons: true
        }).then((willSend) => {
            if (willSend) {
                const data = new FormData();
                const fileNameList = [];

                for (let i = 0; i < state.files.length; i++) {
                    data.append(state.files[i].name, state.files[i]);
                    fileNameList.push(state.files[i].name);
                }

                data.append('subject', state.subject);
                data.append('message', state.body || '(pas de message)');
                data.append('contact', state.contact);
                data.append('contactName', state.contactName);

                sendFile(`/messages`, {
                    method: 'POST',
                    body: data
                })
                    .then((res) => res.json())
                    .then(({ data }) => {
                        onNewThread(data);
                        notify.show("L'email a bien été envoyé.", 'success');
                    })
                    .catch(() => {
                        notify.show('Une erreur est survenue. Veuillez ré-essayer', 'error');
                    });
            }
        });
    };

    return (
        <div className="new-message">
            <h4 className="new">Nouveau message</h4>
            <div className="form-group">
                <label>Sujet* :</label>
                <select
                    className="form-control"
                    value={state.subject}
                    onChange={(e) =>
                        setState({
                            ...state,
                            subject: e.target.value
                        })
                    }
                >
                    {SUBJECTS.map((s) => (
                        <option key={s} value={s}>
                            {s}
                        </option>
                    ))}
                </select>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Votre nom* :</label>
                        <input
                            type="text"
                            className="form-control"
                            value={state.contactName}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    contactName: e.target.value
                                })
                            }
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Numéro de téléphone* :</label>
                        <input
                            type="text"
                            className="form-control"
                            value={state.contact}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    contact: e.target.value
                                })
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label>Message :</label>
                <CKEditor
                    activeClass="p10"
                    editor={ClassicEditor}
                    data={state.body}
                    onChange={(e, editor) =>
                        setState((prevState) => ({
                            ...prevState,
                            body: editor.getData()
                        }))
                    }
                    config={{
                        toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline'],
                        autoGrow_maxHeight: '600',
                        // removePlugins: 'elementspath',
                        resize_enabled: true,
                        height: '100%'
                    }}
                />
            </div>
            <div className="buttons">
                <input
                    className="file"
                    type="file"
                    multiple={true}
                    ref={fileInput}
                    onChange={(e) =>
                        setState({
                            ...state,
                            files: [...state.files, ...fileListToList(e.target.files)]
                        })
                    }
                />
                <div className="attachements-list">
                    {state.files.map((f, i) => (
                        <div
                            key={f.name}
                            className="att-item"
                            onClick={() =>
                                setState({
                                    ...state,
                                    files: state.files.filter((_, id) => id !== i)
                                })
                            }
                        >
                            <i className="fa fa-file"></i>
                            <span>{f.name}</span>
                        </div>
                    ))}
                </div>
                <span className="attachement" onClick={() => fileInput.current.click()}>
                    <i className="fa fa-paperclip"></i>Ajouter un fichier
                </span>
                <button className="btn btn-info send" onClick={sendMessage}>
                    Envoyer
                </button>
            </div>
        </div>
    );
};

const Messages = (props) => {
    const [state, setState] = useState({
        threads: [],
        selectedThread: -1
    });

    const refreshNotifs = props.refreshNotifs;
    const refreshUnread = props.refreshUnread;

    useEffect(() => {
        fetchApi(`/messages${props.admin ? '/all' : ''}`)
            .then((res) => res.json())
            .then(({ threads }) => {
                const threadsSorted = threads.sort((a, b) => {
                    const lastMessageA = a.messages[a.messages.length - 1];
                    const lastMessageB = b.messages[b.messages.length - 1];

                    return new Date(lastMessageB.date) - new Date(lastMessageA.date);
                });

                setState((prevState) => ({
                    ...prevState,
                    threads: threadsSorted,
                    selectedThread: window.innerWidth >= 425 ? 0 : -1
                }));

                if (window.innerWidth >= 425 && threadsSorted.length > 0) {
                    const th = threadsSorted[0];

                    if ((props.admin && !th.readSupport) || (!props.admin && !th.read)) {
                        fetchApi(`/messages/${props.admin ? 'readA' : 'read'}/${th._id}`, {
                            method: 'PUT'
                        }).then(() => {
                            refreshUnread();
                            refreshNotifs();
                        });
                    }
                }
            });
    }, [props.admin, refreshUnread, refreshNotifs]);

    const archiveThread = (id) => {
        swal({
            title: 'Supprimer?',
            text: 'Le message va être supprimé',
            icon: 'warning',
            buttons: ['Annuler', 'OK']
        }).then((willDelete) => {
            if (willDelete) {
                fetchApi(`/messages/${props.admin ? 'adm/' : ''}${id}`, { method: 'DELETE' }).then(() => {
                    setState((prevState) => ({
                        ...prevState,
                        threads: prevState.threads.filter(({ _id }) => _id !== id),
                        selectedThread: window.innerWidth >= 425 ? 0 : -1
                    }));

                    swal({ text: 'Message supprimé', icon: 'success' });
                });
            }
        });
    };

    return (
        <div className="dyl-home contact">
            <header>
                <Link to="/" className="logo">
                    <img src="/image.png" alt="logo" />
                </Link>
                <p className="hidden-xs">Contact</p>
                <h3 className="hidden-xs">Vos messages</h3>
                <h3 className="visible-xs">Contact</h3>
                <HeaderNotifications {...props.notifsData} />
            </header>
            <div className="content">
                <div className="contact-columns">
                    <div className={`messages-list ${state.selectedThread !== -1 ? 'active' : ''}`}>
                        <div className="button-ctr hidden-xs">
                            <div
                                className="btn-new-message"
                                onClick={() =>
                                    setState({
                                        ...state,
                                        selectedThread: null
                                    })
                                }
                            >
                                <i className="fa fa-plus"></i> Rédiger un nouveau message
                            </div>
                        </div>
                        <div className="mobile-controls">
                            <div
                                className={'btn-add'}
                                onClick={() =>
                                    setState({
                                        ...state,
                                        selectedThread: null
                                    })
                                }
                            >
                                <i className="fa fa-plus"></i>
                            </div>
                        </div>
                        <div className="alert alert-danger subject-details">
                            Contact privilégié: Dany au <a href="tel:+33695709486">06.95.70.94.86</a>
                        </div>
                        <ul>
                            {state.threads
                                .filter(
                                    ({ archived, archivedAdm }) =>
                                        (!archived && !props.admin) || (!archivedAdm && props.admin)
                                )
                                .map((th, i) => (
                                    <ThreadItem
                                        key={th._id}
                                        admin={props.admin}
                                        selected={th._id === state.selectedThread}
                                        {...th}
                                        onClick={() => {
                                            if ((props.admin && !th.readSupport) || (!props.admin && !th.read)) {
                                                fetchApi(`/messages/${props.admin ? 'readA' : 'read'}/${th._id}`, {
                                                    method: 'PUT'
                                                }).then(() => {
                                                    props.refreshUnread();
                                                    props.refreshNotifs();
                                                });
                                            }

                                            if (props.admin) th.readSupport = true;
                                            else th.read = true;

                                            setState({
                                                ...state,
                                                selectedThread: th._id
                                            });
                                        }}
                                        onDelete={() => archiveThread(th._id)}
                                    />
                                ))}
                        </ul>
                    </div>
                    <div className={`message-content ${state.selectedThread !== -1 ? 'active' : ''}`}>
                        <div className="mobile-controls">
                            <div className="btn-ret" onClick={() => setState({ ...state, selectedThread: -1 })}>
                                <i className="fa fa-chevron-left"></i>
                            </div>
                        </div>
                        <div className="card">
                            {state.selectedThread !== null ? (
                                <ThreadBody
                                    key={state.selectedThread}
                                    {...state.threads.find((th) => th._id === state.selectedThread)}
                                    onNewMessage={(message) => {
                                        const threadObject = state.threads.find(
                                            (th) => th._id === state.selectedThread
                                        );

                                        setState((prevState) => ({
                                            ...prevState,
                                            threads: [
                                                {
                                                    ...threadObject,
                                                    messages: [...threadObject.messages, message]
                                                },
                                                ...state.threads.filter((th) => th._id !== state.selectedThread)
                                            ]
                                        }));
                                    }}
                                    admin={props.admin}
                                    history={props.history}
                                    setupUser={props.setupUser}
                                    updateCurrentThread={(values) => {
                                        const threads = state.threads;
                                        const idx = state.threads.findIndex((th) => th._id === state.selectedThread);

                                        if (idx === -1) return;

                                        state.threads[idx] = {
                                            ...state.threads[idx],
                                            ...values
                                        };

                                        setState({ ...state, threads });
                                    }}
                                />
                            ) : (
                                <NewMessage
                                    admin={props.admin}
                                    onNewThread={(th) =>
                                        setState({
                                            ...state,
                                            threads: [th, ...state.threads],
                                            selectedThread: 0
                                        })
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Messages;
