import React, { useState } from 'react';
import { Panel, Table } from 'react-bootstrap';
import constants from '../../Constants';
import AuthService from '../../Components/Auth/AuthService';
import { notify } from 'react-notify-toast';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getLastSuivi } from './Map';
import { fetchApi } from '../../Services/NetworkServcice';
import swal from 'sweetalert';
import xlsxPopulate from 'xlsx-populate';
import '../../Assets/editor.css';

const CampaignNumbers = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <div style={{ position: 'relative' }} onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)}>
            <div className="bTooltip" style={{ display: visible ? 'initial' : 'none' }}>
                {props.campaigns.map(({ name }) => (
                    <p key={name}>{name}</p>
                ))}
            </div>
            {props.campaigns.length}
        </div>
    );
};

const Screenshot = (props) => {
    return (
        <div className="rotation-wrapper-outer">
            <div className="rotation-wrapper-inner">
                <img
                    style={{
                        height: '40px',
                        border:
                            '2px solid ' + (new Date() - new Date(props.dev.screen) < 30 * 1000 * 60 ? 'black' : 'red')
                    }}
                    className={
                        props.dev.orientation === 'portrait' || props.dev.orientation === 'portrait_direct'
                            ? 'rotation-element'
                            : 'mt--22'
                    }
                    src={`${constants.endpoint}/admin/screenshot/${props.dev.id}?d=${new Date(
                        props.dev.screen
                    ).getTime()}`}
                    alt="Last screen"
                    height="40"
                    width="68"
                />
            </div>
        </div>
    );
};

const getShopsNumberFromDevices = (devices) => {
    const s = new Set();

    for (const d of devices) {
        if (d.Shop) {
            s.add(d.Shop.id);
        }
    }

    return s.size;
};

const getPlayerModelOptions = (devices) => {
    const models = {};

    for (const d of devices) {
        let model = d.model;

        if (d.model === 'Default string' || d.model === 'AK1V77001') {
            model = 'Probably AK7';
            d.model = 'AK7';
        }

        if (model in models) {
            models[model].count++;
        } else {
            models[model] = { count: 1, dmi: d.model };
        }
    }

    const options = [{ value: 'Tous', label: 'Tous' }];

    for (const m in models) {
        if (m === 'Default string') {
            options.push({ value: models[m].dmi, label: `Maybe AK7 (${models[m].count})` });
        } else if (m === 'J34') {
            options.push({ value: models[m].dmi, label: `J34 - Coofun (${models[m].count})` });
        } else if (m === 'GN31') {
            options.push({ value: models[m].dmi, label: `GN31 - Minis Forum (${models[m].count})` });
        } else {
            options.push({ value: models[m].dmi, label: `${m} (${models[m].count})` });
        }
    }

    return options;
};

const getConnexionIcon = (isLan, isWifi) => {
    if (isWifi) {
        return 'wifi';
    }

    if (isLan) {
        return 'network-wired';
    } else {
        return 'signal';
    }
};

const Checkbox = ({ type = 'checkbox', name, checked = false, id, onChange, value }) => (
    <input type={type} name={name} checked={checked} onChange={onChange} id={id} value={value} className="checkedcp" />
);

Checkbox.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

const CampaignSlots = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <div style={{ position: 'relative' }} onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)}>
            <div className="bTooltip" style={{ display: visible ? 'initial' : 'none' }}>
                {props.campaigns.map(({ name, slots }) => (
                    <p key={name}>
                        {name}: {slots}
                    </p>
                ))}
            </div>
            {props.campaigns.map(({ slots }) => slots).reduce((acc, s) => acc + s, 0)}
        </div>
    );
};

const filterByLastCheck = (screens, lastSuivi, suivis) => {
    let minDate = new Date();
    let maxDate = new Date();

    if (lastSuivi === 0) {
        return screens;
    } else if (lastSuivi === 2) {
        minDate.setMonth(minDate.getMonth() - 1);
    } else if (lastSuivi === 1) {
        minDate.setMonth(minDate.getMonth() - 2);
        maxDate.setMonth(maxDate.getMonth() - 1);
    } else if (lastSuivi === 3) {
        maxDate.setMonth(maxDate.getMonth() - 2);
        minDate.setFullYear(2000);
    }

    return screens.filter((s) => {
        const comment = suivis[s.ShopId];

        if (!comment) {
            return false;
        }

        const d = new Date(comment.date);

        return d >= minDate && d <= maxDate;
    });
};

const downloadAsFile = (screens, stats) => {
    const list = [];

    for (const s of screens) {
        const data = {
            id: s.id,
            nom: s.name,
            groupe: s.Group?.name,
            enseigne: s.Shop?.Sign.name,
            magasin: s.Shop?.name,
            adresse: s.Shop?.address,
            zip: s.Shop?.zip,
            ville: s.Shop?.city,
            region: s.Shop?.region,
            tel: s.Shop?.tel,
            email: s.Shop?.email,
            contact: s.Shop?.contact,
            dernier_suivi: dateFormat(stats[s.Shop?.id]?.date, 'dd/mm/yyyy - HH:MM'),
            dernier_ping: dateFormat(s.status.lastPing, 'dd/mm/yyyy - HH:MM'),
            ecran: s.status.screenStatus,
            version: s.version,
            date_screenshot: s.screen
        };

        list.push(data);
    }

    if (list.length === 0) {
        return;
    }

    xlsxPopulate
        .fromBlankAsync()
        .then((w) => {
            for (let i = 0; i < Object.keys(list[0]).length; i++) {
                w.sheet(0)
                    .row(1)
                    .cell(i + 1)
                    .value(Object.keys(list[0])[i]);
            }

            for (let i = 0; i < list.length; i++) {
                for (let k = 0; k < Object.keys(list[0]).length; k++) {
                    w.sheet(0)
                        .row(i + 2)
                        .cell(k + 1)
                        .value(list[i][Object.keys(list[i])[k]]);
                }
            }

            return w.outputAsync();
        })
        .then((blob) => window.download(blob, 'screen_selection.xlsx'));
};

class Screens extends React.Component {
    constructor() {
        super();

        this.state = {
            devices: [],
            userSequences: [],
            campaigns: [],
            sortArgs: ['id', true, false],
            playerChecked: false,
            screenChecked: false,
            playerModel: 'Tous',
            maintenance: 'Tous',
            screenStatusSelected: '',
            playerStatusSelected: '',
            playerConnection: 'Tous',
            filter: '',
            stats: {},
            lastSuivi: 0,
            selectedScreens: [],
            value: '',
            mongoStore: null,
            lastRefresh: new Date()
        };

        this.interval = null;

        this.Auth = new AuthService();
        this.getSequenceFromDevice = this.getSequenceFromDevice.bind(this);
        this.getDeviceStatus = this.getDeviceStatus.bind(this);
        this.getDeviceConnection = this.getDeviceConnection.bind(this);
        this.getData = this.getData.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSendCommand = this.handleSendCommand.bind(this);
        this.filterdevices = this.filterdevices.bind(this);
    }

    sortTable(column, asc = true, text = true) {
        if (column === 'screenStatus') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return a.status.screenStatus.localeCompare(b.status.screenStatus);
                    } else {
                        return b.status.screenStatus.localeCompare(a.status.screenStatus);
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'status' || column === 'statusPing') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return new Date(a.status.lastPing).getTime() - new Date(b.status.lastPing).getTime();
                    } else {
                        return new Date(b.status.lastPing).getTime() - new Date(a.status.lastPing).getTime();
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'conn') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return new Date(a.connStore.lastConn).getTime() - new Date(b.connStore.lastConn).getTime();
                    } else {
                        return new Date(b.connStore.lastConn).getTime() - new Date(a.connStore.lastConn).getTime();
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'commercant') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    const aSlides = this.state.userSequences.find((s) => s.devices.indexOf(a.id) !== -1);

                    const bSlides = this.state.userSequences.find((s) => s.devices.indexOf(b.id) !== -1);

                    if (asc) {
                        return (
                            (aSlides && aSlides.creatives ? aSlides.creatives.length : 0) -
                            (bSlides && bSlides.creatives ? bSlides.creatives.length : 0)
                        );
                    } else {
                        return (
                            (bSlides && bSlides.creatives ? bSlides.creatives.length : 0) -
                            (aSlides && aSlides.creatives ? aSlides.creatives.length : 0)
                        );
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'pub' || column === 'pubtques') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return (
                            this.state.campaigns.filter(
                                (s) =>
                                    s.devices.indexOf(a.id) !== -1 &&
                                    new Date() < new Date(s.endDate) &&
                                    new Date() > new Date(s.startDate) &&
                                    s.active === true &&
                                    (column === 'pubtques' ? s.triggered === true : !s.triggered)
                            ).length -
                            this.state.campaigns.filter(
                                (s) =>
                                    s.devices.indexOf(b.id) !== -1 &&
                                    new Date() < new Date(s.endDate) &&
                                    new Date() > new Date(s.startDate) &&
                                    s.active === true &&
                                    (column === 'pubtques' ? s.triggered === true : !s.triggered)
                            ).length
                        );
                    } else {
                        return (
                            this.state.campaigns.filter(
                                (s) =>
                                    s.devices.indexOf(b.id) !== -1 &&
                                    new Date() < new Date(s.endDate) &&
                                    new Date() > new Date(s.startDate) &&
                                    s.active === true &&
                                    (column === 'pubtques' ? s.triggered === true : !s.triggered)
                            ).length -
                            this.state.campaigns.filter(
                                (s) =>
                                    s.devices.indexOf(a.id) !== -1 &&
                                    new Date() < new Date(s.endDate) &&
                                    new Date() > new Date(s.startDate) &&
                                    s.active === true &&
                                    (column === 'pubtques' ? s.triggered === true : !s.triggered)
                            ).length
                        );
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'slots') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return (
                            this.state.campaigns
                                .filter((s) => s.devices.indexOf(a.id) !== -1)
                                .reduce((acc, s) => acc + s.slots, 0) -
                            this.state.campaigns
                                .filter((s) => s.devices.indexOf(b.id) !== -1)
                                .reduce((acc, s) => acc + s.slots, 0)
                        );
                    } else {
                        return (
                            this.state.campaigns
                                .filter((s) => s.devices.indexOf(b.id) !== -1)
                                .reduce((acc, s) => acc + s.slots, 0) -
                            this.state.campaigns
                                .filter((s) => s.devices.indexOf(a.id) !== -1)
                                .reduce((acc, s) => acc + s.slots, 0)
                        );
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'Sign') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return a.Shop.Sign.name.toLowerCase().localeCompare(b.Shop.Sign.name.toLowerCase());
                    } else {
                        return b.Shop.Sign.name.toLowerCase().localeCompare(a.Shop.Sign.name.toLowerCase());
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'Group' || column === 'Shop') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return a[column].name.toLowerCase().localeCompare(b[column].name.toLowerCase());
                    } else {
                        return b[column].name.toLowerCase().localeCompare(a[column].name.toLowerCase());
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'sign') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return a.Sign.name.toLowerCase().localeCompare(b.Sign.name.toLowerCase());
                    } else {
                        return b.Sign.name.toLowerCase().localeCompare(a.Sign.name.toLowerCase());
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'screen') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    const aDate = new Date(a.screen || 0);
                    const bDate = new Date(b.screen || 0);

                    if (asc) {
                        return aDate.getTime() - bDate.getTime();
                    } else {
                        return bDate.getTime() - aDate.getTime();
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'suivi') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    const aStat = this.state.stats[a.ShopId];
                    const bStat = this.state.stats[b.ShopId];

                    let aDate = 0;
                    let bDate = 0;

                    if (aStat) {
                        aDate = new Date(aStat.date).getTime();
                    }

                    if (bStat) {
                        bDate = new Date(bStat.date).getTime();
                    }

                    if (asc) {
                        return aDate - bDate;
                    } else {
                        return bDate - aDate;
                    }
                }),
                sortArgs: arguments
            });
        } else if (column === 'programmaticOnly') {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return a.programmaticOnly.toString().localeCompare(b.programmaticOnly);
                    } else {
                        return b.programmaticOnly.toString().localeCompare(a.programmaticOnly);
                    }
                }),
                sortArgs: arguments
            });
        } else if (text) {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (a[column] && b[column]) {
                        if (asc) {
                            return a[column].localeCompare(b[column]);
                        } else {
                            return b[column].localeCompare(a[column]);
                        }
                    }
                    return -1;
                }),
                sortArgs: arguments
            });
        } else {
            this.setState({
                devices: this.state.devices.sort((a, b) => {
                    if (asc) {
                        return a[column] - b[column];
                    } else {
                        return b[column] - a[column];
                    }
                }),
                sortArgs: arguments
            });
        }
    }

    getData(mongoStore) {
        this.Auth.fetch(`${constants.endpoint}/admin/devices`)
            .then((res) => {
                if (res.success === false) {
                    notify.show('Impossible de recuperer les donnees: ' + res.message, 'warning');
                    return;
                }

                let devices = res.devices;

                mongoStore.statuses.forEach((s) => {
                    devices.forEach((dev) => {
                        if (dev.id === s.sqlId) {
                            dev.status = s;
                        }
                    });
                });

                devices.forEach((dev) => {
                    dev.screen = res.screenshots[dev.id];
                    dev.status = {
                        lastPing: new Date(0),
                        ...dev.status,
                        screenStatus: 'OFF',
                        ...res.store[dev.id]
                    };
                    dev.connStore = {
                        lastConn: res.connStore[dev.id] || new Date(0)
                    };
                });

                for (const device of devices) {
                    if (device.status.screenStatus === '') {
                        device.status.screenStatus = 'OFF';
                    } else if (device.status.screenStatus === 'IN TRANSITION FROM STANDBY TO ON') {
                        device.status.screenStatus = 'ON';
                    } else if (device.status.screenStatus === 'UNKNOWN') {
                        device.status.screenStatus = 'OFF';
                    } else if (device.status.screenStatus === 'STANDBY') {
                        device.status.screenStatus = 'VEILLE';
                    }
                }

                if (this.props.user) {
                    if (this.props.user.role.id === 1442) {
                        devices = devices.filter((d) => {
                            if (!d.Shop || !d.Shop.isPrismaflex) {
                                return false;
                            }

                            return true;
                        });
                    } else if (this.props.user.role.id === 1506) {
                        devices = devices.filter((d) => {
                            if (!d.Shop || !d.Shop.isMagicVisiom) {
                                return false;
                            }

                            return true;
                        });
                    } else {
                        devices = devices.filter((d) => {
                            if (!d.Shop) {
                                return false;
                            }

                            return !d.Shop.isPrismaflex && !d.Shop.isMagicVisiom;
                        });
                    }
                }

                this.setState(
                    {
                        devices: devices,
                        lastRefresh: new Date()
                    },
                    () => {
                        this.sortTable(...this.state.sortArgs);
                    }
                );
            })
            .catch((err) => {
                notify.show('Impossible de recuperer les donnees: ' + err.message, 'warning');
            });
    }

    componentDidMount() {
        this.Auth.fetch(`${constants.endpoint}/admin/devStatus`)
            .then((ress) => {
                if (ress.success === false) {
                    notify.show('Impossible de recuperer les donnees: ' + ress.message, 'warning');
                    return;
                }

                this.setState({
                    userSequences: ress.userSequences,
                    campaigns: ress.campaigns,
                    mongoStore: ress
                });

                this.getData(ress);
            })
            .catch((err) => {
                return notify.show('Impossible de recuperer les donnees: ' + err.message, 'warning');
            });

        const savedFilter = localStorage.getItem('filter');
        localStorage.removeItem('filter');

        if (savedFilter) {
            this.setState({
                filter: savedFilter
            });
        }

        fetchApi('/admin/history/stats')
            .then((res) => res.json())
            .then((stats) => {
                const statsHash = {};

                for (const s of stats) {
                    if (s.shopId in statsHash) continue;

                    statsHash[s.shopId] = s;
                }

                this.setState({ stats: statsHash });
            });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getSequenceFromDevice(id) {
        let name = 'Unknown';

        const seq = this.state.userSequences.find(({ devices }) => devices.indexOf(id) !== -1);

        if (seq) {
            return seq.name + ' (' + seq.creatives.length + ')';
        }

        return name;
    }

    getDeviceConnection(dev) {
        if (!dev.connStore) {
            return 'No conn_store found';
        }

        if ((new Date(this.state.lastRefresh).getTime() - new Date(dev.connStore.lastConn).getTime()) / 1000 <= 120) {
            return (
                <span className="label label-success">
                    <i className="fa fa-link"></i>
                </span>
            );
        } else {
            return (
                <span className="label label-danger">
                    <i className="fa fa-unlink"></i>
                </span>
            );
        }
    }

    getDeviceStatus(dev) {
        if (!dev.status) {
            return 'No status found';
        }

        if (dev.status.status === 'Running') {
            return (new Date(this.state.lastRefresh) - new Date(dev.status.lastPing)) / 1000 <= 120 ? (
                <span className="label label-success">Running</span>
            ) : (
                <span className="label label-danger">Off</span>
            );
        } else if (dev.status.status === 'Downloading') {
            return <span className="label label-warning">Downloading</span>;
        } else if (dev.status.status === 'Paired') {
            return <span className="label label-success">Linked</span>;
        }
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleChangeCheckbox(e) {
        if (e.target.checked) {
            this.setState({ selectedScreens: [...this.state.selectedScreens, parseInt(e.target.value, 10)] });
        } else {
            this.setState({
                selectedScreens: this.state.selectedScreens.filter((c) => c !== parseInt(e.target.value, 10))
            });
        }
    }

    handleSendCommand() {
        swal({
            text: `Êtes-vous sur de vouloir faire un ${this.state.value} sur ${this.state.selectedScreens.length} écrans ?`,
            title: 'La commande ne pourra pas être annulée',
            icon: 'warning',
            buttons: ['Annuler', 'Go'],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                if (this.state.value === 'reboot') {
                    for (const devId of this.state.selectedScreens) {
                        this.Auth.fetch(`${constants.endpoint}/admin/command/${devId}`, {
                            method: 'PUT',
                            body: JSON.stringify({
                                command: 'reboot'
                            })
                        });
                    }
                    swal(`Commande effectuée, Reboot de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }
                if (this.state.value === 'firmwareupdate') {
                    for (const devId of this.state.selectedScreens) {
                        this.Auth.fetch(`${constants.endpoint}/admin/command/${devId}`, {
                            method: 'PUT',
                            body: JSON.stringify({
                                command: 'firmwareUpdate'
                            })
                        });
                    }
                    swal(`Commande effectuée, FirmwareUpdate de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }
                if (this.state.value === 'screenshot') {
                    for (const devId of this.state.selectedScreens) {
                        this.Auth.fetch(`${constants.endpoint}/admin/command/${devId}`, {
                            method: 'PUT',
                            body: JSON.stringify({
                                command: 'screenshot'
                            })
                        });
                    }
                    swal(`Commande effectuée, screenshot de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }
                if (this.state.value === 'report') {
                    for (const devId of this.state.selectedScreens) {
                        this.Auth.fetch(`${constants.endpoint}/admin/command/${devId}`, {
                            method: 'PUT',
                            body: JSON.stringify({
                                command: 'report'
                            })
                        });
                    }
                    swal(`Commande effectuée, rapport de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }

                if (this.state.value === 'refresh') {
                    for (const devId of this.state.selectedScreens) {
                        fetchApi(`/admin/refresh/${devId}`, {
                            method: 'PATCH'
                        });
                    }

                    swal(`Commande effectuée, rapport de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }

                if (this.state.value === 'restartApp') {
                    for (const devId of this.state.selectedScreens) {
                        this.Auth.fetch(`${constants.endpoint}/admin/command/${devId}`, {
                            method: 'PUT',
                            body: JSON.stringify({
                                command: 'restartApp'
                            })
                        });
                    }
                    swal(`Commande effectuée, rapport de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }

                if (this.state.value === 'disableScreen') {
                    for (const devId of this.state.selectedScreens) {
                        fetchApi(`/admin/disabled/${devId}`, {
                            method: 'PATCH',
                            body: JSON.stringify({ disabled: true })
                        });
                    }

                    swal(`Commande effectuée, désactivation de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                } else if (this.state.value === 'enableScreen') {
                    for (const devId of this.state.selectedScreens) {
                        fetchApi(`/admin/disabled/${devId}`, {
                            method: 'PATCH',
                            body: JSON.stringify({ disabled: false })
                        });
                    }

                    swal(`Commande effectuée, activation de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }

                if (this.state.value === 'changeOrientation') {
                    for (const devId of this.state.selectedScreens) {
                        this.Auth.fetch(`${constants.endpoint}/admin/command/${devId}`, {
                            method: 'PUT',
                            body: JSON.stringify({
                                command: 'changeOrientation'
                            })
                        });
                    }
                    swal(`Commande effectuée, rapport de ${this.state.selectedScreens.length} Ecrans`, {
                        icon: 'success'
                    });
                    this.setState({ selectedScreens: [] });
                }
            } else {
                swal('Commande annulée');
            }
        });
    }

    filterdevices(d) {
        if (
            (this.state.filter || '').trim().length === 0 &&
            !this.state.screenChecked &&
            !this.state.playerChecked &&
            !this.state.screenChecked &&
            this.state.playerConnection === 'Tous' &&
            this.state.playerModel === 'Tous' &&
            this.state.maintenance === 'Tous'
        ) {
            return true;
        }

        const filter = this.state.filter;

        if (!d.Shop.address) {
            d.Shop.address = 'Non renseignée';
        }

        if (this.state.playerConnection === 'Wifi' && !d.isWifi) {
            return false;
        }

        if (this.state.playerConnection === '4G' && (d.isLan || d.isWifi)) {
            return false;
        }

        if (this.state.playerConnection === 'Ethernet' && !d.isLan) {
            return false;
        }

        if (this.state.playerModel !== 'Tous' && this.state.playerModel !== d.model) {
            return false;
        }

        if (this.state.maintenance === 'Maintenance' && !d.isDisabled) {
            return false;
        }

        if (this.state.maintenance === 'Running' && d.isDisabled) {
            return false;
        }

        if (!d.Group || !d.Shop || !d.id) {
            return false;
        }

        const seqAd = this.state.userSequences.find((s) => s.devices.indexOf(d.id) !== -1 && s.type === 1);
        let matchCond = d.id.toString().toUpperCase().indexOf(filter.toUpperCase()) !== -1;

        matchCond |= d.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1;
        matchCond |= (d.Group.name || '').toUpperCase().indexOf(filter.toUpperCase()) !== -1;
        matchCond |= (d.Shop.Sign.name || '').toUpperCase().indexOf(filter.toUpperCase()) !== -1;
        matchCond |= (d.Shop.name || '').toUpperCase().indexOf(filter.toUpperCase()) !== -1;
        matchCond |= (d.Shop.address || '').toUpperCase().indexOf(filter.toUpperCase()) !== -1;
        matchCond |= (d.Shop.city || '').toUpperCase().indexOf(filter.toUpperCase()) !== -1;
        matchCond |= d.Shop.zip.toUpperCase().indexOf(filter.toUpperCase()) !== -1;
        matchCond |= seqAd && seqAd.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1;

        if (this.state.playerChecked) {
            if (this.state.playerStatusSelected === 'ON') {
                const isInactive =
                    d.status.status === 'Running' &&
                    (new Date(this.state.lastRefresh) - new Date(d.status.lastPing)) / 1000 <= 120;
                matchCond &= isInactive;
            }
            if (this.state.playerStatusSelected === 'OFF') {
                const isInactive =
                    d.status.status !== 'Running' ||
                    (new Date(this.state.lastRefresh) - new Date(d.status.lastPing)) / 1000 >= 120;
                matchCond &= isInactive;
            }
        }

        if (this.state.screenChecked) {
            matchCond &= this.state.screenStatusSelected === d.status.screenStatus;
        }

        return matchCond;
    }

    render() {
        let devicesEnabled = this.state.devices.filter(({ isDisabled }) => !isDisabled);
        let devices = this.state.devices;

        if (this.props.assistant) {
            devices = filterByLastCheck(devices, this.state.lastSuivi, this.state.stats);
        }

        const activeDevices = devicesEnabled.filter((d) => {
            return (new Date(this.state.lastRefresh) - new Date(d.status.lastPing)) / 1000 <= 120;
        });

        return (
            <Panel
                style={{
                    overflowX: 'scroll',
                    WebkitOverflowScrolling: 'touch',
                    padding: this.props.assistant ? '5px' : '25px'
                }}
            >
                <div style={{ display: this.props.assistant ? 'none' : '' }}>
                    <div className="movescreen">
                        <span>
                            {devicesEnabled.length} Écrans / {getShopsNumberFromDevices(devicesEnabled)} Mags -{' '}
                            {activeDevices.length} Écrans actifs (
                            {Math.round((10000 * activeDevices.length) / devicesEnabled.length) / 100}%) /{' '}
                            {getShopsNumberFromDevices(activeDevices)} Mags
                        </span>
                        <div className="ecran_selected">
                            <span>
                                ({this.state.selectedScreens.length} Écrans Sélectionnés) /{' '}
                                {this.state.devices.length - devicesEnabled.length} Écrans en maintenance
                            </span>
                        </div>
                    </div>
                    <div className="removehr">
                        <hr />
                    </div>
                    <div className="pull-right margfilter">
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.filter}
                            onChange={(e) => this.setState({ filter: e.target.value })}
                            placeholder="Filtre"
                        />
                    </div>
                </div>

                <div style={{ display: this.props.assistant ? '' : 'none' }}>
                    <div style={{ textAlign: 'center' }}>
                        {this.state.devices.length} Ecrans -{' '}
                        {
                            this.state.devices.filter((d) => {
                                return (new Date(this.state.lastRefresh) - new Date(d.status.lastPing)) / 1000 <= 30;
                            }).length
                        }{' '}
                        actifs
                    </div>
                    <div className="flex-screens">
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.filter}
                                onChange={(e) => this.setState({ filter: e.target.value })}
                                placeholder="Filtre"
                            />
                        </div>
                        <div className="groupBox">
                            <label>Date dernier suivi</label>
                            <div className="mapBtnsFilterSuivi">
                                <span>
                                    <div
                                        className={'buttonSuivi' + (this.state.lastSuivi === 3 ? ' active' : '')}
                                        onClick={() => this.setState({ lastSuivi: 3 })}
                                        style={{ backgroundColor: '#E73323' }}
                                    ></div>{' '}
                                    {'>'} 2M
                                </span>
                                <span>
                                    <div
                                        className={'buttonSuivi' + (this.state.lastSuivi === 1 ? ' active' : '')}
                                        onClick={() => this.setState({ lastSuivi: 1 })}
                                        style={{ backgroundColor: '#F4C043' }}
                                    ></div>{' '}
                                    {'<='} 2M
                                </span>
                                <span>
                                    <div
                                        className={'buttonSuivi' + (this.state.lastSuivi === 2 ? ' active' : '')}
                                        onClick={() => this.setState({ lastSuivi: 2 })}
                                        style={{ backgroundColor: '#A0CD62' }}
                                    ></div>{' '}
                                    {'<='} 1M
                                </span>
                                <span>
                                    <div
                                        className={'buttonSuivi' + (this.state.lastSuivi === 0 ? ' active' : '')}
                                        onClick={() => this.setState({ lastSuivi: 0 })}
                                    ></div>{' '}
                                    TOUS
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pull-left" style={{ display: 'flex', paddingTop: '10px', width: 'fit-content' }}>
                    <div style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }} className="v-center">
                        <div style={{ width: '120px' }}>
                            <Select
                                onChange={(e) =>
                                    this.setState({
                                        maintenance: e.value
                                    })
                                }
                                options={[
                                    { value: 'Tous', label: 'Tous' },
                                    { value: 'Maintenance', label: 'Maintenance' },
                                    { value: 'Running', label: 'Running' }
                                ]}
                                placeholder="Maintenance"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }} className="v-center">
                        <div style={{ width: '120px' }}>
                            <Select
                                onChange={(e) =>
                                    this.setState({
                                        playerModel: e.value
                                    })
                                }
                                options={getPlayerModelOptions(this.state.devices)}
                                placeholder="Model"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }} className="v-center">
                        <div style={{ width: '120px' }}>
                            <Select
                                onChange={(e) =>
                                    this.setState({
                                        playerConnection: e.value
                                    })
                                }
                                options={[
                                    { value: 'Tous', label: 'Tous' },
                                    { value: '4G', label: '4G' },
                                    { value: 'Ethernet', label: 'Ethernet' },
                                    { value: 'Wifi', label: 'Wifi' }
                                ]}
                                placeholder="Connexion"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }} className="v-center">
                        <div style={{ width: '120px' }}>
                            <Select
                                onChange={(e) =>
                                    this.setState({
                                        playerStatusSelected: e.value,
                                        playerChecked: e.value === 'Statut' ? false : true
                                    })
                                }
                                options={[
                                    { value: 'Statut', label: 'Tous' },
                                    { value: 'ON', label: 'Running' },
                                    { value: 'OFF', label: 'Off' }
                                ]}
                                placeholder="Statut"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }} className="v-center">
                        <div style={{ width: '120px' }}>
                            <Select
                                onChange={(e) =>
                                    this.setState({
                                        screenStatusSelected: e.value,
                                        screenChecked: e.value !== 'Ecrans'
                                    })
                                }
                                options={[
                                    { value: 'Ecrans', label: 'Tous' },
                                    { value: 'ON', label: 'ON' },
                                    { value: 'OFF', label: 'OFF' },
                                    { value: 'VEILLE', label: 'VEILLE' }
                                ]}
                                placeholder="Ecrans"
                            />
                        </div>
                    </div>
                </div>

                <br />

                <div className="selectmultip">
                    <select value={this.state.value} onChange={this.handleChange} ref="command">
                        <option value="---">Avec sélection</option>
                        <option value="reboot">Reboot</option>
                        <option value="screenshot">Screenshot</option>
                        <option value="firmwareupdate">FirmwareUpdate</option>
                        <option value="report">Envoi du rapport</option>
                        <option value="refresh">Refresh</option>
                        <option value="restartApp">Restart App</option>
                        <option value="changeOrientation">Changer l'orientation</option>
                        <option value="disableScreen">Désactiver les écrans (maintenance)</option>
                        <option value="enableScreen">Réactiver les écrans (maintenance)</option>
                    </select>
                    <button className={`btn btn-default adjustcommand`} onClick={this.handleSendCommand}>
                        Go
                    </button>
                    <button
                        className="btn btn-default refreshScreens"
                        onClick={() => this.getData(this.state.mongoStore)}
                    >
                        <i className="fa fa-sync-alt"></i>
                    </button>
                    {!this.props.monitorMode || (
                        <button
                            className="btn btn-default refreshScreens"
                            onClick={() => downloadAsFile(devices.filter(this.filterdevices), this.state.stats)}
                        >
                            <i className="fa fa-file-download"></i>
                        </button>
                    )}
                </div>

                <Table striped bordered condensed hover className="vcenterTable">
                    <thead>
                        <tr>
                            <th className="selectcheckb">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            const allscreens = this.state.devices
                                                .filter(this.filterdevices)
                                                .map((i) => i.id);

                                            this.setState({ selectedScreens: allscreens });
                                        } else {
                                            this.setState({ selectedScreens: [] });
                                        }
                                    }}
                                    checked={
                                        this.state.devices.length !== 0 &&
                                        this.state.devices.filter(this.filterdevices).length ===
                                            this.state.selectedScreens.length
                                    }
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'id',
                                        this.state.sortArgs[0] === 'id' ? !this.state.sortArgs[1] : true,
                                        false
                                    )
                                }
                            >
                                id
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'id' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer', display: this.props.assistant ? '' : 'none' }}
                                onClick={() =>
                                    this.sortTable(
                                        'status',
                                        this.state.sortArgs[0] === 'status' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Player
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'status' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'name',
                                        this.state.sortArgs[0] === 'name' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Nom
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'name' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{
                                    cursor: 'pointer',
                                    display:
                                        this.props.assistant || (this.props.user && this.props.user.role.id === 1218)
                                            ? 'none'
                                            : ''
                                }}
                                onClick={() =>
                                    this.sortTable(
                                        'Group',
                                        this.state.sortArgs[0] === 'Group' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Groupe
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'Group' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'Sign',
                                        this.state.sortArgs[0] === 'Sign' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Enseigne
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'Sign' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'Shop',
                                        this.state.sortArgs[0] === 'Shop' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Magasin
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'Shop' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th style={{ display: this.props.monitorMode ? '' : 'none' }}>Adresse</th>
                            <th style={{ display: this.props.monitorMode ? '' : 'none' }}>Tel</th>
                            <th style={{ display: this.props.monitorMode ? '' : 'none' }}>Email</th>
                            <th style={{ display: this.props.monitorMode ? '' : 'none' }}>Contact</th>
                            <th
                                style={{
                                    cursor: 'pointer',
                                    display: this.props.assistant || this.props.monitorMode ? '' : 'none'
                                }}
                                onClick={() =>
                                    this.sortTable(
                                        'suivi',
                                        this.state.sortArgs[0] === 'suivi' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Dernier suivi
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'suivi' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{
                                    cursor: 'pointer',
                                    display: this.props.assistant || this.props.monitorMode ? 'none' : ''
                                }}
                                onClick={() =>
                                    this.sortTable(
                                        'pub',
                                        this.state.sortArgs[0] === 'pub' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                # Camp.
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'pub' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{
                                    cursor: 'pointer',
                                    display: this.props.assistant || this.props.monitorMode ? 'none' : ''
                                }}
                                onClick={() =>
                                    this.sortTable(
                                        'slots',
                                        this.state.sortArgs[0] === 'slots' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                # Slots
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'slots' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{
                                    cursor: 'pointer',
                                    display: this.props.assistant || this.props.monitorMode ? 'none' : ''
                                }}
                                onClick={() =>
                                    this.sortTable(
                                        'commercant',
                                        this.state.sortArgs[0] === 'commercant' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Seq. com.
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'commercant' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer', display: this.props.assistant ? 'none' : '' }}
                                onClick={() =>
                                    this.sortTable(
                                        'status',
                                        this.state.sortArgs[0] === 'status' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                App.
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'status' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer', display: this.props.assistant ? 'none' : '' }}
                                onClick={() =>
                                    this.sortTable(
                                        'conn',
                                        this.state.sortArgs[0] === 'conn' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Conn.
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'conn' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'screenStatus',
                                        this.state.sortArgs[0] === 'screenStatus' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Ecran
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'screenStatus' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'statusPing',
                                        this.state.sortArgs[0] === 'statusPing' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Dernier ping
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'statusPing' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th>Horaires</th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'version',
                                        this.state.sortArgs[0] === 'version' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Version
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'version' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th>T</th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'programmaticOnly',
                                        this.state.sortArgs[0] === 'programmaticOnly' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Prog only
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display:
                                            this.state.sortArgs[0] === 'programmaticOnly' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'orientation',
                                        this.state.sortArgs[0] === 'orientation' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Typ.
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'orientation' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.sortTable(
                                        'screen',
                                        this.state.sortArgs[0] === 'screen' ? !this.state.sortArgs[1] : true
                                    )
                                }
                            >
                                Dernier screenshot
                                <i
                                    className={`fas fa-sort-${this.state.sortArgs[1] ? 'down' : 'up'}`}
                                    style={{
                                        display: this.state.sortArgs[0] === 'screen' ? 'inline-block' : 'none',
                                        marginLeft: '5px'
                                    }}
                                />
                            </th>
                            <th>Screenshot</th>
                            <th>Gestion</th>
                            <th style={{ display: this.props.assistant ? 'none' : '' }}>Login as</th>
                            <th style={{ display: this.props.monitorMode ? 'none' : '' }}>Destinations</th>
                            <th>Opérations</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devices.filter(this.filterdevices).map((dev) => {
                            return (
                                <tr key={dev.id} style={dev.isDisabled ? { textDecoration: 'line-through' } : {}}>
                                    <td>
                                        <label key={dev.id} className="labelselect">
                                            <Checkbox
                                                name={dev.name}
                                                checked={this.state.selectedScreens.indexOf(dev.id) !== -1}
                                                onChange={this.handleChangeCheckbox}
                                                value={`${dev.id}`}
                                                className="checkofcamp"
                                            />
                                        </label>
                                    </td>
                                    <td>{dev.id}</td>
                                    <td style={{ display: this.props.assistant ? '' : 'none' }}>
                                        {this.getDeviceStatus(dev)}
                                    </td>
                                    <td>{dev.name} </td>
                                    <td
                                        style={{
                                            display:
                                                this.props.assistant ||
                                                (this.props.user && this.props.user.role.id === 1218)
                                                    ? 'none'
                                                    : ''
                                        }}
                                    >
                                        {(dev.Group && dev.Group.name) || 'Renseigner groupe'}
                                    </td>
                                    <td>{(dev.Shop.Sign && dev.Shop.Sign.name) || 'Renseigner enseigne'}</td>
                                    <td>{dev.Shop.name}</td>
                                    <td
                                        style={{
                                            display: this.props.assistant ? '' : 'none'
                                        }}
                                    >
                                        {this.props.assistant ? getLastSuivi(dev.Shop.id, this.state.stats, true) : ''}
                                    </td>
                                    <td style={{ display: this.props.monitorMode ? '' : 'none' }}>
                                        {dev.Shop.address} {dev.Shop.zip} {dev.Shop.city}
                                    </td>
                                    <td style={{ display: this.props.monitorMode ? '' : 'none' }}>{dev.Shop.tel}</td>
                                    <td style={{ display: this.props.monitorMode ? '' : 'none' }}>{dev.Shop.mail}</td>
                                    <td style={{ display: this.props.monitorMode ? '' : 'none' }}>
                                        {dev.Shop.contact}
                                    </td>
                                    <td
                                        style={{
                                            display: this.props.monitorMode ? '' : 'none'
                                        }}
                                    >
                                        {this.props.monitorMode
                                            ? getLastSuivi(dev.Shop.id, this.state.stats, true)
                                            : ''}
                                    </td>
                                    <td
                                        style={{
                                            display: this.props.assistant || this.props.monitorMode ? 'none' : ''
                                        }}
                                    >
                                        {
                                            <CampaignNumbers
                                                campaigns={this.state.campaigns.filter(
                                                    (s) =>
                                                        s.devices.indexOf(dev.id) !== -1 &&
                                                        new Date() < new Date(s.endDate) &&
                                                        new Date() > new Date(s.startDate) &&
                                                        s.active === true &&
                                                        !s.triggered
                                                )}
                                            />
                                        }
                                    </td>
                                    <td
                                        style={{
                                            display: this.props.assistant || this.props.monitorMode ? 'none' : ''
                                        }}
                                    >
                                        <CampaignSlots
                                            campaigns={this.state.campaigns.filter(
                                                (s) =>
                                                    s.devices.indexOf(dev.id) !== -1 &&
                                                    new Date() < new Date(s.endDate) &&
                                                    new Date() > new Date(s.startDate) &&
                                                    s.active === true
                                            )}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            display: this.props.assistant || this.props.monitorMode ? 'none' : ''
                                        }}
                                    >
                                        {this.getSequenceFromDevice(dev.id)}
                                    </td>
                                    <td style={{ display: this.props.assistant ? 'none' : '' }}>
                                        {this.getDeviceStatus(dev)}
                                    </td>
                                    <td style={{ display: this.props.assistant ? 'none' : '' }}>
                                        {this.getDeviceConnection(dev)}
                                    </td>
                                    <td>{dev.status.screenStatus}</td>
                                    <td>
                                        {(!dev.status && 'Inconnu') || dateFormat(dev.status.lastPing, 'dd/mm - HH:MM')}
                                    </td>
                                    <td>
                                        {(() => {
                                            let newDate = new Date();
                                            let date = newDate.getDay();
                                            const obj = JSON.parse(dev.onOffTimes);

                                            if (dev.autoOnOff) {
                                                if (date === 1) {
                                                    return `${obj[0][0]} - ${obj[0][1]}`;
                                                } else if (date === 2) {
                                                    return `${obj[1][0]} - ${obj[1][1]}`;
                                                } else if (date === 3) {
                                                    return `${obj[2][0]} - ${obj[2][1]}`;
                                                } else if (date === 4) {
                                                    return `${obj[3][0]} - ${obj[3][1]}`;
                                                } else if (date === 5) {
                                                    return `${obj[4][0]} - ${obj[4][1]}`;
                                                } else if (date === 6) {
                                                    return `${obj[5][0]} - ${obj[5][1]}`;
                                                } else if (date === 0) {
                                                    return `${obj[6][0]} - ${obj[6][1]}`;
                                                }
                                            }
                                            return 'Manuel';
                                        })()}
                                    </td>
                                    <td>{dev.version}</td>
                                    <td>
                                        <i className={`fa fa-${getConnexionIcon(dev.isLan, dev.isWifi)}`}></i>
                                    </td>
                                    <td>{dev.programmaticOnly ? 'Oui' : 'Non'}</td>
                                    <td>
                                        {dev.model.indexOf('Raspberry') === -1 ? (
                                            <i className="fa fa-microchip"></i>
                                        ) : (
                                            'RPi'
                                        )}
                                    </td>
                                    <td>{dev.screen && dateFormat(dev.screen, 'dd/mm - HH:MM')}</td>
                                    <td>
                                        <Screenshot dev={dev} />
                                    </td>
                                    <td>
                                        <Link
                                            onClick={() => localStorage.setItem('filter', this.state.filter)}
                                            to={`/screens/${dev.id}`}
                                            className="btn btn-info"
                                        >
                                            Gestion
                                        </Link>
                                    </td>
                                    <td style={{ display: this.props.assistant ? 'none' : '' }}>
                                        <button
                                            className="btn btn-default ml-1 btn_desa_clone"
                                            onClick={() => {
                                                this.Auth.fetch(
                                                    `${constants.endpoint}/admin/loginAs/${dev.Shop.id}`
                                                ).then((res) => {
                                                    if (res.success === false) {
                                                        return window.alert('Erreur');
                                                    }

                                                    localStorage.setItem('filter', this.state.filter);
                                                    this.Auth.tempSession(res.token, 'admin');

                                                    window.location = '/';
                                                });
                                            }}
                                        >
                                            Login
                                        </button>
                                    </td>
                                    <td style={{ display: this.props.monitorMode ? 'none' : '' }}>
                                        <a
                                            href={`https://waze.com/ul?q=${dev.Shop.address}%20${dev.Shop.zip}%20${dev.Shop.city}&navigate=yes`}
                                            className="btn btn-info"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Trajet
                                        </a>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-info"
                                            style={{ display: this.props.monitorMode ? '' : 'none' }}
                                            onClick={() => {
                                                const title = prompt('Suivi rapide : Titre');
                                                const message = prompt('Suivi rapide : Message');

                                                swal({
                                                    title: 'Confirmer ajout de suivi rapide',
                                                    icon: 'info',
                                                    html: true,
                                                    text: `Le suivi va être ajouté:\nTitre: ${title}\nMessage: ${message}`,
                                                    buttons: ['Annuler', 'OK']
                                                }).then((conf) => {
                                                    if (conf) {
                                                        fetchApi(`/admin/history/${dev.Shop.id}`, {
                                                            method: 'PUT',
                                                            body: JSON.stringify({
                                                                title,
                                                                message,
                                                                author: `${this.props.user.role.first_name} ${this.props.user.role.last_name}`
                                                            })
                                                        }).then(() => swal({ icon: 'success', text: 'Entrée créée' }));
                                                    }
                                                });
                                            }}
                                        >
                                            Ajouter suivi
                                        </button>
                                        <button
                                            className="btn btn-danger ml-1 btn_desa_clone"
                                            onClick={() => {
                                                swal({
                                                    text: `Êtes-vous sur de vouloir Reboot cette Ecran ?`,
                                                    title: 'La commande ne pourra pas être annulée',
                                                    icon: 'warning',
                                                    buttons: ['Annuler', 'Supprimer'],
                                                    dangerMode: true
                                                }).then((willDelete) => {
                                                    if (willDelete) {
                                                        this.Auth.fetch(
                                                            `${constants.endpoint}/admin/command/${dev.id}`,
                                                            {
                                                                method: 'PUT',
                                                                body: JSON.stringify({
                                                                    command: 'reboot'
                                                                })
                                                            }
                                                        );
                                                        swal(`Ecran Reboot avec succès`, {
                                                            icon: 'success'
                                                        });
                                                    } else {
                                                        swal('Commande annulée');
                                                    }
                                                });
                                            }}
                                        >
                                            Reboot
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Panel>
        );
    }
}

export default Screens;
