import React, { useState } from 'react';
import { useEffect } from 'react';
import { fetchApi } from '../../Services/NetworkServcice';
import xlsxPopulate from 'xlsx-populate';

const Irina = (props) => {
    const [state, setState] = useState({
        shops: [],
        ready: false
    });

    useEffect(() => {
        if (props.user.role.Agent.id !== 1) {
            return;
        }

        fetchApi('/admin/shops/all-agents')
            .then((res) => res.json())
            .then(({ shops, screens }) => {
                const shop_screens = {};
                for (const s of screens) {
                    if (!(s.ShopId in shop_screens)) {
                        shop_screens[s.ShopId] = [];
                    }

                    shop_screens[s.ShopId].push(s);
                }

                for (const s of shops) {
                    s.screens = shop_screens[s.id] || [];
                }

                setState((prevState) => ({
                    ...prevState,
                    shops,
                    ready: true
                }));
            });
    }, [props.user.role.Agent.id]);

    const downloadExtract = () => {
        const list = [];

        for (const s of state.shops) {
            const data = {
                ID: s.id,
                NOM: s.name,
                ADDRESS: s.address,
                CONTACT: s.contact,
                TEL: s.tel,
                MAIL: s.mail,
                ZIP: s.zip,
                CITY: s.city,
                MULTIPROXY: s.isMulti,
                GROUP: s.Group?.name,
                SIGN: s.Sign?.name,
                AGENT: s.AgentId,
                NB_ECRANS: s.screens.length
            };

            list.push(data);
        }

        if (list.length === 0) {
            return;
        }

        xlsxPopulate
            .fromBlankAsync()
            .then((w) => {
                for (let i = 0; i < Object.keys(list[0]).length; i++) {
                    w.sheet(0)
                        .row(1)
                        .cell(i + 1)
                        .value(Object.keys(list[0])[i]);
                }

                for (let i = 0; i < list.length; i++) {
                    for (let k = 0; k < Object.keys(list[0]).length; k++) {
                        w.sheet(0)
                            .row(i + 2)
                            .cell(k + 1)
                            .value(list[i][Object.keys(list[i])[k]]);
                    }
                }

                return w.outputAsync();
            })
            .then((blob) => window.download(blob, 'Shops.xlsx'));
    };

    function aish() {
        fetchApi('/campaigns/aish').then(res => res.blob()).then(blob => window.download(blob, 'aish.csv'));
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <h4>Menu d'Irina</h4>
            </div>
            <div className="col-md-4">
                <div className="panel">
                    <span>Extract de magasins: {state.shops.length} Magasins</span>
                    <div>
                        <button className="btn btn-info mt-16" disabled={!state.ready} onClick={downloadExtract}>
                            Télécharger extract
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel">
                    <span>Extract aisha</span>
                    <div>
                        <button className="btn btn-info mt-16" disabled={!state.ready} onClick={aish}>
                            Télécharger extract
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Irina;
