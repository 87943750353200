import React from 'react';
import { Panel } from 'react-bootstrap';
import constants from '../../Constants';
import Select from 'react-select';
import AuthService from '../../Components/Auth/AuthService';

class Selector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shops: [],
            screens: [],
            label: '',
            value: ''
        };

        this.loginAs = this.loginAs.bind(this);

        this.Auth = new AuthService();

        this.getToken = this.getToken.bind(this);
    }

    getToken() {
        if (this.Auth.hasTempSession() && localStorage.getItem('old_role') === 'manager') {
            return localStorage.getItem('old_token');
        }

        if (this.Auth.hasTempSession() && localStorage.getItem('old_role') === 'demo') {
            return localStorage.getItem('old_token');
        }

        return this.Auth.getToken();
    }

    loginAs(setupUser) {
        if (!this.state.value) {
            return;
        }

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };

        headers['x-access-token'] = this.getToken();

        fetch(`${constants.endpoint}/manager/loginAs${this.props.isDemo ? '/demo' : ''}/${this.state.value}`, {
            headers
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.success === false) {
                    return window.alert('Erreur');
                }

                if (!this.Auth.hasTempSession()) {
                    this.Auth.tempSession(res.token, this.props.isDemo ? 'demo' : 'manager');
                } else {
                    localStorage.setItem('id_token', res.token);
                }

                setupUser();
            });
    }

    render() {
        if (!this.state.shops) {
            return null;
        }

        return (
            <div className="groupSelectShop">
                <Select
                    options={this.state.shops
                        .filter((s) => {
                            if (!this.props.isDemo) {
                                return true;
                            }

                            if (this.props.isDemo || this.state.screens.findIndex(({ ShopId }) => ShopId === s.id) !== -1) {
                                return true;
                            }

                            return false;
                        })
                        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                        .map((s) => ({
                            value: s.id,
                            label: s.name
                        }))}
                    onChange={(e) => {
                        this.setState(e);
                    }}
                    value={{
                        label: this.state.label || this.props.shopName,
                        value: this.state.value || this.props.shopName
                    }}
                />
                <button
                    disabled={this.state.value === null}
                    className="btn btn-info"
                    onClick={() => this.loginAs(this.props.setupUser)}
                    style={{ marginTop: '10px' }}
                >
                    Se Connecter
                </button>
            </div>
        );
    }

    componentDidMount() {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };

        headers['x-access-token'] = this.getToken();

        fetch(`${constants.endpoint}/manager${this.props.isDemo ? '/demo' : ''}`, {
            headers
        })
            .then((response) => response.json())
            .then((res) => {
                this.setState({ shops: res.shops });
            });

        if (!this.props.isDemo) {
            fetch(`${constants.endpoint}/manager/screens`, {
                headers
            })
                .then((response) => response.json())
                .then((res) => {
                    this.setState({ screens: res });
                });
        }
    }
}

export class Dashboard extends React.Component {
    render() {
        return (
            <Panel>
                <h3>Gestion des magasins du groupe</h3>
                <p>
                    Selectionnez un magasin dans la sidebar puis cliquer sur <code>Se connecter</code> pour vous
                    connecter.
                </p>
            </Panel>
        );
    }
}

export default Selector;
