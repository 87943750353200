import React from 'react';
import { countries } from '../../Constants';
import Select from 'react-select';
import { Col } from 'react-bootstrap';

const ButcheryAttributes = (props) => {
    let availableLabels = [
        'ab.jpg',
        'AOP.png',
        'label_Rouge.png',
        'logo-IGP.png',
        'ploc.png',
        'upc.png',
        'Logo-Halal.png',
        'viande-ovine-fr.jpeg'
    ];
    const frenchLabels = [
        'lapin de france.png',
        'viande bovine.png',
        'viande de porc.png',
        'viande de veau.png',
        'vdf.png'
    ];

    if (props.originCountry === 'France') {
        availableLabels = [...availableLabels, ...frenchLabels];
    }

    if (props.signId === 1 || props.signId === 37) {
        // Intermarche
        availableLabels.push('producteur-commercant.png');
    }

    return (
        <div>
            <div className="form-group">
                <label>Type d'origine</label>
                <select className="form-control" name="originType" onChange={props.handleChange}>
                    <option value="Origine">Pays d'origine</option>
                    <option value="Transformé en">Pays de transformation (transformé en)</option>
                    <option value="Transformé au">Pays de transformation (transformé au)</option>
                    <option value="Transformé aux">Pays de transformation (transformé aux)</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="originCountry">{props.originType} (obligatoire)</label>
                <Select
                    options={[
                        ...[
                            {
                                value: 'France',
                                label: 'France'
                            },
                            {
                                value: 'Espagne',
                                label: 'Espagne'
                            },
                            {
                                value: 'Argentine',
                                label: 'Argentine'
                            },
                            {
                                value: 'Bresil',
                                label: 'Bresil'
                            },
                            {
                                value: 'Ecosse',
                                label: 'Ecosse'
                            },
                            {
                                value: 'Irlande',
                                label: 'Irlande'
                            },
                            {
                                value: 'Uruguay',
                                label: 'Uruguay'
                            },
                            {
                                value: 'Europe',
                                label: 'Europe'
                            },
                            {
                                value: '---------------------',
                                label: '---------------------',
                                disabled: true
                            }
                        ],
                        ...countries
                    ]}
                    name="originCountry"
                    className={props.originCountry ? '' : 'rSelectError'}
                    onChange={(e) =>
                        props.handleChange({
                            target: {
                                name: 'originCountry',
                                value: e.value
                            }
                        })
                    }
                    isOptionDisabled={(o) => o.disabled === true}
                    value={{
                        label: props.originCountry,
                        value: props.originCountry
                    }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="label">Label</label>
                <div className="LabelsHorizontalScroll">
                    <Col
                        lg={2}
                        md={3}
                        xs={4}
                        className={'labelCol' + (props.labels.length === 0 ? ' active' : '')}
                        onClick={() => props.handleSetLabels([])}
                    >
                        <div className="noLabel">
                            <i className="fa fa-ban"></i>
                            <span>Aucun</span>
                        </div>
                    </Col>
                    {availableLabels.map((l) => (
                        <Col
                            lg={2}
                            md={3}
                            xs={4}
                            key={l}
                            onClick={() => {
                                if (props.labels.indexOf(l) === -1) {
                                    props.handleSetLabels([...props.labels, l].slice(0, 2));
                                } else {
                                    props.handleSetLabels(props.labels.filter((lb) => lb !== l));
                                }
                            }}
                            className={'labelCol' + (props.labels.indexOf(l) !== -1 ? ' active' : '')}
                        >
                            <img className="labelImg" src={`/labels/${l}`} alt={l} />
                        </Col>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ButcheryAttributes;
