import React from 'react';
import { fetchApi } from '../Services/NetworkServcice';
import { Col } from 'react-bootstrap';
import Slide from '../Components/Editor/Slide';
import Select from 'react-select';
import '../Assets/editor.css';

class ShopInfoReadOnly extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shops: {},
            selectedShop: -1,
            tags: [],
            suggestions: [],
            mydev: [],
            myshops: [],
            devicesname:''
        };
    }

    componentDidMount() {

        fetchApi('/manager/screens')
            .then(res => res.json())
            .then(devices => {
                const shopsMap = {};
                for (const device of devices) {
                    if (!shopsMap[device['Shop.id']]) {
                        shopsMap[device['Shop.id']] = [device];
                    } else {
                        shopsMap[device['Shop.id']].push(device);
                    }
                }


                this.setState({ shops: shopsMap,  mydev: devices });

                const suggestions = [];

                const shops = Object.values(shopsMap);
                shops.forEach(d => {
                    d = d[0];
                    const entries = [
                        {
                            name: `${d['Shop.name']} / ${d['Shop.Sign.name']} / ${d['Group.name']} / ${d['Shop.address']} / ${d['Shop.zip']} ${d['Shop.city']}`,
                            id: d['Shop.id']
                        }
                    ];

                    entries.forEach(e => {
                        if (!suggestions.find(s => s.type === e.type && s.id === e.id)) {
                            if (e.id) {
                                suggestions.push(e);
                            }
                        }
                    });
                });

                this.setState({ suggestions });
                this.setState({ myshops: shops });
                this.setState({ devicesname: devices[0]['Shop.Sign.name'] });
            });
    }

    render() {
        return (
            <div>
                <h4>{this.state.myshops.length} lieu: {this.state.devicesname} - {this.state.mydev.length} écrans</h4>
                <Select
                    options={this.state.suggestions.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(s => ({
                        label: s.name,
                        value: s.id
                    }))}
                    onChange={e => this.setState({ selectedShop: e.value })}
                    defaultValue={{
                        label: '---',
                        value: -1
                    }}
                />

                {this.state.selectedShop === -1 || (
                    <div className="full_info">
                        <div className="row full_info">
                            <div className="col-md-3">
                                <p className="square">Infos</p>
                            </div>
                            <div className="col-md-9">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Nom</td>
                                            <td>{this.state.shops[this.state.selectedShop][0]['Shop.name']}</td>
                                        </tr>
                                        <tr>
                                            <td>Groupe</td>
                                            <td>{this.state.shops[this.state.selectedShop][0]['Shop.Sign.name']}</td>
                                        </tr>
                                        <tr>
                                            <td>Adresse</td>
                                            <td>{this.state.shops[this.state.selectedShop][0]['Shop.address']}</td>
                                        </tr>
                                        <tr>
                                            <td>Zip</td>
                                            <td>{this.state.shops[this.state.selectedShop][0]['Shop.zip']}</td>
                                        </tr>
                                        <tr>
                                            <td>Ville</td>
                                            <td>{this.state.shops[this.state.selectedShop][0]['Shop.city']}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            {this.state.shops[this.state.selectedShop].map(screen => (
                                <div className="row" key={screen.id}>
                                    <hr className="hrmarg"/>
                                    <div className="col-md-3">
                                        <p className="square">{screen.name}</p>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="row">
                                            {!screen.userSequence ||
                                                screen.userSequence.creatives
                                                    .filter(({ expirationDate }) => {
                                                        if (expirationDate) {
                                                            return new Date() <= new Date(expirationDate);
                                                        }

                                                        return true;
                                                    })
                                                    .map(c => (
                                                        <Col key={c._id} md={4}>
                                                            <Slide
                                                                {...c}
                                                                creative={c.crea}
                                                                deleteButton={false}
                                                                orientation={screen.userSequence.orientation}
                                                                smallAdm={true}
                                                                assistant={this.props.assistant}
                                                            />
                                                        </Col>
                                                    ))}
                                            {!screen.subsequence ||
                                                screen.subsequence.creatives
                                                    .filter(({ expirationDate }) => {
                                                        if (expirationDate) {
                                                            return new Date() <= new Date(expirationDate);
                                                        }

                                                        return true;
                                                    })
                                                    .map(c => (
                                                        <Col key={c._id} md={4}>
                                                            <Slide
                                                                {...c}
                                                                red={'Groupe'}
                                                                creative={c.crea}
                                                                deleteButton={false}
                                                                orientation={screen.subsequence.orientation}
                                                                smallAdm={true}
                                                                assistant={this.props.assistant}
                                                            />
                                                        </Col>
                                                    ))}
                                            {!screen.signSubsequence ||
                                                screen.signSubsequence.creatives
                                                    .filter(({ expirationDate }) => {
                                                        if (expirationDate) {
                                                            return new Date() <= new Date(expirationDate);
                                                        }

                                                        return true;
                                                    })
                                                    .map(c => (
                                                        <Col key={c._id} md={4}>
                                                            <Slide
                                                                {...c}
                                                                red={'Enseigne'}
                                                                creative={c.crea}
                                                                deleteButton={false}
                                                                orientation={screen.signSubsequence.orientation}
                                                                smallAdm={true}
                                                                assistant={this.props.assistant}
                                                            />
                                                        </Col>
                                                    ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ShopInfoReadOnly;
