import React, { useEffect, useState } from 'react';
import { fetchApi } from '../Services/NetworkServcice';
import dateFormat from 'dateformat';
import HeaderNotifications from '../Components/HeaderNotifications';
import { Link } from 'react-router-dom';

const CreativeBlock = (props) => {
    return (
        <div className="creaBlock card">
            <h4 className="visible-mob">
                <strong>{props.name}</strong>
                <p>
                    <i className="fa fa-clock"></i>Du {dateFormat(props.start, 'dd/mm/yy')} au{' '}
                    {dateFormat(props.end, 'dd/mm/yy')}
                </p>
            </h4>
            <div className="creaVid">
                <video autoPlay muted loop playsInline alt="Creative">
                    <source src={props.creatives[0].url} type="video/mp4"></source>
                </video>
            </div>
            <div className="content-data">
                <div className="msg">
                    <h4 className="no-mob">
                        <strong>{props.name}</strong>
                        <p>
                            <i className="fa fa-clock"></i>Du {dateFormat(props.start, 'dd/mm/yy')} au{' '}
                            {dateFormat(props.end, 'dd/mm/yy')}
                        </p>
                    </h4>
                </div>
                <div className="data">
                    {/* <div className="eans-table">
                        <div className="products">
                            <strong>Produit concerné</strong>
                            {props.eans.map((e) => (
                                <p key={e}>{e.split(';')[1]}</p>
                            ))}
                        </div>
                        <div className="eans">
                            <strong>EAN / Identifiant</strong>
                            {props.eans.map((e) => (
                                <p key={e}>{e.split(';')[0]}</p>
                            ))}
                        </div>
                    </div> */}
                    <div className="infos">
                        <strong>Informations additionnelles</strong>
                        <div>
                            {props.notes}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Home = (props) => {
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        fetchApi('/shop/activeCampaigns')
            .then((res) => res.json())
            .then(res => {
                if (res) setCampaigns(res);
            });
    }, []);

    return (
        <div className="dyl-home ads">
            <header>
                <Link to="/" className="logo">
                    <img src="/image.png" alt="logo" />
                </Link>
                <p className="hidden-xs">{dateFormat(new Date(), 'dddd dd mmmm yyyy')}</p>
                <h3>Marques en avant</h3>
                <HeaderNotifications {...props.notifsData} />
            </header>
            <div className="content">
                <div className="banner-camp">Actuellement</div>
                {campaigns
                    .filter(({ start, creatives }) => new Date(start) <= new Date() && creatives.length > 0)
                    .map((c) => (
                        <CreativeBlock key={c._id} {...c} />
                    ))}
                <div className="banner-camp">Prochainement</div>
                {campaigns
                    .filter(({ start, creatives }) => new Date(start) > new Date() && creatives.length > 0)
                    .map((c) => (
                        <CreativeBlock key={c._id} {...c} />
                    ))}
            </div>
        </div>
    );
};

export default Home;
