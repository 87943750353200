import React, { useEffect, useState } from 'react';
import constants, { formats } from '../Constants';
import { fetchApi } from '../Services/NetworkServcice';

export const DefaultAssets = (props) => {
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchApi(`/admin/assets/default/${props.sign.id}`)
            .then((res) => res.json())
            .then(setAssets);
    }, [props.sign.id]);

    return (
        <div>
            <p>Affiches par défaut:</p>
            {Object.keys(formats).map((f) => (
                <div key={f}>
                    <p>{formats[f].name}:</p>
                    {!assets.find(({ orientation }) => orientation === f) || (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${constants.cloud_storage}/Assets/${
                                assets.find(({ orientation }) => orientation === f).filename
                            }`}
                        >
                            <img
                                src={`${constants.cloud_storage}/Thumbs/${
                                    assets.find(({ orientation }) => orientation === f).filename
                                }.png`}
                                alt={`Asset ${formats[f].name}`}
                            />
                        </a>
                    )}
                    <input
                        disabled={loading}
                        accept="video/*"
                        type="file"
                        onChange={async (e) => {
                            setLoading(true);

                            const file = e.target.files[0];
                            if (!file) {
                                return setLoading(false);
                            }

                            const data = new FormData();
                            data.append('asset', file);

                            const url = await fetch(
                                `${constants.endpoint}/admin/assets/default/${props.sign.id}/${f}`,
                                {
                                    headers: {
                                        'x-access-token': localStorage.getItem('id_token'),
                                    },
                                    method: 'POST',
                                    body: data,
                                }
                            ).then(async (res) => {
                                if (res.status === 400) {
                                    const { message } = await res.json();
                                    throw message;
                                }

                                return res.json();
                            });

                            const newAssets = assets.filter(({ orientation }) => orientation !== f);

                            setAssets([...newAssets, { orientation: f, ...url }]);
                            setLoading(false);
                        }}
                    />
                </div>
            ))}
        </div>
    );
};
