import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, OverlayView, InfoWindow } from 'react-google-maps';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import constants, { getShopName } from '../../Constants';
import AuthService from '../../Components/Auth/AuthService';
import { compose, withProps, lifecycle } from 'recompose';
import ReactTags from 'react-tag-autocomplete';

import '../../Assets/map.css';

// Signs icons
import a2pas from '../../Assets/Signs/a2pas.png';
import carrefour from '../../Assets/Signs/carrefour.png';
import carrefourc from '../../Assets/Signs/ccontact.png';
import franprix from '../../Assets/Signs/franprix.png';
import g20 from '../../Assets/Signs/g20.png';
import inter from '../../Assets/Signs/inter.png';
import U from '../../Assets/Signs/U.png';
import cmarket from '../../Assets/Signs/cmarket.png';
import monop from '../../Assets/Signs/monop.png';
import coccinelle from '../../Assets/Signs/coccinelle.png';
import myauchan from '../../Assets/Signs/myauchan.png';
import omf from '../../Assets/Signs/omf.png';
import mac from '../../Assets/Signs/mac.png';
import diagonal from '../../Assets/Signs/diagonal.png';
import { fetchApi } from '../../Services/NetworkServcice';

const icons = {
    INTERMARCHE: inter,
    FRANPRIX: franprix,
    'CARREFOUR EXPRESS': carrefour,
    'CARREFOUR CONTACT': carrefourc,
    'CARREFOUR CITY': carrefour,
    'G 20': g20,
    G20: g20,
    'U EXPRESS': U,
    MONOP: monop,
    A2PAS: a2pas,
    'MY AUCHAN': myauchan,
    'INTERMARCHE EXPRESS': inter,
    'CARREFOUR MARKET': cmarket,
    DIAGONAL: diagonal,
    'O MARCHE FRAIS': omf,
    'COCCINELLE EXPRESS': coccinelle,
    UTILE: U,
    "LE MARCHE D'A COTE": mac
};

/**
 * Following is for other pages, reusable component
 */
export const ScreenMapComponent = withScriptjs(
    withGoogleMap((props) => {
        return (
            <GoogleMap
                defaultZoom={12}
                defaultCenter={{
                    lat: parseFloat(props.screen.lat),
                    lng: parseFloat(props.screen.long)
                }}
            >
                <ScreenMarker unique={true} screen={props.screen} mydev={props.mydev} />
            </GoogleMap>
        );
    })
);

export const SimpleMarkerOnMap = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap defaultZoom={11} defaultCenter={props.position}>
            <Marker position={props.position} />
        </GoogleMap>
    ))
);

export const PlacesWithStandaloneSearchBox = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />
    }),
    lifecycle({
        componentDidMount() {
            const refs = {};

            this.setState({
                places: [],
                onSearchBoxMounted: (ref) => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();

                    this.props.onPlacesChanged(places);
                }
            });
        }
    }),
    withScriptjs
)((props) => (
    <div data-standalone-searchbox="">
        <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                className="noph"
                placeholder="e.g. 100 Av. Charles de Gaulle"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `100%`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`
                }}
            />
        </StandaloneSearchBox>
    </div>
));

// ------------ END ------------
class ScreenMarker extends React.Component {
    constructor() {
        super();

        this.state = {
            isOpen: false,
            deviceSelected: 0
        };

        this.onToggleOpen = this.onToggleOpen.bind(this);
    }

    onToggleOpen() {
        if (this.props.unique) {
            return;
        }

        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        if (this.props.dev && this.props.dev.length === 0) {
            return null; // Do not display shops without screens
        }

        const screen = this.props.screen;

        return (
            <Marker
                key={screen.id}
                position={{
                    lat: parseFloat(screen['Shop.lat']),
                    lng: parseFloat(screen['Shop.long'])
                }}
                onClick={this.onToggleOpen}
                icon={{
                    url: icons[screen['Shop.Sign.name']],
                    scaledSize: { height: 45, width: 30 }
                }}
                animation={window.google.maps.Animation.DROP}
            >
                {this.state.isOpen && (
                    <InfoWindow onCloseClick={this.onToggleOpen}>
                        <div>
                            <h4>
                                {screen['Shop.Sign.name']} - {screen['Shop.name']} - {screen['Shop.address']}
                            </h4>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        );
    }
}

const PosMarker = (props) => {
    return (
        <OverlayView position={props.position} mapPaneName="overlayMouseTarget">
            <div className="mapMyPos" />
        </OverlayView>
    );
};

class GMap extends React.Component {
    constructor() {
        super();

        this.maps = window.google.maps;

        this.state = {
            bounds: new this.maps.LatLngBounds()
        };
    }

    render() {
        return (
            <GoogleMap
                ref="map"
                defaultZoom={this.props.zoom}
                zoom={this.props.zoom}
                defaultCenter={this.props.center}
                center={this.props.center}
                fullscreenControl={true}
                options={{ streetViewControl: false, mapTypeControl: false }}
                onBoundsChanged={() => this.setState({ bounds: this.refs.map.getBounds() })}
                onZoomChanged={() => this.props.onZoomChange(this.refs.map.getZoom())}
            >
                {this.props.mydev
                    .filter((item) => item['Shop.SignId'] === this.props.signId)
                    .map((screen) => {
                        return (
                            <ScreenMarker
                                key={screen.id}
                                stats={this.props.stats || []}
                                mydev={this.props.mydev}
                                screen={screen}
                                assistant={this.props.assistant}
                                signId={this.props.signId}
                            />
                        );
                    })}
                {!this.props.myPosition || <PosMarker position={this.props.myPosition} />}
            </GoogleMap>
        );
    }
}

const MyMapComponent = withScriptjs(withGoogleMap((props) => <GMap {...props} />));

class Map extends React.Component {
    constructor() {
        super();

        this.state = {
            screens: [],
            statuses: [],
            devices: [],
            prospects: [],
            center: { lat: 48.8588377, lng: 2.2770207 },
            zoom: 12,
            tagsProspects: [],
            eChecked: true,
            pChecked: false,
            pCheckedI: false,
            playerChecked: false,
            screenChecked: false,
            screenStatusSelected: '',
            pGT200: true,
            pHidden: [],
            lastSuivi: 0,
            stats: [],
            shops: {},
            tags: [],
            mydev: [],
            myshops: [],
            devicesname: ''
        };

        this.getGeoLocation = this.getGeoLocation.bind(this);
        this.handleCheckFilter = this.handleCheckFilter.bind(this);
        this.handleZoomChange = this.handleZoomChange.bind(this);

        this.Auth = new AuthService();
    }

    async componentDidMount() {
        this.getGeoLocation();

        const apiResults = await Promise.all([
            this.Auth.fetch(`${constants.endpoint}/admin/map`),
            this.Auth.fetch(`${constants.endpoint}/admin/devStatus`),
            this.Auth.fetch(`${constants.endpoint}/admin/map/prospects`)
        ]);

        if (this.props.assistant) {
            await fetchApi('/admin/history/stats')
                .then((res) => res.json())
                .then((stats) => this.setState({ stats }));
        }

        const [{ devices, shops }, { statuses }, prospects] = apiResults;

        devices.forEach((device) => {
            if (statuses) {
                device.status = statuses.find((status) => status.sqlId === device.id);
            }
            device.isActive =
                device.status &&
                device.status.status === 'Running' &&
                (new Date() - new Date(device.status.lastPing)) / 1000 < 30;
        });

        shops.forEach((shop) => {
            shop.devices = devices.filter((device) => {
                return device.ShopId === shop.id;
            });
        });

        this.setState({ screens: shops, devices, prospects, statuses });

        fetchApi('/manager/screens')
            .then((res) => res.json())
            .then((devices) => {
                const shopsMap = {};
                for (const device of devices) {
                    if (!shopsMap[device['Shop.id']]) {
                        shopsMap[device['Shop.id']] = [device];
                    } else {
                        shopsMap[device['Shop.id']].push(device);
                    }
                }

                this.setState({ shops: shopsMap, mydev: devices });

                const suggestions = [];

                const shops = Object.values(shopsMap);

                devices
                    .filter((s) => s['Shop.SignId'] === this.props.user.role.Shop.SignId)
                    .forEach((d) => {
                        const entries = [
                            {
                                type: 'shop',
                                name: d['Shop.name'] + ' - ' + d['Shop.address'] + ' - ' + d['Shop.city'] + ' - Shop',
                                id: d['Shop.name']
                            }
                        ];

                        entries.forEach((e) => {
                            if (!suggestions.find((s) => s.type === e.type && s.id === e.id)) {
                                if (e.id) {
                                    suggestions.push(e);
                                }
                            }
                        });
                    });

                if (devices.length > 0) {
                    this.setState({ suggestions, myshops: shops, devicesname: devices[0]['Shop.Sign.name'] });
                } else {
                    this.setState({ suggestions, myshops: shops, devicesname: 'NoName' });
                }
            });
    }

    handleCheckFilter(e) {
        if (e.target.checked) {
            this.setState({
                pHidden: this.state.pHidden.filter((h) => h !== e.target.value)
            });
        } else {
            this.setState({ pHidden: [...this.state.pHidden, e.target.value] });
        }
    }

    handleZoomChange(e) {
        this.setState({ zoom: e });
    }

    getGeoLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                this.setState({ center: center });
            });
        } else {
            console.error('Impossible de géolocaliser');
        }
    }

    render() {
        let mydev = this.state.mydev;

        this.state.tags.forEach((sf) => {
            mydev = mydev.filter((s) => {
                if (sf.type === 'shop') {
                    return s['Shop.name'] === sf.id;
                }

                return null;
            });
        });

        return (
            <div>
                <Panel style={{ padding: this.props.assistant ? '5px' : '25px' }}>
                    <h4>
                        {this.state.myshops.length} {getShopName().toLowerCase()} {this.state.devicesname} - {this.state.mydev.length} écrans
                    </h4>
                    <Row>
                        <Col md={4} style={{ padding: 0 }}>
                            <div className="groupofmap">
                                <ReactTags
                                    placeholder="Recherche"
                                    tags={this.state.tags}
                                    suggestions={this.state.suggestions}
                                    suggestionsFilter={(item, query) => {
                                        return item.name.toUpperCase().includes(query.toUpperCase());
                                    }}
                                    handleDelete={(e) =>
                                        this.setState({
                                            tags: this.state.tags.filter((_, i) => i !== e)
                                        })
                                    }
                                    handleAddition={(e) =>
                                        this.setState({
                                            tags: [...this.state.tags, e]
                                        })
                                    }
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <MyMapComponent
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgStQUqb1f6TTgpgt5Th_BAgmvt2FTv-A&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `80vh` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                center={this.state.center}
                                zoom={this.state.zoom}
                                pChecked={this.state.pChecked || this.state.pCheckedI}
                                eChecked={this.state.eChecked}
                                myPosition={this.state.center}
                                devices={this.state.devices}
                                statuses={this.state.statuses}
                                assistant={this.props.assistant}
                                onZoomChange={this.handleZoomChange}
                                options={{
                                    mapTypeControl: false
                                }}
                                mydev={mydev}
                                stats={this.state.stats}
                                signId={this.props.user.role.Shop.SignId}
                            />
                        </Col>
                    </Row>
                </Panel>
            </div>
        );
    }
}

export default Map;
