import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { EditAssetDates } from './EditAsset';

export const EditAssetsModal = ({ assets, categories, onClose, onUpdate, modalSelectionOpened }) => {
    const [state, setState] = useState({
        assets: null,
        defaultExpiration: null,
        defaultStart: null
    });

    useEffect(() => {
        if (assets !== null) {
            let defaultExpiration = null;
            let defaultStart = null;

            if (
                assets.length > 0 &&
                assets.every(({ defaultExpiration }) => defaultExpiration === assets[0].defaultExpiration)
            ) {
                defaultExpiration = assets[0].defaultExpiration;
            }

            if (assets.length > 0 && assets.every(({ defaultStart }) => defaultStart === assets[0].defaultStart)) {
                defaultStart = assets[0].defaultStart;
            }

            setState((prevState) => ({ ...prevState, assets, defaultExpiration, defaultStart }));
        }
    }, [assets]);

    if (state.assets === null || state.assets.length === 0) {
        return null;
    }

    const category = categories.find(({ _id }) => _id === state.assets[0].event);

    if (!category) {
        return null;
    }

    return (
        <Modal className="modal-assets-admin" show={modalSelectionOpened} onHide={onClose}>
            <Modal.Header>Edition d'affiches</Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <span>Catégorie: {category.name}</span>
                    </div>
                    <div className="col-md-4">
                        <h4>Informations</h4>
                        <p>Expiration catégorie: {moment(category.expirationDate).format('DD/MM/yy HH:mm:ss')}</p>
                        <EditAssetDates
                            category={category}
                            defaultExpiration={state.defaultExpiration}
                            defaultStart={state.defaultStart}
                            setDefaultExpiration={(e) =>
                                setState((prevState) => ({ ...prevState, defaultExpiration: e }))
                            }
                            setDefaultStart={(e) => setState((prevState) => ({ ...prevState, defaultStart: e }))}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-success"
                    onClick={() =>
                        onUpdate(
                            state.assets.map((a) => ({
                                ...a,
                                defaultExpiration: state.defaultExpiration,
                                defaultStart: state.defaultStart
                            }))
                        )
                    }
                >
                    Enregistrer
                </button>
            </Modal.Footer>
        </Modal>
    );
};
