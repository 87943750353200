import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { fetchApi } from '../../Services/NetworkServcice';
import PreviewFishShop from '../PreviewFishShop';
import ChooseCategory from './ChooseCategory';
import FishShopPrice from './SetPrice';
import FishShopAttributes from './Attributes';

class FishShop extends React.Component {
    constructor(props) {
        super();

        this.state = {
            // State
            options: {},
            cat: {},
            step: 0,

            duration: 5,

            // Crea
            _id: null,
            filename: '',
            subtitle: '',
            fishSub: '',
            category: '',
            originCountry: '',
            price: '',
            priceUnit: 'kilo',
            pieceNb: '1',
            weight: '1',
            weightUnit: 'kg',
            promotion: false,
            expirationDate: null,
            startDate: null,
            fishingMethod: '',
            subzone: '',
            labels: [],
            templateName: props.templateName
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveCrea = this.handleSaveCrea.bind(this);
        this.setSlide = this.setSlide.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentDidMount() {
        fetchApi('/sequences/fishshop')
            .then((res) => res.json())
            .then((fishShop) => {
                const options = {};
                const cat = {};

                fishShop.forEach((entry) => {
                    if (!options[entry.category]) {
                        options[entry.category] = [];
                        if (entry.subcategory) cat[entry.category] = entry.subcategory;
                    }

                    options[entry.category].push(entry.filename);
                });

                this.setState({ options, cat });
            });
    }

    reset() {
        this.setState({
            _id: null,
            filename: '',
            category: '',
            subtitle: '',
            fishSub: '',
            originCountry: '',
            fishingMethod: 'fished',
            subzone: '',
            price: '',
            priceUnit: 'kilo',
            pieceNb: '1',
            weight: '1',
            weightUnit: 'kg',
            promotion: false,
            expirationDate: null,
            startDate: null,
            labels: [],
            templateName: this.props.templateName,
            duration: 5,

            step: 0
        });
    }

    handleClose() {
        this.reset();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSaveCrea() {
        const crea = {
            _id: this.state._id,
            filename: this.state.filename,
            category: this.state.category,
            subtitle: this.state.subtitle,
            fishSub: this.state.fishSub,
            originCountry: this.state.originCountry,
            price: this.state.price.toString().replace(',', '.'),
            priceUnit: this.state.priceUnit,
            pieceNb: this.state.pieceNb,
            weight: this.state.weight,
            weightUnit: this.state.weightUnit,
            promotion: this.state.promotion,
            fishingMethod: this.state.fishingMethod,
            subzone: this.state.subzone,
            expirationDate: this.state.expirationDate,
            startDate: this.state.startDate,
            labels: this.state.labels,
            templateName: this.state.templateName
        };

        this.props.handleSaveCrea({
            creative: crea,
            creaType: 'FishShop',
            expirationDate: this.state.expirationDate,
            startDate: this.state.startDate,
            duration: this.state.duration
        });

        this.handleClose();
    }

    setSlide(s, duration) {
        this.setState({ ...s, price: s.price.toString().replace('.', ','), step: 2, duration });
    }

    render() {
        let component = null;

        switch (this.state.step) {
            case 0:
                component = (
                    <ChooseCategory
                        filename={this.state.filename}
                        options={this.state.options}
                        cat={this.state.cat}
                        category={this.state.category}
                        handleSelectOption={(e) =>
                            this.setState({
                                filename: this.state.options[e][0]
                            })
                        }
                        handleSelectPiece={(e) =>
                            this.setState({
                                category: e
                            })
                        }
                        handleChangeSub={(e) => this.setState({ fishSub: e })}
                        subtitle={this.state.subtitle}
                        fishSub={this.state.fishSub}
                        handleSwitchOption={(index, option) =>
                            this.setState({
                                filename:
                                    this.state.options[option][
                                        index < 0
                                            ? 0
                                            : index >= this.state.options[option].length
                                            ? this.state.options[option].length - 1
                                            : index
                                    ]
                            })
                        }
                    />
                );
                break;
            case 1:
                component = (
                    <FishShopAttributes
                        originCountry={this.state.originCountry}
                        handleChange={this.handleChange}
                        fishingMethod={this.state.fishingMethod}
                        labels={this.state.labels}
                        handleSetLabels={(labels) => this.setState({ labels, label: null })}
                        handleSetSub={(v) => this.setState({ subtitle: v })}
                        handleSetSubzone={(z) => this.setState({ subzone: z })}
                    />
                );
                break;
            case 2:
                component = (
                    <FishShopPrice
                        price={this.state.price}
                        priceUnit={this.state.priceUnit}
                        pieceNb={this.state.pieceNb}
                        weight={this.state.weight}
                        weightUnit={this.state.weightUnit}
                        promotion={this.state.promotion}
                        expirationDate={this.state.expirationDate}
                        startDate={this.state.startDate}
                        handleChange={this.handleChange}
                        allowSlidesDurationChange={this.props.allowSlidesDurationChange}
                        maxDuration={this.props.maxDuration}
                        onChangeDuration={(e) => this.setState({ duration: parseInt(e.target.value) })}
                        duration={this.state.duration}
                    />
                );
                break;
            default:
                break;
        }

        return (
            <div>
                <header>
                    <div className="progress-assistant">
                        <div className={`item${this.state.step >= 0 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(0, this.state.step) })}
                            >
                                1
                            </div>
                            {this.state.step !== 0 || <span>Choisissez un produit</span>}
                        </div>
                        <div className={`item${this.state.step >= 1 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(1, this.state.step) })}
                            >
                                2
                            </div>
                            {this.state.step !== 1 || <span>Renseignez plus d’informations</span>}
                        </div>
                        <div className={`item${this.state.step >= 2 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(2, this.state.step) })}
                            >
                                3
                            </div>
                            {this.state.step !== 2 || <span>Prix et programmation</span>}
                        </div>
                    </div>
                    <div className="buttons">
                        {this.state.step <= 0 || (
                            <button
                                className="prev"
                                onClick={() => this.setState((prevState) => ({ step: prevState.step - 1 }))}
                            >
                                Retour
                            </button>
                        )}
                        <button
                            className="button-assistant"
                            disabled={
                                (this.state.step === 2 && this.state.price.trim().length === 0) ||
                                (this.state.step === 0 && this.state.category.length === 0)
                            }
                            onClick={() => {
                                if (this.state.step < 2) {
                                    return this.setState((prevState) => ({ step: prevState.step + 1 }));
                                }

                                this.handleSaveCrea();
                            }}
                        >
                            {this.state.step < 2 ? 'Suivant' : 'Enregistrer'}
                        </button>
                    </div>
                </header>
                <div>
                    <Row>
                        <Col md={9}>{component}</Col>
                        <Col md={3}>
                            <p className="center">Prévisualisation</p>
                            <PreviewFishShop {...this.state} orientation={this.props.orientation} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default FishShop;
