import React from 'react';
import { fetchApi } from '../../../../Services/NetworkServcice';
import AuthService from '../../../../Components/Auth/AuthService';
import constants from '../../../../Constants';
import Select from 'react-select';
import './index.css';
import addIcon from './add.png';
import {notify} from 'react-notify-toast';

const Auth = new AuthService();

export default class Upload extends React.Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
            campaigns: [],
            campaignSelected: {},
            shops: [],
            shopSelected: {},
            imgUrls: [''],
            files: []
        };
    }

    getData() {
        window.scrollTo(0, 0);

        return Promise.all([
            fetchApi('/admin/shops')
                .then(res => res.json())
                .then(({ shops }) => {
                    this.setState({ shops });
                }),
            fetchApi('/admin/campaignsGallery')
                .then(res => res.json())
                .then(campaigns =>  {
                    this.setState({
                        campaigns: campaigns.filter(x => x.name).sort(function(a, b){
                            return new Date(b.creationDate) - new Date(a.creationDate);
                        }).reverse()
                    });
                })
        ]);
    }

    async componentDidMount() {
        setTimeout(() => {
            this.addImage();
        });
        await this.getData();
    }

    async readFiles(event) {
        const files = [];
        const imgUrls = [];

        const results = await Promise.all(Array.from(event.target.files).map(file => {
            const blob = file.slice(0, file.size, file.type);

            const newFile = new File([blob], `${Date.now()}-${file.name}`, {type: file.type});

            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    resolve({
                        file: newFile,
                        imgUrl: e.target.result
                    });
                };

                reader.readAsDataURL(file);
            });
        }));

        results.forEach(({ file, imgUrl }) => {
            files.push(file);
            imgUrls.push(imgUrl);
        });

        this.setState({ files, imgUrls });
    }

    addImage() {
        this.inputElement.click();
    }

    scrollTo(input) {
        window.scrollTo(0, document.getElementById(input).offsetTop);
    }

    async uploadImage() {
        if (this.state.files.length === 0) { return alert('Pas de fichier ajouté'); }

        const campaign = this.state.campaigns.find(campaign => campaign._id === this.state.campaignSelected.value);
        if(!campaign) { return alert('Pas de campagne séléctionnée'); }

        const shop = this.state.shops.find(({ id }) => id === this.state.shopSelected.value);
        if(!shop) { return alert('Pas de magasin séléctionné'); }

        this.setState({ isLoading: true });

        try {
            await Promise.all(this.state.files.map(async file => {
                const data = new FormData();

                data.append('image', file);
                data.append('type', file.type);
                data.append('id', campaign._id);
                data.append('sign', shop.Sign.name);
                data.append('shop', shop.name);
                data.append('address', shop.address);
                data.append('zip_code', shop.zip);
                data.append('city', shop.city);
                data.append('lng', shop.long);
                data.append('lat', shop.lat);

                return Auth.fetchMultipart(`${constants.endpoint}/admin/campaignsGallery/upload/${campaign._id}`, {
                    method: 'POST',
                    body: data
                });
            }));

            this.setState({
                isLoading: false,
                campaignSelected: {},
                shopSelected: {},
                imgUrls: [''],
                files: []
            });

            window.scrollTo(0, 0);

            notify.show('Photo(s) bien envoyée(s) !', 'success');

        } catch(e) {
            this.setState({ isLoading: false });

            return alert('Impossible d\'uploader l(es) image(s) sur le serveur');
        }
    }

    setValue(input, value) {
        this.setState({
            [input]: value
        });
    }

    render() {
        return (
            <div className="container">
                <input
                    ref={input => this.inputElement = input}
                    type="file"
                    multiple={true}
                    accept="image/*,video/*"
                    style={{ display: 'none' }}
                    onChange={(event)=> { this.readFiles(event); event.target.value=null; }}
                />

                <div className="title">Sélectionne une photo(s) / vidéo(s)</div>
                {
                    this.state.imgUrls.map((imgUrl, i) => {
                        return <img
                            key={i}
                            id="profile-img-tag"
                            style={{ width: '100%'}}
                            src={imgUrl || addIcon}
                            alt="pic"
                            onClick={event => this.addImage()}/>;
                    })
                }

                <div
                    className="title"
                    id="campaignTitle">
                  Sélectionne la campagne
                </div>
                <Select
                    options={this.state.campaigns.map(c => ({
                        value: c._id,
                        label: `${c.name}`
                    }))}
                    onFocus={e => this.scrollTo('campaignTitle')}
                    onChange={e => this.setValue('campaignSelected', e)}
                    value={{
                        value: this.state.campaignSelected.value,
                        label: this.state.campaignSelected.label
                    }}
                />

                <div className="title"
                    id="shopTitle">
                  Sélectionne le magasin
                </div>
                <Select
                    placeholder={'custom placeholder component'}
                    options={this.state.shops.map(s => ({
                        value: s.id,
                        label: `${s.name} - ${s.address} ${s.zip} ${s.city} - ${s.Sign.name}`
                    }))}
                    onFocus={e => this.scrollTo('shopTitle')}
                    onChange={e => this.setValue('shopSelected', e)}
                    value={{
                        value: this.state.shopSelected.value,
                        label: this.state.shopSelected.label
                    }}
                />

                <button
                    className="btn btn-primary bottom-btn"
                    disabled={this.state.isLoading}
                    onClick={() => this.uploadImage()}>
                    { this.state.isLoading ? 'Loading...' : 'Click to upload' }
                </button>
            </div>
        );
    }
}
