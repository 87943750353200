import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import constants, { formats, getShopName, getSignName } from '../Constants';
import { notify } from 'react-notify-toast';
import swal from 'sweetalert';
import { fetchApi } from '../Services/NetworkServcice';
import TagsInput from 'react-tagsinput';
import { Modal } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { Link } from 'react-scroll';
import { Link as RRDLink } from 'react-router-dom';
import HeaderNotifications from '../Components/HeaderNotifications';
import { getDeviceLength } from './Home';
import ReactSwitch from 'react-switch';
import Calendar from 'react-calendar';

const toggleDataList = (list, element) => {
    if (list.includes(element)) {
        return list.filter((e) => e !== element);
    } else {
        return [...list, element];
    }
};

const filterAssets = (filter, elements, catName = null) => {
    filter = filter.trim().toUpperCase();

    if (filter.length < 1) {
        return elements;
    }

    return elements.filter(
        ({ tags }) => tags.indexOf(filter) !== -1 || (catName !== null && catName.indexOf(filter) !== -1)
    );
};

const validateImages = async (files, orientation) => {
    const filesRet = [];

    for (const f of files) {
        if (f.type === 'video/mp4') {
            if (f.size / 1000000 > 250) {
                alert(`Le fichier ${f.name} ne peut pas dépasser 250Mo`);
                continue;
            }

            filesRet.push(f);
        } else if (
            f.type === 'image/jpeg' ||
            f.type === 'image/png' ||
            f.type === 'application/pdf'
            // f.type === 'image/gif' ||
        ) {
            // const getRatio = (f) =>
            //     new Promise((resolve) => {
            //         const fr = new FileReader();

            //         fr.addEventListener('load', () => {
            //             const image = new Image();

            //             image.addEventListener('load', () => {
            //                 const ratio = image.width / image.height;
            //                 return resolve(ratio);
            //             });

            //             image.src = fr.result;
            //         });

            //         fr.readAsDataURL(f);
            //     });

            // const ratio = await getRatio(f);
            // const referenceRatio = formats[orientation].ratio;

            // if (parseInt(1000 * ratio) !== parseInt(1000 * referenceRatio)) {
            //     alert(`L'image ${f.name} a un ratio invalide`);
            //     continue;
            // }

            filesRet.push(f);
        } else {
            // Error
            alert(`Le format du fichier ${f.name} n'est pas pris en charge`);
        }
    }

    return filesRet;
};

const isAllowedToUpload = (role) => {
    // Admin
    if (localStorage.getItem('old_token') && localStorage.getItem('old_role') === 'admin') {
        return true;
    }

    // Allowed agents
    if (role.Agent.uploadAllowed && role.Shop && role.Shop.id !== 1051) {
        return true;
    }

    // Allowed users
    const ALLOWED_USERS = [1518, 1521];
    if (ALLOWED_USERS.includes(role.id)) {
        return true;
    }

    // Allowed signs
    const ALLOWED_SIGNS = [40, 41, 42];
    if (role.Shop && ALLOWED_SIGNS.includes(role.Shop.AgentId)) {
        return true;
    }

    return false;
};

const ModalUpload = ({ show, onClose, availableOrientations, shopId, userObject, getAssets }) => {
    const [state, setState] = useState({
        orientation: availableOrientations[0],
        buttonActive: true,
        uploading: false,
        files: [],
        tags: [],
    });

    useEffect(() => {
        setState((prevState) => ({ ...prevState, orientation: availableOrientations[0] }));
    }, [availableOrientations]);

    const uploadFiles = () => {
        const data = new FormData();
        state.files.forEach((file) => {
            data.append(file.name, file);
        });

        data.append('orientation', state.orientation);
        data.append('tags', state.tags);

        setState({
            ...state,
            buttonActive: false,
            uploading: true,
        });

        let token = localStorage.getItem('id_token');

        if (!userObject.role.Agent.uploadAllowed && ![1518, 1521].includes(userObject.role.id)) {
            token = localStorage.getItem('old_token');
        }

        fetch(`${constants.endpoint}/admin/shops/upload/${shopId}`, {
            headers: {
                'x-access-token': token,
            },
            body: data,
            method: 'POST',
        })
            .then(async (res) => {
                if (res.status === 400) {
                    const error = await res.text();
                    const err = { message: error };
                    throw err;
                }

                if (res.status === 409) {
                    notify.show(
                        "Vous avez atteint le nombre maximal de créatives autorisées. Vous pouvez supprimer des affiches afin de libérer de l'espace",
                        'warning'
                    );
                }

                if (res.status === 204) {
                    notify.show("L'envoi de fichier est termine!", 'success');
                    getAssets();
                }

                setState({
                    ...state,
                    buttonActive: true,
                    files: [],
                    uploading: false,
                    tags: [],
                });

                onClose();
            })
            .catch((err) => {
                setState({
                    ...state,
                    buttonActive: true,
                    files: [],
                    uploading: false,
                    tags: [],
                });

                onClose();
                notify.show("L'envoi de fichier a echoue. " + err.message, 'warning');
            });
    };

    const getFiles = () => {
        if (state.files.length === 0) {
            return <p>Glissez deposez ou cliquez</p>;
        }

        return state.files.map((file, i) => (
            <p key={i}>
                {file.name} - {Math.round(file.size / 10000) / 100}Mo
            </p>
        ));
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4 className="modal-title" style={{ textAlign: 'center' }}>
                        Ajout d'une affiche magasin
                    </h4>
                </Modal.Title>
                <Modal.Body>
                    <h5>Déposez vos fichiers ici</h5>
                    <Dropzone
                        onDrop={async (files) =>
                            setState({ ...state, files: await validateImages(files, state.orientation) })
                        }
                        style={{
                            width: 'initial',
                            height: '200px',
                            border: '1px solid black',
                            color: 'gray',
                            borderRadius: '5px',
                            overflowY: 'scroll',
                        }}
                        accept=".mp4,image/*,.m4v"
                    >
                        {getFiles()}
                    </Dropzone>
                    <hr />
                    <div
                        className="form-group"
                        style={{ display: availableOrientations.length > 1 ? 'block' : 'none' }}
                    >
                        <label htmlFor="orientation">Orientation</label>
                        <select
                            name="orientation"
                            className="form-control"
                            value={state.orientation}
                            onChange={(e) => setState({ ...state, orientation: e.target.value })}
                        >
                            {availableOrientations.map((o) => (
                                <option value={o} key={o}>
                                    {formats[o].name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Mots-clés</label>
                        <TagsInput value={state.tags} onChange={(tags) => setState({ ...state, tags })} />
                    </div>
                    <hr />
                    <button disabled={!state.buttonActive} onClick={uploadFiles} className="btn btn-info pull-right">
                        {state.uploading ? 'Envoi...' : 'Envoyer'}
                    </button>
                </Modal.Body>
            </Modal.Header>
        </Modal>
    );
};

const ModalDetails = ({
    show,
    onClose,
    sequences,
    selectedAsset,
    selectedCreas,
    devices,
    selectedTitle,
    userObject,
    onDelete,
}) => {
    const [state, setState] = useState({
        start: null,
        end: null,
    });

    const getAssetPreview = () => {
        if (!selectedAsset) {
            return null;
        }

        if (selectedAsset.type === 'image') {
            return (
                <React.Fragment>
                    <img
                        src={`${constants.cloud_storage}/Assets/${selectedAsset.filename}`}
                        alt={selectedAsset.filename}
                    />
                    <p>
                        <i className="fa fa-image"></i>Image - {formats[selectedAsset.orientation]?.name}
                    </p>
                    {!userObject.role.Shop || selectedAsset.shopId !== userObject.role.Shop.id || (
                        <button className="btn btn-danger" onClick={() => onDelete(selectedAsset._id)}>
                            Supprimer
                        </button>
                    )}
                </React.Fragment>
            );
        } else if (selectedAsset.type === 'video') {
            return (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        key={selectedAsset.filename}
                        controls="controls"
                        playsInline
                        muted
                        loop={true}
                        className="videoPreview"
                    >
                        <source src={`${constants.cloud_storage}/Assets/${selectedAsset.filename}`} type="video/mp4" />
                        <source
                            src={`${constants.endpoint}/static/assets/${selectedAsset.filename}`}
                            type="video/mp4"
                        />
                    </video>
                    <p>
                        <i className="fa fa-film"></i>Vidéo - {formats[selectedAsset.orientation].name}
                    </p>
                    {!userObject.role.Shop || selectedAsset.shopId !== userObject.role.Shop.id || (
                        <button className="btn btn-danger" onClick={() => onDelete(selectedAsset._id)}>
                            Supprimer
                        </button>
                    )}
                </React.Fragment>
            );
        }
    };

    let title = 'Sélection multiple';
    let multiple = true;
    if (selectedAsset && selectedTitle) {
        title = selectedTitle;
        multiple = false;
    }

    const maxUserSlides = userObject.role.Agent.maxUserSlides || 100;

    return (
        <Modal show={show} onHide={onClose} dialogClassName="asset-preview-modal">
            <Modal.Header closeButton>
                <Modal.Title className="center modal-small">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`assetPreview a-pr-${selectedAsset && selectedAsset.orientation}`}>
                    <div className="asset">
                        {getAssetPreview()}
                        <div style={{ minWidth: 200 }}>
                            <div className="flex center">
                                <ReactSwitch
                                    className="switch-class ml-0"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    onChange={(e) => {
                                        let start = null;

                                        if (e) {
                                            const d = new Date();
                                            d.setHours(0, 0, 0, 0);
                                            d.setDate(d.getDate() + 1);
                                            start = d;
                                        }

                                        setState({
                                            ...state,
                                            start,
                                        });
                                    }}
                                    checked={!!state.start}
                                ></ReactSwitch>
                                <span style={{ marginLeft: 10, marginTop: 3 }}>Date de démarrage</span>
                            </div>
                            {!state.start || (
                                <Calendar
                                    className="fruit-expiration"
                                    onChange={(date) => {
                                        date.setHours(0, 0, 0, 0);

                                        setState({
                                            ...state,
                                            start: date,
                                        });
                                    }}
                                    value={new Date(state.start)}
                                />
                            )}
                        </div>
                        <div>
                            <div className="flex center">
                                <ReactSwitch
                                    className="switch-class ml-0"
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={40}
                                    onChange={(e) => {
                                        let end = null;

                                        if (e) {
                                            const d = new Date();
                                            d.setHours(23, 59, 59, 999);
                                            d.setDate(d.getDate() + 1);
                                            end = d;
                                        }

                                        setState({
                                            ...state,
                                            end,
                                        });
                                    }}
                                    checked={!!state.end}
                                ></ReactSwitch>
                                <span style={{ marginLeft: 10, marginTop: 3 }}>Date d'expiration</span>
                            </div>
                            {!state.end || (
                                <Calendar
                                    className="fruit-expiration"
                                    onChange={(date) => {
                                        date.setHours(23, 59, 59, 999);

                                        setState({
                                            ...state,
                                            end: date,
                                        });
                                    }}
                                    value={new Date(state.end)}
                                />
                            )}
                        </div>
                    </div>
                    {!userObject.role.Shop || userObject.role.Shop.id === 1501 || (
                        <div className="sequences">
                            <h4 className="indications">
                                Ajoutez {multiple ? 'ces affiches' : 'cette affiche'} directement dans une séquence :
                            </h4>
                            {sequences
                                .sort((a, b) => {
                                    if (a.devices.length > b.devices.length) {
                                        return -1;
                                    } else if (a.devices.length < b.devices.length) {
                                        return 1;
                                    } else if (!!a.startDate && !b.startDate) {
                                        return 1;
                                    } else if (!a.startDate && !!b.startDate) {
                                        return -1;
                                    } else {
                                        return a.name.localeCompare(b.name);
                                    }
                                })
                                .filter(({ orientation }) => {
                                    if (!!selectedAsset && selectedAsset.orientation === orientation) {
                                        return true;
                                    }

                                    if (!selectedAsset && selectedCreas.length > 0) {
                                        return true;
                                    }

                                    return false;
                                })
                                .map((s) => {
                                    const screens = devices.filter(({ id }) => s.devices.indexOf(id) !== -1);

                                    const used = !!s.creatives.find(
                                        ({ crea }) => selectedAsset && crea === selectedAsset._id
                                    );

                                    return (
                                        <div className="card seq" key={s._id}>
                                            <div className="content">
                                                <h3>{s.name}</h3>
                                                <p>
                                                    <i className="fa fa-images"></i> Format :{' '}
                                                    {formats[s.orientation].name} - {s.creatives.length} affiche
                                                    {s.creatives.length <= 1 ? '' : 's'}
                                                    {s.creatives.length < maxUserSlides || (
                                                        <span style={{ marginLeft: 8, color: '#f8485e' }}>
                                                            Séquence pleine
                                                        </span>
                                                    )}
                                                    {!used || (
                                                        <span style={{ marginLeft: 8, color: '#52cc52' }}>
                                                            Utilisée
                                                        </span>
                                                    )}
                                                </p>
                                                <div className="labels">
                                                    {!!s.startDate || getDeviceLength(s) === 0 || (
                                                        <span>Séquence active</span>
                                                    )}
                                                    {!s.startDate || <span className="future">Séquence prog.</span>}
                                                    {!s.startDate || (
                                                        <span className="future">
                                                            <i className="fa fa-clock"></i> Dém.{' '}
                                                            {dateFormat(s.startDate, 'dd/mm/yy')}
                                                        </span>
                                                    )}
                                                    {getDeviceLength(s) !== 0 || !!s.startDate || (
                                                        <span className="draft">Brouillon</span>
                                                    )}
                                                </div>
                                                <p>
                                                    {!screens.length || <i className="fa fa-tv"></i>}
                                                    {screens.length === 0 || (
                                                        <React.Fragment>
                                                            {' '}
                                                            {screens.length} Ecran
                                                            {screens.length <= 1 ? '' : 's'} :{' '}
                                                            <strong>{screens[0].name}</strong>
                                                        </React.Fragment>
                                                    )}
                                                    {screens.slice(1).map((s) => (
                                                        <strong key={s.id}>, {s.name}</strong>
                                                    ))}
                                                </p>
                                            </div>
                                            <div className="btn-container">
                                                <button
                                                    disabled={s.creatives.length >= maxUserSlides}
                                                    className="btn-dyl"
                                                    onClick={() => {
                                                        const assets =
                                                            selectedAsset !== null
                                                                ? [selectedAsset._id]
                                                                : selectedCreas;

                                                        fetchApi(`/sequences/${s._id}`, {
                                                            method: 'PATCH',
                                                            body: JSON.stringify({
                                                                assets,
                                                                start: state.start,
                                                                end: state.end,
                                                            }),
                                                        }).then((res) => {
                                                            switch (res.status) {
                                                                case 200:
                                                                    swal({
                                                                        title: `Affiche${
                                                                            assets.length > 1 ? 's' : ''
                                                                        } ajoutée${assets.length > 1 ? 's' : ''}`,
                                                                        text: `${assets.length} affiche${
                                                                            assets.length > 1 ? 's' : ''
                                                                        } ${
                                                                            assets.length > 1 ? 'ont' : 'a'
                                                                        } été ajoutée${
                                                                            assets.length > 1 ? 's' : ''
                                                                        } à la séquence`,
                                                                        icon: 'success',
                                                                    });
                                                                    break;
                                                                case 400:
                                                                    swal({
                                                                        title: 'Erreur',
                                                                        text: "Pas assez d'espace dans la séquence",
                                                                        icon: 'error',
                                                                    });
                                                                    break;
                                                                case 409:
                                                                    notify.show(
                                                                        "Impossible d'ajouter l'affiche: format d'image incompatible",
                                                                        'warning'
                                                                    );
                                                                    break;
                                                                default:
                                                                    notify.show(
                                                                        "Une erreur est survenue lors de l'ajout de cette affiche",
                                                                        'warning'
                                                                    );
                                                                    break;
                                                            }

                                                            onClose();
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-plus"></i> Ajouter
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    );
};

const AssetThumb = ({ src, orientation, onClick, type, onSelect, selected, selectable, isProcessing, previewable }) => {
    const [state, setState] = useState({ isRatioCorrect: true, isPreviewing: false });

    return (
        <div className="asset-thumb">
            {!state.isPreviewing || (
                <Modal
                    className="modal-asset-preview"
                    show={true}
                    onHide={() => setState((prevState) => ({ ...prevState, isPreviewing: false }))}
                >
                    <Modal.Header closeButton>Prévisualisation</Modal.Header>
                    <Modal.Body>
                        {type === 'video' || <img src={`${constants.cloud_storage}/Assets/${src}`} alt="preview" />}
                        {type !== 'video' || (
                            <video muted autoPlay playsInline>
                                <source src={`${constants.cloud_storage}/Assets/${src}`} />
                            </video>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-info"
                            onClick={() => setState((prevState) => ({ ...prevState, isPreviewing: false }))}
                        >
                            Fermer
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
            <img
                className={`${orientation}${selected ? ' selected' : ''}`}
                alt={src}
                onClick={() => {
                    if (isProcessing) return;
                    onClick();
                }}
                onLoad={(e) => {
                    if (orientation !== 'portrait' && orientation !== 'landscape') return;

                    const ratio = e.target.width / e.target.height;
                    if (Math.abs(parseInt(1000 * ratio) - parseInt(1000 * formats[orientation]?.ratio)) > 50) {
                        setState({
                            ...state,
                            isRatioCorrect: false,
                        });
                    }
                }}
                src={`${constants.cloud_storage}/Thumbs/${src}.png`}
            />
            <div className="controls">
                {!selectable || !!isProcessing || (
                    <div className={`round check` + (selected ? ' selected' : '')} onClick={onSelect}>
                        <i className="fas fa-check"></i>
                    </div>
                )}
                <div className="round type">
                    <i className={`fa fa-${type.replace('video', 'film')}`}></i>
                </div>
                {state.isRatioCorrect || (
                    <div className="round warning">
                        <i className="fas fa-exclamation-triangle"></i>
                        <div className="tooltip-ratio">
                            Le ratio du format n'est pas respecté. L'image s'affichera déformée à l'écran.
                        </div>
                    </div>
                )}
                {!isProcessing || (
                    <div className="round processing">
                        <i className="fas fa-sync"></i>
                    </div>
                )}
                {!previewable || (
                    <div
                        className="round check normal"
                        onClick={() => setState((prevState) => ({ ...prevState, isPreviewing: true }))}
                    >
                        <i className="fas fa-eye"></i>
                    </div>
                )}
            </div>
        </div>
    );
};

const AssetList = ({ assets, onClick, onSelect, selectedList, selectable, previewable }) => {
    const formatsFound = new Set(assets.map(({ orientation }) => orientation));

    let content = [];

    for (const format of formatsFound) {
        const assetsMatching = assets.filter(({ orientation }) => orientation === format);

        if (formatsFound.size > 1) {
            content.push(
                <span key={`${format}-title`} className="card-asset-title">
                    <h5>{formats[format]?.name}</h5> - {assetsMatching.length} affiche
                    {assetsMatching.length > 1 ? 's' : ''}
                </span>
            );
        }

        content.push(
            <div className="asset-list" key={format}>
                {assetsMatching.map((a) => (
                    <div key={a._id} className="asset">
                        <AssetThumb
                            onSelect={() => onSelect(a._id)}
                            selected={selectedList.includes(a._id)}
                            src={a.filename}
                            type={a.type}
                            orientation={a.orientation}
                            onClick={() => onClick(a._id)}
                            selectable={selectable}
                            previewable={previewable}
                            isProcessing={a.isProcessing}
                        />
                    </div>
                ))}
            </div>
        );
    }

    return content;
};

export const AssetsSidebarBlock = ({ categories, closeModal, assetNb }) => (
    <div className="card home-editor-cards">
        <h4>Filtres rapides</h4>
        <div className="category manual">
            <div className="cat">
                <i className="fa fa-building"></i>
                {getSignName()}
            </div>
        </div>
        {categories.map((c) => (
            <div className="category" key={c._id}>
                <Link
                    to={c._id}
                    onClick={closeModal}
                    containerId="home-editor-card"
                    smooth="true"
                    activeClass="active"
                    spy={true}
                >
                    {c.name}
                </Link>
            </div>
        ))}
        <div className="category">
            <Link
                to="sign"
                onClick={closeModal}
                containerId="home-editor-card"
                smooth="true"
                activeClass="active"
                spy={true}
            >
                Autres
            </Link>
        </div>
        <div className="category separator"></div>
        <div className="category manual">
            <span className="cat">
                <Link
                    to="mag"
                    onClick={closeModal}
                    containerId="home-editor-card"
                    smooth="true"
                    activeClass="active"
                    spy={true}
                >
                    <i className="fa fa-store-alt"></i>
                    {getShopName()} ({assetNb})
                </Link>
            </span>
        </div>
    </div>
);

export const AssetListBlock = ({
    categories,
    magAssets,
    signAssets,
    onCreaClick,
    onCreaSelect,
    selectable = false,
    selectedCreas = [],
    filter = '',
    orientation = null,
    orientations = null,
    previewable = false,
}) => (
    <React.Fragment>
        {categories.map((c) => {
            const assets = filterAssets(filter, c.assets, c.name).filter((a) => {
                if (orientation !== null && a.orientation !== orientation) {
                    return false;
                }

                if (orientations !== null && orientations.indexOf(a.orientation) === -1) {
                    return false;
                }

                return true;
            });

            if (assets.length === 0) {
                return null;
            }

            return (
                <div key={c._id} id={c._id}>
                    <span className="card-asset-title">
                        <h2>{c.name}</h2>
                        <span>
                            {' '}
                            - {assets.length} affiche{assets.length > 1 ? 's' : ''}
                        </span>
                    </span>
                    <AssetList
                        selectedList={selectedCreas}
                        onSelect={(id) => onCreaSelect(toggleDataList(selectedCreas, id))}
                        onClick={(id) => {
                            const asset = c.assets.find(({ _id }) => _id === id);

                            if (c.expirationDate && !asset.defaultExpiration) {
                                asset.defaultExpiration = c.expirationDate;
                            }

                            if (c.visibleDate && !asset.defaultStart) {
                                asset.startDate = c.visibleDate;
                            } else if (asset.defaultStart) {
                                asset.startDate = asset.defaultStart;
                            }

                            onCreaClick(asset, c.name);
                        }}
                        assets={assets}
                        selectable={selectable}
                        previewable={previewable}
                    />
                </div>
            );
        })}
        {signAssets.length === 0 || (
            <div id="sign">
                <span className="card-asset-title">
                    <h2>{getSignName()}</h2>
                    <span>
                        {' '}
                        - {signAssets.length} affiche{signAssets.length > 1 ? 's' : ''}
                    </span>
                </span>
                <AssetList
                    selectedList={selectedCreas}
                    onSelect={(id) => onCreaSelect(toggleDataList(selectedCreas, id))}
                    onClick={(id) =>
                        onCreaClick(
                            signAssets.find(({ _id }) => _id === id),
                            'Affiche enseigne'
                        )
                    }
                    assets={signAssets}
                    selectable={selectable}
                    previewable={previewable}
                />
            </div>
        )}
        {magAssets.length === 0 || (
            <div id="mag">
                <span className="card-asset-title">
                    <h2>{getShopName()}</h2>
                    <span>
                        {' '}
                        - {magAssets.length} affiche{magAssets.length > 1 ? 's' : ''}
                    </span>
                </span>
                <AssetList
                    selectedList={selectedCreas}
                    onSelect={(id) => onCreaSelect(toggleDataList(selectedCreas, id))}
                    onClick={(id) =>
                        onCreaClick(
                            magAssets.find(({ _id }) => _id === id),
                            'Affiche magasin'
                        )
                    }
                    assets={magAssets}
                    selectable={selectable}
                    previewable={previewable}
                />
            </div>
        )}
    </React.Fragment>
);

class AssetsV2 extends React.Component {
    constructor() {
        super();

        this.state = {
            modalUploadOpen: false,
            modalAssetDetailOpen: false,
            assets: [],
            signAssets: [],
            categories: [],
            shopId: null,
            filter: '',
            sequences: [],
            availableOrientations: ['portrait'],
            selectedCreas: [],
            selectedAsset: null,
            modalFiltersOpen: false,
            devices: [],
            selectedTitle: '',
            buttonCreateLock: false,
        };

        this.getAssets = this.getAssets.bind(this);
        this.removeAsset = this.removeAsset.bind(this);
        this.getSequences = this.getSequences.bind(this);
    }

    removeAsset(id) {
        swal({
            title: 'Confirmation',
            text: 'Êtes-vous sûr ?',
            icon: 'warning',
            buttons: ['Annuler', 'OK'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({
                    assetModalOpen: false,
                });

                fetchApi(`/shop/assets/${id}`, {
                    method: 'DELETE',
                })
                    .then((res) => {
                        if (res.status === 200) {
                            this.setState({ modalAssetDetailOpen: false, selectedAsset: null }, () => this.getAssets());
                            return notify.show('OK !', 'success');
                        } else {
                            return notify.show('Erreur', 'warning');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }

    async removeAssets(ids) {
        if (window.confirm('Etes-vous sur?')) {
            this.setState({
                assetModalOpen: false,
            });

            const removeAsset = (id) =>
                new Promise((resolve, reject) =>
                    fetchApi(`/shop/assets/${id}`, {
                        method: 'DELETE',
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                resolve();
                            } else {
                                reject();
                            }
                        })
                        .catch((err) => {
                            reject();
                        })
                );
            try {
                for (const id of ids) {
                    await removeAsset(id);
                }
            } catch (_) {
                return notify.show('Erreur', 'warning');
            }

            notify.show('OK !', 'success');
            this.setState({ modalAssetDetailOpen: false, selectedAsset: null, selectedCreas: [] }, () =>
                this.getAssets()
            );
        }
    }

    getAssets() {
        fetchApi(`/shop/assets`)
            .then((res) => res.json())
            .then((res) => {
                this.setState({
                    assets: res.assets.reverse(),
                    signAssets: res.signAssets.reverse().filter((a) => {
                        if (
                            this.state.availableOrientations.indexOf(a.orientation) === -1 &&
                            !(this.props.user.role.Shop && this.props.user.role.Shop.id === 1051)
                        ) {
                            return false;
                        }

                        if (!a.defaultExpiration) {
                            return true;
                        }

                        return new Date(a.defaultExpiration) >= new Date();
                    }),
                    categories: res.categories,
                    defaultAsset: res.defaultAsset,
                    shopId: res.shopId,
                });
            })
            .catch((err) => {
                notify.show('La recuperation de donnee a echoue: ' + err.message, 'warning');
            });
    }

    getSequences() {
        fetchApi('/sequences')
            .then((res) => res.json())
            .then((sequences) => this.setState({ sequences }));
    }

    async componentDidMount() {
        await fetchApi('/shop/devices')
            .then((res) => res.json())
            .then((devices) => {
                const availableOrientations = [...new Set(devices.map(({ orientation }) => orientation))];

                if (availableOrientations.length === 0) {
                    availableOrientations.push('portrait');
                }

                const portrait_direct_id = availableOrientations.indexOf('portrait_direct');
                if (portrait_direct_id !== -1) {
                    if (availableOrientations.indexOf('portrait') === -1) {
                        availableOrientations[portrait_direct_id] = 'portrait';
                    } else {
                        availableOrientations.splice(portrait_direct_id, 1);
                    }
                }

                this.setState({
                    availableOrientations,
                    orientation: availableOrientations[0] || 'portrait',
                    devices,
                });
            });

        this.getAssets();
        this.getSequences();
    }

    render() {
        const magAssets = filterAssets(this.state.filter, this.state.assets);
        const signAssets = filterAssets(this.state.filter, this.state.signAssets);

        return (
            <div className="dyl-home assets">
                <ModalUpload
                    availableOrientations={this.state.availableOrientations}
                    onClose={() => this.setState({ modalUploadOpen: false })}
                    shopId={this.state.shopId}
                    show={this.state.modalUploadOpen}
                    userObject={this.props.user}
                    getAssets={this.getAssets}
                />
                <ModalDetails
                    show={this.state.modalAssetDetailOpen}
                    onClose={() => this.setState({ modalAssetDetailOpen: false, selectedAsset: null })}
                    selectedAsset={this.state.selectedAsset}
                    sequences={this.state.sequences}
                    selectedCreas={this.state.selectedCreas}
                    devices={this.state.devices}
                    selectedTitle={this.state.selectedTitle}
                    userObject={this.props.user}
                    onDelete={this.removeAsset}
                />
                <header>
                    <RRDLink to="/" className="logo">
                        <img src="/image.png" alt="logo" />
                    </RRDLink>
                    <p className="hidden-xs">Bibliothèque d'affiche</p>
                    <h3>Toutes les affiches</h3>
                    <HeaderNotifications {...this.props.notifsData} />
                </header>
                <div className="search-filters">
                    <input
                        type="text"
                        value={this.state.filter}
                        onChange={(e) => this.setState({ filter: e.target.value })}
                        className="search"
                        placeholder="Entrez un mot clé"
                    />
                </div>
                <div className="content">
                    <div className="mobile-controls">
                        <div className="btn-add" onClick={() => this.setState({ modalFiltersOpen: true })}>
                            <i className="fa fa-filter"></i>
                        </div>
                    </div>
                    <div className="asset-columns">
                        <div className={`side` + (this.state.modalFiltersOpen ? ' visible-mob' : '')}>
                            <button
                                className="btn btn-info mb-16"
                                style={{
                                    display: isAllowedToUpload(this.props.user.role) ? 'initial' : 'none',
                                }}
                                onClick={() => this.setState({ modalUploadOpen: true })}
                            >
                                Ajouter une affiche
                            </button>
                            <div>
                                <button
                                    onClick={() => this.props.history.push('/pdf-upload')}
                                    className="btn btn-info mb-16 hidden-xs"
                                    style={{
                                        display:
                                            this.props.user.role.Agent.id === 1 ||
                                            this.props.user.role.Agent.id === 3 ||
                                            this.props.user.role.Agent.id === 5 ||
                                            this.props.user.role.Agent.id === 8 ||
                                            this.props.user.role.Agent.id === 9 ||
                                            this.props.user.role.Agent.id === 10
                                                ? 'initial'
                                                : 'none',
                                    }}
                                >
                                    Ajouter
                                </button>
                            </div>
                            <AssetsSidebarBlock
                                assetNb={this.state.assets.length}
                                categories={this.state.categories}
                                closeModal={() => this.setState({ modalFiltersOpen: false })}
                            />
                            <div className="btn-close" onClick={() => this.setState({ modalFiltersOpen: false })}>
                                <i className="fa fa-times"></i>
                            </div>
                            <div className="card selection">
                                <h4>Sélection</h4>
                                <p>
                                    {this.state.selectedCreas.length} affiche
                                    {this.state.selectedCreas.length === 1 || 's'} sélectionnée
                                    {this.state.selectedCreas.length === 1 || 's'}
                                </p>
                                {this.props.user.role.Shop.id === 1051 || (
                                    <div className="buttons">
                                        <button
                                            disabled={this.state.selectedCreas.length === 0}
                                            className="btn-dyl"
                                            onClick={() => this.setState({ selectedCreas: [] })}
                                        >
                                            <i className="fa fa-wind"></i>
                                            Tout déselectionner
                                        </button>
                                        <button
                                            disabled={this.state.selectedCreas.length === 0}
                                            className="btn-dyl"
                                            onClick={() => this.setState({ modalAssetDetailOpen: true })}
                                        >
                                            <i className="fa fa-plus"></i>
                                            Ajouter à une séquence
                                        </button>
                                        <button
                                            disabled={
                                                this.state.selectedCreas.length === 0 || this.state.buttonCreateLock
                                            }
                                            className="btn-dyl"
                                            onClick={() => {
                                                this.setState({ buttonCreateLock: true });

                                                fetchApi(`/sequences`, {
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        name: 'Séquence générée',
                                                        devices: [],
                                                        virtualDevices: [],
                                                        creatives: this.state.selectedCreas.map((c) => ({
                                                            creaType: 'UserCreative',
                                                            creative: {
                                                                _id: c,
                                                            },
                                                        })),
                                                    }),
                                                })
                                                    .then((res) => res.text())
                                                    .then((id) => {
                                                        this.setState({ buttonCreateLock: false });

                                                        swal({
                                                            title: 'Séquence créée',
                                                            text: 'Votre séquence a été créée !',
                                                            buttons: ['Voir la séquence', 'Fermer'],
                                                            icon: 'success',
                                                        }).then((isTrue) => {
                                                            if (!isTrue) {
                                                                this.props.history.push(`/sequences/${id}`);
                                                            }
                                                        });
                                                    });
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                            Créer une séquence
                                        </button>
                                        <button
                                            disabled={
                                                this.state.selectedCreas.length === 0 ||
                                                !this.state.selectedCreas.every(
                                                    (id) => !!this.state.assets.find((a) => a._id === id)
                                                )
                                            }
                                            className="btn-dyl danger"
                                            onClick={() => this.removeAssets(this.state.selectedCreas)}
                                        >
                                            <i className="fa fa-trash"></i>
                                            Supprimer
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="assets">
                            <div className="card card-assets-lib" id="home-editor-card">
                                <AssetListBlock
                                    magAssets={magAssets}
                                    categories={this.state.categories}
                                    signAssets={signAssets}
                                    onCreaClick={(asset, selectedTitle) =>
                                        this.setState({
                                            selectedAsset: asset,
                                            modalAssetDetailOpen: true,
                                            selectedTitle,
                                        })
                                    }
                                    onCreaSelect={(selectedCreas) =>
                                        this.setState({
                                            selectedCreas,
                                        })
                                    }
                                    filter={this.state.filter}
                                    selectable={true}
                                    selectedCreas={this.state.selectedCreas}
                                    orientation={null}
                                    orientations={this.state.availableOrientations}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AssetsV2;
