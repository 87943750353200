import React, { Component } from 'react';
import AuthService from './AuthService';

export default function withAuth(AuthComponent) {
    const Auth = new AuthService();

    return class AuthWrapped extends Component {
        constructor() {
            super();
            this.state = {
                user: null
            };
        }

        componentDidMount() {
            if (window.location.hash.startsWith('#auth-ext')) {
                const token = window.location.hash.slice(9);
                Auth.setToken(token);
                console.log('Authenticating via token');
                window.location.hash = '';
            }

            if (!Auth.loggedIn()) {
                this.props.history.replace('/login');
            } else {
                try {
                    const profile = Auth.getProfile();
                    this.setState({
                        user: profile
                    });
                } catch (err) {
                    Auth.logout();
                    this.props.history.replace('/login');
                }
            }
        }

        render() {
            if (this.state.user) {
                return <AuthComponent user={this.state.user} {...this.props} />;
            } else {
                return null;
            }
        }
    };
}
