import React from 'react';
import { Panel } from 'react-bootstrap';
import AuthService from '../../../Components/Auth/AuthService';
import constants from '../../../Constants';
import { fetchApi } from '../../../Services/NetworkServcice';
import PropTypes from 'prop-types';
import Select from 'react-select';
import dateFormat from 'dateformat';

import '../../../Assets/editor.css';

const ENDPOINT = 'https://api.doohyoulike.com';

const Auth = new AuthService();

const Checkbox = ({ type = 'checkbox', name, checked = false, id, onChange, value }) => (
    <input type={type} name={name} checked={checked} onChange={onChange} id={id} value={value} />
);

Checkbox.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

export default class BilanCampaign extends React.Component {
    constructor() {
        super();
        this.state = {
            campaign: [],
            selectedCampaign: [],
            camp: [],
            gallery: [],
            selectedGallery: '',
            availableDevices: [],
            deviceSelectionSuggestions: [],
            deviceSignsSuggestions: [],
            devices: [],
            filter: ''
        };

        this.handleDownloadCSV = this.handleDownloadCSV.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    }

    componentDidMount() {
        let EP = `${ENDPOINT}/pricer/devices`;

        if (this.props.user.Agent?.id === 8) {
            EP = `${ENDPOINT}/pricer/devices/sport`;
        }

        fetch(EP)
            .then(res => res.json())
            .then(devices => {
                const suggestions = [];
                const signs = [];

                devices = devices.filter(d => d.Shop.id !== 2 && d.campaigns === true);

                devices.forEach(d => {
                    const entries = [
                        {
                            type: 'zip',
                            name: `${d.Shop.zip}`,
                            id: d.Shop.zip
                        },
                        {
                            type: 'city',
                            name: `${d.Shop.city} (Ville)`,
                            id: d.Shop.city
                        },
                        {
                            type: 'departement',
                            name: `${d.Shop.departement} (Departement)`,
                            id: d.Shop.departement
                        },
                        {
                            type: 'region',
                            name: `${d.Shop.region} (Region)`,
                            id: d.Shop.region
                        }
                    ];

                    const signsEntries = [
                        {
                            type: 'sign',
                            name: `${d.Shop.Sign.name}`,
                            id: d.Shop.Sign.name
                        }
                    ];

                    entries.forEach(e => {
                        if (!suggestions.find(s => s.type === e.type && s.id === e.id)) {
                            if (e.id) {
                                suggestions.push(e);
                            }
                        }
                    });

                    signsEntries.forEach(e => {
                        if (!signs.find(s => s.type === e.type && s.id === e.id)) {
                            if (e.id) {
                                signs.push(e);
                            }
                        }
                    });
                });

                this.setState({
                    availableDevices: devices,
                    deviceSelectionSuggestions: suggestions,
                    deviceSignsSuggestions: signs
                });
            })
            .catch(e => {
                console.error('Failed to fetch:', e);
            });
        fetchApi(`/campaigns`)
            .then(res => res.json())
            .then(mycomp => {
                this.setState({
                    campaign: mycomp.campaigns
                }, () => {
                    fetchApi(`/campaigns/archived`)
                        .then(res => res.json())
                        .then(archived => this.setState({ campaign: [...this.state.campaign, ...archived.campaigns] }));
                });
            });
        this.getData();
    }

    async getData() {
        return Auth.fetch(`${constants.endpoint}/admin/campaignsGallery`).then(campaigns => {
            this.setState({ gallery: campaigns });
            return Promise.resolve();
        });
    }

    async handleDownloadCSV() {
        fetchApi(`/admin/campaignsGallery/downloadBilan`, {
            method: 'POST',
            body: JSON.stringify({
                selectedCampaign: this.state.selectedCampaign,
                campaign: this.state.campaign,
                availableDevices: this.state.availableDevices,
                selectedGallery: this.state.selectedGallery
            })
        }).then(async res => {
            window.download(await res.blob(), 'Bilan_Campagne.xlsx');
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCheckbox(e) {
        if (e.target.checked) {
            this.setState({ selectedCampaign: [...this.state.selectedCampaign, e.target.value] });
        } else {
            this.setState({ selectedCampaign: this.state.selectedCampaign.filter(c => c !== e.target.value) });
        }
    }

    render() {
        return (
            <div>
                <div className="moveselect">
                    <Panel style={{ width: '34vw', maxheight: '60vh'}}>
                        <div>
                            <h3>Séléction de Campagne</h3>
                            <div className="selectcamp">
                                <div className="marginsimple">
                                    <input
                                        type="text"
                                        className="form-control campagne"
                                        value={this.state.filter}
                                        onChange={e => this.setState({ filter: e.target.value })}
                                        placeholder="Filtre"
                                    />
                                </div>
                                <React.Fragment>
                                    {this.state.campaign.filter(d => {
                                        let matchCond = d.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) !== -1;
                                        return matchCond;
                                    }).map((item, id) => {
                                        return (
                                            <label key={item._id} className="handlelabel">
                                                <div className="checkofcamp">
                                                    <Checkbox
                                                        name={item.name}
                                                        checked={this.state.selectedCampaign.indexOf(item._id) !== -1}
                                                        onChange={this.handleChangeCheckbox}
                                                        value={item._id}
                                                        className="checkofcamp"
                                                    />
                                                    <p className="itemchamp">{item.name} </p>
                                                    <p style={{ fontSize: 9 }}>({dateFormat(item.startDate, 'dd/mm/yy')} - {dateFormat(item.endDate, 'dd/mm/yy')})</p>
                                                </div>
                                            </label>
                                        );
                                    })}
                                </React.Fragment>
                            </div>
                        </div>
                    </Panel>

                    <br />
                    <Panel style={{ width: '34vw'}}>
                        <div >
                            <h3>Séléction de Galerie</h3>
                            <Select
                                options={this.state.gallery
                                    .filter(item => item.name !== undefined && item.name !== '')
                                    .map((gal, i) => ({
                                        label: gal.name,
                                        value: gal._id
                                    }))
                                    .reverse()
                                }
                                onChange={e =>
                                    this.setState({
                                        selectedGallery: this.state.gallery.find(c => c._id === e.value)
                                    })
                                }
                                value={{
                                    label : this.state.selectedGallery.name,
                                    value : this.state.selectedGallery._id
                                }}
                                className="galerie"
                                placeholder="Selection Galerie"
                            />
                            <button onClick={this.handleDownloadCSV} className="btn btn-info btncamp">
                                Generer Bilan
                            </button>
                        </div>
                    </Panel>
                    <br />
                </div>
            </div>
        );
    }
}
