import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchApi } from '../../Services/NetworkServcice';

const getLabels = (categories, creativeId, onDeleteCategory) => {
    const categorieNames = [];

    for (const cat of categories) {
        if (cat.creativesCovered.findIndex(({ id }) => id === creativeId.toString()) !== -1) {
            categorieNames.push(cat);
            continue;
        }
    }

    return categorieNames.map(({ name, _id }) => (
        <div className="label-bs-grouped" key={_id}>
            <span className="label label-default">{name}</span>
            <span className="label label-default second" onClick={() => onDeleteCategory(_id, creativeId)}>
                <i className="fa fa-times"></i>
            </span>
        </div>
    ));
};

const AssignButtton = ({ onAddToCat, categories, id }) => {
    const ref = useRef(null);

    const availableCategories = categories.filter(
        (c) => c.creativesCovered.findIndex((cat) => cat.id === id.toString()) === -1
    );

    if (availableCategories.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <select ref={ref}>
                {availableCategories.map(({ _id, name }) => (
                    <option key={_id} value={_id}>
                        {name}
                    </option>
                ))}
            </select>
            <button onClick={() => onAddToCat(ref.current.value)}>Ajouter</button>
        </React.Fragment>
    );
};

export const BSCreativesCategories = () => {
    const [state, setState] = useState({
        categories: [],
        creatives: []
    });

    useEffect(() => {
        fetchApi('/admin/programmaticCategories')
            .then((res) => res.json())
            .then(({ categories, creatives }) =>
                setState(() => ({
                    categories,
                    creatives: creatives.reverse()
                }))
            );
    }, []);

    const onCreateCategory = () => {
        const name = window.prompt('Nom de la catégorie');

        if (!name) {
            return;
        }

        fetchApi('/admin/programmaticCategories', {
            method: 'POST',
            body: JSON.stringify({ name })
        })
            .then((res) => res.json())
            .then((cat) =>
                setState((prevState) => ({
                    ...prevState,
                    categories: [...prevState.categories, cat]
                }))
            );
    };

    const onAssignToCategory = (categoryId, creativeId) => {
        fetchApi('/admin/programmaticCategories', {
            method: 'PUT',
            body: JSON.stringify({ categoryId, creativeId })
        })
            .then((res) => res.json())
            .then((cat) => {
                const categories_ = [...state.categories];
                const id = categories_.findIndex(({ _id }) => _id === cat._id);
                categories_.splice(id, 1, cat);

                setState((prevState) => ({
                    ...prevState,
                    categories: categories_
                }));
            });
    };

    const onDeleteCategory = (categoryId, creativeId) => {
        fetchApi(`/admin/programmaticCategories/${creativeId}/${categoryId}`, {
            method: 'DELETE'
        })
            .then((res) => res.json())
            .then((cat) => {
                const categories_ = [...state.categories];
                const id = categories_.findIndex(({ _id }) => _id === cat._id);
                categories_.splice(id, 1, cat);

                setState((prevState) => ({
                    ...prevState,
                    categories: categories_
                }));
            });
    };

    return (
        <div className="bsCreatives">
            <div className="row">
                <div className="col-md-4">
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="flex space-between">
                                <h3>Catégories</h3>
                                <button className="btn btn-info" onClick={onCreateCategory}>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="panel-body">
                            <table className="table table-responsibve">
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th># Creatives</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.categories.map((c) => (
                                        <tr key={c._id}>
                                            <td>{c.name}</td>
                                            <td>{c.creativesCovered.length}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="panel">
                        <div className="panel-heading">
                            <h3>Créatives</h3>
                        </div>
                        <div className="panel-body">
                            <table className="table table-responsibve">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nom</th>
                                        <th>Categories</th>
                                        <th>Approved</th>
                                        <th>Voir</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.creatives.map((c) => (
                                        <tr key={c.id}>
                                            <td>{c.id}</td>
                                            <td>{c.name}</td>
                                            <td>{getLabels(state.categories, c.id, onDeleteCategory)}</td>
                                            <td>{c.status}</td>
                                            <td>
                                                <a href={c.original_url} target="_blank" rel="noopener noreferrer">
                                                    Voir
                                                </a>
                                            </td>
                                            <td>
                                                <AssignButtton
                                                    onAddToCat={(cat) => onAssignToCategory(cat, c.id)}
                                                    categories={state.categories}
                                                    id={c.id}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
