import React from 'react';
import { Panel, Table, Modal } from 'react-bootstrap';
import { fetchApi } from '../../Services/NetworkServcice';

class OrderEdition extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idContrat: '',
            displayName: ''
        };

        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        if (this.props.selectedId) {
            this.setState({
                idContrat: this.props.selectedElt.idContrat,
                displayName: this.props.selectedElt.displayName
            });
        }
    }

    handleSave() {
        if (this.state.idContrat.trim().length === 0 || this.state.displayName.trim().length === 0) {
            return alert('Merci de renseigner les champs ci dessus');
        }

        this.props.onSave({
            idContrat: this.state.idContrat,
            displayName: this.state.displayName
        });
    }

    render() {
        return (
            <Modal show={true} onHide={this.props.closeModal}>
                <Modal.Header closeButton>
                    {this.props.selectedId ? 'Edition' : 'Creation'} d'un ordre d'insertion
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="idContrat">ID Contrat</label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.idContrat}
                            onChange={(e) => {
                                if (this.state.idContrat.length >= 64) {
                                    return;
                                }

                                this.setState({ idContrat: e.target.value });
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="displayName">Nom d'affichage</label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.displayName}
                            onChange={(e) => this.setState({ displayName: e.target.value })}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-info" onClick={this.handleSave}>
                        Enregistrer
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default class Orders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            modalOpen: false,
            selectedId: null,
            selectedElt: null
        };

        this.getData = this.getData.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        fetchApi('/admin/orders')
            .then((res) => res.json())
            .then((orders) => this.setState({ orders }));
    }

    handleSave(order) {
        this.setState({ modalOpen: false });

        if (this.state.selectedId) {
            fetchApi('/admin/orders/' + this.state.selectedId, {
                method: 'PUT',
                body: JSON.stringify(order)
            }).then(async (res) => {
                if (res.status === 200) {
                    this.getData();
                }
            });
        } else {
            fetchApi('/admin/orders', {
                method: 'POST',
                body: JSON.stringify(order)
            }).then(async (res) => {
                if (res.status === 200) {
                    const id = await res.text();
                    this.setState({ orders: [...this.state.orders, { ...order, id }] });
                }
            });
        }

        this.setState({ selectedId: null, selectedElt: null });
    }

    render() {
        return (
            <Panel>
                {!this.state.modalOpen || (
                    <OrderEdition
                        selectedId={this.state.selectedId}
                        selectedElt={this.state.selectedElt}
                        closeModal={() => this.setState({ modalOpen: false, selectedElt: null, selectedId: null })}
                        onSave={this.handleSave}
                    />
                )}
                <span>Ordres d'insertion</span>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Id contrat</th>
                            <th>Edition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orders.map((o) => (
                            <tr key={o.id}>
                                <td>{o.displayName}</td>
                                <td>{o.idContrat}</td>
                                <td>
                                    <button
                                        className="btn btn-info"
                                        onClick={() =>
                                            this.setState({ modalOpen: true, selectedId: o.id, selectedElt: o })
                                        }
                                    >
                                        <i className="fa fa-pen" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <br />
                <button className="btn btn-info" onClick={() => this.setState({ modalOpen: true })}>
                    Ajouter
                </button>
            </Panel>
        );
    }
}
