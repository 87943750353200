import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { fetchApi } from '../../Services/NetworkServcice';
import ChooseCategory from './ChooseCategory';
import SetPrice from './SetPrice';
import Attributes from './Attributes';
import PreviewCaterer from '../PreviewCaterer';

class Caterer extends React.Component {
    constructor(props) {
        super();

        this.state = {
            // State
            options: [],
            step: 0,

            duration: 5,

            // Crea
            _id: null,
            filename: '',
            subtitle: '',
            category: '',
            ingredients: '',
            price: '',
            priceUnit: 'kilo',
            pieceNb: '1',
            weight: '100',
            weightUnit: 'g',
            promotion: false,
            expirationDate: null,
            startDate: null,
            labels: [],
            templateName: props.templateName
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setSlide = this.setSlide.bind(this);
        this.handleSaveCrea = this.handleSaveCrea.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentDidMount() {
        fetchApi('/sequences/caterer')
            .then((res) => res.json())
            .then((caterer) => {
                const options = {};
                caterer.forEach((entry) => {
                    if (!options[entry.category]) {
                        options[entry.category] = [];
                    }

                    options[entry.category].push(entry.filename);
                });

                this.setState({ options });
            });
    }

    reset() {
        this.setState({
            _id: null,
            filename: '',
            subtitle: '',
            category: '',
            ingredients: '',
            price: '',
            priceUnit: 'kilo',
            pieceNb: '1',
            weight: '100',
            weightUnit: 'g',
            promotion: false,
            expirationDate: null,
            startDate: null,
            labels: [],
            templateName: this.props.templateName,
            duration: 5,

            step: 0
        });
    }

    handleClose() {
        this.reset();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSaveCrea() {
        const crea = {
            _id: this.state._id,
            filename: this.state.filename,
            category: this.state.category,
            subtitle: this.state.subtitle,
            ingredients: this.state.ingredients,
            price: this.state.price.toString().replace(',', '.'),
            priceUnit: this.state.priceUnit,
            pieceNb: this.state.pieceNb,
            weight: this.state.weight,
            weightUnit: this.state.weightUnit,
            promotion: this.state.promotion,
            expirationDate: this.state.expirationDate,
            startDate: this.state.startDate,
            labels: this.state.labels,
            templateName: this.state.templateName
        };

        this.props.handleSaveCrea({
            creative: crea,
            creaType: 'Caterer',
            startDate: this.state.startDate,
            expirationDate: this.state.expirationDate,
            duration: this.state.duration
        });

        this.handleClose();
    }

    setSlide(s, duration) {
        this.setState({ ...s, price: s.price.toString().replace('.', ','), step: 2, duration });
    }

    render() {
        let component = null;

        switch (this.state.step) {
            case 0:
                component = (
                    <ChooseCategory
                        filename={this.state.filename}
                        options={this.state.options}
                        ingredients={this.state.ingredients}
                        category={this.state.category}
                        handleSelectOption={(s) =>
                            this.setState({
                                filename: this.state.options[s][0],
                                category: s
                            })
                        }
                        handleSetCategory={(c) =>
                            this.setState({
                                category: c
                            })
                        }
                        handleSetSub={(v) => this.setState({ subtitle: v })}
                        handleSetIngredients={(i) => this.setState({ ingredients: i })}
                        subtitle={this.state.subtitle}
                        handleSwitchOption={(index, option) =>
                            this.setState({
                                filename:
                                    this.state.options[option][
                                        index < 0
                                            ? 0
                                            : index >= this.state.options[option].length
                                            ? this.state.options[option].length - 1
                                            : index
                                    ]
                            })
                        }
                    />
                );
                break;
            case 1:
                component = (
                    <Attributes
                        originCountry={this.state.originCountry}
                        labels={this.state.labels}
                        handleSetLabels={(labels) => this.setState({ labels, label: null })}
                        signId={this.props.signId}
                    />
                );
                break;
            case 2:
                component = (
                    <SetPrice
                        price={this.state.price}
                        priceUnit={this.state.priceUnit}
                        pieceNb={this.state.pieceNb}
                        weight={this.state.weight}
                        weightUnit={this.state.weightUnit}
                        promotion={this.state.promotion}
                        expirationDate={this.state.expirationDate}
                        handleChange={this.handleChange}
                        startDate={this.state.startDate}
                        allowSlidesDurationChange={this.props.allowSlidesDurationChange}
                        maxDuration={this.props.maxDuration}
                        onChangeDuration={(e) => this.setState({ duration: parseInt(e.target.value) })}
                        duration={this.state.duration}
                    />
                );
                break;
            default:
                break;
        }

        return (
            <div>
                <header>
                    <div className="progress-assistant">
                        <div className={`item${this.state.step >= 0 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(0, this.state.step) })}
                            >
                                1
                            </div>
                            {this.state.step !== 0 || <span>Choisissez un produit</span>}
                        </div>
                        <div className={`item${this.state.step >= 1 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(1, this.state.step) })}
                            >
                                2
                            </div>
                            {this.state.step !== 1 || <span>Renseignez plus d’informations</span>}
                        </div>
                        <div className={`item${this.state.step >= 2 ? ' active' : ''}`}>
                            <div
                                className="round"
                                onClick={() => this.setState({ step: Math.min(2, this.state.step) })}
                            >
                                3
                            </div>
                            {this.state.step !== 2 || <span>Prix et programmation</span>}
                        </div>
                    </div>
                    <div className="buttons">
                        {this.state.step <= 0 || (
                            <button
                                className="prev"
                                onClick={() => this.setState((prevState) => ({ step: prevState.step - 1 }))}
                            >
                                Retour
                            </button>
                        )}
                        <button
                            className="button-assistant"
                            disabled={
                                (this.state.step === 2 && this.state.price.trim().length === 0) ||
                                (this.state.step === 0 && this.state.filename.length === 0)
                            }
                            onClick={() => {
                                if (this.state.step < 2) {
                                    return this.setState((prevState) => ({ step: prevState.step + 1 }));
                                }

                                this.handleSaveCrea();
                            }}
                        >
                            {this.state.step < 2 ? 'Suivant' : 'Enregistrer'}
                        </button>
                    </div>
                </header>
                <div>
                    <Row>
                        <Col md={9}>{component}</Col>
                        <Col md={3}>
                            <p className="center">Prévisualisation</p>
                            <PreviewCaterer {...this.state} orientation={this.props.orientation} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Caterer;
